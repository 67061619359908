import MomentUtils from "@date-io/moment";
import {
    Box,
    Checkbox,
    createStyles,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    Snackbar,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { GiayChungNhanDiSanVanHoaService } from "../../../../apis/DiSanVanHoa/GiayChungNhanDiSanVanHoaService";
import { HoSoThuLyDiSanVanHoaService } from "../../../../apis/DiSanVanHoa/HoSoThuLyDiSanVanHoaService";
import { ACCEPT_FILES, LOAIBIENDONG_CAPLAI, TINHTRANG_HOATDONG } from "../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDiSanVanHoa_INIT } from "../../../../init/DiSanVanHoa/HoSoThuLyDiSanVanHoa_INIT";
import { GiayChungNhanDiSanVanHoa, GiayChungNhanDiSanVanHoa_INIT } from "../../../../model/giayChungNhanDiSanVanHoa";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import DropZoneCustom from "../../../../components/commons/hoso/CustomDropzone/DropZoneCustom";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import DiSanVanHoaButtonComponent from "../Action/DiSanVanHoaButtonComponent";
import AXIOS_INSTANCE from "../../../../apis";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import { HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import ThuLyButtonComponent from "../../../commons/hoso/thuly/ThuLyButtonComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

type ErrorValidation = {
    soGiayChungNhan: string;
    ngayCap: string;
    tenToChuc: string;
    maHuyen: string;
    maPhuong: string;
    soQD: string;
    soCMND: string;
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CapLaiGCNTuBoDiTichComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });

    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [showChuyen, setShowChuyen] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [dataAutocomple, setDataAutocomple] = useState<HoSoThuLyDiSanVanHoa[]>(new Array<HoSoThuLyDiSanVanHoa>());
    const [dataSoGiayPhep, setDataSoGiayPhep] = useState({ ...HoSoThuLyDiSanVanHoa_INIT });
    const [capLaiChungNhan, setCapLaiChungNhan] = useState({ ...HoSoThuLyDiSanVanHoa_INIT });
    const [giayChungNhan, setGiayChungNhan] = useState<GiayChungNhanDiSanVanHoa>({ ...GiayChungNhanDiSanVanHoa_INIT });
    const [data, setData] = useState<HoSoThuLyDiSanVanHoa>({ ...HoSoThuLyDiSanVanHoa_INIT, className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa" });
    const classes = useStyles();
    const hoSoThuLyDiSanVanHoaService = new HoSoThuLyDiSanVanHoaService();
    const service = new GiayChungNhanDiSanVanHoaService();
    const fileServerService = new FileServerService();
    const [currentDate, setCurrentDate] = useState(0);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [open, setOpen] = useState(false);
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        hoSoThuLyDiSanVanHoaService
            .getByMaHoSo(maHS)
            .then((res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    const dataNew = {
                        ...res?.data?.data,
                        maHoSo: maHS,
                        maThuTuc: maThuTuc,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPLAI.ma,
                        tenBienDong: LOAIBIENDONG_CAPLAI.ten,
                    };
                    setData(dataNew);
                } else {
                    const dataNew = {
                        ...HoSoThuLyDiSanVanHoa_INIT,
                        maHoSo: maHS,
                        maThuTuc: maThuTuc,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
                        isCapSoMoi: false,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPLAI.ma,
                        tenBienDong: LOAIBIENDONG_CAPLAI.ten,
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleBlur = (event: any) => {
        if (!StringUtil.listIsNotEmty(capLaiChungNhan)) {
            if (capLaiChungNhan && capLaiChungNhan?.id > 0) {
                setDataSoGiayPhep(capLaiChungNhan);
            } else {
                setDataSoGiayPhep({ ...HoSoThuLyDiSanVanHoa_INIT });
            }
        }
    };

    const handleSelectSoChungNhanDaCap = async (newValue: GiayChungNhanDiSanVanHoa) => {
        let id = newValue?.id;
        if (id && id > 0) {
            service
                .getBySoGiayChungNhanDiSanVanHoa(id)
                .then((res) => {
                    let selectData = res?.data.data;
                    setData({
                        ...selectData,
                        soChungNhan: selectData?.soChungNhan,
                        ngayCapChungNhan: selectData?.ngayCapChungNhan,
                        ngayHetHan: selectData?.ngayHetHan,
                        isCapSoMoi: data.isCapSoMoi,
                        soChungNhanMoi: data.soChungNhanMoi,
                        ngayCapChungChiMoi: data.ngayCapChungChiMoi,
                        ngayHetHanMoi: data.ngayHetHanMoi,
                        lyDoCapLai: data.lyDoCapLai,
                        lanCapLai: selectData.lanCapLai + 1,
                        ngayCapLai: data.ngayCapLai,
                        maHoSo: data.maHoSo,
                        maThuTuc: data.maThuTuc,
                        id: data.id,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPLAI.ma,
                        tenBienDong: LOAIBIENDONG_CAPLAI.ten,
                        resoucePK: selectData.id,
                        files: new Array<FileEntry>(),
                    });
                    setDataSoGiayPhep(selectData);
                })
                .catch((error) => {});
        }
    };

    const handleChangeSoGiayChungNhan = async (event: any) => {
        let soGiayChungChiDaCap = event.target.value;
        setDataSoGiayPhep({
            ...dataSoGiayPhep,
            soChungNhanMoi: event.target.value,
        });
        await service
            .getByAutocompleteSoGiayChungNhan(soGiayChungChiDaCap, true, 10)
            .then((res) => {
                let dataAutocompleNew = res?.data.data;
                if (StringUtil.listIsNotEmty(dataAutocompleNew)) {
                    setDataAutocomple([...dataAutocompleNew]);
                } else {
                    setDataAutocomple(new Array<HoSoThuLyDiSanVanHoa>());
                }
            })
            .catch((error) => {});
    };

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };
    const handleShowCapMoi = () => {
        setData({
            ...data,
            isCapSoMoi: !data.isCapSoMoi,
        });
    };
    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (data.isCapSoMoi) {
            if (data.donViCapSoGiayPhep) {
                if (StringUtil.isNullOrEmty(data.soChungNhanMoi)) {
                    return;
                }
                if (StringUtil.isNullOrEmty(data.ngayCapChungNhanMoi)) {
                    return;
                }
            }
        }
        if (StringUtil.isNullOrEmty(data.lyDoCapLai)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.lanCapLai)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.ngayCapLai)) {
            return;
        }
        
        if (data.ngayCapLai < data.ngayCapChungNhan) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp lại không được nhỏ hơn ngày cấp",
                severity: "warning",
            });
            return;
        }
        if (data.ngayCapChungNhanMoi > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        dispatchLoading(showLoading());
        setSubmit(false);
        hoSoThuLyDiSanVanHoaService
            .capLai(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setData({ ...response.data.data });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                    setShowChuyen(true);
                } else {
                    setAlert({
                        showAlert: false,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
                //lỗi
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let tenValue = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: tenValue,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {};
    const handleResetData = () => {
        const dataNew = {
            ...HoSoThuLyDiSanVanHoa_INIT,
            id: data.id,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            resoucePK: data.resoucePK,
            className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
            maTrangThai: TINHTRANG_HOATDONG.ma,
            tenTrangThai: TINHTRANG_HOATDONG.ten,
            maBienDong: LOAIBIENDONG_CAPLAI.ma,
            tenBienDong: LOAIBIENDONG_CAPLAI.ten,
        };
        setData(dataNew);
    };

    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        hoSoThuLyDiSanVanHoaService.downloadFileThuLy({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
        });
    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(data.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };
    
    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    return (
        <div className="dash-content">
        <>
            {

                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>
                                CẤP LẠI GIẤY CHỨNG NHẬN ĐỂ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH - MÃ HỒ SƠ {data.maHoSo}
                            </h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Số giấy chứng nhận đã cấp
                                    </InputLabel>
                                    <Autocomplete
                                        classes={inputStyles}
                                        options={dataAutocomple}
                                        value={data}
                                        noOptionsText={"Không có dữ liệu"}
                                        getOptionLabel={(option) => option.soChungNhan}
                                        onChange={(event: any, newValue: any) => handleSelectSoChungNhanDaCap(newValue)}
                                        onClose={handleBlur}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                onChange={(e: any) => handleChangeSoGiayChungNhan(e)}
                                                error={submit && StringUtil.isNullOrEmty(data.soChungNhan)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.soChungNhan) ? "Vui lòng nhập số chứng nhận" : ""}
                                            />
                                        )}
                                    />
                                    {submit && StringUtil.isNullOrEmty(data?.soChungNhan) && (
                                        <FormHelperText className="-error" classes={helperTextStyles}>
                                            Vui lòng chọn số giấy chứng nhận
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapChungNhan && data.ngayCapChungNhan !== 0 ? new Date(data.ngayCapChungNhan) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungNhan")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel classes={labelStyles} >Ngày hết hạn</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} style={{ marginTop: "20px" }} className="grid-item">
                                    <Checkbox name="active" value={data.isCapSoMoi} onChange={handleShowCapMoi} checked={data.isCapSoMoi} /> Cấp số mới
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {data.isCapSoMoi && (
                                            <>
                                                <Grid item xs={3} className="grid-item">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={data.donViCapSoGiayPhep}
                                                                name="donViCapSoGiayPhep"
                                                                value={data.donViCapSoGiayPhep}
                                                                onChange={handleChange}
                                                            />
                                                        }
                                                        label="Đơn vị cấp số giấy phép "
                                                    />
                                                </Grid>
                                                {data.donViCapSoGiayPhep && (
                                                    <>
                                                        <Grid item xs={3} className="grid-item">
                                                            <InputLabel classes={labelStyles} required>
                                                                Số giấy chứng nhận
                                                            </InputLabel>
                                                            <TextField
                                                                fullWidth
                                                                name="soChungNhanMoi"
                                                                InputLabelProps={{ classes: labelStyles }}
                                                                value={data.soChungNhanMoi}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    classes: inputStyles,
                                                                }}
                                                                onChange={handleChange}
                                                                error={submit && StringUtil.isNullOrEmty(data.soChungNhanMoi)}
                                                                helperText={
                                                                    submit && StringUtil.isNullOrEmty(data.soChungNhanMoi) ? "Vui lòng nhập số chứng nhận" : ""
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} className="grid-item">
                                                            <InputLabel classes={labelStyles} required>
                                                                Ngày cấp
                                                            </InputLabel>
                                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                                <KeyboardDatePicker
                                                                    autoOk={true}
                                                                    showTodayButton={true}
                                                                    value={
                                                                        data.ngayCapChungNhanMoi && data.ngayCapChungNhanMoi != 0
                                                                            ? new Date(data.ngayCapChungNhanMoi)
                                                                            : null
                                                                    }
                                                                    format="DD/MM/YYYY"
                                                                    fullWidth
                                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                                    variant="inline"
                                                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                                    onChange={(date: any, value: any) =>
                                                                        handleChangeDatePicker(date, value, "ngayCapChungNhanMoi")
                                                                    }
                                                                    error={submit && StringUtil.isNullOrEmty(data.ngayCapChungNhanMoi)}
                                                                    helperText={
                                                                        submit && StringUtil.isNullOrEmty(data.ngayCapChungNhanMoi) ? "Vui lòng nhập ngày" : ""
                                                                    }
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item xs={3} className="grid-item">
                                                            <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                                <KeyboardDatePicker
                                                                    autoOk={true}
                                                                    showTodayButton={true}
                                                                    value={data.ngayHetHanMoi && data.ngayHetHanMoi != 0 ? new Date(data.ngayHetHanMoi) : null}
                                                                    format="DD/MM/YYYY"
                                                                    fullWidth
                                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                                    variant="inline"
                                                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHanMoi")}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{ marginTop: "2%" }}>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tên tổ chức</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{data.tenToChuc}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item"></Grid>
                                    <Grid item xs={3} className="grid-item"></Grid>

                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Địa chỉ</InputLabel>
                                    </Grid>
                                    <Grid item xs={9} className="grid-item">
                                        <InputLabel>
                                            {data.soNha} {data.tenDuong} {data.tenPhuong} {data.tenHuyen}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Số QĐ/ GCNĐKDN/ GCNĐKKD /GCNĐKKD</InputLabel>
                                    </Grid>
                                    <Grid item xs={1} className="grid-item">
                                        <InputLabel>{data.soQD}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    </Grid>
                                    <Grid item xs={5} className="grid-item">
                                        <InputLabel>{data?.ngayCapQD ? moment(data?.ngayCapQD).format("DD/MM/YYYY") : ""}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Nơi cấp</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{data.noiCapQD}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item"></Grid>
                                    <Grid item xs={3} className="grid-item"></Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Người đại diện theo pháp luật</InputLabel>
                                    </Grid>
                                    <Grid item xs={1} className="grid-item">
                                        <InputLabel>{data.nguoiDaiDienTheoPhapLuat}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Ngày sinh</InputLabel>
                                    </Grid>
                                    <Grid item xs={5} className="grid-item">
                                        <InputLabel>{data?.ngaySinh ? moment(data?.ngaySinh).format("DD/MM/YYYY") : ""}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Chức danh</InputLabel>
                                    </Grid>
                                    <Grid item xs={1} className="grid-item" justify="flex-end">
                                        <InputLabel>{data.chucDanh}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Số CMND/ Số định danh</InputLabel>
                                    </Grid>
                                    <Grid item xs={5} className="grid-item">
                                        <InputLabel>{data.soCMND}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Ngày cấp CMND</InputLabel>
                                    </Grid>
                                    <Grid item xs={1} className="grid-item">
                                        <InputLabel>{data?.ngayCapCMND ? moment(data?.ngayCapCMND).format("DD/MM/YYYY") : ""}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Nơi cấp CMND</InputLabel>
                                    </Grid>
                                    <Grid item xs={5} className="grid-item">
                                        <InputLabel>{data.noiCapCMND}</InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: "20px" }}>
                                    <Grid item xs={6} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Lý do cấp lại"}
                                            name="lyDoCapLai"
                                            required
                                            InputLabelProps={{ classes: labelStyles }}
                                            onChange={handleChange}
                                            value={data.lyDoCapLai}
                                            error={submit && StringUtil.isNullOrEmty(data.lyDoCapLai)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            helperText={submit && StringUtil.isNullOrEmty(data?.lyDoCapLai) ? "Vui lòng nhập lý do cấp lại" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Lần cấp lại"}
                                            name="lanCapLai"
                                            required
                                            InputLabelProps={{ classes: labelStyles }}
                                            onChange={handleChange}
                                            value={data.lanCapLai}
                                            error={submit && StringUtil.isNullOrEmty(data.lanCapLai)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            helperText={submit && StringUtil.isNullOrEmty(data?.lanCapLai) ? "Vui lòng nhập số lần cấp lại" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel required  classes={labelStyles}>Ngày cấp lại</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                name="ngayCapLai"
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={data.ngayCapLai && data.ngayCapLai !== 0 ? new Date(data.ngayCapLai) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapLai")}
                                                error={submit && StringUtil.isNullOrEmty(data.ngayCapLai)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.ngayCapLai) ? "Vui lòng nhập ngày cấp lại" : ""}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "30px" }}>
                                    <Grid item xs={12} container className="grid-item">
                                        <DropZoneCustom
                                            acceptFiles={ACCEPT_FILES}
                                            attachFileServer={data.files}
                                            url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                                            handleChangeStatus={handleChangeStatus}
                                            handleDeleteFileServer={handleDeleteFileServer}
                                            handleDownloadFile={handleDownloadFile}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item"></Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    )}
                    <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        <ThuLyButtonComponent
                            showIn = {showChuyen}
                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                            onSubmit={handleSubmit}
                            onDelete={handleResetData}
                            handleChuyen={showHidePopperThaoTac}
                            handleDuThao={showHidePopperDuThao}
                            dataButton= {data}
                        />
                    </Grid>
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </>
         </div>
    );
   
};
export default CapLaiGCNTuBoDiTichComponent;
