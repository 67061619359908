import MomentUtils from "@date-io/moment";
import { Box, Button, createStyles, Grid, InputBase, InputLabel, Link, makeStyles, Snackbar, SvgIcon, TextField, Theme, Typography } from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { GiayChungNhanTheThaoService } from "../../../../apis/DichVuDuLich/GiayChungNhanTheThaoService";
import { LIST_TINHTRANG, LOAIBIENDONG_THUHOI, TINHTRANG_THUHOI } from "../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDichVuDuLich_INIT } from "../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLichSearchRequest_INIT } from "../../../../init/HoSoThuLyDichVuDuLich/HoSoThuLyDichVuDuLichSearchRequest_INIT";
import { HoSoThuLyDichVuDuLich, HoSoThuLyDichVuDuLichSearchRequest } from "../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import ButtonExt from "../../../commons/button/customButton";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import { DataListThongTinCSKDDichVuTheThao } from "../../DataListHoSoThuLy/TheThao/DataListThongTinCSKDDichVuTheThao";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

type ErrorValidation = {
    soGiayChungNhan: string;
    ngayCap: string;
    tenToChuc: string;
    maHuyen: string;
    maPhuong: string;
    soQD: string;
    soCMND: string;
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const TraCuuDSCSKDichVuTheThaoComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);

    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [dataList, setDataList] = useState<HoSoThuLyDichVuDuLich[]>([]);
    const [itemSeleted, setItemSeleted] = useState<HoSoThuLyDichVuDuLich>({} as HoSoThuLyDichVuDuLich);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = useState<HoSoThuLyDichVuDuLich>({
        ...HoSoThuLyDichVuDuLich_INIT,
        classNameGCN: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
    });
    const dispatch = useDispatch<Dispatch<LoadingAction>>();
    const giayChungNhanTheThaoService = new GiayChungNhanTheThaoService();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [totalData, setTotalData] = useState(0);
    const [searchData, setSeachData] = useState({ ...HoSoThuLyDichVuDuLichSearchRequest_INIT });
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();

    const history = useHistory();
    const search = () => {
        initData();
    };

    useEffect(() => {
        initData();
        loadQuans("92");
    }, []);

    /* Danh mục quận huyện */
    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadPhuongs = () => {
        if(searchData?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(searchData?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSeachData({
            ...searchData,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSeachData({ ...searchData, [nameValue]: value?.code, [nameText]: valueText });
    };

    const doSearch = async () => {
        let searchNew = { ...searchData, pageNumber: 1 };
        await setSeachData(searchNew);
        loadDataTable(searchNew);
    };

    const reload = async () => {
        let searchNew = { ...HoSoThuLyDichVuDuLichSearchRequest_INIT, pageNumber: 1 };
        await setSeachData(searchNew);
        loadDataTable(searchNew);
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...searchData, pageNumber: value };
        setSeachData(searchNew);
        loadDataTable(searchNew);
    };

    const handleDeleteItem = (id: number) => {
        if (id > 0) {
            setItemSeleted({ hoSoThuLyId: id } as HoSoThuLyDichVuDuLich);
            setOpenDeleteUserDlg(true);
        }
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...searchData, pageSize: Number(event.target.value), pageNumber: 1 };
        setSeachData(searchNew);
        loadDataTable(searchNew);
    };

    const showEditDialog = (item: HoSoThuLyDichVuDuLich) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    };
    const showNhapDauKy = () => {
        history.push("/cskd-dich-vu-the-thao", { maHoSo: "", isNhapDauKy: true });
    };

    const initData = () => {
        dispatchLoading(showLoading());
        loadDataTable(searchData);
    };

    const loadDataTable = async (requestBody: HoSoThuLyDichVuDuLichSearchRequest) => {
        dispatchLoading(showLoading());
        await giayChungNhanTheThaoService
            .getListDichVuDuLichTheThao(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                        setDataList(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...searchData, [filedName]: valueNew };
        setSeachData(dataNew);
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const downloadFile = async (extension: string) => {
        let request = {
            ...searchData,
            exportType: extension,
            exportFileName: "tra_cuu_danh_sach_dich_vu_the_thao",
        };
        console.log(request);

        dispatchLoading(showLoading());
        await giayChungNhanTheThaoService
            .downloadFileCSKDTheThao(request)
            .then((res) => {
                downLoadFile(res?.data, createFileType(extension), "danh_sach_giay_chung_nhan");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...searchData, [nameValue]: value?.ma, [nameText]: valueText };
        setSeachData(searchNew);
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        console.log(isCheckbox);
        console.log(e.target.value);
        console.group(e.target.name);
        console.log(data);
        setSeachData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleResetData = () => {
        const dataNew = {
            ...data,
            files: new Array<FileEntry>(),
            lyDoThuHoi: "",
            ngayThuHoi: 0,
            ghiChu: "",
            maTrangThai: TINHTRANG_THUHOI.ma,
            tenTrangThai: TINHTRANG_THUHOI.ten,
            maBienDong: LOAIBIENDONG_THUHOI.ma,
            tenBienDong: LOAIBIENDONG_THUHOI.ten,
            className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
        };
        setData(dataNew);
        setSubmit(false);
    };

    return (
        <>
            {
                <div className="dash-content">
                    <div className="dash-title">
                        <span>DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO</span>
                    </div>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={12} className="grid-item" container>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>Số quyết định</InputLabel>
                                        <InputBase fullWidth name="soQuyetDinh" value={searchData.soQuyetDinh} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Tên cơ sở"}
                                            name="tenCoSo"
                                            InputLabelProps={{ classes: labelStyles }}
                                            value={searchData.tenCoSo}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={handleChange}
                                            error={submit && StringUtil.isNullOrEmty(searchData.tenCoSo)}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>Tình trạng </InputLabel>
                                        <Autocomplete
                                            id="maTrangThai"
                                            classes={inputStyles}
                                            options={LIST_TINHTRANG}
                                            getOptionLabel={(option) => option.ten}
                                            value={{ ma: searchData.maTrangThai, ten: searchData.tenTrangThai }}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelect(newValue, "maTrangThai", "tenTrangThai");
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    error={submit && StringUtil.isNullOrEmty(searchData.maTrangThai)}
                                                    helperText={submit && StringUtil.isNullOrEmty(searchData.maTrangThai) ? "Vui lòng chọn phường/Xã/TT" : ""}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="grid-item" container>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>
                                            Huyện/Thị/TP{" "}
                                        </InputLabel>
                                        <Autocomplete
                                            id="maHuyen"
                                            options={quans}
                                            getOptionLabel={(option) => option?.name}
                                            value={{ code: searchData.maHuyen, name: searchData.tenHuyen }}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>
                                            Phường/Xã/TT{" "}
                                        </InputLabel>
                                        <Autocomplete
                                            id="maPhuong"
                                            options={phuongs}
                                            getOptionLabel={(option) => option.name}
                                            value={{ code: searchData.maPhuong, name: searchData.tenPhuong }}
                                            noOptionsText={"Không có Phường/Xã/TT"}
                                            onFocus={loadPhuongs}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>Ngày cấp từ</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={
                                                    searchData.ngayCapChungNhanTu && searchData.ngayCapChungNhanTu != 0
                                                        ? new Date(searchData.ngayCapChungNhanTu)
                                                        : null
                                                }
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungNhanTu")}
                                                error={submit && StringUtil.isNullOrEmty(searchData.ngayCapChungNhanTu)}
                                                helperText={submit && StringUtil.isNullOrEmty(searchData.ngayCapChungNhanTu) ? "Vui lòng nhập ngày" : ""}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>Ngày cấp đến</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={
                                                    searchData.ngayCapChungNhanDen && searchData.ngayCapChungNhanDen != 0
                                                        ? new Date(searchData.ngayCapChungNhanDen)
                                                        : null
                                                }
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungNhanDen")}
                                                error={submit && StringUtil.isNullOrEmty(searchData.ngayCapChungNhanDen)}
                                                helperText={submit && StringUtil.isNullOrEmty(searchData.ngayCapChungNhanDen) ? "Vui lòng nhập ngày" : ""}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="button-group-center">
                                <Button variant="contained" color="primary" disableElevation onClick={doSearch}>
                                    Tìm kiếm
                                </Button>
                                <ButtonExt variant="contained" color="primary" disableElevation onClick={() => showNhapDauKy()}>
                                    Nhập đầu kỳ
                                </ButtonExt>
                                <Button variant="contained" color="primary" disableElevation onClick={reload}>
                                    Làm mới
                                </Button>
                            </Grid>
                            <Grid item xs={12} className="grid-item" container justify="flex-end">
                                <Link
                                    title="Tải xuống"
                                    href="#"
                                    onClick={() => {
                                        downloadFile("docx");
                                    }}
                                >
                                    <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                        <path
                                            fill="#007bff"
                                            d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                                        />
                                    </SvgIcon>
                                </Link>
                                <Link
                                    title="Tải xuống"
                                    href="#"
                                    onClick={() => {
                                        downloadFile("pdf");
                                    }}
                                >
                                    <SvgIcon fontSize="large">
                                        <path
                                            fill="#dc3545"
                                            d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                                        />
                                    </SvgIcon>
                                </Link>
                                <Link
                                    title="Tải xuống"
                                    href="#"
                                    onClick={() => {
                                        downloadFile("xlsx");
                                    }}
                                >
                                    <SvgIcon fontSize="large">
                                        <path
                                            fill="#28a745"
                                            d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                                        />
                                    </SvgIcon>
                                </Link>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <DataListThongTinCSKDDichVuTheThao
                                    dataList={dataList}
                                    deleteItem={handleDeleteItem}
                                    pageProps={{
                                        page: searchData.pageNumber,
                                        count: totalData,
                                        rowsPerPage: searchData.pageSize,
                                        onChangePage: handleOnChangePage,
                                        onChangeRowsPerPage: handOnChangeRowsPerPage,
                                        reloadData: search,
                                    }}
                                    modalEdit={{ open: true, openEditDialog: showEditDialog }}
                                />
                            </Grid>
                        </TabPanel>
                    )}
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                </div>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </>
    );
};
export default TraCuuDSCSKDichVuTheThaoComponent;
