import { BaseService } from "../baseService";
import AXIOS_INSTANCE from "../index";
import { DanhMucDiaChiDTO } from "./model/DanhMucDTO";

export type DanhMucDTO = {
    id: number;
    ma: string;
    ten: string;
    ext?: any;
};

export type CoQuanDTO = {
    kyHieu: string;
    ten: string;
    ma: string;
    thamGiaHeThong: string;
    hoatDong: boolean;
}
export class DanhMucService extends BaseService {
    getLoaiVanBanByMaCoQuan_HoatDong = async (maCoQuan: string, hoatDong: boolean): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.post(
            "danh-muc/loai-van-ban/get-by-ma-co-quan-hoat-dong",
            { maCoQuan: maCoQuan, hoatDong: hoatDong },
            this.getTokenRequestHeaders()
        )
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.loaiVanBan, ma: "", ten: item.tenLoaiVanBan });
                    });
                }
            })
            .catch();
        return result;
    };

    getLinhVucByMaCoQuan_HoatDong = async (maCoQuan: string, hoatDong: boolean): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.post("danh-muc/linh-vuc/get-by-ma-co-quan-hoat-dong", { maCoQuan: maCoQuan, hoatDong: hoatDong }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.ma, ten: item.ten });
                    });
                }
            })
            .catch();
        return result;
    };
    getDMThuTuc = async (maLinhVuc: string, hoatDong: boolean): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.post("/danh-muc/thu-tuc", { maLinhVuc: maLinhVuc, hoatDong: hoatDong }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: 0, ma: item.ma, ten: item.ten });
                    });
                }
            })
            .catch();
        return result;
    };

    getDMLoaiThuTuc = async (): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.get("/danh-muc-loai-thu-tuc", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.maLoaiThuTuc, ten: item.tenLoaiThuTuc });
                    });
                }
            })
            .catch();
        return result;
    };

    getDMPhongBan = async (): Promise<DanhMucDTO[]> => {
        let result: DanhMucDTO[] = [];
        await AXIOS_INSTANCE.get("/danh-muc-phong-ban", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    response.data.data.forEach((item: any) => {
                        result.push({ id: item.id, ma: item.maPhongBan, ten: item.tenPhongBan });
                    });
                }
            })
            .catch();
        return result;
    };

    getDanhMucDiaChi = async (parentCode: String): Promise<DanhMucDiaChiDTO[]> => {
        let result: DanhMucDiaChiDTO[] = [];
        await AXIOS_INSTANCE.post(`/danh-muc-dia-chi/findAllDiaChiCommon`, { parentCode })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getDanhMucDiaChiByCode = async (code: String): Promise<any> => {
        let result ;
        await AXIOS_INSTANCE.post(`/danh-muc-dia-chi/findByCode`, { code })
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    getBySoCMND = (requestBody: any) => {
        return AXIOS_INSTANCE.post('/lgsp/info-cong-dan-by-cmnd', requestBody);
    }

    getDanhMucCoQuanCode_HoatDong = async (hoatDong: boolean): Promise<any> => {
        let result;
        await AXIOS_INSTANCE.post(`/danh-muc-co-quan/findAll`, { hoatDong })
            .then((response) => {
                if (response?.data?.error_code === "SUCCESSFUL" && response?.data?.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };
}
