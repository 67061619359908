import { FileEntry } from "../apis/Common/fileEntry";

export interface DiSanVanHoaVatThe {
    id: number;
    soQuyetDinh: string;
    ngayQuyetDinh: number;
    capDiSanId: string;
    tenCapDiSan: string;
    loaiDiSanId: string;
    tenLoaiDiSan: string;
    tenDiSan: string;
    diaChiKhiXepHang: string;
    tenHuyen: string;
    maHuyen: string;
    tenPhuong: string;
    maPhuong: string;
    tenDuong: string;
    maDuong: string;
    soNha: string;
    noiDungGioiThieu: string;
    ghiChu: string;
    duaRaKhoiDanhSach: boolean;
    files: FileEntry[];
    className: string;
}

export const DiSanVanHoaVatThe_INT: DiSanVanHoaVatThe = {
    id: 0,
    soQuyetDinh: "",
    ngayQuyetDinh: 0,
    capDiSanId: "",
    tenCapDiSan: "",
    loaiDiSanId: "",
    tenLoaiDiSan: "",
    tenDiSan: "",
    diaChiKhiXepHang: "",
    tenHuyen: "",
    maHuyen: "",
    tenPhuong: "",
    maPhuong: "",
    tenDuong: "",
    maDuong: "",
    soNha: "",
    noiDungGioiThieu: "",
    ghiChu: "",
    duaRaKhoiDanhSach: false,
    files: new Array<FileEntry>(),
    className: "com.fpt.egov.api.model.dsvh.DiSanVanHoaVatThe",
};

export interface TongTheoCapDiSan {
    maHuyen: string;
    tenHuyen: string;
    tongSo: number;
    capQG: number;
    congTrinhVHTN: number;
    capQGDB: number;
    capTP: number;
}

export interface ThongKeDSVHVatThe {
    maHuyen: string;
    tenHuyen: string;
    tongSo: number;
    tongSoTheoCapDiSan: TongTheoCapDiSan[];
}
export const ThongKeDSVHVatThe_INT: ThongKeDSVHVatThe = {
    maHuyen: "",
    tenHuyen: "",
    tongSo: 0,
    tongSoTheoCapDiSan: new Array<TongTheoCapDiSan>(),
};
export interface ThongKeDSVHVatTheResponse {
    totalTongSo: number;
    totalCapQG: number;
    totalCongTrinhVH: number;
    totalCapQGDB: number;
    totalCapTP: number;
    thongKeDSVHDTOs: TongTheoCapDiSan[];
}