import { FileEntry } from "../../../apis/Common/fileEntry";

export interface LichSuThuLyDichVuDuLich {
    hoSoThuLyId: number;
    
    nguoiTaoId: string;
    
    giayChungNhanId: number;
    
    maBienDong: string;
    
    tenBienDong: string;
    
    maTrangThai: string;
    
    tenTrangThai: string;
    
    ngayBienDong: number;
    
    lanCapLai: number;
   
    lyDoCapLai: string;
    
    soGiayPhepCu: string;

    soGiayPhepMoi: string;
    
    ngayCapCu: number;
    
    ngayCapMoi: number;
    
    ngayHetHanCu: number;
    
    ngayHetHanMoi: number;
    
    ngayCapLai: number;

    lyDo: string;

    ngayQuyetDinhThuHoi: number;

    ghiChu: string;

    files: FileEntry[]; 
}

export const LichSuThuLyDichVuDuLich_INIT: LichSuThuLyDichVuDuLich = {
    hoSoThuLyId: 0,
    
    nguoiTaoId: '',
    
    giayChungNhanId: 0,
    
    maBienDong: '',
    
    tenBienDong: '',
    
    maTrangThai: '',
    
    tenTrangThai: '',
    
    ngayBienDong: 0,
    
    lanCapLai: 0,
   
    lyDoCapLai: '',
    
    soGiayPhepCu: '',

    soGiayPhepMoi: '',
    
    ngayCapCu: 0,
    
    ngayCapMoi: 0,
    
    ngayHetHanCu: 0,
    
    ngayHetHanMoi: 0,
    
    ngayCapLai: 0,

    lyDo: '',

    ngayQuyetDinhThuHoi: 0,

    ghiChu: '',

    files: new Array<FileEntry>(),
}