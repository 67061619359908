import MomentUtils from "@date-io/moment";
import { Button, createStyles, Grid, InputLabel, Link, makeStyles, NativeSelect, Snackbar, SvgIcon, TextField, Theme } from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createFileType, downLoadFile } from "../../../../../apis/Common/exportUtil";
import { DanhMucService } from "../../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../apis/danhMuc/model/DanhMucDTO";
import { GiayChungNhanAnUongService } from "../../../../../apis/DichVuDuLich/GiayChungNhanAnUongService";
import { LIST_TINHTRANG } from "../../../../../constants/DichVuDuLichConstants";
import { TraCuuCoSoDuLichDTO_INIT } from "../../../../../init/DichVuDuLich/DichVuDuLichModel_INIT";
import { TraCuuCoSoDuLichDTO } from "../../../../../model/DichVuDuLich/DichVuDuLichModel";
import { GiayChungNhanKinhDoanh } from "../../../../../model/DichVuDuLich/GiayChungNhanKinhDoanh";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { AlertMessage } from "../../../../commons/alert";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import DanhSachGCN from "./DanhSachGCN";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px",
        },
    })
);

export const BaoCaoGCNAnUong = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [totalData, setTotalData] = React.useState(0);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [search, setSeach] = useState({
        ...TraCuuCoSoDuLichDTO_INIT,
        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
    });
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const giayChungNhanAnUongService = new GiayChungNhanAnUongService();
    const [dataList, setDataList] = useState<GiayChungNhanKinhDoanh[]>([]);
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    useEffect(() => {
        initData();
        loadQuans("92");
    }, []);

    const initData = async () => {
        namThongKeList();

        await loadDataTable(search);
    };

    /* Danh mục quận huyện */
    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadPhuongs = () => {
        if(search?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(search?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSeach({
            ...search,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSeach({ ...search, [nameValue]: value?.code, [nameText]: valueText });
    };

    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });

    const loadDataTable = async (requestBody: TraCuuCoSoDuLichDTO) => {
        if (requestBody.kyThongKe == 2) {
            if (requestBody.ngayCapTu > 0 && requestBody.ngayCapDen > 0 && requestBody.ngayCapDen < requestBody.ngayCapTu) {
                setAlert({
                    showAlert: true,
                    message: "Từ ngày không được lớn hơn đến này",
                    severity: "warning",
                });
                return;
            }
        }
        dispatchLoading(showLoading());
        await giayChungNhanAnUongService
            .baoCaoTinhHinhGCNAnUong(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                        setDataList(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ ma: 0, ten: "Chọn năm" }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { ma: namHienTai - i, ten: "Năm " + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { ma: namHienTai + i, ten: "Năm " + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    };

    const handleChangeKyThongKe = (e: any) => {
        const dataNew = {
            ...search,
            ["kyThongKe"]: e.target.value,
        };
        setSeach(dataNew);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let searchNew = { ...search, [filedName]: date?.toDate()?.getTime() };
        setSeach(searchNew);
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const downloadFile = async (extension: string) => {
        let request = {
            ...search,
            exportType: extension,
            exportFileName: "bao_cao_co_so_kinh_doanh_dich_vu_an_uong",
        };
        dispatchLoading(showLoading());
        await giayChungNhanAnUongService
            .downloadFileBaoCaoChungNhan(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "bao_cao_co_so_kinh_doanh_dich_vu_an_uong");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    /* Phân trang */
    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageSize: Number(event.target.value), pageNumber: 1 };
        setSeach(searchNew);
        loadDataTable(searchNew);
    };
    const handleSearch = async () => {
        let searchNew = { ...search, pageNumber: 1 };
        await setSeach(searchNew);
        await loadDataTable(searchNew);
    };

    const reload = async () => {
        let searchNew = { ...TraCuuCoSoDuLichDTO_INIT, classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong", pageNumber: 1 };
        await setSeach(searchNew);
        await loadDataTable(searchNew);
    }
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG TRÊN ĐỊA BÀN</span>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Kỳ báo cáo</InputLabel>
                    <NativeSelect disableUnderline fullWidth name="kyThongKe" value={search.kyThongKe} onChange={handleChangeKyThongKe}>
                        <option value={1}>Năm</option>
                        <option value={2}>Khoảng thời gian</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 2}>
                    <InputLabel classes={labelStyles}>Năm </InputLabel>
                    <Autocomplete
                        options={dataNamThongKe}
                        value={{ ma: search.namThongKe, ten: search.tenNamThongKe }}
                        getOptionLabel={(option) => option?.ten}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "namThongKe", "tenNamThongKe")}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 1}>
                    <InputLabel classes={labelStyles}>Từ ngày</InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={
                                search.ngayCapTu !== 0 && search.ngayCapTu && search.ngayCapTu !== null && search.ngayCapTu !== undefined
                                    ? new Date(search.ngayCapTu)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapTu")}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 1}>
                    <InputLabel classes={labelStyles}>Đến ngày</InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={
                                search.ngayCapDen !== 0 && search.ngayCapDen && search.ngayCapDen !== null && search.ngayCapDen !== undefined
                                    ? new Date(search.ngayCapDen)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapDen")}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 1}></Grid>
                <Grid item xs={6} className="grid-item" hidden={search.kyThongKe == 2}></Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Huyện/Thị/TP</InputLabel>
                    <Autocomplete
                        id="maHuyen"
                        options={quans}
                        getOptionLabel={(option) => option?.name}
                        value={{ code: search.maHuyen, name: search.tenHuyen }}
                        onChange={(event: any, newValue: any) => {
                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Phường/Xã/TT </InputLabel>
                    <Autocomplete
                        id="maPhuong"
                        options={phuongs}
                        noOptionsText={"Không có Phường/Xã/TT"}
                        getOptionLabel={(option) => option.name}
                        value={{ code: search.maPhuong, name: search.tenPhuong }}
                        onFocus={loadPhuongs}
                        onChange={(event: any, newValue: any) => {
                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Tình trạng</InputLabel>
                    <Autocomplete
                        options={LIST_TINHTRANG}
                        getOptionLabel={(option: any) => option.ten}
                        value={{ ma: search.maTrangThai, ten: search.tenTrangThai }}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "maTrangThai", "tenTrangThai")}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel></InputLabel>
                    <Button onClick={handleSearch} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                        Tổng hợp
                    </Button>
                    <Button onClick={reload} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                        Làm mới
                    </Button>
                </Grid>
                <Grid item xs={12} className="grid-item" container justify="flex-end">
                    <Link
                        title="Tải xuống"
                        href="#"
                        onClick={() => {
                            downloadFile("docx");
                        }}
                    >
                        <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                            <path
                                fill="#007bff"
                                d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                            />
                        </SvgIcon>
                    </Link>
                    <Link
                        title="Tải xuống"
                        href="#"
                        onClick={() => {
                            downloadFile("pdf");
                        }}
                    >
                        <SvgIcon fontSize="large">
                            <path
                                fill="#dc3545"
                                d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                            />
                        </SvgIcon>
                    </Link>
                    <Link
                        title="Tải xuống"
                        href="#"
                        onClick={() => {
                            downloadFile("xlsx");
                        }}
                    >
                        <SvgIcon fontSize="large">
                            <path
                                fill="#28a745"
                                d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                            />
                        </SvgIcon>
                    </Link>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <DanhSachGCN
                        search={handleSearch}
                        count={totalData}
                        page={search.pageNumber}
                        rowsPerPage={search.pageSize}
                        onChangePage={handleOnChangePage}
                        onChangeRowsPerPage={handOnChangeRowsPerPage}
                        dataList={dataList}
                        classNameGCN={"com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong"}
                    />
                </Grid>
            </Grid>
            <OverlayLoading />
            <Snackbar
                open={alert.showAlert}
                onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
};
