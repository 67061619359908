import { ThongKeDiaDiemDuLichDTO } from './../../model/DiaDiemDuLich/ThongKeDiaDiemDuLichDTO';
import { ThongKeDiaDiemDuLichRequest } from 'model/DiaDiemDuLich/ThongKeDiaDiemDuLichRequest';
import { LichSuDiaDiemDuLichDTO } from './../../model/DiaDiemDuLich/LichSuDiaDiemDuLichDTO';
import { PageRequest, Pagination } from 'apis/baseService';
import AXIOS_INSTANCE from 'apis';
import { BaseResponse } from 'apis/baseService';
import { BaseService } from 'apis/baseService';
import { DiaDiemDuLichRequest } from 'model/DiaDiemDuLich/DiaDiemDuLichRequest';
import { HoSoDiaDiemDuLichDTO } from 'model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO';


export class DiaDiemDuLichService extends BaseService {
    url: string = "/dia-diem-du-lich";

    insertOrUpdateDauKy = async (data: HoSoDiaDiemDuLichDTO): Promise<BaseResponse> => {
        let result = { isSuccess: false } as BaseResponse;

        await AXIOS_INSTANCE.post(`${this.url}/insert-or-update-dau-ky`, data).then((response) => {
            result = {
                isSuccess: response.data.error_code === "SUCCESSFUL",
                message: response.data.error_message,
                data: response.data.data,
            };
        });

        return result;
    };

    getBySoHoSo = async (maHoSo: String): Promise<HoSoDiaDiemDuLichDTO> => {
        let result = {} as HoSoDiaDiemDuLichDTO;
        await AXIOS_INSTANCE.post(`${this.url}/get-by-ma-ho-so`, { maHoSo }).then((response) => {
            result = response?.data?.data;
        });
        return result;
    };

    async search(data: DiaDiemDuLichRequest): Promise<Pagination<HoSoDiaDiemDuLichDTO[]>> {
        let result: Pagination<HoSoDiaDiemDuLichDTO[]> = {
            page: data.pageNumber,
            size: data.pageSize,
            total: 0,
            rows: [],
        };
        const newData = { ...data };
        await AXIOS_INSTANCE.post(`${this.url}/tra-cuu`, newData).then((response) => {
            if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }
        });

        return result;
    }

    async baoCao(data: DiaDiemDuLichRequest): Promise<Pagination<HoSoDiaDiemDuLichDTO[]>> {
        let result: Pagination<HoSoDiaDiemDuLichDTO[]> = {
            page: data.pageNumber,
            size: data.pageSize,
            total: 0,
            rows: [],
        };
        const newData = { ...data };
        await AXIOS_INSTANCE.post(`${this.url}/bao-cao`, newData).then((response) => {
            if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                result = {
                    ...result,
                    total: response.data.data.total,
                    rows: response.data.data.content,
                };
            }
        });
        return result;
    }

    exportFileTraCuu = async (request: DiaDiemDuLichRequest): Promise<any> => {
        return AXIOS_INSTANCE.post(`${this.url}/export-tra-cuu`, request, {
            params: {},
            data: {},
            responseType: "blob",
            timeout: 30000,
        });
    };

    exportFileBaoCao = async (request: DiaDiemDuLichRequest): Promise<any> => {
        return AXIOS_INSTANCE.post(`${this.url}/export-bao-cao`, request, {
            params: {},
            data: {},
            responseType: "blob",
            timeout: 30000,
        });
    };

    getByHoSoID = async (hoSoThuLyId: Number): Promise<HoSoDiaDiemDuLichDTO> => {
        let result = {} as HoSoDiaDiemDuLichDTO;
        await AXIOS_INSTANCE.post(`${this.url}/get-by-ho-so-id`, { hoSoThuLyId: hoSoThuLyId }).then((response) => {
            result = response?.data?.data;
        });
        return result;
    };

    getBienDongLichSu = async (giayPhepId: Number, maBienDong: string): Promise<LichSuDiaDiemDuLichDTO> => {
        let result = {} as LichSuDiaDiemDuLichDTO;
        await AXIOS_INSTANCE.post(`${this.url}/get-bien-dong-lich-su`, { giayPhepId: giayPhepId, maBienDong: maBienDong }).then((response) => {
            result = response?.data?.data;
        });
        return result;
    };


    insertOrUpdateTrangThai = (data: LichSuDiaDiemDuLichDTO) => {
        return AXIOS_INSTANCE.post(`${this.url}/insert-or-update-trang-thai`, data);
    };


    thongKe = (data: ThongKeDiaDiemDuLichRequest) => {
        return AXIOS_INSTANCE.post(`${this.url}/thong-ke`, data);
    }

    exportThongKe = (request: ThongKeDiaDiemDuLichRequest) => {
        return AXIOS_INSTANCE.post(`${this.url}/expot-thong-ke`, request, {
            responseType: "blob",
        });
    };

    bieuDo = (data: ThongKeDiaDiemDuLichRequest) => {
        return AXIOS_INSTANCE.post(`${this.url}/bieu-do`, data);
    }

    downloadFileThuLy(request: any) {
        return AXIOS_INSTANCE.post(`${this.url}/download-file-file-thu-ly`, request, {
            responseType: "blob",
        });
    }

    chiTietThongKe = (requestBody: ThongKeDiaDiemDuLichRequest) => {
        return AXIOS_INSTANCE.post(`${this.url}/chi-tiet-thong-ke`, requestBody);
    };
}
