import { Animation, Stack, EventTracker } from "@devexpress/dx-react-chart";
import {
    ArgumentAxis,
    BarSeries, Chart,
    Legend, ValueAxis, Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Box, Button, Card, CardContent, createStyles, Grid, InputBase, InputLabel, makeStyles, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { Dispatch, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { PageRequest } from "apis/baseService";
import { GiayChungNhanGiaiTriService } from "apis/DichVuDuLich/GiayChungNhanGiaiTriService";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import { BieuDoThongkeRespone } from "../../model/BaoCaoThongKeCSKDDTO";

export interface RequestBieuDoThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;",
        },
    })
);

export const BieuDoThongKeCSKDGiaiTri = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<BieuDoThongkeRespone[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [rows, setRows] = useState<BieuDoThongkeRespone[]>([]);

    const dataConHan = [...rows.map((item) => item.tongConHan)];
    const dataHetHan = [...rows.map((item) => item.tongHetHan)];
    
    useEffect(() => {
        formik.setValues({
            request: {
                namThongKe: new Date().getUTCFullYear(),
                tenNamThongKe: "Năm " + new Date().getUTCFullYear(),
            } as RequestBieuDoThongKeDTO,
        });
        namThongKeList();
        execute();
    }, []);

    const formik = useFormik({
        initialValues: {
            request: {
                namThongKe: new Date().getUTCFullYear(),
                tenNamThongKe: "Năm " + new Date().getUTCFullYear(),
            } as RequestBieuDoThongKeDTO,
        },
        onSubmit: () => execute(),
    });

    const execute = async () => {
        console.log("execute");
        let request = {
            ...formik.values.request,
        } as RequestBieuDoThongKeDTO;
        dispatchLoading(showLoading());
        await new GiayChungNhanGiaiTriService()
            .bieuDoThongkeCSKDGiaiTri(request)
            .then((res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    setData(res?.data?.data);
                    setRows(res?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ nam: 0, name: "Chọn năm" }];
        for (let i = namHienTai; i >= namHienTai - 5; --i) {
            var temp = { nam: i, name: "Năm " + i };
            temps.push(temp);
        }
        setDataNamThongKe(temps);
    };
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };
    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Đang hoạt động",
                backgroundColor: "#51A3A3",
                data: dataConHan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Hết hạn",
                backgroundColor: "#EAD94C",
                data: dataHetHan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
           
        ],
    };

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Năm </InputLabel>
                        <Autocomplete
                            options={dataNamThongKe}
                            value={{
                                nam: formik.values?.request?.namThongKe ? formik.values.request?.namThongKe : 0,
                                name: formik.values?.request?.tenNamThongKe ? formik.values.request?.tenNamThongKe : "",
                            }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.namThongKe", newValue?.nam);
                                formik.setFieldValue("request.tenNamThongKe", newValue?.name);
                            }}
                            renderInput={(params) => {
                                return (
                                    <div className="padding-top-8px" ref={params.InputProps.ref}>
                                        <InputBase fullWidth {...params.inputProps} />
                                    </div>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={9} className="grid-item" container justify="flex-end">
                        <Button onClick={execute} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tổng hợp
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <Card classes={classes}>
                            <CardContent>
                                <Bar data={dataBD} options={options} />
                            </CardContent>
                        </Card> 
                        {/* <Paper>
                            <Chart data={data}>
                                <ArgumentAxis />
                                <ValueAxis
                                    labelComponent={(props) => {
                                        return Number.isInteger(Number(props.text)) ? <text {...props}>{Number(props.text)}</text> : <text {...props}></text>;
                                    }}
                                />

                                <BarSeries name="Đang hoạt động" valueField="tongConHan" argumentField="thang" color="#00c292" />
                                <BarSeries name="Thu hồi" valueField="tongHetHan" argumentField="thang" color="orange" />
                                <Animation />
                                <Legend
                                    position="bottom"
                                    rootComponent={(root: Legend.RootProps) => {
                                        return (
                                            <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                                {root.children}
                                            </Box>
                                        );
                                    }}
                                    itemComponent={(label: Legend.ItemProps) => {
                                        return (
                                            <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                                {label.children}
                                            </Box>
                                        );
                                    }}
                                />
                                <Stack />
                                <EventTracker></EventTracker>
                                <Tooltip />
                            </Chart>
                        </Paper> */}
                    </Grid>
                </Grid>
            </form>
            <OverlayLoading />
        </div>
    );
};
