import { BaseService } from "../baseService";
import AXIOS_INSTANCE from "../index";
import { FileEntry } from "./fileEntry";
export class FileServerService extends BaseService {
    downloadFile(request: FileEntry) {
        return AXIOS_INSTANCE.post("/file-server/download", request, {
            responseType: "blob",
        });
    }
}
