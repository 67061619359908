import AXIOS_INSTANCE from "..";
import { DiSanVanHoaPhiVatThe, DiSanVanHoaPhiVatThe_INT, ThongKeDSVHPhiVatThe, ThongKeDSVHPhiVatTheResponse } from "../../model/diSanVanHoaPhiVatThe";
import { ExportPVTRequest } from "../../pages/diSanVanHoa/phiVatThe/baoCaoDiSanVanHoaPhiVatThe";
import { SearchPVTRequest } from "../../pages/diSanVanHoa/phiVatThe/searchDiSanVanHoaPhiVatThe";
import { ThongKePVTRequest } from "../../pages/diSanVanHoa/phiVatThe/thongKeDiSanVanHoaPhiVatThe";
import { BaseService } from "../baseService";

export class DiSanVanHoaPhiVatTheService extends BaseService {
    getFullList = async (): Promise<{ rows: DiSanVanHoaPhiVatThe[]; total: number }> => {
        var list: DiSanVanHoaPhiVatThe[] = [];
        var total: number = 0;
        await AXIOS_INSTANCE.post("/di-san-van-hoa-phi-vat-the/search", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        total = response.data.data.total;
                        list = response.data.data.data;
                    }
                }
            })
            .catch((error) => {});
        return { rows: list, total: total };
    };

    getList = async (request: SearchPVTRequest): Promise<{ rows: DiSanVanHoaPhiVatThe[]; total: number }> => {
        var list: DiSanVanHoaPhiVatThe[] = [];
        var total: number = 0;
        await AXIOS_INSTANCE.post("/di-san-van-hoa-phi-vat-the/search", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        total = response.data.data.total;
                        list = response.data.data.data;
                    }
                }
            })
            .catch((error) => {});
        return { rows: list, total: total };
    };

    thongKe = async (request: ThongKePVTRequest): Promise<ThongKeDSVHPhiVatTheResponse> => {
        var list: ThongKeDSVHPhiVatThe[] = [];
        let result = {} as ThongKeDSVHPhiVatTheResponse;
        await AXIOS_INSTANCE.post("/di-san-van-hoa-phi-vat-the/thong-ke", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.data){
                    result = response.data.data;
                }
            })
            .catch((error) => {});
        return result
    };

    downloadFileBaoCao = (request: ExportPVTRequest) => {
        return AXIOS_INSTANCE.get("/di-san-van-hoa-phi-vat-the/export", {
            responseType: "blob",
            params: request,
        });
    };

    downloadFileThongKe = async (request: ThongKePVTRequest) => {
        return AXIOS_INSTANCE.get("/di-san-van-hoa-phi-vat-the/download-thong-ke", {
            responseType: "blob",
            params: request,
        });
    };

    downloadFileTraCuu = (request: SearchPVTRequest) => {
        return AXIOS_INSTANCE.get("/di-san-van-hoa-phi-vat-the/download-tra-cuu", {
            responseType: "blob",
            params: request,
        });
    };

    getDetailById = async (id: Number): Promise<DiSanVanHoaPhiVatThe> => {
        var result: DiSanVanHoaPhiVatThe = DiSanVanHoaPhiVatThe_INT;
        await AXIOS_INSTANCE.post(`di-san-van-hoa-phi-vat-the/get-detail-by-id/${id}`, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    result = { ...response.data.data };
                }
            })
            .catch();
        return result;
    };

    insertOrUpdate = (data: DiSanVanHoaPhiVatThe) => {
        return AXIOS_INSTANCE.post("/di-san-van-hoa-phi-vat-the/add-or-update", data, this.getTokenRequestHeaders());
    };

    chiTietThongKe = (tenHuyenString?: string, index?: number, maHuyen?: string, ngayCapTu?: number, ngayCapDen?: number, namThongKe?: number) => {
        let body = {
            index: index,
            tenHuyenString: tenHuyenString,
            maHuyen: maHuyen,
            ngayCapTu: ngayCapTu,
            ngayCapDen: ngayCapDen,
            namThongKe: namThongKe,
        };
        return AXIOS_INSTANCE.post("/di-san-van-hoa-phi-vat-the/chi-tiet-thong-ke", {body});
    };

    // insertOrUpdate = async (data: DiSanVanHoaPhiVatThe): Promise<BaseResponse> => {
    //     let requestBody = {
    //         id: data.id,
    //         soQuyetDinh: data.soQuyetDinh,
    //         ngayQuyetDinh: data.ngayQuyetDinh,
    //         loaiDiSanId: data.loaiDiSanId,
    //         tenLoaiDiSan: data.tenLoaiDiSan,
    //         tenDiSan: data.tenDiSan,
    //         tenHuyen: data.tenHuyen,
    //         maHuyen: data.maHuyen,
    //         tenPhuong: data.tenPhuong,
    //         maPhuong: data.maPhuong,
    //         tenDuong: data.tenDuong,
    //         maDuong: data.maDuong,
    //         soNha: data.soNha,
    //         noiDungGioiThieu: data.noiDungGioiThieu,
    //         ghiChu: data.ghiChu,
    //         duaRaKhoiDanhSach: data.duaRaKhoiDanhSach
    //     } as DiSanVanHoaPhiVatThe;
    //     let result = {} as BaseResponse;
    //     await AXIOS_INSTANCE.post('/di-san-van-hoa-phi-vat-the/add-or-update', JSON.stringify(requestBody), this.getTokenRequestHeaders())
    //         .then(response => {
    //             if (response.status !== 200) {
    //                 result = { isSuccess: false, message: 'Có lỗi xảy ra, thực hiện lại!' };
    //             }
    //             result = {
    //                 isSuccess: response.data.error_code === 'SUCCESSFUL',
    //                 message: response.data.error_message,
    //                 data: response.data.data
    //             };
    //         }).catch((error) => {
    //             result = { isSuccess: false, message: 'Lỗi khi thực hiện kết nối server' };
    //         });
    //     return result
    // }
}
