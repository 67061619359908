import { Animation, Stack, EventTracker } from "@devexpress/dx-react-chart";
import {
    ArgumentAxis,
    BarSeries, Chart,
    Legend, ValueAxis, Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Box, Button, Card, CardContent, createStyles, Grid, InputBase, InputLabel, makeStyles, NativeSelect, TextField, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import React, { Dispatch, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { PageRequest } from "apis/baseService";
import { GiayChungNhanLuuTruService } from "apis/DichVuDuLich/GiayChungNhanLuuTruService";
import { TraCuuCoSoDuLichDTO_INIT } from "../../../../init/DichVuDuLich/DichVuDuLichModel_INIT";
import { TraCuuCoSoDuLichDTO } from "../../../../model/DichVuDuLich/DichVuDuLichModel";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { BieuDoThongkeRespone } from "../../../HoSoThuLyDichVuDuLich/model/BaoCaoThongKeCSKDDTO";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import { Pie } from "react-chartjs-2";

export interface RequestBieuDoThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;",
        },
    })
);

export const BieuDoCoSoLuuTruComponent = () => {
    const classes = useStyles();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<BieuDoThongkeRespone[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [search, setSeach] = useState<TraCuuCoSoDuLichDTO>({ ...TraCuuCoSoDuLichDTO_INIT });
    const service = new GiayChungNhanLuuTruService();
    const [rows, setRows] = useState<BieuDoThongkeRespone[]>([]);
    const inputStyles = bootstrapTextFieldHook.useInput();
    const dataConHan = [...rows.map((item) => item.tongDangHoatDong)];
    const dataHetHan = [...rows.map((item) => item.tongHetHan)];
    const [dataBDTron, setDataBDTron] = useState<BieuDoThongkeRespone>();

    const [dataBDTronTK, setDataBDTronTK] = useState<BieuDoThongkeRespone[]>([]);
    const dataConHanTK = [...dataBDTronTK.map((item) => item.tenHang)];
    const dataDangHoatDong = [...dataBDTronTK.map((item) => item.tongDangHoatDong)];
    const [colors, setColors] = useState<string[]>([]);
    useEffect(() => {
        namThongKeList();
        if (search.loaiBieuDo == 0) {
            loadDataTable(search);
        } else if (search.loaiBieuDo == 1) {
            execute(search);
        } else if (search.loaiBieuDo == 2) {
            executeBDTronTK(search);
        }

    }, [search.loaiBieuDo]);

    useEffect(() => {
        generateColor();

    }, [dataBDTronTK]);

    const loadDataTable = async (requestBody: TraCuuCoSoDuLichDTO) => {
        dispatchLoading(showLoading());
        await service
            .loadBieuDo(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setData(response.data.data);
                        setRows(response.data.data);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleButtonBD = () => {
        if (search.loaiBieuDo == 0) {
            loadDataTable(search);
        } else if(search.loaiBieuDo == 1) {
            execute(search);
        } else if (search.loaiBieuDo == 2) {
            executeBDTronTK(search);
        }
    }

    const generateColor = async () => {
        if (data) {
            let colorRamdom = []
            for (let i = 0; i < data?.length; i += 1) {
                const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
                console.log('test', color);
                colorRamdom.push(color);
            }
            setColors(colorRamdom);
        }
    }

    const execute = async (requestBody: TraCuuCoSoDuLichDTO) => {
        dispatchLoading(showLoading());
        await new GiayChungNhanLuuTruService().thongKeBieuDoTron(requestBody).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setDataBDTron(res?.data?.data);
            }
        }).catch(error => {
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const executeBDTronTK = async (requestBody: TraCuuCoSoDuLichDTO) => {
        dispatchLoading(showLoading());

        await new GiayChungNhanLuuTruService().thongKeBieuDoTronTheoHang(requestBody).then(res => {
            if (res?.data?.data !== null && res?.data?.data !== undefined) {
                setDataBDTronTK(res?.data?.data);
            }
        }).catch(error => {
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ ma: 0, ten: "Chọn năm" }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { ma: namHienTai - i, ten: "Năm " + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { ma: namHienTai + i, ten: "Năm " + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    };
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    };
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };

    const optionsBDTron = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            position: "bottom",
        },
        layout: {
            padding: 10,
        },
        type: "pie",
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem: any, data: any) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    const optionsBDTronTK = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            position: "bottom",
        },
        layout: {
            padding: 10,
        },
        type: "pie",
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem: any, data: any) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Đang hoạt động",
                backgroundColor: "#51A3A3",
                data: dataConHan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Hết hạn",
                backgroundColor: "#EAD94C",
                data: dataHetHan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },

        ],
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        let dataNew = { ...search, [e.target.name]: value };
        setSeach(dataNew);
    };

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ LƯU TRÚ</span>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Loại biểu đồ</InputLabel>
                    <NativeSelect
                        name="loaiBieuDo"
                        input={<InputBase fullWidth classes={inputStyles} />}
                        value={search.loaiBieuDo}
                        onChange={handleChange}
                    >
                        <option value="0">Biểu đồ thống kê</option>
                        <option value="1">Biểu đồ tròn</option>
                        <option value="2">Biểu đồ tròn thống kê theo hạng</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 2}>
                    <InputLabel classes={labelStyles}>Năm </InputLabel>
                    <Autocomplete
                        options={dataNamThongKe}
                        getOptionLabel={(option) => option?.ten}
                        value={{ ten: search.tenNamThongKe, ma: search.namThongKe }}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "namThongKe", "tenNamThongKe")}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-start">
                    <Button
                        onClick={handleButtonBD}
                        type="button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.button}
                    >
                        Tổng hợp
                    </Button>
                </Grid>
                {search.loaiBieuDo == 0 &&
                    <Grid item xs={12} className="grid-item">
                        <Card classes={classes}>
                            <CardContent>
                                <Bar data={dataBD} options={options} />
                            </CardContent>
                        </Card>
                        {/* <Paper>
                        <Chart data={data}>
                            <ArgumentAxis />
                            <ValueAxis
                                labelComponent={(props) => {
                                    return Number.isInteger(Number(props.text)) ? <text {...props}>{Number(props.text)}</text> : <text {...props}></text>;
                                }}
                            />
                            <BarSeries name="Đang hoạt động" valueField="tongDangHoatDong" argumentField="thang" color="#00c292" /> 
                            <BarSeries name="Hết hạn" valueField="tongHetHan" argumentField="thang" color="silver" />
                            <Animation />
                            <Legend
                                position="bottom"
                                rootComponent={(root: Legend.RootProps) => {
                                    return (
                                        <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                            {root.children}
                                        </Box>
                                    );
                                }}
                                itemComponent={(label: Legend.ItemProps) => {
                                    return (
                                        <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                            {label.children}
                                        </Box>
                                    );
                                }}
                            />
                            <Stack />
                            <EventTracker></EventTracker>
                            <Tooltip />
                        </Chart>
                    </Paper> */}
                    </Grid>
                }
                {search.loaiBieuDo == 1 &&
                    <Grid item xs={12} className="grid-item">
                        <Paper  >
                            <Card >
                                {/* <CardHeader title={"HỒ SƠ ĐÃ TIẾP NHẬN (" + dataTinhHinhXuLy.daTiepNhan + ")"} style={{ backgroundColor: "#f2f2f2", fontSize: "1em" }}></CardHeader> */}
                                <CardContent >
                                    <Pie
                                        height={120}
                                        data={{
                                            labels: ["Đang hoạt động (" + dataBDTron?.tongDangHoatDong + ")", "Hết hạn (" + (dataBDTron?.tongHetHan) + ")"],
                                            datasets: [
                                                {
                                                    data: [dataBDTron?.tongDangHoatDong, dataBDTron?.tongHetHan],
                                                    backgroundColor: ["#51A3A3", "#EAD94C",]
                                                    //hoverBackgroundColor: chartColors
                                                }
                                            ]
                                        }} options={optionsBDTron}
                                    />

                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                }
                {search.loaiBieuDo == 2 &&
                    <Grid item xs={12} className="grid-item">
                        <Paper  >
                            <Card >
                                <CardContent >
                                    <Pie
                                        height={120}
                                        data={{
                                            labels: dataConHanTK,
                                            datasets: [
                                                {
                                                    data: dataDangHoatDong,
                                                    backgroundColor: colors
                                                    //hoverBackgroundColor: chartColors
                                                }
                                            ]
                                        }} options={optionsBDTronTK}
                                    />

                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                }
            </Grid>
            <OverlayLoading />
        </div>
    );
};
