export interface HoSoThuLyDSKinhDoanhTheThaoRequest {

    soChungNhan: string,

    ngayCapChungNhanTu: number;

    ngayCapChungNhanDen: number;

    tenToChuc: string,

    maHuyen: string,

    tenHuyen: string,

    maPhuong: string,

    tenPhuong: string,

    tenNamThongKe: string,

    ngayHetHanTu: number;

    ngayHetHanDen: number;

    pageSize: number;

    pageNumber: number;

    namThongKe: number;

    kyThongKe: number;

    tenTrangThai: string;

    maTrangThai: string;

    keywords: string;

    hoatDong: boolean;

    classNameGCN: string;

}

export const HoSoThuLyDSKinhDoanhTheThaoRequest_INIT: HoSoThuLyDSKinhDoanhTheThaoRequest = {

    soChungNhan: '',

    ngayCapChungNhanTu: 0,

    ngayCapChungNhanDen: 0,

    tenToChuc: '',

    maHuyen: '',

    tenHuyen: '',

    maPhuong: '',

    tenPhuong: '',

    tenNamThongKe: 'Chọn năm',

    ngayHetHanTu: 0,

    ngayHetHanDen: 0,

    pageNumber: 1,

    pageSize: 5,

    kyThongKe: 1,

    namThongKe: 0,

    tenTrangThai: '',

    maTrangThai: '',

    keywords: '',

    hoatDong: true,

    classNameGCN: '',
}

