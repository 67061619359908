import { Box, createStyles, Grid, makeStyles, Snackbar, Theme, Typography } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../../apis/Common/FileServerService";
import { GiayChungNhanAnUongService } from "../../../../../apis/DichVuDuLich/GiayChungNhanAnUongService";
import { LOAIBIENDONG_THUHOI, TINHTRANG_THUHOI } from "../../../../../constants/DiSanVanHoaConstants";
import { GiayChungNhanKinhDoanh_INIT } from "../../../../../init/DichVuDuLich/GiayChungNhanKinhDoanh_INIT";
import { GiayChungNhanKinhDoanh } from "../../../../../model/DichVuDuLich/GiayChungNhanKinhDoanh";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import StringUtil from "../../../../../utils/stringUtils";
import { ConfirmationDialogRaw } from "../../../../commons/dialog/confirmDialog";
import TepTinDinhKemViewComponent from "../../../../commons/hoso/TepTinDinhKemViewComponent";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import TepTinDinhKemTuBoDiTichComponent from "../../../../DiSanVanHoa/GiaoDienThuLy/ChungChiTuBoDiTich/ThuHoi/TepTinDinhKemTuBoDiTichComponent";
import ThuHoiTuBoDiTichButtonComponent from "../../../../DiSanVanHoa/GiaoDienThuLy/ChungChiTuBoDiTich/ThuHoi/ThuHoiTuBoDiTichButtonComponent";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import ChiTietThongTinChungGCNAnUongComponent from "./ChiTietThongTinChungGCNAnUongComponent";
import LichSuGiayChungNhanKinhDoanhAnUong from "./LichSuGiayChungNhanKinhDoanhAnUong";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const ChiTietGCNKinhDoanhAnUong = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [currentDate, setCurrentDate] = useState(0);
    const [giayChungChiId, setGiayChungChiId] = useState(0);
    const [data, setData] = useState<GiayChungNhanKinhDoanh>({
        ...GiayChungNhanKinhDoanh_INIT,
        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
    });
    const classes = useStyles();
    const fileServerService = new FileServerService();
    const giayChungNhanAnUongService = new GiayChungNhanAnUongService();
    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        let giayChungNhanKinhDoanh = location.state?.giayChungNhanKinhDoanh;
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        await giayChungNhanAnUongService
            .chiTietGiayChungNhanById(giayChungNhanKinhDoanh)
            .then((res) => {
                if (StringUtil.hasDataGiayPhep(res)) {
                    const dataNew = {
                        ...res?.data?.data,
                    };
                    setData(dataNew);
                } else {
                    const dataNew = {
                        ...GiayChungNhanKinhDoanh_INIT,
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let tenValue = value?.ten;
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: tenValue,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value,
            };
            setData(dataNew);
        }
    };

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };
    const handleResetData = () => {
        const dataNew = {
            ...data,
            files: new Array<FileEntry>(),
            lyDoThuHoi: "",
            ngayThuHoi: 0,
            ghiChu: "",
            maTrangThai: TINHTRANG_THUHOI.ma,
            tenTrangThai: TINHTRANG_THUHOI.ten,
            maBienDong: LOAIBIENDONG_THUHOI.ma,
            tenBienDong: LOAIBIENDONG_THUHOI.ten,
            classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
        };
        setData(dataNew);
        setSubmit(false);
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const handleSubmit = () => { };
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container style={{ float: "left", paddingTop: "20px" }} item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>THÔNG TIN CHI TIẾT CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG</h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab style={tabRequired == 0 ? { color: "red" } : {}} label="Thông tin chung" />
                                <CustomTab label="Lịch sử" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <ChiTietThongTinChungGCNAnUongComponent data={data} />
                        </TabPanel>
                    )}
                    {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <LichSuGiayChungNhanKinhDoanhAnUong data={data?.lichSus} dataFile={data.files}/>
                        </TabPanel>
                    )}
                    <Grid container item xs={12} className="grid-item">
                        <ThuHoiTuBoDiTichButtonComponent
                            onSubmit={handleSubmit}
                            onResetData={handleResetData}
                            onOpenDialog={handleOpenDialog}
                            isChiTiet={true}
                        />
                    </Grid>
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </>
        </div>
    );
};
export default ChiTietGCNKinhDoanhAnUong;
