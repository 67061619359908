import { Box, Checkbox, FormHelperText, Grid, InputBase, InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { DanhMucI, DanhMucI_INIT } from "apis/Common/model/CommonModel";
import { HangCoSoLuuTruService } from "apis/DichVuDuLich/HangCoSoLuuTruService";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { DialogContent, DialogTitle } from "../../../commons/dialog/dialogCommons";

interface IModalProps {
    onSubmitData: (content: string, isSuccess: boolean) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: DanhMucI;
}

type ErrorValidation = {
    name: string;
};

export const EditDialog = (props: IModalProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [openDialog, setOpenDialog] = useState(false);
    const [itemEditDTO, setItemEditDTO] = useState<DanhMucI>({} as DanhMucI);
    const [errors, setErrors] = useState<ErrorValidation>({} as ErrorValidation);
    const [checked, setChecked] = useState<string[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [submit, setSubmit] = useState(false);

    const service = new HangCoSoLuuTruService();

    useEffect(() => {
        setItemEditDTO(props.itemEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    useEffect(() => {}, []);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let parentName = "";
        if (e.target.name === "parentId") {
            if (parseInt(e.target.value) !== 0) {
                var index = e.nativeEvent.target.selectedIndex;
                parentName = e.nativeEvent.target[index].text;
            }
            setItemEditDTO((itemEditDTO) => ({
                ...itemEditDTO,
                parentId: e.target.value,
                parentName: parentName,
            }));
        } else {
            setItemEditDTO((itemEditDTO) => ({
                ...itemEditDTO,
                [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
            }));
        }
    };
    const submitData = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(itemEditDTO.ten)) {
            return;
        }
        if (StringUtil.isNullOrEmty(itemEditDTO.ma)) {
            return;
        }
        dispatchLoading(showLoading());
        service
            .addOrUpdate(itemEditDTO)
            .then((res) => {
                if (res && res.data && res.data.error_code == "SUCCESSFUL") {
                    props.onSubmitData("Yêu cầu thực hiện thành công", true);
                }else if(res && res.data &&  res.data.error_code ===  'DUPLICATE_CODE'){
                    props.onSubmitData("DUPLICATE_CODE", false);
                }else {
                    props.onSubmitData(res.data.error_message, false);
                }
            })
            .catch((error) => {
                props.onSubmitData("Yêu cầu thực hiện thất bại", false);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const onResetData = () => {
        setItemEditDTO({
            ...DanhMucI_INIT,
            id: itemEditDTO.id,
        });
    };

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {!itemEditDTO.id ? "Thêm mới" : "Cập nhật"}
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid spacing={2} container>
                            <Grid item xs={12} className="gridItem">
                                <InputLabel required>Tên</InputLabel>
                                <InputBase
                                    fullWidth
                                    error={submit && StringUtil.isNullOrEmty(itemEditDTO.ten)}
                                    value={itemEditDTO.ten}
                                    name="ten"
                                    onChange={handleChange}
                                />
                                {submit && StringUtil.isNullOrEmty(itemEditDTO.ten) && <FormHelperText className="-error">Vui lòng nhập tên</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} className="gridItem">
                                <InputLabel required>Mã</InputLabel>
                                <InputBase
                                    fullWidth
                                    error={submit && StringUtil.isNullOrEmty(itemEditDTO.ma)}
                                    value={itemEditDTO.ma}
                                    name="ma"
                                    onChange={handleChange}
                                />
                                {submit && StringUtil.isNullOrEmty(itemEditDTO.ma) && <FormHelperText className="-error">Vui lòng nhập mã</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} className="gridItem" style={{ paddingLeft: 5 }}>
                                <Checkbox
                                    name="hoatDong"
                                    checked={itemEditDTO.hoatDong}
                                    color="default"
                                    size="small"
                                    value={itemEditDTO.hoatDong}
                                    onChange={handleChange}
                                />{" "}
                                Hoạt động
                            </Grid>

                            <Grid item xs={12} className="gridItem">
                                <Box marginTop="5px">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={() => {
                                            submitData();
                                        }}
                                    >
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={onResetData} disableElevation>
                                        Xóa
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation onClick={props.onDialogClose}>
                                        Hủy
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
