import { Box, Button, createStyles, Grid, InputLabel, makeStyles, Snackbar, Table, TableHead, TextField, Theme, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GiayChungNhanGiaiTriService } from "../../../../../apis/DichVuDuLich/GiayChungNhanGiaiTriService";
import { HoSoThuLyDichVuDuLich_INIT } from "../../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLich } from "../../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { HoSoThuLyDiSanVanHoa } from "../../../../../model/hoSoThuLyDiSanVanHoa";
import { LichSuGiayChungNhan } from "../../../../../model/lichSuGiayChungNhan";
import StringUtil from "../../../../../utils/stringUtils";
import { TepDinhKemPopper } from "../../../../commons/hoso/TepDinhKemPopper";
import TepTinDinhKemViewComponent from "../../../../commons/hoso/TepTinDinhKemViewComponent";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { Cell, Row } from "../../../../commons/table/tableCommons";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import { LichSuThuLyDichVuDuLich, LichSuThuLyDichVuDuLich_INIT } from "../../../model/lichSuThuLyDichVuDuLich";
import TepTinDinhKemCSKDDichVuComponent from "../../TepDinhKem/TepTinDinhKemCSKDDichVuComponent";
import MomentUtils from "@date-io/moment";
import { ACCEPT_FILES, LOAIBIENDONG_TAMNGUNG, TINHTRANG_TAMNGUNG } from "../../../../../constants/DichVuDuLichConstants";
import AXIOS_INSTANCE from "../../../../../apis";
import { IFileWithMeta } from "react-dropzone-uploader";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { createFileType, downLoadFile } from "../../../../../apis/Common/exportUtil";
import { FileServerService } from "../../../../../apis/Common/FileServerService";
import TamNgungButtonComponent from "./TamNgungButtonComponent";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { useDispatch } from "react-redux";
import { ConfirmationDialogRaw } from "../../../../commons/dialog/confirmDialog";
import { DUPLICATE } from "../../../../../constants/DiSanVanHoaConstants";
import { useLocation } from "react-router";
import { Alert, Color } from "@material-ui/lab";
import DropZoneCustom from "../../../../commons/hoso/CustomDropzone/DropZoneCustom";

interface TabPanelProps {
    children?: React.ReactNode;
    index?: any;
    value: any;
}

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const useStylesLabel = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

interface IDataListProps {
    dataList: LichSuGiayChungNhan[];
}

const TamNgungDichVuVuiChoiGiaiTriComponent = (props: IDataListProps) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const leftStyle = useStylesLabelLeft();
    const rightStyle = useStylesLabelRight();
    const [indexTab, setIndexTab] = useState(0);
    const [data, setData] = useState<HoSoThuLyDichVuDuLich>({ ...HoSoThuLyDichVuDuLich_INIT });
    const [dataLichSu, setDataLichSu] = useState<LichSuThuLyDichVuDuLich>({ ...LichSuThuLyDichVuDuLich_INIT });
    const history = useHistory<any>();
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const [submit, setSubmit] = useState(false);
    const label = useStylesLabel();
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState<HoSoThuLyDiSanVanHoa[]>([]);
    const [rowLichSu, setRowLichSu] = useState<LichSuThuLyDichVuDuLich[]>([]);
    const [thuHoi, setThuHoi] = useState<LichSuThuLyDichVuDuLich>({ ...LichSuThuLyDichVuDuLich_INIT });
    const inputStyles = bootstrapTextFieldHook.useInput();
    const fileServerService = new FileServerService();
    const [tabRequired, setTabRequired] = useState(-1);
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [showAlert, setShowAlert] = useState(false);
    const giayChungNhanGiaiTriService = new GiayChungNhanGiaiTriService();
    const location = useLocation<any>();
    const [currentDate, setCurrentDate] = useState(0);
    const loadGiayChungNhan = (giayChungNhanId: number, classNameGCN: string) => { 
        giayChungNhanGiaiTriService
            .chiTietGiayChungNhanById(giayChungNhanId)
            .then((res) => {
                console.log("Res", res);
                
                if (StringUtil.hasDataGiayPhep(res)) {
                    const dataNew = {
                        ...res?.data?.data,
                        maTrangThai: TINHTRANG_TAMNGUNG.ma,
                        tenTrangThai: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                        classNameGCN: classNameGCN,
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {});
    };

    const loadThongTinThuHoi = (giayChungNhanId: number, hoSoThuLyId: number, classNameGCN: string) => {
        giayChungNhanGiaiTriService
            .chiTietGiayTamNgung(giayChungNhanId, classNameGCN)
            .then((res) => {
                console.log("data", res);
                
                if (StringUtil.hasDataGiayPhep(res)) {
                    setThuHoi(res?.data.data);
                } else {
                    const dataNew = {
                        ...thuHoi,
                        giayChungNhanId: giayChungNhanId,
                        hoSoThuLyId: hoSoThuLyId,
                        maTrangThai: TINHTRANG_TAMNGUNG.ma,
                        tenTrangThai: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri",
                    };
                    setThuHoi(dataNew);
                }
            })
            .catch((error) => {})
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    // const getDetailById = async (id: number) => {
    //     await new GiayChungNhanGiaiTriService()
    //         .getDetailDichVuVuiChoiGiaiTri(id)
    //         .then((p) => {
    //             setData(p);
    //             setThuHoi(p.data.data);
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //         });
    // };

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const getLichSuGiayChungNhan = async (id: number) => {
        let classNameGCN = "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri";
        await new GiayChungNhanGiaiTriService()
            .getLichSuDichVuDuLichVuiChoiGiaiTri(id, classNameGCN)
            .then((res) => {
                setRowLichSu(res.rows ? res.rows : []);

                setTotal(res.total);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleResetData = () => {
        const dataNew = {
            ...thuHoi,
            lyDo: "",
            ngayQuyetDinhThuHoi: 0,
            ghiChu: "",
            soQuyetDinhThuHoi: "",
            maTrangThai: TINHTRANG_TAMNGUNG.ma,
            tenTrangThai: TINHTRANG_TAMNGUNG.ten,
            maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
            tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
            classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri",
            files: new Array<FileEntry>(),
        };
        setThuHoi(dataNew);
        setSubmit(false);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleSubmit = async () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(thuHoi.lyDo)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(thuHoi.ngayQuyetDinhThuHoi)) {
            setTabRequired(0);
            return;
        }
        
        if(thuHoi.ngayQuyetDinhThuHoi < data.ngayCap){
            setAlert({
                showAlert: true,
                message: "Ngày tạm ngưng không được nhỏ hơn ngày cấp",
                severity: "warning"
            });
            setTabRequired(0);
            return;
        }
        dispatchLoading(showLoading());
        await new GiayChungNhanGiaiTriService()
            .thuHoi(thuHoi)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response?.data?.data) {
                    setThuHoi({
                        ...thuHoi,
                        // hoSoThuLyId: response?.data?.data?.id,
                        hoSoThuLyId: response?.data?.data?.hoSoThuLyId,
                        giayChungNhanId: response?.data?.data?.giayChungNhanId,
                    });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                    // getDetailById(thuHoi.hoSoThuLyId);
                    loadGiayChungNhan(thuHoi.giayChungNhanId, "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri");
                } else if (response.data.error_code === DUPLICATE) {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...thuHoi,
            ["files"]: mems,
        };
        setThuHoi(dataNew);
    };

    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };

    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };

    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };

    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...thuHoi,
            ["files"]: mems,
        };
        setThuHoi(dataNew);
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setThuHoi((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    function getDiaChi(soNha: string, tenDuong: string, tenPhuong: string, tenHuyen: string) {
        var result = "";
        if (!StringUtil.isNullOrEmty(soNha)) result = result.concat(soNha);
        if (!StringUtil.isNullOrEmty(tenDuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenDuong);
            else result = result.concat(", ", tenDuong);
        }
        if (!StringUtil.isNullOrEmty(tenPhuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenPhuong);
            else result = result.concat(", ", tenPhuong);
        }
        if (!StringUtil.isNullOrEmty(tenHuyen)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenHuyen);
            else result = result.concat(", ", tenHuyen);
        }
        return result;
    }

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...thuHoi, [filedName]: valueNew };
        setThuHoi(dataNew);
    };

    useEffect(() => {
        // let id = history?.location?.state?.params;;
        // getDetailById(id);
        // getLichSuGiayChungNhan(id);
        initData();
    }, []);

    const initData = async () => {
        let giayChungNhanId = location.state?.giayChungNhanId;
        let hoSoThuLyId = location.state?.hoSoThuLyId;

        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        await loadGiayChungNhan(giayChungNhanId, "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri");
        await loadThongTinThuHoi(giayChungNhanId, hoSoThuLyId, "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri");
    };


    return (
        <div className="dash-content">
        <Snackbar
            open={alert.showAlert}
            onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={3000}
        >
            <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                {alert.message}
            </Alert>
        </Snackbar>
        <Grid container>
            <div className="dash-content">
                <div className="dash-title">
                    <span>TẠM NGƯNG CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ</span>
                </div>
            </div>
            <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={indexTab} onChange={handleChangeTab}>
                        <CustomTab label="Thông tin chung" />
                        {/* <CustomTab label="Lịch sử" /> */}
                    </CustomTabs>
                </Box>
            </Grid>
            <TabPanel value={indexTab} index={0}>
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Số quyết định</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.soQuyetDinh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.ngayCap != null ? moment(data?.ngayCap).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày hết hạn</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.ngayHetHan != null ? moment(data?.ngayHetHan).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tình trạng</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.tenTrangThai}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tên cơ sở</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.tenCoSo}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{getDiaChi(data.soNha, data.tenDuong, data.tenPhuong, data.tenHuyen)}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Điện thoại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.soDienThoai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Fax</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.fax}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Email</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.email}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Website</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.website}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Số GCNĐKDN/HKD</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.soGCN}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.ngayCapGCN != null ? moment(data?.ngayCapGCN).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Cơ quan cấp</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.coQuanCapGCN}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Nơi cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.noiCapGCN}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tổng số người quản lý và nhân viên</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.tongSoNguoi}</InputLabel>
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <InputLabel classes={label} required>
                                Lý do tạm ngưng
                            </InputLabel>
                            <TextField
                                fullWidth
                                name="lyDo"
                                InputLabelProps={{ classes: labelStyles }}
                                value={thuHoi.lyDo}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: inputStyles,
                                }}
                                onChange={handleChange}
                                error={submit && StringUtil.isNullOrEmty(thuHoi.lyDo)}
                                helperText={submit && StringUtil.isNullOrEmty(thuHoi.lyDo) ? "Vui lòng nhập lý do thu hồi" : ""}
                            />
                        </Grid>
                        {/* <Grid item xs={3} className="grid-item">
                            <InputLabel classes={label} required>
                                Số quyết định thu hồi
                            </InputLabel>
                            <TextField
                                fullWidth
                                name="soQuyetDinhThuHoi"
                                InputLabelProps={{ classes: labelStyles }}
                                value={thuHoi.soQuyetDinhThuHoi}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: inputStyles,
                                }}
                                onChange={handleChange}
                                error={submit && StringUtil.isNullOrEmty(thuHoi.soQuyetDinhThuHoi)}
                                helperText={submit && StringUtil.isNullOrEmty(thuHoi.soQuyetDinhThuHoi) ? "Vui lòng nhập số quyết định thu hồi" : ""}
                            />
                        </Grid> */}
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles} required>
                                Ngày tạm ngưng
                            </InputLabel>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={thuHoi.ngayQuyetDinhThuHoi && thuHoi.ngayQuyetDinhThuHoi != 0 ? new Date(thuHoi.ngayQuyetDinhThuHoi) : null}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhThuHoi")}
                                    error={submit && StringUtil.isNullOrEmty(thuHoi.ngayQuyetDinhThuHoi)}
                                    helperText={submit && StringUtil.isNullOrEmty(thuHoi.ngayQuyetDinhThuHoi) ? "Vui lòng nhập ngày tạm ngưng " : ""}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} className="grid-item">
                            <InputLabel classes={label}>Ghi chú</InputLabel>
                            <TextField
                                fullWidth
                                name="ghiChu"
                                InputLabelProps={{ classes: labelStyles }}
                                value={thuHoi.ghiChu}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: inputStyles,
                                }}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} container className="grid-item">
                            <DropZoneCustom
                                acceptFiles={ACCEPT_FILES}
                                attachFileServer={thuHoi.files}
                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                                handleChangeStatus={handleChangeStatus}
                                handleDeleteFileServer={handleDeleteFileServer}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            
            {/* <TabPanel value={indexTab} index={1}>
                <Grid container>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">
                                    STT
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="25%">
                                    Loại biến động
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    Ngày biến động
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="30%">
                                    Tập tin
                                </Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowLichSu.map((row, i) => (
                                <Row key={row.giayChungNhanId}>
                                    <Cell scope="row" align="center">
                                        {i + 1}
                                    </Cell>
                                    <Cell align="left">{row.tenBienDong}</Cell>
                                    <Cell align="left">
                                        {row.ngayBienDong !== null && row.ngayBienDong !== undefined ? moment(row.ngayBienDong).format("DD/MM/YYYY") : ""}
                                    </Cell>
                                    <Cell align="left">
                                        <TepDinhKemPopper data={data.files}/>
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </TabPanel> */}
            <Grid item xs={12} container className="gridItem">
                <Grid container item xs={12} className="grid-item">
                    <TamNgungButtonComponent
                        onSubmit={handleSubmit}
                        onResetData={handleResetData}
                        onOpenDialog={handleOpenDialog}
                        isChiTiet={false}
                    />
                </Grid>
                {/* <Box marginTop="5px">
                    <Button variant="contained" color="primary" disableElevation className="button" onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Box> */}
            </Grid>
        </Grid>
        {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </div>
    );
};
export default TamNgungDichVuVuiChoiGiaiTriComponent;
