import { Box, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { HoSoThuLyLuHanhNoiDia } from "../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { ROUTE_GIAY_PHEP_LHND_CHI_TIET } from "../../../routes/route_luhanh";
import { CustomInput } from "../../commons/input/customInput";
import { Cell, Row } from "../../commons/table/tableCommons";
import ThaoTacQuanLyGiayPhepLHND from "./ThaoTacQuanLyGiayPhepLHND";

interface TableProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    dataList: HoSoThuLyLuHanhNoiDia[];
    search: () => void;
    onHandleThaoTac: (id: number, loai: string) => void;
}
const DanhSachGiayPhepLHNDComponent = (props: TableProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    // const [open, setOpen] = React.useState(false);
    // const anchorRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        setTotalPage(Math.ceil(props.count / props.rowsPerPage));
        setRowsPerPage(props.rowsPerPage);
    }, [props.count, props.rowsPerPage]);

    const handleThaoTac = (id: number, loai: string) => {
        props.onHandleThaoTac(id, loai);
    };

    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="15%">
                                Số giấy phép
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ngày cấp
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tên doanh nghiệp
                            </Cell>
                            <Cell component="th" scope="row" align="center">
                                Địa chỉ
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Điện thoại
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tình trạng
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="5%">
                                Thao tác
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataList.length > 0 ? (
                            props.dataList.map((row, i) => (
                                <Row key={i}>
                                    <Cell>{(props.page - 1) * rowsPerPage + i + 1}</Cell>
                                    <Cell>
                                        <NavLink
                                            style={{ textDecoration: "none" }}
                                            exact
                                            to={{
                                                pathname: ROUTE_GIAY_PHEP_LHND_CHI_TIET.duongDan,
                                                state: { giayPhepId: row.giayPhepId },
                                            }}
                                        >
                                            {row.soGiayPhep}
                                        </NavLink>
                                    </Cell>
                                    <Cell>
                                        {row.ngayCap && row.ngayCap !== undefined && row.ngayCap !== null
                                            ? moment(new Date(row.ngayCap)).format("DD/MM/YYYY")
                                            : ""}
                                    </Cell>
                                    <Cell>{row.tenDoanhNghiep}</Cell>
                                    <Cell>{row.diaChiDayDu}</Cell>
                                    <Cell>{row.dienThoai}</Cell>
                                    <Cell>{row.tenTrangThai}</Cell>
                                    <Cell align="center">
                                        <ThaoTacQuanLyGiayPhepLHND data={row} onHandleThaoTac={handleThaoTac} />
                                    </Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={9} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu
                                </Cell>
                            </Row>
                        )}
                    </TableBody>
                </Table>
                {props.dataList.length > 0 && (
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination
                            count={totalPage}
                            defaultPage={1}
                            siblingCount={1}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={props.onChangePage}
                        />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">
                                Số mẫu tin trên trang
                            </Box>
                            <NativeSelect input={<CustomInput />} onChange={props.onChangeRowsPerPage}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">
                            Mẫu tin {props.page * props.rowsPerPage - props.rowsPerPage + 1} -{" "}
                            {props.count - (props.page - 1) * props.rowsPerPage < props.rowsPerPage ? props.count : props.page * props.rowsPerPage} /{" "}
                            {props.count}
                        </Box>
                        {/* <Box component="span" paddingX="5px">Mẫu tin {(props.page * rowsPerPage - rowsPerPage) + 1} - {(props.count - (props.page-1) * rowsPerPage) < rowsPerPage ? (props.count):(props.page * rowsPerPage) } / {props.count}</Box> */}
                    </Box>
                )}
            </TableContainer>
        </>
    );
};
export default DanhSachGiayPhepLHNDComponent;
