import { Button, Grid } from "@material-ui/core";
import { BieuMauAttachFileDTO } from "apis/danhMucCN";
import { HoSoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO";
import { useHistory } from "react-router";
import InBieuFileThuLyButton from "./InBieuFileThuLyButton";
import InBieuMauButton from "./thuly/InBieuMauButton";

interface HoSoThuLyButonProps {
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    onSubmit?: (values: HoSoDiaDiemDuLichDTO) => void;
    onResetData: () => void;
    onOpenDialog: () => void;
    onDownloadFileThuLy?: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    formik: any;
    showIn?: boolean;
}

const HoSoKhuDuLichButonComponent = (props: HoSoThuLyButonProps) => {
    const { onDownloadFileBieuMau, showIn,onSubmit, onResetData, onOpenDialog, onDownloadFileThuLy, formik } = props;
    const history = useHistory();
    return (
        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
            <Button onClick={() => formik.handleSubmit()} variant="contained" color="primary" disableElevation style={{ margin: "0px 5px" }}>
                Lưu
            </Button>
            {
                showIn &&
                <InBieuMauButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
            }
            {onDownloadFileThuLy && <InBieuFileThuLyButton onDownloadFileThuLy={onDownloadFileThuLy} />}
            <Button variant="contained" color="secondary" onClick={onOpenDialog} disableElevation style={{ margin: "0px 5px" }}>
                {" "}
                Xóa
            </Button>
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation style={{ margin: "0px 5px" }}>
                {" "}
                Trở về
            </Button>
        </Grid>
    );
};
export default HoSoKhuDuLichButonComponent;
