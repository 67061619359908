export type DichVuDuLichConstants = {
    ten: string;
    ma: string;
}
export const LOAIBIENDONG_CAPMOI: DichVuDuLichConstants = {
    ten: "Cấp mới",
    ma: "1"
}
export const LOAIBIENDONG_THUHOI: DichVuDuLichConstants = {
    ten: "Thu hồi",
    ma: "2"
}
export const LOAIBIENDONG_TAMNGUNG: DichVuDuLichConstants = {
    ten: "Tạm ngưng",
    ma: "3"
}
export const TINHTRANG_HOATDONG: DichVuDuLichConstants = {
    ten: "Đang Hoạt động",
    ma: "1"
}
export const TINHTRANG_THUHOI: DichVuDuLichConstants = {
    ten: "Thu hồi",
    ma: "2"
}
export const TINHTRANG_NGUNGHOATDONG: DichVuDuLichConstants = {
    ten: "Hết hạn",
    ma: "3"
}
export const TINHTRANG_TAMNGUNG: DichVuDuLichConstants = {
    ten: "Tạm ngưng",
    ma: "4"
}
export const TINHTRANG_GANHETHAN: DichVuDuLichConstants = {
    ten: "Gần hết hạn",
    ma: "5"
}

export const LIST_BIENDONG: DichVuDuLichConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_THUHOI
]

export const LIST_TINHTRANG: DichVuDuLichConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_THUHOI,
    TINHTRANG_TAMNGUNG,
    TINHTRANG_GANHETHAN
]

export const LIST_TINHTRANG_LUUTRU: DichVuDuLichConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_NGUNGHOATDONG,
    TINHTRANG_TAMNGUNG,
    TINHTRANG_GANHETHAN
]

export const LIST_TINHTRANG_NHAPDAUKY: DichVuDuLichConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_THUHOI,
]

export const ACCEPT_FILES = "image/*,.doc,.docx,.xls,.xlsx,.pdf";