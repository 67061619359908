import { Button, ClickAwayListener, Grow, Paper, Popper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { HoSoThuLyLuHanhNoiDia } from "../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
interface ThaoTacQuanLyGiayChungChiDSVHProps {
    data: HoSoThuLyLuHanhNoiDia;
    onHandleThaoTac: (id: number, loai: string) => void;
}
const ThaoTacQuanLyGiayPhepLHND = (props: ThaoTacQuanLyGiayChungChiDSVHProps) => {
    const [open, setOpen] = React.useState(false);
    // const [data, setData] = React.useState<HoSoThuLyDiSanVanHoa>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    let history = useHistory();

    useEffect(() => {}, []);

    const showHidePopper = async () => {
        setOpen(!open);
    };

    const handleClosePopper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const clickEdit = () => {};

    const handleThaoTac = (loai: string) => {
        props.onHandleThaoTac(props.data?.giayPhepId, loai);
    };

    return (
        <>
            <Button
                startIcon={<ListIcon />}
                size="small"
                color="primary"
                disableRipple
                aria-haspopup="true"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                onClick={(e) => {
                    showHidePopper();
                }}
            ></Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopper}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={(e) => setOpen(false)}>
                                    {props.data?.maHoSo === "" || props.data?.maHoSo === null ? (
                                        <MenuItem key={"menu-item-edit"} style={{ padding: "unset" }} onClick={(e) => clickEdit()}>
                                            <Button
                                                onClick={(e) => handleThaoTac("ChinhSua")}
                                                startIcon={<EditIcon color="primary" />}
                                                size="small"
                                                disableRipple
                                            >
                                                Chỉnh sửa
                                            </Button>
                                        </MenuItem>
                                    ) : (
                                        ``
                                    )}
                                    <MenuItem key={"menu-item-huy"} style={{ padding: "unset" }}>
                                        <Button
                                            startIcon={<VisibilityIcon style={{ color: "green" }} />}
                                            size="small"
                                            disableRipple
                                            onClick={(e) => handleThaoTac("ChiTiet")}
                                        >
                                            Xem chi tiết
                                        </Button>
                                    </MenuItem>
                                    <MenuItem key={"menu-item-thuhoi"} style={{ padding: "unset" }}>
                                        <Button
                                            startIcon={<VisibilityIcon style={{ color: "green" }} />}
                                            size="small"
                                            disableRipple
                                            onClick={(e) => handleThaoTac("TamNgung")}
                                        >
                                            Tạm ngưng hoạt động
                                        </Button>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
export default ThaoTacQuanLyGiayPhepLHND;
