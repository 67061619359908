import {
    Box,
    Button,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    NativeSelect,
    Snackbar,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Alert, Autocomplete, Pagination } from "@material-ui/lab";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DanhMucService } from "apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "apis/danhMuc/model/DanhMucDTO";
import { CapSoDichVuDuLichService } from "apis/DichVuDuLich/CapSoDichVuDuLichService";
import { HoSoThuLyDichVuDuLich_INIT } from "../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLich } from "../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { LoadingAction } from "../../../redux/actions/applicationAction";
import StringUtil from "../../../utils/stringUtils";
import { bootstrapTextFieldHook } from "../../commons/input/bootstrap";
import { CustomInput } from "../../commons/input/customInput";
import { Cell, Row } from "../../commons/table/tableCommons";
import { CapSoDichVuDuLichModal } from "./CapSoDichVuDuLichModal";

const CapSoDichVuDuLichComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [total, setTotal] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState<HoSoThuLyDichVuDuLich[]>([]);
    const [search, setSearch] = useState<HoSoThuLyDichVuDuLich>({
        ...HoSoThuLyDichVuDuLich_INIT,
        pageSize: 5,
        pageNumber: 1,
    });
    const [indexRow, setIndexRow] = useState(-1);
    const [dataRow, setDataRow] = useState({ ...HoSoThuLyDichVuDuLich_INIT } as HoSoThuLyDichVuDuLich);
    const service = new CapSoDichVuDuLichService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    useEffect(() => {
        doSearch(search);
        loadQuans("92");
    }, []);
    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadPhuongs = (maQuan: string) => {
        if (!StringUtil.isNullOrEmty(maQuan)) {
            danhMucService
                .getDanhMucDiaChi(maQuan)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    };
    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSearch({
            ...search,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSearch({ ...search, [nameValue]: value?.code, [nameText]: valueText });
    };
    const doSearch = (searchRequest: HoSoThuLyDichVuDuLich) => {
        service.danhSachCapSo(searchRequest)
            .then((response) => {
                let result = response.data;
                if (result && result.data) {
                    setData(result.data.data !== undefined ? result.data.data : []);
                    setTotal(result.data.total !== undefined ? result.data.total : 0);
                } else {
                    setData([]);
                    setTotal(0);
                }
            })
            .catch((error) => {
                setData([]);
                setTotal(0);
                console.log(error);
            });
    };
    const setParamSearch = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearch((search) => ({
            ...search,
            [name]: value,
        }));
    };
    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSearch(searchNew);
        doSearch(searchNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageNumber: 1, pageSize: Number(event.target.value) };
        setSearch(searchNew);
        doSearch(searchNew);
    };

    const handSearch = () => {
        let searchNew = { ...search, pageNumber: 1, pageSize: 5 };
        setSearch(searchNew);
        doSearch(searchNew);
    };
    const handleToggle = () => {
        setShowModal(!showModal)
    };
    const handleSubmit = (success: boolean, mess: string) => {
        if (success) {
            setMessage(mess);
            setSuccess(true);
            handleToggle();
            handSearch();
        } else {
            setMessage(mess);
            setSuccess(false);
        }
        setShowAlert(true);
    };
    const clickCapSo = (item: HoSoThuLyDichVuDuLich, index: number) => {
        setDataRow(item);
        setIndexRow(index);
        setShowModal(true);
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>CẬP NHẬT SỐ</span>
            </div>
            <CapSoDichVuDuLichModal indexEdit={indexRow} data={dataRow} onToggle={handleToggle} show={showModal} onSubmit={handleSubmit} />
            <Snackbar open={showAlert} onClose={() => setShowAlert(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <Alert onClose={() => setShowAlert(false)} severity={success ? "success" : "error"}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={6} className="gridItem">
                    <InputLabel>Tên cơ sở</InputLabel>
                    <InputBase fullWidth name="tenCoSo" value={search.tenCoSo} onChange={setParamSearch} inputProps={{ "aria-label": "description" }} />
                </Grid>
                <Grid item xs={6} className="gridItem">
                    <InputLabel>Mã hồ sơ</InputLabel>
                    <InputBase fullWidth name="maHoSo" value={search.maHoSo} onChange={setParamSearch} inputProps={{ "aria-label": "description" }} />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles} >
                        Huyện/Thị/TP
                    </InputLabel>
                    <Autocomplete
                        id="maHuyen"
                        options={quans}
                        getOptionLabel={(option) => option?.name}
                        value={{ code: search.maHuyen, name: search.tenHuyen }}
                        onChange={(event: any, newValue: any) => {
                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles} >
                        Phường/Xã/TT{" "}
                    </InputLabel>
                    <Autocomplete
                        id="maPhuong"
                        options={phuongs}
                        getOptionLabel={(option) => option.name}
                        value={{ code: search.maPhuong, name: search.tenPhuong }}
                        onFocus={(e) => loadPhuongs(search.maHuyen)}
                        onChange={(event: any, newValue: any) => {
                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} className="button-group-center">
                    <Button variant="contained" color="primary" disableElevation onClick={handSearch}>
                        Tìm kiếm
                    </Button>
                </Grid>

                <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">
                                    STT
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    MÃ HỒ SƠ
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    TÊN CƠ SỞ
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="12%">
                                    SỐ QUYẾT ĐỊNH
                                </Cell>
                                <Cell component="th" scope="row" align="center">
                                    ĐỊA CHỈ
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="8%">
                                    THAO TÁC
                                </Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {total > 0 ? (
                                data.map((row, i) => (
                                    <Row key={i}>
                                        <Cell scope="row" align="center">
                                            {(search.pageNumber - 1) * search.pageSize + i + 1}
                                        </Cell>
                                        <Cell align="left">{row.maHoSo}</Cell>
                                        <Cell align="left">{row.tenCoSo}</Cell>
                                        <Cell align="left">
                                            {row.soQuyetDinh}
                                        </Cell>
                                        <Cell align="left">{row.diaChiDayDu}</Cell>
                                        <Cell align="center">
                                            <Box display="flex" justifyContent="center">
                                                <IconButton onClick={(event) => clickCapSo(row, i)} color="primary" aria-label="edit" size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            </Box>
                                        </Cell>
                                    </Row>
                                ))
                            ) : (
                                <Row key={"empty"}>
                                    <Cell colSpan={7} scope="row" align="center">
                                        Không có dữ liệu
                                    </Cell>
                                </Row>
                            )}
                        </TableBody>
                    </Table>
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination
                            count={Math.ceil(total / search.pageSize)}
                            page={search.pageNumber}
                            defaultPage={1}
                            siblingCount={1}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={handleOnChangePage}
                        />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">
                                Số mẫu tin trên trang
                            </Box>
                            <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">
                            Mẫu tin {(search.pageNumber - 1) * search.pageSize + 1} - {search.pageNumber * search.pageSize} / {total}
                        </Box>
                    </Box>
                </TableContainer>
            </Grid>
        </div>
    );
};
export default CapSoDichVuDuLichComponent;
