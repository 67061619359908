import { FileEntry } from "../../apis/Common/fileEntry";
import { GiayChungChiDiSanVanHoa, LichSuTuBoDiTich } from "../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoa";

export const GiayChungChiDiSanVanHoa_INIT: GiayChungChiDiSanVanHoa = {
    id: 0,

    nguoiTaoId: 0,

    hoSoThuLyId: 0,

    donViCapSoGiayPhep: false,

    soChungChi: "",

    soChungChiMoi: "",

    ngayCapChungChi: 0,

    ngayCapChungChiMoi: 0,

    ngayHetHan: 0,

    ngayCapCMND: 0,

    ngayTao: 0,

    ngayCapNhat: 0,

    ngayHetHanMoi: 0,

    lanCapLai: 0,

    ngayCapLai: 0,

    ngaySinh: 0,

    ngayThuHoi: 0,

    soCMND: "",

    noiCapCMND: "",

    hoTen: "",

    noiSinh: "",

    maQuocTich: "",

    tenQuocTich: "",

    maTinh: "",

    tenTinh: "",

    maHuyen: "",

    tenHuyen: "",

    maPhuong: "",

    tenPhuong: "",

    tenDuong: "",

    maDuong: "",

    soNha: "",

    trinhDoChuyenMon: "",

    kinhNghiemChuyenMon: "",

    duocPhepHanhNghe: "",

    canCu: "",

    deNghi: "",

    maTrangThai: "",

    tenTrangThai: "",

    maBienDong: "",

    tenBienDong: "",

    diaChiDayDu: "",

    lyDoCapLai: "",

    lyDoThuHoi: "",

    maHoSo: "",

    maThuTuc: "",

    className: "",

    ghiChu: "",

    lichSuTuBoDiTichs: new Array<LichSuTuBoDiTich>(),

    files: new Array<FileEntry>(),

    fileCapMois: new Array<FileEntry>(),

    fileThuHois: new Array<FileEntry>(),

    fileCapLais: new Array<FileEntry>(),

    hoatDong: true,

    isCapSoMoi: false,
};
