import AXIOS_INSTANCE from "..";
import { TraCuuCoSoDuLichDTO } from "../../model/DichVuDuLich/DichVuDuLichModel";
import { HoSoThuLyDichVuDuLich, LichSuDichVuDuLichDTO } from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { BaseService } from "../baseService";

export class GiayChungNhanLuuTruService extends BaseService {
    chiTiet = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/chi-tiet", requestBody, this.getTokenRequestHeaders());
    };
    getDanhSachQuanLy = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/danh-sach-quan-ly", requestBody, this.getTokenRequestHeaders());
    };

    exportTraCuu = (request: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/export-tra-cuu", request, {
            responseType: "blob",
        });
    };
    getDanhSachBaoCao = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/bao-cao", requestBody, this.getTokenRequestHeaders());
    };

    exportBaoCao = (request: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/export-bao-cao", request, {
            responseType: "blob",
        });
    };

    thongKe = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/thong-ke", requestBody, this.getTokenRequestHeaders());
    };

    exportThongKe = (request: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/export-thong-ke", request, {
            responseType: "blob",
        });
    };
    loadBieuDo = (request: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/bieu-do", request);
    };

    chiTietGiayChungNhanById(giayChungNhanId: number) {
        let body = {
            giayChungNhanId: giayChungNhanId,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/luu-tru/get-by-id", {
            params: body,
        });
    }

    chiTietGiayTamNgung(giayChungNhanId: number, classNameGCN: string) {
        let body = {
            giayChungNhanId: giayChungNhanId,
            classNameGCN: classNameGCN,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/luu-tru/chi-tiet-tam-ngung", {
            params: body,
        });
    }

    thuHoi = (thuHoi: LichSuDichVuDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/save-or-update-tam-ngung", thuHoi, this.getTokenRequestHeaders());
    };

    chiTietThongKe = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/chi-tiet-thong-ke", requestBody, this.getTokenRequestHeaders());
    };

    thongKeBieuDoTron = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/bieu-do-tron", requestBody, this.getTokenRequestHeaders());
    };
    
    thongKeBieuDoTronTheoHang = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/luu-tru/bieu-do-tron-theo-hang", requestBody, this.getTokenRequestHeaders());
    };
}
