import AXIOS_INSTANCE from "..";
import { RequestBieuDoThongKeDTO } from "../../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/BieuDoThongKeCSKDTheThao";
import { RequestThongKeDTO } from "../../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/ThongKeTinhHinhCSKDTheThao";
import { ThongKeTinhHinhCSKDResponse } from "../../components/HoSoThuLyDichVuDuLich/model/BaoCaoThongKeCSKDDTO";
import { LichSuThuLyDichVuDuLich } from "../../components/HoSoThuLyDichVuDuLich/model/lichSuThuLyDichVuDuLich";
import { HoSoThuLyDichVuDuLich_INIT } from "../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLich, HoSoThuLyDichVuDuLichSearchRequest } from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { BaseService } from "../baseService";

export class GiayChungNhanGiaiTriService extends BaseService {
    getListDichVuDuLichVuiChoiGiaiTri = (requestBody: HoSoThuLyDichVuDuLichSearchRequest) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-giai-tri/get-list-data-dich-vu-vui-choi-giai-tri", requestBody);
    };

    downloadFileCSKDVuiChoiGiaiTri = (request: HoSoThuLyDichVuDuLichSearchRequest) => {
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-giai-tri/export-file-vui-choi-giai-tri", {
            responseType: "blob",
            params: request,
        });
    };
    getDetailDichVuVuiChoiGiaiTri = async (id: number): Promise<HoSoThuLyDichVuDuLich> => {
        var result: HoSoThuLyDichVuDuLich = HoSoThuLyDichVuDuLich_INIT;
        await AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-giai-tri/get-chi-tiet-dich-vu-vui-choi-giai-tri", { id: id }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    result = { ...response.data.data };
                }
            })
            .catch();
        return result;
    };

    chiTietGiayChungNhanById(giayChungNhanId: number) {
        let body = {
            giayChungNhanId: giayChungNhanId,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-giai-tri/get-by-id", {
            params: body,
        });
    }

    chiTietGiayTamNgung(giayChungNhanId: number, classNameGCN: string) {
        let body = {
            giayChungNhanId: giayChungNhanId,
            classNameGCN: classNameGCN,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-giai-tri/chi-tiet-tam-ngung", {
            params: body,
        });
    }

    getLichSuDichVuDuLichVuiChoiGiaiTri = async (id: number, classNameGCN: string): Promise<{ rows: LichSuThuLyDichVuDuLich[]; total: number }> => {
        var list: LichSuThuLyDichVuDuLich[] = [];
        var total: number = 0;
        await AXIOS_INSTANCE.post(
            "dich-vu-du-lich/gcn-giai-tri/get-lich-su-dich-vu-du-lich-vui-choi-giai-tri",
            { id: id, classNameGCN: classNameGCN },
            this.getTokenRequestHeaders()
        )
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    list = response.data.data.data;
                    total = response.data.data.data;
                }
            })
            .catch();
        return { rows: list, total: total };
    };

    thongKeCSKDGiaiTri = async (request: RequestThongKeDTO): Promise<ThongKeTinhHinhCSKDResponse> => {
        let result = {} as ThongKeTinhHinhCSKDResponse;
        await AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-giai-tri/thong-ke-tinh-hinh-cskd-giai-tri", request, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    downloadFileThongKeCSKDGiaiTri = (request: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-giai-tri/export-thong-ke-cskd-giai-tri", {
            responseType: "blob",
            params: request,
        });
    };
    bieuDoThongkeCSKDGiaiTri = (requestBody: RequestBieuDoThongKeDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-giai-tri/bieu-do-thong-ke-cskd-giai-tri", requestBody);
    };

    thuHoi = (thuHoi: LichSuThuLyDichVuDuLich) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-giai-tri/save-or-update-thu-hoi", thuHoi, this.getTokenRequestHeaders());
    };

    chiTietThongKe = (request: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-giai-tri/chi-tiet-thong-ke", request);
    };
}
