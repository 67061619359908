import AXIOS_INSTANCE from "..";
import { TraCuuCoSoDuLichDTO } from "../../model/DichVuDuLich/DichVuDuLichModel";
import { BaseService } from "../baseService";

export interface DashboardDiSanDTO {
    thang: number;
    vatThe: number;
    phiVatThe: number;
    tenThang: string;
}
export interface DashboardDiSanRequest {
    namThongKe: number;
    tenNamThongKe : string;
}
export class DashboardDiSanService extends BaseService {
    loadDataDashboard = (nam: number) => {
        return AXIOS_INSTANCE.post("di-san/dashboard/load-data", { nam: nam });
    };


}
