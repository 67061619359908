import { Button, ClickAwayListener, Grow, Paper, Popper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import StopIcon from '@material-ui/icons/Stop';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { TINHTRANG_CHAMDUT_DIADIEMDULICH, TINHTRANG_HOATDONG_DIADIEMDULICH, TINHTRANG_TAMNGUNG_DIADIEMDULICH } from "constants/DiaDiemDuLichConstants";
import { HoSoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO";
import React, { useRef } from "react";
import { useHistory } from "react-router";
import { ROUTE_TAMNGUNG_KDL, ROUTE_CHI_TIET_KDL, ROUTE_CN_KDL, ROUTE_NHAP_DAU_KY_KDL, ROUTE_CHAMDUT_KDL } from "routes/route_khudulich";
import StringUtil from "utils/stringUtils";

interface ThaoTacQuanLyCoSoLuuTruProps {
    data: HoSoDiaDiemDuLichDTO;
}
const ThaoTacKhuDuLichComponent = (props: ThaoTacQuanLyCoSoLuuTruProps) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    let history = useHistory();
    const showHidePopper = async () => {
        setOpen(!open);
    };

    const handleClosePopper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    
    const handleEdit = () => {
        if(!StringUtil.isNullOrEmty(props?.data?.maHoSo) && props?.data?.maTinhTrang == TINHTRANG_HOATDONG_DIADIEMDULICH.ma){
            history.push(ROUTE_CN_KDL[0].duongDan, {maHoSo : props?.data?.maHoSo});
        }else if(StringUtil.isNullOrEmty(props?.data?.maHoSo) && props?.data?.maTinhTrang == TINHTRANG_HOATDONG_DIADIEMDULICH.ma){
            history.push(ROUTE_NHAP_DAU_KY_KDL.duongDan, {hoSoDiaDiemId : props?.data?.id });
        }else if(props?.data?.maTinhTrang == TINHTRANG_TAMNGUNG_DIADIEMDULICH.ma){
            history.push(ROUTE_TAMNGUNG_KDL.duongDan, {hoSoDiaDiemId : props?.data?.id });
        }else{
            history.push(ROUTE_CHAMDUT_KDL.duongDan, {hoSoDiaDiemId : props?.data?.id });
        }
    };
    
    const handleView = () => {
        history.push(ROUTE_CHI_TIET_KDL.duongDan, {hoSoDiaDiemId : props?.data?.id});
    };
    
    const handleTamNgung = () => {
        history.push(ROUTE_TAMNGUNG_KDL.duongDan, {hoSoDiaDiemId : props?.data?.id });
    };
    
    const handleChamDut = () => {
        history.push(ROUTE_CHAMDUT_KDL.duongDan, {hoSoDiaDiemId : props?.data?.id });
    };

    return (
        <>
            <Button
                startIcon={<ListIcon />}
                size="small"
                color="primary"
                disableRipple
                aria-haspopup="true"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                onClick={(e) => {
                    showHidePopper();
                }}
            ></Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopper}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={(e) => setOpen(false)}>
                                    <MenuItem key={"menu-item-edit"} style={{ padding: "unset" }}>
                                        <Button onClick={(e) => handleEdit()} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                            Chỉnh sửa
                                        </Button>
                                    </MenuItem>
                                    {(props?.data?.maTinhTrang == TINHTRANG_CHAMDUT_DIADIEMDULICH.ma || props?.data?.maTinhTrang == TINHTRANG_HOATDONG_DIADIEMDULICH.ma)&&
                                        <>
                                            <MenuItem key={"menu-item-tamngung"} style={{ padding: "unset" }} onClick={(e) => handleTamNgung()}>
                                                <Button startIcon={<PauseCircleOutlineIcon color="secondary" />} size="small" disableRipple>
                                                    Tạm ngưng hoạt động
                                                </Button>
                                            </MenuItem>
                                        </>

                                    }
                                    {(props?.data?.maTinhTrang == TINHTRANG_TAMNGUNG_DIADIEMDULICH.ma ||  props?.data?.maTinhTrang == TINHTRANG_HOATDONG_DIADIEMDULICH.ma)&& 
                                        <>
                                            <MenuItem key={"menu-item-cham-dut"} style={{ padding: "unset" }} onClick={(e) => handleChamDut()}>
                                                <Button startIcon={<StopIcon color="secondary" />} size="small" disableRipple>
                                                    Chấm dứt
                                                </Button>
                                            </MenuItem>
                                        </>
                                    }
                                   
                                    <MenuItem key={"menu-item-view"} style={{ padding: "unset" }}>
                                        <Button
                                            startIcon={<VisibilityIcon style={{ color: "green" }} />}
                                            size="small"
                                            disableRipple
                                            onClick={(e) => handleView()}
                                        >
                                            Xem chi tiết
                                        </Button>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
export default ThaoTacKhuDuLichComponent;
