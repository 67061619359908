import MomentUtils from "@date-io/moment";
import { Box, Button, Checkbox, ClickAwayListener, createStyles, Grid, Grow, InputLabel, makeStyles, MenuItem, MenuList, Paper, Popper, Snackbar, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../apis";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { GiayChungNhanDiSanVanHoaService } from "../../../../apis/DiSanVanHoa/GiayChungNhanDiSanVanHoaService";
import { HoSoThuLyDiSanVanHoaService } from "../../../../apis/DiSanVanHoa/HoSoThuLyDiSanVanHoaService";
import { ChuyenXuLyRequest, HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import { ThongTinDungChungService } from "apis/hoSoCuaToi/thongTinDungChungService";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import { ACCEPT_FILES, LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from "../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDiSanVanHoa_INIT } from "../../../../init/DiSanVanHoa/HoSoThuLyDiSanVanHoa_INIT";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { ThongTinChuyenNhanhUtil } from "../../../../utils/thongTinChuyenNhanhUtil";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import ThuLyButtonComponent from "../../../commons/hoso/thuly/ThuLyButtonComponent";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import DanhSachDuThaoModal from "../../../commons/hoso/thuly/danhSachDuThaoModal";
import DropZoneCustom from "../../../commons/hoso/CustomDropzone/DropZoneCustom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: "none",
        },
        sizeSmall: {
            fontSize: "13px",
        },
        startIcon: {
            marginRight: theme.spacing(0.5),
        },
        "&:focus": {
            outlineWidth: "0px",
            outlineStyle: "none",
        },
    })
)(Button);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
        margin: {
            margin: theme.spacing(0.5),
        },
    })
);

type ErrorValidation = {
    soGiayChungNhan: string;
    ngayCap: string;
    tenToChuc: string;
    maHuyen: string;
    maPhuong: string;
    soQD: string;
    soCMND: string;
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CapGCNTuBoDiTichComponet = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [currentDate, setCurrentDate] = useState(0);
    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [title, setTitle] = useState<string>("NHẬP ĐẦU KỲ GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH");
    const [titleChinhSua, setTitleChinhSua] = useState<string>("CHỈNH SỬA GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH");
    const [data, setData] = useState<HoSoThuLyDiSanVanHoa>({ ...HoSoThuLyDiSanVanHoa_INIT, className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa" });
    const classes = useStyles();
    const hoSoThuLyDiSanVanHoaService = new HoSoThuLyDiSanVanHoaService();
    const service = new GiayChungNhanDiSanVanHoaService();
    const fileServerService = new FileServerService();
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [showAlert, setShowAlert] = useState(false);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [showChuyen, setShowChuyen] = useState(false);
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const history = useHistory<any>();
    const thongTinDungChungService = new ThongTinDungChungService();
    const [open, setOpen] = useState(false);
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);

    useEffect(() => {
        initData();
        loadQuans("92");
    }, []);

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(data.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };
    
    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const initData =  async () => {
        let id = history?.location?.state?.params;
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        let soChungNhan = location.state?.soChungNhan;
        let update = location?.state?.isUpdate;
        let soChungNhanId = location.state?.soChungNhanId;
        let show = location.state?.showChuyen;
        setShowChuyen(show);
        dispatchLoading(showLoading());
        setCurrentDate(StringUtil.dateSecond());

        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        hoSoCuaToiMgtService.chiTiet(location.state?.maHoSo).then((p) => {
            if (p.data && p.data.data) {
                hoTenNguoiNop = p.data.data.nguoiNop;
                soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
        
        if (update) {
            await service
                .getBySoGiayChungNhanDiSanVanHoas(soChungNhanId)
                .then((res) => {
                    const dataNew = {
                        ...res?.data?.data,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
                        isUpdate: update,
                        resoucePK: res?.data?.data.id,
                        id: res?.data?.data.hoSoThuLyId,
                        nhapDauKy: true,
                    };
                    setData(dataNew);
                    setIsNhapDauKy(true);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (maHS) {
            
            
            await hoSoThuLyDiSanVanHoaService
                .getByMaHoSo(maHS)
                .then(async(res) => {
                    if (StringUtil.hasData(res)) {
                        const dataNew = {
                            ...res?.data?.data,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
                        };
                        setData(dataNew);
                    } else {
                        let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHS);
                        let maHuyen ="", tenHuyen = "", maPhuong = "", tenPhuong = "", noiCapCM="";
                        console.log("DataEform", dataEform);
                        
                        if(!StringUtil.isNullOrEmty(dataEform?.quanhuyen)){
                            let donViHanhChinh  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                            maHuyen = donViHanhChinh?.code;
                            tenHuyen = donViHanhChinh?.name;
                        }
                        if(!StringUtil.isNullOrEmty(dataEform?.xaphuong)){
                            let donViHanhChinhCap3  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                            maPhuong = donViHanhChinhCap3?.code;
                            tenPhuong = donViHanhChinhCap3?.name;
                        }
                        if (!StringUtil.isNullOrEmty(dataEform?.noicapcmnd)) {
                            let noiCap  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.noicapcmnd);
                            noiCapCM = noiCap?.name;
                        }
                        const dataNew = {
                            ...HoSoThuLyDiSanVanHoa_INIT,
                            maHoSo: maHS,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            maThuTuc: maThuTuc,
                            className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
                            // nhapDauKy: true,
                            soCMND :  dataEform?.cmnd ? dataEform?.cmnd : HoSoThuLyDiSanVanHoa_INIT.soCMND,
                            noiCapQD :  dataEform?.noicapdkkd ? dataEform?.noicapdkkd : HoSoThuLyDiSanVanHoa_INIT.noiCapQD,
                            ngayCapCMND : dataEform?.ngaycapcmnd ? StringUtil.stringDateToNumber(dataEform?.ngaycapcmnd) : HoSoThuLyDiSanVanHoa_INIT.ngayCapCMND,
                            ngayCapQD : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : HoSoThuLyDiSanVanHoa_INIT.ngayCapQD,
                            // ngaySinh : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaythangsinh) : HoSoThuLyDiSanVanHoa_INIT.ngaySinh,
                            giaydkkd : dataEform?.giaydkkd,
                            soDienThoai : dataEform?.sdt,
                            fax : dataEform?.fax,
                            chucDanh : dataEform?.chucvunguoidaidien,
                            tenToChuc : dataEform?.tendoanhnghiep,
                            dienThoai : soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt,
                            soQD: dataEform?.giaydkkd,
                            nguoiDaiDienTheoPhapLuat: dataEform?.hotennguoidaidien,
                            maHuyen : !StringUtil.isNullOrEmty(maHuyen) ? maHuyen :  HoSoThuLyDiSanVanHoa_INIT?.maHuyen,
                            tenHuyen : !StringUtil.isNullOrEmty(tenHuyen) ? tenHuyen : HoSoThuLyDiSanVanHoa_INIT?.tenHuyen,
                            maPhuong : !StringUtil.isNullOrEmty(maPhuong) ? maPhuong :HoSoThuLyDiSanVanHoa_INIT?.maPhuong,
                            tenPhuong : !StringUtil.isNullOrEmty(tenPhuong) ? tenPhuong : HoSoThuLyDiSanVanHoa_INIT?.tenPhuong,
                            noiCapCMND : dataEform?.noicapcmnd,
                            tenDuong: dataEform?.diachi,
                            
                        };
                        setData(dataNew);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else {
            const dataNew = {
                ...HoSoThuLyDiSanVanHoa_INIT,
                maHoSo: maHS,
                maTrangThai: TINHTRANG_HOATDONG.ma,
                tenTrangThai: TINHTRANG_HOATDONG.ten,
                maBienDong: LOAIBIENDONG_CAPMOI.ma,
                tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                maThuTuc: maThuTuc,
                className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
                nhapDauKy: true,
            };
            setData(dataNew);
            setIsNhapDauKy(true);
            dispatchLoading(hideLoading());
        }
    };

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);

        if (data.donViCapSoGiayPhep) {
            if (StringUtil.isNullOrEmty(data.soChungNhan)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.ngayCapChungNhan)) {
                return;
            }
        }
        if (data.ngayCapChungNhan > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (isNhapDauKy) {
            if (StringUtil.isNullOrEmty(data.ngayHetHan)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.soChungNhan)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.ngayCapChungNhan)) {
                return;
            }
        }
        if (StringUtil.isNullOrEmty(data.tenToChuc)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHuyen)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuong)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.soQD)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.soCMND)) {
            return;
        }
        if ((!StringUtil.isNullOrEmty(data.soCMND) && data.soCMND.length < 9) || data.soCMND.length <= 0 || data.soCMND.length > 12) {
            setAlert({
                showAlert: true,
                message: "Số CMND phải từ 9 đến 12 số",
                severity: "warning",
            });
            return;
        }
        if (data.ngayCapQD > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp QD không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (data.ngayCapCMND > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp CMND không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (data.ngaySinh > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày sinh không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHoSo) && !(isNhapDauKy || data.isUpdate)) {
            setAlert({
                showAlert: true,
                message: "Không có mã hồ sơ",
                severity: "warning",
            });
            return;
        }
        setSubmit(false);
        dispatchLoading(showLoading());
        hoSoThuLyDiSanVanHoaService
            .insertOrUpdate(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setData({ ...response.data.data });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else if (response.data.error_code === "409") {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: false,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let tenValue = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: tenValue,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {};

    const handleDelete = () => {
        setOpenDeleteUserDlg(true);
    }

    const handleResetData = () => {
        const dataNew = {
            ...HoSoThuLyDiSanVanHoa_INIT,
            id: data.id,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            resoucePK: data.resoucePK,
            className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
        };
        setData(dataNew);
    };

    useEffect(() => {
        if (data.maHoSo) {
            setTitle(`CẤP GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH - MÃ HỒ SƠ ${data.maHoSo}`);
        }
    }, [data?.maHoSo]);
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };

    const loadPhuongs = () => {
        if(data?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(data?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        hoSoThuLyDiSanVanHoaService.downloadFileThuLy({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
        });
    };

    async function handleSearchDoanhNghiep() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinDoanhNghiep(data.soQD)
        .then((res) => { 
            console.log("Res", res);
            
            let tenNguoiDaiDien = "";
            for (let index = 0; index < res?.data?.data?.data?.listThanhVien.length; index++) {
                if (res?.data?.data?.data?.listThanhVien[index].isNguoiDaiDienPhapLuat=="1"){
                    tenNguoiDaiDien=res?.data?.data?.data?.listThanhVien[index].hoTen;
                };
            }
            
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.maSoDoanhNghiep) {
                setData({ ...data,
                    ngayCapQD : res?.data?.data?.data?.ngayThanhLap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.ngayThanhLap) : data?.ngayCapQD,
                    tenToChuc: res?.data?.data?.data?.tenDoanhNghiep_VN,
                    dienThoai: res?.data?.data?.data?.dienThoai,
                    nguoiDaiDienTheoPhapLuat: tenNguoiDaiDien, 
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số đăng ký doanh nghiệp không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    async function handleSearchCMND() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinCongDan(data?.soCMND)
        .then((res) => { 
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.soCMND) {
                setData({ ...data,
                    ngayCapCMND: res?.data?.data?.data?.cmt_NgayCap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.cmt_NgayCap) : data?.ngayCapCMND,
                    noiCapCMND: res?.data?.data?.data?.cmt_NoiCap ? res?.data?.data?.data?.cmt_NoiCap : "",
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số căn cước/CMND không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>

                    <Grid container item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>{title} </h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {!isNhapDauKy && (
                                            <>
                                                {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                                    <Grid item xs={3} className="grid-item">
                                                        <Checkbox
                                                            checked={data.donViCapSoGiayPhep}
                                                            name="donViCapSoGiayPhep"
                                                            value={data.donViCapSoGiayPhep}
                                                            onChange={handleChange}
                                                        />{" "}
                                                        Đơn vị cấp số giấy phép
                                                    </Grid>
                                                ) : (
                                                    ``
                                                )}
                                            </>
                                        )}
                                        {data.donViCapSoGiayPhep || isNhapDauKy ? (
                                            <>
                                                <Grid item xs={3} className="grid-item">
                                                    <TextField
                                                        fullWidth
                                                        label={"Số giấy chứng nhận"}
                                                        required
                                                        name="soChungNhan"
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        value={data.soChungNhan}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                        onChange={handleChange}
                                                        error={submit && StringUtil.isNullOrEmty(data.soChungNhan)}
                                                        helperText={submit && StringUtil.isNullOrEmty(data.soChungNhan) ? "Vui lòng nhập số chứng chỉ" : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Ngày cấp
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayCapChungNhan && data.ngayCapChungNhan != 0 ? new Date(data.ngayCapChungNhan) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungNhan")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayCapChungNhan)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.ngayCapChungNhan) ? "Vui lòng nhập ngày" : ""}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                                    <Grid item xs={3} className="grid-item">
                                                        <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                autoOk={true}
                                                                showTodayButton={true}
                                                                value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                                format="DD/MM/YYYY"
                                                                fullWidth
                                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                                variant="inline"
                                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={3} className="grid-item">
                                                        <InputLabel classes={labelStyles} required>
                                                            Ngày hết hạn
                                                        </InputLabel>
                                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                autoOk={true}
                                                                showTodayButton={true}
                                                                value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                                format="DD/MM/YYYY"
                                                                fullWidth
                                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                                variant="inline"
                                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                                error={submit && StringUtil.isNullOrEmty(data.ngayHetHan)}
                                                                helperText={
                                                                    submit && StringUtil.isNullOrEmty(data.ngayHetHan) ? "Vui lòng nhập ngày hết hạn" : ""
                                                                }
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        style={{ width: "90%" }}
                                        label={"Số QĐ/ GCNĐKDN/ GCNĐKKD"}
                                        name="soQD"
                                        required
                                        InputLabelProps={{ classes: labelStyles }}
                                        onChange={handleChange}
                                        value={data.soQD}
                                        error={submit && StringUtil.isNullOrEmty(data.soQD)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        helperText={submit && StringUtil.isNullOrEmty(data?.soQD) ? "Vui lòng nhập số QĐ/ GCNĐKDN/ GCNĐKKD" : ""}
                                    />
                                    <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "30px", marginLeft: "3px" }}  onClick={handleSearchDoanhNghiep}/>
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapQD && data.ngayCapQD !== 0 ? new Date(data.ngayCapQD) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapQD")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Nơi cấp"}
                                        name="noiCapQD"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.noiCapQD}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên tổ chức"}
                                        required
                                        name="tenToChuc"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.tenToChuc}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.tenToChuc)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.tenToChuc) ? "Vui lòng nhập tên tổ chức" : ""}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Điện thoại"}
                                        name="dienThoai"
                                        onChange={handleChange}
                                        value={data.dienThoai}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Fax"}
                                        name="fax"
                                        onChange={handleChange}
                                        value={data.fax}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Huyện/Thị/TP{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maHuyen"
                                        classes={inputStyles}
                                        options={quans}
                                        getOptionLabel={(option) => option?.name}
                                        value={{ code: data.maHuyen, name: data.tenHuyen }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn huyện/Thị/TP" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Phường/Xã/TT{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maPhuong"
                                        classes={inputStyles}
                                        options={phuongs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maPhuong, name: data.tenPhuong }}
                                        onFocus={loadPhuongs}
                                        noOptionsText={"Không có Phường/Xã/TT"}        
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maPhuong) ? "Vui lòng chọn phường/Xã/TT" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Đường"}
                                        name="tenDuong"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.tenDuong}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                    {/* <InputLabel>Đường </InputLabel>
                                    <Autocomplete
                                        id="maDuong"
                                        classes={inputStyles}
                                        options={DUONG}
                                        getOptionLabel={(option) => option.ten}
                                        value={{ ma: data.maDuong, ten: data.tenDuong }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelect(newValue, "maDuong", "tenDuong");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    /> */}
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số nhà"}
                                        name="soNha"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soNha}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <Box mt="5px" bgcolor="#f2f2f2">
                                        <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                            <CustomTab label="Thông tin người đại diện" />
                                        </CustomTabs>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Người đại diện theo pháp luật"}
                                        name="nguoiDaiDienTheoPhapLuat"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.nguoiDaiDienTheoPhapLuat}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày sinh</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngaySinh && data.ngaySinh != 0 ? new Date(data.ngaySinh) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngaySinh")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Chức danh"}
                                        name="chucDanh"
                                        value={data.chucDanh}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        style={{ width: "90%" }}
                                        label={"Số CMND/ Số định danh"}
                                        name="soCMND"
                                        required
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soCMND}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.soCMND)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        helperText={submit && StringUtil.isNullOrEmty(data?.soCMND) ? "Vui lòng nhập số CMND/ Số định danh" : ""}
                                    />
                                    <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "30px", marginLeft: "3px" }} onClick={handleSearchCMND}/>
                                </Grid>
                                <Grid item xs={3} className="grid-item" style={{ marginTop: "8px" }}>
                                    <InputLabel>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapCMND && data.ngayCapCMND != 0 ? new Date(data.ngayCapCMND) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapCMND")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Nơi cấp"}
                                        name="noiCapCMND"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.noiCapCMND}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                    <Grid item xs={12} className="grid-item">
                                        <TextField
                                            fullWidth
                                            size="medium"
                                            label={"Trích yếu"}
                                            name="trichYeu"
                                            onChange={handleChange}
                                            value={data.trichYeu}
                                            rows={3}
                                            multiline
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    ``
                                )}
                                {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                    <Grid item xs={6} className="grid-item">
                                        <TextField
                                            fullWidth
                                            size="medium"
                                            label={"Căn cứ"}
                                            name="canCu"
                                            value={data.canCu}
                                            onChange={handleChange}
                                            rows={3}
                                            multiline
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    ``
                                )}
                                {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                    <Grid item xs={6} className="grid-item">
                                        <TextField
                                            fullWidth
                                            size="medium"
                                            label={"Đề nghị"}
                                            name="deNghi"
                                            value={data.deNghi}
                                            onChange={handleChange}
                                            rows={3}
                                            multiline
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    ``
                                )}
                                {/* {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? ``
                                    : <Grid item xs={3} className="grid-item" >
                                        <InputLabel classes={labelStyles}>Tình trạng </InputLabel>
                                        <Autocomplete id="maTrangThai"
                                            classes={inputStyles}
                                            options={LIST_TINHTRANG_NHAPDAUKY}
                                            getOptionLabel={(option) => option.ten}
                                            value={{ ma: data.maTrangThai, ten: data.tenTrangThai }}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelect(newValue, "maTrangThai", "tenTrangThai")
                                            }
                                            }
                                            renderInput={(params) => (
                                                <TextField  {...params} variant="outlined" />
                                            )}
                                        />
                                    </Grid>} */}
                                {/* <Grid item xs={3} className="grid-item">
                                    {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                        ``
                                    ) : (
                                        <TextField
                                            fullWidth
                                            label={"Lần cấp lại"}
                                            name="lanCapLai"
                                            InputLabelProps={{ classes: labelStyles }}
                                            value={data.lanCapLai}
                                            onChange={handleChange}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    )}
                                </Grid> */}
                                <Grid item xs={6} className="grid-item"></Grid>

                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item"></Grid>
                            </Grid>
                        </TabPanel>
                    )}
                    <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        <ThuLyButtonComponent
                            showIn={data.id > 0 && !isNhapDauKy}
                            onSubmit={handleSubmit}
                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                            onDelete={handleDelete}
                            handleChuyen={showHidePopperThaoTac}
                            handleDuThao={showHidePopperDuThao}
                            dataButton= {data}
                        />
                         {/* <Button
                            className={classes.margin}
                            size="small"
                            color="primary"
                            disableRipple
                            ref={anchorRef}
                            aria-haspopup="true"
                            aria-controls={open ? "menu-list-grow" : undefined}
                            onClick={(e) => {
                                showHidePopperThaoTac();
                            }}>
                                Chuyển
                            </Button>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center bottom" : "center bottom" }}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                                    {buocChuyens.map((buocChuyen, k) => {
                                                        return (
                                                            <MenuItem key={k}>
                                                                <CustomButton
                                                                    className={classes.margin}
                                                                    startIcon={<ReplyIcon />}
                                                                    size="small"
                                                                    color="primary"
                                                                    disableRipple
                                                                    aria-controls={open ? "menu-list-grow" : undefined}
                                                                    onClick={() => clickBuocChuyen(buocChuyen)}
                                                                >
                                                                    {buocChuyen.tenBuocXuLy}
                                                                </CustomButton>
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper> */}
                        {/* {isNhapDauKy || data.isUpdate ? (
                            <NhapDauKyGiayChungNhanButton onSubmit={handleSubmit} onOpenDialog={handleOpenDialog}></NhapDauKyGiayChungNhanButton>
                        ) : (
                            <DiSanVanHoaButtonComponent
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onSubmit={handleSubmit}
                                onResetData={handleResetData}
                                onOpenDialog={handleOpenDialog}
                            />
                        )} */}
                    </Grid>
                    <OverlayLoading />
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
            {showDanhSachDuThaoModal && (
               <DanhSachDuThaoModal hoso={data} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}

        </>
        </div>
    );
};
export default CapGCNTuBoDiTichComponet;
