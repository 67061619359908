import { Box, Button, createStyles, Grid, InputLabel, makeStyles, Theme, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DiSanVanHoaPhiVatTheService } from "apis/DiSanVanHoa/DiSanVanHoaPhiVatTheService";
import { CustomTab, CustomTabs } from "../../../components/commons/tabs";
import { DiSanVanHoaPhiVatThe, DiSanVanHoaPhiVatThe_INT } from "../../../model/diSanVanHoaPhiVatThe";
import StringUtil from "../../../utils/stringUtils";
import { ListDinhKemComponent } from "../listDinhKemComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index?: any;
    value: any;
}

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export const ChiTietDiSanVanHoaPhiVatThe = () => {
    const leftStyle = useStylesLabelLeft();
    const rightStyle = useStylesLabelRight();
    const [indexTab, setIndexTab] = useState(0);
    const [data, setData] = useState<DiSanVanHoaPhiVatThe>({ ...DiSanVanHoaPhiVatThe_INT });
    const history = useHistory<any>();

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const getDetailById = async (id: number) => {
        await new DiSanVanHoaPhiVatTheService()
            .getDetailById(id)
            .then((p) => {
                setData(p);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    function getDiaChi(soNha: string, tenDuong: string, tenPhuong: string, tenHuyen: string) {
        var result = "";
        if (!StringUtil.isNullOrEmty(soNha)) result = result.concat(soNha);
        if (!StringUtil.isNullOrEmty(tenDuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenDuong);
            else result = result.concat(", ", tenDuong);
        }
        if (!StringUtil.isNullOrEmty(tenPhuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenPhuong);
            else result = result.concat(", ", tenPhuong);
        }
        if (!StringUtil.isNullOrEmty(tenHuyen)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenHuyen);
            else result = result.concat(", ", tenHuyen);
        }
        return result;
    }

    useEffect(() => {
        let id = history?.location?.state?.params;
        getDetailById(id);
    }, []);

    return (
        <Grid container>
            <div className="dash-content">
                <div className="dash-title">
                    <span>THÔNG TIN CHI TIẾT DI SẢN VĂN HÓA PHI VẬT THỂ</span>
                </div>
            </div>
            <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={indexTab} onChange={handleChangeTab}>
                        <CustomTab label="Thông tin chung" />
                        <CustomTab label="Tập đính kèm" />
                    </CustomTabs>
                </Box>
            </Grid>
            <TabPanel value={indexTab} index={0}>
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Số quyết định</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.soQuyetDinh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày quyết định</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.ngayQuyetDinh != null ? moment(data?.ngayQuyetDinh).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Loại di sản văn hóa</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.tenLoaiDiSan}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tên di sản</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.tenDiSan}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{getDiaChi(data.soNha, data.tenDuong, data.tenPhuong, data.tenHuyen)}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Nội dung giới thiệu (bài viết tóm tắt)</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.noiDungGioiThieu}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ghi chú</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.ghiChu}</InputLabel>{" "}
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}> Đưa ra khỏi danh sách</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            {data.duaRaKhoiDanhSach && <Check color="primary"></Check>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="gridItem">
                    <Box marginTop="5px">
                        <Button variant="contained" color="primary" disableElevation className="button" onClick={() => history.go(-1)}>
                            Trở về
                        </Button>
                    </Box>
                </Grid>
            </TabPanel>
            <TabPanel value={indexTab} index={1}>
                <ListDinhKemComponent data={data?.files} dateQuyetDinh={data?.ngayQuyetDinh}/>
            </TabPanel>
        </Grid>
    );
};
