import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
interface ChiTietGiayPhepLHNDButtonProps {}
const ChiTietGiayPhepLHNDButtonComponent = (props: ChiTietGiayPhepLHNDButtonProps) => {
    const history = useHistory();
    return (
        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation>
                {" "}
                Trở về
            </Button>
        </Grid>
    );
};
export default ChiTietGiayPhepLHNDButtonComponent;
