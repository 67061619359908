import { DanhMucTruongHopThuHoi } from "../../model/danhMucTruongHopThuHoi";
import { BaseResponse, BaseService } from "../baseService";
import axios from "../index";
export interface DanhMucTruongHopThuHoiRequest {
    keywords: string;
    hoatDong: number;
    pageNumber: number;
    pageSize: number;
}
export interface DanhMucTruongHopThuHoiItem {
    id: string;
    name: string;
    code: string;
    createDate?: string | number | Date;
    modifyDate?: string | number | Date;
    description?: string;
    active?: boolean;
}

export class DanhMucTruongHopThuHoiService extends BaseService {
    getGroupRoles = async (request: DanhMucTruongHopThuHoiRequest): Promise<{ rows: DanhMucTruongHopThuHoi[]; total: number }> => {
        var list: DanhMucTruongHopThuHoi[] = [];
        var total: number = 0;
        await axios
            .post("/danh-muc-truong-hop-thu-hoi/search", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        total = response.data.data.total;
                        list = response.data.data.data;
                    }
                }
            })
            .catch((error) => {});
        return { rows: list, total: total };
    };

    insertOrUpdateGroupRole = async (danhMucTruongHopThuHoi: DanhMucTruongHopThuHoi): Promise<BaseResponse> => {
        let requestBody = {
            id: danhMucTruongHopThuHoi.id,
            name: danhMucTruongHopThuHoi.name,
            code: danhMucTruongHopThuHoi.code,
            description: danhMucTruongHopThuHoi.description,
            active: danhMucTruongHopThuHoi.active,
        } as DanhMucTruongHopThuHoi;
        let result = {} as BaseResponse;
        await axios
            .post("/danh-muc-truong-hop-thu-hoi/add-or-update", JSON.stringify(requestBody), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status !== 200) {
                    result = { isSuccess: false, message: "Có lỗi xảy ra, thực hiện lại!" };
                }
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch((error) => {
                result = { isSuccess: false, message: "Lỗi khi thực hiện kết nối server" };
            });
        return result;
    };
    getAllActive = (hoatDong: boolean) => {
        return axios.get("/danh-muc-truong-hop-thu-hoi/get-active/" + hoatDong);
    };
}
