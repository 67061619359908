import MomentUtils from "@date-io/moment";
import { Button, Grid, InputLabel, Link, NativeSelect, Snackbar, SvgIcon, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createFileType, downLoadFile } from "apis/Common/exportUtil";
import { DiaDiemDuLichService } from "apis/DiaDiemDuLich/DiaDiemDuLichService";
import { LIST_TINHTRANG_DIADIEMDULICH } from "constants/DiaDiemDuLichConstants";
import { ThongKeDiaDiemDuLichRequest_INIT } from "init/DiaDiemDuLich/ThongKeDiaDiemDuLichRequest_INIT";
import { HoSoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO";
import { ThongKeDiaDiemDuLichDTO } from "model/DiaDiemDuLich/ThongKeDiaDiemDuLichDTO";
import { ThongKeDiaDiemDuLichRequest } from "model/DiaDiemDuLich/ThongKeDiaDiemDuLichRequest";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { AlertMessage, CustomAlert } from "../../../commons/alert";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import { ChiTietThongKeKhuDuLichDialog } from "./ChiTietThongKeKhuDuLichDialog";
import DanhSachThongKeKhuDuLichComponent from "./DanhSachThongKeKhuDuLichComponent";

const ThongKeKhuDuLichComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const service = new DiaDiemDuLichService();
    const danhMucService = new DanhMucService();
    const [search, setSeach] = useState<ThongKeDiaDiemDuLichRequest>({ ...ThongKeDiaDiemDuLichRequest_INIT, pageSize: 10 });
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const [totalData, setTotalData] = React.useState(0);
    const [dataList, setDataList] = useState<ThongKeDiaDiemDuLichDTO[]>([]);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    let history = useHistory();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataChiTiet, setDataChiTiet] = useState<HoSoDiaDiemDuLichDTO[]>([]);

    useEffect(() => {
        loadDataTable(search);
        loadQuans("92");
        namThongKeList();
    }, []);
    /* Danh mục quận huyện */
    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSeach({
            ...search,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };
    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ ma: 0, ten: "Chọn năm" }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { ma: namHienTai - i, ten: "Năm " + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { ma: namHienTai + i, ten: "Năm " + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    };

    const handleChange = (e: any) => {
        setSeach({
            ...search,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let searchNew = { ...search, [filedName]: date?.toDate()?.getTime() };
        setSeach(searchNew);
    };

    /* Phân trang */
    const handleSearch = async () => {
        let searchNew = { ...search, pageNumber: 1 };
        await setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const reload = async () => {
        let searchNew = { ...ThongKeDiaDiemDuLichRequest_INIT, pageSize: 10, pageNumber: 1 };
        await setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        loadDataTable(searchNew);
        setSeach(searchNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageSize: Number(event.target.value), pageNumber: 1 };
        setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const handleChiTiet = (maTinhTrangString?: string, maHuyenString?: string) => {
        setOpenDialog(true);
        dispatchLoading(showLoading());

        let request = { ...search, maTinhTrangString: maTinhTrangString, maHuyenString: maHuyenString, pageSize: 5, pageNumber: 1 } as ThongKeDiaDiemDuLichRequest;
        service
            .chiTietThongKe(request)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                        setDataChiTiet(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    }

    const loadDataTable = async (requestBody: ThongKeDiaDiemDuLichRequest) => {
        dispatchLoading(showLoading());
        await service
            .thongKe(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                        setDataList(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const downloadFile = async (extension: string) => {
        let request = {
            ...search,
            exportType: extension,
            exportFileName: "Thống kê danh sách khu/điểm du lịch trên địa bàn",
        };
        dispatchLoading(showLoading());
        await service
            .exportThongKe(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "Thống kê danh sách khu/điểm du lịch trên địa bàn");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const handleChangeKyThongKe = (e: any) => {
        const dataNew = {
            ...search,
            ["kyThongKe"]: e.target.value,
        };
        setSeach(dataNew);
    };

    return (
        <>
            {
                <div className="dash-content">
                    <div className="dash-title">
                        <span>THỐNG KÊ DANH SÁCH KHU/ĐIỂM DU LỊCH TRÊN ĐỊA BÀN</span>
                    </div>
                    <Grid container>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Kỳ thống kê</InputLabel>
                            <NativeSelect disableUnderline fullWidth name="kyThongKe" value={search.kyThongKe} onChange={handleChangeKyThongKe}>
                                <option value={1}>Năm</option>
                                <option value={2}>Khoảng thời gian</option>
                            </NativeSelect>
                        </Grid>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 1}>
                                <InputLabel classes={labelStyles}>Ngày cấp từ</InputLabel>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={
                                        search.ngayCapVanBanTu !== 0 && search.ngayCapVanBanTu && search.ngayCapVanBanTu !== null && search.ngayCapVanBanTu !== undefined
                                            ? new Date(search.ngayCapVanBanTu)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapVanBanTu")}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 1}>
                                <InputLabel classes={labelStyles}>Ngày cấp đến</InputLabel>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={
                                        search.ngayCapVanBanDen !== 0 && search.ngayCapVanBanDen && search.ngayCapVanBanDen !== null && search.ngayCapVanBanDen !== undefined
                                            ? new Date(search.ngayCapVanBanDen)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapVanBanDen")}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 2}>
                            <InputLabel classes={labelStyles}>Năm </InputLabel>
                            <Autocomplete
                                options={dataNamThongKe}
                                getOptionLabel={(option) => option?.ten}
                                value={{ ten: search.tenNamThongKe, ma: search.namThongKe }}
                                onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "namThongKe", "tenNamThongKe")}
                                renderInput={(params) => {
                                    return (
                                        <div className="padding-top-8px" ref={params.InputProps.ref}>
                                            <TextField {...params} variant="outlined" />
                                        </div>
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Huyện/Thị/TP </InputLabel>
                            <Autocomplete
                                id="maHuyen"
                                options={quans}
                                getOptionLabel={(option) => option?.name}
                                value={{ code: search.maHuyen, name: search.tenHuyen }}
                                onChange={(event: any, newValue: any) => {
                                    handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles} >Loại hình</InputLabel>
                            <NativeSelect disableUnderline fullWidth name="maLoaiHinh" value={search.maLoaiHinh} onChange={handleChange}>
                                <option value="">chọn </option>
                                <option value={1}>Điểm du lịch</option>
                                <option value={2}>Khu du lịch</option>
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Tình trạng</InputLabel>
                            <Autocomplete
                                options={LIST_TINHTRANG_DIADIEMDULICH}
                                getOptionLabel={(option: any) => option.ten}
                                value={{ ma: search.maTinhTrang, ten: search.tenTinhTrang }}
                                onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "maTinhTrang", "tenTinhTrang")}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </Grid>


                        <Grid item xs={12} className="button-group-center">
                            <Button onClick={handleSearch} type="button" variant="contained" color="primary" className="align-right" disableElevation>
                                Tổng hợp
                            </Button>
                            <Button onClick={reload} type="button" variant="contained" color="primary" className="align-right" disableElevation>
                                Làm mới
                            </Button>
                        </Grid>
                        <Grid item xs={12} className="grid-item" container justify="flex-end">
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("docx");
                                }}
                            >
                                <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                        fill="#007bff"
                                        d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                                    />
                                </SvgIcon>
                            </Link>
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("pdf");
                                }}
                            >
                                <SvgIcon fontSize="large">
                                    <path
                                        fill="#dc3545"
                                        d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                                    />
                                </SvgIcon>
                            </Link>
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("xlsx");
                                }}
                            >
                                <SvgIcon fontSize="large">
                                    <path
                                        fill="#28a745"
                                        d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                                    />
                                </SvgIcon>
                            </Link>
                        </Grid>
                        <DanhSachThongKeKhuDuLichComponent
                            search={handleSearch}
                            count={totalData}
                            page={search.pageNumber}
                            rowsPerPage={search.pageSize}
                            onChangePage={handleOnChangePage}
                            onChangeRowsPerPage={handOnChangeRowsPerPage}
                            dataList={dataList}
                            handleChiTiet={handleChiTiet}
                        />
                        {openDialog &&
                            <ChiTietThongKeKhuDuLichDialog
                                dataList={dataChiTiet}
                                onDialogClose={() => setOpenDialog(false)}
                                openDialog={openDialog}
                                pageProps={{
                                    page: 1,
                                    count: totalData,
                                    rowsPerPage: 5,
                                    onChangePage: handleOnChangePage,
                                    onChangeRowsPerPage: handOnChangeRowsPerPage,
                                }}
                            />}
                    </Grid>
                    <OverlayLoading />
                    <Snackbar
                        open={alertMessage.open}
                        onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                            {alertMessage.content}
                        </CustomAlert>
                    </Snackbar>
                </div>
            }
        </>
    );
};
export default ThongKeKhuDuLichComponent;
