import { Box, Button, IconButton, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Pagination } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { BuongLuuTruDTO_INIT } from "../../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { BuongLuuTruDTO } from "../../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import StringUtil from "../../../../../utils/stringUtils";
import { CustomInput } from "../../../../commons/input/customInput";
import { Cell, Row } from "../../../../commons/table/tableCommons";
import BuongCoSoModal from "./BuongCoSoModal";
interface BuongCoSoComponentProps {
    data: BuongLuuTruDTO[];
    view?: boolean;
    onUpdateData?: (name: string, value: any) => void;
}
interface BuongCoSoModalData {
    data: BuongLuuTruDTO;
    show: boolean;
    indexEdit: number;
}
export const BuongCoSoComponent = (props: BuongCoSoComponentProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<BuongLuuTruDTO[]>(new Array<BuongLuuTruDTO>());
    const [openModel, setOpenModel] = useState(false);
    const [dataEdit, setDataEdit] = useState<BuongCoSoModalData>({ data: { ...BuongLuuTruDTO_INIT }, show: false, indexEdit: -1 });
    const { onUpdateData } = props;

    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    };
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    };
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = (pageNumberNew - 1) * pageSizeNew;
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    };
    const handleAdd = (dataAdd: BuongLuuTruDTO) => {
        let dataNew = [...props.data];
        dataNew.unshift(dataAdd);
        if (onUpdateData) {
            onUpdateData("buongLuuTruDTOs", dataNew);
        }
    };
    const handleUpdate = (index: number, value: BuongLuuTruDTO) => {
        let dataNew = [...props.data];
        dataNew[index] = value;
        if (onUpdateData) {
            onUpdateData("buongLuuTruDTOs", dataNew);
        }
    };
    const handleDrop = (index: number) => {
        let dataNew = [...props.data];
        dataNew.splice(index, 1);
        if (onUpdateData) {
            onUpdateData("buongLuuTruDTOs", dataNew);
        }
    };
    const handleToggle = () => {
        setDataEdit({ ...dataEdit, show: !dataEdit.show });
    };
    const handleEditToggle = (index: number) => {
        setDataEdit({ ...dataEdit, data: props.data[index], show: !dataEdit.show, indexEdit: index });
    };
    const handleAddToggle = () => {
        setDataEdit({ data: { ...BuongLuuTruDTO_INIT }, show: !dataEdit.show, indexEdit: -1 });
    };
    return (
        <div>
            <BuongCoSoModal data={dataEdit} onAddData={handleAdd} onUpdateData={handleUpdate} onToggle={handleToggle} />
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center">
                                Loại buồng
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="15%">
                                Số lượng
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Giá công bố (VND)
                            </Cell>
                            {!props.view && <Cell component="th" scope="row" align="center" width="8%"></Cell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataPagi &&
                            dataPagi.map((item, i) => (
                                <Row key={i}>
                                    <Cell scope="row" align="center">
                                        {(pageNumber - 1) * pageSize + i + 1}
                                    </Cell>
                                    <Cell align="left">{item.loaiBuong}</Cell>
                                    <Cell align="left">{item.soLuong}</Cell>
                                    <Cell align="left">{StringUtil.formatMoney(item.gia)}</Cell>
                                    {!props.view && (
                                        <Cell align="center">
                                            <Box display="flex" justifyContent="center">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="edit"
                                                    size="small"
                                                    onClick={() => {
                                                        handleEditToggle(i);
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    aria-label="delete"
                                                    size="small"
                                                    onClick={() => {
                                                        handleDrop(i);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Cell>
                                    )}
                                </Row>
                            ))}
                    </TableBody>
                </Table>
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination
                        count={Math.ceil(props.data.length / pageSize)}
                        page={pageNumber}
                        defaultPage={1}
                        siblingCount={1}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        onChange={handleChangePage}
                    />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">
                            Số mẫu tin trên trang
                        </Box>
                        <NativeSelect input={<CustomInput />} onChange={handleChangePageSize}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">
                        Mẫu tin {(pageNumber - 1) * pageSize + 1} - {pageNumber * pageSize > props.data.length ? props.data.length : pageNumber * pageSize} /{" "}
                        {props.data.length}
                    </Box>
                </Box>
                {!props.view && (
                    <Button hidden={props.view} onClick={handleAddToggle} variant="contained" color="primary" disableElevation>
                        Thêm mới
                    </Button>
                )}
            </TableContainer>
        </div>
    );
};
