import { Animation, Stack, EventTracker } from "@devexpress/dx-react-chart";
import {
    ArgumentAxis,
    BarSeries, Chart,
    Legend, ValueAxis, Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Box, Button, Card, CardContent, createStyles, Grid, InputLabel, makeStyles, TextField, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import React, { Dispatch, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { PageRequest } from "apis/baseService";
import { DashboardDichVuDuLichDTO, DashboardDichVuDuLichService } from "apis/DichVuDuLich/DashboardDichVuDuLichService";
import { TraCuuCoSoDuLichDTO_INIT } from "../../../init/DichVuDuLich/DichVuDuLichModel_INIT";
import { TraCuuCoSoDuLichDTO } from "../../../model/DichVuDuLich/DichVuDuLichModel";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { bootstrapTextFieldHook } from "../../commons/input/bootstrap";
import { OverlayLoading } from "../../overlayLoading/overlayLoading";

export interface RequestBieuDoThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;",
        },
    })
);

export const DashboardDichVuDuLichComponent = () => {
    const classes = useStyles();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<DashboardDichVuDuLichDTO[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [search, setSeach] = useState<TraCuuCoSoDuLichDTO>({ ...TraCuuCoSoDuLichDTO_INIT });
    const service = new DashboardDichVuDuLichService();
    const [rows, setRows] = useState<DashboardDichVuDuLichDTO[]>([]);

    const dataLuuTru = [...rows.map((item) => item.luuTru)];
    const dataAnUong = [...rows.map((item) => item.anUong)];
    const dataMuaSam = [...rows.map((item) => item.muaSam)];
    const dataTheThao = [...rows.map((item) => item.theThao)];
    const dataGiaiTri = [...rows.map((item) => item.giaiTri)];
    useEffect(() => {
        namThongKeList();
        loadDataTable(search);
    }, []);
    const loadDataTable = async (requestBody: TraCuuCoSoDuLichDTO) => {
        dispatchLoading(showLoading());
        await service
            .loadDataDashboard(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setData(response.data.data);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ ma: 0, ten: "Chọn năm" }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { ma: namHienTai - i, ten: "Năm " + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { ma: namHienTai + i, ten: "Năm " + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    };
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    };
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };
    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Cơ sở lưu trú",
                backgroundColor: "#009999",
                data: dataLuuTru,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Cơ sở ăn uống",
                backgroundColor: "#0033cc",
                data: dataAnUong,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Cơ sở mua sắm",
                backgroundColor: "#9933ff",
                data: dataMuaSam,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Cơ sở thể thao",
                backgroundColor: "#ff9933",
                data: dataTheThao,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Cơ sở vui chơi",
                backgroundColor: "#009933",
                data: dataGiaiTri,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
        ],
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ CƠ SỞ KINH DOANH ĐẠT CHUẨN</span>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 2}>
                    <InputLabel classes={labelStyles}>Năm </InputLabel>
                    <Autocomplete
                        options={dataNamThongKe}
                        getOptionLabel={(option) => option?.ten}
                        value={{ ten: search.tenNamThongKe, ma: search.namThongKe }}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "namThongKe", "tenNamThongKe")}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={9} className="grid-item" container justify="flex-end">
                    <Button
                        onClick={(e) => loadDataTable(search)}
                        type="button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.button}
                    >
                        Tổng hợp
                    </Button>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <Card classes={classes}>
                        <CardContent>
                            <Bar data={dataBD} options={options} />
                        </CardContent>
                    </Card>
                    {/* <Paper>
                        <Chart data={data}>
                            <ArgumentAxis />
                            <ValueAxis
                                labelComponent={(props) => {
                                    return Number.isInteger(Number(props.text)) ? <text {...props}>{Number(props.text)}</text> : <text {...props}></text>;
                                }}
                            />
                            <BarSeries name="Cơ sở lưu trú" valueField="luuTru" argumentField="tenThang" color="#009999" />
                            <BarSeries name="Cơ sở ăn uống" valueField="anUong" argumentField="tenThang" color="#0033cc" />
                            <BarSeries name="Cơ sở mua sắm" valueField="muaSam" argumentField="tenThang" color="#9933ff" />
                            <BarSeries name="Cơ sở thể thao" valueField="theThao" argumentField="tenThang" color="#ff9933" />
                            <BarSeries name="Cơ sở vui chơi" valueField="giaiTri" argumentField="tenThang" color="#009933" />
                            <Animation />
                            <Legend
                                position="bottom"
                                rootComponent={(root: Legend.RootProps) => {
                                    return (
                                        <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                            {root.children}
                                        </Box>
                                    );
                                }}
                                itemComponent={(label: Legend.ItemProps) => {
                                    return (
                                        <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                            {label.children}
                                        </Box>
                                    );
                                }}
                            />
                            <Stack />
                            <EventTracker></EventTracker>
                            <Tooltip />
                        </Chart>
                    </Paper> */}
                </Grid>
            </Grid>
            <OverlayLoading />
        </div>
    );
};
