import { Box, IconButton, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { CustomInput } from "../../../commons/input/customInput";
import { Cell, Row } from "../../../commons/table/tableCommons";
import { FileEntry } from "../../../../apis/Common/fileEntry";

interface chiTietProps {
    data: FileEntry[];
}

const TepTinDinhKem = (props: chiTietProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<FileEntry[]>(new Array<FileEntry>());
    const fileServerService = new FileServerService();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    };
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    };
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = (pageNumberNew - 1) * pageSizeNew;
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        dispatchLoading(showLoading());
        fileServerService
            .downloadFile(file)
            .then((res) => {
                downLoadFile(res.data, createFileType(file.extension), file.name);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center">
                                Tên tập tin
                            </Cell>
                            <Cell component="th" scope="row" align="center">
                                Người đính
                            </Cell>
                            <Cell component="th" scope="row" align="center">
                                Tập tin
                            </Cell>
                            <Cell component="th" scope="row" align="center">
                                Ngày đính kèm
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataPagi?.length > 0 ? (
                            dataPagi?.map((item, index) => (
                                <Row key={item.fileEntryId}>
                                    <Cell className="text-center">{(pageNumber - 1) * pageSize + index + 1}</Cell>
                                    <Cell>
                                        <Box style={{ cursor: "pointer" }} onClick={() => handleDownloadFile(item)} component="div" display="inline">
                                            {item.name}
                                        </Box>
                                    </Cell>
                                    <Cell>{item.userName}</Cell>
                                    <Cell className="text-center">
                                        <Box display="flex" justifyContent="center">
                                            <IconButton color="primary" aria-label="edit" size="small" onClick={() => handleDownloadFile(item)}>
                                                <AttachFileIcon />
                                            </IconButton>
                                        </Box>
                                    </Cell>
                                    <Cell>{item.createdDate ? moment(item.createdDate).format("DD/MM/YYYY") : ""}</Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={5} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu
                                </Cell>
                            </Row>
                        )}
                    </TableBody>
                </Table>
                {dataPagi && dataPagi?.length > 0 && (
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination
                            count={Math.ceil(props.data?.length / pageSize)}
                            defaultPage={1}
                            siblingCount={1}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={handleChangePage}
                        />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">
                                Số mẫu tin trên trang
                            </Box>
                            <NativeSelect input={<CustomInput />} onChange={handleChangePageSize}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">
                            Mẫu tin {pageNumber} / {props.data?.length === 0 || props.data == null ? 1 : Math.ceil(props.data?.length / pageSize)}
                        </Box>
                    </Box>
                )}
            </TableContainer>
        </>
    );
};
export default TepTinDinhKem;
