import AXIOS_INSTANCE from "..";
import { GiayChungNhanDSVHRequest } from "../../init/GiayChungNhan/GiayChungNhanDSVHRequest";
import { GiayChungNhanDiSanVanHoa, GiayChungNhanDiSanVanHoa_INIT } from "../../model/giayChungNhanDiSanVanHoa";
import { LichSuGiayChungNhan } from "../../model/lichSuGiayChungNhan";
import { HoatDongVanHoaNgheThuat, HoatDongVanHoaNgheThuat_INIT } from "../../model/ThongBaoNgheThuat/HoatDongVanHoaNgheThuat";
import { LichSuThongBaoToChucNT } from "../../model/ThongBaoNgheThuat/lichSuThongBaoToChucNT";
import { BaseService } from "../baseService";

export class ThongBaoToChucService extends BaseService {
    insertOrUpdate = (hoSo: HoatDongVanHoaNgheThuat) => {
        return AXIOS_INSTANCE.post("ho-so-thu-ly-NT/save-or-update", hoSo, this.getTokenRequestHeaders());
    };

    getByMaHoSo = (maHS: string, className: string) => {
        return AXIOS_INSTANCE.post("ho-so-thu-ly-NT/get-by-ma-ho-so", { maHoSo: maHS, className }, this.getTokenRequestHeaders());
    };

    downloadFileThuLy(request: any) {
        return AXIOS_INSTANCE.post("ho-so-thu-ly-NT/download-file-thu-ly", request, {
            responseType: "blob",
        });
    }
    
    getListGCN = (requestBody: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("hoat-dong-nghe-thuat/thong-bao-to-chuc/search", requestBody);
    };

    getBySoGiayChungNhanDiSanVanHoas(id: number) {
        return AXIOS_INSTANCE.post("hoat-dong-nghe-thuat/thong-bao-to-chuc/get-giay-chung-nhan-by-id", {
            id: id,
        });
    }

    getGCNChiTiet = async (id: number): Promise<HoatDongVanHoaNgheThuat> => {
        var result: HoatDongVanHoaNgheThuat = HoatDongVanHoaNgheThuat_INIT;
        await AXIOS_INSTANCE.post("hoat-dong-nghe-thuat/thong-bao-to-chuc/get-chi-tiet-giay-chung-nhan", { id: id }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    result = { ...response.data.data };
                }
            })
            .catch();
        return result;
    };

    getLichSuGCN = async (id: number): Promise<{ rows: LichSuThongBaoToChucNT[]; total: number }> => {
        var list: LichSuThongBaoToChucNT[] = [];
        var total: number = 0;
        await AXIOS_INSTANCE.post("hoat-dong-nghe-thuat/thong-bao-to-chuc/get-lich-su-giay-chung-nhan", { id: id }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    list = response.data.data;
                    total = list.length;
                }
            })
            .catch();
        return { rows: list, total: total };
    };
    thuHoi = (giayChungNhan: GiayChungNhanDiSanVanHoa) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/thu-hoi", giayChungNhan, this.getTokenRequestHeaders());
    };

    baoCaoTinhHinhGiayChungNhan = (requestBody: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/bao-cao-ds-giay-chung-nhan", requestBody);
    };
    downloadFileBaoCaoGiayChungNhan = (request: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/export-bao-cao-giay-chung-nhan", request, {
            responseType: "blob",
        });
    };
    downloadFileTraCuuThongTinGCN = (request: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("hoat-dong-nghe-thuat/thong-bao-to-chuc/export-tra-cuu-thong-tin", request, {
            responseType: "blob",
        });
    };
    getBySoGiayChungNhanDiSanVanHoa(id: number) {
        let body = {
            id: id,
        };
        return AXIOS_INSTANCE.get("di-san-van-hoa/giay-chung-nhan/get-giay-chung-nhan-di-san-van-hoa", {
            params: body,
        });
    }

    getByAutocompleteSoGiayChungNhan(soChungNhanDaCap: string, hoatDong: boolean, limit: number) {
        let body = {
            soGiayChungNhan: soChungNhanDaCap,
            hoatDong: hoatDong,
            limit: limit,
        };
        return AXIOS_INSTANCE.get("di-san-van-hoa/giay-chung-nhan/get-autocomplete-by-so-giay-chung-nhan", {
            params: body,
        });
    }

    downloadFileThongKeGCN = (request: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.get("di-san-van-hoa/giay-chung-nhan/export-thong-ke-gcn", {
            responseType: "blob",
            params: request,
        });
    };

    thongKeGCNDiSanVanHoa = (requestBody: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/thong-ke-gcn", requestBody);
    };

    downloadFileThongKe = (request: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.get("di-san-van-hoa/giay-chung-nhan/export-file-thong-ke", {
            responseType: "blob",
            params: request,
        });
    };
}
