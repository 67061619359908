import { Box, Button, Dialog, DialogContent, Grid, InputLabel, Snackbar, TextField } from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../apis";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { DanhMucDTO, DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { HoSoCuaToiDTO, HoSoCuaToiMgtService, VanBanDuThaoDTO, VanBanDuThaoDTO_INIT } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import { ACCEPT_FILES } from "../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { HoSoThuLyLuHanhNoiDia } from "../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { DialogTitle } from "../../../commons/dialog/dialogCommons";
import DropZoneCustom from "../../../commons/hoso/CustomDropzone/DropZoneCustom";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";

interface DuThaoModalProps {
    show: boolean;
    onToggle: () => void;
    reloadData: () => void;
    hoso: HoSoThuLyLuHanhNoiDia;
    data: VanBanDuThaoDTO;
}
const DuThaoModal = (props: DuThaoModalProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [show, setShow] = useState(false);
    const [submit, setSubmit] = useState(false);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [loaiVanBans, setLoaiVanBans] = useState<DanhMucDTO[]>([]);
    const [data, setData] = useState<VanBanDuThaoDTO>({
        ...VanBanDuThaoDTO_INIT,
        maHoSo: props.hoso.maHoSo,
        maNguoiGui: "16806",
        tenNguoiYeuCau: "Chuyên viên 1",
    });
    const { onToggle, reloadData } = props;

    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const fileServerService = new FileServerService();
    const danhMucService = new DanhMucService();

    useEffect(() => {
        setShow(props.show);
        setData(props.data);
        if (props.show) {
            loadLoaiVanBan();
        }
    }, [props.show, props.data]);
    const loadLoaiVanBan = () => {
        danhMucService.getLoaiVanBanByMaCoQuan_HoatDong("000.00.13.H13", true).then((p) => setLoaiVanBans(p));
    };
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };
    const handleSubmit = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.noiDung)) {
            return;
        }
        dispatchLoading(showLoading());
        hoSoCuaToiMgtService
            .addOrUpdateVanBanDuThao(data)
            .then((p) => {
                if (p.data && p.data.error_code === "SUCCESSFUL") {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                    onToggle();
                    reloadData();
                } else {
                    setAlert({
                        showAlert: false,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);

            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.id > 0 ? value?.ten : "";
        setData({
            ...data,
            [nameValue]: value?.id,
            [nameText]: valueText,
        });
    };
    return (
        <>
            <Snackbar
                open={alert.showAlert}
                onClose={() => setAlert({ ...alert, showAlert: false })}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                    {alert.message}
                </Alert>
            </Snackbar>
            <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show}>
                <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                    DỰ THẢO VĂN BẢN
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid container>
                            <Grid item xs={4} className="grid-item">
                                <InputLabel>Loại văn bản</InputLabel>
                                <Autocomplete
                                    id="loaiVanBan"
                                    options={loaiVanBans}
                                    value={{ ma: "", id: data.loaiVanBan, ten: data.tenLoaiVanBan } as DanhMucDTO}
                                    getOptionLabel={(option) => option.ten}
                                    onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "loaiVanBan", "tenLoaiVanBan")}
                                    noOptionsText={"Không có dữ liệu"}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel required>Trích yếu dự thảo</InputLabel>
                                <TextField
                                    fullWidth
                                    name="noiDung"
                                    value={data.noiDung}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                    multiline
                                    rows={3}
                                    onChange={handleChange}
                                    error={submit && StringUtil.isNullOrEmty(data.noiDung)}
                                    helperText={submit && StringUtil.isNullOrEmty(data.noiDung) ? "Vui lòng nhập nội dung trích yếu" : ""}
                                />
                            </Grid>
                            <Grid item xs={12} container className="grid-item">
                                <DropZoneCustom
                                    acceptFiles={ACCEPT_FILES}
                                    attachFileServer={data.files}
                                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/duthaovanban"}
                                    handleChangeStatus={handleChangeStatus}
                                    handleDeleteFileServer={handleDeleteFileServer}
                                    handleDownloadFile={handleDownloadFile}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <Box marginTop="5px">
                                    <Button variant="contained" color="primary" disableElevation onClick={handleSubmit}>
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation onClick={onToggle}>
                                        Đóng
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default DuThaoModal;
