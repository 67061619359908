import MomentUtils from "@date-io/moment";
import { Button, createStyles, Grid, InputLabel, Link, makeStyles, NativeSelect, Snackbar, SvgIcon, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useFormik } from "formik";
import { HoSoThuLyDichVuDuLich } from "model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageRequest } from "../../../../apis/baseService";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { GiayChungNhanTheThaoService } from "../../../../apis/DichVuDuLich/GiayChungNhanTheThaoService";
import { DichVuDuLichConstants, LIST_TINHTRANG, TINHTRANG_HOATDONG } from "../../../../constants/DichVuDuLichConstants";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { AlertMessage, CustomAlert } from "../../../commons/alert";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import { ThongKeTinhHinhCSKDResponse } from "../../model/BaoCaoThongKeCSKDDTO";
import { ChiTietThongKeTheThaoDialog } from "./ChiTietThongKeTheThaoDialog";
import { DanhSachThongKeCSKDTheThao } from "./DanhSachThongKeCSKDTheThao";
// import { BienDongDTO } from "../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";

export interface RequestThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
    maQuanHuyen: string;
    maPhuongXa: string;
    tenQuanHuyen: string;
    tenPhuongXa: string;
    tinhTrang: string;
    tenTinhTrang: string;
    tuNgay: number;
    denNgay: number;
    kyThongKe: number;
    maBienDong: string;
    tenBienDong: string;
}

export interface RequestBaoCaoThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
    thangThongKe: number;
    tenThangThongKe: string;
    loaiNuaNam: number;
    tenLoaiNuaNam: string;
    quyThongKe: number;
    tenQuyThongKe: string;
    maPhuongXa: string;
    tenPhuongXa: string;
    tinhTrang: string;
    tenTinhTrang: string;
    tuNgay: number;
    denNgay: number;
    kyThongKe: number;
    keywords: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px",
        },
    })
);

export const ThongKeTinhHinhCSKDTheThao = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<ThongKeTinhHinhCSKDResponse>();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [totalData, setTotalData] = useState(0);
    const [dataChiTiet, setDataChiTiet] = useState<HoSoThuLyDichVuDuLich[]>([])
    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        namThongKeList();
        loadDataTable();
        loadQuans("92");
        // loadDataBienDong();
    }, []);

    const formik = useFormik({
        initialValues: {
            request: {
                maPhuongXa: "",
                maQuanHuyen: "",
                tenPhuongXa: "Chọn phường xã",
                tenQuanHuyen: "Chọn quận huyện",
                tinhTrang: "",
                tenTinhTrang: "Chọn tình trạng",
                kyThongKe: 1,
                namThongKe: 0,
                tenNamThongKe: "Chọn năm",
            } as RequestThongKeDTO,
        },
        onSubmit: () => loadDataTable(),
    });

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        formik.setFieldValue("request.maQuanHuyen",value?.code);
        formik.setFieldValue("request.tenQuanHuyen",valueText);
    };

    const loadDataTable = async () => {
        if (formik.values.request?.kyThongKe == 2) {
            if (formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay) {
                showMessageAlert("Từ ngày không được lớn hơn đến này", false);
                return;
            }
        }
        let request = {
            ...formik.values.request,
        } as RequestThongKeDTO;
        dispatchLoading(showLoading());
        await new GiayChungNhanTheThaoService()
            .thongKeCSKDTheThao(request)
            .then((p) => {
                setData(p);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleChiTiet = (maTinhTrang?: string, maHuyenString?: string) => {
        setOpenDialog(true);
        dispatchLoading(showLoading());

        let request = {
            ...formik.values.request,
            maTinhTrang: maTinhTrang,
            maHuyenString: maHuyenString,
        } as RequestThongKeDTO;
        new GiayChungNhanTheThaoService()
            .chiTietThongKe(request)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                        setDataChiTiet(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {

    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {

    };

    const reload = async() => {
        formik.resetForm();
        let request = {
            maPhuongXa: "",
            maQuanHuyen: "",
            tenPhuongXa: "Chọn phường xã",
            tenQuanHuyen: "Chọn quận huyện",
            tinhTrang: "",
            tenTinhTrang: "Chọn tình trạng",
            kyThongKe: 1,
            namThongKe: 0,
            tenNamThongKe: "Chọn năm",
        } as RequestThongKeDTO;
        dispatchLoading(showLoading());
        await new GiayChungNhanTheThaoService()
            .thongKeCSKDTheThao(request)
            .then((p) => {
                setData(p);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    }

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...formik.values.request, [nameValue]: value?.ma, [nameText]: valueText };
        formik.setFieldValue("request.maBienDong",value?.code);
        formik.setFieldValue("request.tenBienDong",valueText);
    };

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ nam: 0, name: "Chọn năm" }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { nam: namHienTai - i, name: "Năm " + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { nam: namHienTai + i, name: "Năm " + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    };

    // const loadDataBienDong = async () => {
    //     let request = {
    //         ...formik.values.request
    //     } as RequestBaoCaoThongKeDTO;
    //     dispatchLoading(showLoading());
    //     await new ThongKeService().danhMucBienDong(request).then((p) => {
    //         setDataBienDong(p.rows);
    //     }).finally(() => {
    //         dispatchLoading(hideLoading());
    //     });
    // }
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };
    const downloadFile = async (extension: string) => {
        let request = {
            ...formik.values.request,
            exportType: extension,
            exportFileName: "Danh_sach_thong_ke_cap_phep",
        };
        dispatchLoading(showLoading());
        await new GiayChungNhanTheThaoService()
            .downloadFileThongKeCSKDTheThao(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "Danh_sach_thong_ke_cap_phep");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const TINHTRANG: DichVuDuLichConstants[] = [
        TINHTRANG_HOATDONG,
    ]
    
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO</span>
            </div>

            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Kỳ thống kê</InputLabel>
                        <NativeSelect
                            fullWidth
                            disableUnderline
                            name="kyThongKe"
                            value={formik.values?.request?.kyThongKe}
                            onChange={(event: any) => {
                                formik.setFieldValue("request.kyThongKe", event.target.value);
                            }}
                        >
                            <option value="1">Năm</option>
                            <option value="2">Khoảng thời gian</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={formik.values?.request?.kyThongKe == 2}>
                        <InputLabel>Năm </InputLabel>
                        <Autocomplete
                            options={dataNamThongKe}
                            value={{
                                nam: formik.values?.request?.namThongKe ? formik.values.request?.namThongKe : 0,
                                name: formik.values?.request?.tenNamThongKe ? formik.values.request?.tenNamThongKe : "",
                            }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.namThongKe", newValue?.nam);
                                formik.setFieldValue("request.tenNamThongKe", newValue?.name);
                            }}
                            renderInput={(params) => {
                                return (
                                    <div className="padding-top-8px" ref={params.InputProps.ref}>
                                        <TextField {...params} variant="outlined" />
                                    </div>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={formik.values?.request?.kyThongKe == 1}>
                        <InputLabel>Từ ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request.tuNgay > 0 ? new Date(formik.values.request.tuNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.tuNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={formik.values?.request?.kyThongKe == 1}>
                        <InputLabel>Đến ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request?.denNgay > 0 ? new Date(formik.values.request?.denNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.denNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={formik.values?.request?.kyThongKe == 1}></Grid>
                    <Grid item xs={6} className="grid-item" hidden={formik.values?.request?.kyThongKe == 2}></Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Huyện/Thị/TP</InputLabel>
                        <Autocomplete
                            id="maHuyen"
                            options={quans}
                            getOptionLabel={(option) => option?.name}
                            value={{ code: formik.values.request?.maQuanHuyen, name: formik.values.request?.tenQuanHuyen }}
                            onChange={(event: any, newValue: any) => {
                                handleChangeSelectQuan(newValue, "maQuanHuyen", "tenQuanHuyen");
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>Tình trạng</InputLabel>
                        <Autocomplete
                            id="maHuyen"
                            options={TINHTRANG}
                            getOptionLabel={(option) => option?.ten}
                            value={{ ma: formik.values.request?.maBienDong, ten: formik.values.request?.tenBienDong }}
                            onChange={(event: any, newValue: any) => {
                                handleChangeSelect(newValue, "maBienDong", "tenBienDong");
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={12} className="grid-item" container justify="center">
                        <InputLabel></InputLabel>
                        <Button onClick={loadDataTable} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tổng hợp
                        </Button>
                        <Button onClick={reload} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Làm mới
                        </Button>
                    </Grid>

                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                downloadFile("docx");
                            }}
                        >
                            <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    fill="#007bff"
                                    d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                                />
                            </SvgIcon>
                        </Link>
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                downloadFile("pdf");
                            }}
                        >
                            <SvgIcon fontSize="large">
                                <path
                                    fill="#dc3545"
                                    d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                                />
                            </SvgIcon>
                        </Link>
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                downloadFile("xlsx");
                            }}
                        >
                            <SvgIcon fontSize="large">
                                <path
                                    fill="#28a745"
                                    d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                                />
                            </SvgIcon>
                        </Link>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <DanhSachThongKeCSKDTheThao data={data} handleChiTiet={handleChiTiet}/>
                    </Grid>
                    {openDialog &&
                        <ChiTietThongKeTheThaoDialog
                            dataList={dataChiTiet}
                            onDialogClose={() => setOpenDialog(false)}
                            openDialog={openDialog}
                            pageProps={{
                                page: 1,
                                count: totalData,
                                rowsPerPage: 5,
                                onChangePage: handleOnChangePage,
                                onChangeRowsPerPage: handOnChangeRowsPerPage,
                            }}
                        />}
                </Grid>
            </form>
            <OverlayLoading />
            <Snackbar
                open={alertMessage.open}
                onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                    {alertMessage.content}
                </CustomAlert>
            </Snackbar>
        </div>
    );
};
