export type DiaDiemDuLichConstants = {
    ten : string;
    ma : string;
}
export const LOAIBIENDONG_CAPMOI_DIADIEMDULICH :  DiaDiemDuLichConstants = {
    ten : "Cấp mới",
    ma : "CM"
}

export const TINHTRANG_HOATDONG_DIADIEMDULICH :  DiaDiemDuLichConstants = {
    ten : "Đang Hoạt động",
    ma : "HD"
}
export const TINHTRANG_CHAMDUT_DIADIEMDULICH :  DiaDiemDuLichConstants = {
    ten : "Chấm dứt",
    ma : "CD"
}
export const TINHTRANG_TAMNGUNG_DIADIEMDULICH :  DiaDiemDuLichConstants = {
    ten : "Tạm ngưng",
    ma : "TN"
}

export const LIST_BIENDONG : DiaDiemDuLichConstants[] = [
    LOAIBIENDONG_CAPMOI_DIADIEMDULICH
]

export const LIST_TINHTRANG_DIADIEMDULICH : DiaDiemDuLichConstants[] = [
    TINHTRANG_HOATDONG_DIADIEMDULICH,
    TINHTRANG_CHAMDUT_DIADIEMDULICH,
    TINHTRANG_TAMNGUNG_DIADIEMDULICH
]

export const LIST_TINHTRANG_NHAPDAUKY : DiaDiemDuLichConstants[] = [
    TINHTRANG_HOATDONG_DIADIEMDULICH,
]

export const ACCEPT_FILES = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

export const  DUPLICATE = '409'