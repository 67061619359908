import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 9999,
            color: "#fff",
        },
    })
);
export const OverlayLoading = () => {
    const classes = useStyles();
    const isLoading = useSelector((state: RootState) => state.application);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(isLoading);
    }, [isLoading]);

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
