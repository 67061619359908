export interface MarkerDTO {
    classNameGCN: Number | null | string;
    tenCoSo: string;
    soQuyetDinh: string;
    kinhDo: number;
    viDo: number;
    tenHuyen: string;
    maHuyen: string;
    tenPhuong: string;
    maPhuong: string;
    diaChiDayDu: string;
    ngayCap: number;
    soDienThoai: string;
    tenTrangThai: string;
    maTrangThai: string;
    mauTrangThai : string;
    pageSize: number;
    pageNumber: number;
    tongSoNguoi?: number;
    phongChayChuaChay?: boolean;
    duDieuKienTranTu?: boolean;
}
export const MarkerDTO_INIT: MarkerDTO = {
    classNameGCN: "",
    tenCoSo: "",
    soQuyetDinh: "",
    kinhDo: 0,
    viDo: 0,
    tenHuyen: "",
    maHuyen: "",
    tenPhuong: "",
    maPhuong: "",
    diaChiDayDu: "",
    ngayCap: 0,
    soDienThoai: "",
    tenTrangThai: "",
    maTrangThai: "",
    mauTrangThai: 'green',
    pageSize: 5,
    pageNumber: 1,
};
