import ApartmentIcon from "@material-ui/icons/Apartment";
import AppsIcon from "@material-ui/icons/Apps";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import BatteryStdIcon from "@material-ui/icons/BatteryStd";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DeckIcon from "@material-ui/icons/Deck";
import DescriptionIcon from "@material-ui/icons/Description";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import GradeIcon from "@material-ui/icons/Grade";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SchoolIcon from "@material-ui/icons/School";
import SettingsIcon from "@material-ui/icons/Settings";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import SportsHandballIcon from "@material-ui/icons/SportsHandball";
import ToysIcon from "@material-ui/icons/Toys";

export const ACCEPT_FILES = "image/*,.doc,.docx,.xls,.xlsx,.pdf";
export const API_KEY_MAP = "AAPK33324f5f968a45d5a0f447b8ccc4dfc48RQTWUPnGZauFUBxgEl0_1FlFxegBPn-iPeYn2DPikYUqlJUxY8H1EmktLnhF1W7";

export const KY_BAOCAO_THONGKE_NAM = {
    name: "Năm",
    value: 1,
};

export const KY_BAOCAO_THONGKE_NGAY = {
    name: "Khoảng thời gian",
    value: 2,
};

export const LIST_ICONS = [
    {
        ten: "Ứng dụng",
        icon: <AppsIcon fontSize="small" />,
    },
    {
        ten: "Chong chóng",
        icon: <ToysIcon fontSize="small" />,
    },
    {
        ten: "Thể thao",
        icon: <SportsHandballIcon fontSize="small" />,
    },
    {
        ten: "Tốt nghiệp",
        icon: <SchoolIcon fontSize="small" />,
    },
    {
        ten: "Địa điểm",
        icon: <LocationOnIcon fontSize="small" />,
    },
    {
        ten: "Bông 3",
        icon: <FilterVintageIcon fontSize="small" />,
    },
    {
        ten: "Nghĩ mát",
        icon: <DeckIcon fontSize="small" />,
    },
    {
        ten: "Thẻ",
        icon: <CreditCardIcon fontSize="small" />,
    },
    {
        ten: "Trẻ nhỏ",
        icon: <ChildCareIcon fontSize="small" />,
    },
    {
        ten: "Âm nhạc",
        icon: <AudiotrackIcon fontSize="small" />,
    },
    {
        ten: "Phân tích",
        icon: <AssessmentIcon fontSize="small" />,
    },
    {
        ten: "Bông 1",
        icon: <LocalFloristIcon fontSize="small" />,
    },
    {
        ten: "Lữ hành",
        icon: <DirectionsRunIcon fontSize="small" />,
    },
    {
        ten: "Âm nhạc",
        icon: <AudiotrackIcon fontSize="small" />,
    },
    {
        ten: "Cài đặt",
        icon: <SettingsIcon fontSize="small" />,
    },
    {
        ten: "Tệp tin",
        icon: <DescriptionIcon fontSize="small" />,
    },
    {
        ten: "Căn hộ, chung cư",
        icon: <ApartmentIcon fontSize="small" />,
    },
    {
        ten: "Thuốc lá",
        icon: <SmokingRoomsIcon fontSize="small" />,
    },
    {
        ten: "Rượu",
        icon: <LocalBarIcon fontSize="small" />,
    },
    {
        ten: "Ăn uống",
        icon: <FastfoodIcon fontSize="small" />,
    },
    {
        ten: "Chứng nhận",
        icon: <CardMembershipIcon fontSize="small" />,
    },
    {
        ten: "Ngôi sao",
        icon: <GradeIcon fontSize="small" />,
    },
    {
        ten: "Chai",
        icon: <BatteryStdIcon fontSize="small" />,
    },
    {
        ten: "Cúp",
        icon: <EmojiEventsIcon fontSize="small" />,
    },
];
export const DECRYPT_KEY = "my-secret-key@123";
