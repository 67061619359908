import { Box, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import { ThongKeDiaDiemDuLichDTO } from "model/DiaDiemDuLich/ThongKeDiaDiemDuLichDTO";
import React, { useEffect } from "react";
import { CustomInput } from "../../../commons/input/customInput";
import { Cell, Row } from "../../../commons/table/tableCommons";

interface TableProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    dataList: ThongKeDiaDiemDuLichDTO[];
    search: () => void;
    handleChiTiet: (maTinhTrangString?: string, maHuyenString?: string) => void;
}
const DanhSachThongKeKhuDuLichComponent = (props: TableProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    useEffect(() => {
        setTotalPage(Math.ceil(props.count / props.rowsPerPage));
        setRowsPerPage(props.rowsPerPage);
    }, [props.count, props.rowsPerPage]);

    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="15%">
                                Quận/ Huyện
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Đang hoạt động
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tạm ngưng
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Châm dứt
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataList.length > 0 ? (
                            props.dataList.map((row, i) => (
                                <Row key={i}>
                                    <Cell scope="row" align="center" >
                                        {row.maHuyen == 'sum' ? '' : ((props.page - 1) * rowsPerPage + i + 1)}
                                    </Cell>
                                    <Cell style={row.maHuyen == 'sum' ? { fontWeight: 'bold' } : {}} align="left">{row.tenHuyen}</Cell>
                                    <Cell style={row.maHuyen == 'sum' ? { fontWeight: 'bold' } : {}} align="center">
                                        <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("HD", row.tenHuyen)}>
                                            {row.hoatDong}
                                        </Box>
                                    </Cell>
                                    <Cell style={row.maHuyen == 'sum' ? { fontWeight: 'bold' } : {}} align="center">
                                        <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("TN", row.tenHuyen)}>
                                            {row.tamNgung}
                                        </Box>
                                    </Cell>
                                    <Cell style={row.maHuyen == 'sum' ? { fontWeight: 'bold' } : {}} align="center">
                                        <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("CD", row.tenHuyen)}>
                                            {row.chamDut}
                                        </Box>
                                    </Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={4} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu
                                </Cell>
                            </Row>
                        )}
                    </TableBody>
                </Table>
                {props.dataList.length > 0 && (
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination
                            count={totalPage}
                            defaultPage={1}
                            siblingCount={1}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={props.onChangePage}
                        />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">
                                Số mẫu tin trên trang
                            </Box>
                            <NativeSelect value={props.rowsPerPage} input={<CustomInput />} onChange={props.onChangeRowsPerPage}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">
                            Mẫu tin {(props.page - 1) * props.rowsPerPage + 1} -{" "}
                            {props.count > props.page * props.rowsPerPage ? props.page * props.rowsPerPage : props.count} / {props.count}
                        </Box>
                    </Box>
                )}
            </TableContainer>
        </>
    );
};
export default DanhSachThongKeKhuDuLichComponent;
