import { FileEntry } from "../../apis/Common/fileEntry";

export interface GiayPhepToChucNgheThuat {
    id: number;

    tenNhaHat: string;

    soGiayPhep: string;

    soDienThoai: string;

    tenChuongTrinh: string;

    soDienThoaiNguoiDaiDien: string;

    ngayTao: number;

    ngayCapNhat: number;

    ngayCapChungNhan: number;

    ngayCapChungNhanMoi: number;

    resoucePK: number;

    pageSize: number;

    pageNumber: number;

    ngayCap: number;

    ngayHetHan: number;

    tuNgay: number;

    denNgay: number;

    maHuyen: string;

    tenHuyen: string;

    maPhuong: string;

    tenPhuong: string;

    tenDuong: string;

    soNha: string;

    ngayCapCMND: number;

    thoiGianToChuc: number;

    ngayCapQD: number;

    ngayCapLai: number;

    noiCapCMND: string;

    nguoiTaoId: string;

    className: string;

    maHoSo: string;

    maThuTuc: string;

    tenToChuc: string;

    thoiLuongChuongTrinh: string;

    nguoiChiuTrachNhiem: string;

    tenHuyenToChuc: string;

    maHuyenToChuc: string;

    tenPhuongToChuc: string;

    maPhuongToChuc: string;

    tenDuongToChuc: string;

    maDuongToChuc: string;

    soNhaToChuc: string;

    soCMND: string;

    nguoiDaiDienTheoPhapLuat: string;

    noiDungBieuDien: string;

    noiDungChuongTrinh: string;

    noiDungKhac: string;

    tenTietMuc: string;

    tacGia: string;

    nguoiBieuDien: string;

    ngaySinh: number;

    maTrangThai: string;

    tenTrangThai: string;

    maBienDong: string;

    tenBienDong: string;

    diaChiDayDu: string;

    keywords: string;

    danhDauLuuY: boolean;

    lanCapLai: string;

    files: FileEntry[];

    
    hoatDong: boolean;

    isUpdate: boolean;

    ghiChu: string;

    canCuocPhapLy: string;

    lyDoThuHoi: string;

    ngayThuHoi: number;
}

export interface GiayPhepToChucNgheThuatDTO {
    id: number;

    ngayTao: number;

    ngayCapNhat: number;

    ngayCapChungNhan: number;

    ngayCapChungNhanMoi: number;

    resoucePK: number;

    pageSize: number;

    pageNumber: number;

    ngayHetHan: number;

    ngayHetHanMoi: number;

    ngayHetHanCu: number;

    ngayCapCMND: number;

    ngayCapQD: number;

    ngayCapLai: number;

    noiCapCMND: string;

    nguoiTaoId: string;

    className: string;

    maHoSo: string;

    maThuTuc: string;

    soChungNhan: string;

    soChungChi: string;

    soChungChiCu: string;

    soChungChiMoi: string;

    tenToChuc: string;

    dienThoai: string;

    fax: string;

    maTinh: string;

    tenTinh: string;

    tenHuyen: string;

    maHuyen: string;

    tenPhuong: string;

    maPhuong: string;

    tenDuong: string;

    maDuong: string;

    soNha: string;

    soQD: string;

    noiCapQD: string;

    soCMND: string;

    nguoiDaiDienTheoPhapLuat: string;

    ngaySinh: number;

    chucDanh: string;

    trichYeu: string;

    canCu: string;

    deNghi: string;

    hoTen: string;

    noiSinh: string;

    maQuocTich: string;

    tenQuocTich: string;

    trinhDoChuyenMon: string;

    kinhNghiemChuyenMon: string;

    duocPhepHanhNghe: string;

    maTrangThai: string;

    tenTrangThai: string;

    maBienDong: string;

    tenBienDong: string;

    lyDoCapLai: string;

    soChungNhanMoi: string;

    diaChiDayDu: string;

    keywords: string;

    donViCapPhep: number;

    lanCapLai: string;

    files: FileEntry[];

    fileCapLais: FileEntry[];

    hoatDong: boolean;

    donViCapSoGiayPhep: boolean;

    isCapSoMoi: boolean;

    nhapDauKy: boolean;

    isUpdate: boolean;
    ghiChu: string;
    nguoiKy: string;
}

export interface SearchRequest {
    id: number;

    ngayTao: number;

    ngayCapNhat: number;

    nguoiTaoId: string;

    resoucePK: number;

    className: string;

    maHoSo: string;

    maThuTuc: string;

    soChungNhan: string;

    ngayCapChungNhan: number;

    ngayHetHan: number;

    tenToChuc: string;

    dienThoai: string;

    fax: string;

    maTinh: string;

    tenTinh: string;

    tenHuyen: string;

    maHuyen: string;

    tenPhuong: string;

    maPhuong: string;

    tenDuong: string;

    maDuong: string;

    soNha: string;

    soQD: string;

    ngayCapQD: number;

    noiCapQD: string;

    soCMND: string;

    ngayCapCMND: number;

    noiCapCMND: string;

    nguoiDaiDienTheoPhapLuat: string;

    ngaySinh: number;

    chucDanh: string;

    trichYeu: string;

    canCu: string;

    deNghi: string;

    hoTen: string;

    noiSinh: string;

    maQuocTich: string;

    tenQuocTich: string;

    trinhDoChuyenMon: string;

    kinhNghiemChuyenMon: string;

    duocPhepHanhNghe: string;

    maTrangThai: string;

    tenTrangThai: string;

    maBienDong: string;

    tenBienDong: string;

    lyDoCapLai: string;

    soChungNhanMoi: string;

    diaChiDayDu: string;

    lanCapLai: string;

    donViCapPhep: number;

    ngayCapLai: number;

    ngayCapChungNhanMoi: number;

    hoatDong: boolean;

    donViCapSoGiayPhep: boolean;

    isCapSoMoi: boolean;
}
export const GiayPhepToChucNgheThuat_INIT: GiayPhepToChucNgheThuat = {
    id: 0,

    tenNhaHat: '',

    soGiayPhep: '',

    soDienThoai: '',

    tenChuongTrinh: '',

    soDienThoaiNguoiDaiDien: '',

    ngayTao: 0,

    ngayCapNhat: 0,

    ngayCapChungNhan: 0,

    ngayCapChungNhanMoi: 0,

    resoucePK: 0,

    pageSize: 0,

    pageNumber: 0,

    ngayCap: 0,

    ngayHetHan: 0,

    tuNgay: 0,

    denNgay: 0,

    maHuyen: "",

    tenHuyen: "",

    maPhuong: "",

    tenPhuong: "",

    tenDuong: "",

    soNha: "",

    ngayCapCMND: 0,

    thoiGianToChuc: 0,

    ngayCapQD: 0,

    ngayCapLai: 0,

    noiCapCMND: "",

    nguoiTaoId: "",

    className: "",

    maHoSo: "",

    maThuTuc: "",

    tenToChuc: "",

    thoiLuongChuongTrinh: "",

    nguoiChiuTrachNhiem: "",

    tenHuyenToChuc: "",

    maHuyenToChuc: "",

    tenPhuongToChuc: "",

    maPhuongToChuc: "",

    tenDuongToChuc: "",

    maDuongToChuc: "",

    soNhaToChuc: "",

    soCMND: "",

    nguoiDaiDienTheoPhapLuat: "",

    noiDungBieuDien: "",

    ngaySinh: 0,

    noiDungChuongTrinh: "",

    noiDungKhac: "",

    tenTietMuc: "",

    tacGia: "",

    nguoiBieuDien: "",

    maTrangThai: "",

    tenTrangThai: "",

    maBienDong: "",

    tenBienDong: "",

    diaChiDayDu: "",

    keywords: "",

    danhDauLuuY: true,

    lanCapLai: "",

    files: [],

    hoatDong: true,

    isUpdate: false,

    ghiChu: "",

    canCuocPhapLy: "",

    lyDoThuHoi: "",

    ngayThuHoi: 0,
};
