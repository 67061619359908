import MomentUtils from "@date-io/moment";
import { Box, Checkbox, createStyles, FormControlLabel, Grid, InputLabel, makeStyles, Snackbar, TextField, Theme, Typography } from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DropZoneCustom from "components/commons/hoso/CustomDropzone/DropZoneCustom";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../../apis";
import { createFileType, downLoadFile } from "../../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../../apis/Common/FileServerService";
import { GiayChungChiDiSanVanHoaService } from "../../../../../apis/DiSanVanHoa/GiayChungChiDiSanVanHoaService";
import { HoSoThuLyDiSanVanHoaService } from "../../../../../apis/DiSanVanHoa/HoSoThuLyDiSanVanHoaService";
import { BieuMauAttachFileDTO } from "apis/danhMucCN";
import { ACCEPT_FILES, LOAIBIENDONG_CAPLAI, TINHTRANG_HOATDONG } from "../../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDiSanVanHoa_INIT } from "../../../../../init/DiSanVanHoa/HoSoThuLyDiSanVanHoa_INIT";
import { GiayChungChiDiSanVanHoa } from "../../../../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoa";
import { HoSoThuLyDiSanVanHoa } from "../../../../../model/hoSoThuLyDiSanVanHoa";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import StringUtil from "../../../../../utils/stringUtils";
import { ConfirmationDialogRaw } from "../../../../commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import ThuLyButtonComponent from "../../../../commons/hoso/thuly/ThuLyButtonComponent";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import DiSanVanHoaButtonComponent from "../../Action/DiSanVanHoaButtonComponent";
import { ThongTinDungChungService } from "apis/hoSoCuaToi/thongTinDungChungService";
import { HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import DanhSachDuThaoModal from "../../../../commons/hoso/thuly/danhSachDuThaoModal";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const ChungChiTuBoDiTichComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [showChuyen, setShowChuyen] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [currentDate, setCurrentDate] = useState(0);
    const [dataAutocomple, setDataAutocomple] = useState<HoSoThuLyDiSanVanHoa[]>(new Array<HoSoThuLyDiSanVanHoa>());
    const [dataSoGiayPhep, setDataSoGiayPhep] = useState({ ...HoSoThuLyDiSanVanHoa_INIT });
    const [capLaiChungChiDiSanVanHoa, setCapLaiChungChiDiSanVanHoa] = useState({ ...HoSoThuLyDiSanVanHoa_INIT });
    const classes = useStyles();
    const hoSoThuLyDiSanVanHoaService = new HoSoThuLyDiSanVanHoaService();
    const giayChungChiDiSanVanHoaService = new GiayChungChiDiSanVanHoaService();
    const fileServerService = new FileServerService();
    const thongTinDungChungService = new ThongTinDungChungService();
    const [open, setOpen] = useState(false);
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        hoSoThuLyDiSanVanHoaService
            .getByMaHoSos(maHS)
            .then((res) => {
                if (StringUtil.hasData(res)) {
                    const dataNew = {
                        ...res?.data?.data,
                        maHoSo: maHS,
                        maThuTuc: maThuTuc,
                        tenQuocTich: "",
                        className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                    };
                    setCapLaiChungChiDiSanVanHoa(dataNew);
                    setDataSoGiayPhep(res?.data?.data);
                } else {
                    const dataNew = {
                        ...HoSoThuLyDiSanVanHoa_INIT,
                        maHoSo: maHS,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPLAI.ma,
                        tenBienDong: LOAIBIENDONG_CAPLAI.ten,
                    };
                    setCapLaiChungChiDiSanVanHoa(dataNew);
                }
            })
            .catch((error) => {
                console.log("error", error);
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleSubmit = async () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.maHoSo)) {
            setAlert({
                showAlert: true,
                message: "Không có mã hồ sơ",
                severity: "warning",
            });
            return;
        }
        if (StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.soChungChi)) {
            return;
        }
        if (StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.ngayCapChungChi)) {
            return;
        }
        if (StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.lyDoCapLai)) {
            return;
        }
        if (StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.ngayCapLai)) {
            return;
        }
        if (capLaiChungChiDiSanVanHoa.ngayCapLai < capLaiChungChiDiSanVanHoa.ngayCapChungChi) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp lại không được nhỏ hơn ngày cấp",
                severity: "warning",
            });
            return;
        }
        if (capLaiChungChiDiSanVanHoa.ngayCapChungChi > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (capLaiChungChiDiSanVanHoa.isCapSoMoi && capLaiChungChiDiSanVanHoa.donViCapSoGiayPhep) {
            if (StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.soChungChiMoi)) {
                return;
            }
            if (StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi)) {
                return;
            }
            if (capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi > 0 && capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi > currentDate) {
                setAlert({
                    showAlert: true,
                    message: "Ngày cấp không được lớn hơn ngày hiện tại",
                    severity: "warning",
                });
                return;
            }
        }
        dispatchLoading(showLoading());
        await hoSoThuLyDiSanVanHoaService
            .capLai(capLaiChungChiDiSanVanHoa)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setCapLaiChungChiDiSanVanHoa({
                        ...capLaiChungChiDiSanVanHoa,
                        id: response.data.data.id,
                        resoucePK: response.data.data.resoucePK,
                        ngayTao: response.data.data.ngayTao,
                    });

                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                    setShowChuyen(true);
                } else if (response.data.error_code === "409") {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...capLaiChungChiDiSanVanHoa, [filedName]: valueNew };
        setCapLaiChungChiDiSanVanHoa(dataNew);
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setCapLaiChungChiDiSanVanHoa((data) => ({
            ...capLaiChungChiDiSanVanHoa,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };
    const handleResetData = () => {
        const dataNew = {
            ...HoSoThuLyDiSanVanHoa_INIT,
            id: capLaiChungChiDiSanVanHoa.id,
            maHoSo: capLaiChungChiDiSanVanHoa.maHoSo,
            maThuTuc: capLaiChungChiDiSanVanHoa.maThuTuc,
            resoucePK: capLaiChungChiDiSanVanHoa.resoucePK,
            className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
            tenQuocTich: "",
            maTrangThai: LOAIBIENDONG_CAPLAI.ma,
            tenTrangThai: LOAIBIENDONG_CAPLAI.ten,
        };
        setCapLaiChungChiDiSanVanHoa(dataNew);
        setDataSoGiayPhep({ ...HoSoThuLyDiSanVanHoa_INIT });
    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(capLaiChungChiDiSanVanHoa.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };
    
    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {};

    const handleShowCapMoi = () => {
        setCapLaiChungChiDiSanVanHoa({
            ...capLaiChungChiDiSanVanHoa,
            isCapSoMoi: !capLaiChungChiDiSanVanHoa.isCapSoMoi,
        });
    };

    const handleBlur = (event: any) => {
        if (!StringUtil.listIsNotEmty(capLaiChungChiDiSanVanHoa)) {
            if (capLaiChungChiDiSanVanHoa && capLaiChungChiDiSanVanHoa?.id > 0) {
                setDataSoGiayPhep(capLaiChungChiDiSanVanHoa);
            } else {
                setDataSoGiayPhep({ ...HoSoThuLyDiSanVanHoa_INIT });
            }
        }
    };

    const handleSelectSoChungChiDaCap = async (newValue: GiayChungChiDiSanVanHoa) => {
        let giayChungChiDiSanVanHoaID = newValue?.id;
        if (giayChungChiDiSanVanHoaID && giayChungChiDiSanVanHoaID > 0) {
            await giayChungChiDiSanVanHoaService
                .getBySoGiayChungChiDiSanVanHoa(giayChungChiDiSanVanHoaID)
                .then((res) => {
                    let selectChungChiDiSanVanHoa = res?.data.data;
                    setCapLaiChungChiDiSanVanHoa({
                        ...selectChungChiDiSanVanHoa,
                        soChungChiCu: selectChungChiDiSanVanHoa?.soChungChi,
                        ngayCapChungChiCu: selectChungChiDiSanVanHoa?.ngayCapChungChi,
                        ngayHetHanCu: selectChungChiDiSanVanHoa?.ngayHetHan,
                        isCapSoMoi: capLaiChungChiDiSanVanHoa.isCapSoMoi,
                        soChungChiMoi: capLaiChungChiDiSanVanHoa.soChungChiMoi,
                        ngayCapChungChiMoi: capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi,
                        ngayHetHanMoi: capLaiChungChiDiSanVanHoa.ngayHetHanMoi,
                        lyDoCapLai: capLaiChungChiDiSanVanHoa.lyDoCapLai,
                        lanCapLai: selectChungChiDiSanVanHoa.lanCapLai + 1,
                        ngayCapLai: capLaiChungChiDiSanVanHoa.ngayCapLai,
                        maHoSo: capLaiChungChiDiSanVanHoa.maHoSo,
                        maThuTuc: capLaiChungChiDiSanVanHoa.maThuTuc,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPLAI.ma,
                        tenBienDong: LOAIBIENDONG_CAPLAI.ten,
                        resoucePK: selectChungChiDiSanVanHoa.id,
                        fileCapLais: new Array<FileEntry>(),
                        id: capLaiChungChiDiSanVanHoa.id,
                    });
                    setDataSoGiayPhep(selectChungChiDiSanVanHoa);
                })
                .catch((error) => {});
        }
    };

    const handleChangeSoGiayChungNhan = async (event: any) => {
        let soGiayChungChiDaCap = event.target.value;
        setDataSoGiayPhep({
            ...dataSoGiayPhep,
            soChungChi: event.target.value,
        });
        await giayChungChiDiSanVanHoaService
            .getByAutocompleteSoGiayChungNhan(soGiayChungChiDaCap, true, 10)
            .then((res) => {
                let dataAutocompleNew = res?.data.data;
                if (StringUtil.listIsNotEmty(dataAutocompleNew)) {
                    setDataAutocomple([...dataAutocompleNew]);
                } else {
                    setDataAutocomple(new Array<HoSoThuLyDiSanVanHoa>());
                }
            })
            .catch((error) => {});
    };
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...capLaiChungChiDiSanVanHoa.fileCapLais];
        mems = mems.concat(memData);
        let dataNew = {
            ...capLaiChungChiDiSanVanHoa,
            ["fileCapLais"]: mems,
        };
        setCapLaiChungChiDiSanVanHoa(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...capLaiChungChiDiSanVanHoa.fileCapLais];
        mems.splice(index, 1);
        let dataNew = {
            ...capLaiChungChiDiSanVanHoa,
            ["fileCapLais"]: mems,
        };
        setCapLaiChungChiDiSanVanHoa(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dispatchLoading(showLoading());
        giayChungChiDiSanVanHoaService
            .downloadFileBieuMauGiayChungChi({ templateId: bieuMau.fileId, GiayChungChiDiSanVanHoa: capLaiChungChiDiSanVanHoa.resoucePK })
            .then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>
                                CẤP LẠI CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH - MÃ HỒ SƠ {capLaiChungChiDiSanVanHoa.maHoSo}{" "}
                            </h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Số chứng chỉ đã cấp
                                    </InputLabel>
                                    <Autocomplete
                                        classes={inputStyles}
                                        options={dataAutocomple}
                                        value={dataSoGiayPhep}
                                        noOptionsText={"Không có dữ liệu"}
                                        getOptionLabel={(option) => option.soChungChi}
                                        onChange={(event: any, newValue: any) => handleSelectSoChungChiDaCap(newValue)}
                                        onClose={handleBlur}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                onChange={(e: any) => handleChangeSoGiayChungNhan(e)}
                                                error={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.soChungChi)}
                                                helperText={
                                                    submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.soChungChi) ? "Vui lòng nhập số chứng nhận" : ""
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}> Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled={true}
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={
                                                capLaiChungChiDiSanVanHoa.ngayCapChungChi && capLaiChungChiDiSanVanHoa.ngayCapChungChi != 0
                                                    ? new Date(capLaiChungChiDiSanVanHoa.ngayCapChungChi)
                                                    : null
                                            }
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungChi")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled={true}
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={
                                                capLaiChungChiDiSanVanHoa.ngayHetHan && capLaiChungChiDiSanVanHoa.ngayHetHan != 0
                                                    ? new Date(capLaiChungChiDiSanVanHoa.ngayHetHan)
                                                    : null
                                            }
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <Box style={{ marginTop: "6%" }}>
                                        <Checkbox
                                            checked={capLaiChungChiDiSanVanHoa.isCapSoMoi}
                                            name="showCaoMoi"
                                            value={capLaiChungChiDiSanVanHoa.isCapSoMoi}
                                            onChange={handleShowCapMoi}
                                        />{" "}
                                        Cấp số mới
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {capLaiChungChiDiSanVanHoa.isCapSoMoi && (
                                            <>
                                                <Grid item xs={3} className="grid-item">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={capLaiChungChiDiSanVanHoa.donViCapSoGiayPhep}
                                                                name="donViCapSoGiayPhep"
                                                                value={capLaiChungChiDiSanVanHoa.donViCapSoGiayPhep}
                                                                onChange={handleChange}
                                                            />
                                                        }
                                                        label="Đơn vị cấp số giấy phép "
                                                    />
                                                </Grid>
                                                {capLaiChungChiDiSanVanHoa.donViCapSoGiayPhep && (
                                                    <>
                                                        <Grid item xs={3} className="grid-item">
                                                            <InputLabel classes={labelStyles} required>
                                                                Số giấy chứng chỉ
                                                            </InputLabel>
                                                            <TextField
                                                                fullWidth
                                                                name="soChungChiMoi"
                                                                InputLabelProps={{ classes: labelStyles }}
                                                                value={capLaiChungChiDiSanVanHoa.soChungChiMoi}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    classes: inputStyles,
                                                                }}
                                                                onChange={handleChange}
                                                                error={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.soChungChiMoi)}
                                                                helperText={
                                                                    submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.soChungChiMoi)
                                                                        ? "Vui lòng nhập số chứng nhận"
                                                                        : ""
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} className="grid-item">
                                                            <InputLabel classes={labelStyles} required>
                                                                Ngày cấp
                                                            </InputLabel>
                                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                                <KeyboardDatePicker
                                                                    autoOk={true}
                                                                    showTodayButton={true}
                                                                    value={
                                                                        capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi &&
                                                                        capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi != 0
                                                                            ? new Date(capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi)
                                                                            : null
                                                                    }
                                                                    format="DD/MM/YYYY"
                                                                    fullWidth
                                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                                    variant="inline"
                                                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                                    onChange={(date: any, value: any) =>
                                                                        handleChangeDatePicker(date, value, "ngayCapChungChiMoi")
                                                                    }
                                                                    error={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi)}
                                                                    helperText={
                                                                        submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.ngayCapChungChiMoi)
                                                                            ? "Vui lòng nhập ngày"
                                                                            : ""
                                                                    }
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item xs={3} className="grid-item">
                                                            <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                                <KeyboardDatePicker
                                                                    autoOk={true}
                                                                    showTodayButton={true}
                                                                    value={
                                                                        capLaiChungChiDiSanVanHoa.ngayHetHanMoi && capLaiChungChiDiSanVanHoa.ngayHetHanMoi != 0
                                                                            ? new Date(capLaiChungChiDiSanVanHoa.ngayHetHanMoi)
                                                                            : null
                                                                    }
                                                                    format="DD/MM/YYYY"
                                                                    fullWidth
                                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                                    variant="inline"
                                                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHanMoi")}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} style={{ marginTop: "2%" }}>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Họ tên</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa.hoTen}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Ngày sinh</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>
                                            {capLaiChungChiDiSanVanHoa?.ngaySinh ? moment(capLaiChungChiDiSanVanHoa?.ngaySinh).format("DD/MM/YYYY") : ""}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Nơi sinh</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa?.noiSinh}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Quốc tịch</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa?.tenQuocTich}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Số CMND</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa?.soCMND}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Ngày cấp</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>
                                            {capLaiChungChiDiSanVanHoa?.ngayCapChungChi
                                                ? moment(capLaiChungChiDiSanVanHoa?.ngayCapChungChi).format("DD/MM/YYYY")
                                                : ""}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Nơi cấp</InputLabel>
                                    </Grid>
                                    <Grid item xs={9} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa?.noiCapCMND}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Địa chỉ</InputLabel>
                                    </Grid>
                                    <Grid item xs={9} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa?.diaChiDayDu}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Trình độ chuyên môn</InputLabel>
                                    </Grid>
                                    <Grid item xs={9} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa?.trinhDoChuyenMon}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Kinh nghiệm chuyên môn</InputLabel>
                                    </Grid>
                                    <Grid item xs={9} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa?.kinhNghiemChuyenMon}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Được phép hành nghề hoạt động</InputLabel>
                                    </Grid>
                                    <Grid item xs={9} className="grid-item">
                                        <InputLabel>{capLaiChungChiDiSanVanHoa?.duocPhepHanhNghe}</InputLabel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    )}
                    <Grid item xs={6} className="grid-item">
                        <InputLabel classes={labelStyles} required>
                            Lý do cấp lại
                        </InputLabel>
                        <TextField
                            fullWidth
                            name="lyDoCapLai"
                            InputLabelProps={{ classes: labelStyles }}
                            value={capLaiChungChiDiSanVanHoa.lyDoCapLai}
                            InputProps={{
                                disableUnderline: true,
                                classes: inputStyles,
                            }}
                            onChange={handleChange}
                            error={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.lyDoCapLai)}
                            helperText={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.lyDoCapLai) ? "Vui lòng nhập lý do cấp lại" : ""}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} required>
                            Lần cấp lại
                        </InputLabel>
                        <TextField
                            fullWidth
                            name="lanCapLai"
                            InputLabelProps={{ classes: labelStyles }}
                            value={capLaiChungChiDiSanVanHoa.lanCapLai}
                            InputProps={{
                                disableUnderline: true,
                                classes: inputStyles,
                            }}
                            onChange={handleChange}
                            error={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.lanCapLai)}
                            helperText={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.lanCapLai) ? "Vui lòng nhập số lần cấp lại" : ""}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} required>
                            Ngày cấp lại{" "}
                        </InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                value={
                                    capLaiChungChiDiSanVanHoa.ngayCapLai && capLaiChungChiDiSanVanHoa.ngayCapLai != 0
                                        ? new Date(capLaiChungChiDiSanVanHoa.ngayCapLai)
                                        : null
                                }
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapLai")}
                                error={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.ngayCapLai)}
                                helperText={submit && StringUtil.isNullOrEmty(capLaiChungChiDiSanVanHoa.ngayCapLai) ? "Vui lòng nhập ngày cấp lại" : ""}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} container className="grid-item">
                        <DropZoneCustom
                            acceptFiles={ACCEPT_FILES}
                            attachFileServer={capLaiChungChiDiSanVanHoa.fileCapLais}
                            url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                            handleChangeStatus={handleChangeStatus}
                            handleDeleteFileServer={handleDeleteFileServer}
                            handleDownloadFile={handleDownloadFile}
                        />
                    </Grid>
                    <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        {/* <DiSanVanHoaButtonComponent
                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                            onSubmit={handleSubmit}
                            onResetData={handleResetData}
                            onOpenDialog={handleOpenDialog}
                        /> */}
                        <ThuLyButtonComponent
                            showIn={showChuyen}
                            onSubmit={handleSubmit}
                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                            onDelete={handleResetData}
                            handleChuyen={showHidePopperThaoTac}
                            handleDuThao={showHidePopperDuThao}
                            dataButton= {capLaiChungChiDiSanVanHoa}
                        />
                    </Grid>
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
             {showDanhSachDuThaoModal && (
               <DanhSachDuThaoModal hoso={capLaiChungChiDiSanVanHoa} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}
        </>
        </div>
    );
};
export default ChungChiTuBoDiTichComponent;
