import { GiayChungNhanKinhDoanhDichVuLuuTruDTO, LichSuGiayChungNhanDichVuDuLich, TraCuuCoSoDuLichDTO } from "../../model/DichVuDuLich/DichVuDuLichModel";
import { CoSoVatChatLuuTruDTO_INIT, NhanSuCoSoLuuTruDTO_INIT } from "./HoSoThuLyDichVuDuLich_INIT";

export const LichSuGiayChungNhanDichVuDuLich_INIT: LichSuGiayChungNhanDichVuDuLich = {
    id: 0,

    giayChungNhanId: 0,

    ngayThuHoi: 0,

    hoSoThuLyId: 0,

    nguoiTaoId: 0,

    lanCapLai: "",

    maBienDong: "",

    tenBienDong: "",

    maTrangThai: "",

    tenTrangThai: "",

    ngayBienDong: 0,

    lyDoThuHoi: "",

    maTinh: "",

    tenTinh: "",

    maHuyen: "",

    tenHuyen: "",

    maPhuong: "",

    tenPhuong: "",

    ghiChu: "",

    soQuyetDinhThuHoi: "",

    files: [],
};
export const TraCuuCoSoDuLichDTO_INIT: TraCuuCoSoDuLichDTO = {
    giayChungNhanId: 0,

    soQuyetDinh: "",

    tenCoSo: "",

    maTrangThai: "",

    tenTrangThai: "",

    maHuyen: "",

    tenHuyen: "",

    maPhuong: "",

    tenPhuong: "",

    ngayCapTu: 0,

    ngayCapDen: 0,

    pageSize: 5,

    pageNumber: 1,

    exportType: "",

    exportFileName: "",

    kyThongKe: 1,

    namThongKe: 0,

    tenNamThongKe: "Chọn năm",

    maLoaiHinhCoSo: "",

    maHangCoSo: "",

    tenLoaiHinhCoSo: "",

    tenHangCoSo: "",

    maTinhTrang: "",

    maHuyenString: "",

    loaiBieuDo: 0,
};
export const GiayChungNhanKinhDoanhDichVuLuuTruDTO_INIT: GiayChungNhanKinhDoanhDichVuLuuTruDTO = {
    hoSoThuLyId: 0,
    giayChungNhanId: 0,
    nguoiTaoId: "",
    ngayCapNhat: 0,
    ngayTao: 0,
    hoatDong: true,
    maHoSo: "",
    maLinhVuc: "",
    maThuTuc: "",
    soQuyetDinh: "",
    ngayCap: 0,
    ngayHetHan: 0,
    donViCapPhep: false,
    tenCoSo: "",
    maLoaiHinhCoSo: "",
    tenLoaiHinhCoSo: "",
    maHangCoSo: "",
    tenHangCoSo: "",
    soGCN: "",
    ngayCapGCN: 0,
    noiCapGCN: "",
    coQuanCapGCN: "",
    fax: "",
    email: "",
    website: "",
    soDienThoai: "",
    maHuyen: "",
    tenHuyen: "",
    maPhuong: "",
    tenPhuong: "",
    maDuong: "",
    tenDuong: "",
    soNha: "",
    diaChiDayDu: "",
    nguoiLienHe: "",
    dienThoaiNguoiLienHe: "",
    maTrangThai: "",
    tenTrangThai: "",
    maBienDong: "",
    tenBienDong: "",
    lyDo: "",
    ghiChu: "",
    duDieuKienTranTu: false,
    phongChayChuaChay: false,
    baoVeMoiTruong: false,
    anToanThucPham: false,
    tongSoNguoi: "",
    coSoVatChatLuuTruDTO: { ...CoSoVatChatLuuTruDTO_INIT },
    nhanSuCoSoLuuTruDTO: { ...NhanSuCoSoLuuTruDTO_INIT },
    buongLuuTruDTOs: [],
    files: [],
    lichSus: [],
    maMauTrangThai: 0,
};
