import AXIOS_INSTANCE from "..";
import { DiSanVanHoaVatThe, DiSanVanHoaVatThe_INT, ThongKeDSVHVatThe, ThongKeDSVHVatTheResponse } from "../../model/diSanVanHoaVatThe";
import { ExportVTRequest } from "../../pages/diSanVanHoa/vatThe/baoCaoDiSanVanHoaVatThe";
import { SearchVTRequest } from "../../pages/diSanVanHoa/vatThe/searchDiSanVanHoaVatThe";
import { ThongKeVTRequest } from "../../pages/diSanVanHoa/vatThe/thongKeDiSanVanHoaVatThe";
import { BaseService } from "../baseService";

export class DiSanVanHoaVatTheService extends BaseService {
    getFullList = async (): Promise<{ rows: DiSanVanHoaVatThe[]; total: number }> => {
        var list: DiSanVanHoaVatThe[] = [];
        var total: number = 0;
        await AXIOS_INSTANCE.post("/di-san-van-hoa-vat-the/search", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        total = response.data.data.total;
                        list = response.data.data.data;
                    }
                }
            })
            .catch((error) => {});
        return { rows: list, total: total };
    };

    getList = async (request: SearchVTRequest): Promise<{ rows: DiSanVanHoaVatThe[]; total: number }> => {
        var list: DiSanVanHoaVatThe[] = [];
        var total: number = 0;
        await AXIOS_INSTANCE.post("/di-san-van-hoa-vat-the/search", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        total = response.data.data.total;
                        list = response.data.data.data;
                    }
                }
            })
            .catch((error) => {});
        return { rows: list, total: total };
    };

    thongKe = async (request: ThongKeVTRequest): Promise<ThongKeDSVHVatTheResponse> => {
        // var list: ThongKeDSVHVatTheResponse[] = [];
        let result = {} as ThongKeDSVHVatTheResponse;
        await AXIOS_INSTANCE.post("/di-san-van-hoa-vat-the/thong-ke", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                    if (response.data.data) {
                        result = response.data.data;
                    }
            })
            .catch((error) => {});
        return result;
    };

    downloadFileBaoCao = async (request: ExportVTRequest) => {
        return AXIOS_INSTANCE.get("/di-san-van-hoa-vat-the/export", {
            responseType: "blob",
            params: request,
        });
    };

    downloadFileThongKe = async (request: ThongKeVTRequest) => {
        return AXIOS_INSTANCE.get("/di-san-van-hoa-vat-the/download-thong-ke", {
            responseType: "blob",
            params: request,
        });
    };

    downloadFileTraCuu = async (request: SearchVTRequest) => {
        return AXIOS_INSTANCE.get("/di-san-van-hoa-vat-the/download-tra-cuu", {
            responseType: "blob",
            params: request,
        });
    };

    getDetailById = async (id: Number): Promise<DiSanVanHoaVatThe> => {
        var result: DiSanVanHoaVatThe = DiSanVanHoaVatThe_INT;
        await AXIOS_INSTANCE.post(`di-san-van-hoa-vat-the/get-detail-by-id/${id}`, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    result = { ...response.data.data };
                }
            })
            .catch();
        return result;
    };

    insertOrUpdate = (data: DiSanVanHoaVatThe) => {
        return AXIOS_INSTANCE.post("/di-san-van-hoa-vat-the/add-or-update", data, this.getTokenRequestHeaders());
    };

    chiTietThongKe = (maHuyenString?: string, index?: number, maHuyen?: string, ngayCapTu?: number, ngayCapDen?: number, namThongKe?: number) => {
        let body = {
            index: index,
            maHuyenString: maHuyenString,
            maHuyen: maHuyen,
            ngayCapTu: ngayCapTu,
            ngayCapDen: ngayCapDen,
            namThongKe: namThongKe,
        };
        return AXIOS_INSTANCE.post("/di-san-van-hoa-vat-the/chi-tiet-thong-ke", {body});
    };

    // insertOrUpdate = async (data: DiSanVanHoaVatThe): Promise<BaseResponse> => {
    //     let requestBody = {
    //         id: data.id,
    //         soQuyetDinh: data.soQuyetDinh,
    //         ngayQuyetDinh: data.ngayQuyetDinh,
    //         capDiSanId: data.capDiSanId,
    //         tenCapDiSan: data.tenCapDiSan,
    //         loaiDiSanId: data.loaiDiSanId,
    //         tenLoaiDiSan: data.tenLoaiDiSan,
    //         tenDiSan: data.tenDiSan,
    //         diaChiKhiXepHang: data.diaChiKhiXepHang,
    //         tenHuyen: data.tenHuyen,
    //         maHuyen: data.maHuyen,
    //         tenPhuong: data.tenPhuong,
    //         maPhuong: data.maPhuong,
    //         tenDuong: data.tenDuong,
    //         maDuong: data.maDuong,
    //         soNha: data.soNha
    //     } as DiSanVanHoaVatThe;
    //     let result = {} as BaseResponse;
    //     await AXIOS_INSTANCE.post('/di-san-van-hoa-vat-the/add-or-update', JSON.stringify(requestBody), this.getTokenRequestHeaders())
    //         .then(response => {
    //             if (response.status !== 200) {
    //                 result = { isSuccess: false, message: 'Có lỗi xảy ra, thực hiện lại!' };
    //             }
    //             result = {
    //                 isSuccess: response.data.error_code === 'SUCCESSFUL',
    //                 message: response.data.error_message,
    //                 data: response.data.data
    //             };
    //         }).catch((error) => {
    //             result = { isSuccess: false, message: 'Lỗi khi thực hiện kết nối server' };
    //         });
    //     return result
    // }
}
