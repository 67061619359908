import AXIOS_INSTANCE from "..";
import { BaseService } from "../baseService";
export class ThongTinDungChungService extends BaseService {
    
    getThongTinCongDan(soCMND: any) {
        return AXIOS_INSTANCE.post("/lgsp/info-cong-dan-by-cmnd", {"soCMND": soCMND});
    }

    getThongTinDoanhNghiep(maSoDoanhNghiep: any) {
        return AXIOS_INSTANCE.post("/lgsp/info-doanh-nghiep-by-ma-so", {"maSoDoanhNghiep": maSoDoanhNghiep});
    }
}
