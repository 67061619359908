import { Box, Grid, Snackbar, Typography } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { GiayChungNhanAnUongService } from "../../../../../apis/DichVuDuLich/GiayChungNhanAnUongService";
import { LOAIBIENDONG_TAMNGUNG, LOAIBIENDONG_THUHOI, TINHTRANG_TAMNGUNG, TINHTRANG_THUHOI } from "../../../../../constants/DichVuDuLichConstants";
import { DUPLICATE } from "../../../../../constants/DiSanVanHoaConstants";
import { GiayChungNhanKinhDoanh_INIT } from "../../../../../init/DichVuDuLich/GiayChungNhanKinhDoanh_INIT";
import { LichSuDichVuDuLich_INIT } from "../../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { GiayChungNhanKinhDoanh } from "../../../../../model/DichVuDuLich/GiayChungNhanKinhDoanh";
import { LichSuDichVuDuLichDTO } from "../../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import StringUtil from "../../../../../utils/stringUtils";
import { ConfirmationDialogRaw } from "../../../../commons/dialog/confirmDialog";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import TepTinDinhKemTuBoDiTichComponent from "../../../../DiSanVanHoa/GiaoDienThuLy/ChungChiTuBoDiTich/ThuHoi/TepTinDinhKemTuBoDiTichComponent";
import ThuHoiTuBoDiTichButtonComponent from "../../../../DiSanVanHoa/GiaoDienThuLy/ChungChiTuBoDiTich/ThuHoi/ThuHoiTuBoDiTichButtonComponent";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import LichSuGiayChungNhanKinhDoanhAnUong from "../../DanhSach/ChiTiet/LichSuGiayChungNhanKinhDoanhAnUong";
import ThongTinChungTamNgungGCNAnUongComponent from "./ThongTinChungTamNgungGCNAnUongComponent";
import ThongTinChungThuHoiGCNAnUongComponent from "./ThongTinChungTamNgungGCNAnUongComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const TamNgungGCNKinhDoanhAnUongComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [currentDate, setCurrentDate] = useState(0);
    const [giayChungNhanId, setGiayChungNhanId] = useState(0);
    const [hoSoThuLyId, setHoSoThuLyId] = useState(0);
    const [thuHoi, setThuHoi] = useState<LichSuDichVuDuLichDTO>({ ...LichSuDichVuDuLich_INIT });
    const [data, setData] = useState<GiayChungNhanKinhDoanh>({
        ...GiayChungNhanKinhDoanh_INIT,
        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
    });
    const giayChungNhanAnUongService = new GiayChungNhanAnUongService();
    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        let giayChungNhanId = location.state?.giayChungNhanId;
        let hoSoThuLyId = location.state?.hoSoThuLyId;
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        await loadGiayChungNhan(giayChungNhanId, "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong");
        await loadThongTinThuHoi(giayChungNhanId, hoSoThuLyId, "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong");
    };

    const loadGiayChungNhan = (giayChungNhanId: number, classNameGCN: string) => { 
        giayChungNhanAnUongService
            .chiTietGiayChungNhanById(giayChungNhanId)
            .then((res) => {
                if (StringUtil.hasDataGiayPhep(res)) {
                    const dataNew = {
                        ...res?.data?.data,
                        maTrangThai: TINHTRANG_TAMNGUNG.ma,
                        tenTrangThai: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                        classNameGCN: classNameGCN,
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {});
    };

    const loadThongTinThuHoi = (giayChungNhanId: number, hoSoThuLyId: number, classNameGCN: string) => {
        giayChungNhanAnUongService
            .chiTietGiayTamNgung(giayChungNhanId, classNameGCN)
            .then((res) => {
                console.log("data", res);
                
                if (StringUtil.hasDataGiayPhep(res)) {
                    setThuHoi(res?.data.data);
                } else {
                    const dataNew = {
                        ...thuHoi,
                        giayChungNhanId: giayChungNhanId,
                        hoSoThuLyId: hoSoThuLyId,
                        maTrangThai: TINHTRANG_TAMNGUNG.ma,
                        tenTrangThai: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
                    };
                    setThuHoi(dataNew);
                }
            })
            .catch((error) => {})
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setThuHoi(dataNew);
        } else {
            let dataNew = {
                ...thuHoi,
                [name]: value,
            };
            setThuHoi(dataNew);
        }
    };

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };
    const handleResetData = () => {
        const dataNew = {
            ...thuHoi,
            lyDo: "",
            ngayQuyetDinhThuHoi: 0,
            ghiChu: "",
            soQuyetDinhThuHoi: "",
            maTrangThai: TINHTRANG_TAMNGUNG.ma,
            tenTrangThai: TINHTRANG_TAMNGUNG.ten,
            maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
            tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
            classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
            files: new Array<FileEntry>(),
        };
        setThuHoi(dataNew);
        setSubmit(false);
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const handleSubmit = async () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(thuHoi.lyDo)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(thuHoi.ngayQuyetDinhThuHoi)) {
            setTabRequired(0);
            return;
        }
        
        if(thuHoi.ngayQuyetDinhThuHoi < data.ngayCap){
            setAlert({
                showAlert: true,
                message: "Ngày tạm ngưng không được nhỏ hơn ngày cấp",
                severity: "warning"
            });
            setTabRequired(0);
            return;
        }
        dispatchLoading(showLoading());
        await giayChungNhanAnUongService
            .thuHoi(thuHoi)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response?.data?.data) {
                    setThuHoi({
                        ...thuHoi,
                        id: response?.data?.data?.id,
                        hoSoThuLyId: response?.data?.data?.hoSoThuLyId,
                        giayChungNhanId: response?.data?.data?.giayChungNhanId,
                    });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                    loadGiayChungNhan(thuHoi.giayChungNhanId, "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong");
                } else if (response.data.error_code === DUPLICATE) {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container style={{ float: "left", paddingTop: "20px" }} item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>TẠM NGƯNG BIỂN HIỆU CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG</h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab style={tabRequired == 0 ? { color: "red" } : {}} label="Thông tin chung" />
                                {/* <CustomTab label="Tệp tin đính kèm" /> */}
                                {/* <CustomTab label="Lịch sử" /> */}
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <ThongTinChungTamNgungGCNAnUongComponent data={data} onUpdateData={setDataParam} submit={submit} thuHoi={thuHoi} />
                        </TabPanel>
                    )}
                    {/* {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <TepTinDinhKemTuBoDiTichComponent data={data?.files} />
                        </TabPanel>
                    )} */}
                    {/* {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <LichSuGiayChungNhanKinhDoanhAnUong data={data?.lichSus} dataFile={data.files}/>
                        </TabPanel>
                    )} */}
                    <Grid container item xs={12} className="grid-item">
                        <ThuHoiTuBoDiTichButtonComponent
                            onSubmit={handleSubmit}
                            onResetData={handleResetData}
                            onOpenDialog={handleOpenDialog}
                            isChiTiet={false}
                        />
                    </Grid>
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </>
        </div>
    );
};
export default TamNgungGCNKinhDoanhAnUongComponent;
