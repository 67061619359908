import { DiaDiemDuLichRequest } from "model/DiaDiemDuLich/DiaDiemDuLichRequest";

export const DiaDiemDuLichRequest_INIT: DiaDiemDuLichRequest = {
    kyThongKe : 1,
    namThongKe : 0,
    soQuyetDinh: '',
    maQuanHuyen: '',
    tenQuanHuyen : '',
    maPhuongXa: '',
    tenPhuong : '',
    maTinhTrang: '',
    tenTinhTrang: '',
    tenDiemDuLich: '',
    maLoaiHinh: '',  
    maCapDuLich: '',
    tenNamThongKe: '',
    tenCapDuLich :'',
    ngayVanBanTu:0,
    ngayVanBanDen:0,
    ngayHetHanTu:0,
    ngayHetHanDen:0,
    pageSize : 5,
    pageNumber : 1

};
