import { Collapse, Snackbar, Typography } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { CapGiayPhepToChucNTService } from "apis/VanHoaNgheThuat/CapGiayPhepToChucNTService";
import { GiayChungNhanDSVHRequest, GiayChungNhanDSVHRequest_INIT } from "init/GiayChungNhan/GiayChungNhanDSVHRequest";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Pagination } from "../../apis/baseService";
import { GiayChungNhanAnUongService } from "../../apis/DichVuDuLich/GiayChungNhanAnUongService";
import { GiayChungNhanGiaiTriService } from "../../apis/DichVuDuLich/GiayChungNhanGiaiTriService";
import { GiayChungNhanLuuTruService } from "../../apis/DichVuDuLich/GiayChungNhanLuuTruService";
import { GiayChungNhanMuaSamService } from "../../apis/DichVuDuLich/GiayChungNhanMuaSamService";
import { GiayChungNhanTheThaoService } from "../../apis/DichVuDuLich/GiayChungNhanTheThaoService";
import { LuHanhNoiDiaService } from "../../apis/LuHanhNoiDia/LuHanhNoiDiaService";
import TraCuuBanDo from "../../components/commons/componentMap/index";
import { OverlayLoading } from "../../components/overlayLoading/overlayLoading";
import { TraCuuCoSoDuLichDTO_INIT } from "../../init/DichVuDuLich/DichVuDuLichModel_INIT";
import { HoSoThuLyDichVuDuLichSearchRequest_INIT } from "../../init/HoSoThuLyDichVuDuLich/HoSoThuLyDichVuDuLichSearchRequest_INIT";
import { GiayPhepLuHanhNoiDiaRequest_INIT } from "../../init/LuHanhNoiDia/GiayPhepLuHanhNoiDia_INIT";
import { TraCuuCoSoDuLichDTO } from "../../model/DichVuDuLich/DichVuDuLichModel";
import { HoSoThuLyDichVuDuLichSearchRequest } from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { GiayPhepLuHanhNoiDiaRequest } from "../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { MarkerDTO, MarkerDTO_INIT } from "../../model/marker";
import { hideLoading, LoadingAction, showLoading } from "../../redux/actions/applicationAction";
import DateUtil from "../../utils/dateUtils";
export interface WrapperMapTraCuuProps { }

const WrapperMapTraCuu: React.FC<WrapperMapTraCuuProps> = ({ }) => {
    const title = "TRA CỨU BẢN ĐỒ";
    const [type, setType] = React.useState<Number>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    React.useEffect(() => {
        document.title = title.toLowerCase().charAt(0).toUpperCase() + title.toLowerCase().slice(1);
    }, []);

    const dispatch = useDispatch();
    async function handleSubmitSearch(values: MarkerDTO) {
        let rows: MarkerDTO[] = [];
        const page = 1;
        let total = 0;
        const size = 5;
        if (values.classNameGCN === "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong") {
            dispatchLoading(showLoading());
            let requestBody: TraCuuCoSoDuLichDTO = {
                ...TraCuuCoSoDuLichDTO_INIT,
                soQuyetDinh: values.soQuyetDinh,
                tenCoSo: values.tenCoSo,
                maHuyen: values.maHuyen,
                maPhuong: values.maPhuong,
            };
            await new GiayChungNhanAnUongService()
                .searchGiayChungNhanAnuong(requestBody)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.error_code === "SUCCESSFUL") {
                            const data = response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : [];
                            data.forEach((item: any) => {
                                let mauTrangThai = 'green';
                                if (item.maTrangThai == '2') {
                                    mauTrangThai = '#565252';
                                }
                                rows.push({ ...MarkerDTO_INIT, diaChiDayDu: item.diaChiDayDu, maTrangThai: item.maTrangThai, tenTrangThai: item.tenTrangThai, mauTrangThai: mauTrangThai, classNameGCN: values.classNameGCN , soQuyetDinh: item.soQuyetDinh, ngayCap: item.ngayCap, tenCoSo: item.tenCoSo, kinhDo: item.kinhDo, viDo: item.viDo });
                            });
                            total = response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0;
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (values.classNameGCN === "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuMuaSam") {
            dispatchLoading(showLoading());
            let requestBody: TraCuuCoSoDuLichDTO = {
                ...TraCuuCoSoDuLichDTO_INIT,
                soQuyetDinh: values.soQuyetDinh,
                tenCoSo: values.tenCoSo,
                maHuyen: values.maHuyen,
                maPhuong: values.maPhuong,
            };
            await new GiayChungNhanMuaSamService()
                .searchGiayChungNhanMuaSam(requestBody)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.error_code === "SUCCESSFUL") {
                            const data = response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : [];
                            data.forEach((item: any) => {
                                let mauTrangThai = 'green';
                                if (item.maTrangThai == '2') {
                                    mauTrangThai = '#565252';
                                }
                                rows.push({ ...MarkerDTO_INIT, diaChiDayDu: item.diaChiDayDu, maTrangThai: item.maTrangThai, tenTrangThai: item.tenTrangThai, mauTrangThai: mauTrangThai, classNameGCN: values.classNameGCN , soQuyetDinh: item.soQuyetDinh, ngayCap: item.ngayCap, tenCoSo: item.tenCoSo, kinhDo: item.kinhDo, viDo: item.viDo });
                            });
                            total = response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0;
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (values.classNameGCN === "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuTheThao") {
            dispatchLoading(showLoading());
            let requestBody: HoSoThuLyDichVuDuLichSearchRequest = {
                ...HoSoThuLyDichVuDuLichSearchRequest_INIT,
                soQuyetDinh: values.soQuyetDinh,
                tenCoSo: values.tenCoSo,
                maHuyen: values.maHuyen,
                maPhuong: values.maPhuong,
            };
            await new GiayChungNhanTheThaoService()
                .getListDichVuDuLichTheThao(requestBody)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.error_code === "SUCCESSFUL") {
                            const data = response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : [];
                            data.forEach((item: any) => {
                                let mauTrangThai = 'green';
                                if (item.maTrangThai == '2') {
                                    mauTrangThai = '#565252';
                                }
                                rows.push({ ...MarkerDTO_INIT, diaChiDayDu: item.diaChiDayDu, maTrangThai: item.maTrangThai, tenTrangThai: item.tenTrangThai, mauTrangThai: mauTrangThai, classNameGCN: values.classNameGCN , soQuyetDinh: item.soQuyetDinh, ngayCap: item.ngayCap, tenCoSo: item.tenCoSo, kinhDo: item.kinhDo, viDo: item.viDo });
                            });
                            total = response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0;
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (values.classNameGCN === "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri") {
            dispatchLoading(showLoading());
            let requestBody: HoSoThuLyDichVuDuLichSearchRequest = {
                ...HoSoThuLyDichVuDuLichSearchRequest_INIT,
                soQuyetDinh: values.soQuyetDinh,
                tenCoSo: values.tenCoSo,
                maHuyen: values.maHuyen,
                maPhuong: values.maPhuong,
            };
            await new GiayChungNhanGiaiTriService()
                .getListDichVuDuLichVuiChoiGiaiTri(requestBody)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.error_code === "SUCCESSFUL") {
                            const data = response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : [];
                            data.forEach((item: any) => {
                                let mauTrangThai = 'green';
                                if (item.maTrangThai == '2') {
                                    mauTrangThai = '#565252';
                                }
                                rows.push({ ...MarkerDTO_INIT, diaChiDayDu: item.diaChiDayDu, maTrangThai: item.maTrangThai, tenTrangThai: item.tenTrangThai, mauTrangThai: mauTrangThai, classNameGCN: values.classNameGCN , soQuyetDinh: item.soQuyetDinh, ngayCap: item.ngayCap, tenCoSo: item.tenCoSo, kinhDo: item.kinhDo, viDo: item.viDo });
                            });
                            total = response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0;
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (values.classNameGCN === "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuLuuTru") {
            dispatchLoading(showLoading());
            let requestBody: TraCuuCoSoDuLichDTO = {
                ...TraCuuCoSoDuLichDTO_INIT,
                soQuyetDinh: values.soQuyetDinh,
                tenCoSo: values.tenCoSo,
                maHuyen: values.maHuyen,
                maPhuong: values.maPhuong,
            };
            await new GiayChungNhanLuuTruService()
                .getDanhSachQuanLy(requestBody)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.error_code === "SUCCESSFUL") {
                            const data = response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : [];
                            data.forEach((item: any) => {
                                let mauTrangThai = 'green';
                                if (item.maTrangThai == '2') {
                                    mauTrangThai = '#565252';
                                }
                                rows.push({ ...MarkerDTO_INIT, diaChiDayDu: item.diaChiDayDu, maTrangThai: item.maTrangThai, tenTrangThai: item.tenTrangThai, mauTrangThai: mauTrangThai, classNameGCN: values.classNameGCN , soQuyetDinh: item.soQuyetDinh, ngayCap: item.ngayCap, tenCoSo: item.tenCoSo, kinhDo: item.kinhDo, viDo: item.viDo });
                            });
                            total = response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0;
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (values.classNameGCN === 'com.fpt.egov.api.model.luhanh.GiayPhepKinhDoanhLuHanh') {
            dispatchLoading(showLoading());
            let requestBody: GiayPhepLuHanhNoiDiaRequest = {
                ...GiayPhepLuHanhNoiDiaRequest_INIT,
                soGiayPhep: values.soQuyetDinh,
                tenDoanhNghiep: values.tenCoSo,
                maHuyen: values.maHuyen,
                maPhuong: values.maPhuong
            }
            await new LuHanhNoiDiaService().searchGiayChungChi(requestBody).then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        const data = response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : [];
                        data.forEach((item: any) => {
                            let mauTrangThai = 'green';
                            if (item.maTrangThai == '2') {
                                mauTrangThai = '#565252';
                            }
                            rows.push({ ...MarkerDTO_INIT, diaChiDayDu: item.diaChiDayDu, maTrangThai: item.maTrangThai, tenTrangThai: item.tenTrangThai, mauTrangThai: mauTrangThai, classNameGCN: 'com.fpt.egov.api.model.luhanh.GiayPhepKinhDoanhLuHanh', soQuyetDinh: item.soGiayPhep, ngayCap: item.ngayCap, tenCoSo: item.tenDoanhNghiep, kinhDo: item.kinhDo, viDo: item.viDo });
                        });
                        total = response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0;
                    }
                }
            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        } else if (values.classNameGCN === 'com.fpt.egov.api.model.hdnt.GiayPhepToChucNT') {
            dispatchLoading(showLoading());
            let requestBody: GiayChungNhanDSVHRequest = {
                ...GiayChungNhanDSVHRequest_INIT,
                soChungNhan: values.soQuyetDinh,
                tenToChuc: values.tenCoSo,
                maHuyen: values.maHuyen,
                maPhuong: values.maPhuong
            }
            await new CapGiayPhepToChucNTService().getListGCN(requestBody).then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        const data = response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : [];
                        data.forEach((item: any) => {
                            let mauTrangThai = 'green';
                            if (item.maTrangThai == '2') {
                                mauTrangThai = '#565252';
                            }
                            rows.push({ ...MarkerDTO_INIT, diaChiDayDu: item.diaChiDayDu, maTrangThai: item.maTrangThai, tenTrangThai: item.tenTrangThai, mauTrangThai: mauTrangThai, classNameGCN: 'com.fpt.egov.api.model.luhanh.GiayPhepKinhDoanhLuHanh', soQuyetDinh: item.soGiayPhep, ngayCap: item.ngayCap, tenCoSo: item.tenDoanhNghiep, kinhDo: item.kinhDo, viDo: item.viDo });
                        });
                        total = response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0;
                    }
                }
            }).catch((e) => {
                console.log(e);
            }).finally(() => {
                dispatchLoading(hideLoading());
            });
        }

        return Promise.resolve({
            rows,
            page,
            total,
            size,
        } as unknown as Pagination<MarkerDTO[]>);
    }

    function renderDetails(item: MarkerDTO, showDetail: boolean) {
        return (
            <>
                <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                    <strong>{item.classNameGCN === 'com.fpt.egov.api.model.luhanh.GiayPhepKinhDoanhLuHanh' ? 'Số giấy phép' : 'Số Quyết Định'}:</strong> <span>{item.soQuyetDinh}</span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                    <strong>Ngày cấp:</strong> <span>{DateUtil.timeToDDMMYYYY(item.ngayCap)}</span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                    <strong>Tên trạng thái :</strong> <span style={{ color: item.mauTrangThai }}>{item.tenTrangThai}</span>
                </Typography>
                <Collapse in={showDetail}>
                    <Typography variant="subtitle2" gutterBottom style={{ display: "flex", justifyContent: "space-between" }}>
                        <strong>{item.classNameGCN === 'com.fpt.egov.api.model.luhanh.GiayPhepKinhDoanhLuHanh' ? 'Tên doanh nghiệp' : 'Tên cơ sở'}:</strong> <span>{item.tenCoSo}</span>
                    </Typography>
                </Collapse>
            </>
        );
    }


    function checkRender() {
        return renderDetails;
    }

    async function handleSaveLatLong(values: any) {
        setAlert({
            showAlert: true,
            message: "Cập nhật tọa độ thành công",
            severity: "success",
        });
        return Promise.resolve({ ...values });
    }

    return (
        <>
            <Snackbar
                open={alert.showAlert}
                onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                    {alert.message}
                </Alert>
            </Snackbar>
            <OverlayLoading />
            <TraCuuBanDo id={""} handleSubmitSearch={handleSubmitSearch} handleSaveLatLong={handleSaveLatLong} renderDetails={checkRender()} />
        </>
    );
};

export default WrapperMapTraCuu;
