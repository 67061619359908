import { Box, Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { MenuRoleItem } from "apis/quanTri/menuRoleManagementService";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { LIST_ICONS } from "../constants/constants";
import { selectOtherMenuChil, selectSubMenu, selectOtherMenu } from "../redux/actions/menuAction";
import { RootState } from "../redux/reducers/rootReducer";
const useStyles = makeStyles((theme) => ({
    root: {
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    menuItem: {
        color: "#fff",
        textDecoration: "none",
        padding: "10px 15px",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#333333",
            fontWeight: "bold",
        },
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "5px",
        paddingRight: "5px",
    },
    itemIcon: {
        color: "white",
        minWidth: "30px",
    },
    activeLink: {
        color: "#2fabf3;",
    },
    nested: {
        paddingLeft: theme.spacing(1.5),
    },
}));

function getIcon(icon?: string): JSX.Element | undefined {
    let iconImg = LIST_ICONS.filter((p) => p.ten === icon)[0];
    if (iconImg && iconImg.icon !== undefined) {
        return iconImg.icon;
    } else {
        return undefined;
    }
}

export interface MenuI {
    menu: MenuRoleItem;
    scrollToElement?: () => void;
}

export const SubMenuTreeChild: React.FC<MenuI> = (props: { menu: MenuRoleItem; scrollToElement?: () => void }) => {
    const classes = useStyles();
    const [menu, setMenu] = useState({} as MenuRoleItem);
    const { menuActive, subMenuID, menuID, parentID } = useSelector((state: RootState) => state.menuReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        setMenu(props.menu);
    }, [props.menu]);

    const triggerNalinkClick = (event: React.MouseEvent<HTMLElement>) => {
        event.currentTarget.getElementsByTagName("a")[0].click();
        props.scrollToElement && props.scrollToElement();
    };

    function handleClick(menu: MenuRoleItem) {
        console.log(menu);
        if (menu.parentId === parentID && subMenuID !== Number(menu.id) && menu.childrens && menu.childrens?.length > 0) {
            return dispatch(selectOtherMenuChil({ id: Number(menu.id) }));
        }
        if (!menuActive.includes(Number(menu.id))) {
            dispatch(
                selectSubMenu({
                    id: Number(menu.id),
                    parentID: menu.childrens && menu.childrens.length > 1 ? Number(menu.id) : NaN,
                    length: menu.childrens && menu.childrens.length > 1 ? Number(menu.id) : NaN,
                })
            );
        }

        if (menu.parentId === menuID && subMenuID && subMenuID !== Number(menu.id) && menuActive.length > 0) {
            dispatch(selectOtherMenu({ id: Number(menu.id) }));
        }
    }

    return (
        <>
            {menu.childrens && menu.childrens.length > 0 ? (
                <div>
                    <ListItem
                        key={menu.id + menu.name}
                        onClick={() => {
                            handleClick(menu);
                        }}
                        className={classes.menuItem}
                    >
                        <ListItemIcon className={classes.itemIcon}>{getIcon(menu.icon)}</ListItemIcon>
                        <ListItemText primary={menu.name} />
                        {menuActive.includes(Number(menu.id)) ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={menuActive.includes(Number(menu.id))} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {menu.childrens &&
                                menu.childrens.map((p, i) => {
                                    return <SubMenuTreeChild key={i} menu={p} scrollToElement={props.scrollToElement} />;
                                })}
                        </List>
                    </Collapse>
                </div>
            ) : (
                <ListItem key={menu.id + menu.name} className={classes.menuItem} onClick={(event) => triggerNalinkClick(event)}>
                    <ListItemIcon className={classes.itemIcon}>{getIcon(menu.icon)}</ListItemIcon>
                    <NavLink style={{ textDecoration: "none" }} exact to={String(menu.path_)} className={classes.menuItem} activeClassName={classes.activeLink}>
                        <Box display="flex" alignItems="center">
                            <Box component="span" fontSize="15px">
                                {menu.name}
                            </Box>
                        </Box>
                    </NavLink>
                </ListItem>
            )}
        </>
    );
};
