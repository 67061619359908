import { useEffect, useState } from 'react';
import { Box, Grid, InputLabel, FormHelperText, InputBase, TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import StringUtil from '../../../../utils/stringUtils';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { HoatDongVanHoaNgheThuat } from '../../../../model/ThongBaoNgheThuat/HoatDongVanHoaNgheThuat';
import { DanhMucService } from '../../../../apis/danhMuc/danhMucService';
import { DanhMucDiaChiDTO } from '../../../../apis/danhMuc/model/DanhMucDTO';
import { FileEntry } from '../../../../apis/Common/fileEntry';
import { FileServerService } from '../../../../apis/Common/FileServerService';
import { createFileType, downLoadFile } from '../../../../apis/Common/exportUtil';
import { bootstrapTextFieldHook } from '../../../commons/input/bootstrap';
import { AlertMessage } from '../../../commons/alert';
import { ACCEPT_FILES } from '../../../../constants/VanHoaNgheThuatConstants';
import AXIOS_INSTANCE from '../../../../apis';
import { GiayPhepToChucNgheThuat } from '../../../../model/ThongBaoNgheThuat/GiayPhepToChucNgheThuat';
import DropZoneCustom from 'components/commons/hoso/CustomDropzone/DropZoneCustom';

interface NhaORiengLeCapMoiNoiDungComponentProps {
    data: GiayPhepToChucNgheThuat;
    submit: boolean;
    onUpdateData: (name: string, value: any) => void;
    isNhapDauKy: boolean;
}
const ThongTinChungGiayPhepNTComponent = (props: NhaORiengLeCapMoiNoiDungComponentProps) => {


    const [data, setData] = useState({} as GiayPhepToChucNgheThuat);
    const [submit, setSubmit] = useState(false);

    const [openDonViTuVanModal, setOpenDonViTuVanModal] = useState(false);
    const [openSoKyHieuBangVeModal, setOpenSoKyHieuBangVeModal] = useState(false);
    const { onUpdateData } = props;

    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const fileServerService = new FileServerService();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [severity, setSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    useEffect(() => {
        loadQuans("92");
    }, []);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadPhuongs = () => {
        if(data?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(data?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
  const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
    let valueText = value?.code !== "" ? value?.name : "";
    setData({
        ...data,
        maPhuong: "",
        tenPhuong: "",
        [nameValue]: value?.code,
        [nameText]: valueText,
    });
};

const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
    let valueText = value?.code !== "" ? value?.name : "";
    let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
    onUpdateData("data", dataNew);
};

    useEffect(() => {
        let dataNew = {
            ...props.data,
        }
        setData(dataNew);
        setSubmit(props.submit);
    }, [props.data, props.submit, props.onUpdateData]);
   
    const handleUpdateData = (name: string, value: string) => {
        onUpdateData(name, value);
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    //đơn vị tư vấn
    const handleDonViTuVanModalToggle = () => {
        setOpenDonViTuVanModal(!openDonViTuVanModal);
    }
    //Số ký hiệu bảng vẽ
    const handleSoKyHieuBangVeModalToggle = () => {
        setOpenSoKyHieuBangVeModal(!openSoKyHieuBangVeModal);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    }

    const onSearchCMND = (cmnd: string) => {
        if (StringUtil.isNullOrEmty(cmnd)) {
            showMessageAlert("Số giấy tờ tùy thân không được trống", false);
            return;
        } else {
            new DanhMucService().getBySoCMND({soCMND: cmnd}).then(res => {
            let dataNew = res?.data.data;
            if (dataNew !== null && dataNew !== undefined) {
                if(dataNew.error_code === 'SUCCESSFUL') {
                    console.log("dataNew ", dataNew);
                
                    setData({
                        ...data,
                        ngayCapCMND: dataNew.data.cmt_NgayCap,
                        ngaySinh: dataNew.data.ngayThangNamSinh,
                    });
                }else {
                    setAlertMessage({ open: true, content: "Số giấy tờ tùy thân không tồn tại trong dữ liệu", severity: 'error' });
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }
    };
    return (
        <>
            {
                <Box padding={0}>
                    <Box padding={0}>
                        <Grid container>
                                <Grid container>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required classes={labelStyles}>Số giấy phép</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="soGiayPhep"
                                            InputLabelProps={{ classes: labelStyles }}
                                            value={data.soGiayPhep}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={handleChange}
                                            error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.soGiayPhep) ? "Vui lòng nhập số giấy phép" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" >
                                        <InputLabel required classes={labelStyles}>Ngày cấp</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={data.ngayCap && data.ngayCap != 0 ? new Date(data.ngayCap) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                                error={submit && StringUtil.isNullOrEmty(data.ngayCap)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.ngayCap) ? "Vui lòng nhập ngày cấp" : ""}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={6} className="grid-item" >
                                        <TextField
                                            fullWidth
                                            required
                                            label={"Tên nhà hát/ tên tổ chức"}
                                            name="tenNhaHat"
                                            onChange={handleChange}
                                            value={data.tenNhaHat}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            error={submit && StringUtil.isNullOrEmty(data.tenNhaHat)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.tenNhaHat) ? "Vui lòng nhập tên nhà hát/ tên tổ chức" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item" >
                                        <TextField
                                            fullWidth
                                            required
                                            label={"Tên chương trình"}
                                            name="tenChuongTrinh"
                                            onChange={handleChange}
                                            value={data.tenChuongTrinh}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            error={submit && StringUtil.isNullOrEmty(data.tenChuongTrinh)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.tenChuongTrinh) ? "Vui lòng nhập tên chương trình" : ""}
                                        />
                                    </Grid>
                                </Grid>
                            <Grid item xs={6} className="grid-item">
                                <InputLabel classes={labelStyles}>Nội dung chương trình</InputLabel>
                                <TextField
                                    fullWidth
                                    size="medium"
                                    name="noiDungChuongTrinh"
                                    value={data.noiDungChuongTrinh}
                                    onChange={handleChange}
                                    rows={2}
                                    multiline
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <InputLabel classes={labelStyles}>Nội dung khác</InputLabel>
                                <TextField
                                    fullWidth
                                    size="medium"
                                    name="noiDungKhac"
                                    value={data.noiDungKhac}
                                    onChange={handleChange}
                                    rows={2}
                                    multiline
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel classes={labelStyles}>Thời lượng chương trình </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="thoiLuongChuongTrinh"
                                        value={data.thoiLuongChuongTrinh}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <InputLabel classes={labelStyles}>Người chịu trách nhiệm chương trình </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="nguoiChiuTrachNhiem"
                                        value={data.nguoiChiuTrachNhiem}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Thời gian tổ chức</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required classes={labelStyles}>Từ ngày</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={data.tuNgay && data.tuNgay != 0 ? new Date(data.tuNgay) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "tuNgay")}
                                    />
                                    {submit && (data.tuNgay === null || data.tuNgay === 0 || data.tuNgay === undefined) && (
                                        <FormHelperText className="-error">Vui lòng nhập ngày</FormHelperText>
                                    )}
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel required classes={labelStyles}>Đến ngày</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={data.denNgay && data.denNgay != 0 ? new Date(data.denNgay) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "denNgay")}
                                    />
                                    {submit && (data.denNgay === null || data.denNgay === 0 || data.denNgay === undefined) && (
                                        <FormHelperText className="-error">Vui lòng nhập ngày</FormHelperText>
                                    )}
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel>Thời gian tổ chức</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required classes={labelStyles}>Huyện/Thị/TP </InputLabel>
                                    <Autocomplete
                                        id="maHuyen"
                                        options={quans}
                                        getOptionLabel={(option) => option?.name}
                                        value={{ code: data.maHuyen, name: data.tenHuyen }}
                                    
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.maHuyen) &&
                                        <FormHelperText className="-error"  >Vui lòng chọn Huyện/Thị/TP</FormHelperText>
                                    }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required classes={labelStyles}>Phường/Xã/TT </InputLabel>
                                    <Autocomplete
                                            id="maPhuong"
                                            options={phuongs}
                                            onFocus={loadPhuongs}
                                            getOptionLabel={(option) => option.name}
                                            value={{ code: data.maPhuong, name: data.tenPhuong }}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.maPhuong) &&
                                        <FormHelperText className="-error"  >Vui lòng chọn Phường/Xã/TT</FormHelperText>
                                    }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel classes={labelStyles}>Đường </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="tenDuong"
                                        value={data.tenDuong}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel classes={labelStyles}>Số nhà </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="soNha"
                                        value={data.soNha}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles}>Ghi chú</InputLabel>
                                <TextField
                                    fullWidth
                                    size="medium"
                                    name="ghiChu"
                                    value={data.ghiChu}
                                    onChange={handleChange}
                                    rows={2}
                                    multiline
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles}>Căn cứ pháp lý</InputLabel>
                                <TextField
                                    fullWidth
                                    size="medium"
                                    name="canCuocPhapLy"
                                    value={data.canCuocPhapLy}
                                    onChange={handleChange}
                                    rows={2}
                                    multiline
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <Checkbox
                                    checked={data.danhDauLuuY}
                                    name="danhDauLuuY"
                                    value={data.danhDauLuuY}
                                    onChange={handleChange}
                                />
                                Đánh dấu lưu ý
                                {/* <FormControlLabel
                                    label={<span className="label-checkbox">Đánh dấu lưu ý</span>}
                                    control={
                                        <Checkbox
                                            checked={data.danhDauLuuY}
                                            name="danhDauLuuY"
                                            color="default"
                                            size="small"
                                            onChange={handleChange}
                                            value={data.danhDauLuuY}
                                        />
                                    }
                                /> */}
                            </Grid>
                            <DropZoneCustom
                                acceptFiles={ACCEPT_FILES}
                                attachFileServer={data.files}
                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/vhdl"}
                                handleChangeStatus={handleChangeStatus}
                                handleDeleteFileServer={handleDeleteFileServer}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </Grid>
                    </Box>

                </Box>
            }
        </>
    )
}
export default ThongTinChungGiayPhepNTComponent;