import { Box, Button, createStyles, Grid, InputLabel, makeStyles, Table, TableHead, Theme, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GiayChungNhanTheThaoService } from "apis/DichVuDuLich/GiayChungNhanTheThaoService";
import { HoSoThuLyDichVuDuLich_INIT } from "../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLich } from "../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { LichSuGiayChungNhan } from "../../../../model/lichSuGiayChungNhan";
import StringUtil from "../../../../utils/stringUtils";
import { TepDinhKemPopper } from "../../../commons/hoso/TepDinhKemPopper";
import TepTinDinhKemViewComponent from "../../../commons/hoso/TepTinDinhKemViewComponent";
import { Cell, Row } from "../../../commons/table/tableCommons";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { LichSuThuLyDichVuDuLich, LichSuThuLyDichVuDuLich_INIT } from "../../model/lichSuThuLyDichVuDuLich";
import TepTinDinhKemCSKDDichVuComponent from "../TepDinhKem/TepTinDinhKemCSKDDichVuComponent";
interface TabPanelProps {
    children?: React.ReactNode;
    index?: any;
    value: any;
}

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

interface IDataListProps {
    dataList: LichSuGiayChungNhan[];
}

const ChiTietDichVuTheThaoComponent = (props: IDataListProps) => {
    const leftStyle = useStylesLabelLeft();
    const rightStyle = useStylesLabelRight();
    const [indexTab, setIndexTab] = useState(0);
    const [data, setData] = useState<HoSoThuLyDichVuDuLich>({ ...HoSoThuLyDichVuDuLich_INIT });
    const [dataLichSu, setDataLichSu] = useState<LichSuThuLyDichVuDuLich>({ ...LichSuThuLyDichVuDuLich_INIT });
    const history = useHistory<any>();
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState<HoSoThuLyDiSanVanHoa[]>([]);
    const [rowLichSu, setRowLichSu] = useState<LichSuThuLyDichVuDuLich[]>([]);
    const getDetailById = async (id: number) => {
        await new GiayChungNhanTheThaoService()
            .getDetailDichVuTheThao(id)
            .then((p) => {
                setData(p);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const getLichSuGiayChungNhan = async (id: number) => {
        let classNameGCN = "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuTheThao";
        await new GiayChungNhanTheThaoService()
            .getLichSuDichVuDuLich(id, classNameGCN)
            .then((res) => {
                setRowLichSu(res.rows ? res.rows : []);

                setTotal(res.total);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    function getDiaChi(soNha: string, tenDuong: string, tenPhuong: string, tenHuyen: string) {
        var result = "";
        if (!StringUtil.isNullOrEmty(soNha)) result = result.concat(soNha);
        if (!StringUtil.isNullOrEmty(tenDuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenDuong);
            else result = result.concat(", ", tenDuong);
        }
        if (!StringUtil.isNullOrEmty(tenPhuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenPhuong);
            else result = result.concat(", ", tenPhuong);
        }
        if (!StringUtil.isNullOrEmty(tenHuyen)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenHuyen);
            else result = result.concat(", ", tenHuyen);
        }
        return result;
    }

    useEffect(() => {
        let id = history?.location?.state?.params;
        getDetailById(id);
        getLichSuGiayChungNhan(id);
    }, []);

    return (
        <div className="dash-content">
        <Grid container>
            <div className="dash-content">
                <div className="dash-title">
                    <span>THÔNG TIN CHI TIẾT CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO</span>
                </div>
            </div>
            <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={indexTab} onChange={handleChangeTab}>
                        <CustomTab label="Thông tin chung" />
                        <CustomTab label="Lịch sử" />
                    </CustomTabs>
                </Box>
            </Grid>
            <TabPanel value={indexTab} index={0}>
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Số quyết định</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.soQuyetDinh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày Cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.ngayCap != null ? moment(data?.ngayCap).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày hết hạn</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.ngayHetHan != null ? moment(data?.ngayHetHan).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tình trạng</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.tenTrangThai}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tên cơ sở</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.tenCoSo}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{getDiaChi(data.soNha, data.tenDuong, data.tenPhuong, data.tenHuyen)}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Điện thoại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.soDienThoai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Fax</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.fax}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Email</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.email}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Website</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.website}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Số GCNĐKDN/HKD</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.soGCN}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.ngayCapGCN != null ? moment(data?.ngayCapGCN).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Cơ quan cấp</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.coQuanCapGCN}</InputLabel>
                        </Grid>

                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Nơi cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.noiCapGCN}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tổng số người quản lý và nhân viên</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.tongSoNguoi}</InputLabel>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
           
            <TabPanel value={indexTab} index={1}>
                <Grid container>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">
                                    STT
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="25%">
                                    Loại biến động
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    Ngày biến động
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="30%">
                                    Tập tin
                                </Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.lichSus.map((row, i) => (
                                <Row key={row.giayChungNhanId}>
                                    <Cell scope="row" align="center">
                                        {i + 1}
                                    </Cell>
                                    <Cell align="left">{row.tenBienDong}</Cell>
                                    <Cell align="left">
                                        {row.ngayBienDong !== null && row.ngayBienDong !== undefined ? moment(row.ngayBienDong).format("DD/MM/YYYY") : ""}
                                    </Cell>
                                    <Cell align="left">
                                        <TepDinhKemPopper data={row.files}/>
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </TabPanel>
            <Grid item xs={12} container className="gridItem">
                <Box marginTop="5px">
                    <Button variant="contained" color="primary" disableElevation className="button" onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Box>
            </Grid>
        </Grid>
        </div>
    );
};
export default ChiTietDichVuTheThaoComponent;
