import { Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, Theme, createStyles, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import InBieuMauButton from "./InBieuMauButton";
import ReplyIcon from "@material-ui/icons/Reply";
import ChuyenXuLyHoSoModal from './chuyenXuLyHoSoModal';
import { HoSoThuLyDichVuDuLich } from '../../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich';
import { BieuMauAttachFileDTO } from 'apis/quanTri/model/danhMucBieuMau';
import { ChuyenXuLyRequest, HoSoCuaToiMgtService, ThongTinBuocDTO } from 'apis/hoSoCuaToi/HoSoCuaToiMgtService';
import { ConfirmationDialogRaw } from 'components/commons/dialog/confirmDialog';

export const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: "none",
        },
        sizeSmall: {
            fontSize: "13px",
        },
        startIcon: {
            marginRight: theme.spacing(0.5),
        },
        "&:focus": {
            outlineWidth: "0px",
            outlineStyle: "none",
        },
    })
)(Button);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
        margin: {
            margin: theme.spacing(0.5),
        },
    })
);

interface ThuLyButtonProps {
    onSubmit: () => void;
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
    onDelete: () => void;
    showIn?: boolean;
    handleChuyen: () => void;
    handleDuThao: () => void;
    dataButton: HoSoThuLyDichVuDuLich;
}
const ThuLyButtonComponent = (props: ThuLyButtonProps) => {
    const { onSubmit, onDownloadFileBieuMau, onDelete, showIn, handleChuyen, handleDuThao } = props;
    const history = useHistory();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const [open, setOpen] = useState(false);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [buocChuyen, setBuocChuyen] = useState<ThongTinBuocDTO>({} as ThongTinBuocDTO);
    const [chuyenXuLyRequest, setChuyenXuLyRequest] = useState<ChuyenXuLyRequest>({} as ChuyenXuLyRequest);
    const [showModal, setShowModal] = useState(false);
    const classes = useStyles();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(props.dataButton.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });

    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const clickBuocChuyen = (buocChuyenData: ThongTinBuocDTO) => {
        setBuocChuyen(buocChuyenData);
        const chuyenXuLyRequestNew: ChuyenXuLyRequest = {
            ...chuyenXuLyRequest,
            maHoSo: props.dataButton.maHoSo,
            idBuocXuLyTiepTheo: buocChuyenData.idBuocXuLy,
            maNguoiGui: "16807",
            nguoiNhanId: 0,
            noiDung: "",
            trichYeuDuThao: "",
            idLoaiVanBan: 0,
            hanXuLy: buocChuyenData.hanXuLyTheoGio,
        };
        setChuyenXuLyRequest(chuyenXuLyRequestNew);
        setShowModal(!showModal);
    };

    const handleToggle = () => {
        setShowModal(!showModal);
    }

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            onDelete();
            setAlert({
                showAlert: true,
                message: "Yêu cầu thực hiện thành công",
                severity: "success",
            });
        }
        setOpenDeleteUserDlg(false);
    };

    return (
        <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
            <Button onClick={onSubmit} variant="contained" color="primary" disableElevation>Lưu</Button>
            {
                showIn &&
                <InBieuMauButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
            }
            <Button onClick={handleOpenDialog} variant="contained" color="secondary" disableElevation> Xóa</Button>
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation> Trở về</Button>
            {
                showIn &&
                <Button 
                onClick={showHidePopperThaoTac} 
                variant="contained" 
                color="primary" 
                disableElevation 
                style={{margin: '0px 5px'}}
                ref={anchorRef}
                >
                     Chuyển
                </Button>
            } 
            {
                showIn &&
                <Button onClick={handleDuThao} variant="contained" color="primary" disableElevation style={{margin: '0px 5px'}}> Dự thảo</Button>
            }
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center bottom" : "center bottom" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                    {buocChuyens.map((buocChuyen, k) => {
                                        return (
                                            <MenuItem key={k}>
                                                <CustomButton
                                                    className={classes.margin}
                                                    startIcon={<ReplyIcon />}
                                                    size="small"
                                                    color="primary"
                                                    disableRipple
                                                    aria-controls={open ? "menu-list-grow" : undefined}
                                                    onClick={() => clickBuocChuyen(buocChuyen)}
                                                >
                                                    {buocChuyen.tenBuocXuLy}
                                                </CustomButton>
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <ChuyenXuLyHoSoModal thongTinBuocDTO={buocChuyen} onToggle={handleToggle} hoso={props.dataButton} show={showModal} />
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </Grid>
        
    )
}
export default ThuLyButtonComponent;
