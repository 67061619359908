import MomentUtils from "@date-io/moment";
import { Box, Checkbox, FormControlLabel, Grid, InputLabel, Snackbar, TextField, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../../apis";
import { createFileType, downLoadFile } from "../../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../../apis/Common/FileServerService";
import { DanhMucService } from "../../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../apis/danhMuc/model/DanhMucDTO";
import { GiayChungNhanAnUongService } from "../../../../../apis/DichVuDuLich/GiayChungNhanAnUongService";
import { HoSoThuLyDichVuDuLichService } from "../../../../../apis/DichVuDuLich/HoSoThuLyDichVuDuLichService";
import { ACCEPT_FILES, LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG, TINHTRANG_THUHOI } from "../../../../../constants/DichVuDuLichConstants";
import { DUPLICATE } from "../../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDichVuDuLich_INIT } from "../../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLich } from "../../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import StringUtil from "../../../../../utils/stringUtils";
import { ThongTinChuyenNhanhUtil } from "../../../../../utils/thongTinChuyenNhanhUtil";
import { ConfirmationDialogRaw } from "../../../../commons/dialog/confirmDialog";
import HoSoThuLyButonComponent from "../../../../commons/hoso/HoSoDichVuDuLichButonComponent";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { ChonToaDoComponent } from "../../../../commons/map/ChonToaDoComponent";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import DanhSachDuThaoModal from "../thuly/danhSachDuThaoModal";
import ThuLyButtonComponent from "../thuly/ThuLyButtonComponent";
import DropZoneCustom from "../../../../commons/hoso/CustomDropzone/DropZoneCustom";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import { HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import { ThongTinDungChungService } from "apis/hoSoCuaToi/thongTinDungChungService";
import DateUtil from "utils/dateUtils";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CapMoiGCNKinhDoanhAnUongComponent = () => { 
    const classNameGCN = "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong";
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [currentDate, setCurrentDate] = useState(0);
    const [data, setData] = useState<HoSoThuLyDichVuDuLich>({ ...HoSoThuLyDichVuDuLich_INIT, classNameGCN: classNameGCN });
    const fileServerService = new FileServerService();
    const hoSoThuLyDichVuDuLichService = new HoSoThuLyDichVuDuLichService();
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const giayChungNhanAnUongService = new GiayChungNhanAnUongService();
    const thongTinDungChungService = new ThongTinDungChungService();
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        initData();
        loadQuans("92");
    }, []);

    const initData = async () => {
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        let nhapDauKy = location.state?.isNhapDauKy;
        let update = location?.state?.isUpdate;
        let giayChungNhanId = location.state?.giayChungNhanId;
        setIsNhapDauKy(nhapDauKy);
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        let hoTenNguoiNop = "";
        // let soDienThoaiNguoiNop = "";
        // hoSoCuaToiMgtService.chiTiet(location.state?.maHoSo).then((p) => {
        //     if (p.data && p.data.data) {
        //         hoTenNguoiNop = p.data.data.nguoiNop;
        //         soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
        //     }
        // }).finally(() => {
        //     dispatchLoading(hideLoading());
        // });

        if (update) {
            await giayChungNhanAnUongService
                .chiTietGiayChungNhanById(giayChungNhanId)
                .then((res) => {
                    if (StringUtil.hasDataGiayPhep(res)) {
                        const dataNew = {
                            ...res?.data?.data,
                            classNameGCN: classNameGCN,
                            thuHoi: false,
                            isUpdate: update,
                            fileThuHois: new Array<FileEntry>(),
                        };
                        setData(dataNew);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else {
            await hoSoThuLyDichVuDuLichService
                .getByMaHoSo(maHS, classNameGCN)
                .then(async(res) => {
                    if (res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.data.hoSoThuLyId > 0) {
                        const dataNew = {
                            ...res?.data?.data,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            classNameGCN: classNameGCN,
                            thuHoi: false,
                            fileThuHois: new Array<FileEntry>(),
                        };
                        setData(dataNew);
                    } else {
                        let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHS);
                        let maHuyen ="", tenHuyen = "", maPhuong = "", tenPhuong = "";
                        if(!StringUtil.isNullOrEmty(dataEform?.quanhuyen)){
                            let donViHanhChinh  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                            maHuyen = donViHanhChinh?.code;
                            tenHuyen = donViHanhChinh?.name;
                        }
                        if(!StringUtil.isNullOrEmty(dataEform?.xaphuong)){
                            let donViHanhChinhCap3  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                            maPhuong = donViHanhChinhCap3?.code;
                            tenPhuong = donViHanhChinhCap3?.name;
                        }
                        const dataNew = {
                            ...HoSoThuLyDichVuDuLich_INIT,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            classNameGCN: classNameGCN,
                            thuHoi: false,
                            fileThuHois: new Array<FileEntry>(),
                            soDienThoai : dataEform?.sdt,
                            fax : dataEform?.fax,
                            noiCapGCN :  dataEform?.noicapdoanhnghiep ? dataEform?.noicapdoanhnghiep : HoSoThuLyDichVuDuLich_INIT.noiCapGCN,
                            ngayCapGCN : dataEform?.ngaycapdoanhnghiep ? DateUtil.stringDDMMYYYtoDateSecond(dataEform?.ngaycapdoanhnghiep) : HoSoThuLyDichVuDuLich_INIT.ngayCapGCN,
                            email : dataEform?.email,
                            website : dataEform?.website,
                            coQuanCapGCN : dataEform?.coquancapdkkd,
                            maHuyen : !StringUtil.isNullOrEmty(maHuyen) ? maHuyen :  HoSoThuLyDichVuDuLich_INIT?.maHuyen,
                            tenHuyen : !StringUtil.isNullOrEmty(tenHuyen) ? tenHuyen : HoSoThuLyDichVuDuLich_INIT?.tenHuyen,
                            maPhuong : !StringUtil.isNullOrEmty(maPhuong) ? maPhuong :HoSoThuLyDichVuDuLich_INIT?.maPhuong,
                            tenPhuong : !StringUtil.isNullOrEmty(tenPhuong) ? tenPhuong : HoSoThuLyDichVuDuLich_INIT?.tenPhuong,
                            soGCN : dataEform?.sodoanhnghiep,
                            tenCoSo: dataEform?.tencosodv,
                            tongSoNguoi:dataEform?.tongsonv
                            // tenDuong: dataEform?.diachi,
                        };
                        setData(dataNew);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        }
    };

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadPhuongs = () => {
        if (data?.maHuyen) {
            danhMucService
                .getDanhMucDiaChi(data?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    const handleSubmit = async () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.maHoSo) && !(isNhapDauKy || data.isUpdate)) {
            setAlert({
                showAlert: true,
                message: "Không có mã hồ sơ",
                severity: "warning",
            });
            return;
        }
        if (data.donViCapPhep || isNhapDauKy || data.maHoSo === null || data.maHoSo === undefined || data.maHoSo === "") {
            if (StringUtil.isNullOrEmty(data.soQuyetDinh)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.ngayCap)) {
                return;
            }
            if (isNhapDauKy) {
                if (StringUtil.isNullOrEmty(data.ngayHetHan)) {
                    return;
                }
            }
            if (data.ngayCap > currentDate) {
                setAlert({
                    showAlert: true,
                    message: "Ngày cấp không được lớn hơn ngày hiện tại",
                    severity: "warning",
                });
                return;
            }
            if (data.ngayCap > 0 && data.ngayHetHan > 0 && data.ngayCap > data.ngayHetHan) {
                setAlert({
                    showAlert: true,
                    message: "Ngày cấp phải bé hơn ngày hết hạn",
                    severity: "warning",
                });
                return;
            }
        }
        if (StringUtil.isNullOrEmty(data.soGCN)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenCoSo)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHuyen)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuong)) {
            return;
        }
        dispatchLoading(showLoading());
        await hoSoThuLyDichVuDuLichService
            .insertOrUpdate(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response?.data?.data) {
                    setData({
                        ...data,
                        hoSoThuLyId: response.data.data.hoSoThuLyId,
                        giayChungNhanId: response?.data?.data?.giayChungNhanId,
                        ngayTao: response.data.data.ngayTao,
                    });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else if (response.data.error_code === DUPLICATE) {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
                //lỗi
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: valueText,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(data.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleResetData = () => {
        const dataNew: HoSoThuLyDichVuDuLich = {
            ...HoSoThuLyDichVuDuLich_INIT,
            hoSoThuLyId: data.hoSoThuLyId,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            giayChungNhanId: data.giayChungNhanId,
            classNameGCN: classNameGCN,
        };
        setData(dataNew);
        setSubmit(false);
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setAlert({
                showAlert: true,
                message: "Yêu cầu thực hiện thành công",
                severity: "success",
            });
        }
        setOpenDeleteUserDlg(false);
    };
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => { };
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);

            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        hoSoThuLyDichVuDuLichService.downloadFileThuLy({ templateId: bieuMau.fileId, hoSoThuLyId: data.hoSoThuLyId }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
        });
    };

    const handleChangeTrangThai = (e: any) => {
        let tenTrangThai = "";
        if (e.target.value === TINHTRANG_HOATDONG.ma) {
            tenTrangThai = TINHTRANG_HOATDONG.ten;
        } else if (e.target.value === TINHTRANG_THUHOI.ma) {
            tenTrangThai = TINHTRANG_THUHOI.ten;
        }
        setData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
            ["tenTrangThai"]: tenTrangThai,
        }));
    };

    const handleChangeNumber = (e: any) => {
        let value = e.target.value;
        let name = e.target.name;
        const regExpNumber = new RegExp("^[0-9]*$");
        if (regExpNumber.test(value)) {
            let newData = {
                ...data,
                [name]: value,
            };
            setData(newData);
        }
    };
    const handleChangeToaDo = (dataToaDo: any) => {
        let newData = {
            ...data,
            viDo: dataToaDo.currentViDo,
            kinhDo: dataToaDo.currentKinhDo,
            diaChiDayDu: dataToaDo.diaChiDayDu,
        };
        setData(newData);
    };
    const getDiaChiDayDu = (): string => {
        if (StringUtil.isNullOrEmty(data.diaChiDayDu)) {
            return data.tenPhuong + ", " + data.tenHuyen + ", " + data.tenTinh;
        } else {
            return data.diaChiDayDu;
        }
    };

    async function handleSearchDoanhNghiep() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinDoanhNghiep(data.soGCN)
        .then((res) => { 
            console.log("res", res);
            
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.maSoDoanhNghiep) {
                setData({ ...data,
                    ngayCapGCN : res?.data?.data?.data?.ngayThanhLap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.ngayThanhLap) : data?.ngayCapGCN,
                    tenCoSo : res?.data?.data?.data?.tenDoanhNghiep_VN,
                    soDienThoai : res?.data?.data?.data?.dienThoai ? StringUtil.replaceDots(res?.data?.data?.data?.dienThoai) : data.soDienThoai,
                    fax: res?.data?.data?.data?.fax,
                    email: res?.data?.data?.data?.email,
                    website: res?.data?.data?.data?.website,
                    soNha: res?.data?.data?.data?.soNha,
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số đăng ký doanh nghiệp không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container item xs={12} className="grid-item">
                        {!isNhapDauKy && data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" && (
                            <div className="dash-title">
                                <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>
                                    CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG ĐẠT TIÊU CHUẨN PHỤC VỤ KHÁCH DU LỊCH- MÃ HỒ SƠ {data.maHoSo}
                                </h6>
                            </div>
                        )}
                        {(isNhapDauKy || data.maHoSo === null || data.maHoSo === undefined || data.maHoSo === "") && (
                            <div className="dash-title">
                                <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>NHẬP ĐẦU KỲ CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG</h6>
                            </div>
                        )}
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {!isNhapDauKy && (
                                            <>
                                                {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                                    <Grid item xs={3} className="grid-item">
                                                        <FormControlLabel
                                                            label={<span className="label-checkbox"> Đơn vị cấp số giấy phép</span>}
                                                            control={
                                                                <Checkbox
                                                                    checked={data.donViCapPhep}
                                                                    name="donViCapPhep"
                                                                    color="default"
                                                                    size="small"
                                                                    onChange={handleChange}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                ) : (
                                                    ``
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} container>
                                        {(isNhapDauKy || data.maHoSo === null || data.maHoSo === undefined || data.maHoSo === "") && (
                                            <>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Số quyết định
                                                    </InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        name="soQuyetDinh"
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        value={data.soQuyetDinh}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                        onChange={handleChange}
                                                        error={submit && StringUtil.isNullOrEmty(data.soQuyetDinh)}
                                                        helperText={submit && StringUtil.isNullOrEmty(data.soQuyetDinh) ? "Vui lòng nhập số quyết định" : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Ngày cấp
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayCap && data.ngayCap != 0 ? new Date(data.ngayCap) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayCap)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.ngayCap) ? "Vui lòng nhập ngày cấp " : ""}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles}>Cơ quan cấp</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        name="coQuanCapGCN"
                                                        value={data.coQuanCapGCN}
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Ngày hết hạn
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayHetHan)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.ngayHetHan) ? "Vui lòng nhập ngày hết hạn " : ""}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </>
                                        )}
                                        {data.donViCapPhep && (
                                            <>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Số giấy chứng nhận
                                                    </InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        name="soQuyetDinh"
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        value={data.soQuyetDinh}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                        onChange={handleChange}
                                                        error={submit && StringUtil.isNullOrEmty(data.soQuyetDinh)}
                                                        helperText={submit && StringUtil.isNullOrEmty(data.soQuyetDinh) ? "Vui lòng nhập số chứng nhận" : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Ngày cấp
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayCap && data.ngayCap != 0 ? new Date(data.ngayCap) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayCap)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.ngayCap) ? "Vui lòng nhập ngày cấp " : ""}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles}>Cơ quan cấp</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        name="coQuanCapGCN"
                                                        value={data.coQuanCapGCN}
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Số GCNĐKDN/HKD
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        style={{ width: "90%" }}
                                        name="soGCN"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soGCN}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.soGCN)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.soGCN) ? "Vui lòng nhập số GCNĐKDN" : ""}
                                    />
                                    <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "15PX", marginLeft: "3px" }}  onClick={handleSearchDoanhNghiep} />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Tên cơ sở
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="tenCoSo"
                                        value={data.tenCoSo}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.tenCoSo)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.tenCoSo) ? "Vui lòng nhập Tên cơ sở" : ""}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapGCN && data.ngayCapGCN != 0 ? new Date(data.ngayCapGCN) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapGCN")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Nơi cấp</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="noiCapGCN"
                                        value={data.noiCapGCN}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Điện thoại</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="soDienThoai"
                                        value={data.soDienThoai}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChangeNumber}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Fax</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="fax"
                                        value={data.fax}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Email</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="email"
                                        value={data.email}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Website</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="website"
                                        value={data.website}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Huyện/Thị/TP{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maHuyen"
                                        classes={inputStyles}
                                        options={quans}
                                        getOptionLabel={(option) => option?.name}
                                        value={{ code: data.maHuyen, name: data.tenHuyen }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn huyện/Thị/TP" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Phường/Xã/TT{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maPhuong"
                                        options={phuongs}
                                        classes={inputStyles}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maPhuong, name: data.tenPhuong }}
                                        noOptionsText={"Không có Phường/Xã/TT"}
                                        onFocus={loadPhuongs}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maPhuong) ? "Vui lòng chọn phường/Xã/TT" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel classes={labelStyles}>Đường </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="tenDuong"
                                        value={data.tenDuong}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel classes={labelStyles}>Số nhà</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="soNha"
                                        value={data.soNha}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <ChonToaDoComponent
                                        id={classNameGCN}
                                        handleChangeToaDo={handleChangeToaDo}
                                        diaChiDayDu={getDiaChiDayDu()}
                                        kinhDo={data.kinhDo}
                                        viDo={data.viDo}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Tổng số người quản lý và nhân viên phục vụ</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="tongSoNguoi"
                                        value={data.tongSoNguoi}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <FormControlLabel
                                        label={<span className="label-checkbox"> Đủ điều kiện về an ninh, trật tự</span>}
                                        control={
                                            <Checkbox
                                                checked={data.duDieuKienTranTu}
                                                name="duDieuKienTranTu"
                                                color="default"
                                                size="small"
                                                onChange={handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={<span className="label-checkbox">Bảo vệ môi trường</span>}
                                        control={
                                            <Checkbox
                                                checked={data.baoVeMoiTruong}
                                                name="baoVeMoiTruong"
                                                color="default"
                                                size="small"
                                                onChange={handleChange}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <FormControlLabel
                                        label={<span className="label-checkbox"> Phòng cháy, chữa cháy</span>}
                                        control={
                                            <Checkbox
                                                checked={data.phongChayChuaChay}
                                                name="phongChayChuaChay"
                                                color="default"
                                                size="small"
                                                onChange={handleChange}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={<span className="label-checkbox"> An toàn thực phẩm</span>}
                                        control={
                                            <Checkbox
                                                checked={data.anToanThucPham}
                                                name="anToanThucPham"
                                                color="default"
                                                size="small"
                                                onChange={handleChange}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    )}
                    <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        <ThuLyButtonComponent
                            showIn={data.hoSoThuLyId > 0 && !isNhapDauKy}
                            onSubmit={handleSubmit}
                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                            onDelete={handleResetData}
                            handleChuyen={showHidePopperThaoTac}
                            handleDuThao={showHidePopperDuThao}
                            dataButton= {data}
                        />
                    </Grid>
                    {/* {(isNhapDauKy || data.hoSoThuLyId <= 0) ?
                        <HoSoThuLyButonComponent
                            onSubmit={handleSubmit}
                            onResetData={handleResetData}
                            onOpenDialog={handleOpenDialog}
                        />
                        :
                        <HoSoThuLyButonComponent
                            onDownloadFileThuLy={handleDownloadFileBieuMau}
                            onSubmit={handleSubmit}
                            onResetData={handleResetData}
                            onOpenDialog={handleOpenDialog}
                        />
                    } */}
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
            {showDanhSachDuThaoModal && (
               <DanhSachDuThaoModal hoso={data} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}
        </>
        </div>
    );
};
export default CapMoiGCNKinhDoanhAnUongComponent;
