import MomentUtils from "@date-io/moment";
import { Button, createStyles, Grid, InputBase, InputLabel, Link, makeStyles, NativeSelect, Snackbar, SvgIcon, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useFormik } from "formik";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageRequest } from "../../../../apis/baseService";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { ThongKeService } from "../../../../apis/LuHanhNoiDia/ThongKeService";
import { LIST_TINHTRANG } from "../../../../constants/LuHanhNoiDiaConstants";
import { GiayPhepKinhDoanhLuHanhDTO } from "../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { AlertMessage, CustomAlert } from "../../../commons/alert";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import { DanhSachBaoCaoLHND } from "./DanhSachBaoCaoLHND";

export interface RequestBaoCaoThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
    maLoaiHinhDoanhNghiep: string;
    tenLoaiHinhDoanhNghiep: string;
    maHuyen: string;
    tenHuyen: string;
    maPhuong: string;
    tenPhuong: string;
    maTrangThai: string;
    tenTrangThai: string;
    tuNgay: number;
    denNgay: number;
    kyThongKe: number;
    keywords: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px",
        },
    })
);
export const BaoCaoLHND = () => {
    const classes = useStyles();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [dataList, setDataList] = useState<GiayPhepKinhDoanhLuHanhDTO[]>([]);
    const thongKeService = new ThongKeService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [showDate, setShowDate] = useState(true);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const formik = useFormik({
        initialValues: {
            request: {
                namThongKe: 0,
                tenNamThongKe: "Chọn năm",
                maLoaiHinhDoanhNghiep: "",
                tenLoaiHinhDoanhNghiep: "Chọn loại hình doanh nghiệp",
                maHuyen: "",
                tenHuyen: "",
                maPhuong: "",
                tenPhuong: "",
                maTrangThai: "",
                tenTrangThai: "Chọn tình trạng",
                tuNgay: 0,
                denNgay: 0,
                kyThongKe: 1,
                keywords: "",
            } as RequestBaoCaoThongKeDTO,
        },
        onSubmit: () => loadDataTable(),
    });

    useEffect(() => {
        formik.setValues({
            request: {
                namThongKe: 0,
                tenNamThongKe: "Chọn năm",
                maLoaiHinhDoanhNghiep: "",
                tenLoaiHinhDoanhNghiep: "Chọn loại hình doanh nghiệp",
                maHuyen: "",
                tenHuyen: "",
                maPhuong: "",
                tenPhuong: "",
                maTrangThai: "",
                tenTrangThai: "Chọn tình trạng",
                tuNgay: 0,
                denNgay: 0,
                kyThongKe: 1,
                keywords: "",
            } as RequestBaoCaoThongKeDTO,
        });
        namThongKeList();
        loadDataTable();
        // loadDataBienDong()
        loadQuans("92");
    }, []);
    
    useEffect(() => {
        loadDataTable();
    }, [page, size]);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadDataTable = async () => {
        if (formik.values.request?.kyThongKe == 2) {
            if (formik.values.request?.tuNgay > 0 && formik.values.request?.denNgay > 0 && formik.values.request?.denNgay < formik.values.request?.tuNgay) {
                showMessageAlert("Từ ngày không được lớn hơn đến này", false);
                return;
            }
        }
        let request = {
            ...formik.values.request,
            pageSize: size,
            pageNumber: page,
        } as RequestBaoCaoThongKeDTO;
        dispatchLoading(showLoading());
        await thongKeService
            .baoCaoThongKe(request)
            .then((p) => {
                setDataList(p.rows);
                setTotal(p.total);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ nam: 0, name: "Chọn năm" }];
        for (let i = namHienTai; i >= namHienTai - 5; --i) {
            var temp = { nam: i, name: "Năm " + i };
            temps.push(temp);
        }

        setDataNamThongKe(temps);
    };
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPage(1);
        setSize(Number(event.target.value));
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const handleChangeKyThongKe = (value: any) => {
        if (value == 1) {
            setShowDate(true);
        } else {
            setShowDate(false);
        }
    };
    const reload = async () => {
        formik.resetForm();
        let request = {
            namThongKe: 0,
            tenNamThongKe: "Chọn năm",
            maLoaiHinhDoanhNghiep: "",
            tenLoaiHinhDoanhNghiep: "Chọn loại hình doanh nghiệp",
            maHuyen: "",
            tenHuyen: "Chọn huyện",
            maPhuong: "",
            tenPhuong: "Chọn phường",
            maTrangThai: "",
            tenTrangThai: "Chọn tình trạng",
            tuNgay: 0,
            denNgay: 0,
            kyThongKe: 1,
            keywords: "",
        } as RequestBaoCaoThongKeDTO;
        dispatchLoading(showLoading());
        await thongKeService
            .baoCaoThongKe(request)
            .then((p) => {
                setDataList(p.rows);
                setTotal(p.total);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
        setShowDate(true);
    };
    const downloadFile = async (extension: string) => {
        let request = {
            ...formik.values.request,
            exportType: extension,
            exportFileName: "Danh_sach_bao_cao_LHND",
            tenThongke: "DANH SÁCH GIẤY PHÉP KINH DOANH LỮ HÀNH NỘI ĐỊA",
        };
        dispatchLoading(showLoading());
        await thongKeService
            .downloadFileBaoCao(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "Danh_sach_bao_cao_LHND");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const loadPhuongs = () => {
        if(formik.values.request?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(formik.values.request?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };


    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BÁO CÁO DOANH NGHIỆP KINH DOANH LỮ HÀNH NỘI ĐỊA</span>
            </div>
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Kỳ báo cáo</InputLabel>
                        <NativeSelect
                            disableUnderline
                            fullWidth
                            name="kyThongKe"
                            value={formik.values?.request?.kyThongKe}
                            onChange={(event: any) => {
                                formik.setFieldValue("request.kyThongKe", event.target.value);
                                handleChangeKyThongKe(event.target.value);
                            }}
                        >
                            <option value="1">Năm</option>
                            <option value="2">Khoảng thời gian</option>
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={3} className="grid-item" hidden={!showDate}>
                        <InputLabel classes={labelStyles}>Năm </InputLabel>
                        <NativeSelect
                            value={formik.values?.request?.namThongKe}
                            name="namThongKe"
                            onChange={(event: any) => {
                                formik.setFieldValue("request.namThongKe", event.target.value);
                            }}
                            input={<InputBase fullWidth />}
                        >
                            {dataNamThongKe.map((nam, i) => (
                                <option key={i} value={nam.nam}>
                                    {nam.name}
                                </option>
                            ))}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={showDate}>
                        <InputLabel classes={labelStyles}>Từ ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request.tuNgay > 0 ? new Date(formik.values.request.tuNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.tuNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item" hidden={showDate}>
                        <InputLabel classes={labelStyles}>Đến ngày</InputLabel>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                autoOk={true}
                                showTodayButton={true}
                                placeholder="dd/MM/yyyy"
                                value={formik.values.request?.denNgay > 0 ? new Date(formik.values.request?.denNgay) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputProps={{ disableUnderline: true }}
                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                onChange={(date: any, value: any) => formik.setFieldValue("request.denNgay", date?.toDate()?.getTime())}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {/* <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Loại doanh nghiệp</InputLabel>
                        <Autocomplete
                            options={}
                            value={{
                                ma: formik.values?.request?.maLoaiHinhDoanhNghiep ? formik.values.request?.maLoaiHinhDoanhNghiep : "",
                                ten: formik.values?.request?.tenLoaiHinhDoanhNghiep ? formik.values.request.tenLoaiHinhDoanhNghiep : "",
                            }}
                            getOptionLabel={(option) => option?.ten}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maLoaiHinhDoanhNghiep", newValue?.ma);
                                formik.setFieldValue("request.tenLoaiHinhDoanhNghiep", newValue?.ten);
                            }}
                            renderInput={(params) => {
                                return (
                                    <div className="padding-top-8px" ref={params.InputProps.ref}>
                                        <TextField {...params} variant="outlined" />
                                    </div>
                                );
                            }}
                        />
                    </Grid> */}
                    <Grid item xs={3} className="grid-item" hidden={!showDate}></Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Huyện/Thị/TP</InputLabel>
                        <Autocomplete
                           options={quans}
                            value={{
                                code: formik.values?.request?.maHuyen ? formik.values.request?.maHuyen : "",
                                name: formik.values?.request?.tenHuyen ? formik.values.request.tenHuyen : "",
                            }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maHuyen", newValue?.code);
                                formik.setFieldValue("request.tenHuyen", newValue?.name);
                                formik.setFieldValue("request.maPhuong", "");
                                formik.setFieldValue("request.tenPhuong", "");
                            }}
                            renderInput={(params) => {
                                return (
                                    <div className="padding-top-8px" ref={params.InputProps.ref}>
                                        <TextField {...params} variant="outlined" />
                                    </div>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Phường/Xã/TT</InputLabel>
                        <Autocomplete
                            options={phuongs}
                            value={{
                                code: formik.values?.request?.maPhuong ? formik.values.request?.maPhuong : "",
                                name: formik.values?.request?.tenPhuong ? formik.values.request.tenPhuong : "",
                            }}
                            onFocus ={loadPhuongs}
                            noOptionsText={"Không có Phường/Xã/TT"}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maPhuong", newValue?.code);
                                formik.setFieldValue("request.tenPhuong", newValue?.name);
                            }}
                            renderInput={(params) => {
                                return (
                                    <div className="padding-top-8px" ref={params.InputProps.ref}>
                                        <TextField {...params} variant="outlined" />
                                    </div>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Tình trạng</InputLabel>
                        <Autocomplete
                            options={LIST_TINHTRANG}
                            value={{
                                ma: formik.values?.request?.maTrangThai ? formik.values.request?.maTrangThai : "",
                                ten: formik.values?.request?.tenTrangThai ? formik.values?.request?.tenTrangThai : "",
                            }}
                            getOptionLabel={(option) => option?.ten}
                            onChange={(event: any, newValue: any) => {
                                formik.setFieldValue("request.maTrangThai", newValue?.ma);
                                formik.setFieldValue("request.tenTrangThai", newValue?.ten);
                            }}
                            renderInput={(params) => {
                                return (
                                    <div className="padding-top-8px" ref={params.InputProps.ref}>
                                        <TextField {...params} variant="outlined" />
                                    </div>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel></InputLabel>
                        <Button onClick={loadDataTable} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                            Tổng hợp
                        </Button>
                        <Button type="button" variant="contained" color="primary" disableElevation className={classes.button} onClick={reload}>
                            Làm mới
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                downloadFile("docx");
                            }}
                        >
                            <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    fill="#007bff"
                                    d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                                />
                            </SvgIcon>
                        </Link>
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                downloadFile("pdf");
                            }}
                        >
                            <SvgIcon fontSize="large">
                                <path
                                    fill="#dc3545"
                                    d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                                />
                            </SvgIcon>
                        </Link>
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                downloadFile("xlsx");
                            }}
                        >
                            <SvgIcon fontSize="large">
                                <path
                                    fill="#28a745"
                                    d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                                />
                            </SvgIcon>
                        </Link>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <DanhSachBaoCaoLHND
                            dataList={dataList}
                            pageProps={{
                                page: page,
                                count: total,
                                rowsPerPage: size,
                                onChangePage: handleOnChangePage,
                                onChangeRowsPerPage: handOnChangeRowsPerPage,
                            }}
                        />
                    </Grid>
                </Grid>
            </form>
            <OverlayLoading />
            <Snackbar
                open={alertMessage.open}
                onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                    {alertMessage.content}
                </CustomAlert>
            </Snackbar>
        </div>
    );
};
export default BaoCaoLHND;
