import { BaoCaoDanhSachGiayPhepHDNT } from "../components/HoatDongNgheThuat/BaoCao/baoCaoDanhSachGiayPhepHDNT";
import ChiTietGiayPhepToChuc from "../components/HoatDongNgheThuat/ChiTiet/chiTietGiayPhepToChuc";
import ChiTietThongBaoToChuc from "../components/HoatDongNgheThuat/ChiTiet/chiTietThongBaoToChuc";
import ThongKeGiayPhepHDNT from "../components/HoatDongNgheThuat/ThongKe/ThongKeGiayPhepHDNT";
import ThuHoiGiayPhepToChucHDNT from "../components/HoatDongNgheThuat/ThuHoi/thuHoiGiayPhepToChucHDNT";
import CapGiayPhepToChucNT from "../components/HoatDongNgheThuat/ThuLyHoSo/GiayPhepToChucNgheThuat/CapGiayPhepToChucNT";
import ThongBaoToChucNTComponet from "../components/HoatDongNgheThuat/ThuLyHoSo/ThongBaoToChucNT/ThongBaoToChucNTComponet";
import { TraCuuThongTinGiayPhepHDNT } from "../components/HoatDongNgheThuat/TraCuu/GiapPhepNT/traCuuGiayPhepHoatDongNgheThuat";
import { TraCuuThongTinThongBaoHDNT } from "../components/HoatDongNgheThuat/TraCuu/ThongBaoToChucNT/traCuuThongBaoHoatDongNgheThuat";


export interface RoutePropsI {
    ten: string;
    giaoDien: any;
    duongDan: string;
    routes
    ?: RoutePropsI[];
}
export const ROUTE_THONG_BAO_TO_CHUC_CHI_TIET = {
    ten: "Xem thông tin chi tiết văn bản nghệ thuật",
    duongDan: "/hoat-dong-nghe-thuat/thong-bao-to-chuc-NT/get-chi-tiet",
    giaoDien: ChiTietThongBaoToChuc,
};
export const ROUTE_GIAY_PHEP_TO_CHUC_CHI_TIET = {
    ten: "Xem thông tin chi tiết văn bản giấp phép tổ chức nghệ thuật",
    duongDan: "/hoat-dong-nghe-thuat/giay-phep-to-chuc-NT/get-chi-tiet",
    giaoDien: ChiTietGiayPhepToChuc,
};
export const ROUTE_THU_HOI_GIAY_PHEP_HDNT = {
    ten: "Thu hồi giấy phép tổ chức hoạt động nghệ thuật",
    duongDan: "/hoat-dong-nghe-thuat/giay-phep-to-chuc-NT/thu-hoi-giay-phep",
    giaoDien: ThuHoiGiayPhepToChucHDNT,
};
export const ROUTE_CN_HDNT: RoutePropsI[] = [
    //văn hóa nghê thuật
    {
        ten: "Thông báo tổ chức nghệ thuật",
        duongDan: "/hoat-dong-nghe-thuat/thong-bao-to-chuc-nghe-thuat",
        giaoDien: ThongBaoToChucNTComponet,
    },
    {
        ten: "Cấp giấy phép tổ chức biểu diễn nghệ thuật",
        duongDan: "/hoat-dong-nghe-thuat/giay-phep-nghe-thuat",
        giaoDien: CapGiayPhepToChucNT,
    },
    ROUTE_THONG_BAO_TO_CHUC_CHI_TIET,
    ROUTE_GIAY_PHEP_TO_CHUC_CHI_TIET,
    ROUTE_THU_HOI_GIAY_PHEP_HDNT
];

export const ROUTE_PAGE_HDNT: RoutePropsI[] = [
    {
        ten: "TRA CỨU DANH SÁCH GIẤY PHÉP TỔ CHỨC BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG CHO CÁC TỔ CHỨC THUỘC ĐỊA PHƯƠNG",
        duongDan: "/hoat-dong-nghe-thuat/tra-cuu-giay-phep-HDNT",
        giaoDien: TraCuuThongTinGiayPhepHDNT,
    },
    
    {
        ten: "DANH SÁCH VĂN BẢN CHẤP THUẬN THÔNG BÁO TỔ CHỨC BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG",
        duongDan: "/hoat-dong-nghe-thuat/tra-cuu-thong-bao-to-chuc-HDNT",
        giaoDien: TraCuuThongTinThongBaoHDNT,
    },
    {
        ten: "BÁO CÁO DANH SÁCH GIẤY PHÉP TỔ CHỨC BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG",
        duongDan: "/hoat-dong-nghe-thuat/bao-cao-giay-phep-HDNT",
        giaoDien: BaoCaoDanhSachGiayPhepHDNT,
    },
    {
        ten: "THỐNG KÊ SỐ LƯỢNG CHƯƠNG TRÌNH BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG",
        duongDan: "/hoat-dong-nghe-thuat/thong-ke-giay-phep-HDNT",
        giaoDien: ThongKeGiayPhepHDNT,
    },
    
]

export const ROUTE_DS_HDNT: RoutePropsI[] = [
    {
        ten: "TRA CỨU DANH SÁCH GIẤY PHÉP TỔ CHỨC BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG CHO CÁC TỔ CHỨC THUỘC ĐỊA PHƯƠNG",
        duongDan: "/hoat-dong-nghe-thuat/tra-cuu-giay-phep-HDNT",
        giaoDien: TraCuuThongTinGiayPhepHDNT,
    },
    {
        ten: "DANH SÁCH VĂN BẢN CHẤP THUẬN THÔNG BÁO TỔ CHỨC BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG",
        duongDan: "/hoat-dong-nghe-thuat/tra-cuu-thong-bao-to-chuc-HDNT",
        giaoDien: TraCuuThongTinThongBaoHDNT,
    },
    {
        ten: "BÁO CÁO DANH SÁCH GIẤY PHÉP TỔ CHỨC BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG",
        duongDan: "/hoat-dong-nghe-thuat/bao-cao-giay-phep-HDNT",
        giaoDien: BaoCaoDanhSachGiayPhepHDNT,
    },
    {
        ten: "THỐNG KÊ SỐ LƯỢNG CHƯƠNG TRÌNH BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG",
        duongDan: "/hoat-dong-nghe-thuat/thong-ke-giay-phep-HDNT",
        giaoDien: ThongKeGiayPhepHDNT,
    },
    
]
