import { LichSuThuLyDichVuDuLich } from "components/HoSoThuLyDichVuDuLich/model/lichSuThuLyDichVuDuLich";
import { FileEntry } from "../../apis/Common/fileEntry";
import {
    BuongLuuTruDTO,
    CoSoVatChatLuuTruDTO,
    HoSoThuLyDichVuDuLich,
    LichSuDichVuDuLichDTO,
    NhanSuCoSoLuuTruDTO,
} from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
export const BuongLuuTruDTO_INIT: BuongLuuTruDTO = {
    id: 0,
    hoSoThuLyId: 0,
    giayChungNhanId: 0,
    nguoiTaoId: "",
    ngayCapNhat: 0,
    ngayTao: 0,
    loaiBuong: "",
    soLuong: 0,
    gia: 0,
    hoatDong: true,
};

export const NhanSuCoSoLuuTruDTO_INIT: NhanSuCoSoLuuTruDTO = {
    id: 0,
    hoSoThuLyId: 0,
    giayChungNhanId: 0,
    nguoiTaoId: "",
    ngayCapNhat: 0,
    ngayTao: 0,
    soQuanLyVaNhanVien: 0,
    soBanGiamDoc: 0,
    soLeTan: 0,
    soBuong: 0,
    soBep: 0,
    soBan: 0,
    soBoPhanKhac: 0,
    soTrenDaiHoc: 0,
    soDaiHoc: 0,
    soCaoDang: 0,
    soTrungCap: 0,
    soSoCap: 0,
    soTHPT: 0,
    soChungChiKhac: 0,
    daoTaoNghiepVu: 0,
    daoTaoNgoaiNgu: 0,
    hoatDong: true,
};

export const CoSoVatChatLuuTruDTO_INIT: CoSoVatChatLuuTruDTO = {
    id: 0,
    hoSoThuLyId: 0,
    giayChungNhanId: 0,
    nguoiTaoId: "",
    ngayCapNhat: 0,
    ngayTao: 0,
    tongSoDauTuBanDau: "",
    tongSoDauTuNangCap: "",
    tongDienTichMatBang: 0,
    tongDienTichMatBangXayDung: 0,
    dichVuTrongCoSo: "",
    hoatDong: true,
};
export const HoSoThuLyDichVuDuLich_INIT: HoSoThuLyDichVuDuLich = {
    hoSoThuLyId: 0,

    giayChungNhanId: 0,

    nguoiTaoId: "",

    ngayThuHoi: 0,

    ngayCap: 0,

    ngayHetHan: 0,

    ngayHetHanCapSoGiayChungNhan: 0,

    ngayQuyetDinhThuHoi: 0,

    ngayCapNhat: 0,

    ngayTao: 0,

    ngayCapGCN: 0,

    soDienThoai: "",

    classNameGCN: "",

    ghiChu: "",

    nguoiKy : "",

    maHoSo: "",

    maLinhVuc: "",

    maThuTuc: "",

    soQuyetDinh: "",

    tenCoSo: "",

    maLoaiHinhCoSo: "",

    tenLoaiHinhCoSo: "",

    maHangCoSo: "",

    tenHangCoSo: "",

    soGCN: "",

    noiCapGCN: "",

    coQuanCapGCN: "",

    fax: "",

    email: "",

    website: "",

    maHuyen: "",

    tenHuyen: "",

    maPhuong: "",

    tenPhuong: "",

    maDuong: "",

    tenDuong: "",

    soNha: "",

    diaChiDayDu: "",

    maTrangThai: "",

    tenTrangThai: "",

    maBienDong: "",

    tenBienDong: "",

    lyDo: "",

    soQuyetDinhThuHoi: "",

    tongSoNguoi: "",

    hoatDong: true,

    duDieuKienTranTu: false,

    phongChayChuaChay: false,

    baoVeMoiTruong: false,

    anToanThucPham: false,

    donViCapPhep: false,

    files: new Array<FileEntry>(),

    fileCapMois: new Array<FileEntry>(),

    fileThuHois: new Array<FileEntry>(),

    resoucePK: 0,

    maTinh: "",

    tenTinh: "",

    kinhDo: 0,

    viDo: 0,

    keywords: "",

    isCapSoMoi: false,

    isUpdate: false,

    nhapDauKy: false,

    thuHoi: false,

    coSoVatChatLuuTruDTO: { ...CoSoVatChatLuuTruDTO_INIT } as CoSoVatChatLuuTruDTO,

    nhanSuCoSoLuuTruDTO: { ...NhanSuCoSoLuuTruDTO_INIT } as NhanSuCoSoLuuTruDTO,

    buongLuuTruDTOs: [],

    pageNumber : 1,

    pageSize : 5,

    lichSus: new Array<LichSuThuLyDichVuDuLich>(),

    nguoiLienHe: '',
    
    dienThoaiNguoiLienHe: '',

    maMauTrangThai: 0,
    
};

export const LichSuDichVuDuLich_INIT: LichSuDichVuDuLichDTO = {
    id: 0,

    giayChungNhanId: 0,

    hoSoThuLyId: 0,

    ngayBienDong: 0,

    ngayCapCu: 0,

    ngayCapMoi: 0,

    ngayCapNhat: 0,

    ngayTao: 0,

    ngayQuyetDinhThuHoi: 0,

    nguoiTaoId: "",

    classNameGCN: "",

    maTrangThaiCu: "",

    tenTrangThaiCu: "",

    maTrangThai: "",

    tenTrangThai: "",

    maBienDong: "",

    tenBienDong: "",

    soLanCapLai: "",

    soLanCapDoi: "",

    lyDo: "",

    soQuyetDinhCu: "",

    soQuyetDinhMoi: "",

    soQuyetDinhThuHoi: "",

    ghiChu: "",

    maHuyen: "",

    maPhuong: "",

    tenHuyen: "",

    tenPhuong: "",

    files: new Array<FileEntry>(),

    hoatDong: true,
};
