import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import moment from "moment";
import { TongThongKeGiayChungChi } from "../../../../model/GiayChungChiDSVH/TongThongKeGiayChungChi";
import { Cell, Row } from "../../../commons/table/tableCommons";

interface TableProps {
    dataList: TongThongKeGiayChungChi;
    search: () => void;
    handleChiTiet: (field?: number, index?: number) => void;
}
const DanhSachGiayChungChiTheoThoiGian = (props: TableProps) => {
    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="10%">
                            STT
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Từ ngày
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Đến ngày
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Cấp mới
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Cấp lại
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Thu hồi
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        <Row key={1}>
                            <Cell align="center">{1}</Cell>
                            <Cell align="center">
                                {props?.dataList?.ngayCapChungChiTu != null ? moment(props?.dataList?.ngayCapChungChiTu).format("DD/MM/YYYY") : ""}
                            </Cell>
                            <Cell align="center">
                                {props?.dataList?.ngayCapChungChiDen != null ? moment(props?.dataList?.ngayCapChungChiDen).format("DD/MM/YYYY") : ""}
                            </Cell>
                            <Cell align="center">
                                <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(0)}>
                                    {props?.dataList?.tongCapMoi}
                                </Box>
                            </Cell>
                            <Cell align="center">
                                <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(1)}>
                                    {props?.dataList?.tongCapLai}
                                </Box>
                            </Cell>
                            <Cell align="center">
                                <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(2)}>
                                    {props?.dataList?.tongThuHoi}
                                </Box>
                            </Cell>
                        </Row>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default DanhSachGiayChungChiTheoThoiGian;
