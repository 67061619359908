import AXIOS_INSTANCE from "..";
import { TraCuuCoSoDuLichDTO } from "../../model/DichVuDuLich/DichVuDuLichModel";
import { BaseService } from "../baseService";

export interface DashboardDichVuDuLichDTO {
    thang : number;
    anUong : number;
    muaSam: number;
    theThao: number;
    giaiTri: number;
    luuTru: number;
    tenThang : string;
}
export class DashboardDichVuDuLichService extends BaseService {
    loadDataDashboard = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/dashboard/load-data", requestBody);
    };


}
