import { DashboardPublic } from "../../pages/dashboard/DashboardPublic";
import WrapperMapTraCuu from "../../pages/traCuuBanDo/wrapperTraCuuBanDo";
import { ROUTE_PAGE_PUBLIC_DVDL } from "../route_dvdl";
import { ROUTE_PAGE_LUHANH_PUBLIC } from "../route_luhanh";
import { RoutePropsI } from "../route_vhdl";

export const ROUTE_PAGE_PUBLIC: RoutePropsI[] = [
    {
        ten: "Tra cứu bản đồ",
        duongDan: `/tra-cuu-ban-do`,
        giaoDien: <WrapperMapTraCuu />,
    },
    ...ROUTE_PAGE_PUBLIC_DVDL,
    ...ROUTE_PAGE_LUHANH_PUBLIC,
    {
        ten: "Dashboard",
        giaoDien: <DashboardPublic />,
        duongDan: "/dashboard",
    }
];
