import { Box, Checkbox, createStyles, Grid, InputBase, InputLabel, makeStyles, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect, useState } from "react";
import { DialogContent, DialogTitle } from "../../../commons/dialog/dialogCommons";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import { Cell, Row } from "components/commons/table/tableCommons";
import { Pagination } from "@material-ui/lab";
import { HoSoThuLyDiSanVanHoa } from "model/hoSoThuLyDiSanVanHoa";
import moment from "moment";
import { CustomInput } from "components/commons/input/customInput";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

interface IModalProps {
    dataList: HoSoThuLyDiSanVanHoa[];
    pageProps: TablePaginationActionsProps;
    onDialogClose: () => void;
    openDialog: boolean;
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const ChiTietThongKeGCNDialog = (props: IModalProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setOpenDialog(props.openDialog);
    }, [props.openDialog]);

    const getDiaChiDayDu = (data: HoSoThuLyDiSanVanHoa): string => {
        let diaChiDayDu = "";
        return (diaChiDayDu =
            (data.soNha !== "" && data.soNha !== undefined ? data.soNha + " " : "") +
            (data.tenDuong !== "" && data.tenDuong !== undefined ? data.tenDuong + ", " : "") +
            (data.tenPhuong !== "" && data.tenPhuong !== undefined ? data.tenPhuong + ", " : "") +
            (data.tenHuyen !== "" && data.tenHuyen !== undefined ? data.tenHuyen + ", " : "") +
            "Thành Phố Cần Thơ");
    };

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    Thông tin
                </DialogTitle>
                <OverlayLoading />
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid container>
                    <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">
                                    STT
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="15%">
                                    Số chứng chỉ
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="10%">
                                    Ngày cấp
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="10%">
                                    Ngày hết hạn
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="10%">
                                    Họ tên
                                </Cell>
                                <Cell component="th" scope="row" align="center">
                                    Địa chỉ thường trú
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="10%">
                                    Được phép hành nghề hoạt động
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="10%">
                                    Tình trạng
                                </Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {props.dataList.length > 0 ? (
                            props.dataList.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {(props.pageProps.page - 1) * rowsPerPage + i + 1}
                                    </Cell>
                                    <Cell align="left">
                                            {row.soChungChi}
                                    </Cell>
                                    <Cell align="left">
                                        {row.ngayCapChungChi && row.ngayCapChungChi !== undefined && row.ngayCapChungChi !== null
                                            ? moment(new Date(row.ngayCapChungChi)).format("DD/MM/YYYY")
                                            : ""}
                                    </Cell>
                                    <Cell align="left">
                                        {row.ngayHetHan && row.ngayHetHan !== undefined && row.ngayHetHan !== null
                                            ? moment(new Date(row.ngayHetHan)).format("DD/MM/YYYY")
                                            : ""}
                                    </Cell>
                                    <Cell align="left">{row.hoTen}</Cell>
                                    <Cell align="left">{row.diaChiDayDu}</Cell>
                                    <Cell align="left">{row.duocPhepHanhNghe}</Cell>
                                    <Cell align="left">{row.tenTrangThai}</Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={9} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu
                                </Cell>
                            </Row>
                        )}
                        </TableBody>
                    </Table>
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination
                            count={totalPage}
                            defaultPage={1}
                            siblingCount={1}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={props.pageProps.onChangePage}
                        />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">
                                Số mẫu tin trên trang
                            </Box>
                            <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">
                            Mẫu tin {(props.pageProps.page - 1) * props.pageProps.rowsPerPage + 1} -{" "}
                            {props.pageProps.count > props.pageProps.page * props.pageProps.rowsPerPage
                                ? props.pageProps.page * props.pageProps.rowsPerPage
                                : props.pageProps.count}{" "}
                            / {props.pageProps.count}
                        </Box>
                    </Box>
                </TableContainer>
                </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
