import { HoSoThuLyDiSanVanHoa } from "../../model/hoSoThuLyDiSanVanHoa";

export const HoSoThuLyDiSanVanHoa_INIT: HoSoThuLyDiSanVanHoa = {
    id: 0,

    ngayTao: 0,

    ngayCapNhat: 0,

    ngayCapChungNhan: 0,

    ngayCapChungChi: 0,

    ngayCapChungChiCu: 0,

    ngayCapChungChiMoi: 0,

    ngayCapChungNhanMoi: 0,

    resoucePK: 0,

    pageSize: 0,

    pageNumber: 0,

    ngayHetHan: 0,

    ngayHetHanMoi: 0,

    ngayHetHanCu: 0,

    ngayCapCMND: 0,

    ngayCapQD: 0,

    ngayCapLai: 0,

    noiCapCMND: "",

    nguoiTaoId: "",

    className: "",

    maHoSo: "",

    maThuTuc: "",

    soChungNhan: "",

    soChungChi: "",

    soChungChiCu: "",

    soChungChiMoi: "",

    tenToChuc: "",

    dienThoai: "",

    fax: "",

    maTinh: "",

    tenTinh: "",

    tenHuyen: "",

    maHuyen: "",

    tenPhuong: "",

    maPhuong: "",

    tenDuong: "",

    maDuong: "",

    soNha: "",

    soQD: "",

    noiCapQD: "",

    soCMND: "",

    nguoiDaiDienTheoPhapLuat: "",

    ngaySinh: 0,

    chucDanh: "",

    trichYeu: "",

    canCu: "",

    deNghi: "",

    hoTen: "",

    noiSinh: "",

    maQuocTich: "VN",

    tenQuocTich: "Cộng hòa xã hội chủ nghĩa Việt Nam",

    trinhDoChuyenMon: "",

    kinhNghiemChuyenMon: "",

    duocPhepHanhNghe: "",

    maTrangThai: "",

    tenTrangThai: "",

    maBienDong: "",

    tenBienDong: "",

    lyDoCapLai: "",

    soChungNhanMoi: "",

    diaChiDayDu: "",

    keywords: "",

    donViCapPhep: 0,

    lanCapLai: 0,

    files: [],

    fileCapLais: [],

    hoatDong: true,

    donViCapSoGiayPhep: true,

    isCapSoMoi: false,

    nhapDauKy: false,

    isUpdate: false,

    ghiChu: "",

    nguoiKy: "",
};
