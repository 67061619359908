import { createStyles, Grid, InputLabel, makeStyles, Theme } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { GiayChungNhanKinhDoanhDichVuLuuTruDTO_INIT } from "../../../../../init/DichVuDuLich/DichVuDuLichModel_INIT";
import { GiayChungNhanKinhDoanhDichVuLuuTruDTO } from "../../../../../model/DichVuDuLich/DichVuDuLichModel";
import StringUtil from "../../../../../utils/stringUtils";
import { BuongCoSoComponent } from "../../GiaoDienThuLy/BuongCoSo/BuongCoSoComponent";

interface ThongTinChungCoSoLuuTruProps {
    data: GiayChungNhanKinhDoanhDichVuLuuTruDTO;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabel = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const ThongTinChungCoSoLuuTruComponent = (props: ThongTinChungCoSoLuuTruProps) => {
    const rightStyle = useStylesLabelRight();
    const leftStyle = useStylesLabelLeft();
    const [data, setData] = useState<GiayChungNhanKinhDoanhDichVuLuuTruDTO>({ ...GiayChungNhanKinhDoanhDichVuLuuTruDTO_INIT });
    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel>1. THÔNG TIN CHUNG VỀ CƠ SỞ LƯU TRÚ DU LỊCH</InputLabel>
                </Grid>
                <br />
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số quyết định</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.soQuyetDinh}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayCap != null ? moment(data?.ngayCap).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày hết hạn</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayHetHan != null ? moment(data?.ngayHetHan).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tình trạng</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.tenTrangThai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tên cơ sở lưu trú du lịch</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tenCoSo}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Loại hình cơ sở</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tenLoaiHinhCoSo}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Loại hạng</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tenHangCoSo}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.diaChiDayDu}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Điện thoại</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.soDienThoai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Fax</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.fax}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Email</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.email}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Website</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.website}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số GCNĐKDN/HKD</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.soGCN}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayCapGCN != null ? moment(data?.ngayCapGCN).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Cơ quan cấp</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.coQuanCapGCN}</InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>2. CƠ SỞ VẬT CHẤT KỸ THUẬT</InputLabel>
                </Grid>
                <br />
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tổng vốn đầu tư ban đầu</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{StringUtil.formatMoney(data.coSoVatChatLuuTruDTO?.tongSoDauTuBanDau)}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tổng vốn đầu tư nâng cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{StringUtil.formatMoney(data.coSoVatChatLuuTruDTO?.tongSoDauTuNangCap)}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tổng diện tích mặt bằng</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.coSoVatChatLuuTruDTO?.tongDienTichMatBang} m&#178;</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tổng diện tích mặt bằng xây dựng (m2)</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.coSoVatChatLuuTruDTO?.tongDienTichMatBangXayDung} m&#178;</InputLabel>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <BuongCoSoComponent
                        view={true}
                        data={data.buongLuuTruDTOs && data.buongLuuTruDTOs != null && data.buongLuuTruDTOs != undefined ? data.buongLuuTruDTOs : []}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Dịch vụ có trong cơ sở lưu trú du lịch</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.coSoVatChatLuuTruDTO?.dichVuTrongCoSo}</InputLabel>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>3. Người quản lý và nhân viên phục vụ</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số người quản lý và nhân viên</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soQuanLyVaNhanVien}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ban giám đốc</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soBanGiamDoc} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Lễ tân</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soLeTan} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Buồng</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soBuong} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Bếp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soBep} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Bàn, bar</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soBan} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Bộ phận khác</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soBoPhanKhac} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Trình độ trên địa học</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soTrenDaiHoc} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Đại học</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soDaiHoc} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Cao đẳng</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soCaoDang} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Trung cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soTrungCap} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Sơ cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soSoCap} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>THPT</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soTHPT} Người</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Chứng chỉ khác</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.soChungChiKhac} Người</InputLabel>
                </Grid>
                <Grid item xs={6} className="grid-item" container justify="flex-end"></Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Được đào tạo nghiệp vụ</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.daoTaoNghiepVu}%</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Được đào tạo ngoại ngữ</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.nhanSuCoSoLuuTruDTO?.daoTaoNgoaiNgu}%</InputLabel>
                </Grid>
            </Grid>
        </>
    );
};
export default ThongTinChungCoSoLuuTruComponent;
