import { Box, Grid, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import { useEffect, useState } from "react";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { LichSuGiayPhepKinhDoanhLuHanh } from "../../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { TepDinhKemPopper } from "../../../../commons/hoso/TepDinhKemPopper";
import { CustomInput } from "../../../../commons/input/customInput";
import { Cell, Row } from "../../../../commons/table/tableCommons";

interface chiTietProps {
    data: LichSuGiayPhepKinhDoanhLuHanh[];
    file: FileEntry[];
}

const LichSu = (props: chiTietProps) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [dataPagi, setPataPagi] = useState<LichSuGiayPhepKinhDoanhLuHanh[]>(new Array<LichSuGiayPhepKinhDoanhLuHanh>());
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        handleSearch(pageNumber, pageSize);
    }, [props.data]);
    /*Phân trang*/
    const handleChangePage = (event: any, valuePage: number) => {
        setPageNumber(valuePage);
        handleSearch(valuePage, pageSize);
    };
    const handleChangePageSize = (event: any) => {
        let value = event.target.value;
        setPageNumber(1);
        setPageSize(value);
        handleSearch(pageNumber, value);
    };
    const handleSearch = (pageNumberNew: number, pageSizeNew: number) => {
        let offset = 0;
        if (pageNumberNew === 1) {
            offset = 0;
        } else if (pageNumberNew > 0 && pageSizeNew > 0) {
            offset = (pageNumberNew - 1) * pageSizeNew;
        } else {
            offset = 0;
        }
        let dataNew = props.data?.slice(offset, offset + pageSizeNew);
        setPataPagi(dataNew);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} className="grid-item">
                    <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <Cell component="th" scope="row" align="center">
                                        STT
                                    </Cell>
                                    <Cell component="th" scope="row" align="center">
                                        Loại biến động
                                    </Cell>
                                    <Cell component="th" scope="row" align="center">
                                        Ngày biến động
                                    </Cell>
                                    <Cell component="th" scope="row" align="center" >Số lần</Cell>
                                    <Cell component="th" scope="row" align="center">
                                        Nội dung
                                    </Cell>
                                    <Cell component="th" scope="row" align="center">
                                        Tệp tin
                                    </Cell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataPagi?.length > 0 ? (
                                    dataPagi?.map((item, index) => (
                                        <Row key={item.id}>
                                            <Cell className="text-center">{(pageNumber - 1) * pageSize + index + 1}</Cell>
                                            <Cell>{item.tenBienDong}</Cell>
                                            <Cell>{item.ngayBienDong ? moment(item.ngayBienDong).format("DD/MM/YYYY") : ""}</Cell>
                                            <Cell>{item.maBienDong === "3" ? item.soLanCapDoi : item.soLanCapLai}</Cell>
                                            <Cell>{item.lyDo}</Cell>
                                            <Cell>
                                                <TepDinhKemPopper data={item.files} />
                                            </Cell>
                                        </Row>
                                    ))
                                ) : (
                                    <Row>
                                        <Cell colSpan={5} scope="row" align="center">
                                            {" "}
                                            Không có dữ liệu
                                        </Cell>
                                    </Row>
                                )}
                            </TableBody>
                        </Table>
                        {dataPagi && dataPagi?.length > 0 && (
                            <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                                <Pagination
                                    count={Math.ceil(props.data?.length / pageSize)}
                                    defaultPage={1}
                                    siblingCount={1}
                                    variant="outlined"
                                    size="medium"
                                    color="primary"
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                    onChange={handleChangePage}
                                />
                                <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                                    <Box component="span" paddingX="5px">
                                        Số mẫu tin trên trang
                                    </Box>
                                    <NativeSelect input={<CustomInput />} onChange={handleChangePageSize}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={25}>20</option>
                                    </NativeSelect>
                                </Box>
                                <Box component="span" paddingX="5px">
                                    Mẫu tin {pageNumber * pageSize - pageSize + 1} -{" "}
                                    {props.data?.length - (pageNumber - 1) * pageSize < pageSize ? props.data?.length : pageNumber * pageSize} /{" "}
                                    {props.data?.length}
                                </Box>
                            </Box>
                        )}
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
};
export default LichSu;
