import { Box, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import StringUtil from "utils/stringUtils";
import { GiayChungNhanKinhDoanh } from "../../../../../model/DichVuDuLich/GiayChungNhanKinhDoanh";
import { CHI_TIET_GNC_AN_UONG, CHI_TIET_GNC_MUA_SAM } from "../../../../../routes/route_dvdl";
import { CustomInput } from "../../../../commons/input/customInput";
import { Cell, Row } from "../../../../commons/table/tableCommons";
import ThaoTacQuanLyGCNKinhDoanh from "../../../Action/ThaoTacQuanLyGCNKinhDoanh";

interface TableProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    dataList: GiayChungNhanKinhDoanh[];
    search: () => void;
    onHandleThuHoi: (id: number, loai: string, maBienDong: string, maHoSo: string, hoSoThuLyId: number, isNhapDauKy: boolean) => void;
    classNameGCN: string;
}
const DanhSachGCNKinhDoanhMuaSam = (props: TableProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    useEffect(() => {
        setTotalPage(Math.ceil(props.count / props.rowsPerPage));
        setRowsPerPage(props.rowsPerPage);
    }, [props.count, props.rowsPerPage]);

    const handleThuHoi = (id: number, loai: string, maBienDong: string, maHoSo: string, hoSoThuLyId: number, isNhapDauKy: boolean) => {
        props.onHandleThuHoi(id, loai, maBienDong, maHoSo, hoSoThuLyId, isNhapDauKy);
    };
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="15%">
                                Số quyết định
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ngày cấp
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ngày hết hạn
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tên Cơ sở
                            </Cell>
                            <Cell component="th" scope="row" align="center">
                                Địa chỉ
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Điện thoại
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tình trạng
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="5%">
                                Thao tác
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataList.length > 0 ? (
                            props.dataList.map((row, i) => (
                                <Row key={row.giayChungNhanId}>
                                    <Cell scope="row" align="center" style={{backgroundColor: StringUtil.getMaMau(row?.maMauTrangThai)}}>
                                        {(props.page - 1) * rowsPerPage + i + 1}
                                    </Cell>
                                    <Cell align="left">
                                        <NavLink
                                            style={{ textDecoration: "none" }}
                                            exact
                                            to={{
                                                pathname:
                                                    props?.classNameGCN === "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong"
                                                        ? CHI_TIET_GNC_AN_UONG.duongDan
                                                        : CHI_TIET_GNC_MUA_SAM.duongDan,
                                                state: { giayChungNhanKinhDoanh: row?.giayChungNhanId, isUpdate: false, hoSoThuLyId: row?.hoSoThuLyId },
                                            }}
                                        >
                                            {row.soQuyetDinh}
                                        </NavLink>
                                    </Cell>
                                    <Cell align="left">
                                        {row.ngayCap && row.ngayCap !== undefined && row.ngayCap !== null
                                            ? moment(new Date(row.ngayCap)).format("DD/MM/YYYY")
                                            : ""}
                                    </Cell>
                                    <Cell align="left">
                                        {row.ngayHetHan && row.ngayHetHan !== undefined && row.ngayHetHan !== null
                                            ? moment(new Date(row.ngayHetHan)).format("DD/MM/YYYY")
                                            : ""}
                                    </Cell>
                                    <Cell align="left">{row.tenCoSo}</Cell>
                                    <Cell align="left">{row.diaChiDayDu}</Cell>
                                    <Cell align="left">{row.soDienThoai}</Cell>
                                    <Cell align="left">{row.tenTrangThai}</Cell>
                                    <Cell align="center">
                                        <ThaoTacQuanLyGCNKinhDoanh data={row} onHandleThuHoi={handleThuHoi} />
                                    </Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={9} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu
                                </Cell>
                            </Row>
                        )}
                    </TableBody>
                </Table>
                {props.dataList.length > 0 && (
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination
                            count={totalPage}
                            defaultPage={1}
                            siblingCount={1}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={props.onChangePage}
                        />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">
                                Số mẫu tin trên trang
                            </Box>
                            <NativeSelect input={<CustomInput />} onChange={props.onChangeRowsPerPage}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">
                            Mẫu tin {(props.page - 1) * props.rowsPerPage + 1} -{" "}
                            {props.count > props.page * props.rowsPerPage ? props.page * props.rowsPerPage : props.count} / {props.count}
                        </Box>
                    </Box>
                )}
            </TableContainer>
        </>
    );
};
export default DanhSachGCNKinhDoanhMuaSam;
