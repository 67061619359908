import AXIOS_INSTANCE from "..";
import { HoSoThuLyDiSanVanHoa } from "../../model/hoSoThuLyDiSanVanHoa";
import { BaseService } from "../baseService";

export class CapSoDiSanVanHoaService extends BaseService {
    capSo = (request: HoSoThuLyDiSanVanHoa) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/cap-so/add-or-update", request, this.getTokenRequestHeaders());
    };
    danhSachCapSo = (request: HoSoThuLyDiSanVanHoa) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/cap-so/danh-sach-cap-so", request, this.getTokenRequestHeaders());
    };
}
