import { FileEntry } from "../../apis/Common/fileEntry";
import { GiayPhepKinhDoanhLuHanhCu, HoSoThuLyLuHanhNoiDia } from "../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
export const GiayPhepKinhDoanhLuHanhCu_INIT: GiayPhepKinhDoanhLuHanhCu = {
    id: 0,
    hoSoThuLyId: 0,
    giayPhepId: 0,
    ngayTaoId: 0,
    ngayCapNhat: 0,
    ngayTao: 0,
    hoatDong: true,
    maHoSo: "",
    maLinhVuc: "",
    maThuTuc: "",
    soGiayPhep: "",
    soGiayPhepCu: "",
    ngayCap: 0,
    tenDoanhNghiep: "",
    tenLoaiHinhDoanhNghiep: "",
    maLoaiHinhDoanhNghiep: "",
    dienThoai: "",
    tenVietTat: "",
    tenDichVu: "",
    fax: "",
    email: "",
    website: "",
    maHuyen: "",
    tenHuyen: "",
    maPhuong: "",
    tenPhuong: "",
    maDuong: "",
    tenDuong: "",
    soNha: "",
    diaChiDayDu: "",
    nguoiDaiDienTheoPhapLuat: "",
    gioiTinh: "",
    tenGioiTinh: "",
    ngaySinh: 0,
    maDanToc: "01",
    tenDanToc: "Kinh (Việt)",
    maQuocTich: "VN",
    tenQuocTich: "Cộng hòa xã hội chủ nghĩa Việt Nam",
    soDienThoaiNDD: "",
    mailNDD: "",
    soGiayTo: "",
    ngayCapSoGiayTo: 0,
    noiCapSoGiayTo: "",
    chucDanh: "",
    diaChiThuongTru: "",
    choOHienNay: "",
    soGCN: "",
    ngayCapGCN: 0,
    noiCapGCN: "",
    taiKhoanKyQuySo: "",
    nganHang: "",
    maTrangThai: "",
    tenTrangThai: "",
    maBienDong: "",
    tenBienDong: "",
    lyDo: "",
    soLanCapLai: 0,
    soLanCapDoi: 0,
    truongHopThuHoi: "",
    tenTruongHopThuHoi: "",
    ngayThuHoi: 0,
    soQuyetDinhThuHoi: "",
    ngayQuyetDinhThuHoi: 0,
    ghiChu: "",
    tenDiaChiChiNhanh: "",
    tenVanPhongDaiDien: "",
    ngayThayDoi: 0,
    nhapDauKy: false,
};
export const HoSoThuLyLuHanhNoiDia_INIT: HoSoThuLyLuHanhNoiDia = {
    hoSoThuLyId: 0,
    giayPhepId: 0,
    ngayTaoId: 0,
    ngayCapNhat: 0,
    ngayTao: 0,
    hoatDong: true,
    maHoSo: "",
    maLinhVuc: "",
    maThuTuc: "",
    soGiayPhep: "",
    soGiayPhepCu: "",
    ngayCap: 0,
    tenDoanhNghiep: "",
    tenLoaiHinhDoanhNghiep: "",
    maLoaiHinhDoanhNghiep: "",
    dienThoai: "",
    tenVietTat: "",
    tenDichVu: "",
    fax: "",
    email: "",
    website: "",
    maHuyen: "",
    tenHuyen: "",
    maPhuong: "",
    tenPhuong: "",
    maDuong: "",
    tenDuong: "",
    soNha: "",
    diaChiDayDu: "",
    kinhDo : 0,
    viDo : 0,
    nguoiDaiDienTheoPhapLuat: "",
    gioiTinh: "",
    tenGioiTinh: "",
    ngaySinh: 0,
    maDanToc: "01",
    tenDanToc: "Kinh (Việt)",
    maQuocTich: "VN",
    tenQuocTich: "Cộng hòa xã hội chủ nghĩa Việt Nam",
    soDienThoaiNDD: "",
    mailNDD: "",
    soGiayTo: "",
    ngayCapSoGiayTo: 0,
    noiCapSoGiayTo: "",
    chucDanh: "",
    diaChiThuongTru: "",
    choOHienNay: "",
    soGCN: "",
    ngayCapGCN: 0,
    noiCapGCN: "",
    taiKhoanKyQuySo: "",
    nganHang: "",
    maTrangThai: "",
    tenTrangThai: "",
    maBienDong: "",
    tenBienDong: "",
    lyDo: "",
    soLanCapLai: 0,
    soLanCapDoi: 0,
    truongHopThuHoi: "",
    tenTruongHopThuHoi: "",
    ngayThuHoi: 0,
    soQuyetDinhThuHoi: "",
    ngayQuyetDinhThuHoi: 0,
    ghiChu: "",
    tenDiaChiChiNhanh: "",
    tenVanPhongDaiDien: "",
    ngayThayDoi: 0,
    nhapDauKy: false,
    files: new Array<FileEntry>(),
    giayPhepCu: null,
};
