import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router";

interface GiayPhepXayDungButtonProps {
    onSubmit: () => void;
    onResetData: () => void;
    onOpenDialog: () => void;
    // onDownloadFileBieuMau : (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
}

const DauKyLuHanhNoiDiaButton = (props: GiayPhepXayDungButtonProps) => {
    const { onSubmit, onResetData, onOpenDialog } = props;
    const history = useHistory();
    return (
        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
            <Button onClick={onSubmit} variant="contained" color="primary" disableElevation style={{ margin: "0px 5px" }}>
                Lưu
            </Button>
            {/* <InBieuFileThuLyButton onDownloadFileBieuMau={onDownloadFileBieuMau} /> */}
            <Button variant="contained" color="secondary" onClick={onOpenDialog} disableElevation style={{ margin: "0px 5px" }}>
                {" "}
                Xóa
            </Button>
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation style={{ margin: "0px 5px" }}>
                {" "}
                Trở về
            </Button>
            {/* <Button variant="contained" color="primary" disableElevation style={{margin: '0px 5px'}}> Chuyển</Button> */}
        </Grid>
    );
};
export default DauKyLuHanhNoiDiaButton;
