import { ThongKeDiaDiemDuLichRequest } from "model/DiaDiemDuLich/ThongKeDiaDiemDuLichRequest";

export const ThongKeDiaDiemDuLichRequest_INIT: ThongKeDiaDiemDuLichRequest = {
    pageSize: 5,

    pageNumber: 1,
 
     kyThongKe: 1,
 
     namThongKe: 0,

     ngayCapVanBanTu: 0,
     
     ngayCapVanBanDen: 0,

     tenNamThongKe : 'Chọn năm',
 
     maHuyen: '',

     tenHuyen: '',
      
     maPhuong: '', 

     tenPhuong : '',
     
     maTinhTrang: '',

     tenTinhTrang: '',
     
     maLoaiHinh: '',

     maTinhTrangString: '',

     maHuyenString: '',
     
};
