import { FileEntry } from "apis/Common/fileEntry";
import { HoSoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO";
import { LichSuDiaDiemDuLichDTO } from "model/DiaDiemDuLich/LichSuDiaDiemDuLichDTO";
import { GiayPhepDiaDiemDuLichDTO_INIT } from "./GiayPhepDiaDiemDuLichDTO_INIT";

export const HoSoDiaDiemDuLichDTO_INIT: HoSoDiaDiemDuLichDTO = {
    id: 0,
    
    giayPhep : {...GiayPhepDiaDiemDuLichDTO_INIT},
    
    giayPhepId: 0,

    kinhDo: 0,

    viDo: 0,

    congNhan : true,
    
    ngayCapVanBan: 0,
    
    ngayHetHan: 0,
    
    ngayTao: 0,
    
    ngayCapNhat: 0,
    
     soQuyetDinh: "",
   
     coQuanCap: "",
    
     tenToChucDuLich: "",
   
     dienThoai: "",
    
     email: "",
    
     website: "",
    
     tenDiemDuLich: "",
   
     dienThoaiDuLich: "",
    
     emailDuLich: "",
    
     websiteDuLich: "",
    
     nguoiQuanLy: "",
   
     maLoaiHinh: "",
    
     tenLoaiHinh: "",
    
     maCapDuLich: "",
    
     tenCapDuLich: "",
    
     ghiChu: "",
    
     loaiHinhDichVu: "",
    
     maTinh: "92",

     tenTinh: "Thành phố Cần Thơ",

     maHuyen: "",

     tenHuyen: "",

     maPhuong: "",

     tenPhuong: "",

     tenDuong: "",

     maDuong: "",

     soNha: "",
    
     maTinhTrang: "",

     tenTinhTrang: "",

     maHoSo: "",
    
     maBienDong: "",
    
     tenBienDong: "",
    
     diaChiDayDu: "",

     files: new Array<FileEntry>(),

     lichSus: new Array<LichSuDiaDiemDuLichDTO>(),

};
