import { Box, Grid, InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FileEntry } from "apis/Common/fileEntry";
import React from "react";
import Dropzone from "react-dropzone-uploader";
import { IFileWithMeta } from "react-dropzone-uploader/dist/Dropzone";
import { bootstrapTextFieldHook } from "../input/bootstrap";
import LayoutDrop from "../layoutDropZone/layoutDrop";
import { PreviewDropZone } from "../layoutDropZone/previewDropZone";
import PreviewDropzoneFromServer2 from "../previewFileFromServer2/previewFileFromServer2";

interface IDropZoneCustom {
    acceptFiles: String;
    attachFileServer: FileEntry[];
    url: String;
    handleChangeStatus: (file: IFileWithMeta) => void;
    handleDeleteFileServer: (file: FileEntry) => void;
    handleDownloadFile: (file: FileEntry) => void;
}

const DropZoneCustom2: React.FC<IDropZoneCustom> = ({ acceptFiles, attachFileServer, url, handleDeleteFileServer, handleChangeStatus, handleDownloadFile }) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();

    return (
        <>
            <Grid item xs={12} />
            <Grid item xs={3} className="grid-item">
                <InputLabel classes={labelStyles}>Đính kèm</InputLabel>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                <Box>
                    {attachFileServer && (
                        <Box width="30vw">
                            <PreviewDropzoneFromServer2 files={attachFileServer} onDelete={handleDeleteFileServer} onDownload={handleDownloadFile} />
                        </Box>
                    )}
                    <Box width="30vw">
                        <Dropzone
                            accept={`${acceptFiles}`}
                            inputContent={
                                <Button variant="outlined" size="small" color="default" component="span" disableElevation style={{ marginRight: "10px" }}>
                                    Chọn tập tin
                                </Button>
                            }
                            multiple={true}
                            // getUploadParams={() => ({ url: `${url}` })}
                            getUploadParams={(file: IFileWithMeta) => {
                                const formData = new FormData();
                                formData.append("file", file.file);
                                let headers: any = {
                                    'csrf-token': localStorage.getItem("csrf-token")
                                }
                                return {
                                    url: `${url}`,
                                    headers: headers,
                                    body: formData,
                                };
                            }}
                            onChangeStatus={handleChangeStatus}
                            // InputComponent={(props: any) => <InputCustom acceptFiles={`${acceptFiles}`} {...props} />}
                            LayoutComponent={(props: any) => <LayoutDrop {...props} acceptFiles={acceptFiles} />}
                            PreviewComponent={(props: any) => <PreviewDropZone {...props} />}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3}></Grid>
        </>
    );
};

export default DropZoneCustom2;
