import { Box, Button, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { DiaDiemDuLichService } from "apis/DiaDiemDuLich/DiaDiemDuLichService";
import { HoSoDiaDiemDuLichDTO_INIT } from "init/DiaDiemDuLich/HoSoDiaDiemDuLichDTO_INIT";
import { HoSoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO";
import { LichSuDiaDiemDuLichDTO } from "model/DiaDiemDuLich/LichSuDiaDiemDuLichDTO";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Dispatch } from "redux";
import { GiayChungNhanLuuTruService } from "../../../../../apis/DichVuDuLich/GiayChungNhanLuuTruService";
import { GiayChungNhanKinhDoanhDichVuLuuTruDTO_INIT } from "../../../../../init/DichVuDuLich/DichVuDuLichModel_INIT";
import { GiayChungNhanKinhDoanhDichVuLuuTruDTO, TraCuuCoSoDuLichDTO } from "../../../../../model/DichVuDuLich/DichVuDuLichModel";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import LichSuKhuDuLichComponent from "./LichSuKhuDuLichComponent";
import LichSuGiayPhepCoSoLuuTruComponent from "./LichSuKhuDuLichComponent";
import ThongTinChungKhuDuLichComponent from "./ThongTinChungKhuDuLichComponent";
import ThongTinChungCoSoLuuTruComponent from "./ThongTinChungKhuDuLichComponent";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const ChiTietKhuDuLichComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [indexTab, setIndexTab] = useState(0);
    const history = useHistory();
    const location = useLocation<any>();
    const [tabRequired, setTabRequired] = useState(-1);
    const [data, setData] = useState<HoSoDiaDiemDuLichDTO>({ ...HoSoDiaDiemDuLichDTO_INIT});
    const service = new DiaDiemDuLichService();
    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        let hoSoDiaDiemId = location?.state?.hoSoDiaDiemId;
        dispatchLoading(showLoading());
        await service
            .getByHoSoID(hoSoDiaDiemId)
            .then((res) => {
                setData({
                    ...res
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <div className="dash-content">
                        <div className="dash-title">
                            <span>THÔNG TIN CHI TIẾT KHU/ĐIỂM DU LỊCH</span>
                        </div>
                    </div>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab style={tabRequired == 0 ? { color: "red" } : {}} label="Thông tin chung" />
                                <CustomTab label="Lịch sử" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <ThongTinChungKhuDuLichComponent data={data} />
                        </TabPanel>
                    )}
                    {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <LichSuKhuDuLichComponent data={data?.lichSus ? data?.lichSus : new Array<LichSuDiaDiemDuLichDTO>()}  />
                        </TabPanel>
                    )}
                    <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation>
                            {" "}
                            Trở về
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
        </div>
    );
};
export default ChiTietKhuDuLichComponent;
