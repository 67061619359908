import { Map } from "@esri/react-arcgis";
import { Box, Button, createStyles, Dialog, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { loadModules } from "esri-loader";
import React, { useEffect, useState } from "react";
import { API_KEY_MAP } from "../../../constants/constants";
import { DialogContent, DialogTitle } from "../dialog/dialogCommons";
import { bootstrapTextFieldHook } from "../input/bootstrap";
import LocatorARCMAP from "./locator";
import Marker from "./marker";
import SearchMap from "./search";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const useStylesDialog = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            minHeight: "80vh",
            maxHeight: "80vh",
        },
    })
);

export interface DialogMapProps {
    id: Number | string;
    handleCloseDialog: () => void;
    setData: (data: any) => void;
    openDialog: boolean;
    diaChiDayDu: string;
    kinhDo: number;
    viDo: number;
}

export interface FormValuesMap {
    kinhDo: String;
    viDo: String;
}

const DialogMap = ({ id, handleCloseDialog, setData, openDialog, diaChiDayDu, kinhDo, viDo }: DialogMapProps) => {
    const [view, setView] = useState<any | null>(null);
    const [map, setMap] = useState<any | null>(null);
    const [stringDiaChi, setStringDiaChi] = useState<string>("");
    const [point, setPoint] = useState<any>(null);
    const [currentKinhDo, setCurrentKinhDo] = useState<number>(0);
    const [currentViDo, setCurrentViDo] = useState<number>(0);
    // Hook
    const classes = useStyles();
    const classesDialog = useStylesDialog();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();

    useEffect(() => {
        setCurrentKinhDo(kinhDo);
        setCurrentViDo(viDo);
        setStringDiaChi(diaChiDayDu);
    }, []);

    useEffect(() => {
        loadModules(["esri/config", "esri/Map", "esri/views/MapView"]).then(([esriConfig, Map, MapView]) => {
            esriConfig.apiKey = API_KEY_MAP;
            const map = new Map({
                id: id,
                basemap: "streets-navigation-vector",
            });

            let pt = {
                center: [105.78712, 10.03572],
            };

            setPoint(pt);

            const view = new MapView({
                container: "viewDiv",
                map: map,
                center: [105.78712, 10.03572],
                zoom: 12,
            });

            setView(view);
            setMap(map);
        });
    }, []);

    // Control
    function closeDialog() {
        handleCloseDialog();
    }

    async function handleCheckPoint(e: any) {
        const { mapPoint } = e;
        const { latitude, longitude } = mapPoint;
        setCurrentViDo(latitude);
        setCurrentKinhDo(longitude);
    }

    async function handleReset() {
        setCurrentViDo(0);
        setStringDiaChi("");
        handleCloseDialog();
    }

    async function handleSubmit() {
        setData({
            currentKinhDo: currentKinhDo,
            currentViDo: currentViDo,
            diaChiDayDu: stringDiaChi,
        });
        handleCloseDialog();
    }
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            classes={{ paper: classesDialog.dialogPaper }}
            aria-labelledby="customized-dialog-title"
            open={openDialog}
            onClose={closeDialog}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleReset}>
                Địa chỉ cơ sở
            </DialogTitle>
            <DialogContent dividers>
                <Box padding={0}>
                    <Grid container>
                        <Grid item xs={3} className="grid-item" style={{ display: "flex", flexFlow: "column", justifyContent: "space-between" }}>
                            <Box>
                                <Typography variant="subtitle2" gutterBottom>
                                    <strong>Địa chỉ:</strong> {stringDiaChi}
                                </Typography>
                                <Box style={{ marginBottom: 10 }}>
                                    <TextField
                                        fullWidth
                                        label={"Kinh độ (longitude):"}
                                        name="currentKinhDo"
                                        value={Math.round(currentKinhDo * 100000) / 100000 ? Math.round(currentKinhDo * 100000) / 100000 : ""}
                                        onChange={(e) => setCurrentKinhDo(Number.parseFloat(e.target.value))}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        fullWidth
                                        label={"Vĩ độ (latitude):"}
                                        name="diaChiViDo"
                                        value={Math.round(currentViDo * 100000) / 100000 ? Math.round(currentViDo * 100000) / 100000 : ""}
                                        onChange={(e) => setCurrentViDo(Number.parseFloat(e.target.value))}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                    />
                                </Box>
                            </Box>
                            <Box marginTop="5px" style={{ display: "flex", justifyContent: "flex-start", flexFlow: "row" }}>
                                <Button variant="contained" color="primary" disableElevation className={classes.button} onClick={handleSubmit}>
                                    Lưu
                                </Button>
                                <Button variant="contained" color="primary" disableElevation className={classes.button} onClick={handleReset}>
                                    Hủy
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={9} style={{ height: "70vh" }}>
                            <Map
                                viewProperties={{ center: point && point.center, zoom: 13, map: map, basemapView: { view: view } }}
                                mapProperties={{ basemap: "streets-navigation-vector" }}
                                onClick={(e) => handleCheckPoint(e)}
                            >
                                <Marker fullDiaChi={stringDiaChi} view={view} kinhDo={kinhDo} viDo={viDo} />
                                <LocatorARCMAP view={view} setStringDiaChi={setStringDiaChi} />
                                <SearchMap
                                    view={view}
                                    setStringDiaChi={setStringDiaChi}
                                    diaChiOf={stringDiaChi}
                                    setKinhDo={setCurrentKinhDo}
                                    setViDo={setCurrentViDo}
                                />
                                {/* <CheckPoint view={view} formik={formik} /> */}
                            </Map>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
export default DialogMap;
