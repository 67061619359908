import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import RefreshIcon from "@material-ui/icons/Refresh";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { TINHTRANG_HOATDONG } from "../../../constants/DichVuDuLichConstants";
import { HoSoThuLyDichVuDuLich } from "../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0.5),
        },
        menuItem: {
            color: "#0821af",
            textDecoration: "none",
            padding: "10px 15px",
            "&:hover": {
                color: "#0821af",
                cursor: "pointer",
                backgroundColor: "#bfc1cc",
                fontWeight: "bold",
            },
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
    })
);

export const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: "none",
        },
        sizeSmall: {
            fontSize: "13px",
        },
        startIcon: {
            marginRight: theme.spacing(0.5),
        },
        "&:focus": {
            outlineWidth: "0px",
            outlineStyle: "none",
        },
    })
)(Button);

export interface ThaoTacHoSoCuaToiPropsI {
    row: HoSoThuLyDichVuDuLich;
    reloadData: () => void;
}

export const ThaoTacCSKDDichVuVuiChoiGiaiTri = (props: ThaoTacHoSoCuaToiPropsI) => {
    const classes = useStyles();
    const { row, reloadData } = props;
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const showHidePopperThaoTac = () => {
        setOpen(!open);
    };

    const match = useRouteMatch();

    console.log(match.params);

    const handleCloseProper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const clickChiTiet = () => {
        history.push("/dich-vu-va-du-lich/chi-tiet-dich-vu-vui-choi-giai-tri", { params: row.giayChungNhanId });
    };
    const clickChinhSua = () => {
        history.push('/cskd-dich-vu-vui-choi-giai-tri', { isUpdate: true, giayChungNhanId: row.giayChungNhanId, isNhapDauKy: true });
        
    };
    const clickThuHoi = () => {
        history.push('/dich-vu-va-du-lich/tam-ngung-dich-vu-vui-choi-giai-tri', { giayChungNhanId: row.giayChungNhanId, hoSoThuLyId: row.hoSoThuLyId });
    };

    return (
        <>
            <CustomButton
                className={classes.margin}
                startIcon={<ListIcon />}
                size="small"
                color="primary"
                disableRipple
                ref={anchorRef}
                aria-haspopup="true"
                aria-controls={open ? "menu-list-grow" : undefined}
                onClick={() => {
                    showHidePopperThaoTac();
                }}
            ></CustomButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseProper}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem key={"chinh-sua"}>
                                        <Button onClick={clickChinhSua} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                            Chỉnh sửa
                                        </Button>
                                    </MenuItem>
                                    <MenuItem key={"menu-item-thuhoi"} onClick={(e) => clickThuHoi()}>
                                        <Button startIcon={<RefreshIcon color="error" />} size="small" disableRipple>
                                        {props.row.maTrangThai === TINHTRANG_HOATDONG.ma ? "Tạm ngưng" : "Chỉnh sửa tạm ngưng"}
                                        </Button>
                                    </MenuItem>
                                    <MenuItem key={"menu-item-huy"}>
                                        <Button startIcon={<VisibilityIcon style={{ color: "green" }} />} size="small" disableRipple onClick={clickChiTiet}>
                                            Xem chi tiết
                                        </Button>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
