export type VanHoaNgheThuatConstants = {
    ten: string;
    ma: string;
}
export const LOAIBIENDONG_CAPMOI: VanHoaNgheThuatConstants = {
    ten: "Cấp mới",
    ma: "1"
}
export const LOAIBIENDONG_THUHOI: VanHoaNgheThuatConstants = {
    ten: "Thu hồi",
    ma: "2"
}
export const TINHTRANG_HOATDONG: VanHoaNgheThuatConstants = {
    ten: "Đang Hoạt động",
    ma: "1"
}
export const TINHTRANG_THUHOI: VanHoaNgheThuatConstants = {
    ten: "Thu hồi",
    ma: "2"
}
export const TINHTRANG_NGUNGHOATDONG: VanHoaNgheThuatConstants = {
    ten: "Hết hạn",
    ma: "3"
}

export const ACCEPT_FILES = "image/*,.doc,.docx,.xls,.xlsx,.pdf";