import { FileEntry } from "../apis/Common/fileEntry";

export interface GiayChungNhanDiSanVanHoa {
    id: number;
    ngayTao: number;
    ngayCapNhap: number;
    soChungNhan: string;
    ngayCapChungNhan: number;
    ngayCap: number;
    ngayHetHan: number;
    tenToChuc: string;
    dienThoai: string;
    fax: string;
    tenHuyen: string;
    maHuyen: string;
    tenPhuong: string;
    maPhuong: string;
    tenDuong: string;
    maDuong: string;
    soNha: string;
    soQD: string;
    ngayCapQD: number;
    noiCapQD: string;
    soCMND: string;
    maTrangThai: string;
    tenTrangThai: string;
    ngayCapCMND: number;
    noiCapCMND: string;
    nguoiDaiDienTheoPhapLuat: string;
    ngaySinh: number;
    chucDanh: string;
    trichYeu: string;
    canCu: string;
    deNghi: string;
    hoatDong: boolean;
    maBienDong: string;
    tenBienDong: string;
    lyDoThuHoi: string;
    ngayThuHoi: number;
    lanCapLai: number;
    ghiChu: string;
    className: string;
    fileCapMois: FileEntry[];
    files: FileEntry[];
    fileThuHois: FileEntry[];
    isCapSoMoi: boolean;
}
export const GiayChungNhanDiSanVanHoa_INIT: GiayChungNhanDiSanVanHoa = {
    id: 0,
    ngayTao: 0,
    ngayCapNhap: 0,
    soChungNhan: "",
    ngayCapChungNhan: 0,
    ngayCap: 0,
    ngayHetHan: 0,
    tenToChuc: "",
    dienThoai: "",
    fax: "",
    tenHuyen: "",
    maHuyen: "",
    tenPhuong: "",
    maPhuong: "",
    tenDuong: "",
    maDuong: "",
    soNha: "",
    soQD: "",
    ngayCapQD: 0,
    noiCapQD: "",
    soCMND: "",
    maTrangThai: "",
    tenTrangThai: "",
    ngayCapCMND: 0,
    noiCapCMND: "",
    nguoiDaiDienTheoPhapLuat: "",
    ngaySinh: 0,
    chucDanh: "",
    trichYeu: "",
    canCu: "",
    deNghi: "",
    hoatDong: true,
    maBienDong: "",
    tenBienDong: "",
    lyDoThuHoi: "",
    ngayThuHoi: 0,
    lanCapLai: 0,
    ghiChu: "",
    className: "",
    fileCapMois: new Array<FileEntry>(),
    fileThuHois: new Array<FileEntry>(),
    files: new Array<FileEntry>(),
    isCapSoMoi: false,
};
