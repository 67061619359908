import { BaseService } from "../baseService";
import AXIOS_INSTANCE from "../index";
import { DanhMucDungChungDTO } from "./model/DanhMucDTO";


export class DanhMucQuocGiaService extends BaseService {
    findAll = async (): Promise<DanhMucDungChungDTO[]> => {
        let result: DanhMucDungChungDTO[] = [];
        await AXIOS_INSTANCE.get("danh-muc-quoc-gia/find-all",this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    findByCode = async (code: String):  Promise<any> => {
        let result ;
        await AXIOS_INSTANCE.post("danh-muc-quoc-gia/find-by-code",{ code })
            .then((response) => {
                if (response?.data?.error_code === "SUCCESSFUL" && response?.data?.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };
}
