export type DiSanVanHoaConstants = {
    ten : string;
    ma : string;
}
export const LOAIBIENDONG_CAPMOI :  DiSanVanHoaConstants = {
    ten : "Cấp mới",
    ma : "1"
}
export const LOAIBIENDONG_CAPLAI :  DiSanVanHoaConstants = {
    ten : "Cấp lại",
    ma : "2"
}
export const LOAIBIENDONG_THUHOI :  DiSanVanHoaConstants = {
    ten : "Thu hồi",
    ma : "3"
}
export const TINHTRANG_HOATDONG :  DiSanVanHoaConstants = {
    ten : "Đang Hoạt động",
    ma : "1"
}
export const TINHTRANG_THUHOI :  DiSanVanHoaConstants = {
    ten : "Thu hồi",
    ma : "2"
}
export const TINHTRANG_TAMNGUNG :  DiSanVanHoaConstants = {
    ten : "Tạm ngưng",
    ma : "4"
}
export const TINHTRANG_GANHETHAN :  DiSanVanHoaConstants = {
    ten : "Gần hết hạn",
    ma : "5"
}

export const LIST_BIENDONG : DiSanVanHoaConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_CAPLAI,
    LOAIBIENDONG_THUHOI
]

export const LIST_TINHTRANG : DiSanVanHoaConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_THUHOI,
    TINHTRANG_TAMNGUNG,
    TINHTRANG_GANHETHAN
]

export const LIST_TINHTRANG_NHAPDAUKY : DiSanVanHoaConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_THUHOI,
]

export const ACCEPT_FILES = "image/*,.doc,.docx,.xls,.xlsx,.pdf";

export const  DUPLICATE = '409'