import MomentUtils from "@date-io/moment";
import { createStyles, Grid, InputLabel, makeStyles, TextField, Theme } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useContextDropZone } from "hooks/useContextDropZone";
import { LichSuDiaDiemDuLichDTO_INIT } from "init/DiaDiemDuLich/LichSuDiaDiemDuLichDTO_INIT";
import { HoSoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO";
import { LichSuDiaDiemDuLichDTO } from "model/DiaDiemDuLich/LichSuDiaDiemDuLichDTO";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import AXIOS_INSTANCE from "../../../../apis";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { ACCEPT_FILES } from "../../../../constants/DichVuDuLichConstants";
import StringUtil from "../../../../utils/stringUtils";
import DropZoneCustom from "../../../commons/hoso/CustomDropzone/DropZoneCustom";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { IFileWithMeta } from "react-dropzone-uploader";
import { FileEntry } from "apis/Common/fileEntry";
import { createFileType, downLoadFile } from "apis/Common/exportUtil";
interface diaDiemDuLichProps {
    data: HoSoDiaDiemDuLichDTO;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
    lichSu: LichSuDiaDiemDuLichDTO;
}

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabel = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const ThongTinBienDongComponent = (props: diaDiemDuLichProps) => {
    const [submit, setSubmit] = useState(false);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const rightStyle = useStylesLabelRight();
    const leftStyle = useStylesLabelLeft();
    const label = useStylesLabel();
    const [data, setData] = useState<HoSoDiaDiemDuLichDTO>();
    const [lichSu, setLichSu] = useState<LichSuDiaDiemDuLichDTO>({ ...LichSuDiaDiemDuLichDTO_INIT });
    const fileServerService = new FileServerService();
    const { onUpdateData } = props;
    useEffect(() => {
        initData();
    }, [props.data,props.submit, props.lichSu]);

    const initData = () => {
        setData(props?.data);
        setSubmit(props.submit);
        setLichSu(props.lichSu);
    };
    //onchange
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    };
    const handleChange = (e: any) => {
        onUpdateData(e.target.name, e.target.value);
    };

     //file
     const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        console.log("data", ...lichSu.files);
        
        let mems = [...lichSu.files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...lichSu.files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số quyết định</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.giayPhep?.soQuyetDinh}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.giayPhep?.ngayCapVanBan != null ? moment(data?.giayPhep?.ngayCapVanBan).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày hết hạn</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.giayPhep?.ngayHetHan != null ? moment(data?.giayPhep?.ngayHetHan).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tình trạng</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.giayPhep?.tenTinhTrang}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tên Khu/Điểm du lịch</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.tenDiemDuLich}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.diaChiDayDu}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Điện thoại</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.dienThoai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Fax</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    {/* <InputLabel classes={rightStyle}>{data.fax}</InputLabel> */}
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Email</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.email}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Website</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.website}</InputLabel>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel classes={label} required>
                        Lý do
                    </InputLabel>
                    <TextField
                        fullWidth
                        name="lyDo"
                        InputLabelProps={{ classes: labelStyles }}
                        value={lichSu.lyDo}
                        InputProps={{
                            disableUnderline: true,
                            classes: inputStyles,
                        }}
                        onChange={handleChange}
                        error={submit && StringUtil.isNullOrEmty(lichSu.lyDo)}
                        helperText={submit && StringUtil.isNullOrEmty(lichSu.lyDo) ? "Vui lòng nhập lý do " : ""}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles} required>
                        Ngày biến động
                    </InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={lichSu.ngayBienDong && lichSu.ngayBienDong != 0 ? new Date(lichSu.ngayBienDong) : null}
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayBienDong")}
                            error={submit && StringUtil.isNullOrEmty(lichSu.ngayBienDong)}
                            helperText={submit && StringUtil.isNullOrEmty(lichSu.ngayBienDong) ? "Vui lòng nhập ngày biến động " : ""}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <InputLabel classes={label}>Ghi chú</InputLabel>
                    <TextField
                        fullWidth
                        name="ghiChu"
                        InputLabelProps={{ classes: labelStyles }}
                        value={lichSu.ghiChu}
                        InputProps={{
                            disableUnderline: true,
                            classes: inputStyles,
                        }}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} container className="grid-item">
                <DropZoneCustom
                    acceptFiles={ACCEPT_FILES}
                    attachFileServer={lichSu.files}
                    url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                    handleChangeStatus={handleChangeStatus}
                    handleDeleteFileServer={handleDeleteFileServer}
                    handleDownloadFile={handleDownloadFile}
                    />
                </Grid>
            </Grid>
        </>
    );
};
export default ThongTinBienDongComponent;
