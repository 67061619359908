import { Box, Button, Grid, InputBase, Snackbar,  Link,  SvgIcon } from '@material-ui/core';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataListUser } from './dataListUser';
import { UserEditDialog } from './userEditDialog';
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import { User } from '../../../../model/user';
import { hideLoading, LoadingAction, showLoading } from '../../../../redux/actions/applicationAction';
import { AlertMessage, CustomAlert } from '../../../../components/commons/alert';
import { UserManagementService } from '../../../../apis/userMgt/userManagementService';
import { BaseResponse } from '../../../../apis/baseService';
import StringUtil from '../../../../utils/stringUtils';
import { bootstrapTextFieldHook } from '../../../../components/commons/input/bootstrap';
import ButtonExt from '../../../../components/commons/button/customButton';
import { ConfirmationDialogRaw } from '../../../../components/commons/dialog/confirmDialog';
import { createFileType, downLoadFile } from '../../../../apis/Common/exportUtil';
export const UserManagement = () => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openUnlockUserDlg, setOpenUnlockUserDlg] = useState(false);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: 'error', open: false } as AlertMessage);

    const [seachQuery, setSearchQuery] = useState<string>('');
    const [dataList, setDataList] = useState<User[]>([]);
    const [itemSeleted, setItemSeleted] = useState<User>({} as User);

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);

    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? 'success' : 'error' });
    }

    const loadDataTable = async () => {
        dispatch(showLoading());
        await new UserManagementService().getUser(seachQuery, page * size, size).then((p) => {
            setDataList(p.rows);
            setTotal(p.total);
        }).catch((e) => {
            console.log(e);
        }).finally(() => {
            dispatch(hideLoading());
        });
    }
    useEffect(() => {
        loadDataTable();
    }, [page, size]);// eslint-disable-line react-hooks/exhaustive-deps

    const doSearch = () => {
        loadDataTable();
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSize(Number(event.target.value));
    }

    const handleSubmitData = (resp: BaseResponse) => {
        if (resp.isSuccess) {
            setOpenDialog(false);
            setPage(0);
            loadDataTable();
            showMessageAlert(resp.message);
        } else {
            showMessageAlert(resp.message, false);
        }
    }

    const showEditDialog = (item: User) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    }

    const handleDeleteItem = (userId: string) => {
        if (!StringUtil.isNullOrEmty(userId)) {
            setItemSeleted({ userId: userId } as User);
            setOpenDeleteUserDlg(true);
        }
    }

    const handleUnlockUser = (userId: string, userLocked: boolean) => {
        if (!StringUtil.isNullOrEmty(userId) && userLocked) {
            setItemSeleted({ userId: userId } as User);
            setOpenUnlockUserDlg(true);
        }
    }

    const handleCloseUnlockUser = async (isOk?: boolean) => {
        let isReloadTable = false;
        if (Boolean(isOk)) {
            if (!StringUtil.isNullOrEmty(itemSeleted.userId)) {
                dispatch(showLoading());
                await new UserManagementService().unlockedUser(itemSeleted.userId).then((p) => {
                    isReloadTable = p.data;
                }).finally(() => {
                    dispatch(hideLoading());
                });
            }
        }
        setOpenUnlockUserDlg(false);
        if (isReloadTable) {
            loadDataTable();
        }
    };

    const handleCloseDeleteUser = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (!StringUtil.isNullOrEmty(itemSeleted.userId)) {
                dispatch(showLoading());
                await new UserManagementService().deleteUser(itemSeleted.userId).then((p) => {
                    if (Boolean(p.data)) {
                        loadDataTable();
                        showMessageAlert('Xóa người dùng thành công');
                    } else {
                        showMessageAlert('Xóa người dùng không thành công', false);
                    }
                }).catch(e => {
                    showMessageAlert(e, false);
                }).finally(() => {
                    dispatch(hideLoading());
                });
            }
        }
        setOpenDeleteUserDlg(false);
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage(alertMessage => ({
            ...alertMessage,
            open: false
        }))
    }

    async function handleExportFile(extension: string) {
        dispatch(showLoading());
        await new UserManagementService().exportUser(seachQuery, extension).then((resp) => {
            if (resp && resp.data) {
                downLoadFile(resp.data, createFileType(extension), 'Danh_sach_nguoi_dung');
            }
        }).finally(() => {
            dispatch(hideLoading());
        });
    }

    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Quản lý người dùng hệ thống</span>
            </div>
            <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputBase fullWidth placeholder="Nhập nội dung tìm kiếm" classes={bootstrapTextFieldHook.useInput()}
                            inputProps={{ 'aria-label': 'description' }} value={seachQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} className="button-group-center">
                        <Button variant="contained" color="primary" disableElevation
                            onClick={doSearch}>
                            Tìm kiếm
                        </Button>
                        <ButtonExt variant="contained" color="warning" disableElevation
                            onClick={() => showEditDialog({ isActive: false, isResetPassword: false } as User)}>
                            Thêm mới
                        </ButtonExt>
                    </Grid>
                    <Grid item xs={12} className="grid-item" container justify="flex-end">
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                handleExportFile("docx");
                            }}
                        >
                            <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    fill="#007bff"
                                    d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                                />
                            </SvgIcon>
                        </Link>
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                handleExportFile("pdf");
                            }}
                        >
                            <SvgIcon fontSize="large">
                                <path
                                    fill="#dc3545"
                                    d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                                />
                            </SvgIcon>
                        </Link>
                        <Link
                            title="Tải xuống"
                            href="#"
                            onClick={() => {
                                handleExportFile("xlsx");
                            }}
                        >
                            <SvgIcon fontSize="large">
                                <path
                                    fill="#28a745"
                                    d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                                />
                            </SvgIcon>
                        </Link>
                        </Grid>
                </Grid>
                <div>
                    <DataListUser dataList={dataList} deleteItem={handleDeleteItem} unlockUser={handleUnlockUser}
                        pageProps={{
                            page: page, count: total, rowsPerPage: size,
                            onChangePage: handleOnChangePage,
                            onChangeRowsPerPage: handOnChangeRowsPerPage
                        }}
                        modalEdit={{ open: true, openEditDialog: showEditDialog }} />
                    {
                        openDialog &&
                        <UserEditDialog openDialog={openDialog} onDialogClose={() => { setOpenDialog(false) }}
                            itemEdit={itemSeleted} onSubmitData={handleSubmitData} />
                    }
                </div>

                <ConfirmationDialogRaw id="confirm-dialog-unlock-user" keepMounted open={openUnlockUserDlg}
                    title="Mở khóa tài khoản người dùng"
                    content="Bạn có đồng ý thực hiện hành động này?"
                    value={itemSeleted.userId} onClose={handleCloseUnlockUser}
                />
                <ConfirmationDialogRaw id="confirm-dialog-delete-user" keepMounted open={openDeleteUserDlg}
                    title="Xóa tài khoản người dùng"
                    content="Bạn có đồng ý xóa tài khoản này?"
                    value={itemSeleted.userId} onClose={handleCloseDeleteUser}
                />
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>{alertMessage.content}</CustomAlert>
                </Snackbar>
            </form>
        </div >
    )
}

