import {
    Box,
    Checkbox,
    Dialog,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    NativeSelect,
    Radio,
    RadioGroup,
    RadioProps,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    makeStyles,
    Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { FieldArray, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { DanhMucBieuMauService } from "../../../apis/quanTri/danhMucBieuMauService";
import CellUpload from "../../../components/commons/cellUpload/cellUpload";
import { DialogContent, DialogTitle } from "../../../components/commons/dialog/dialogCommons";
import { Cell, Row } from "../../../components/commons/table/tableCommons";
import { ROUTE_CN, ROUTE_PAGE_DS } from "../../../routes";
import GetAppIcon from "@material-ui/icons/GetApp";
import { FileEntry } from "../../../apis/Common/fileEntry";
import { FileServerService } from "../../../apis/Common/FileServerService";
import { BaseResponse } from "../../../apis/baseService";
import { BieuMauAttachFileDTO, BieuMauOptions, DanhMucBieuMauDTO, LoaiBieuMauEnum } from "apis/quanTri/model/danhMucBieuMau";
import { bootstrapTextFieldHook } from "components/commons/input/bootstrap";
import { Autocomplete } from "@material-ui/lab";
import { RoutePropsI } from "routes/route_vhdl";

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
});

interface IModalProps {
    onSubmitData: (data: DanhMucBieuMauDTO) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: DanhMucBieuMauDTO | null;
}

export function StyledRadio(props: RadioProps) {
    const classes = useStyles();

    return <Radio className={classes.root} disableRipple color="default" icon={<span className={classes.icon} />} {...props} />;
}

const DialogBieuMau: React.FC<IModalProps> = (props: IModalProps) => {
    // Constant
    const initialValues: DanhMucBieuMauDTO = {
        id: null,
        name: "",
        isActive: false,
        path: "",
        specializ: true,
    };
    // State
    const [openDialog, setOpenDialog] = useState(false);
    // Hook
    const formikRef = useRef<any>(null);
    const validateionSchema = Yup.object().shape({
        name: Yup.string().required("Tên biểu mẫu bắt buộc nhập"),
        path: Yup.string().required("Nghiệp vụ bắt buộc nhập"),
    });
    useEffect(() => {
        setOpenDialog(props.openDialog);
        async function fetchData() {
            if (props.itemEdit && props.itemEdit.id) {
                await new DanhMucBieuMauService().getDetailDanhMucBieuMau(props.itemEdit.id).then((res) => {
                    if (res) {
                        formikRef.current.setValues({ ...res });
                    }
                });
            }
        }
        fetchData();
    }, [props.itemEdit, props.openDialog, props.itemEdit, formikRef]);

    // Control
    const handleSubmit = (values: DanhMucBieuMauDTO) => {
        props.onSubmitData(values);
    };
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const [loaiBieuMau, setLoaiBieuMau] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<RoutePropsI | null>(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (props.itemEdit && loaiBieuMau) {
            const path = props.itemEdit && props.itemEdit.path;
            const value = ROUTE_CN.find((item) => item.duongDan === path);
            value && setValue(value);
        } else {
            const path = props.itemEdit && props.itemEdit.path;
            const value = ROUTE_PAGE_DS.find((item) => item.duongDan === path);
            value && setValue(value);
        }
    }, [props.itemEdit, loaiBieuMau]);

    return (
        <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
            <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                Cấu hình biểu mẫu
            </DialogTitle>

            <DialogContent dividers>
                <Box padding={2}>
                    <Grid spacing={2} container>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validateionSchema}
                            onSubmit={handleSubmit}
                            innerRef={formikRef}
                            validateOnChange={false}
                        >
                            {(formikProps) => {
                                const { handleChange, values, errors } = formikProps;
                                return (
                                    <form noValidate autoComplete="off" onSubmit={formikProps.handleSubmit} style={{ width: "100%" }}>
                                        <Grid item xs={12} className="grid-item">
                                            <TextField
                                                fullWidth
                                                required
                                                label={"Tên biểu mẫu"}
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                error={Boolean(errors.name)}
                                                InputProps={{ disableUnderline: true }}
                                                FormHelperTextProps={{ className: "-error" }}
                                                helperText={Boolean(errors.name) && errors.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingLeft: 10 }}>
                                            <InputLabel classes={labelStyles}>Loại biểu mẫu</InputLabel>
                                            <RadioGroup
                                                row
                                                name="specializ"
                                                value={values.specializ ? String(LoaiBieuMauEnum.NGHIEP_VU) : String(LoaiBieuMauEnum.DANH_SACH)}
                                                onChange={(e) => {
                                                    formikProps.setFieldValue("specializ", !values.specializ);
                                                    setLoaiBieuMau(!loaiBieuMau);
                                                }}
                                            >
                                                {BieuMauOptions?.map((item) => (
                                                    <FormControlLabel
                                                        value={item.value}
                                                        control={<StyledRadio size="small" color="default" />}
                                                        label={item.title}
                                                        labelPlacement="end"
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </Grid>
                                        {!values.specializ ? (
                                            <Grid item xs={12} className="grid-item">
                                                <InputLabel error={Boolean(errors.path)} required classes={labelStyles}>
                                                    Danh sách
                                                </InputLabel>
                                                {/* <NativeSelect
                                                    value={values.path}
                                                    onChange={(e: any) => {
                                                        formikProps.setFieldValue("path", e.target.value);
                                                        let index = e.nativeEvent.target.selectedIndex;
                                                        formikProps.setFieldValue("bussinessName", e.nativeEvent.target[index].text);
                                                    }}
                                                    error={Boolean(errors.path)}
                                                    input={<InputBase fullWidth classes={inputStyles} />}
                                                >
                                                    <option value="">Chọn danh sách</option>
                                                    {ROUTE_PAGE_DS.map((item, i) => (
                                                        <option key={i} value={item.duongDan}>
                                                            {item.ten}
                                                        </option>
                                                    ))}
                                                </NativeSelect>
                                                {Boolean(errors.path) && (
                                                    <FormHelperText className="-error" classes={helperTextStyles}>
                                                        {errors.path}
                                                    </FormHelperText>
                                                )} */}
                                                <Autocomplete
                                                    classes={classes}
                                                    disableCloseOnSelect
                                                    options={ROUTE_PAGE_DS}
                                                    onChange={(e, val) => {
                                                        formikProps.setFieldValue("path", val?.duongDan);
                                                        formikProps.setFieldValue("bussinessName", val?.ten);
                                                        setValue(val);
                                                    }}
                                                    placeholder="Chọn danh sách"
                                                    value={value}
                                                    getOptionSelected={(option, value) => {
                                                        return option.ten === value.ten;
                                                    }}
                                                    getOptionLabel={(option) => `${option.ten}`}
                                                    renderOption={(option, { selected }) => {
                                                        return (
                                                            <Typography noWrap={true} color="textPrimary" style={{ padding: 5 }} classes={inputStyles}>
                                                                {option.ten}
                                                            </Typography>
                                                            
                                                        );
                                                    }}
                                                    renderTags={(selected) => {
                                                        return (
                                                            <Typography
                                                                style={{ maxWidth: 360, position: "absolute", zIndex: 99, left: "8px", fontSize: "13px" }}
                                                                noWrap={true}
                                                                color="textPrimary"
                                                                classes={inputStyles}
                                                            >
                                                                {ROUTE_PAGE_DS.find((item) => item.duongDan === values.path)?.ten}
                                                            </Typography>
                                                        );
                                                    }}
                                                    renderInput={(params) => {
                                                        return (
                                                            <div className="padding-top-8px" ref={params.InputProps.ref}>
                                                                <TextField
                                                                    {...params}
                                                                    onChange={handleChange}
                                                                    classes={inputStyles}
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        classes: inputStyles,
                                                                    }}
                                                                    placeholder="Chọn danh sách"
                                                                    error={Boolean(errors.path)}
                                                                />
                                                            </div>
                                                        );
                                                    }}
                                                />
                                                {Boolean(errors.path) && (
                                                    <FormHelperText className="-error" classes={helperTextStyles}>
                                                        {errors.path}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} className="grid-item">
                                                <InputLabel required classes={labelStyles}>
                                                    Nghiệp vụ
                                                </InputLabel>
                                                <NativeSelect
                                                    value={values.path}
                                                    onChange={(e: any) => {
                                                        formikProps.setFieldValue("path", e.target.value);
                                                        let index = e.nativeEvent.target.selectedIndex;
                                                        formikProps.setFieldValue("bussinessName", e.nativeEvent.target[index].text);
                                                    }}
                                                    error={Boolean(errors.path)}
                                                    input={<InputBase fullWidth classes={inputStyles} />}
                                                >
                                                    <option value="">Chọn Nghiệp vụ</option>
                                                    {ROUTE_CN.map((item, i) => (
                                                        <option key={i} value={item.duongDan}>
                                                            {item.ten}
                                                        </option>
                                                    ))}
                                                </NativeSelect>
                                                {Boolean(errors.path) && (
                                                    <FormHelperText className="-error" classes={helperTextStyles}>
                                                        {errors.path}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                        )}
                                        {/* <Grid item xs={12} className="grid-item">
                                            <InputLabel required>Nghiệp vụ</InputLabel>
                                            <NativeSelect
                                                value={values.path}
                                                onChange={(e: any) => {
                                                    formikProps.setFieldValue("path", e.target.value);
                                                    let index = e.nativeEvent.target.selectedIndex;
                                                    formikProps.setFieldValue("bussinessName", e.nativeEvent.target[index].text);
                                                }}
                                                error={Boolean(errors.path)}
                                                input={<InputBase fullWidth />}
                                            >
                                                <option value=""> </option>
                                                {ROUTE_CN.map((item, index) => {
                                                    return <option value={item.duongDan}>{item.ten}</option>;
                                                })}
                                            </NativeSelect>
                                            {Boolean(errors.path) && <FormHelperText className="-error">{errors.path}</FormHelperText>}
                                        </Grid> */}
                                        <Grid item xs={12} className="grid-item">
                                            <InputLabel>Đính kèm</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <DataAttachFile formikProps={formikProps} />
                                        </Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disableRipple
                                                        color="default"
                                                        size="small"
                                                        checked={formikProps.values.isActive}
                                                        onChange={(e) => formikProps.setFieldValue("isActive", !formikProps.values.isActive)}
                                                        name="isActive"
                                                    />
                                                }
                                                label="Hoạt động"
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="gridItem">
                                            <Box className="button-group">
                                                <Button variant="contained" color="primary" disableElevation type="submit">
                                                    Lưu
                                                </Button>
                                                <Button variant="contained" color="secondary" disableElevation>
                                                    Xóa
                                                </Button>
                                                <Button variant="contained" color="default" disableElevation onClick={props.onDialogClose}>
                                                    Hủy
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

async function handleDownloadFile(file: BieuMauAttachFileDTO) {
    let requestData: FileEntry = {
        fileEntryId: 0,
        folderId: 0,
        userId: '',
        userName: '',
        name: file.fileName as string,
        createdDate: 0,
        extension: file.contentType as string,
        mimeType: file.contentType as string,
        orginName: '',
        description: '',
        version: '',
        docId: file.fileId as string,
        fileParentId: 0,
        updatedDate: 0,
        url: '',
        hoatDong: false,
        resourceName: '',
        resourcePK: 0,
        isDeleted: false,
        fileName: '',
        fileId: ''
    }

    await new FileServerService().downloadFile(requestData).then((res) => {
        let blob = new Blob([res.data], { type: res.data.type });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file.fileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    });
}

export interface DataAttachFilePropsI {
    formikProps: FormikProps<DanhMucBieuMauDTO>;
}
const DataAttachFile: React.FC<DataAttachFilePropsI> = ({ formikProps }) => {
    function handleCallbackUploadFile(resp: BaseResponse, index: number) {
        //show alter notify..not impl
        if (resp.isSuccess && resp.data[0]) {
            let attachFile = {
                id: 0,
                fileId: resp.data[0].docId,
                fileName: resp.data[0].name,
                fileSize: 0,
                contentType: resp.data[0].extension,
                screenName: resp.data[0].name,
                isEdit: true,
            } as BieuMauAttachFileDTO;
            formikProps.setFieldValue(`attachFiles[${index}]`, attachFile);
        }
    }
    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">
                            STT
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="40%">
                            Tên hiển thị
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="40%">
                            Tên tập tin
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="15%">
                            Thao tác
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <FieldArray
                        name="attachFiles"
                        render={(arrayHelpers) => (
                            <>
                                {formikProps.values.attachFiles !== undefined &&
                                    formikProps.values.attachFiles.map((item, index) => (
                                        <Row key={index}>
                                            <Cell scope="row" align="center">
                                                {index + 1}
                                            </Cell>
                                            <Cell align="left">
                                                <InputBase
                                                    disabled={!Boolean(item.isEdit)}
                                                    name={`attachFiles[${index}].screenName`}
                                                    value={item.screenName}
                                                    onChange={formikProps.handleChange}
                                                    fullWidth
                                                />
                                            </Cell>
                                            <Cell align="center">
                                                {/* <Box component="span" marginRight={1}>
                                                    {item.fileName}
                                                </Box> */}
                                                {item.fileName && (
                                                    <Button
                                                        variant="text"
                                                        color="default"
                                                        disableRipple
                                                        startIcon={<GetAppIcon color="primary" />}
                                                        onClick={() => handleDownloadFile(item)}
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        {item.fileName.length > 30
                                                            ? `${item.fileName.slice(0, 10)}...${item.fileName.slice(20)}`
                                                            : item.fileName}
                                                    </Button>
                                                )}
                                                <CellUpload index={index} isEdit={item.isEdit} callbackUploadFile={handleCallbackUploadFile} />
                                            </Cell>
                                            <Cell align="center">
                                                <Box display="flex" justifyContent="center">
                                                    {item.isEdit ? (
                                                        <IconButton
                                                            color="primary"
                                                            size="small"
                                                            aria-label="save-btn"
                                                            onClick={() => {
                                                                formikProps.setFieldValue(`attachFiles[${index}].isEdit`, false);
                                                            }}
                                                        >
                                                            <SaveIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton
                                                            color="primary"
                                                            size="small"
                                                            aria-label="edit-btn"
                                                            onClick={() => formikProps.setFieldValue(`attachFiles[${index}].isEdit`, true)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}

                                                    <IconButton
                                                        color="secondary"
                                                        aria-label="delete"
                                                        size="small"
                                                        onClick={() => {
                                                            arrayHelpers.remove(index);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </Cell>
                                        </Row>
                                    ))}
                                <Row>
                                    <td colSpan={4} align="right" style={{ paddingTop: "5px" }}>
                                        <Button
                                            // disabled={!formikProps.dirty}
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            onClick={() => {
                                                arrayHelpers.push({
                                                    fileName: "",
                                                    isEdit: true,
                                                    fileId: "",
                                                    fileSize: 0,
                                                    contentType: "",
                                                } as BieuMauAttachFileDTO);
                                            }}
                                        >
                                            Thêm mới
                                        </Button>
                                    </td>
                                </Row>
                            </>
                        )}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DialogBieuMau;
