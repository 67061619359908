import { lazy } from "react";
import { BaoCaoTinhHinhGiayChungChi } from "../components/DiSanVanHoa/BaoCao/GiayChungChi/BaoCaoTinhHinhGiayChungChi";
import { BaoCaoDanhSachGiayChungNhan } from "../components/DiSanVanHoa/BaoCao/GiayChungNhan/baoCaoDanhSachGiayChungNhan";
import CapSoDiSanVanHoaComponent from "../components/DiSanVanHoa/CapSoGiayPhep/CapSoDiSanVanHoaComponent";
import ChiTietGiayChungChiDiSanVanHoa from "../components/DiSanVanHoa/ChiTiet/GiayChungChi/ChiTietGiayChungChiDiSanVanHoa";
import ChiTietDiSanVanHoaGiayChungNhan from "../components/DiSanVanHoa/ChiTiet/GiayChungNhan/chiTietDiSanVanHoaGiayChungNhan";
import { DanhMucCapDiSanComponent } from "../components/DiSanVanHoa/DanhMuc/DanhMucDiSanVH/DanhMucCapDiSanComponent";
import { DanhMucLoaiDiSanComponent } from "../components/DiSanVanHoa/DanhMuc/DanhMucDiSanVH/DanhMucLoaiDiSanComponent";
import CapLaiTuBoDiTichComponent from "../components/DiSanVanHoa/GiaoDienThuLy/ChungChiTuBoDiTich/CapLai/CapLaiTuBoDiTichComponent";
import CapMoiTuBoDiTichComponent from "../components/DiSanVanHoa/GiaoDienThuLy/ChungChiTuBoDiTich/CapMoi/CapMoiTuBoDiTichComponent";
import ThuHoiTuBoDiTichComponent from "../components/DiSanVanHoa/GiaoDienThuLy/ChungChiTuBoDiTich/ThuHoi/ThuHoiTuBoDiTichComponent";
import CapGCNTuBoDiTichComponet from "../components/DiSanVanHoa/GiaoDienThuLy/GiayChungNhan/CapGCNTuBoDiTichComponet";
import CapLaiGCNTuBoDiTichComponent from "../components/DiSanVanHoa/GiaoDienThuLy/GiayChungNhan/CapLaiGCNTuBoDiTichComponent";
import QuanLyChiSanVanHoa from "../components/DiSanVanHoa/QuanLy/GiayChungChi/QuanLyChiSanVanHoa";
import ThuHoiGCNDiSanVanHoa from "../components/DiSanVanHoa/QuanLy/GiayChungNhan/thuHoiGCNDiSanVanHoa";
import { TraCuuThongTinGCN } from "../components/DiSanVanHoa/QuanLy/GiayChungNhan/traCuuThongTinGCN";
import ThongKeChungChiDiSanVanHoa from "../components/DiSanVanHoa/ThongKe/GiayChungChi/ThongKeChungChiDiSanVanHoa";
import ThongKeGiayChungNhanDiSanVanHoa from "../components/DiSanVanHoa/ThongKe/GiayChungNhan/ThongKeGiayChungNhanDiSanVanHoa";
import { AddOrEditDiSanVanHoaPhiVatThe } from "../pages/diSanVanHoa/phiVatThe/addOrEditDiSanVanHoaPhiVatThe";
import { BaoCaoDiSanVanHoaPhiVatThe } from "../pages/diSanVanHoa/phiVatThe/baoCaoDiSanVanHoaPhiVatThe";
import { ChiTietDiSanVanHoaPhiVatThe } from "../pages/diSanVanHoa/phiVatThe/chiTietDiSanVanHoaPhiVatThe";
import { SearchDiSanVanHoaPhiVatThe } from "../pages/diSanVanHoa/phiVatThe/searchDiSanVanHoaPhiVatThe";
import { ThongKeDiSanVanHoaPhiVatThe } from "../pages/diSanVanHoa/phiVatThe/thongKeDiSanVanHoaPhiVatThe";
import { AddOrEditDiSanVanHoaVatThe } from "../pages/diSanVanHoa/vatThe/addOrEditDiSanVanHoaVatThe";
import { BaoCaoDiSanVanHoaVatThe } from "../pages/diSanVanHoa/vatThe/baoCaoDiSanVanHoaVatThe";
import { ChiTietDiSanVanHoaVatThe } from "../pages/diSanVanHoa/vatThe/chiTietDiSanVanHoaVatThe";
import { SearchDiSanVanHoaVatThe } from "../pages/diSanVanHoa/vatThe/searchDiSanVanHoaVatThe";
import { ThongKeDiSanVanHoaVatThe } from "../pages/diSanVanHoa/vatThe/thongKeDiSanVanHoaVatThe";
const DanhMucLoaiHinhDoanhNghiepComponent = lazy(() =>
    import("../pages/quanTriHeThong/DanhMucLuHanhNoiDia/DanhMucLoaiHinhDoanhNghiepComponent").then(({ DanhMucLoaiHinhDoanhNghiepComponent }) => ({
        default: DanhMucLoaiHinhDoanhNghiepComponent,
    }))
);
const DanhMucTruongHopThuHoiComponent = lazy(() =>
    import("../pages/quanTriHeThong/DanhMucLuHanhNoiDia/DanhMucTruongHopThuHoiComponent").then(({ DanhMucTruongHopThuHoiComponent }) => ({
        default: DanhMucTruongHopThuHoiComponent,
    }))
);

export interface RoutePropsI {
    ten: string;
    giaoDien: any;
    duongDan: string;
    routes?: RoutePropsI[];
}

export const ROUTE_GIAY_CHUNG_CHI_CHI_TIET = {
    ten: "Chi tiết giấy chứng chỉ di sản văn hóa",
    duongDan: "/giay-chung-chi-di-san-van-hoa/chi-tiet",
    giaoDien: ChiTietGiayChungChiDiSanVanHoa,
};
export const ROUTE_GIAY_CHUNG_NHAN_CHI_TIET = {
    ten: "Xem thông tin chi tiết giấy chứng nhận đủ điều kiện hành nghề tu bổ di tích",
    duongDan: "/ho-so-thu-ly/di-san-van-hoa/get-chi-tiet-giay-chung-nhan",
    giaoDien: ChiTietDiSanVanHoaGiayChungNhan,
};

export const ROUTE_THU_HOI_GIAY_CHUNG_CHI_DSVH = {
    ten: "Thu hồi chứng chỉ hành nghề tu bổ di tích",
    duongDan: "/di-san-van-hoa/thu-hoi-chung-chi-tu-bo-di-tich",
    giaoDien: ThuHoiTuBoDiTichComponent,
};

export const ROUTE_THU_HOI_GIAY_CHUNG_NHAN_DSVH = {
    ten: "Thu hồi giấy chứng nhận đủ điều kiện hành nghề tu bổ di tích",
    duongDan: "/ho-so-thu-ly/di-san-van-hoa/thu-hoi-GCN",
    giaoDien: ThuHoiGCNDiSanVanHoa,
};

export const ROUTE_CAP_MOI_GIAY_CHUNG_CHI_DSVH = {
    ten: "Cấp chứng chỉ hành nghề tu bổ di tích",
    duongDan: "/di-san-van-hoa/cap-moi-chung-chi-tu-bo-di-tich",
    giaoDien: CapMoiTuBoDiTichComponent,
};

export const ROUTE_CN_VHDL: RoutePropsI[] = [
    //Di sản văn hóa
    {
        ten: "Cấp giấy chứng nhận đủ điều kiện hành nghề tu bổ di tích",
        duongDan: "/di-san-van-hoa/cap-moi-gcn-tu-bo-di-tich",
        giaoDien: CapGCNTuBoDiTichComponet,
    },
    ROUTE_CAP_MOI_GIAY_CHUNG_CHI_DSVH,
    {
        ten: "Cấp lại giấy chứng nhận đủ điều kiện hành nghề tu bổ di tích",
        duongDan: "/di-san-van-hoa/cap-lai-gcn-tu-bo-di-tich",
        giaoDien: CapLaiGCNTuBoDiTichComponent,
    },
    ROUTE_CAP_MOI_GIAY_CHUNG_CHI_DSVH,
    {
        ten: "Cấp lại chứng chỉ hành nghề tu bổ di tích",
        duongDan: "/di-san-van-hoa/cap-lai-chung-chi-tu-bo-di-tich",
        giaoDien: CapLaiTuBoDiTichComponent,
    },
    ROUTE_GIAY_CHUNG_CHI_CHI_TIET,
    {
        ten: "Chi tiết di sản văn hóa phi vật thể",
        duongDan: "/di-san-van-hoa-phi-vat-the/them-moi",
        giaoDien: AddOrEditDiSanVanHoaPhiVatThe,
    },
    {
        ten: "Chi tiết di sản văn hóa phi vật thể",
        duongDan: "/di-san-van-hoa-phi-vat-the/chi-tiet",
        giaoDien: ChiTietDiSanVanHoaPhiVatThe,
    },
    {
        ten: "Chi tiết di sản văn hóa vật thể",
        duongDan: "/di-san-van-hoa-vat-the/them-moi",
        giaoDien: AddOrEditDiSanVanHoaVatThe,
    },
    {
        ten: "Chi tiết di sản văn hóa vật thể",
        duongDan: "/di-san-van-hoa-vat-the/chi-tiet",
        giaoDien: ChiTietDiSanVanHoaVatThe,
    },
    ROUTE_THU_HOI_GIAY_CHUNG_CHI_DSVH,
    ROUTE_GIAY_CHUNG_NHAN_CHI_TIET,
    ROUTE_THU_HOI_GIAY_CHUNG_NHAN_DSVH
];
export const ROUTE_PAGE_VHDL: RoutePropsI[] = [
    {
        ten: "DI SẢN VĂN HÓA PHI VẬT THỂ - Tra cứu",
        duongDan: "/di-san-van-hoa-phi-vat-the/tra-cuu",
        giaoDien: SearchDiSanVanHoaPhiVatThe,
    },
    {
        ten: "CẬP NHẬT SỐ",
        duongDan: "/tu-bo-di-tich/cap-so",
        giaoDien: CapSoDiSanVanHoaComponent,
    },
    {
        ten: "DI SẢN VĂN HÓA VẬT THỂ - Tra cứu",
        duongDan: "/di-san-van-hoa-vat-the/tra-cuu",
        giaoDien: SearchDiSanVanHoaVatThe,
    },
    {
        ten: "DI SẢN VĂN HÓA PHI VẬT THỂ - Báo cáo",
        duongDan: "/di-san-van-hoa-phi-vat-the/bao-cao",
        giaoDien: BaoCaoDiSanVanHoaPhiVatThe,
    },
    {
        ten: "Tra cứu giấy chứng nhận đủ điều kiện hành nghề tu bổ di tích",
        duongDan: "/di-san-van-hoa/tra-cuu-thong-tin-GCN",
        giaoDien: TraCuuThongTinGCN,
    },
    {
        ten: "Báo cáo danh sách giấy chứng nhận đủ điều kiện hành nghề tu bổ di tích",
        duongDan: "/di-san-van-hoa/bao-cao",
        giaoDien: BaoCaoDanhSachGiayChungNhan,
    },
    {
        ten: "THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/di-san-van-hoa/thong-ke-tinh-hinh-giay-chung-nhan",
        giaoDien: ThongKeGiayChungNhanDiSanVanHoa,
    },
    {
        ten: "DI SẢN VĂN HÓA VẬT THỂ - Báo cáo",
        duongDan: "/di-san-van-hoa-vat-the/bao-cao",
        giaoDien: BaoCaoDiSanVanHoaVatThe,
    },
    {
        ten: "DI SẢN VĂN HÓA PHI VẬT THỂ - Thống kê",
        duongDan: "/di-san-van-hoa-phi-vat-the/thong-ke",
        giaoDien: ThongKeDiSanVanHoaPhiVatThe,
    },
    {
        ten: "DI SẢN VĂN HÓA VẬT THỂ - Thống kê",
        duongDan: "/di-san-van-hoa-vat-the/thong-ke",
        giaoDien: ThongKeDiSanVanHoaVatThe,
    },
    {
        ten: "TRA CỨU CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/giay-chung-chi-di-san-van-hoa/tra-cuu",
        giaoDien: QuanLyChiSanVanHoa,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/giay-chung-chi-di-san-van-hoa/bao-cao",
        giaoDien: BaoCaoTinhHinhGiayChungChi,
    },
    {
        ten: "THỐNG KÊ TÌNH HÌNH CẤP CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/giay-chung-chi-di-san-van-hoa/thong-ke",
        giaoDien: ThongKeChungChiDiSanVanHoa,
    },
    {
        ten: "Danh mục cấp di sản",
        giaoDien: DanhMucCapDiSanComponent,
        duongDan: "/danh-muc-cap-di-san",
    },
    {
        ten: "Danh mục loại di sản",
        giaoDien: DanhMucLoaiDiSanComponent,
        duongDan: "/danh-muc-loai-di-san",
    },
    {
        ten: "Danh mục loại hình doanh nghiệp",
        giaoDien: DanhMucLoaiHinhDoanhNghiepComponent,
        duongDan: "/danh-muc-loai-hinh-doanh-nghiep",
    },
    {
        ten: "Danh mục trường hợp thu hồi",
        giaoDien: DanhMucTruongHopThuHoiComponent,
        duongDan: "/danh-muc-truong-hop-thu-hoi",
    },
];
export const ROUTE_PAGE_VHDL_PUBLIC: RoutePropsI[] = [
    {
        ten: "DI SẢN VĂN HÓA VẬT THỂ - Thống kê",
        duongDan: "/di-san-van-hoa-vat-the/thong-ke",
        giaoDien: <ThongKeDiSanVanHoaVatThe />,
    },
    {
        ten: "DI SẢN VĂN HÓA PHI VẬT THỂ - Thống kê",
        duongDan: "/di-san-van-hoa-phi-vat-the/thong-ke",
        giaoDien: <ThongKeDiSanVanHoaPhiVatThe />,
    }
];

export const ROUTE_DS_VHDL: RoutePropsI[] = [
    {
        ten: "DI SẢN VĂN HÓA PHI VẬT THỂ - Tra cứu",
        duongDan: "/di-san-van-hoa-phi-vat-the/tra-cuu",
        giaoDien: SearchDiSanVanHoaPhiVatThe,
    },
    {
        ten: "DI SẢN VĂN HÓA VẬT THỂ - Tra cứu",
        duongDan: "/di-san-van-hoa-vat-the/tra-cuu",
        giaoDien: SearchDiSanVanHoaVatThe,
    },
    {
        ten: "DI SẢN VĂN HÓA PHI VẬT THỂ - Báo cáo",
        duongDan: "/di-san-van-hoa-phi-vat-the/bao-cao",
        giaoDien: BaoCaoDiSanVanHoaPhiVatThe,
    },
    {
        ten: "TRA CỨU GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/di-san-van-hoa/tra-cuu-thong-tin-GCN",
        giaoDien: TraCuuThongTinGCN,
    },
    {
        ten: "BÁO CÁO DANH SÁCH GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHÊ TU BỔ DI TÍCH",
        duongDan: "/di-san-van-hoa/bao-cao",
        giaoDien: BaoCaoDanhSachGiayChungNhan,
    },
    {
        ten: "THỐNG KÊ TÌNH HÌNH CẤP GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/di-san-van-hoa/thong-ke-tinh-hinh-giay-chung-nhan",
        giaoDien: ThongKeGiayChungNhanDiSanVanHoa,
    },
    {
        ten: "DI SẢN VĂN HÓA VẬT THỂ - Báo cáo",
        duongDan: "/di-san-van-hoa-vat-the/bao-cao",
        giaoDien: BaoCaoDiSanVanHoaVatThe,
    },
    {
        ten: "DI SẢN VĂN HÓA PHI VẬT THỂ - Thống kê",
        duongDan: "/di-san-van-hoa-phi-vat-the/thong-ke",
        giaoDien: ThongKeDiSanVanHoaPhiVatThe,
    },
    {
        ten: "DI SẢN VĂN HÓA VẬT THỂ - Thống kê",
        duongDan: "/di-san-van-hoa-vat-the/thong-ke",
        giaoDien: ThongKeDiSanVanHoaVatThe,
    },
    {
        ten: "TRA CỨU CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/giay-chung-chi-di-san-van-hoa/tra-cuu",
        giaoDien: QuanLyChiSanVanHoa,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/giay-chung-chi-di-san-van-hoa/bao-cao",
        giaoDien: BaoCaoTinhHinhGiayChungChi,
    },
    {
        ten: "THỐNG KÊ TÌNH HÌNH CẤP CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH",
        duongDan: "/giay-chung-chi-di-san-van-hoa/thong-ke",
        giaoDien: ThongKeChungChiDiSanVanHoa,
    },
];