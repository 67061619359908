export interface FakeDTO {
    ten: string;
    ma: string;
}
export const SO_GIAY_CNĐKKD_HKD: FakeDTO[] = [
    {
        ten: 'Số Giấy CNĐKKD/HKD loại 1',
        ma: 'CNĐKKD1'
    },
    {
        ten: 'Số Giấy CNĐKKD/HKD loại 2',
        ma: 'CNĐKKD2'
    }
]
export const CO_QUAN: FakeDTO[] = [
    {
        ten: 'Chọn cơ quan',
        ma: ''
    },
    {
        ten: 'Sở Văn hóa, Thể thao và Du lịch',
        ma: '000.00.15.H13'
    }
]
export const TINH_TP: FakeDTO[] = [
    {
        ten: 'Chọn tỉnh thành',
        ma: ''
    },
    {
        ten: 'Thành phố Cần Thơ',
        ma: 'CT'
    },
    {
        ten: 'Hồ Chí Minh',
        ma: 'TPHCM'
    },
    {
        ten: 'Hà Nội',
        ma: 'HN'
    }

]
export const HUYEN: FakeDTO[] = [
    {
        ten: 'Quận Ninh Kiều',
        ma: 'NK'
    },
    {
        ten: 'Quận Cái Răng',
        ma: 'CR'
    },
    {
        ten: 'Quận Thốt Nốt',
        ma: 'TN'
    },
    {
        ten: 'Quận Ô Môn',
        ma: 'OM'
    }

]
export const PHUONG: FakeDTO[] = [
    {
        ten: 'Phường An Bình',
        ma: 'AB'
    },
    {
        ten: 'Phường An Cư',
        ma: 'AC'
    }

]
export const CAP_CONG_TRINH: FakeDTO[] = [
    {
        ten: 'Chọn cấp công trình ',
        ma: ''
    },
    {
        ten: 'Cấp I',
        ma: 'c1'
    },
    {
        ten: 'Cấp II',
        ma: 'c2'
    },
    {
        ten: 'Cấp III',
        ma: 'c3'
    },
    {
        ten: 'Cấp IV',
        ma: 'c4'
    }
]
export const DUONG = [
    {
        ten: 'Chọn đường ',
        ma: ''
    },
    {
        ten: 'Đường 1',
        ma: 'd1'
    },
    {
        ten: 'Đường 2',
        ma: 'd2'
    }
]
export const LOAI_CONG_TRINH = [
    {
        ten: 'Chọn loại công trình ',
        ma: ''
    },
    {
        ten: 'Nhà ở riêng lẻ',
        ma: 'd1'
    },
    {
        ten: 'Văn phòng',
        ma: 'd2'
    }
]
export const PHAN_LOAI_XAY_DUNG = [
    {
        ten: 'Chọn phân loại xây dựng',
        ma: ''
    },
    {
        ten: 'Xây dựng mới',
        ma: 'd1'
    },
    {
        ten: 'Sửa chữa',
        ma: 'd2'
    }
    ,
    {
        ten: 'Sửa chữa và xây mới',
        ma: 'd23'
    }
]
export const GIOI_TINH = [
    {
        ten: 'Nam',
        ma: 1
    },
    {
        ten: 'Nữ',
        ma: 2
    },
    {
        ten: 'Khác',
        ma: 3
    }
]
export const LOAI_GIAY_TO = [
    {
        ten: 'Chứng minh nhân dân',
        ma: 1
    },
    {
        ten: 'Căn cước công dân',
        ma: 2
    },
    {
        ten: 'Hộ chiếu',
        ma: 3
    }
]
export const NOI_CAP = [
    {
        ten: 'Hồ Chí Minh',
        ma: 'HCM'
    },
    {
        ten: 'Cần thơ',
        ma: 'CT'
    }
]
export const DAN_TOC = [
    {
        ten: 'Kinh',
        ma: 'KK'
    },
    {
        ten: 'Mường',
        ma: 'MM'
    },
    {
        ten: 'Thái',
        ma: 'TT'
    }

]
export const QUOC_TICH = [
    {
        ten: 'Việt Nam',
        ma: 'VN'
    },
    {
        ten: 'Mỹ',
        ma: 'US'
    },
    {
        ten: 'TRUNG QUỐC',
        ma: 'CN'
    }

]
export const LOAI_TAI_SAN = [
    {
        ten: 'Đồng Việt Nam',
        ma: '01'
    },
    {
        ten: 'Ngoại tệ tự do chuyển đổi',
        ma: '02'
    },
    {
        ten: 'Vàng',
        ma: '03'
    },
    {
        ten: 'Giá trị quyền sử dụng đất, giá trị quyền sở hữu trí tuệ, công nghệ, bí quyết kỹ thuật',
        ma: '04'
    },
    {
        ten: 'Tài sản khác',
        ma: '05'
    }
]

export const TINH_TRANG = [
    {
        ten: 'Đang hoạt động',
        ma: 'HD'
    },
    {
        ten: 'Ngưng hoạt động',
        ma: 'NG'
    },
    {
        ten: 'Thu hồi',
        ma: 'TH'
    },
    {
        ten: 'Hủy',
        ma: 'HH'
    },
    {
        ten: 'Tạm ngưng hoạt động',
        ma: 'TN'
    },
    {
        ten: 'Chấm dứt',
        ma: 'CD'
    }
]
export const DOANH_NGHIEP = [
    {
        ten: 'Công ty cổ phần',
        ma: 'CP'
    },
    {
        ten: 'Công ty trách nhiệm hữu hạn',
        ma: 'TN-HH'
    },
    {
        ten: 'Công ty một thành viên',
        ma: 'MTV'
    }

]
export const TH_THU_HOI = [
    {
        ten: 'Doanh nghiệp chấm dứt hoạt động kinh doanh lữ hành',
        ma: 'CD'
    },
    {
        ten: 'Doanh nghiệp giải thể',
        ma: 'GT'
    },
    {
        ten: 'Doanh nghiệp phá sản',
        ma: 'PS'
    }

]




