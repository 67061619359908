import { ThongKeGiayChungChi, TongThongKeGiayChungChi } from "../../model/GiayChungChiDSVH/TongThongKeGiayChungChi";

export const TongThongKeGiayChungChi_INIT: TongThongKeGiayChungChi = {
    tongCapMoi: 0,

    tongCapLai: 0,

    hoSoThuLyId: 0,

    tongThuHoi: 0,

    ngayCapChungChiTu: 0,

    ngayCapChungChiDen: 0,

    thongKeGiayChungChi: new Array<ThongKeGiayChungChi>(),
};
