import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useRef } from "react";
import { ThongKeGiayChungNhanReponse } from "../../../../model/ThongKeGiayChungNhan";
import { Cell, Row } from "../../../commons/table/tableCommons";

interface TableProps {
    dataList: ThongKeGiayChungNhanReponse;
    search: () => void;
    handleChiTiet: (field?: number, index?: number) => void;
}

const DanhSachGiayChungNhanTheoNam = (props: TableProps) => {
    const anchorRef = useRef<HTMLButtonElement>(null);
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="10%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Tháng
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Cấp mới
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Cấp lại
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Thu hồi
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataList?.thongKeGiayChungNhan?.length > 0 ? (
                            props.dataList?.thongKeGiayChungNhan.map((row, i) => (
                                <Row key={i}>
                                    <Cell align="center">{i + 1}</Cell>
                                    <Cell>{row.tenThang}</Cell>
                                    <Cell align="center"><Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(0, i)}>{row.capMoi}</Box></Cell>
                                    <Cell align="center"><Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(1, i)}>{row.capLai}</Box></Cell>
                                    <Cell align="center"><Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(2, i)}>{row.thuHoi}</Box></Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={5} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu{" "}
                                </Cell>
                            </Row>
                        )}
                        {props?.dataList && (
                            <Row>
                                <Cell scope="row" align="center"></Cell>
                                <Cell align="left" className="font-weight-bold">
                                    Tổng số
                                </Cell>
                                <Cell align="center" className="font-weight-bold text-center">
                                    {props?.dataList.tongCapMoi}
                                </Cell>
                                <Cell align="center" className="font-weight-bold">
                                    {props?.dataList.tongCapLai}
                                </Cell>
                                <Cell align="center" className="font-weight-bold">
                                    {props?.dataList.tongThuHoi}
                                </Cell>
                            </Row>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default DanhSachGiayChungNhanTheoNam;
