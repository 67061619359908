import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import moment from "moment";
import React, { useRef } from "react";
import { ThongKeGiayChungNhanReponse } from "../../../../model/ThongKeGiayChungNhan";
import { Cell, Row } from "../../../commons/table/tableCommons";

interface TableProps {
    dataList: ThongKeGiayChungNhanReponse;
    search: () => void;
    handleChiTiet: (field?: number, index?: number) => void;
}
const DanhSachGiayChungChiTheoThoiGian = (props: TableProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="10%">
                            STT
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Từ ngày
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Đến ngày
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Cấp mới
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Cấp lại
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="20%">
                            Thu hồi
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        <Row key={1}>
                            <Cell align="center">{1}</Cell>
                            <Cell>{props?.dataList?.ngayCapChungNhanTu != null ? moment(props?.dataList?.ngayCapChungNhanTu).format("DD/MM/YYYY") : ""}</Cell>
                            <Cell>{props?.dataList?.ngayCapChungNhanDen != null ? moment(props?.dataList?.ngayCapChungNhanDen).format("DD/MM/YYYY") : ""}</Cell>
                            <Cell align="center"><Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(0)}>{props?.dataList?.tongCapMoi}</Box></Cell>
                            <Cell align="center"><Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(1)}>{props?.dataList?.tongCapLai}</Box></Cell>
                            <Cell align="center"><Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(2)}>{props?.dataList?.tongThuHoi}</Box></Cell>
                        </Row>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default DanhSachGiayChungChiTheoThoiGian;
