import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import DialogMap from "./dialogMap";

export interface ChonToaDoComponentProps {
    handleChangeToaDo: (dataToaDo: any) => void;
    id: Number | string;
    diaChiDayDu: string;
    kinhDo: number;
    viDo: number;
}

export const ChonToaDoComponent = (props: ChonToaDoComponentProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    useEffect(() => {}, []);
    return (
        <>
            {openDialog && (
                <DialogMap
                    id={props.id}
                    handleCloseDialog={() => setOpenDialog(false)}
                    setData={props.handleChangeToaDo}
                    openDialog={openDialog}
                    diaChiDayDu={props.diaChiDayDu}
                    kinhDo={props.kinhDo}
                    viDo={props.viDo}
                />
            )}
            <Button variant="outlined" disableElevation onClick={() => setOpenDialog(true)} style={{ margin: "20px 5px" }}>
                Lấy tọa độ
            </Button>
        </>
    );
};
