import { Box, IconButton, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { DanhMucLoaiDiSan } from "../../../../model/DiSanVanHoa/DiSanVanHoaModel";
import { CustomInput } from "../../../commons/input/customInput";
import { Cell, CheckIconDeActive, Row } from "../../../commons/table/tableCommons";

interface IModalProps {
    openEditDialog: (itemSelected: DanhMucLoaiDiSan) => void;
    open: boolean;
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
interface IDataListProps {
    dataList: DanhMucLoaiDiSan[];
    deleteItem: (roleId: number) => void;
    modalEdit: IModalProps;
    pageProps: TablePaginationActionsProps;
}

export const DataListDanhMucLoaiDiSan = (props: IDataListProps) => {
    const [rows, setRows] = useState<DanhMucLoaiDiSan[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.rowsPerPage);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="left" width="55%">
                                Tên danh mục
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Hoạt động
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Thao tác
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => (
                            <Row key={row.id}>
                                <Cell scope="row" align="center">
                                    {i + 1}
                                </Cell>
                                <Cell align="left">{row.name}</Cell>
                                <Cell align="center">{Boolean(row.active) && <CheckIconDeActive />}</Cell>
                                <Cell align="center">
                                    <Box display="flex" justifyContent="center">
                                        <IconButton
                                            color="primary"
                                            aria-label="edit"
                                            size="small"
                                            onClick={() => {
                                                props.modalEdit.openEditDialog(row);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Box>
                                </Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination
                        count={totalPage}
                        defaultPage={1}
                        siblingCount={1}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        onChange={props.pageProps.onChangePage}
                    />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">
                            Số mẫu tin trên trang
                        </Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">
                        Mẫu tin {props.pageProps.page * rowsPerPage + 1} - {props.pageProps.page * rowsPerPage + rowsPerPage} / {props.pageProps.count}
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};
