import apis from "..";
import { GiayChungChiDiSanVanHoa } from "../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoa";
import { GiayChungChiDiSanVanHoaRequest } from "../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoaRequest";
import { BaseService } from "../baseService";

export class GiayChungChiDiSanVanHoaService extends BaseService {
    getByAutocompleteSoGiayChungNhan(SoChungChiDaCap: string, hoatDong: boolean, limit: number) {
        let body = {
            soGiayChungNhan: SoChungChiDaCap,
            hoatDong: hoatDong,
            limit: limit,
        };
        return apis.get("giay-chung-chi/di-san-van-hoa/get-autocomplete-by-so-giay-chung-nhan", {
            params: body,
        });
    }

    getBySoGiayChungChiDiSanVanHoa(giayChungChiDiSanVanHoaID: number) {
        let body = {
            giayChungChiDiSanVanHoaID: giayChungChiDiSanVanHoaID,
        };
        return apis.get("giay-chung-chi/di-san-van-hoa/get-giay-chung-chi-di-san-van-hoa", {
            params: body,
        });
    }

    getBySoGiayChungChiDiSanVanHoas(giayChungChiDiSanVanHoaID: number) {
        let body = {
            giayChungChiDiSanVanHoaID: giayChungChiDiSanVanHoaID,
        };
        return apis.get("giay-chung-chi/di-san-van-hoa/get-giay-chung-chi-di-san-van-hoa-by-id", {
            params: body,
        });
    }

    searchGiayChungChi = (requestBody: GiayChungChiDiSanVanHoaRequest) => {
        return apis.post("giay-chung-chi/di-san-van-hoa/tim-kiem", requestBody);
    };

    thuHoi = (giayChungChi: GiayChungChiDiSanVanHoa) => {
        return apis.post("giay-chung-chi/di-san-van-hoa/thu-hoi", giayChungChi, this.getTokenRequestHeaders());
    };

    downloadFileTraCuuChungChi = (request: GiayChungChiDiSanVanHoaRequest) => {
        return apis.get("giay-chung-chi/di-san-van-hoa/export-tra-cuu-giay-chung-chi", {
            responseType: "blob",
            params: request,
        });
    };

    downloadFileBaoCaoChungChi = (request: GiayChungChiDiSanVanHoaRequest) => {
        return apis.get("giay-chung-chi/di-san-van-hoa/export-bao-cao-giay-chung-chi", {
            responseType: "blob",
            params: request,
        });
    };
    downloadFileThongKeGiayChungChi = (request: GiayChungChiDiSanVanHoaRequest) => {
        return apis.get("thong-ke-giay-chung-chi/export-file-theo-nam", {
            responseType: "blob",
            params: request,
        });
    };
    baoCaoTinhHinhGiayChungChi = (requestBody: GiayChungChiDiSanVanHoaRequest) => {
        return apis.post("giay-chung-chi/di-san-van-hoa/bao-cao", requestBody);
    };

    thongKeGiayChungChiTheoNam = (requestBody: GiayChungChiDiSanVanHoaRequest) => {
        return apis.post("thong-ke-giay-chung-chi/theo-nam", requestBody);
    };

    downloadFileBieuMauGiayChungChi(request: any) {
        return apis.post("giay-chung-chi/di-san-van-hoa/download-file-giay-chung-chi", request, {
            responseType: "blob",
        });
    }

    chiTietThongKe = (field?: number, index?: number, showDate?: boolean, maHuyen?: string, ngayCapChungNhanTu?: number, ngayCapChungNhanDen?: number, namThongKe?: number) => {
        let body = {
            index: index,
            field: field,
            showDate: showDate,
            maHuyen: maHuyen,
            ngayCapChungNhanTu: ngayCapChungNhanTu,
            ngayCapChungNhanDen: ngayCapChungNhanDen,
            namThongKe: namThongKe,
        };
        return apis.post("thong-ke-giay-chung-chi/chi-tiet-thong-ke", {body});
    };
}
