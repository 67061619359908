export enum LoaiDoiTuong {
    NONE = "0",
    GIAY_CHUNG_NHAN = "1",
    GIAY_CHUNG_CHI = "2",
    PHI_VAT_THE = "3",
    VAT_THE = "4",
    LU_HANH = "5",
    CO_SO_AN_UONG = "6",
    CO_SO_GIAI_TRI = "7",
    CO_SO_THE_THAO = "8",
    CO_SO_MUA_SAM = "9",
    CO_SO_LUU_TRU = "10",
}