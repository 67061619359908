import { createStyles, Grid, InputLabel, makeStyles, Theme } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { GiayChungChiDiSanVanHoa_INIT } from "../../../../init/GiayChungChi/GiayChungChiDiSanVanHoa";
import { GiayChungChiDiSanVanHoa } from "../../../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoa";
import { FileEntry } from "../../../../apis/Common/fileEntry";

interface giayChungChiProps {
    data: GiayChungChiDiSanVanHoa;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabel = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const ChiTietThongTinChungTuBoDiTichComponent = (props: giayChungChiProps) => {
    const rightStyle = useStylesLabelRight();
    const leftStyle = useStylesLabelLeft();

    const [data, setData] = useState<GiayChungChiDiSanVanHoa>({
        ...GiayChungChiDiSanVanHoa_INIT,
        className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
    });
    const fileServerService = new FileServerService();
    const { onUpdateData } = props;

    useEffect(() => {
        initData();
    }, [props.data, props.submit]);

    const initData = () => {
        setData(props.data);
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số chứng chỉ</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.soChungChi}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayCapChungChi != null ? moment(data?.ngayCapChungChi).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tình trạng</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tenTrangThai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Lần cấp lại</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.lanCapLai > 0 ? data.lanCapLai : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Họ tên</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.hoTen}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày sinh</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngaySinh != null ? moment(data?.ngaySinh).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Nơi Sinh</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.noiSinh}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Địa chỉ thường trú</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.diaChiDayDu}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số CMND</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.soCMND}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayCapCMND != null ? moment(data?.ngayCapCMND).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Nơi cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.noiCapCMND}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Quốc tịch</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tenQuocTich}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Trình độ chuyên môn</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.trinhDoChuyenMon}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Kinh nghiệm chuyên môn</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.kinhNghiemChuyenMon}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel>Được phép hành nghề hoạt động</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.duocPhepHanhNghe}</InputLabel>
                </Grid>
            </Grid>
        </>
    );
};
export default ChiTietThongTinChungTuBoDiTichComponent;
