import { Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { Cell, Row } from "../../../commons/table/tableCommons";
import { ThongKeTinhHinhCSKDResponse } from "../../model/BaoCaoThongKeCSKDDTO";

interface IDataListProps {
    data: any;
    handleChiTiet: (maTinhTrang?: string, maHuyenString?: string) => void;
}

export const DanhSachThongKeCSKDTheThao = (props: IDataListProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [data, setData] = useState<ThongKeTinhHinhCSKDResponse>();
    useEffect(() => {
        setData(props.data);
        setRows(props.data?.thongKeTinhHinhCSKDDTOs);
    }, [props.data?.thongKeTinhHinhCSKDDTOs]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">
                            STT
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="35%">
                            Quận/ Huyện
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="12%">
                            Đang hoạt động
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.length > 0 ? (
                        rows.map((row, i) => (
                            <Row key={row?.id}>
                                <Cell scope="row" align="center">
                                    {i + 1}
                                </Cell>
                                <Cell align="left">{row.tenPhuongXa}</Cell>
                                <Cell align="center" className="text-center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("HD", row.tenPhuongXa)}>
                                        {row.total}
                                    </Box>
                                </Cell>
                            </Row>
                        ))
                    ) : (
                        <Row>
                            <Cell colSpan={7} scope="row" align="center">
                                {" "}
                                Không có dữ liệu
                            </Cell>
                        </Row>
                    )}
                    {rows?.length > 0 ? (
                        <Row>
                            <Cell scope="row" align="center"></Cell>
                            <Cell align="left" className="font-weight-bold">
                                Tổng số
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("HD", "ALL")}>
                                    {data?.totalTrangThai}
                                </Box>
                            </Cell>
                        </Row>
                    ) : (
                        ""
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
