import { Button, Card, CardContent, createStyles, Grid, InputLabel, makeStyles, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { PageRequest } from "apis/baseService";
import { DiaDiemDuLichService } from "apis/DiaDiemDuLich/DiaDiemDuLichService";
import { ThongKeDiaDiemDuLichRequest_INIT } from "init/DiaDiemDuLich/ThongKeDiaDiemDuLichRequest_INIT";
import { BieuDoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/BieuDoDiaDiemDuLichDTO";
import { ThongKeDiaDiemDuLichRequest } from "model/DiaDiemDuLich/ThongKeDiaDiemDuLichRequest";
import React, { Dispatch, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";

export interface RequestBieuDoThongKeDTO extends PageRequest {
    namThongKe: number;
    tenNamThongKe: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            "margin-top": " 22px !important;",
        },
    })
);

export const BieuDoKhuDuLichComponent = () => {
    const classes = useStyles();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<BieuDoDiaDiemDuLichDTO[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [search, setSeach] = useState<ThongKeDiaDiemDuLichRequest>({ ...ThongKeDiaDiemDuLichRequest_INIT });
    const service = new DiaDiemDuLichService();
    const [rows, setRows] = useState<BieuDoDiaDiemDuLichDTO[]>([]);

    const dataConHan = [...rows.map((item) => item.tongDangHoatDong)];
    const dataTamNgung = [...rows.map((item) => item.tongTamNgung)];
    const datachamDut = [...rows.map((item) => item.tongChamDut)];
    useEffect(() => {
        namThongKeList();
        loadDataTable(search);
    }, []);
    const loadDataTable = async (requestBody: ThongKeDiaDiemDuLichRequest) => {
        dispatchLoading(showLoading());
        await service
            .bieuDo(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setData(response.data.data);
                        setRows(response.data.data);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ ma: 0, ten: "Chọn năm" }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { ma: namHienTai - i, ten: "Năm " + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { ma: namHienTai + i, ten: "Năm " + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    };
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    };
    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };
    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Đang hoạt động",
                backgroundColor: "#51A3A3",
                data: dataConHan,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Tạm ngưng",
                backgroundColor: "#EAD94C",
                data: dataTamNgung,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Chấm dứt",
                backgroundColor: "#FFFFFF",
                data: datachamDut,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
           
        ],
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN KHU/ĐIỂM DU LỊCH</span>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item" hidden={search.kyThongKe == 2}>
                    <InputLabel classes={labelStyles}>Năm </InputLabel>
                    <Autocomplete
                        options={dataNamThongKe}
                        getOptionLabel={(option) => option?.ten}
                        value={{ ten: search.tenNamThongKe, ma: search.namThongKe }}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "namThongKe", "tenNamThongKe")}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-start">
                    <Button
                        onClick={(e) => loadDataTable(search)}
                        type="button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.button}
                    >
                        Tổng hợp
                    </Button>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <Card classes={classes}>
                        <CardContent>
                            <Bar data={dataBD} options={options} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <OverlayLoading />
        </div>
    );
};
