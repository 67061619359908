import { Grid, InputLabel } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { GiayPhepKinhDoanhLuHanhDTO } from "../../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
interface ChiTietNoiDungGiayPhepLHNDProps {
    data: GiayPhepKinhDoanhLuHanhDTO;
}
const ChiTietNoiDungGiayPhepLHNDComponent = (props: ChiTietNoiDungGiayPhepLHNDProps) => {
    const [data, setData] = useState({} as GiayPhepKinhDoanhLuHanhDTO);
    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    return (
        <>
            {
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Số GPKD dịch vụ lữ hành</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.soGiayPhep}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.ngayCap ? moment(data?.ngayCap).format("DD/MM/YYYY") : ""}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên Doanh nghiệp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.tenDoanhNghiep}</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item"></Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên giao dịch</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenDichVu}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên viết tắt</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenVietTat}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Điện thoại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.dienThoai}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Fax</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.fax}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Email</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.email}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Website</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.website}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Địa chỉ</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel>{data.diaChiDayDu}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Người đại diện theo pháp luật</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.nguoiDaiDienTheoPhapLuat}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Ngày sinh</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.ngaySinh ? moment(data?.ngaySinh).format("DD/MM/YYYY") : ""}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Giới tính</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.hoSoThuLyLuHanhDTO?.tenGioiTinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Dân tộc</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenDanToc}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Chức danh</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.chucDanh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Quốc tịch</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenQuocTich}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Số điện thoại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.soDienThoaiNDD}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Email</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.mailNDD}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Số CMND/ Hộ chiếu/ Căn cước</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.soGiayTo}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Ngày cấp CMND</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.ngaySinh ? moment(data?.ngayCapSoGiayTo).format("DD/MM/YYYY") : ""}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Nơi cấp CMND</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.hoSoThuLyLuHanhDTO?.noiCapSoGiayTo}</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item" container justify="flex-end"></Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Địa chỉ thường trú</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.diaChiThuongTru}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Chỗ ở hiện nay</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.choOHienNay}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên, địa chỉ chi nhánh</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.hoSoThuLyLuHanhDTO?.tenDiaChiChiNhanh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên, địa chỉ văn phòng đại diện</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.hoSoThuLyLuHanhDTO?.tenVanPhongDaiDien}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Số GCNĐKDN/ GCNĐKKD</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.soGCN}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.ngaySinh ? moment(data?.ngayCapGCN).format("DD/MM/YYYY") : ""}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Nơi cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.noiCapGCN}</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item" container justify="flex-end"></Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tài khoản ký quỹ số</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.taiKhoanKyQuySo}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tại ngân hàng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.nganHang}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tình trạng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenTrangThai}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Lần cấp lại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.soLanCapLai}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Lần thay đổi</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.soLanCapDoi}</InputLabel>
                    </Grid>
                </Grid>
            }
        </>
    );
};
export default ChiTietNoiDungGiayPhepLHNDComponent;
