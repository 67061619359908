import { Box, IconButton, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { DanhMucI } from "apis/Common/model/CommonModel";
import { CustomInput } from "../../../commons/input/customInput";
import { Cell, CheckIconDeActive, Row } from "../../../commons/table/tableCommons";

interface IModalProps {
    openEditDialog: (itemSelected: DanhMucI) => void;
    open: boolean;
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}
interface IDataListProps {
    dataList: DanhMucI[];
    deleteItem: (item: DanhMucI) => void;
    modalEdit: IModalProps;
    pageProps: TablePaginationActionsProps;
}

export const DataList = (props: IDataListProps) => {
    const [rows, setRows] = useState<DanhMucI[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.rowsPerPage);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Tên
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="15%">
                                Mã
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="8%">
                                Hoạt động
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="5%">
                                Thao tác
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows &&
                            rows.map((row, i) => (
                                <Row key={row.id}>
                                    <Cell scope="row" align="center">
                                        {(props.pageProps.page - 1) * rowsPerPage + i + 1}
                                    </Cell>
                                    <Cell align="left">{row.ten}</Cell>
                                    <Cell align="left">{row.ma}</Cell>
                                    <Cell align="center">{Boolean(row.hoatDong) && <CheckIconDeActive />}</Cell>
                                    <Cell align="center">
                                        <Box display="flex" justifyContent="center">
                                            <IconButton
                                                color="primary"
                                                aria-label="edit"
                                                size="small"
                                                onClick={() => {
                                                    props.modalEdit.openEditDialog(row);
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                aria-label="delete"
                                                size="small"
                                                onClick={() => {
                                                    props.deleteItem(row);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Cell>
                                </Row>
                            ))}
                    </TableBody>
                </Table>
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination
                        count={totalPage}
                        page={props.pageProps.page}
                        defaultPage={1}
                        siblingCount={1}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        onChange={props.pageProps.onChangePage}
                    />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">
                            Số mẫu tin trên trang
                        </Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">
                        Mẫu tin {(props.pageProps.page - 1) * rowsPerPage + 1} -{" "}
                        {props.pageProps.page * rowsPerPage > props.pageProps.count ? props.pageProps.count : props.pageProps.page * rowsPerPage} /{" "}
                        {props.pageProps.count}
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};
