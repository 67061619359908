import moment from "moment";

export default class StringUtil {
    static convertToNumber(str: string): string {
        return str.replace(/[^\d]/g, '');
    }
    static formatMoney(str: any): string {
        if (!StringUtil.isNullOrEmty(str)) {
            str = str.toString().replace(/\D/g, '');
            return `${str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        return "";
    }

    static isNullOrEmty(str: any): boolean {
        if (typeof str == 'undefined' || !str || str.length === 0 || str === "" || !/[^\s]/.test(str) || /^\s*$/.test(str) || str.toString().replace(/\s/g, "") === "")
            return true;
        else
            return false;
    }

    static dateSecond(): number {
        const myDate = new Date(new Date());
        myDate.setHours(23, 59, 59, 59);
        var seconds = myDate.getTime();
        return seconds;
    }

    static listIsNotEmty(arr: any): boolean {
        if (arr !== null && arr !== undefined && arr.length > 0) {
            return true;
        }
        return false;
    }

    static hasData(res: any): boolean {
        if (res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.data.id > 0) {
            return true;
        }
        return false;
    }

    static hasDataGiayPhep(res: any): boolean {
        if (res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.data.giayChungNhanId > 0) {
            return true;
        }
        return false;
    }

    static getCurrentYear(): number {
        return new Date().getFullYear();
    }

    static  stringDateToNumber(value:any): number{
        let result= 0;
        let date= null;
        if(value != null && !StringUtil.isNullOrEmty(value)){
            date = moment(value, 'DD/MM/YYYY').toDate();
            if(date){
                result=  date.getTime();
            }
        }
        return result;
    }

    static  replaceDots(value:string): string{
        let result= "";
        if(!StringUtil.isNullOrEmty(value)){
            result = value.replace(/\./g,"")
        }
        return result;
    }

    static  formatNgaySinh(value:any): number{
        let result= 0;
        let date= null;
        if(value != null && !StringUtil.isNullOrEmty(value)){
            date = moment(value, 'MM/DD/YYYY').toDate();
            if(date){
                result=  date.getTime();
            }
        }
        return result;
    }

    static getMaMau(maMauTrangThai : Number ) : string{
        if(maMauTrangThai === 2){
            return "yellow";
        }else if(maMauTrangThai === 1){
            return "red";
        }else{
            return "none";
        }

    }
}