import MomentUtils from "@date-io/moment";
import {
    Box,
    Checkbox,
    createStyles,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    InputBase,
    InputLabel,
    makeStyles,
    Snackbar,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../../apis";
import { createFileType, downLoadFile } from "../../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../../apis/Common/FileServerService";
import { DanhMucIView } from "../../../../../apis/Common/model/CommonModel";
import { DanhMucService } from "../../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../apis/danhMuc/model/DanhMucDTO";
import { HangCoSoLuuTruService } from "../../../../../apis/DichVuDuLich/HangCoSoLuuTruService";
import { HoSoThuLyDichVuDuLichService } from "../../../../../apis/DichVuDuLich/HoSoThuLyDichVuDuLichService";
import { LoaiHinhCoSoLuuTruService } from "../../../../../apis/DichVuDuLich/LoaiHinhCoSoLuuTruService";
import { HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import { ThongTinDungChungService } from "apis/hoSoCuaToi/thongTinDungChungService";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import { ACCEPT_FILES, LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from "../../../../../constants/DichVuDuLichConstants";
import { DUPLICATE } from "../../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDichVuDuLich_INIT } from "../../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLich } from "../../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import StringUtil from "../../../../../utils/stringUtils";
import { ThongTinChuyenNhanhUtil } from "../../../../../utils/thongTinChuyenNhanhUtil";
import { ConfirmationDialogRaw } from "../../../../commons/dialog/confirmDialog";
import DropZoneCustom from "../../../../commons/hoso/CustomDropzone/DropZoneCustom";
import HoSoThuLyButonComponent from "../../../../commons/hoso/HoSoDichVuDuLichButonComponent";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { ChonToaDoComponent } from "../../../../commons/map/ChonToaDoComponent";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import DanhSachDuThaoModal from "../../../AnUong/GiaoDienThuLy/thuly/danhSachDuThaoModal";
import ThuLyButtonComponent from "../../../AnUong/GiaoDienThuLy/thuly/ThuLyButtonComponent";
import { BuongCoSoComponent } from "../BuongCoSo/BuongCoSoComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CapMoiCoSoLuuTruComponent = () => {
    const classNameGCN = "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuLuuTru";
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = useState<HoSoThuLyDichVuDuLich>({ ...HoSoThuLyDichVuDuLich_INIT, classNameGCN: classNameGCN });
    const fileServerService = new FileServerService();
    const hoSoThuLyDichVuDuLichService = new HoSoThuLyDichVuDuLichService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const service = new HoSoThuLyDichVuDuLichService();

    const loaiHinhCoSoLuuTruService = new LoaiHinhCoSoLuuTruService();
    const hangCoSoLuuTruService = new HangCoSoLuuTruService();
    const [loaiHinhCoSos, setLoaiHinhCoSos] = useState<DanhMucIView[]>([]);
    const [hangCoSos, setHangCoSos] = useState<DanhMucIView[]>([]);
    const thongTinDungChungService = new ThongTinDungChungService();
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const [open, setOpen] = useState(false);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadPhuongs = (maQuan: string) => {
        danhMucService
            .getDanhMucDiaChi(maQuan)
            .then((res) => {
                setPhuongs(res);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };
    const loadLoaiHinhCoSos = () => {
        loaiHinhCoSoLuuTruService
            .getByHoatDong(true)
            .then((res) => {
                if (res && res.data && res.data.data) {
                    setLoaiHinhCoSos(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadHangCoSos = () => {
        hangCoSoLuuTruService
            .getByHoatDong(true)
            .then((res) => {
                if (res && res.data && res.data.data) {
                    setHangCoSos(res.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        initData();
        loadQuans("92");
        loadLoaiHinhCoSos();
        loadHangCoSos();
    }, []);

    const initData = async () => {
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;

        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        hoSoCuaToiMgtService.chiTiet(location.state?.maHoSo).then((p) => {
            if (p.data && p.data.data) {
                hoTenNguoiNop = p.data.data.nguoiNop;
                soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });

        dispatchLoading(showLoading());
        await hoSoThuLyDichVuDuLichService
            .getByMaHoSo(maHS, classNameGCN)
            .then(async(res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.data.hoSoThuLyId > 0) {
                    const dataNew = {
                        ...res?.data?.data,
                        maHoSo: maHS,
                        maThuTuc: maThuTuc,
                        classNameGCN: classNameGCN,
                    };
                    setData(dataNew);
                } else {
                    let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHS);
                    let maHuyen ="", tenHuyen = "", maPhuong = "", tenPhuong = "";
                    if(!StringUtil.isNullOrEmty(dataEform?.quanhuyen)){
                        let donViHanhChinh  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                        maHuyen = donViHanhChinh?.code;
                        tenHuyen = donViHanhChinh?.name;
                    }
                    if(!StringUtil.isNullOrEmty(dataEform?.xaphuong)){
                        let donViHanhChinhCap3  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                        maPhuong = donViHanhChinhCap3?.code;
                        tenPhuong = donViHanhChinhCap3?.name;
                    }
                    const dataNew = {
                        ...HoSoThuLyDichVuDuLich_INIT,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        maBienDong: LOAIBIENDONG_CAPMOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                        maHoSo: maHS,
                        maThuTuc: maThuTuc,
                        classNameGCN: classNameGCN,
                        soDienThoai : soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt,
                        fax : dataEform?.fax,
                        giaydkkd : dataEform?.giaydkkd,
                        noiCapGCN :  dataEform?.noicapdkkd ? dataEform?.noicapdkkd : HoSoThuLyDichVuDuLich_INIT.noiCapGCN,
                        ngayCapGCN : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : HoSoThuLyDichVuDuLich_INIT.ngayCapGCN,
                        email : dataEform?.email,
                        website : dataEform?.website,
                        coQuanCapGCN : dataEform?.coquancapdkkd,
                        maHuyen : !StringUtil.isNullOrEmty(maHuyen) ? maHuyen :  HoSoThuLyDichVuDuLich_INIT?.maHuyen,
                        tenHuyen : !StringUtil.isNullOrEmty(tenHuyen) ? tenHuyen : HoSoThuLyDichVuDuLich_INIT?.tenHuyen,
                        maPhuong : !StringUtil.isNullOrEmty(maPhuong) ? maPhuong :HoSoThuLyDichVuDuLich_INIT?.maPhuong,
                        tenPhuong : !StringUtil.isNullOrEmty(tenPhuong) ? tenPhuong : HoSoThuLyDichVuDuLich_INIT?.tenPhuong,
                        soGCN : dataEform?.giaydkkd,
                        tenCoSo: dataEform?.tendoanhnghiep,
                        tenDuong: dataEform?.diachi,
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleSubmit = async () => {
        setSubmit(true);
        if (data.donViCapPhep) {
            if (StringUtil.isNullOrEmty(data.soQuyetDinh)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.ngayCap)) {
                return;
            }
        }
        if (StringUtil.isNullOrEmty(data.soGCN)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenCoSo)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maLoaiHinhCoSo)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHangCoSo)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHuyen)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuong)) {
            return;
        }
        if (data.donViCapPhep) {
            if (StringUtil.isNullOrEmty(data.soQuyetDinh)) {
                return;
            }
            if (!(data.ngayCap != null && data.ngayCap != undefined && data.ngayCap && data.ngayCap != 0)) {
                return;
            }
            if (data.ngayHetHan != null && data.ngayHetHan != undefined && data.ngayHetHan && data.ngayHetHan != 0) {
                if (data.ngayCap > data.ngayHetHan) {
                    return;
                }
            }
        }
        dispatchLoading(showLoading());
        await hoSoThuLyDichVuDuLichService
            .insertOrUpdate(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response?.data?.data) {
                    setData({
                        ...data,
                        hoSoThuLyId: response.data.data.hoSoThuLyId,
                        giayChungNhanId: response?.data?.data?.giayChungNhanId,
                    });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else if (response.data.error_code === DUPLICATE) {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
                //lỗi
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: valueText,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleResetData = () => {
        const dataNew: HoSoThuLyDichVuDuLich = {
            ...HoSoThuLyDichVuDuLich_INIT,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            hoSoThuLyId: data.hoSoThuLyId,
            giayChungNhanId: data.giayChungNhanId,
            tenTrangThai: TINHTRANG_HOATDONG.ten,
            maTrangThai: TINHTRANG_HOATDONG.ma,
            maBienDong: LOAIBIENDONG_CAPMOI.ma,
            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
            classNameGCN: classNameGCN,
        };
        setData(dataNew);
        setSubmit(false);
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(data.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setAlert({
                showAlert: true,
                message: "Yêu cầu thực hiện thành công",
                severity: "success",
            });
        }
        setOpenDeleteUserDlg(false);
    };
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => { };
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);

            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };
    const onDownloadFileThuLy = (bieuMau: BieuMauAttachFileDTO) => {
        dispatchLoading(showLoading());
        service
            .downloadFileThuLy({ templateId: bieuMau.fileId, hoSoThuLyId: data.hoSoThuLyId })
            .then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), `${bieuMau.fileName}`);
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleChangeNumber = (e: any) => {
        let value = e.target.value;
        let name = e.target.name;
        const regExpNumber = new RegExp("^[0-9]*$");
        if (regExpNumber.test(value)) {
            let newData = {
                ...data,
                [name]: value,
            };
            setData(newData);
        }
    };
    const handleChangeNumberCoSoVatChat = (e: any) => {
        let name = e.target.name;
        let value = StringUtil.convertToNumber(e.target.value);
        const regExpNumber = new RegExp("^[0-9]*$");
        if (regExpNumber.test(value)) {
            let newData = {
                ...data,
                coSoVatChatLuuTruDTO: { ...data.coSoVatChatLuuTruDTO, [name]: value },
            };
            setData(newData);
        }
    };
    const handleChangeCoSoVatChat = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let newData = {
            ...data,
            coSoVatChatLuuTruDTO: { ...data.coSoVatChatLuuTruDTO, [e.target.name]: isCheckbox ? e.target.checked : e.target.value },
        };
        setData(newData);
    };
    const handleChangeNumberNhanSu = (e: any) => {
        let name = e.target.name;
        let value = StringUtil.convertToNumber(e.target.value);
        const regExpNumber = new RegExp("^[0-9]*$");
        if (regExpNumber.test(value)) {
            let newData = {
                ...data,
                nhanSuCoSoLuuTruDTO: { ...data.nhanSuCoSoLuuTruDTO, [name]: value },
            };
            setData(newData);
        }
    };
    const handleChangeNhanSu = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let newData = {
            ...data,
            nhanSuCoSoLuuTruDTO: { ...data.nhanSuCoSoLuuTruDTO, [e.target.name]: isCheckbox ? e.target.checked : e.target.value },
        };
        setData(newData);
    };

    const setDataParam = (name: string, value: any) => {
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value,
            };
            setData(dataNew);
        }
    };
    const handleChangeToaDo = (dataToaDo: any) => {
        let newData = {
            ...data,
            viDo: dataToaDo.currentViDo,
            kinhDo: dataToaDo.currentKinhDo,
            diaChiDayDu: dataToaDo.diaChiDayDu,
        };
        setData(newData);
    };
    const getDiaChiDayDu = (): string => {
        if (StringUtil.isNullOrEmty(data.diaChiDayDu)) {
            return data.tenPhuong + ", " + data.tenHuyen + ", " + data.tenTinh;
        } else {
            return data.diaChiDayDu;
        }
    };
    
    async function handleSearchDoanhNghiep() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinDoanhNghiep(data.soGCN)
        .then((res) => { 
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.maSoDoanhNghiep) {
                setData({ ...data,
                    ngayCapGCN : res?.data?.data?.data?.ngayThanhLap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.ngayThanhLap) : data?.ngayCapGCN,
                    tenCoSo : res?.data?.data?.data?.tenDoanhNghiep_VN,
                    soDienThoai : res?.data?.data?.data?.dienThoai ? StringUtil.replaceDots(res?.data?.data?.data?.dienThoai) : data.soDienThoai,
                    fax: res?.data?.data?.data?.fax,
                    email: res?.data?.data?.data?.email,
                    website: res?.data?.data?.data?.website,
                    soNha: res?.data?.data?.data?.soNha,
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số đăng ký doanh nghiệp không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <div className="dash-content">
                        <div className="dash-title">
                            <span> CÔNG NHẬN HẠNG CƠ SỞ LƯU TRÚ DU LỊCH - MÃ HỒ SƠ : {data.maHoSo}</span>
                        </div>
                    </div>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item">
                                    <FormControlLabel
                                        label={<span className="label-checkbox"> Đơn vị cấp số giấy phép</span>}
                                        control={
                                            <Checkbox checked={data.donViCapPhep} name="donViCapPhep" color="default" size="small" onChange={handleChange} />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                {data.donViCapPhep && (
                                    <>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel classes={labelStyles} required>
                                                Số quyết định
                                            </InputLabel>
                                            <TextField
                                                fullWidth
                                                name="soQuyetDinh"
                                                InputLabelProps={{ classes: labelStyles }}
                                                value={data.soQuyetDinh}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: inputStyles,
                                                }}
                                                onChange={handleChange}
                                                error={submit && StringUtil.isNullOrEmty(data.soQuyetDinh)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.soQuyetDinh) ? "Vui lòng nhập số quyết định" : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel classes={labelStyles}>Cơ quan cấp</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="coQuanCapGCN"
                                                value={data.coQuanCapGCN}
                                                InputLabelProps={{ classes: labelStyles }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: inputStyles,
                                                }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel classes={labelStyles} required>
                                                Ngày cấp
                                            </InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    value={data.ngayCap && data.ngayCap != 0 ? new Date(data.ngayCap) : null}
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                                    error={submit && StringUtil.isNullOrEmty(data.ngayCap)}
                                                    helperText={submit && StringUtil.isNullOrEmty(data.ngayCap) ? "Vui lòng nhập ngày cấp " : ""}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                    error={
                                                        submit &&
                                                        data.ngayHetHan != null &&
                                                        data.ngayHetHan != undefined &&
                                                        data.ngayHetHan != 0 &&
                                                        data.ngayCap > data.ngayHetHan
                                                    }
                                                    helperText={
                                                        submit &&
                                                            data.ngayHetHan != null &&
                                                            data.ngayHetHan != undefined &&
                                                            data.ngayHetHan != 0 &&
                                                            data.ngayCap > data.ngayHetHan
                                                            ? "Vui lòng nhập ngày hết hạn sau ngày cấp"
                                                            : ""
                                                    }
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <InputLabel>1. THÔNG TIN CHUNG VỀ CƠ SỞ LƯU TRÚ DU LỊCH</InputLabel>
                                </Grid>
                                <br />
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Số GCNĐKDN/HKD
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        style={{ width: "90%" }}
                                        name="soGCN"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soGCN}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.soGCN)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.soGCN) ? "Vui lòng nhập số GCNĐKDN" : ""}
                                    />
                                    <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "15PX", marginLeft: "3px" }}  onClick={handleSearchDoanhNghiep}/>
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Tên cơ sở lưu trú du lịch
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="tenCoSo"
                                        value={data.tenCoSo}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.tenCoSo)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.tenCoSo) ? "Vui lòng nhập Tên cơ sở" : ""}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapGCN && data.ngayCapGCN != 0 ? new Date(data.ngayCapGCN) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapGCN")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel required classes={labelStyles}>
                                        Loại hình cơ sở{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maLoaiHinhCoSo"
                                        options={loaiHinhCoSos}
                                        value={{ ma: data.maLoaiHinhCoSo ? data.maLoaiHinhCoSo : "", ten: data.tenLoaiHinhCoSo ? data.tenLoaiHinhCoSo : "" }}
                                        getOptionLabel={(option) => option?.ten}
                                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "maLoaiHinhCoSo", "tenLoaiHinhCoSo")}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" error={submit && StringUtil.isNullOrEmty(data.maLoaiHinhCoSo)} />
                                        )}
                                    />
                                    {submit && StringUtil.isNullOrEmty(data.maLoaiHinhCoSo) && (
                                        <FormHelperText className="-error">Vui lòng chọn loại hình cơ sở </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel required classes={labelStyles}>
                                        Loại/ Hạng{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maHangCoSo"
                                        options={hangCoSos}
                                        value={{ ma: data.maHangCoSo ? data.maHangCoSo : "", ten: data.tenHangCoSo ? data.tenHangCoSo : "" }}
                                        getOptionLabel={(option) => option?.ten}
                                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "maHangCoSo", "tenHangCoSo")}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" error={submit && StringUtil.isNullOrEmty(data.maHangCoSo)} />
                                        )}
                                    />
                                    {submit && StringUtil.isNullOrEmty(data.maHangCoSo) && (
                                        <FormHelperText className="-error">Vui lòng chọn loại hạng</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Huyện/Thị/TP
                                    </InputLabel>
                                    <Autocomplete
                                        id="maHuyen"
                                        options={quans}
                                        getOptionLabel={(option) => option?.name}
                                        value={{ code: data.maHuyen, name: data.tenHuyen }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn huyện/Thị/TP" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Phường/Xã/TT
                                    </InputLabel>
                                    <Autocomplete
                                        id="maPhuong"
                                        options={phuongs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maPhuong, name: data.tenPhuong }}
                                        onFocus={(e) => loadPhuongs(data.maHuyen)}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maPhuong) ? "Vui lòng chọn phường/Xã/TT" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel classes={labelStyles}>Đường </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="tenDuong"
                                        value={data.tenDuong}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <InputLabel classes={labelStyles}>Số nhà</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="soNha"
                                        value={data.soNha}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <ChonToaDoComponent
                                        id={classNameGCN}
                                        handleChangeToaDo={handleChangeToaDo}
                                        diaChiDayDu={getDiaChiDayDu()}
                                        kinhDo={data.kinhDo}
                                        viDo={data.viDo}
                                    />
                                </Grid>

                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Điện thoại</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="soDienThoai"
                                        value={data.soDienThoai}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChangeNumber}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Fax</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="fax"
                                        value={data.fax}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Email</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="email"
                                        value={data.email}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Website</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="website"
                                        value={data.website}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid xs={12} container>
                                    <Grid item xs={6} className="grid-item">
                                        <FormControlLabel
                                            label={<span className="label-checkbox"> Đủ điều kiện về an ninh, trật tự</span>}
                                            control={
                                                <Checkbox
                                                    checked={data.duDieuKienTranTu}
                                                    name="duDieuKienTranTu"
                                                    color="default"
                                                    size="small"
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item">
                                        <FormControlLabel
                                            label={<span className="label-checkbox"> Bảo vệ môi trường</span>}
                                            control={
                                                <Checkbox
                                                    checked={data.baoVeMoiTruong}
                                                    name="baoVeMoiTruong"
                                                    color="default"
                                                    size="small"
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item">
                                        <FormControlLabel
                                            label={<span className="label-checkbox"> Phòng cháy, chữa cháy</span>}
                                            control={
                                                <Checkbox
                                                    checked={data.phongChayChuaChay}
                                                    name="phongChayChuaChay"
                                                    color="default"
                                                    size="small"
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item">
                                        <FormControlLabel
                                            label={<span className="label-checkbox"> An toàn thực phẩm</span>}
                                            control={
                                                <Checkbox
                                                    checked={data.anToanThucPham}
                                                    name="anToanThucPham"
                                                    color="default"
                                                    size="small"
                                                    onChange={handleChange}
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>2. CƠ SỞ VẬT CHẤT KỸ THUẬT</InputLabel>
                                </Grid>
                                <br />
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Tổng vốn đầu tư ban đầu </InputLabel>
                                    <InputBase
                                        fullWidth
                                        value={StringUtil.formatMoney(data.coSoVatChatLuuTruDTO?.tongSoDauTuBanDau)}
                                        name="tongSoDauTuBanDau"
                                        onChange={handleChangeNumberCoSoVatChat}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Tổng vốn đầu tư nâng cấp </InputLabel>
                                    <InputBase
                                        fullWidth
                                        value={StringUtil.formatMoney(data.coSoVatChatLuuTruDTO?.tongSoDauTuNangCap)}
                                        name="tongSoDauTuNangCap"
                                        onChange={handleChangeNumberCoSoVatChat}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Tổng diện tích mặt bằng</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                        value={data.coSoVatChatLuuTruDTO?.tongDienTichMatBang}
                                        name="tongDienTichMatBang"
                                        onChange={handleChangeNumberCoSoVatChat}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Tổng diện tích mặt bằng xây dựng</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">m&#178;</InputAdornment>}
                                        value={data.coSoVatChatLuuTruDTO?.tongDienTichMatBangXayDung}
                                        name="tongDienTichMatBangXayDung"
                                        onChange={handleChangeNumberCoSoVatChat}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles}>Tổng số buồng</InputLabel>
                                    <BuongCoSoComponent
                                        onUpdateData={setDataParam}
                                        data={
                                            data.buongLuuTruDTOs && data.buongLuuTruDTOs != null && data.buongLuuTruDTOs != undefined
                                                ? data.buongLuuTruDTOs
                                                : []
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles}>Dịch vụ có trong cơ sở lưu trú du lịch </InputLabel>
                                    <InputBase
                                        fullWidth
                                        multiline
                                        rows="4"
                                        name="dichVuTrongCoSo"
                                        value={data.coSoVatChatLuuTruDTO?.dichVuTrongCoSo}
                                        onChange={handleChangeCoSoVatChat}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>3. NGƯỜI QUẢN LÝ VÀ NHÂN VIÊN PHỤC VỤ</InputLabel>
                                </Grid>
                                <br />
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Số người quản lý và nhân viên</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soQuanLyVaNhanVien > 0 ? data.nhanSuCoSoLuuTruDTO?.soQuanLyVaNhanVien : ''}
                                        name="soQuanLyVaNhanVien"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ban giám đốc</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soBanGiamDoc > 0 ? data.nhanSuCoSoLuuTruDTO?.soBanGiamDoc : ''}
                                        name="soBanGiamDoc"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Lễ tân</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soLeTan > 0 ? data.nhanSuCoSoLuuTruDTO?.soLeTan : ''}
                                        name="soLeTan"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Buồng</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soBuong > 0 ? data.nhanSuCoSoLuuTruDTO?.soBuong : ''}
                                        name="soBuong"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>

                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Bếp</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soBep > 0 ? data.nhanSuCoSoLuuTruDTO?.soBep : ''}
                                        name="soBep"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Bàn, bar</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soBan > 0 ? data.nhanSuCoSoLuuTruDTO?.soBan : ''}
                                        name="soBan"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Bộ phận khác</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soBoPhanKhac > 0 ? data.nhanSuCoSoLuuTruDTO?.soBoPhanKhac : ''}
                                        name="soBoPhanKhac"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" />

                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Trên đại học</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soTrenDaiHoc > 0 ? data.nhanSuCoSoLuuTruDTO?.soTrenDaiHoc : ''}
                                        name="soTrenDaiHoc"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Đại học</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soDaiHoc > 0 ? data.nhanSuCoSoLuuTruDTO?.soDaiHoc : ''}
                                        name="soDaiHoc"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Cao đẳng</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soCaoDang > 0 ? data.nhanSuCoSoLuuTruDTO?.soCaoDang : ''}
                                        name="soCaoDang"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Trung cấp</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soTrungCap > 0 ? data.nhanSuCoSoLuuTruDTO?.soTrungCap : ''}
                                        name="soTrungCap"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>

                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Sơ cấp</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soSoCap > 0 ? data.nhanSuCoSoLuuTruDTO?.soSoCap : ''}
                                        name="soSoCap"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>THPT</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soTHPT > 0 ? data.nhanSuCoSoLuuTruDTO?.soTHPT : ''}
                                        name="soTHPT"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Chứng chỉ khác</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.soChungChiKhac > 0 ? data.nhanSuCoSoLuuTruDTO?.soChungChiKhac : ''}
                                        name="soChungChiKhac"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item"></Grid>

                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Được đào tạo nghiệp vụ</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.daoTaoNghiepVu > 0 ? data.nhanSuCoSoLuuTruDTO?.daoTaoNghiepVu : ''}
                                        name="daoTaoNghiepVu"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Được đào tạo ngoại ngữ</InputLabel>
                                    <InputBase
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">Người</InputAdornment>}
                                        value={data.nhanSuCoSoLuuTruDTO?.daoTaoNgoaiNgu > 0 ? data.nhanSuCoSoLuuTruDTO?.daoTaoNgoaiNgu : ''}
                                        name="daoTaoNgoaiNgu"
                                        onChange={handleChangeNumberNhanSu}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item"></Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Người liên hệ</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="nguoiLienHe"
                                        value={data.nguoiLienHe}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Số điện thoại</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="dienThoaiNguoiLienHe"
                                        value={data.dienThoaiNguoiLienHe}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChangeNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dvdl"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                                {data.hoSoThuLyId <= 0 ?
                                    <HoSoThuLyButonComponent
                                        onSubmit={handleSubmit}
                                        onResetData={handleResetData}
                                        onOpenDialog={handleOpenDialog}
                                    />
                                    :
                                    <ThuLyButtonComponent
                                        showIn={data.hoSoThuLyId > 0}
                                        onSubmit={handleSubmit}
                                        onDownloadFileBieuMau={onDownloadFileThuLy}
                                        onDelete={handleResetData}
                                        handleChuyen={showHidePopperThaoTac}
                                        handleDuThao={showHidePopperDuThao}
                                        dataButton= {data}
                                    />
                                    // <HoSoThuLyButonComponent
                                    //     onDownloadFileThuLy={onDownloadFileThuLy}
                                    //     onSubmit={handleSubmit}
                                    //     onResetData={handleResetData}
                                    //     onOpenDialog={handleOpenDialog}
                                    // />
                                }
                            </Grid>
                        </TabPanel>
                    )}
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
            {showDanhSachDuThaoModal && (
               <DanhSachDuThaoModal hoso={data} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}
        </>
        </div>
    );
};
export default CapMoiCoSoLuuTruComponent;
