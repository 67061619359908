import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Cell, Row } from "../../../components/commons/table/tableCommons";
import { DanhMucCapDiSan } from "../../../model/danhMucCapDiSan";
import { ThongKeDSVHVatThe, ThongKeDSVHVatTheResponse } from "../../../model/diSanVanHoaVatThe";

interface IDataListProps {
    dataList: any;
    handleChiTiet: (index?: number, maHuyenString?: string) => void;
}

export const ListThongKeDiSanVanHoaVatThe = (props: IDataListProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [data, setData] = useState<ThongKeDSVHVatTheResponse>();
    useEffect(() => {
        setData(props.dataList);
        setRows(props.dataList?.thongKeDSVHDTOs);
    }, [props.dataList]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">
                            STT
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="15%">
                            Quận/Huyện
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Tổng số
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Cấp quốc gia đặc biệt
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Cấp thành phố
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Công trình văn hóa tưởng niệm
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Cấp quốc gia
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.length > 0 ? (
                        rows.map((row, i) => (
                            <Row>
                                <Cell scope="row" align="center">
                                    {i + 1}
                                </Cell>
                                <Cell align="center">{row.tenHuyen}</Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(0, row.tenHuyen)}>
                                        {row.tongSo}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(1, row.tenHuyen)}>
                                        {row.capQGDB}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(2, row.tenHuyen)}>
                                        {row.capTP}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(3, row.tenHuyen)}>
                                        {row.congTrinhVHTN}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(4, row.tenHuyen)}>
                                        {row.capQG}
                                    </Box>
                                </Cell>
                            </Row>
                        ))
                    ) : (
                        <Row>
                            <Cell colSpan={12} scope="row" align="center">
                                {" "}
                                Không có dữ liệu
                            </Cell>
                        </Row>
                    )}
                    {rows?.length > 0 ? (
                        <Row>
                            <Cell scope="row" align="center"></Cell>
                            <Cell align="center" className="font-weight-bold">
                                Tổng số
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalTongSo}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalCapQGDB}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalCapTP}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalCongTrinhVH}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalCapQG}
                            </Cell>
                        </Row>
                    ) : (
                        ""
                    )}
                </TableBody>
            </Table>
        </TableContainer >
    );
};
