import MomentUtils from "@date-io/moment";
import { Button, Grid, InputBase, InputLabel, Link, Snackbar, SvgIcon, TextField } from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { LIST_TINHTRANG, LOAIBIENDONG_THUHOI } from "../../../../constants/DiSanVanHoaConstants";
import { GiayChungChiDiSanVanHoaRequest_INIT } from "../../../../init/GiayChungChi/GiayChungChiDiSanVanHoaRequest";
import { GiayChungChiDiSanVanHoaRequest } from "../../../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoaRequest";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { ROUTE_CAP_MOI_GIAY_CHUNG_CHI_DSVH, ROUTE_GIAY_CHUNG_CHI_CHI_TIET, ROUTE_THU_HOI_GIAY_CHUNG_CHI_DSVH } from "../../../../routes/route_vhdl";
import { AlertMessage } from "../../../commons/alert";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import DanhSachChungChiDSVH from "./DanhSachChungChiDSVH";
import { GiayChungChiDiSanVanHoaService } from "../../../../apis/DiSanVanHoa/GiayChungChiDiSanVanHoaService";

const QuanLyChiSanVanHoa = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [search, setSeach] = useState({ ...GiayChungChiDiSanVanHoaRequest_INIT });
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const [totalData, setTotalData] = React.useState(0);
    const [dataList, setDataList] = useState<HoSoThuLyDiSanVanHoa[]>([]);
    const giayChungChiDiSanVanHoaService = new GiayChungChiDiSanVanHoaService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    let history = useHistory();

    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });

    useEffect(() => {
        loadDataTable(search);
    }, []);

    const handleChange = (e: any) => {
        setSeach({
            ...search,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let searchNew = { ...search, [filedName]: date?.toDate()?.getTime() };
        setSeach(searchNew);
    };

    /* Phân trang */
    const handleSearch = async () => {
        let searchNew = { ...search, pageNumber: 1 };
        await setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const reload = async() => {
        let searchNew = { ...GiayChungChiDiSanVanHoaRequest_INIT, pageNumber: 1 };
        await setSeach(searchNew);
        loadDataTable(searchNew);
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageSize: Number(event.target.value), pageNumber: 1 };
        setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const loadDataTable = async (requestBody: GiayChungChiDiSanVanHoaRequest) => {
        if (requestBody.ngayCapChungChiTu > 0 && requestBody.ngayCapChungChiDen > 0 && requestBody.ngayCapChungChiDen < requestBody.ngayCapChungChiTu) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp từ không được lớn hơn ngày cấp đến",
                severity: "warning",
            });
            return;
        }
        if (requestBody.ngayHetHanTu > 0 && requestBody.ngayHetHanDen > 0 && requestBody.ngayHetHanDen < requestBody.ngayHetHanTu) {
            setAlert({
                showAlert: true,
                message: "Ngày hết hạn từ không được lớn hơn ngày hết hạn đến",
                severity: "warning",
            });
            return;
        }
        dispatchLoading(showLoading());
        await giayChungChiDiSanVanHoaService
            .searchGiayChungChi(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                        setDataList(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const downloadFile = async (extension: string) => {
        let request = {
            ...search,
            exportType: extension,
            exportFileName: "tra_cuu_chung_chi_hanh_nghe_tu_bo_di_tich",
        };
        dispatchLoading(showLoading());
        await giayChungChiDiSanVanHoaService
            .downloadFileTraCuuChungChi(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "danh_sach_giay_phep_cpxd");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const handleNhapDauKy = () => {
        history.push(ROUTE_CAP_MOI_GIAY_CHUNG_CHI_DSVH.duongDan, { isNhapDauKy: true });
    };

    const handleThuHoi = (id: number, loai: string, maBienDong: string) => {
        if (loai === "ThuHoi") {
            history.push(ROUTE_THU_HOI_GIAY_CHUNG_CHI_DSVH.duongDan, { giayChungChiId: id, isUpdate: false });
        } else if (loai == "ChiTiet") {
            history.push(ROUTE_GIAY_CHUNG_CHI_CHI_TIET.duongDan, { giayChungChiId: id, isUpdate: false, isNhapDauKy: true, showChuyen: false });
        } else {
            /* Update */
            if (maBienDong === LOAIBIENDONG_THUHOI.ma) {
                history.push(ROUTE_THU_HOI_GIAY_CHUNG_CHI_DSVH.duongDan, { giayChungChiId: id, isUpdate: true });
            } else {
                history.push(ROUTE_CAP_MOI_GIAY_CHUNG_CHI_DSVH.duongDan, { giayChungChiId: id, isUpdate: true });
            }
        }
    };
    return (
        <>
            {
                <div className="dash-content">
                    <div className="dash-title">
                        <span>TRA CỨU CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH</span>
                    </div>
                    <Grid container>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Số chứng chỉ</InputLabel>
                            <InputBase fullWidth name="soChungChi" value={search.soChungChi} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Tình trạng</InputLabel>
                            <Autocomplete
                                options={LIST_TINHTRANG}
                                getOptionLabel={(option: any) => option.ten}
                                value={{
                                    ma: search.maTrangThai,
                                    ten: search.tenTrangThai
                                }}
                                onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "maTrangThai", "tenTrangThai")}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày cấp từ</InputLabel>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={
                                        search.ngayCapChungChiTu !== 0 &&
                                        search.ngayCapChungChiTu &&
                                        search.ngayCapChungChiTu !== null &&
                                        search.ngayCapChungChiTu !== undefined
                                            ? new Date(search.ngayCapChungChiTu)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungChiTu")}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày cấp đến</InputLabel>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={
                                        search.ngayCapChungChiDen !== 0 &&
                                        search.ngayCapChungChiDen &&
                                        search.ngayCapChungChiDen !== null &&
                                        search.ngayCapChungChiDen !== undefined
                                            ? new Date(search.ngayCapChungChiDen)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungChiDen")}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={6} className="grid-item">
                            <InputLabel classes={labelStyles}>Họ tên</InputLabel>
                            <InputBase fullWidth name="hoTen" value={search.hoTen} onChange={handleChange} />
                        </Grid>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày hết hạn từ</InputLabel>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={
                                        search.ngayHetHanTu !== 0 && search.ngayHetHanTu && search.ngayHetHanTu !== null && search.ngayHetHanTu !== undefined
                                            ? new Date(search.ngayHetHanTu)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHanTu")}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày hết hạn Đến</InputLabel>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={
                                        search.ngayHetHanDen !== 0 &&
                                        search.ngayHetHanDen &&
                                        search.ngayHetHanDen !== null &&
                                        search.ngayHetHanDen !== undefined
                                            ? new Date(search.ngayHetHanDen)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHanDen")}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} className="button-group-center">
                            <Button onClick={handleSearch} type="button" variant="contained" color="primary" className="align-right" disableElevation>
                                Tìm kiếm
                            </Button>
                            <Button onClick={handleNhapDauKy} type="button" variant="contained" color="primary" className="align-right" disableElevation>
                                Nhập đầu kỳ
                            </Button>
                            <Button onClick={reload} type="button" variant="contained" color="primary" className="align-right" disableElevation>
                                Làm mới
                            </Button>
                        </Grid>
                        <Grid item xs={12} className="grid-item" container justify="flex-end">
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("docx");
                                }}
                            >
                                <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                        fill="#007bff"
                                        d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                                    />
                                </SvgIcon>
                            </Link>
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("pdf");
                                }}
                            >
                                <SvgIcon fontSize="large">
                                    <path
                                        fill="#dc3545"
                                        d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                                    />
                                </SvgIcon>
                            </Link>
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("xlsx");
                                }}
                            >
                                <SvgIcon fontSize="large">
                                    <path
                                        fill="#28a745"
                                        d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                                    />
                                </SvgIcon>
                            </Link>
                        </Grid>
                    </Grid>
                    <DanhSachChungChiDSVH
                        search={handleSearch}
                        count={totalData}
                        page={search.pageNumber}
                        rowsPerPage={search.pageSize}
                        onChangePage={handleOnChangePage}
                        onChangeRowsPerPage={handOnChangeRowsPerPage}
                        dataList={dataList}
                        onHandleThuHoi={handleThuHoi}
                    />
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                </div>
            }
        </>
    );
};
export default QuanLyChiSanVanHoa;
