import AXIOS_INSTANCE from "..";
import { HoSoThuLyDSKinhDoanhTheThaoRequest } from "../../components/HoSoThuLyDichVuDuLich/model/HoSoThuLyDSKinhDoanhTheThaoRequest";
import { HoSoThuLyDichVuDuLich } from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { BaseService } from "../baseService";

export class HoSoThuLyDichVuDuLichService extends BaseService {
    getByMaHoSo = (maHS: string, classNameGCN: string) => {
        return AXIOS_INSTANCE.post("thu-ly/dich-vu-du-lich/get-by-ma-ho-so", { maHoSo: maHS, classNameGCN: classNameGCN }, this.getTokenRequestHeaders());
    };
    getByHoSoThuLyId = (hoSoThuLyId: number) => {
        return AXIOS_INSTANCE.post("thu-ly/dich-vu-du-lich/get-by-ho-so-thu-ly-id", { hoSoThuLyId: hoSoThuLyId }, this.getTokenRequestHeaders());
    };
    insertOrUpdate = (hoSo: HoSoThuLyDichVuDuLich) => {
        return AXIOS_INSTANCE.post("thu-ly/dich-vu-du-lich/save-or-update", hoSo, this.getTokenRequestHeaders());
    };
    downloadFileThuLy(requestBody: any) {
        return AXIOS_INSTANCE.post("thu-ly/dich-vu-du-lich/download-file-thu-ly", requestBody, {
            responseType: "blob",
        });
    }

    baoCaoDSKDTheThao = (requestBody: HoSoThuLyDSKinhDoanhTheThaoRequest) => {
        return AXIOS_INSTANCE.post("thu-ly/dich-vu-du-lich/bao-cao-ds-kd-the-thao", requestBody);
    };

    baoCaoDSKDVuiChoiGiaiTri = (requestBody: HoSoThuLyDSKinhDoanhTheThaoRequest) => {
        return AXIOS_INSTANCE.post("thu-ly/dich-vu-du-lich/bao-cao-ds-kd-vui-choi-giai-tri", requestBody);
    };

    downloadFileBaoCaoDSKD = (request: HoSoThuLyDSKinhDoanhTheThaoRequest) => {
        return AXIOS_INSTANCE.get("thu-ly/dich-vu-du-lich/export-file-bao-cao-ds-kd", {
            responseType: "blob",
            params: request,
        });
    };
    getById(id: number, classNameGCN: string) {
        return AXIOS_INSTANCE.post("thu-ly/dich-vu-du-lich/get-by-id", {
            id: id,
            classNameGCN: classNameGCN
        });
    }
}
