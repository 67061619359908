import { Grid, InputLabel } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { HoSoThuLyLuHanhNoiDia } from "../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import DateUtil from "../../../../utils/dateUtils";

interface NoiDungCuProps {
    data: HoSoThuLyLuHanhNoiDia;
}
const NoiDungCuComponent = (props: NoiDungCuProps) => {
    const [data, setData] = useState({} as HoSoThuLyLuHanhNoiDia);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    return (
        <>
            {
                <Grid container spacing={2}>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Số GPKD dịch vụ lữ hành</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.giayPhepCu?.soGiayPhep}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.giayPhepCu?.ngayCap ? moment(data?.giayPhepCu?.ngayCap).format("DD/MM/YYYY") : ""}</InputLabel>
                    </Grid>

                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tình trạng</InputLabel>
                    </Grid>
                    <Grid item xs={1} className="grid-item">
                        <InputLabel>{data?.giayPhepCu?.tenTrangThai}</InputLabel>
                    </Grid>

                    <Grid item xs={1} className="grid-item" container justify="flex-end">
                        <InputLabel>Lần cấp đổi</InputLabel>
                    </Grid>
                    <Grid item xs={1} className="grid-item">
                        <InputLabel>{data?.giayPhepCu?.soLanCapDoi}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Lần cấp lại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.giayPhepCu?.soLanCapLai}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên doanh nghiệp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.giayPhepCu?.tenDoanhNghiep}</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item" container justify="flex-end"></Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên giao dịch</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data?.giayPhepCu?.tenDichVu}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên viết tắt</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenVietTat}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Điện thoại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.dienThoai}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Fax</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.fax}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Email</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.email}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Website</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.website}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Địa chỉ</InputLabel>
                    </Grid>
                    <Grid item xs={9} className="grid-item">
                        <InputLabel>
                            {data.soNha} {data.tenDuong} {data.tenPhuong} {data.tenHuyen}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Người đại diện theo pháp luật</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.nguoiDaiDienTheoPhapLuat}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Ngày sinh</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.ngaySinh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Giới tính</InputLabel>
                    </Grid>
                    <Grid item xs={1} className="grid-item">
                        <InputLabel>{data.tenGioiTinh}</InputLabel>
                    </Grid>
                    <Grid item xs={1} className="grid-item" container justify="flex-end">
                        <InputLabel>Quốc tịch</InputLabel>
                    </Grid>
                    <Grid item xs={1} className="grid-item">
                        <InputLabel>{data.tenQuocTich}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Dân tộc</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenDanToc}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Chức danh</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.chucDanh}</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item" container justify="flex-end"></Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Số điện thoại</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.soDienThoaiNDD}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Email</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.mailNDD}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Số CMND/ Hộ chiếu/ Căn cước</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.soGiayTo}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Ngày cấp CMND</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{DateUtil.timeToDDMMYYYY(data.ngayCapSoGiayTo)}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Nơi cấp CMND</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.noiCapSoGiayTo}</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item" container justify="flex-end"></Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Địa chỉ thường trú</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.diaChiThuongTru}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Chỗ ở hiện nay</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.choOHienNay}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên, địa chỉ chi nhánh</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenDiaChiChiNhanh}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tên, địa chỉ văn phòng đại diện</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.tenVanPhongDaiDien}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Số GCNĐKDN/ GCNĐKKD</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.soGCN}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Ngày cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.ngayCapGCN}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Nơi cấp</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.noiCapGCN}</InputLabel>
                    </Grid>
                    <Grid item xs={6} className="grid-item" container justify="flex-end"></Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tài khoản ký quỹ số</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.taiKhoanKyQuySo}</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                        <InputLabel>Tại ngân hàng</InputLabel>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel>{data.nganHang}</InputLabel>
                    </Grid>
                </Grid>
            }
        </>
    );
};
export default NoiDungCuComponent;
