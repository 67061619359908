import MomentUtils from "@date-io/moment";
import { Button, Grid, InputBase, InputLabel, Link, Snackbar, SvgIcon, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createFileType, downLoadFile } from "../../../apis/Common/exportUtil";
import { DanhMucService } from "../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO, DanhMucDungChungDTO } from "../../../apis/danhMuc/model/DanhMucDTO";
import { LuHanhNoiDiaService } from "../../../apis/LuHanhNoiDia/LuHanhNoiDiaService";
import { LIST_TINHTRANG } from "../../../constants/LuHanhNoiDiaConstants";
import { GiayPhepLuHanhNoiDiaRequest_INIT, RequestDTO_INIT } from "../../../init/LuHanhNoiDia/GiayPhepLuHanhNoiDia_INIT";
import { GiayPhepLuHanhNoiDiaRequest, HoSoThuLyLuHanhNoiDia, RequestDTO } from "../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { ROUTE_GIAY_PHEP_LHND_CAP_MOI, ROUTE_GIAY_PHEP_LHND_CHI_TIET, ROUTE_GIAY_PHEP_LHND_TAM_NGUNG } from "../../../routes/route_luhanh";
import { AlertMessage, CustomAlert } from "../../commons/alert";
import { bootstrapTextFieldHook } from "../../commons/input/bootstrap";
import { OverlayLoading } from "../../overlayLoading/overlayLoading";
import DanhSachGiayPhepLHNDComponent from "./DanhSachGiayPhepLHNDComponent";

const QuanLyGiayPhepLHNDComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [search, setSeach] = useState({ ...GiayPhepLuHanhNoiDiaRequest_INIT });
    const [searchRequest, setSearchRequest] = useState<RequestDTO>({ ...RequestDTO_INIT });
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const [totalData, setTotalData] = React.useState(0);
    const [dataList, setDataList] = useState<HoSoThuLyLuHanhNoiDia[]>([]);
    const luHanhNoiDiaService = new LuHanhNoiDiaService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    let history = useHistory();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const [danTocs, setDanTocs] = useState<DanhMucDungChungDTO[]>([]);
    const [quocGias, setQuocGias] = useState<DanhMucDungChungDTO[]>([]);
    useEffect(() => {
        loadDataTable(search);
        loadQuans("92");
    }, []);

    const handleChange = (e: any) => {
        setSeach({
            ...search,
            [e.target.name]: e.target.value,
        });
        setSearchRequest({
            ...searchRequest,
            [e.target.name]: e.target.value,
        });
    };

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
        let searchNew1 = { ...searchRequest, [nameValue]: value?.ma, [nameText]: valueText };
        setSearchRequest(searchNew1);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let searchNew = { ...search, [filedName]: date?.toDate()?.getTime() };
        setSeach(searchNew);
        let searchNew1 = { ...searchRequest, [filedName]: date?.toDate()?.getTime() };
        setSearchRequest(searchNew1);
    };

    /* Phân trang */
    const handleSearch = async () => {
        let searchNew = { ...search, pageNumber: 1 };
        await setSeach(searchNew);
        loadDataTable(searchNew);
    };

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSeach(searchNew);
        let searchNew1 = { ...searchRequest, pageNumber: value };
        setSearchRequest(searchNew1);
        loadDataTable(searchNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageSize: Number(event.target.value), pageNumber: 1 };
        setSeach(searchNew);
        let searchNew1 = { ...searchRequest, pageSize: Number(event.target.value), pageNumber: 1 };
        setSearchRequest(searchNew1);
        loadDataTable(searchNew);
    };
    const loadDataTable = async (requestBody: GiayPhepLuHanhNoiDiaRequest) => {
        dispatchLoading(showLoading());
        await luHanhNoiDiaService
            .searchGiayChungChi(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                        setDataList(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const downloadFile = async (extension: string) => {
        let request = {
            ...searchRequest,
            exportType: extension,
            exportFileName: "danh_sach_giay_phep_lhnd",
        };
        dispatchLoading(showLoading());
        await luHanhNoiDiaService
            .downloadFileTraCuu(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "danh_sach_giay_phep_lhnd");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const handleNhapDauKy = () => {
        history.push(ROUTE_GIAY_PHEP_LHND_CAP_MOI.duongDan, { maHoSo: "" });
    };

    const handleThaoTac = (id: number, loai: string) => {
        if (loai == "ChiTiet") {
            history.push(ROUTE_GIAY_PHEP_LHND_CHI_TIET.duongDan, { giayPhepId: id, isUpdate: false });
        } else if (loai == "TamNgung") {
            history.push(ROUTE_GIAY_PHEP_LHND_TAM_NGUNG.duongDan, { giayPhepId: id, isUpdate: false });
        } else {
            history.push(ROUTE_GIAY_PHEP_LHND_CAP_MOI.duongDan, { giayPhepId: id, isUpdate: false });
        }
    };
    const reload = async () => {
        let searchNew = { ...GiayPhepLuHanhNoiDiaRequest_INIT, pageNumber: 1 };
        await setSeach(searchNew);
        let searchNew1 = { ...RequestDTO_INIT, pageNumber: 1 };
        await setSearchRequest(searchNew1);
        loadDataTable(searchNew);
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSeach({
            ...search,
            [nameValue]: value?.code,
            [nameText]: valueText,
            maPhuong: "",
            tenPhuong: "",
        });
        setSearchRequest({
            ...searchRequest,
            [nameValue]: value?.code,
            [nameText]: valueText,
            maPhuong: "",
            tenPhuong: "",
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSeach({ ...search, [nameValue]: value?.code, [nameText]: valueText });
        setSearchRequest({ ...searchRequest, [nameValue]: value?.code, [nameText]: valueText });
    };

    const loadPhuongs = () => {
        if(search?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(search?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            {
                <div className="dash-content">
                    <div className="dash-title">
                        <span>DANH SÁCH GIẤY PHÉP KINH DOANH DỊCH VỤ LỮ HÀNH NỘI ĐỊA</span>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Số giấy phép</InputLabel>
                            <InputBase fullWidth name="soGiayPhep" placeholder="Nhập số giấy phép" value={search.soGiayPhep} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6} className="grid-item">
                            <InputLabel classes={labelStyles}>Tên doanh nghiệp</InputLabel>
                            <InputBase
                                fullWidth
                                name="tenDoanhNghiep"
                                placeholder="Nhập tên doanh nghiệp"
                                value={search.tenDoanhNghiep}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Tình trạng</InputLabel>
                            <Autocomplete
                                options={LIST_TINHTRANG}
                                getOptionLabel={(option: any) => option.ten}
                                value={{ ma: search.maTrangThai, ten: search.tenTrangThai }}
                                onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "maTrangThai", "tenTrangThai")}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Huyện/Thị/TP</InputLabel>
                            <Autocomplete
                                id="maHuyen"
                               options={quans}
                                getOptionLabel={(option: any) => option.name}
                                value={{ code: search.maHuyen, name: search.tenHuyen }}
                                onChange={(event: any, newValue: any) => handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen")}
                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Chọn Huyện/Thị/TP" />}
                            />
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={labelStyles}>Phường/Xã/TT</InputLabel>
                            <Autocomplete
                                id="maPhuong"
                                options={phuongs}
                                onFocus ={loadPhuongs}
                                noOptionsText={"Không có Phường/Xã/TT"}
                                getOptionLabel={(option: any) => option.name}
                                value={{ code: search.maPhuong, name: search.tenPhuong }}
                                onChange={(event: any, newValue: any) => handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong")}
                                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Chọn Phường/Xã/TT" />}
                            />
                        </Grid>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày cấp từ</InputLabel>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={
                                        search.ngayCapPhepTu !== 0 &&
                                            search.ngayCapPhepTu &&
                                            search.ngayCapPhepTu !== null &&
                                            search.ngayCapPhepTu !== undefined
                                            ? new Date(search.ngayCapPhepTu)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapPhepTu")}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày cấp đến</InputLabel>
                                <KeyboardDatePicker
                                    autoOk={true}
                                    showTodayButton={true}
                                    value={
                                        search.ngayCapPhepDen !== 0 &&
                                            search.ngayCapPhepDen &&
                                            search.ngayCapPhepDen !== null &&
                                            search.ngayCapPhepDen !== undefined
                                            ? new Date(search.ngayCapPhepDen)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                    variant="inline"
                                    InputProps={{ disableUnderline: true }}
                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapPhepDen")}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} className="button-group-center">
                            <Button onClick={handleSearch} type="button" variant="contained" color="primary" className="align-right" disableElevation>
                                Tìm kiếm
                            </Button>
                            <Button onClick={handleNhapDauKy} type="button" variant="contained" color="primary" className="align-right" disableElevation>
                                Nhập đầu kỳ
                            </Button>
                            <Button variant="contained" color="primary" disableElevation onClick={reload}>
                                Làm mới
                            </Button>
                        </Grid>
                        
                        <Grid item xs={12} className="grid-item" container justify="flex-end">
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("docx");
                                }}
                            >
                                <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                        fill="#007bff"
                                        d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                                    />
                                </SvgIcon>
                            </Link>
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("pdf");
                                }}
                            >
                                <SvgIcon fontSize="large">
                                    <path
                                        fill="#dc3545"
                                        d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                                    />
                                </SvgIcon>
                            </Link>
                            <Link
                                title="Tải xuống"
                                href="#"
                                onClick={() => {
                                    downloadFile("xlsx");
                                }}
                            >
                                <SvgIcon fontSize="large">
                                    <path
                                        fill="#28a745"
                                        d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                                    />
                                </SvgIcon>
                            </Link>
                        </Grid>
                    </Grid>
                    <DanhSachGiayPhepLHNDComponent
                        search={handleSearch}
                        count={totalData}
                        page={search.pageNumber}
                        rowsPerPage={search.pageSize}
                        onChangePage={handleOnChangePage}
                        onChangeRowsPerPage={handOnChangeRowsPerPage}
                        dataList={dataList}
                        onHandleThaoTac={handleThaoTac}
                    />
                    <OverlayLoading />
                    <Snackbar
                        open={alertMessage.open}
                        onClose={handleCloseAlertMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                            {alertMessage.content}
                        </CustomAlert>
                    </Snackbar>
                </div>
            }
        </>
    );
};
export default QuanLyGiayPhepLHNDComponent;
