import { GiayPhepDiaDiemDuLichDTO } from "model/DiaDiemDuLich/GiayPhepDiaDiemDuLichDTO";

export const GiayPhepDiaDiemDuLichDTO_INIT: GiayPhepDiaDiemDuLichDTO = {
    id : 0,    
    soQuyetDinh: "",
    
    ngayCapVanBan:  0,
    
    ngayHetHan: 0,
    
    maTinhTrang:  "",

    tenTinhTrang:  "",
   
    ngayTao:  0,
    
    ngayCapNhat:  0,
};
