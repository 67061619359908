import MomentUtils from "@date-io/moment";
import { Box, Button, createStyles, Grid, InputLabel, makeStyles, Snackbar, Table, TableHead, TextField, Theme, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { Alert, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../apis";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { GiayChungNhanDiSanVanHoaService } from "../../../../apis/DiSanVanHoa/GiayChungNhanDiSanVanHoaService";
import { ACCEPT_FILES, LOAIBIENDONG_CAPLAI, LOAIBIENDONG_CAPMOI, LOAIBIENDONG_THUHOI, TINHTRANG_THUHOI } from "../../../../constants/DiSanVanHoaConstants";
import { GiayChungNhanDiSanVanHoa, GiayChungNhanDiSanVanHoa_INIT } from "../../../../model/giayChungNhanDiSanVanHoa";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { LichSuGiayChungNhan, LichSuGiayChungNhan_INIT } from "../../../../model/lichSuGiayChungNhan";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import DropZoneCustom from "../../../../components/commons/hoso/CustomDropzone/DropZoneCustom";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { Cell, Row } from "../../../commons/table/tableCommons";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import TepTinDinhKemTuBoDiTichComponent from "../../GiaoDienThuLy/ChungChiTuBoDiTich/ThuHoi/TepTinDinhKemTuBoDiTichComponent";
import { FileEntry } from "../../../../apis/Common/fileEntry";

interface TabPanelProps {
    children?: React.ReactNode;
    index?: any;
    value: any;
}

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

interface IDataListProps {
    dataList: LichSuGiayChungNhan[];
}

const ThuHoiGCNDiSanVanHoa = (props: IDataListProps) => {
    const leftStyle = useStylesLabelLeft();
    const rightStyle = useStylesLabelRight();
    const [indexTab, setIndexTab] = useState(0);
    // const [data, setData] = useState<HoSoThuLyDiSanVanHoa>({ ...HoSoThuLyDiSanVanHoa_INIT });
    const [data, setData] = useState<GiayChungNhanDiSanVanHoa>({
        ...GiayChungNhanDiSanVanHoa_INIT,
        className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
    });
    const [dataLichSu, setDataLichSu] = useState<LichSuGiayChungNhan>({ ...LichSuGiayChungNhan_INIT });
    const history = useHistory<any>();
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [currentDate, setCurrentDate] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState<HoSoThuLyDiSanVanHoa[]>([]);
    const [rowLichSu, setRowLichSu] = useState<LichSuGiayChungNhan[]>([]);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [showAlert, setShowAlert] = useState(false);
    const fileServerService = new FileServerService();
    const [tabRequired, setTabRequired] = useState(-1);
    const service = new GiayChungNhanDiSanVanHoaService();
    const getDetailById = async (id: number) => {
        await service
            .getGCNChiTiet(id)
            .then((res) => {
                if(res && res.fileThuHois && res.fileThuHois.length > 0) {
                    setData(res);

                }else{
                    setData({
                        ...res,
                        fileThuHois : new Array<FileEntry>()
                    })
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        let id = history?.location?.state?.params;
        let giayChungNhanId = history?.location?.state?.params;
        getDetailById(id);
        getLichSuGiayChungNhan(giayChungNhanId);
        setCurrentDate(StringUtil.dateSecond());
    }, []);

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value,
            };
            setData(dataNew);
        }
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };
    const getLichSuGiayChungNhan = async (id: number) => {
        await service
            .getLichSuGCN(id)
            .then((res) => {
                setRowLichSu(res.rows ? res.rows : []);
                setTotal(res.total);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleResetData = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
            const dataNew = {
                ...data,
                fileThuHois: new Array<FileEntry>(),
                lyDoThuHoi: "",
                ngayThuHoi: 0,
                ghiChu: "",
                maTrangThai: TINHTRANG_THUHOI.ma,
                tenTrangThai: TINHTRANG_THUHOI.ten,
                maBienDong: LOAIBIENDONG_THUHOI.ma,
                tenBienDong: LOAIBIENDONG_THUHOI.ten,
                className: "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa",
            };
            setData(dataNew);
            setSubmit(false);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.fileThuHois];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["fileThuHois"]: mems,
        };
        setData(dataNew);
    };

    // const handleChangeStatus = (file: IFileWithMeta) => {
    //     if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
    //         let res = JSON.parse(file.xhr.response);
    //         setDataFiles([...res.data]);
    //         file.remove();
    //     }
    // }

    // const setDataFiles = (memData: FileEntry[]) => {
    //     let mems = [...data.fileThuHois];
    //     mems = mems.concat(memData);
    //     setDataParam("fileThuHois", mems);
    // }

    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };

    const onDeleteFiles = (index: number) => {
        let mems = [...data.fileThuHois];
        mems.splice(index, 1);
        setDataParam("files", mems);
    };

    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };

    const handleSubmit = async () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.lyDoThuHoi)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.ngayThuHoi)) {
            return;
        }
        if (data.ngayThuHoi < data.ngayCapChungNhan) {
            setAlert({
                showAlert: true,
                message: "Ngày thu hồi không được nhỏ hơn ngày cấp",
                severity: "error",
            });
            return;
        }
        dispatchLoading(showLoading());
        data.maTrangThai = TINHTRANG_THUHOI.ma;
        data.tenTrangThai = TINHTRANG_THUHOI.ten;
        data.maBienDong = LOAIBIENDONG_THUHOI.ma;
        data.tenBienDong = LOAIBIENDONG_THUHOI.ten;

        await service
            .thuHoi(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    function getDiaChi(soNha: string, tenDuong: string, tenPhuong: string, tenHuyen: string) {
        var result = "";
        if (!StringUtil.isNullOrEmty(soNha)) result = result.concat(soNha);
        if (!StringUtil.isNullOrEmty(tenDuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenDuong);
            else result = result.concat(", ", tenDuong);
        }
        if (!StringUtil.isNullOrEmty(tenPhuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenPhuong);
            else result = result.concat(", ", tenPhuong);
        }
        if (!StringUtil.isNullOrEmty(tenHuyen)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenHuyen);
            else result = result.concat(", ", tenHuyen);
        }
        return result;
    }

    return (
        <div className="dash-content">
        <>
            <Grid container>
                <OverlayLoading />
                <Snackbar
                    open={alert.showAlert}
                    onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={3000}
                >
                    <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                        {alert.message}
                    </Alert>
                </Snackbar>
                <div className="dash-content">
                    <div className="dash-title">
                        <span>THU HỒI GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH</span>
                    </div>
                </div>
                <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                    <Box mt="5px" bgcolor="#f2f2f2">
                        <CustomTabs value={indexTab} onChange={handleChangeTab}>
                            <CustomTab label="Thông tin chung" />
                            <CustomTab label="Tập đính kèm" />
                            <CustomTab label="Lịch sử" />
                        </CustomTabs>
                    </Box>
                </Grid>
                <TabPanel value={indexTab} index={0}>
                    <Grid container>
                        <Grid container spacing={2}>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Số giấy chứng nhận</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data.soChungNhan}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Ngày Cấp</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>
                                    {data?.ngayCapChungNhan != null ? moment(data?.ngayCapChungNhan).format("DD/MM/YYYY") : ""}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Tình Trạng</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data.tenTrangThai}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Lần cấp lại</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data?.lanCapLai}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Tên tổ chức</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item">
                                <InputLabel classes={rightStyle}>{data.tenToChuc}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item">
                                <InputLabel classes={rightStyle}>{getDiaChi(data.soNha, data.tenDuong, data.tenPhuong, data.tenHuyen)}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Số QĐ/ GCNĐKDN/ GCNĐKKD</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data?.soQD}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data?.ngayCapQD != null ? moment(data?.ngayCapQD).format("DD/MM/YYYY") : ""}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Nơi cấp</InputLabel>
                            </Grid>
                            <Grid item xs={9} className="grid-item">
                                <InputLabel classes={rightStyle}>{data.noiCapQD}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Người đại diện theo pháp luật</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data.nguoiDaiDienTheoPhapLuat}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Ngày sinh</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data?.ngaySinh != null ? moment(data?.ngaySinh).format("DD/MM/YYYY") : ""}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Chức danh</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data.chucDanh}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Số CMND/ Số định danh</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data.soCMND}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Ngày cấp CMND</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data?.ngayCapCMND != null ? moment(data?.ngayCapCMND).format("DD/MM/YYYY") : ""}</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item" container justify="flex-end">
                                <InputLabel classes={leftStyle}>Nơi cấp CMND</InputLabel>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={rightStyle}>{data.noiCapCMND}</InputLabel>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Lý do thu hồi
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="lyDoThuHoi"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.lyDoThuHoi}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.lyDoThuHoi)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.lyDoThuHoi) ? "Vui lòng nhập lý do thu hồi" : ""}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Ngày thu hồi
                                    </InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayThuHoi && data.ngayThuHoi != 0 ? new Date(data.ngayThuHoi) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayThuHoi")}
                                            error={submit && StringUtil.isNullOrEmty(data.ngayThuHoi)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.ngayThuHoi) ? "Vui lòng nhập ngày thu hồi" : ""}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ghi chú</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="medium"
                                        name="ghiChu"
                                        value={data.ghiChu}
                                        onChange={handleChange}
                                        rows={2}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container className="grid-item">
                        <DropZoneCustom
                            acceptFiles={ACCEPT_FILES}
                            attachFileServer={data.fileThuHois}
                            url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                            handleChangeStatus={handleChangeStatus}
                            handleDeleteFileServer={handleDeleteFileServer}
                            handleDownloadFile={handleDownloadFile}
                        />
                    </Grid>
                    <Grid item xs={12} className="gridItem">
                        <Box marginTop="5px">
                            <Button onClick={() => handleSubmit()} variant="contained" color="primary" disableElevation className="button">
                                Lưu
                            </Button>
                            <Button variant="contained" onClick={handleResetData} color="secondary" disableElevation className="button">
                                Xóa
                            </Button>
                            <Button variant="contained" color="primary" disableElevation className="button" onClick={() => history.go(-1)}>
                                Trở về
                            </Button>
                        </Box>
                    </Grid>
                </TabPanel>
                <TabPanel value={indexTab} index={1}>
                    <TepTinDinhKemTuBoDiTichComponent data={data?.files} />
                </TabPanel>
                <TabPanel value={indexTab} index={2}>
                    <Grid container>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <Cell component="th" scope="row" align="center" width="5%">
                                        STT
                                    </Cell>
                                    <Cell component="th" scope="row" align="center" width="25%">
                                        Loại biến động
                                    </Cell>
                                    <Cell component="th" scope="row" align="center" width="20%">
                                        Ngày biến động
                                    </Cell>
                                    <Cell component="th" scope="row" align="center" width="20%">
                                        Số lần
                                    </Cell>
                                    <Cell component="th" scope="row" align="center" width="30%">
                                        Nội dung
                                    </Cell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowLichSu.map((row, i) => (
                                    <Row key={row.giayChungNhanId}>
                                        <Cell scope="row" align="center">
                                            {i + 1}
                                        </Cell>
                                        <Cell align="left">{row.tenBienDong}</Cell>
                                        <Cell align="left">
                                            {LOAIBIENDONG_CAPMOI.ma === row.maBienDong ? moment(row.ngayBienDong).format("DD/MM/YYYY") : ""}
                                            {LOAIBIENDONG_CAPLAI.ma === row.maBienDong ? moment(row.ngayCapLai).format("DD/MM/YYYY") : ""}
                                            {LOAIBIENDONG_THUHOI.ma === row.maBienDong ? moment(row.ngayThuHoi).format("DD/MM/YYYY") : ""}
                                            {/* {row.ngayBienDong !== null && row.ngayBienDong !== undefined ? moment(row.ngayBienDong).format("DD/MM/YYYY") : ""} */}
                                        </Cell>
                                        <Cell align="left">{row.soLanCapLai}</Cell>
                                        <Cell align="left">{row.lyDoCapLai}</Cell>
                                    </Row>
                                ))}
                            </TableBody>
                        </Table>
                        <Grid item xs={12} container className="gridItem">
                            <Box marginTop="5px">
                                <Button variant="contained" color="primary" disableElevation className="button" onClick={() => history.go(-1)}>
                                    Trở về
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Grid>
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
            </>
       </div>
    );
};
export default ThuHoiGCNDiSanVanHoa;