import { Box, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { HoSoThuLyDichVuDuLich } from "../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { CustomInput } from "../../../commons/input/customInput";
import { Cell, Row } from "../../../commons/table/tableCommons";

interface TableProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    dataList: HoSoThuLyDichVuDuLich[];
    search: () => void;
}
const DanhSachKDVuiChoiGiaiTri = (props: TableProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        setTotalPage(Math.ceil(props.count / props.rowsPerPage));
        setRowsPerPage(props.rowsPerPage);
    }, [props.count, props.rowsPerPage]);

    const getDiaChiDayDu = (data: HoSoThuLyDichVuDuLich): string => {
        let diaChiDayDu = "";
        return (diaChiDayDu =
            (data.soNha !== null && data.soNha !== "" && data.soNha !== undefined ? data.soNha + " " : "") +
            (data.tenDuong !== null && data.tenDuong !== "" && data.tenDuong !== undefined ? data.tenDuong + ", " : "") +
            (data.tenPhuong !== "" && data.tenPhuong !== undefined ? data.tenPhuong + ", " : "") +
            (data.tenHuyen !== "" && data.tenHuyen !== undefined ? data.tenHuyen + ", " : "") +
            "Thành Phố Cần Thơ");
    };

    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Số quyết định
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ngày cấp
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ngày hết hạn
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="15%">
                                Tên cơ sở
                            </Cell>
                            <Cell component="th" scope="row" align="center">
                                Địa chỉ
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Điện thoại
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tình trạng
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataList.length > 0 ? (
                            props.dataList.map((row, i) => (
                                <Row key={i}>
                                    <Cell>{(props.page - 1) * rowsPerPage + i + 1}</Cell>
                                    <Cell>{row.soQuyetDinh}</Cell>
                                    {/* <Cell>
                                            <NavLink style={{ textDecoration: 'none' }} exact
                                                to={{
                                                    pathname: ROUTE_GIAY_CHUNG_NHAN_GIAI_TRI_CHI_TIET.duongDan,
                                                    state: { ...row, params: row.giayChungNhanId }
                                                }}>
                                                {row.soQuyetDinh}
                                            </NavLink>
                                        </Cell> */}
                                    <Cell>
                                        {row.ngayCap && row.ngayCap !== undefined && row.ngayCap !== null
                                            ? moment(new Date(row.ngayCap)).format("DD/MM/YYYY")
                                            : ""}
                                    </Cell>
                                    <Cell>
                                        {row.ngayHetHan && row.ngayHetHan !== undefined && row.ngayHetHan !== null
                                            ? moment(new Date(row.ngayHetHan)).format("DD/MM/YYYY")
                                            : ""}
                                    </Cell>
                                    <Cell>{row.tenCoSo}</Cell>
                                    <Cell>{getDiaChiDayDu(row)}</Cell>
                                    <Cell>{row.soDienThoai}</Cell>
                                    <Cell>{row.tenTrangThai}</Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={9} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu
                                </Cell>
                            </Row>
                        )}
                    </TableBody>
                </Table>
                {props.dataList.length > 0 && (
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination
                            count={totalPage}
                            defaultPage={1}
                            siblingCount={1}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={props.onChangePage}
                        />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">
                                Số mẫu tin trên trang
                            </Box>
                            <NativeSelect input={<CustomInput />} onChange={props.onChangeRowsPerPage}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">
                            Mẫu tin {(props.page - 1) * props.rowsPerPage + 1} -{" "}
                            {props.count > props.page * props.rowsPerPage ? props.page * props.rowsPerPage : props.count} / {props.count}
                        </Box>
                    </Box>
                )}
            </TableContainer>
        </>
    );
};
export default DanhSachKDVuiChoiGiaiTri;
