import AXIOS_INSTANCE from "..";
import { TraCuuCoSoDuLichDTO } from "../../model/DichVuDuLich/DichVuDuLichModel";
import { GiayChungNhanKinhDoanh } from "../../model/DichVuDuLich/GiayChungNhanKinhDoanh";
import { LichSuDichVuDuLichDTO } from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { BaseService } from "../baseService";

export class GiayChungNhanAnUongService extends BaseService {
    searchGiayChungNhanAnuong = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-an-uong/tim-kiem", requestBody, this.getTokenRequestHeaders());
    };

    downloadFileTraCuuChungNhan = (request: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-an-uong/export-file-tra-cuu", {
            responseType: "blob",
            params: request,
        });
    };
    downloadFileGiayChungNhan(request: any) {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-an-uong/download-file-giay-chung-nhan", request, {
            responseType: "blob",
        });
    }

    chiTietGiayChungNhanById(giayChungNhanId: number) {
        let body = {
            giayChungNhanId: giayChungNhanId,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-an-uong/get-by-id", {
            params: body,
        });
    }

    chiTietGiayThuHoi(giayChungNhanId: number, classNameGCN: string) {
        let body = {
            giayChungNhanId: giayChungNhanId,
            classNameGCN: classNameGCN,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-an-uong/chi-tiet-thu-hoi", {
            params: body,
        });
    }

    chiTietGiayTamNgung(giayChungNhanId: number, classNameGCN: string) {
        let body = {
            giayChungNhanId: giayChungNhanId,
            classNameGCN: classNameGCN,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-an-uong/chi-tiet-tam-ngung", {
            params: body,
        });
    }

    insertOrUpdate = (hoSo: GiayChungNhanKinhDoanh) => {
        return AXIOS_INSTANCE.post("thu-ly/dich-vu-du-lich/save-or-update", hoSo, this.getTokenRequestHeaders());
    };

    thuHoi = (thuHoi: LichSuDichVuDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-an-uong/save-or-update-thu-hoi", thuHoi, this.getTokenRequestHeaders());
    };

    baoCaoTinhHinhGCNAnUong = (requestBody: TraCuuCoSoDuLichDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-an-uong/bao-cao", requestBody);
    };

    downloadFileBaoCaoChungNhan(request: any) {
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-an-uong/export-bao-cao-giay-chung-nhan", {
            responseType: "blob",
            params: request,
        });
    }
}
