import { Grid } from "@material-ui/core";
import React, { Suspense } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { ThemedSuspense } from "../components/themedSuspense";
import { RoutePropsI, ROUTE_CN, ROUTE_GUEST } from "../routes";

export interface ChildrenLayoutProps {
    cRoute: RoutePropsI[];
}
const ChildrenLayout: React.FC<ChildrenLayoutProps> = (props: ChildrenLayoutProps) => {
    const { cRoute } = props;
    return (
        <Suspense fallback={<ThemedSuspense />}>
            <Grid item xs={10}>
                <Switch>
                    {cRoute.map((item) => {
                        return <Route exact path={item.duongDan} component={item.giaoDien} />;
                    })}
                    {ROUTE_CN.map((item) => {
                        return <Route exact path={item.duongDan} component={item.giaoDien} />;
                    })}
                    {ROUTE_GUEST.map((item) => {
                        return <Route exact path={item.duongDan} component={item.giaoDien} />;
                    })}
                </Switch>
            </Grid>
        </Suspense>
    );
};

export default ChildrenLayout;
