import { AttachFileDTO } from "../../../danhMucCN/attachFile";


export interface BieuMauAttachFileDTO extends AttachFileDTO {
    screenName?: string;
    isEdit?: boolean;
}

export enum LoaiBieuMauEnum {
    NGHIEP_VU = "0",
    DANH_SACH = "1",
}

export interface DanhMucOptionsDTO<T> {
    title: String;
    value: T;
}

export const BieuMauOptions: DanhMucOptionsDTO<LoaiBieuMauEnum>[] = [
    { title: "Nghiệp vụ", value: LoaiBieuMauEnum.NGHIEP_VU },
    { title: "Danh sách", value: LoaiBieuMauEnum.DANH_SACH },
];

export interface DanhMucBieuMauDTO {
    id?: number | null;
    name?: string;
    path?: string;
    bussinessName?: string;
    isActive?: boolean;
    specializ: boolean;
    attachFiles?: BieuMauAttachFileDTO[];
}
