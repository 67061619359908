import { Box, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { GiayPhepToChucNgheThuat } from "../../../../model/ThongBaoNgheThuat/GiayPhepToChucNgheThuat";
import { HoatDongVanHoaNgheThuat } from "../../../../model/ThongBaoNgheThuat/HoatDongVanHoaNgheThuat";
import { ROUTE_GIAY_PHEP_TO_CHUC_CHI_TIET } from "../../../../routes/route_hdnt";
import { ROUTE_GIAY_CHUNG_NHAN_CHI_TIET } from "../../../../routes/route_vhdl";
import { CustomInput } from "../../../commons/input/customInput";
import { Cell, Row } from "../../../commons/table/tableCommons";

import { ThaoTacGiayChungNhan } from "./thaoTacGiayPhepHDNT";

interface IModalProps {
    openEditDialog: (itemSelected: GiayPhepToChucNgheThuat) => void;
    open: boolean;
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    reloadData: () => void;
}
interface IDataListProps {
    dataList: GiayPhepToChucNgheThuat[];
    deleteItem: (roleId: number) => void;
    modalEdit: IModalProps;
    pageProps: TablePaginationActionsProps;
}

export const DataListThongTinGiayChungNhan = (props: IDataListProps) => {
    const [rows, setRows] = useState<GiayPhepToChucNgheThuat[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const { reloadData } = props.pageProps;

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.rowsPerPage);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    const getDiaChiDayDu = (data: GiayPhepToChucNgheThuat): string => {
        let diaChiDayDu = "";
        return (diaChiDayDu =
            (data.soNha !== "" && data.soNha !== undefined ? data.soNha + " " : "") +
            (data.tenDuong !== "" && data.tenDuong !== undefined ? data.tenDuong + ", " : "") +
            (data.tenPhuong !== "" && data.tenPhuong !== undefined ? data.tenPhuong + ", " : "") +
            (data.tenHuyen !== "" && data.tenHuyen !== undefined ? data.tenHuyen + ", " : "") +
            "Thành Phố Cần Thơ");
    };
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="left" width="10%">
                                Số giấy phép
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ngày cấp
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tên đơn vị đề nghị
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Tên chương trình
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Địa chỉ tổ chức
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tình trạng
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ghi chú
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="5%">
                                Thao tác
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => (
                            <Row key={row.id}>
                                <Cell scope="row" align="center">
                                    {(props.pageProps.page - 1) * rowsPerPage + i + 1}
                                </Cell>
                                <Cell>
                                    <NavLink
                                        style={{ textDecoration: "none" }}
                                        exact
                                        to={{
                                            pathname: ROUTE_GIAY_PHEP_TO_CHUC_CHI_TIET.duongDan,
                                            state: { ...row, id: row.id },
                                        }}
                                    >
                                        {row.soGiayPhep}
                                    </NavLink>
                                </Cell>
                                <Cell align="center">{moment(row.ngayCap).format("DD/MM/YYYY")}</Cell>
                                <Cell align="left">{row.tenNhaHat}</Cell>
                                <Cell align="left">{row.tenChuongTrinh}</Cell>
                                <Cell align="center">{getDiaChiDayDu(row)}</Cell>
                                <Cell align="center">{row.tenTrangThai}</Cell>
                                <Cell align="left">{row.ghiChu}</Cell>
                                <Cell align="center">
                                    <ThaoTacGiayChungNhan reloadData={reloadData} row={row} />
                                </Cell>
                            </Row>
                        ))}
                    </TableBody>
                </Table>
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination
                        count={totalPage}
                        defaultPage={1}
                        siblingCount={1}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        onChange={props.pageProps.onChangePage}
                    />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">
                            Số mẫu tin trên trang
                        </Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">
                        Mẫu tin {(props.pageProps.page - 1) * props.pageProps.rowsPerPage + 1} -{" "}
                        {props.pageProps.count > props.pageProps.page * props.pageProps.rowsPerPage
                            ? props.pageProps.page * props.pageProps.rowsPerPage
                            : props.pageProps.count}{" "}
                        / {props.pageProps.count}
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};
