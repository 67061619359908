import MomentUtils from "@date-io/moment";
import { Box, createStyles, FormHelperText, Grid, InputBase, InputLabel, makeStyles, Snackbar, TextField, Theme, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DOANH_NGHIEP } from "../../../../apis/quanTri/danhMucFakeService";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { DanhMucDanTocService } from "../../../../apis/danhMuc/danhMucDanTocService";
import { DanhMucGioiTinhService } from "../../../../apis/danhMuc/danhMucGioiTinhService";
import { DanhMucQuocGiaService } from "../../../../apis/danhMuc/danhMucQuocGiaService";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO, DanhMucDungChungDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { LuHanhNoiDiaService } from "../../../../apis/LuHanhNoiDia/LuHanhNoiDiaService";
import { ACCEPT_FILES, LOAIBIENDONG_CAPDOI, LOAIBIENDONG_THUHOI, TINHTRANG_HOATDONG } from "../../../../constants/LuHanhNoiDiaConstants";
import { GiayPhepLuHanhNoiDia_INIT } from "../../../../init/LuHanhNoiDia/GiayPhepLuHanhNoiDia_INIT";
import { HoSoThuLyLuHanhNoiDia_INIT } from "../../../../init/LuHanhNoiDia/HoSoThuLyLuHanhNoiDia_INIT";
import { GiayPhepKinhDoanhLuHanhDTO, HoSoThuLyLuHanhNoiDia } from "../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { AlertMessage } from "../../../commons/alert";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import LuHanhNoiDiaButtonComponent from "../Action/LuHanhNoiDiaButtonComponent";
import DanhSachDuThaoModal from "../thuly/danhSachDuThaoModal";
import ThuLyButtonComponent from "../thuly/ThuLyButtonComponent";
import NoiDungCuComponent from "./NoiDungCuComponent";
import TepTinDinhKem from "./TepTinDinhKem";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import DropZoneCustom from "../../../commons/hoso/CustomDropzone/DropZoneCustom";
import AXIOS_INSTANCE from "../../../../apis";
import { HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CapDoiLHNDComponent = () => {
    const [indexTab, setIndexTab] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [dataAutocomple, setDataAutocomple] = useState<GiayPhepKinhDoanhLuHanhDTO[]>(new Array<GiayPhepKinhDoanhLuHanhDTO>());
    const [dataSoGiayPhep, setDataSoGiayPhep] = useState<GiayPhepKinhDoanhLuHanhDTO>({ ...GiayPhepLuHanhNoiDia_INIT });
    const [data, setData] = useState<HoSoThuLyLuHanhNoiDia>({ ...HoSoThuLyLuHanhNoiDia_INIT });
    const luHanhNoiDiaService = new LuHanhNoiDiaService();
    const fileServerService = new FileServerService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [currentDate, setCurrentDate] = useState(0);
    const location = useLocation<any>();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [title, setTitle] = useState<string>();
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [showAlert, setShowAlert] = useState(false);
    const history = useHistory<any>();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const [danTocs, setDanTocs] = useState<DanhMucDungChungDTO[]>([]);
    const [quocGias, setQuocGias] = useState<DanhMucDungChungDTO[]>([]);
    const [gioiTinhs, setGioiTinhs] = useState<DanhMucDungChungDTO[]>([]);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const [open, setOpen] = useState(false);
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);

    useEffect(() => {
        initData();
        loadQuans("92");
    }, []);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const initData = async () => {
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        let danToc =  await new DanhMucDanTocService().findAll();
        let quocGia =  await new DanhMucQuocGiaService().findAll();
        let gioiTinh =  await new DanhMucGioiTinhService().findAll();
        setDanTocs(danToc);
        setQuocGias(quocGia);
        setGioiTinhs(gioiTinh);
        await luHanhNoiDiaService
            .getByMaHoSo(maHS)
            .then((res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    const dataNew = {
                        ...res?.data?.data,
                        maHoSo: maHS,
                        maThuTuc: maThuTuc,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPDOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                    }; //as HoSoThuLyLuHanhNoiDia
                    setData(dataNew);
                    setDataSoGiayPhep({ ...dataSoGiayPhep, soGiayPhep: dataNew.soGiayPhepCu, ngayCap: dataNew.ngayCap });
                    // if (dataNew.soGiayPhep != "")
                    //     loadGiayPhepBySoGiayPhep(dataNew.soGiayPhep);
                } else {
                    const dataNew = {
                        ...HoSoThuLyLuHanhNoiDia_INIT,
                        maHoSo: data.maHoSo,
                        maThuTuc: maThuTuc,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPDOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                        files: new Array<FileEntry>(),
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleSubmit = async () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(location.state?.maHoSo)) {
            setAlert({
                showAlert: true,
                message: "Không có mã hồ sơ",
                severity: "warning",
            });
            return;
        }
        if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
            return;
        }
        if (data.ngayCap == 0) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.lyDo)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.soLanCapDoi)) {
            return;
        }
        if (data.ngayCapGCN > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        dispatchLoading(showLoading());
        await luHanhNoiDiaService
            .insertOrUpdate(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setData({
                        ...response.data.data,
                        id: response.data.data.id,
                        resoucePK: response.data.data.resoucePK,
                        ngayTao: response.data.data.ngayTao,
                    });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else {
                    setAlert({
                        showAlert: false,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
                //lỗi
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const loadGiayPhepBySoGiayPhep = (newValue: string) => {
        let maHS = location.state?.maHoSo;
        luHanhNoiDiaService
            .getBySoGiayPhep({ soGiayPhep: newValue })
            .then((res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    let resData = {
                        ...res?.data?.data,
                    } as GiayPhepKinhDoanhLuHanhDTO;
                    setDataSoGiayPhep(resData);
                    setData({
                        ...res?.data?.data,
                        hoSoThuLyId: data.hoSoThuLyId,
                        soGiayPhepCu: newValue,
                        // giayPhepId: data.giayPhepId,
                        maHoSo: maHS,
                        maThuTuc: data.maThuTuc,
                        ngayCap: data.ngayCap,
                        lyDo: data.lyDo,
                        soLanCapDoi: resData.soLanCapDoi + 1,
                        files: data.files,
                        maTrangThai: TINHTRANG_HOATDONG.ma,
                        tenTrangThai: TINHTRANG_HOATDONG.ten,
                        maBienDong: LOAIBIENDONG_CAPDOI.ma,
                        tenBienDong: LOAIBIENDONG_CAPDOI.ten,
                        // giayPhepCu: res?.data?.data,
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Số giấy chứng nhận không tồn tại",
                        severity: "warning",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleChangeSoGiayPhep = async (event: any) => {
        let soGiayPhepCu = event.target.value;
        setDataSoGiayPhep({
            ...dataSoGiayPhep,
            soGiayPhep: soGiayPhepCu,
        });
        await luHanhNoiDiaService
            .getByAutocompleteSoGiayPhep(soGiayPhepCu, LOAIBIENDONG_THUHOI.ma, true, 10)
            .then((res) => {
                let dataAutocompleNew = res?.data.data;
                if (StringUtil.listIsNotEmty(dataAutocompleNew)) {
                    setDataAutocomple([...dataAutocompleNew]);
                } else {
                    setDataAutocomple(new Array<GiayPhepKinhDoanhLuHanhDTO>());
                    // setDataAutocomple([]);
                }
            })
            .catch((error) => {});
    };

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let tenValue = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: tenValue,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(data.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleResetData = () => {
        const dataNew = {
            ...HoSoThuLyLuHanhNoiDia_INIT,
            hoSoThuLyId: data.hoSoThuLyId,
            giayPhepId: data.giayPhepId,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            maTrangThai: TINHTRANG_HOATDONG.ma,
            tenTrangThai: TINHTRANG_HOATDONG.ten,
            maBienDong: LOAIBIENDONG_CAPDOI.ma,
            tenBienDong: LOAIBIENDONG_CAPDOI.ten,
        };
        setData(dataNew);
        const dataNew1 = {
            ...GiayPhepLuHanhNoiDia_INIT,
            hoSoThuLyId: dataSoGiayPhep.hoSoThuLyId,
            giayPhepId: dataSoGiayPhep.giayPhepId,
            maHoSo: dataSoGiayPhep.maHoSo,
            maThuTuc: dataSoGiayPhep.maThuTuc,
            maTrangThai: TINHTRANG_HOATDONG.ma,
            tenTrangThai: TINHTRANG_HOATDONG.ten,
            maBienDong: LOAIBIENDONG_CAPDOI.ma,
            tenBienDong: LOAIBIENDONG_CAPDOI.ten,
        };
        setDataSoGiayPhep(dataNew1);
    };
    useEffect(() => {
        if (data.maHoSo) {
            setTitle(`CẤP ĐỔI GIẤY PHÉP KINH DOANH DỊCH VỤ LỮ HÀNH NỘI ĐỊA - MÃ HỒ SƠ ${data.maHoSo}`);
        }
    }, [data?.maHoSo]);
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {

        luHanhNoiDiaService.downloadFileThuLy({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
        });
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    const loadPhuongs = () => {
        if(data?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(data?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeAutocomplete = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    return (
        
        <>
        <div className="dash-content">
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>{title} </h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Nội dung mới" />
                                <CustomTab label="Nội dung cũ" />
                                <CustomTab label="Tệp đính kèm" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Số GPKD dịch vụ lữ hành
                                    </InputLabel>
                                    <Autocomplete
                                        classes={inputStyles}
                                        options={dataAutocomple}
                                        value={dataSoGiayPhep}
                                        noOptionsText={"Không có dữ liệu"}
                                        getOptionLabel={(option) => option.soGiayPhep}
                                        onChange={(event: any, newValue: any) =>
                                            loadGiayPhepBySoGiayPhep(newValue && newValue != null ? newValue.soGiayPhep : "")
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                name="soGiayPhepCu"
                                                value={data.soGiayPhep}
                                                onChange={(e: any) => handleChangeSoGiayPhep(e)}
                                                error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.soGiayPhep) ? "Vui lòng nhập số phép" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={dataSoGiayPhep.ngayCap && dataSoGiayPhep.ngayCap !== 0 ? new Date(dataSoGiayPhep.ngayCap) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {/* <Grid item xs={9} className="grid-item">
                                    <RadioGroup row aria-label="position" name="maLinhVuc" defaultValue="end" onChange={handleChange}>
                                        <FormControlLabel
                                            checked={data.isNoiDia == 1}
                                            value="1"
                                            control={<Radio color="primary" size="small" />}
                                            label="Giấy phép kinh doanh dịch vụ lữ hành Quốc tế"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            checked={data.isNoiDia == 2}
                                            value="2"
                                            control={<Radio color="primary" size="small" />}
                                            label="Giấy phép kinh doanh dịch vụ lữ hành Nội địa"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </Grid> */}
                                <Grid item xs={6} className="grid-item"></Grid>
                                <Grid item xs={9} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên doanh nghiệp"}
                                        required
                                        name="tenDoanhNghiep"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.tenDoanhNghiep}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.tenDoanhNghiep)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.tenDoanhNghiep) ? "Vui lòng nhập tên doanh nghiệp" : ""}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Loại hình Doanh nghiệp
                                    </InputLabel>
                                    <Autocomplete
                                        id="maLoaiHinhDoanhNghiep"
                                        classes={inputStyles}
                                        options={DOANH_NGHIEP}
                                        getOptionLabel={(option) => option.ten}
                                        value={{ ma: data.maLoaiHinhDoanhNghiep, ten: data.tenLoaiHinhDoanhNghiep }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelect(newValue, "maLoaiHinhDoanhNghiep", "tenLoaiHinhDoanhNghiep");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maLoaiHinhDoanhNghiep)}
                                                helperText={
                                                    submit && StringUtil.isNullOrEmty(data.maLoaiHinhDoanhNghiep) ? "Vui lòng chọn loại doanh nghiệp" : ""
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên giao dịch"}
                                        name="tenDichVu"
                                        onChange={handleChange}
                                        value={data.tenDichVu}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên viết tắt"}
                                        name="tenVietTat"
                                        onChange={handleChange}
                                        value={data.tenVietTat}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Điện thoại"}
                                        name="dienThoai"
                                        onChange={handleChange}
                                        value={data.dienThoai}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Fax"}
                                        name="fax"
                                        onChange={handleChange}
                                        value={data.fax}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Email"}
                                        name="email"
                                        onChange={handleChange}
                                        value={data.email}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Website"}
                                        name="website"
                                        onChange={handleChange}
                                        value={data.website}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Huyện/Thị/TP{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maHuyen"
                                        classes={inputStyles}
                                        options={quans}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maHuyen, name: data.tenHuyen }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn Huyện/Thị/TP" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Phường/Xã/TT{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maPhuong"
                                        classes={inputStyles}
                                        options={phuongs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maPhuong, name: data.tenPhuong }}
                                        onFocus ={loadPhuongs}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maPhuong) ? "Vui lòng chọn Phường/Xã/TT" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Đường"}
                                        name="tenDuong"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.tenDuong}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                    {/* <InputLabel>Đường </InputLabel>
                                    <Autocomplete
                                        id="maDuong"
                                        classes={inputStyles}
                                        options={DUONG}
                                        getOptionLabel={(option) => option.ten}
                                        value={{ ma: data.maDuong, ten: data.tenDuong }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelect(newValue, "maDuong", "tenDuong");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    /> */}
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số nhà"}
                                        name="soNha"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soNha}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Người đại diện theo pháp luật"}
                                        name="nguoiDaiDienTheoPhapLuat"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.nguoiDaiDienTheoPhapLuat}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" style={{ marginTop: "8px" }}>
                                    <InputLabel>Giới tính </InputLabel>
                                    <Autocomplete
                                        id="gioiTinh"
                                        classes={inputStyles}
                                        options={gioiTinhs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.gioiTinh, name: data.tenGioiTinh }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeAutocomplete(newValue, "gioiTinh", "tenGioiTinh");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" style={{ marginTop: "8px" }}>
                                    <InputLabel>Ngày sinh</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngaySinh && data.ngaySinh !== 0 ? new Date(data.ngaySinh) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngaySinh")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel classes={labelStyles}>Dân tộc </InputLabel>
                                    <Autocomplete
                                        id="maDanToc"
                                        classes={inputStyles}
                                        options={danTocs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maDanToc, name: data.tenDanToc }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeAutocomplete(newValue, "maDanToc", "tenDanToc");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel>Quốc tịch </InputLabel>
                                    <Autocomplete
                                        id="maQuocTich"
                                        classes={inputStyles}
                                        options={quocGias}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maQuocTich, name: data.tenQuocTich }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeAutocomplete(newValue, "maQuocTich", "tenQuocTich");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số điện thoại"}
                                        name="soDienThoaiNDD"
                                        onChange={handleChange}
                                        value={data.soDienThoaiNDD}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Email"}
                                        name="mailNDD"
                                        onChange={handleChange}
                                        value={data.mailNDD}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        style={{ width: "90%" }}
                                        label={"Số CMND/ Hộ chiếu/ Căn cước"}
                                        name="soGiayTo"
                                        required
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soGiayTo}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.soGiayTo)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        helperText={submit && StringUtil.isNullOrEmty(data?.soGiayTo) ? "Vui lòng nhập số CMND/ Hộ chiếu/ Căn cước" : ""}
                                    />
                                    <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "30px", marginLeft: "3px" }} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" style={{ marginTop: "8px" }}>
                                    <InputLabel>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapSoGiayTo && data.ngayCapSoGiayTo != 0 ? new Date(data.ngayCapSoGiayTo) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapSoGiayTo")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Nơi cấp"}
                                        name="noiCapSoGiayTo"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.noiCapSoGiayTo}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Chức danh"}
                                        name="chucDanh"
                                        value={data.chucDanh}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Địa chỉ thường trú"}
                                        name="diaChiThuongTru"
                                        value={data.diaChiThuongTru}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Chỗ ở hiện nay"}
                                        name="choOHienNay"
                                        value={data.choOHienNay}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        size="medium"
                                        label={"Tên, địa chỉ chi nhánh"}
                                        name="tenDiaChiChiNhanh"
                                        onChange={handleChange}
                                        value={data.tenDiaChiChiNhanh}
                                        rows={3}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        size="medium"
                                        label={"Tên, văn phòng đại diện"}
                                        name="tenVanPhongDaiDien"
                                        onChange={handleChange}
                                        value={data.tenVanPhongDaiDien}
                                        rows={3}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        style={{ width: "90%" }}
                                        label={"Số GCNĐKDN/GCNĐKĐT"}
                                        name="soGCN"
                                        required
                                        InputLabelProps={{ classes: labelStyles }}
                                        onChange={handleChange}
                                        value={data.soGCN}
                                        error={submit && StringUtil.isNullOrEmty(data.soGCN)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        helperText={submit && StringUtil.isNullOrEmty(data?.soGCN) ? "Vui lòng nhập số GCNĐKDN/GCNĐKĐT" : ""}
                                    />
                                    <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "30px", marginLeft: "3px" }} />
                                </Grid>
                                <Grid item xs={3} className="grid-item" style={{ marginTop: "8px" }}>
                                    <InputLabel>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapGCN && data.ngayCapGCN != 0 ? new Date(data.ngayCapGCN) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapGCN")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Nơi cấp"}
                                        name="noiCapGCN"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.noiCapGCN}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tài khoản ký quỹ số"}
                                        name="taiKhoanKyQuySo"
                                        value={data.taiKhoanKyQuySo}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tại ngân hàng"}
                                        name="nganHang"
                                        value={data.nganHang}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid container style={{ marginTop: "20px" }}>
                                    <Grid item xs={6} className="grid-item">
                                        <InputLabel required classes={labelStyles}>Lý do thay đổi, bổ sung</InputLabel>
                                        <InputBase
                                            fullWidth
                                            name="lyDo"
                                            value={data.lyDo}
                                            onChange={handleChange}
                                            error={submit && StringUtil.isNullOrEmty(data.lyDo)}
                                        />
                                        {submit && StringUtil.isNullOrEmty(data.lyDo) && (
                                            <FormHelperText className="-error">Vui lòng nhập lý do cấp lại</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel required classes={labelStyles}>Lần thay đổi, bổ sung</InputLabel>
                                        <InputBase
                                            fullWidth
                                            name="soLanCapDoi"
                                            error={submit && StringUtil.isNullOrEmty(data.soLanCapDoi)}
                                            value={data?.soLanCapDoi}
                                            onChange={handleChange}
                                        />
                                        {submit && StringUtil.isNullOrEmty(data.soLanCapDoi) && (
                                            <FormHelperText className="-error">Vui lòng nhập Lần thay đổi, bổ sung</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel required classes={labelStyles}>Ngày thay đổi</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                required
                                                name="ngayCap"
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={data.ngayCap && data.ngayCap !== 0 ? new Date(data.ngayCap) : null}
                                                error={submit && !(data.ngayCap && data.ngayCap !== 0)}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                            />
                                            {submit && !(data.ngayCap && data.ngayCap !== 0) && (
                                                <FormHelperText className="-error">Vui lòng nhập Ngày thay đổi</FormHelperText>
                                            )}
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/luhanh"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item"></Grid>
                            </Grid>
                            <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                                {/* <LuHanhNoiDiaButtonComponent
                                    onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                    onSubmit={handleSubmit}
                                    onResetData={handleResetData}
                                    onOpenDialog={handleOpenDialog}
                                /> */}
                                <ThuLyButtonComponent
                                    showIn={data.hoSoThuLyId > 0}
                                    onSubmit={handleSubmit}
                                    onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                    onDelete={handleResetData}
                                    handleChuyen={showHidePopperThaoTac}
                                    handleDuThao={showHidePopperDuThao}
                                    dataButton= {data}
                                />
                            </Grid>
                        </TabPanel>
                    )}
                    {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <NoiDungCuComponent data={data} />
                        </TabPanel>
                    )}
                    {indexTab === 2 && (
                        <TabPanel value={indexTab} index={2}>
                            <TepTinDinhKem data={data?.files} />
                        </TabPanel>
                    )}
                </Grid>
            }
            </div>
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
            {showDanhSachDuThaoModal && (
               <DanhSachDuThaoModal hoso={data} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}
        </>
    );
};
export default CapDoiLHNDComponent;
