import React from "react";
import { Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/rootReducer";
import { CustomAlert } from "../alert";
import { closeNotification } from "../../../redux/actions/notificationAction";

export const AlertMessage = () => {
    const { alertMessage } = useSelector((state: RootState) => state.notification);
    const dispatch = useDispatch();
    function handleCloseAlertMessage() {
        dispatch(closeNotification());
    }
    return (
        <>
            {alertMessage.open && (
                <Snackbar open={alertMessage.open} onClose={handleCloseAlertMessage} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                        {alertMessage.content}
                    </CustomAlert>
                </Snackbar>
            )}
        </>
    );
};
