import AXIOS_INSTANCE from "..";
import { GiayChungNhanDSVHRequest } from "../../init/GiayChungNhan/GiayChungNhanDSVHRequest";
import { GiayChungNhanDiSanVanHoa, GiayChungNhanDiSanVanHoa_INIT } from "../../model/giayChungNhanDiSanVanHoa";
import { LichSuGiayChungNhan } from "../../model/lichSuGiayChungNhan";
import { BaseService } from "../baseService";

export class GiayChungNhanDiSanVanHoaService extends BaseService {
    getListGCN = (requestBody: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/search", requestBody);
    };

    getBySoGiayChungNhanDiSanVanHoas(id: number) {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/get-giay-chung-nhan-by-id", {
            id: id,
        });
    }

    getGCNChiTiet = async (id: number): Promise<GiayChungNhanDiSanVanHoa> => {
        var result: GiayChungNhanDiSanVanHoa = GiayChungNhanDiSanVanHoa_INIT;
        await AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/get-chi-tiet-giay-chung-nhan", { id: id }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    result = { ...response.data.data };
                }
            })
            .catch();
        return result;
    };

    getLichSuGCN = async (id: number): Promise<{ rows: LichSuGiayChungNhan[]; total: number }> => {
        var list: LichSuGiayChungNhan[] = [];
        var total: number = 0;
        await AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/get-lich-su-giay-chung-nhan", { id: id }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    list = response.data.data;
                    total = list.length;
                }
            })
            .catch();
        return { rows: list, total: total };
    };
    thuHoi = (giayChungNhan: GiayChungNhanDiSanVanHoa) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/thu-hoi", giayChungNhan, this.getTokenRequestHeaders());
    };

    baoCaoTinhHinhGiayChungNhan = (requestBody: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/bao-cao-ds-giay-chung-nhan", requestBody);
    };
    downloadFileBaoCaoGiayChungNhan = (request: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/export-bao-cao-giay-chung-nhan", request, {
            responseType: "blob",
        });
    };
    downloadFileTraCuuThongTinGCN = (request: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/export-tra-cuu-thong-tin", request, {
            responseType: "blob",
        });
    };
    getBySoGiayChungNhanDiSanVanHoa(id: number) {
        let body = {
            id: id,
        };
        return AXIOS_INSTANCE.get("di-san-van-hoa/giay-chung-nhan/get-giay-chung-nhan-di-san-van-hoa", {
            params: body,
        });
    }

    getByAutocompleteSoGiayChungNhan(soChungNhanDaCap: string, hoatDong: boolean, limit: number) {
        let body = {
            soGiayChungNhan: soChungNhanDaCap,
            hoatDong: hoatDong,
            limit: limit,
        };
        return AXIOS_INSTANCE.get("di-san-van-hoa/giay-chung-nhan/get-autocomplete-by-so-giay-chung-nhan", {
            params: body,
        });
    }

    downloadFileThongKeGCN = (request: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.get("di-san-van-hoa/giay-chung-nhan/export-thong-ke-gcn", {
            responseType: "blob",
            params: request,
        });
    };

    thongKeGCNDiSanVanHoa = (requestBody: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/thong-ke-gcn", requestBody);
    };

    downloadFileThongKe = (request: GiayChungNhanDSVHRequest) => {
        return AXIOS_INSTANCE.get("di-san-van-hoa/giay-chung-nhan/export-file-thong-ke", {
            responseType: "blob",
            params: request,
        });
    };

    chiTietThongKe = (field?: number, index?: number, showDate?: boolean, maHuyen?: string, ngayCapChungNhanTu?: number, ngayCapChungNhanDen?: number, namThongKe?: number) => {
        let body = {
            index: index,
            field: field,
            showDate: showDate,
            maHuyen: maHuyen,
            ngayCapChungNhanTu: ngayCapChungNhanTu,
            ngayCapChungNhanDen: ngayCapChungNhanDen,
            namThongKe: namThongKe,
        };
        return AXIOS_INSTANCE.post("di-san-van-hoa/giay-chung-nhan/chi-tiet-thong-ke", {body});
    };
}
