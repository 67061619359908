import {
    Box,
    Button,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    NativeSelect,
    Snackbar,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Alert, Autocomplete, Pagination } from "@material-ui/lab";
import { HUYEN, PHUONG } from "apis/quanTri/danhMucFakeService";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CapSoDiSanVanHoaService } from "apis/DiSanVanHoa/CapSoDiSanVanHoaService";
import { HoSoThuLyDiSanVanHoa_INIT } from "../../../init/DiSanVanHoa/HoSoThuLyDiSanVanHoa_INIT";
import { HoSoThuLyDiSanVanHoa } from "../../../model/hoSoThuLyDiSanVanHoa";
import { LoadingAction } from "../../../redux/actions/applicationAction";
import { CustomInput } from "../../commons/input/customInput";
import { Cell, Row } from "../../commons/table/tableCommons";
import { CapSoChungChiVanHoaDuLichModal } from "./CapSoChungChiVanHoaDuLichModal";
import { CapSoChungNhanVanHoaDuLichModal } from "./CapSoChungNhanVanHoaDuLichModal";

const CapSoDiSanGiayPhepComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [total, setTotal] = useState(0);
    const [showModalChungNhan, setShowModalChungNhan] = useState(false);
    const [showModalChungChi, setShowModalChungChi] = useState(false);
    const [data, setData] = useState<HoSoThuLyDiSanVanHoa[]>([]);
    const [search, setSearch] = useState<HoSoThuLyDiSanVanHoa>({
        ...HoSoThuLyDiSanVanHoa_INIT,
        pageSize: 5,
        pageNumber: 1,
    });
    const [indexRow, setIndexRow] = useState(-1);
    const [dataRow, setDataRow] = useState({ ...HoSoThuLyDiSanVanHoa_INIT } as HoSoThuLyDiSanVanHoa);
    const capSoDiSanVanHoaService = new CapSoDiSanVanHoaService();
    useEffect(() => {
        doSearch(search);
    }, []);
    const doSearch = (searchRequest: HoSoThuLyDiSanVanHoa) => {
        capSoDiSanVanHoaService
            .danhSachCapSo(searchRequest)
            .then((response) => {
                let result = response.data;
                if (result && result.data) {
                    setData(result.data.data !== undefined ? result.data.data : []);
                    setTotal(result.data.total !== undefined ? result.data.total : 0);
                } else {
                    setData([]);
                    setTotal(0);
                }
            })
            .catch((error) => {
                setData([]);
                setTotal(0);
                console.log(error);
            });
    };
    const setParamSearch = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setSearch((search) => ({
            ...search,
            [name]: value,
        }));
    };
    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchNew = { ...search, pageNumber: value };
        setSearch(searchNew);
        doSearch(searchNew);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchNew = { ...search, pageNumber: 1, pageSize: Number(event.target.value) };
        setSearch(searchNew);
        doSearch(searchNew);
    };

    const handSearch = () => {
        let searchNew = { ...search, pageNumber: 1, pageSize: 5 };
        setSearch(searchNew);
        doSearch(searchNew);
    };
    const handleToggle = () => {
        if (dataRow.className == "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa") {
            setShowModalChungNhan(!showModalChungNhan);
        } else {
            setShowModalChungChi(!showModalChungChi);
        }
    };
    const handleSubmit = (success: boolean, mess: string) => {
        if (success) {
            setMessage(mess);
            setSuccess(true);
            handleToggle();
            handSearch();
        } else {
            setMessage(mess);
            setSuccess(false);
        }
        setShowAlert(true);
    };
    const clickCapSo = (item: HoSoThuLyDiSanVanHoa, index: number) => {
        setDataRow(item);
        setIndexRow(index);
        if (item.className == "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa") {
            setShowModalChungNhan(true);
        } else {
            setShowModalChungChi(true);
        }
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>CẬP NHẬT SỐ</span>
            </div>
            <CapSoChungChiVanHoaDuLichModal indexEdit={indexRow} data={dataRow} onToggle={handleToggle} show={showModalChungChi} onSubmit={handleSubmit} />
            <CapSoChungNhanVanHoaDuLichModal indexEdit={indexRow} data={dataRow} onToggle={handleToggle} show={showModalChungNhan} onSubmit={handleSubmit} />
            <Snackbar open={showAlert} onClose={() => setShowAlert(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={3000}>
                <Alert onClose={() => setShowAlert(false)} severity={success ? "success" : "error"}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={4} className="gridItem">
                    <InputLabel>Tên tổ chức/ cá nhân</InputLabel>
                    <InputBase fullWidth name="tenToChuc" value={search.tenToChuc} onChange={setParamSearch} inputProps={{ "aria-label": "description" }} />
                </Grid>
                <Grid item xs={4} className="gridItem">
                    <InputLabel>Mã hồ sơ</InputLabel>
                    <InputBase fullWidth name="maHoSo" value={search.maHoSo} onChange={setParamSearch} inputProps={{ "aria-label": "description" }} />
                </Grid>
                <Grid item xs={4} className="gridItem" />
                <Grid item xs={4} className="gridItem">
                    <InputLabel>Loại giấy</InputLabel>
                    <NativeSelect value={search.className} name="className" onChange={setParamSearch} input={<InputBase fullWidth />}>
                        <option value="">Chọn giấy phép</option>
                        <option value="com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa">Giấy chứng nhận</option>
                        <option value="com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa">Giấy chứng chỉ</option>
                    </NativeSelect>
                </Grid>

                <Grid item xs={4} className="gridItem">
                    <InputLabel>Huyện/Thị/TP</InputLabel>
                    <Autocomplete
                        options={HUYEN}
                        getOptionLabel={(option) => option?.ten}
                        value={{ ma: search.maHuyen, ten: search.tenHuyen }}
                        onChange={(event: any, newValue: any) => {
                            if (newValue) {
                                setSearch({
                                    ...search,
                                    maHuyen: newValue.ma,
                                    tenHuyen: newValue.ten,
                                });
                            } else {
                                setSearch({
                                    ...search,
                                    maPhuong: "",
                                    tenPhuong: "",
                                });
                            }
                        }}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={4} className="gridItem">
                    <InputLabel>Phường/Xã/TT</InputLabel>
                    <Autocomplete
                        options={PHUONG}
                        getOptionLabel={(option) => option?.ten}
                        value={{ ma: search.maPhuong, ten: search.tenPhuong }}
                        onChange={(event: any, newValue: any) => {
                            if (newValue) {
                                setSearch({
                                    ...search,
                                    maPhuong: newValue.ma,
                                    tenPhuong: newValue.ten,
                                });
                            } else {
                                setSearch({
                                    ...search,
                                    maPhuong: "",
                                    tenPhuong: "",
                                });
                            }
                        }}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12} className="button-group-center">
                    <Button variant="contained" color="primary" disableElevation onClick={handSearch}>
                        Tìm kiếm
                    </Button>
                </Grid>

                <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">
                                    STT
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    MÃ HỒ SƠ
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    TÊN TỔ CHỨC/CÁ NHÂN
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="12%">
                                    SỐ CHỨNG NHẬN/CHỨNG CHỈ
                                </Cell>
                                <Cell component="th" scope="row" align="center">
                                    ĐỊA CHỈ
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="10%">
                                    Loại giấy
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="8%">
                                    THAO TÁC
                                </Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {total > 0 ? (
                                data.map((row, i) => (
                                    <Row key={i}>
                                        <Cell scope="row" align="center">
                                            {(search.pageNumber - 1) * search.pageSize + i + 1}
                                        </Cell>
                                        <Cell align="left">{row.maHoSo}</Cell>
                                        <Cell align="left">{row.tenToChuc}</Cell>
                                        <Cell align="left">
                                            {row.className == "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa" ? row.soChungNhan : row.soChungChi}
                                        </Cell>
                                        <Cell align="left">{row.diaChiDayDu}</Cell>
                                        <Cell align="left">
                                            {row.className == "com.fpt.egov.api.model.dsvh.GiayChungNhanDiSanVanHoa" ? "Giấy chứng nhận" : "Giấy chứng chỉ"}
                                        </Cell>
                                        <Cell align="center">
                                            <Box display="flex" justifyContent="center">
                                                <IconButton onClick={(event) => clickCapSo(row, i)} color="primary" aria-label="edit" size="small">
                                                    <EditIcon />
                                                </IconButton>
                                            </Box>
                                        </Cell>
                                    </Row>
                                ))
                            ) : (
                                <Row key={"empty"}>
                                    <Cell colSpan={7} scope="row" align="center">
                                        Không có dữ liệu
                                    </Cell>
                                </Row>
                            )}
                        </TableBody>
                    </Table>
                    <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                        <Pagination
                            count={Math.ceil(total / search.pageSize)}
                            page={search.pageNumber}
                            defaultPage={1}
                            siblingCount={1}
                            variant="outlined"
                            size="medium"
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            onChange={handleOnChangePage}
                        />
                        <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                            <Box component="span" paddingX="5px">
                                Số mẫu tin trên trang
                            </Box>
                            <NativeSelect input={<CustomInput />} onChange={handOnChangeRowsPerPage}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>20</option>
                            </NativeSelect>
                        </Box>
                        <Box component="span" paddingX="5px">
                            Mẫu tin {(search.pageNumber - 1) * search.pageSize + 1} - {search.pageNumber * search.pageSize} / {total}
                        </Box>
                    </Box>
                </TableContainer>
            </Grid>
        </div>
    );
};
export default CapSoDiSanGiayPhepComponent;
