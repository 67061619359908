import { Animation, Stack, EventTracker } from "@devexpress/dx-react-chart";
import {
    ArgumentAxis,
    BarSeries, Chart,
    Legend, ValueAxis, Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Box, Button, createStyles, Grid, InputLabel, makeStyles, TextField, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DashboardLuHanhDTO, DashboardLuHanhRequestDTO, ThongKeService } from "apis/LuHanhNoiDia/ThongKeService";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { bootstrapTextFieldHook } from "../../commons/input/bootstrap";
import { OverlayLoading } from "../../overlayLoading/overlayLoading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            "margin-top": " 22px !important;",
        },
    })
);

export const DashboardLuHanhComponent = () => {
    const classes = useStyles();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<DashboardLuHanhDTO[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const [search, setSeach] = useState<DashboardLuHanhRequestDTO>({
        namThongKe: new Date().getFullYear(),
        tenNamThongKe: "Năm " + new Date().getFullYear()
    } as DashboardLuHanhRequestDTO);
    const service = new ThongKeService();
    useEffect(() => {
        namThongKeList();
        loadDataTable(search);
    }, []);
    const loadDataTable = async (requestBody: DashboardLuHanhRequestDTO) => {
        dispatchLoading(showLoading());
        await service
            .loadDataDashboard(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setData(response.data.data);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ ma: 0, ten: "Chọn năm" }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { ma: namHienTai - i, ten: "Năm " + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { ma: namHienTai + i, ten: "Năm " + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    };
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ DOANH NGHIỆP LỮ HÀNH NỘI ĐỊA</span>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item" >
                    <InputLabel classes={labelStyles}>Năm </InputLabel>
                    <Autocomplete
                        options={dataNamThongKe}
                        getOptionLabel={(option) => option?.ten}
                        value={{ ten: search.tenNamThongKe, ma: search.namThongKe }}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "namThongKe", "tenNamThongKe")}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={9} className="grid-item" container justify="flex-end">
                    <Button
                        onClick={(e) => loadDataTable(search)}
                        type="button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.button}
                    >
                        Tổng hợp
                    </Button>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <Paper>
                        <Chart data={data}>
                            <ArgumentAxis />
                            <ValueAxis
                                labelComponent={(props) => {
                                    return Number.isInteger(Number(props.text)) ? <text {...props}>{Number(props.text)}</text> : <text {...props}></text>;
                                }}
                            />
                            <BarSeries name="Cấp mới" valueField="hoatDong" argumentField="tenThang" color="#009999" />
                            <BarSeries name="Thu hồi" valueField="thuHoi" argumentField="tenThang" color="#cc9900" />
                            <Animation />
                            <Legend
                                position="bottom"
                                rootComponent={(root: Legend.RootProps) => {
                                    return (
                                        <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                            {root.children}
                                        </Box>
                                    );
                                }}
                                itemComponent={(label: Legend.ItemProps) => {
                                    return (
                                        <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                            {label.children}
                                        </Box>
                                    );
                                }}
                            />
                            <Stack />
                            <EventTracker></EventTracker>
                            <Tooltip />
                        </Chart>
                    </Paper>
                </Grid>
            </Grid>
            <OverlayLoading />
        </div>
    );
};
