export interface GiayChungNhanDSVHRequest {

    soChungNhan: string,

    ngayCapChungNhanTu: number;

    ngayCapChungNhanDen: number;

    tenToChuc: string,

    maHuyen: string,

    tenHuyen: string,

    maPhuong: string,

    tenPhuong: string,

    tenNamThongKe: string,

    ngayHetHanTu: number;

    ngayHetHanDen: number;

    pageSize: number;

    pageNumber: number;

    namThongKe: number;

    loaiThongKe: number;

    kyThongKe: number;

    tenTrangThai: string;

    maTrangThai: string;

    keywords: string;

    classNameGCN: string;

    hoatDong: boolean;

    tenCuocThi: string;

    loaiBaoCao: number;

    index: number;
}

export const GiayChungNhanDSVHRequest_INIT: GiayChungNhanDSVHRequest = {

    soChungNhan: '',

    ngayCapChungNhanTu: 0,

    ngayCapChungNhanDen: 0,

    tenToChuc: '',

    maHuyen: '',

    tenHuyen: '',

    maPhuong: '',

    tenPhuong: '',

    tenNamThongKe: 'Năm ' + new Date().getUTCFullYear(),

    ngayHetHanTu: 0,

    ngayHetHanDen: 0,

    pageNumber: 1,

    pageSize: 5,

    loaiThongKe: 1,

    kyThongKe: 1,

    namThongKe: new Date().getUTCFullYear(),

    tenTrangThai: '',

    maTrangThai: '',

    keywords: '',

    classNameGCN : '',

    hoatDong: true,

    tenCuocThi: '',

    loaiBaoCao: 0,

    index: 0
}

