import MomentUtils from "@date-io/moment";
import { createStyles, Grid, InputLabel, makeStyles, TextField, Theme } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useLocation } from "react-router";
import AXIOS_INSTANCE from "../../../../../apis";
import { createFileType, downLoadFile } from "../../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../../apis/Common/FileServerService";
import { ACCEPT_FILES } from "../../../../../constants/DichVuDuLichConstants";
import { GiayChungNhanKinhDoanh_INIT } from "../../../../../init/DichVuDuLich/GiayChungNhanKinhDoanh_INIT";
import { LichSuDichVuDuLich_INIT } from "../../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { GiayChungNhanKinhDoanh } from "../../../../../model/DichVuDuLich/GiayChungNhanKinhDoanh";
import { LichSuDichVuDuLichDTO } from "../../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import StringUtil from "../../../../../utils/stringUtils";
import DropZoneCustom from "../../../../commons/hoso/CustomDropzone/DropZoneCustom";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";

interface giayChungChiProps {
    data: GiayChungNhanKinhDoanh;
    onUpdateData: (name: string, value: any) => void;
    submit: boolean;
    thuHoi: LichSuDichVuDuLichDTO;
}

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabel = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const ThongTinChungTamNgungGCNAnUongComponent = (props: giayChungChiProps) => {
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const rightStyle = useStylesLabelRight();
    const leftStyle = useStylesLabelLeft();
    const label = useStylesLabel();
    const [data, setData] = useState<GiayChungNhanKinhDoanh>({
        ...GiayChungNhanKinhDoanh_INIT,
        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
    });
    const [thuHoi, setThuHoi] = useState<LichSuDichVuDuLichDTO>({ ...LichSuDichVuDuLich_INIT });
    const fileServerService = new FileServerService();
    const { onUpdateData } = props;

    useEffect(() => {
        initData();
    }, [props.data, props.submit, props.thuHoi]);

    const initData = () => {
        setData(props.data);
        setThuHoi(props.thuHoi);
        setSubmit(props.submit);
    };
    //onchange
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    };
    const handleChange = (e: any) => {
        onUpdateData(e.target.name, e.target.value);
    };
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...thuHoi.files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...thuHoi.files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số quyết định</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.soQuyetDinh}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayCap != null ? moment(data?.ngayCap).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày hết hạn</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayHetHan != null ? moment(data?.ngayHetHan).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tình trạng</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.tenTrangThai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tên Cơ sở</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tenCoSo}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.diaChiDayDu}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Điện thoại</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.soDienThoai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Fax</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.fax}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Email</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.email}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Website</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.website}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số GCNĐKDN/HKD</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.soGCN}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayCapGCN != null ? moment(data?.ngayCapGCN).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Cơ quan cấp</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.coQuanCapGCN}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Nơi cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.noiCapGCN}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tổng số người quản lý và nhân viên</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tongSoNguoi}</InputLabel>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel classes={label} required>
                        Lý do tạm ngưng
                    </InputLabel>
                    <TextField
                        fullWidth
                        name="lyDo"
                        InputLabelProps={{ classes: labelStyles }}
                        value={thuHoi.lyDo}
                        InputProps={{
                            disableUnderline: true,
                            classes: inputStyles,
                        }}
                        onChange={handleChange}
                        error={submit && StringUtil.isNullOrEmty(thuHoi.lyDo)}
                        helperText={submit && StringUtil.isNullOrEmty(thuHoi.lyDo) ? "Vui lòng nhập lý do thu hồi" : ""}
                    />
                </Grid>
                {/* <Grid item xs={3} className="grid-item">
                    <InputLabel classes={label} required>
                        Số quyết định thu hồi
                    </InputLabel>
                    <TextField
                        fullWidth
                        name="soQuyetDinhThuHoi"
                        InputLabelProps={{ classes: labelStyles }}
                        value={thuHoi.soQuyetDinhThuHoi}
                        InputProps={{
                            disableUnderline: true,
                            classes: inputStyles,
                        }}
                        onChange={handleChange}
                        error={submit && StringUtil.isNullOrEmty(thuHoi.soQuyetDinhThuHoi)}
                        helperText={submit && StringUtil.isNullOrEmty(thuHoi.soQuyetDinhThuHoi) ? "Vui lòng nhập số quyết định thu hồi" : ""}
                    />
                </Grid> */}
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles} required>
                        Ngày tạm ngưng
                    </InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={thuHoi.ngayQuyetDinhThuHoi && thuHoi.ngayQuyetDinhThuHoi != 0 ? new Date(thuHoi.ngayQuyetDinhThuHoi) : null}
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhThuHoi")}
                            error={submit && StringUtil.isNullOrEmty(thuHoi.ngayQuyetDinhThuHoi)}
                            helperText={submit && StringUtil.isNullOrEmty(thuHoi.ngayQuyetDinhThuHoi) ? "Vui lòng nhập ngày thu hồi " : ""}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <InputLabel classes={label}>Ghi chú</InputLabel>
                    <TextField
                        fullWidth
                        name="ghiChu"
                        InputLabelProps={{ classes: labelStyles }}
                        value={thuHoi.ghiChu}
                        InputProps={{
                            disableUnderline: true,
                            classes: inputStyles,
                        }}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} container className="grid-item">
                    <DropZoneCustom
                        acceptFiles={ACCEPT_FILES}
                        attachFileServer={thuHoi.files}
                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                        handleChangeStatus={handleChangeStatus}
                        handleDeleteFileServer={handleDeleteFileServer}
                        handleDownloadFile={handleDownloadFile}
                    />
                </Grid>
            </Grid>
        </>
    );
};
export default ThongTinChungTamNgungGCNAnUongComponent;
