import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { BieuMauAttachFileDTO } from "apis/danhMucCN";
import InBieuFileThuLyButton from "../../../../LuHanhNoiDia/ThuLyHoSo/Action/InBieuFileThuLyButton";

interface GiayPhepXayDungButtonProps {
    onSubmit: () => void;
    onResetData: () => void;
    onOpenDialog: () => void;
    onDownloadFileBieuMau: (bieuMauAttachFileDTO: BieuMauAttachFileDTO) => void;
}

const VanHoaNgheThuatButton = (props: GiayPhepXayDungButtonProps) => {
    const { onSubmit, onResetData, onOpenDialog, onDownloadFileBieuMau } = props;
    const history = useHistory();
    return (
        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
            <Button onClick={onSubmit} variant="contained" color="primary" disableElevation style={{ margin: "0px 5px" }}>
                Lưu
            </Button>
            <InBieuFileThuLyButton onDownloadFileBieuMau={onDownloadFileBieuMau} />
            <Button variant="contained" color="secondary" onClick={onOpenDialog} disableElevation style={{ margin: "0px 5px" }}>
                {" "}
                Xóa
            </Button>
            <Button onClick={() => history.go(-1)} variant="contained" color="primary" disableElevation style={{ margin: "0px 5px" }}>
                {" "}
                Trở về
            </Button>
        </Grid>
    );
};
export default VanHoaNgheThuatButton;
