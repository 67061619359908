import MomentUtils from "@date-io/moment";
import { Button, Grid, InputLabel, Link, NativeSelect, SvgIcon, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createFileType, downLoadFile } from "../../../apis/Common/exportUtil";
import { DanhMucDiaChiDTO } from "../../../apis/danhMuc/model/DanhMucDTO";
import { DanhMucCapDiSanService } from "../../../apis/DiSanVanHoa/DanhMucCapDiSanService";
import { DanhMucLoaiDiSanService } from "../../../apis/danhMucCN/DanhMucLoaiDiSanService";
import { DiSanVanHoaVatTheService } from "../../../apis/DiSanVanHoa/DiSanVanHoaVatTheService";
import { bootstrapTextFieldHook } from "../../../components/commons/input/bootstrap";
import { CustomInput } from "../../../components/commons/input/customInput";
import { DanhMucCapDiSan, DanhMucLoaiDiSan } from "../../../model/DiSanVanHoa/DiSanVanHoaModel";
import { DiSanVanHoaVatThe, ThongKeDSVHVatThe, ThongKeDSVHVatTheResponse } from "../../../model/diSanVanHoaVatThe";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { ListThongKeDiSanVanHoaVatThe } from "./listThongKeDiSanVanHoaVatThe";
import { Autocomplete } from "@material-ui/lab";
import { DanhMucService } from "../../../apis/danhMuc/danhMucService";
import moment from "moment";
import { ChiTietThongKeVatTheDialog } from "./ChiTietThongKeVatTheDialog";

export interface ThongKeVTRequest {
    maHuyen: string;
    tenHuyen: string;
    kyThongKe: number;
    namQuyetDinh: number;
    tenNamQuyetDinh: string;
    ngayQuyetDinhTu: number;
    ngayQuyetDinhDen: number;
    exportType: string;
    exportFileName: string;
    pageSize: number;
    pageNumber: number;
}

export const ThongKeVTRequest_INT: ThongKeVTRequest = {
    kyThongKe: 1,
    namQuyetDinh: 0,
    tenNamQuyetDinh: '',
    ngayQuyetDinhTu: 0,
    ngayQuyetDinhDen: 0,
    exportType: "docx",
    exportFileName: "bao_cao_dsvh_vat_the",
    pageSize: 50,
    pageNumber: 1,
    maHuyen: '',
    tenHuyen: ''
};

export const ThongKeDiSanVanHoaVatThe = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [listDiSan, setListDiSan] = useState<ThongKeDSVHVatThe[]>([]);
    const [data, setData] = useState<ThongKeDSVHVatTheResponse>();
    const [listLoaiDiSan, setListLoaiDiSan] = useState<DanhMucLoaiDiSan[]>([]);
    const [listCapDiSan, setListCapDiSan] = useState<DanhMucCapDiSan[]>([]);
    const [thongKeVTRequest, setThongKeVTRequest] = useState<ThongKeVTRequest>({
        ...ThongKeVTRequest_INT,
    });
    const danhMucService = new DanhMucService();
    const [kyThongKeOption, setKyThongKeOption] = useState(1);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [showDate, setShowDate] = useState(true);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setThongKeVTRequest({ ...thongKeVTRequest, [e.target.name]: value });
    };
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataChiTiet, setDataChiTiet] = useState<DiSanVanHoaVatThe[]>([]);
    const [totalData, setTotalData] = useState(0);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...thongKeVTRequest, [filedName]: date?.toDate()?.getTime() };
        setThongKeVTRequest(dataNew);
    };

    const handleChangeKyThongKe = (event: any) => {
        const dataNew = {
            ...thongKeVTRequest,
            ["kyThongKe"]: event.target.value,
        };
        setThongKeVTRequest(dataNew);
        setShowDate(!showDate);
    };

    const loadListDiSan = (thongKeVTRequest: ThongKeVTRequest) => {
        dispatchLoading(showLoading());
        new DiSanVanHoaVatTheService()
            .thongKe(thongKeVTRequest)
            .then((p) => {
                setData(p);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const loadListLoaiDiSan = async () => {
        await new DanhMucLoaiDiSanService()
            .getAll()
            .then((p) => {
                setListLoaiDiSan(p.rows ? p.rows : []);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setThongKeVTRequest({
            ...thongKeVTRequest,
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const loadListCapDiSan = async () => {
        await new DanhMucCapDiSanService()
            .getAll()
            .then((p) => {
                setListCapDiSan(p.rows ? p.rows : []);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        //loadListLoaiDiSan();
        loadListCapDiSan();
        loadListDiSan({ ...thongKeVTRequest });
        loadQuans("92");
    }, []);

    const doSearch = () => {
        loadListDiSan({ ...thongKeVTRequest });
    };
    const reload = () => {
        let newValues = {...ThongKeVTRequest_INT};
        setThongKeVTRequest(newValues);
        loadListDiSan(newValues);
        setShowDate(true);
    }

    const downloadFile = async (extension: string) => {
        let request = {
            ...thongKeVTRequest,
            exportType: extension,
            exportFileName: "Thống_kê_DSVH_vật_thể",
        };
        dispatchLoading(showLoading());
        await new DiSanVanHoaVatTheService()
            .downloadFileThongKe(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "Thống_kê_DSVH_vật_thể");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const kyThongKeOptions = [
        { value: 1, title: "Năm" },
        { value: 2, title: "Khoảng thời gian" },
    ];

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...thongKeVTRequest, [nameValue]: value?.ma, [nameText]: valueText };
        setThongKeVTRequest(dataNew);
    };

    const nam = [
        { ma: 0, ten: "Chọn năm" },
        { ma: 2018, ten: "Năm 2018" },
        { ma: 2019, ten: "Năm 2019" },
        { ma: 2020, ten: "Năm 2020" },
        { ma: 2021, ten: "Năm 2021" },
    ];

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let thongKeDSVHRequestNew = { ...thongKeVTRequest, pageNumber: value };
        setThongKeVTRequest(thongKeDSVHRequestNew);
        loadListDiSan(thongKeDSVHRequestNew);
    };
    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchDSVHRequestNew = { ...thongKeVTRequest, pageSize: Number(event.target.value) };
        searchDSVHRequestNew.pageNumber = 1;
        setThongKeVTRequest(searchDSVHRequestNew);
        loadListDiSan(searchDSVHRequestNew);
    };

    const handleChiTiet = (index?: number, maHuyenString?: string) => {
        setOpenDialog(true);
        dispatchLoading(showLoading());
        new DiSanVanHoaVatTheService()
            .chiTietThongKe(maHuyenString, index, thongKeVTRequest.maHuyen, thongKeVTRequest.ngayQuyetDinhTu, thongKeVTRequest.ngayQuyetDinhDen, thongKeVTRequest.namQuyetDinh)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        setTotalData(response.data.data.total !== null && response.data.data.total !== undefined ? response.data.data.total : 0);
                        setDataChiTiet(response.data.data.data !== null && response.data.data.data !== undefined ? response.data.data.data : []);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    }

    return (
        <div className="dash-content">
        <Grid container>
            <div className="dash-content">
                <div className="dash-title">
                    <span>THỐNG KÊ DANH SÁCH DI SẢN VĂN HÓA VẬT THỂ TRÊN ĐỊA BÀN</span>
                </div>
            </div>
            <Grid item xs={12} container>
                {/* <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Kỳ thống kê</InputLabel>
                    <NativeSelect input={<CustomInput />} fullWidth onChange={handleChangeKyThongKe}>
                        {kyThongKeOptions.map((item, idx) => (
                            <option value={item.value}>{item.title}</option>
                        ))}
                    </NativeSelect>
                </Grid>
                {kyThongKeOption == 1 && (
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Năm</InputLabel>
                        <NativeSelect fullWidth input={<CustomInput />} value={thongKeVTRequest.namQuyetDinh} name="namQuyetDinh" onChange={handleChange}>
                            {nam.map((item, idx) => (
                                <option value={item.value}>{item.title}</option>
                            ))}
                        </NativeSelect>
                    </Grid>
                )}
                {kyThongKeOption != 1 && (
                    <Grid item xs={3} className="grid-item">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <InputLabel classes={labelStyles}>Từ ngày</InputLabel>
                            <KeyboardDatePicker
                                autoOk={true}
                                value={
                                    thongKeVTRequest.ngayQuyetDinhTu !== 0 && thongKeVTRequest.ngayQuyetDinhTu !== undefined
                                        ? new Date(thongKeVTRequest.ngayQuyetDinhTu)
                                        : null
                                }
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhTu")}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {kyThongKeOption != 1 && (
                    <Grid item xs={3} className="grid-item">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <InputLabel classes={labelStyles}>Đến ngày</InputLabel>
                            <KeyboardDatePicker
                                autoOk={true}
                                value={
                                    thongKeVTRequest.ngayQuyetDinhDen !== 0 && thongKeVTRequest.ngayQuyetDinhDen !== undefined
                                        ? new Date(thongKeVTRequest.ngayQuyetDinhDen)
                                        : null
                                }
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhDen")}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )} */}
                
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Kỳ thống kê</InputLabel>
                    <NativeSelect disableUnderline fullWidth name="kyThongKe" value={thongKeVTRequest.kyThongKe} onChange={handleChangeKyThongKe}>
                        <option value={1}>Năm</option>
                        <option value={2}>Khoảng thời gian</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={!showDate}>
                    <InputLabel classes={labelStyles}>Năm </InputLabel>
                    <Autocomplete
                        options={nam}
                        getOptionLabel={(option) => option?.ten}
                        value={{ ma: thongKeVTRequest.namQuyetDinh, ten: thongKeVTRequest.tenNamQuyetDinh }}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "namQuyetDinh", "tenNamQuyetDinh")}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={showDate}>
                    <InputLabel classes={labelStyles}>Từ ngày</InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={
                                thongKeVTRequest.ngayQuyetDinhTu !== 0 &&
                                thongKeVTRequest.ngayQuyetDinhTu &&
                                thongKeVTRequest.ngayQuyetDinhTu !== null &&
                                thongKeVTRequest.ngayQuyetDinhTu !== undefined
                                    ? new Date(thongKeVTRequest.ngayQuyetDinhTu)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhTu")}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} className="grid-item" hidden={showDate}>
                    <InputLabel classes={labelStyles}>Đến ngày</InputLabel>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            autoOk={true}
                            showTodayButton={true}
                            value={
                                thongKeVTRequest.ngayQuyetDinhDen !== 0 &&
                                thongKeVTRequest.ngayQuyetDinhDen &&
                                thongKeVTRequest.ngayQuyetDinhDen !== null &&
                                thongKeVTRequest.ngayQuyetDinhDen !== undefined
                                    ? new Date(thongKeVTRequest.ngayQuyetDinhDen)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputProps={{ disableUnderline: true }}
                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhDen")}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Huyện/Thị/TP</InputLabel>
                    <Autocomplete
                        id="maHuyen"
                        options={quans}
                        getOptionLabel={(option) => option?.name}
                        value={{ code: thongKeVTRequest.maHuyen, name: thongKeVTRequest.tenHuyen }}
                        onChange={(event: any, newValue: any) => {
                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
            </Grid>
            
            <Grid item xs={12} className="button-group-center">
                <Button variant="contained" color="primary" disableElevation onClick={doSearch}>
                    Tổng hợp
                </Button>
                <Button variant="contained" color="primary" disableElevation onClick={reload}>
                    Làm mới
                </Button>
            </Grid>
            <Grid item xs={12} className="grid-item" container justify="flex-end">
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("docx");
                    }}
                >
                    <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                        <path
                            fill="#007bff"
                            d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                        />
                    </SvgIcon>
                </Link>
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("pdf");
                    }}
                >
                    <SvgIcon fontSize="large">
                        <path
                            fill="#dc3545"
                            d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                        />
                    </SvgIcon>
                </Link>
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("xlsx");
                    }}
                >
                    <SvgIcon fontSize="large">
                        <path
                            fill="#28a745"
                            d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                        />
                    </SvgIcon>
                </Link>
                {openDialog && 
                    <ChiTietThongKeVatTheDialog 
                        dataList={dataChiTiet} 
                        onDialogClose={() => setOpenDialog(false)}
                        openDialog={openDialog}
                        pageProps={{
                            page: 1,
                            count: totalData,
                            rowsPerPage: 5,
                            onChangePage: handleOnChangePage,
                            onChangeRowsPerPage: handOnChangeRowsPerPage,
                        }}
                    />}
            </Grid>
            <Grid item xs={12} className="grid-item">
                <ListThongKeDiSanVanHoaVatThe dataList={data} handleChiTiet={handleChiTiet}/>
            </Grid>
        </Grid>
        </div>
    );
};
