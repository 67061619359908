import MomentUtils from "@date-io/moment";
import { Box, Button, Dialog, FormHelperText, Grid, InputBase, InputLabel } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DropZoneCustom from "../../../components/commons/hoso/CustomDropzone/DropZoneCustom";
import moment from "moment";
import { useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import AXIOS_INSTANCE from "../../../apis";
import { createFileType, downLoadFile } from "../../../apis/Common/exportUtil";
import { FileEntry } from "../../../apis/Common/fileEntry";
import { FileServerService } from "../../../apis/Common/FileServerService";
import { CapSoDiSanVanHoaService } from "../../../apis/DiSanVanHoa/CapSoDiSanVanHoaService";
import { ACCEPT_FILES, DUPLICATE } from "../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDiSanVanHoa_INIT } from "../../../init/DiSanVanHoa/HoSoThuLyDiSanVanHoa_INIT";
import { HoSoThuLyDiSanVanHoa } from "../../../model/hoSoThuLyDiSanVanHoa";
import StringUtil from "../../../utils/stringUtils";
import { ConfirmationDialogRaw } from "../../commons/dialog/confirmDialog";
import { DialogContent, DialogTitle } from "../../commons/dialog/dialogCommons";
interface CapSoDiSanVanHoaModalProps {
    data: HoSoThuLyDiSanVanHoa;
    show: boolean;
    indexEdit: number;
    onToggle: () => void;
    onSubmit: (success: boolean, mess: string) => void;
}
export const CapSoChungChiVanHoaDuLichModal = (props: CapSoDiSanVanHoaModalProps) => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [data, setData] = useState({ ...HoSoThuLyDiSanVanHoa_INIT } as HoSoThuLyDiSanVanHoa);
    const [show, setShow] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [indexEdit, setIndexEdit] = useState(-1);
    const { onToggle, onSubmit } = props;
    const capSoDiSanVanHoaService = new CapSoDiSanVanHoaService();
    const fileServerService = new FileServerService();
    useEffect(() => {
        const dataNew = {
            ...props.data,
            soChungChi: props.data.soChungChi ? props.data.soChungChi : props.data.soChungChiCu,
        };
        setData(dataNew);
        setShow(props.show);
        setIndexEdit(props.indexEdit);
        setSubmit(false);
    }, [props.data, props.show, props.indexEdit]);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";

        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };
    const reset = () => {
        setOpenConfirm(true);
    };
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        setData((data) => ({
            ...data,
            [filedName]: date?.toDate()?.getTime(),
        }));
    };
    const handleSubmit = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.soChungChi)) {
            return;
        }
        if (!data.ngayCapChungChi || data.ngayCapChungChi === undefined || data.ngayCapChungChi === 0) {
            return;
        }
        if (!data.ngayHetHan || data.ngayHetHan === undefined || data.ngayHetHan === 0) {
            return;
        }
        if (data.ngayCapChungChi > data.ngayHetHan) {
            onSubmit(false, "Ngày cấp hết hạn phải sau ngày cấp phép");
            return;
        }
        if (data.ngayCapChungChi > StringUtil.dateSecond()) {
            onSubmit(false, "Ngày cấp phép không được lớn hơn ngày hiện tại");
            return;
        }
        if (indexEdit >= 0) {
            capSoDiSanVanHoaService
                .capSo(data)
                .then((response) => {
                    if (response.data.error_code == "SUCCESSFUL") {
                        onSubmit(true, "Yêu cầu thực hiện thành công");
                    } else if (response.data.error_code == DUPLICATE) {
                        onSubmit(false, "Số giấy phép đã tồn tại");
                    } else {
                        onSubmit(false, "Yêu cầu thực hiện thất bại");
                    }
                })
                .catch((error) => {
                    onSubmit(false, "Yêu cầu thực hiện thất bại");
                    console.log(error);
                });
        }
    };
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        setData({ ...data, files: mems });
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        setData({ ...data, files: mems });
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            setData({
                ...data,
                soChungChi: "",
                ngayCapChungChi: 0,
                ngayHetHan: 0,
                ghiChu: "",
                nguoiKy: "",
            });
        }
        setOpenConfirm(false);
    };
    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show}>
                    <ConfirmationDialogRaw
                        id="confirm-delete"
                        keepMounted
                        open={openConfirm}
                        title="Xóa thông tin"
                        content="Bạn có đồng ý xóa thông tin đã nhập?"
                        onClose={handleCloseDeleteInfo}
                    />
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        CẬP NHẬT VĂN BẢN
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel required>Số Chứng chỉ </InputLabel>
                                    <InputBase
                                        fullWidth
                                        error={submit && StringUtil.isNullOrEmty(data.soChungChi)}
                                        value={data.soChungChi}
                                        name="soChungChi"
                                        onChange={handleChange}
                                    />
                                    {submit && StringUtil.isNullOrEmty(data.soChungChi) && (
                                        <FormHelperText className="-error">Vui lòng nhập số chứng chỉ</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel required>Ngày cấp phép </InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            value={
                                                data.ngayCapChungChi !== null && data.ngayCapChungChi !== undefined && data.ngayCapChungChi !== 0
                                                    ? new Date(data.ngayCapChungChi)
                                                    : null
                                            }
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            error={
                                                submit && (data.ngayCapChungChi === null || data.ngayCapChungChi === 0 || data.ngayCapChungChi === undefined)
                                            }
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungChi")}
                                        />
                                        {submit && (data.ngayCapChungChi === null || data.ngayCapChungChi === 0 || data.ngayCapChungChi === undefined) && (
                                            <FormHelperText className="-error">Vui lòng nhập ngày cấp chứng chỉ</FormHelperText>
                                        )}
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel required>Ngày hết hạn </InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            value={
                                                data.ngayHetHan !== null && data.ngayHetHan !== undefined && data.ngayHetHan !== 0
                                                    ? new Date(data.ngayHetHan)
                                                    : null
                                            }
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            error={submit && (data.ngayHetHan === null || data.ngayHetHan === 0 || data.ngayHetHan === undefined)}
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                        />
                                        {submit && (data.ngayHetHan === null || data.ngayHetHan === 0 || data.ngayHetHan === undefined) && (
                                            <FormHelperText className="-error">Vui lòng nhập ngày hết hạn</FormHelperText>
                                        )}
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel>Ghi chú </InputLabel>
                                    <InputBase fullWidth multiline rows="8" name="ghiChu" value={data.ghiChu} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel>Người ký </InputLabel>
                                    <InputBase fullWidth value={data.nguoiKy} name="nguoiKy" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/vhdl"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <Box marginTop="5px">
                                        <Button variant="contained" color="primary" disableElevation onClick={handleSubmit}>
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="secondary" disableElevation onClick={reset}>
                                            Xóa
                                        </Button>
                                        <Button variant="contained" color="default" disableElevation onClick={onToggle}>
                                            Đóng
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    );
};
