import AttachFileService2 from "apis/attachFileService/attachFileService2";
import { createFileType, downLoadFile } from "apis/Common/exportUtil";
import { FileEntry } from "apis/Common/fileEntry";
import { useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";

export const useContextDropZone = () => {
    // State
    const [attachFileServer, setAttachFileServer] = useState<FileEntry[]>([]);

    // Control
    function handleChangeStatus(file: IFileWithMeta) {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setAttachFileServer((prevState) => [...prevState, ...res.data]);
            file.remove();
        }
    }

    function handleDeleteFileServer(item: FileEntry) {
        let newValues = attachFileServer.map((file: FileEntry) => (file.fileEntryId === item.fileEntryId ? { ...file, isDeleted: true } : { ...file }));
        setAttachFileServer([...newValues]);
    }

    async function handleDownloadFile(file: FileEntry) {
        await new AttachFileService2().downloadFile(file).then((res) => {
            // let blob = new Blob([res.data], { type: res.data.type });
            // let url = window.URL.createObjectURL(blob);
            // let link = document.createElement("a");
            // link.href = url;
            // link.setAttribute("download", `${file.fileName}`);
            // document.body.appendChild(link);
            // link.click();
            // link.remove();
            // window.URL.revokeObjectURL(url);

            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    }

    function handleSetAttachFile(files: FileEntry[]) {
        setAttachFileServer(files);
    }

    return { attachFileServer, handleSetAttachFile, handleChangeStatus, handleDownloadFile, handleDeleteFileServer };
};
