import { FileEntry } from "../apis/Common/fileEntry";

export interface LichSuGiayChungNhan {
    hoSoThuLyId: number;
    
    nguoiTaoId: string;
    
    giayChungNhanId: number;
    
    maBienDong: string;
    
    tenBienDong: string;
    
    maTrangThai: string;
    
    tenTrangThai: string;
    
    ngayBienDong: number;
    
    soLanCapLai: number;
   
    lyDoCapLai: string;
    
    soGiayPhepCu: string;

    soGiayPhepMoi: string;
    
    ngayCapCu: number;
    
    ngayCapMoi: number;
    
    ngayHetHanCu: number;
    
    ngayHetHanMoi: number;
    
    ngayCapLai: number;

    ngayThuHoi: number;

    files: FileEntry[];
}

export const LichSuGiayChungNhan_INIT: LichSuGiayChungNhan = {
    hoSoThuLyId: 0,
    
    nguoiTaoId: '',
    
    giayChungNhanId: 0,
    
    maBienDong: '',
    
    tenBienDong: '',
    
    maTrangThai: '',
    
    tenTrangThai: '',
    
    ngayBienDong: 0,
    
    soLanCapLai: 0,
   
    lyDoCapLai: '',
    
    soGiayPhepCu: '',

    soGiayPhepMoi: '',
    
    ngayCapCu: 0,
    
    ngayCapMoi: 0,
    
    ngayHetHanCu: 0,
    
    ngayHetHanMoi: 0,
    
    ngayCapLai: 0,

    ngayThuHoi: 0,

    files: new Array<FileEntry>(),
}