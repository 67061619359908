import { Button, ClickAwayListener, Grow, Paper, Popper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useRef } from "react";
import { useHistory } from "react-router";
import { TINHTRANG_HOATDONG } from "../../../../constants/DichVuDuLichConstants";
import { GiayChungNhanKinhDoanhDichVuLuuTruDTO } from "../../../../model/DichVuDuLich/DichVuDuLichModel";
import { CHITIET_COSO_LUUTRU, NHAPDAUKY_COSOLUUTRU, ROUTE_CO_SO_LUUTRU_TAM_NGUNG } from "../../../../routes/route_dvdl";
import RefreshIcon from "@material-ui/icons/Refresh";

interface ThaoTacQuanLyCoSoLuuTruProps {
    data: GiayChungNhanKinhDoanhDichVuLuuTruDTO;
}
const ThaoTacQuanLyCoSoLuuTruComponent = (props: ThaoTacQuanLyCoSoLuuTruProps) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    let history = useHistory();
    const showHidePopper = async () => {
        setOpen(!open);
    };

    const handleClosePopper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const handleEdit = () => { 
        history.push(NHAPDAUKY_COSOLUUTRU.duongDan, props.data);
    };
    const handleView = () => {
        history.push(CHITIET_COSO_LUUTRU.duongDan, props.data);
    };
    const handleTamNgung = () => {
        history.push(ROUTE_CO_SO_LUUTRU_TAM_NGUNG.duongDan, { giayChungNhanId: props.data.giayChungNhanId, hoSoThuLyId: props.data.hoSoThuLyId });
    };

    return (
        <>
            <Button
                startIcon={<ListIcon />}
                size="small"
                color="primary"
                disableRipple
                aria-haspopup="true"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                onClick={(e) => {
                    showHidePopper();
                }}
            ></Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopper}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={(e) => setOpen(false)}>
                                    <MenuItem key={"menu-item-edit"} style={{ padding: "unset" }}>
                                        <Button onClick={(e) => handleEdit()} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                            Chỉnh sửa
                                        </Button>
                                    </MenuItem>
                                    <MenuItem key={"menu-item-tamngung"} style={{ padding: "unset" }} onClick={(e) => handleTamNgung()}>
                                        <Button startIcon={<RefreshIcon color="error" />} size="small" disableRipple>
                                        {props.data.maTrangThai === TINHTRANG_HOATDONG.ma ? "Tạm ngưng hoạt động" : "Chỉnh sửa tạm ngưng"}
                                        </Button>
                                    </MenuItem>
                                    <MenuItem key={"menu-item-view"} style={{ padding: "unset" }}>
                                        <Button
                                            startIcon={<VisibilityIcon style={{ color: "green" }} />}
                                            size="small"
                                            disableRipple
                                            onClick={(e) => handleView()}
                                        >
                                            Xem chi tiết
                                        </Button>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
export default ThaoTacQuanLyCoSoLuuTruComponent;
