import {Box, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from '@material-ui/core';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {Dispatch} from 'redux';
import GetAppIcon from "@material-ui/icons/GetApp";
import { hideLoading, LoadingAction, showLoading } from '../../../../redux/actions/applicationAction';
import { BieuMauAttachFileDTO } from 'apis/quanTri/model/danhMucBieuMau';
import { AttachIcon } from '../../../commons/attachFileTooltip/attachFileTooltip';
import { DanhMucBieuMauService } from 'apis/quanTri/danhMucBieuMauService';

interface InBieuMauButtonProps {
    valid?: boolean;
    data?: any;
    onDownloadFileBieuMau :  (bieuMauAttachFileDTO : BieuMauAttachFileDTO) => void;
}
const InBieuMauButton = (props: InBieuMauButtonProps) => {
    const location = useLocation();
    const [openInAn, setOpenInAn] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const danhMucBieuMauService = new DanhMucBieuMauService();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [bieuMauAttachFileDTOs, setBieuMauAttachFileDTOs] = React.useState<BieuMauAttachFileDTO[]>(new Array<BieuMauAttachFileDTO>());
    const handleCloseProper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenInAn(false);
    };
    const handleOpenInAn = () => {
        dispatchLoading(showLoading());
        danhMucBieuMauService.findDanhSachBieuMauByPath(location.pathname).then(response => {
            if (response != null) {
                if (response.data.error_code == 'SUCCESSFUL') {
                    setBieuMauAttachFileDTOs(response.data.data);
                }
            }
        }).catch(error => {

        }).finally(() => {
            dispatchLoading(hideLoading());
            setOpenInAn(!openInAn);
        });
    };
    return (
        <>
            <Button variant="contained" color="primary" disableElevation
                ref={anchorRef} aria-haspopup="true"
                aria-controls={openInAn ? 'menu-list-grow' : undefined} onClick={() => { handleOpenInAn() }}
            > In ấn</Button>
            <Popper open={openInAn} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseProper}>
                                <MenuList id="menu-list-grow" >
                                    {
                                        bieuMauAttachFileDTOs && bieuMauAttachFileDTOs.length > 0 ?
                                            bieuMauAttachFileDTOs.map((item, k) =>
                                                <MenuItem style={{ padding: 'unset' }} key={k}>
                                                    <Button
                                                        size="small"
                                                        // className={classes.buttonFile}
                                                        disableRipple
                                                        startIcon={<AttachIcon fileName={item.fileName} />}
                                                        style={{
                                                            display: "flex",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            width: "410px",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                        endIcon={<GetAppIcon color="primary" fontSize="small" />}
                                                        onClick={() => props.onDownloadFileBieuMau(item)}
                                                    >
                                                        {
                                                            item?.screenName ?
                                                                <Box component="span" flexGrow={1} textAlign="left" >{
                                                                    item?.screenName.length > 30 ? item.screenName.slice(0, 33) + "..." + item.screenName.slice(item.screenName.length - 10) : item.screenName}
                                                                </Box>
                                                                :
                                                                <Box component="span" flexGrow={1} textAlign="left" >{
                                                                    item.fileName.length > 30 ? item.fileName.slice(0, 33) + "..." + item.fileName.slice(item.fileName.length - 10) : item.fileName}
                                                                </Box>
                                                        }
                                                    </Button>
                                                </MenuItem>
                                            )
                                            : ""
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>


    )
}
export default InBieuMauButton;
