import MomentUtils from "@date-io/moment";
import { Box, Button, FormHelperText, Grid, InputBase, InputLabel, TextField, Typography, Snackbar } from "@material-ui/core";
import { Autocomplete, Alert, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useHistory } from "react-router-dom";
import { createFileType, downLoadFile } from "../../../apis/Common/exportUtil";
import { FileEntry } from "../../../apis/Common/fileEntry";
import { FileServerService } from "../../../apis/Common/FileServerService";
import { DanhMucService } from "../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../apis/danhMuc/model/DanhMucDTO";
import { DanhMucCapDiSanService } from "../../../apis/DiSanVanHoa/DanhMucCapDiSanService";
import { DanhMucLoaiDiSanService } from "../../../apis/danhMucCN/DanhMucLoaiDiSanService";
import { DiSanVanHoaVatTheService } from "../../../apis/DiSanVanHoa/DiSanVanHoaVatTheService";
import { AlertMessage } from "../../../components/commons/alert";
import { ConfirmationDialogRaw } from "../../../components/commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../components/commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../components/commons/tabs";
import { ACCEPT_FILES } from "../../../constants/DiSanVanHoaConstants";
import { DiSanVanHoaVatThe, DiSanVanHoaVatThe_INT } from "../../../model/diSanVanHoaVatThe";
import StringUtil from "../../../utils/stringUtils";
import AXIOS_INSTANCE from "../../../apis";
import DropZoneCustom from "../../../components/commons/hoso/CustomDropzone/DropZoneCustom";
interface TabPanelProps {
    children?: React.ReactNode;
    index?: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
type DanhMuc = {
    code: string;
    name: string;
};
export const AddOrEditDiSanVanHoaVatThe = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [indexTab, setIndexTab] = useState(0);
    const [data, setData] = useState<DiSanVanHoaVatThe>({ ...DiSanVanHoaVatThe_INT });
    const [listLoaiDiSan, setListLoaiDiSan] = useState<DanhMuc[]>([]);
    const [listCapDiSan, setListCapDiSan] = useState<DanhMuc[]>([]);
    const [submit, setSubmit] = useState(false);
    const history = useHistory<any>();
    const fileServerService = new FileServerService();
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [currentDate, setCurrentDate] = useState(0);
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });

    useEffect(() => {
        let id = history?.location?.state?.params;
        if (id != null) getDetailById(id);
        loadListCapDiSan();
        loadListLoaiDiSan();
        loadQuans("92");
        setCurrentDate(StringUtil.dateSecond());
    }, []);
    
    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadPhuongs = () => {
        if(data?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(data?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };
    
    const loadListLoaiDiSan = async () => {
        await new DanhMucLoaiDiSanService()
            .getAll()
            .then((p) => {
                setListLoaiDiSan(p.rows ? p.rows : []);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const loadListCapDiSan = async () => {
        await new DanhMucCapDiSanService()
            .getAll()
            .then((p) => {
                setListCapDiSan(p.rows ? p.rows : []);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getDetailById = async (id: number) => {
        await new DiSanVanHoaVatTheService()
            .getDetailById(id)
            .then((p) => {
                setData(p);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => { };
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setData({ ...data, [e.target.name]: value });
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        setData(dataNew);
    };

    const handleChangeSelectLoaiDiSan = (value: any, id: string, name: string) => {
        let dataNew = { ...data, [id]: value?.id, [name]: value?.name };
        setData(dataNew);
    };

    const handleChangeSelectCapDiSan = (value: any, id: string, name: string) => {
        let dataNew = { ...data, [id]: value?.id, [name]: value?.name };
        setData(dataNew);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...data, [filedName]: date?.toDate()?.getTime() };
        setData(dataNew);
    };

    const onResetData = () => {
        setData({ ...DiSanVanHoaVatThe_INT, id: data.id });
    };

    const submitData = async () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.soQuyetDinh)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenDiSan)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenLoaiDiSan)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.ngayQuyetDinh)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenHuyen)) {
            return;
        }
        if (data.ngayQuyetDinh > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày quyết định không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        await new DiSanVanHoaVatTheService()
            .insertOrUpdate(data)
            .then((res) => {
                if (res.data.error_code === "SUCCESSFUL") {
                    history.push("/di-san-van-hoa-vat-the/tra-cuu", { errorCode: res.data.error_code });
                } else {
                    showMessageAlert("Yêu cầu thực hiện thất bại", false);
                }
            })
            .catch((error) => {
                showMessageAlert("Yêu cầu thực hiện thất bại", false);
            });
        //history.go(-1);
    };
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };

    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };
    // ---------------------------------

    const [openResetFormDlg, setOpenResetFormDlg] = useState(false);

    const handleOpenResetForm = () => {
        setOpenResetFormDlg(true);
    }

    const handleCloseResetForm = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            onResetData();
        }
        setOpenResetFormDlg(false);
    };

    return (
        <div className="dash-content">
        <Grid container>
            <Snackbar
                open={alert.showAlert}
                onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                    {alert.message}
                </Alert>
            </Snackbar>
            <div className="dash-content">
                <div className="dash-title">
                    <span>NHẬP ĐẦU KỲ DI SẢN VĂN HÓA VẬT THỂ</span>
                </div>
            </div>
            <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={indexTab} onChange={handleChangeTab}>
                        <CustomTab label="Thông tin chung" />
                    </CustomTabs>
                </Box>
            </Grid>
            <TabPanel value={indexTab} index={0}>
                <Grid item xs={12} container>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} required>
                            Số quyết định{" "}
                        </InputLabel>
                        <InputBase
                            classes={inputStyles}
                            fullWidth
                            error={submit && StringUtil.isNullOrEmty(data.soQuyetDinh)}
                            value={data.soQuyetDinh}
                            name="soQuyetDinh"
                            onChange={handleChange}
                        />
                        {submit && StringUtil.isNullOrEmty(data.soQuyetDinh) && <FormHelperText className="-error">Vui lòng nhập số quyết định</FormHelperText>}
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <InputLabel classes={labelStyles} required>Ngày quyết định</InputLabel>
                            <KeyboardDatePicker
                                autoOk={true}
                                value={data.ngayQuyetDinh !== 0 && data.ngayQuyetDinh !== undefined ? new Date(data.ngayQuyetDinh) : null}
                                format="DD/MM/YYYY"
                                fullWidth
                                error={submit && StringUtil.isNullOrEmty(data.ngayQuyetDinh)}
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinh")}
                            />
                            {submit && StringUtil.isNullOrEmty(data.ngayQuyetDinh) && <FormHelperText className="-error">Vui lòng nhập ngày quyết định</FormHelperText>}
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} required>
                            Cấp di sản{" "}
                        </InputLabel>
                        <Autocomplete
                            classes={inputStyles}
                            id="tenLoaiDiSan"
                            noOptionsText={"Không có dữ liệu!!!"}
                            options={listCapDiSan}
                            value={{ code: data.capDiSanId, name: data.tenCapDiSan }}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => handleChangeSelectCapDiSan(newValue, "capDiSanId", "tenCapDiSan")}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    error={submit && StringUtil.isNullOrEmty(data.capDiSanId)}
                                    helperText={submit && StringUtil.isNullOrEmty(data.capDiSanId) ? "Vui lòng chọn cấp di sản" : ""}
                                />}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} required>
                            Loại di sản văn hóa{" "}
                        </InputLabel>
                        <Autocomplete
                            classes={inputStyles}
                            id="tenLoaiDiSan"
                            noOptionsText={"Không có dữ liệu!!!"}
                            options={listLoaiDiSan}
                            value={{ code: data.loaiDiSanId, name: data.tenLoaiDiSan }}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => handleChangeSelectLoaiDiSan(newValue, "loaiDiSanId", "tenLoaiDiSan")}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    error={submit && StringUtil.isNullOrEmty(data.loaiDiSanId)}
                                    helperText={submit && StringUtil.isNullOrEmty(data.loaiDiSanId) ? "Vui lòng chọn loại di sản" : ""}
                                />}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item"></Grid>
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <InputLabel classes={labelStyles} required>
                        Tên di sản{" "}
                    </InputLabel>
                    <InputBase
                        classes={inputStyles}
                        fullWidth
                        error={submit && StringUtil.isNullOrEmty(data.tenDiSan)}
                        value={data.tenDiSan}
                        name="tenDiSan"
                        onChange={handleChange}
                    />
                    {submit && StringUtil.isNullOrEmty(data.tenDiSan) && <FormHelperText className="-error">Vui lòng nhập tên di sản</FormHelperText>}
                </Grid>
                <Grid item xs={12} className="grid-item">
                    <InputLabel classes={labelStyles}>Xã/ Huyện khi xếp hạng </InputLabel>
                    <InputBase classes={inputStyles} fullWidth value={data.diaChiKhiXepHang} name="diaChiKhiXepHang" onChange={handleChange} />
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles} required>Huyện/Thị/TP</InputLabel>
                        <Autocomplete
                            classes={inputStyles}
                            id="maHuyenThiTp"
                            noOptionsText={"Không có Huyện/Thị/TP"}
                            options={quans}
                            value={{ code: data.maHuyen, name: data.tenHuyen }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                    helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn Huyện/Thị/TP" : ""}
                                />}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Phường/Xã/TT</InputLabel>
                        <Autocomplete
                            classes={inputStyles}
                            id="maPhuongXaTT"
                            options={phuongs}
                            noOptionsText={"Không có Phường/Xã/TT"}
                            onFocus={loadPhuongs}
                            value={{ code: data.maPhuong, name: data.tenPhuong }}
                            getOptionLabel={(option) => option?.name}
                            onChange={(event: any, newValue: any) => {
                                handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <TextField
                            fullWidth
                            label={"Đường"}
                            name="tenDuong"
                            InputLabelProps={{ classes: labelStyles }}
                            value={data.tenDuong}
                            onChange={handleChange}
                            InputProps={{
                                disableUnderline: true,
                                classes: inputStyles,
                            }}
                        />
                        {/* <InputLabel classes={labelStyles}>Đường</InputLabel>
                        <Autocomplete
                            classes={inputStyles}
                            id="maDuong"
                            options={DUONG}
                            value={{ ma: data.maDuong, ten: data.tenDuong }}
                            getOptionLabel={(option) => option?.ten}
                            onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "maDuong", "tenDuong")}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        /> */}
                    </Grid>
                    <Grid item xs={3} className="grid-item">
                        <InputLabel classes={labelStyles}>Số nhà </InputLabel>
                        <InputBase classes={inputStyles} fullWidth value={data.soNha} name="soNha" onChange={handleChange} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container className="grid-item">
                    <DropZoneCustom
                        acceptFiles={ACCEPT_FILES}
                        attachFileServer={data.files}
                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                        handleChangeStatus={handleChangeStatus}
                        handleDeleteFileServer={handleDeleteFileServer}
                        handleDownloadFile={handleDownloadFile}
                    />
                </Grid>
                <Grid item xs={12} className="gridItem">
                    <Box marginTop="5px">
                        <Button variant="contained" color="primary" onClick={submitData} disableElevation className="button">
                            Lưu
                        </Button>
                        &nbsp;&nbsp;
                        <Button variant="contained" color="secondary" onClick={handleOpenResetForm} disableElevation className="button">
                            Xóa
                        </Button>
                        <ConfirmationDialogRaw id="confirm-dialog-reset-form" keepMounted open={openResetFormDlg}
                            title="Xóa thông tin"
                            content="Bạn có đồng ý xóa thông tin đã nhập?"
                            onClose={handleCloseResetForm}
                        />
                        &nbsp;&nbsp;
                        <Button variant="contained" color="default" disableElevation className="button" onClick={() => history.go(-1)}>
                            Trở về
                        </Button>
                        &nbsp;&nbsp;
                    </Box>
                </Grid>
            </TabPanel>
        </Grid>
        </div>
    );
};
