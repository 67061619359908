import MomentUtils from "@date-io/moment";
import { Box, Checkbox, createStyles, FormHelperText, Grid, InputLabel, makeStyles, Snackbar, TextField, Theme, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../../apis";
import { createFileType, downLoadFile } from "../../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../../apis/Common/FileServerService";
import { DanhMucDanTocService } from "../../../../../apis/danhMuc/danhMucDanTocService";
import { DanhMucQuocGiaService } from "../../../../../apis/danhMuc/danhMucQuocGiaService";
import { DanhMucService } from "../../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO, DanhMucDungChungDTO, INIT_DanhMucDungChungDTO } from "../../../../../apis/danhMuc/model/DanhMucDTO";
import { GiayChungChiDiSanVanHoaService } from "../../../../../apis/DiSanVanHoa/GiayChungChiDiSanVanHoaService";
import { HoSoThuLyDiSanVanHoaService } from "../../../../../apis/DiSanVanHoa/HoSoThuLyDiSanVanHoaService";
import { HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import { ThongTinDungChungService } from "apis/hoSoCuaToi/thongTinDungChungService";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import { ACCEPT_FILES, LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG, TINHTRANG_THUHOI } from "../../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDiSanVanHoa_INIT } from "../../../../../init/DiSanVanHoa/HoSoThuLyDiSanVanHoa_INIT";
import { HoSoThuLyDiSanVanHoa } from "../../../../../model/hoSoThuLyDiSanVanHoa";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import StringUtil from "../../../../../utils/stringUtils";
import { ThongTinChuyenNhanhUtil } from "../../../../../utils/thongTinChuyenNhanhUtil";
import { ConfirmationDialogRaw } from "../../../../commons/dialog/confirmDialog";
import DanhSachDuThaoModal from "../../../../commons/hoso/thuly/danhSachDuThaoModal";
import ThuLyButtonComponent from "../../../../commons/hoso/thuly/ThuLyButtonComponent";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import DiSanVanHoaButtonComponent from "../../Action/DiSanVanHoaButtonComponent";
import NhapDauKyGiayChungChiButton from "../../Action/NhapDauKyGiayChungChiButton";
import EsbService from "../../../../../apis/quanTri/EsbService";
import DropZoneCustom from "../../../../commons/hoso/CustomDropzone/DropZoneCustom";
import { TINH_TP } from "../../../../../apis/quanTri/danhMucFakeService";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CapMoiTuBoDiTichComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [showChuyen, setShowChuyen] = useState(false);
    const [currentDate, setCurrentDate] = useState(0);
    const [data, setData] = useState<HoSoThuLyDiSanVanHoa>({ ...HoSoThuLyDiSanVanHoa_INIT, className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa" });
    const classes = useStyles();
    const hoSoThuLyDiSanVanHoaService = new HoSoThuLyDiSanVanHoaService();
    const fileServerService = new FileServerService();
    const giayChungChiDiSanVanHoaService = new GiayChungChiDiSanVanHoaService();
    const esbService = new EsbService();
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [quocTichs, setQuocTichs] = useState<DanhMucDungChungDTO[]>([]);
    const thongTinDungChungService = new ThongTinDungChungService();
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        initData();
        loadQuans("92");
    }, []);

    const layThongTinCongDan = () => {
        dispatchLoading(showLoading());
        esbService
            .layThongTinCongDan(data.soCMND)
            .then((res) => {
                if (res && res.data && res.data.data && res.data.data.soDinhDanh) {
                    const dataNew: HoSoThuLyDiSanVanHoa = {
                        ...data,
                        ngaySinh: res.data.data?.ngaySinh,
                        hoTen: res.data.data?.hoVaTen,
                    };
                    setData(dataNew);
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Định danh công dân không tồn tại trong cơ sở dữ liệu",
                        severity: "warning",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const initData = async () => {
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        let nhapDauKy = location?.state?.isNhapDauKy;
        let update = location?.state?.isUpdate;
        let giayChungChiId = location.state?.giayChungChiId;
        let show = location.state?.showChuyen;
        setShowChuyen(show);
        setIsNhapDauKy(nhapDauKy);
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        let quocTichs =  await new DanhMucQuocGiaService().findAll();
        setQuocTichs(quocTichs);

        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        hoSoCuaToiMgtService.chiTiet(location.state?.maHoSo).then((p) => {
            if (p.data && p.data.data) {
                hoTenNguoiNop = p.data.data.nguoiNop;
                soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });

        if (update) {
            
            await giayChungChiDiSanVanHoaService
                .getBySoGiayChungChiDiSanVanHoas(giayChungChiId)
                .then((res) => {
                    const dataNew = {
                        ...res?.data?.data,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
                        isUpdate: update,
                        resoucePK: res?.data?.data.id,
                        id: res?.data?.data.hoSoThuLyId,
                        maTinh: "92",
                        tenTinh: "Thành phố Cần Thơ",
                    };
                    setData(dataNew);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else {
            await hoSoThuLyDiSanVanHoaService
                .getByMaHoSos(maHS)
                .then(async(res) => {
                    if (StringUtil.hasData(res)) {
                        const dataNew = {
                            ...res?.data?.data,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
                        };
                        setData(dataNew);
                    } else {
                        let maHuyen ="", tenHuyen = "", maPhuong = "", tenPhuong = "",tenQuocTich = "", maQuocTich = "", noiCapCM = "";
                        let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHS);
                        
                        if(!StringUtil.isNullOrEmty(dataEform?.quanhuyen)){
                            let donViHanhChinh  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                            maHuyen = donViHanhChinh?.code;
                            tenHuyen = donViHanhChinh?.name;
                        }
                        if(!StringUtil.isNullOrEmty(dataEform?.xaphuong)){
                            let donViHanhChinhCap3  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                            maPhuong = donViHanhChinhCap3?.code;
                            tenPhuong = donViHanhChinhCap3?.name;
                        }
                        if(!StringUtil.isNullOrEmty(dataEform?.quoctich)){
                            let donViHanhChinhCap3  =  await new DanhMucQuocGiaService().findByCode(dataEform?.quoctich);
                            maQuocTich = donViHanhChinhCap3?.code;
                            tenQuocTich = donViHanhChinhCap3?.name;
                        }
                        if (!StringUtil.isNullOrEmty(dataEform?.noicapcmnd)) {
                            let noiCap  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.noicapcmnd);
                            noiCapCM = noiCap?.name;
                        }
                        const dataNew = {
                            ...HoSoThuLyDiSanVanHoa_INIT,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
                            nhapDauKy: nhapDauKy,
                            maTinh: "92",
                            tenTinh: "Thành phố Cần Thơ",
                            soCMND :  dataEform?.cmnd ? dataEform?.cmnd : HoSoThuLyDiSanVanHoa_INIT.soCMND,
                            ngayCapCMND : dataEform?.ngaycapcmnd ? StringUtil.stringDateToNumber(dataEform?.ngaycapcmnd) : HoSoThuLyDiSanVanHoa_INIT.ngayCapCMND,
                            noiCapCMND :  dataEform?.noicapcmnd ? dataEform?.noicapcmnd :  HoSoThuLyDiSanVanHoa_INIT?.noiCapCMND,
                            ngaySinh : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaythangsinh) : HoSoThuLyDiSanVanHoa_INIT.ngaySinh,
                            hoTen :  hoTenNguoiNop != "" ? hoTenNguoiNop : dataEform?.hovaten ? dataEform?.hovaten : HoSoThuLyDiSanVanHoa_INIT.hoTen,
                            soNha: dataEform?.hokhauthuongtru,
                            maQuocTich: !StringUtil.isNullOrEmty(maQuocTich) ? maQuocTich :  HoSoThuLyDiSanVanHoa_INIT?.maQuocTich,
                            tenQuocTich: !StringUtil.isNullOrEmty(tenQuocTich) ? tenQuocTich :  HoSoThuLyDiSanVanHoa_INIT?.tenQuocTich,
                        };
                        setData(dataNew);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        }
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(data.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    const handleSubmit = async () => {
        setTabRequired(-1);
        setSubmit(true);
        if (data.donViCapSoGiayPhep) {
            if (StringUtil.isNullOrEmty(data.soChungChi)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.ngayCapChungChi)) {
                return;
            }
        }
        if (isNhapDauKy || data.maHoSo === null || data.maHoSo === undefined || data.maHoSo === "") {
            if (StringUtil.isNullOrEmty(data.soChungChi)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.ngayCapChungChi)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.ngayHetHan)) {
                return;
            }
        }
        if (StringUtil.isNullOrEmty(data.soCMND)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.hoTen)) {
            return;
        }
        // if (StringUtil.isNullOrEmty(data.maTinh)) {
        //     return;
        // }
        if (StringUtil.isNullOrEmty(data.maHuyen)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuong)) {
            return;
        }
        if ((!StringUtil.isNullOrEmty(data.soCMND) && data.soCMND.length < 9) || data.soCMND.length <= 0 || data.soCMND.length > 12) {
            setAlert({
                showAlert: true,
                message: "Số CMND phải từ 9 số hoặc 12 số",
                severity: "warning",
            });
            return;
        }
        if (data.ngayCapChungChi > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (data.ngayCapCMND > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp CMND không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (data.ngaySinh > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày sinh không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHoSo) && !(isNhapDauKy || data.isUpdate)) {
            setAlert({
                showAlert: true,
                message: "Không có mã hồ sơ",
                severity: "warning",
            });
            return;
        }

        // if(isNhapDauKy && data.lanCapLai <= 0){
        //     return;
        // }
        setSubmit(false);
        dispatchLoading(showLoading());
        await hoSoThuLyDiSanVanHoaService
            .insertOrUpdate(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setData({
                        ...data,
                        id: response.data.data.id,
                        resoucePK: response.data.data.resoucePK,
                        ngayTao: response.data.data.ngayTao,
                    });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else if (response.data.error_code === "409") {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: valueText,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleChangeTrangThai = (e: any) => {
        let tenTrangThai = "";
        if (e.target.value === TINHTRANG_HOATDONG.ma) {
            tenTrangThai = TINHTRANG_HOATDONG.ten;
        } else if (e.target.value === TINHTRANG_THUHOI.ma) {
            tenTrangThai = TINHTRANG_THUHOI.ten;
        }
        setData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
            ["tenTrangThai"]: tenTrangThai,
        }));
    };

    const handleResetData = () => {
        const dataNew = {
            ...HoSoThuLyDiSanVanHoa_INIT,
            id: data.id,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            resoucePK: data.resoucePK,
            className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
            maTinh: "92",
            tenTinh: "Thành phố Cần Thơ",
            // soChungChi: data.soChungChi,
            // ngayCapChungChi: data.ngayCapChungChi,
            // ngayHetHan: data.ngayHetHan
        };
        setData(dataNew);
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {};
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);

            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        dispatchLoading(showLoading());
        giayChungChiDiSanVanHoaService
            .downloadFileBieuMauGiayChungChi({ templateId: bieuMau.fileId, GiayChungChiDiSanVanHoa: data.resoucePK })
            .then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadPhuongs = () => {
        if(data?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(data?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    const handleChangeSelectDanToc = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    async function handleSearchCMND() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinCongDan(data?.soCMND)
        .then((res) => { 
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.soCMND) {
                let isExist = false;
                if(!StringUtil.isNullOrEmty(res?.data?.data?.data?.maQuocTich) && !StringUtil.isNullOrEmty(res?.data?.data?.data?.tenQuocTich)){
                     isExist = true;   
                }
                setData({ ...data,
                    ngayCapCMND: res?.data?.data?.data?.cmt_NgayCap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.cmt_NgayCap) : data?.ngayCapCMND,
                    ngaySinh: res?.data?.data?.data?.ngayThangNamSinh ? StringUtil.formatNgaySinh(res?.data?.data?.data?.ngayThangNamSinh) : data?.ngaySinh,
                    hoTen : res?.data?.data?.data?.tenCongDan,
                    noiCapCMND : res?.data?.data?.data?.cmt_NoiCap,
                    maQuocTich : isExist ? res?.data?.data?.data?.maQuocTich : data.maQuocTich,
                    tenQuocTich : isExist ? res?.data?.data?.data?.tenQuocTich : data.tenQuocTich,
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số căn cước/CMND không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container item xs={12} className="grid-item">
                        {!isNhapDauKy && data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" && (
                            <div className="dash-title">
                                <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>CẤP CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH- MÃ HỒ SƠ {data.maHoSo}</h6>
                            </div>
                        )}
                        {(isNhapDauKy || data.maHoSo === null || data.maHoSo === undefined || data.maHoSo === "") && (
                            <div className="dash-title">
                                <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>NHẬP ĐẦU KỲ CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH</h6>
                            </div>
                        )}
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {!isNhapDauKy && (
                                            <>
                                                {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                                    <Grid item xs={3} className="grid-item">
                                                        <Checkbox
                                                            checked={data.donViCapSoGiayPhep}
                                                            name="donViCapSoGiayPhep"
                                                            value={data.donViCapSoGiayPhep}
                                                            onChange={handleChange}
                                                        />{" "}
                                                        Đơn vị cấp số giấy phép
                                                    </Grid>
                                                ) : (
                                                    ``
                                                )}
                                            </>
                                        )}
                                        {isNhapDauKy && (
                                            <>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Số giấy chứng chỉ
                                                    </InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        name="soChungChi"
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        value={data.soChungChi}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                        onChange={handleChange}
                                                        error={submit && StringUtil.isNullOrEmty(data.soChungChi)}
                                                        // helperText={submit && StringUtil.isNullOrEmty(data.soChungChi) ? "Vui lòng nhập số chứng nhận" : ""}
                                                        helperText={submit && StringUtil.isNullOrEmty(data.soChungChi) ? "Vui lòng nhập số chứng chỉ" : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Ngày cấp
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayCapChungChi && data.ngayCapChungChi != 0 ? new Date(data.ngayCapChungChi) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungChi")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayCapChungChi)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.ngayCapChungChi) ? "Vui lòng nhập ngày cấp" : ""}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Ngày hết hạn
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayHetHan)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.ngayHetHan) ? "Vui lòng nhập ngày hết hạn" : ""}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </>
                                        )}
                                        {data.isUpdate && StringUtil.isNullOrEmty(data?.maHoSo) && (
                                            <>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Số giấy chứng chỉ
                                                    </InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        name="soChungChi"
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        value={data.soChungChi}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                        onChange={handleChange}
                                                        error={submit && StringUtil.isNullOrEmty(data.soChungChi) && data.donViCapSoGiayPhep}
                                                        // helperText={
                                                        //     submit && StringUtil.isNullOrEmty(data.soChungChi) && data.donViCapSoGiayPhep
                                                        //         ? "Vui lòng nhập số chứng nhận"
                                                        //         : ""
                                                        // }
                                                        helperText={submit && StringUtil.isNullOrEmty(data.soChungChi) && data.donViCapSoGiayPhep ? "Vui lòng nhập số chứng chỉ" : ""}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Ngày cấp
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayCapChungChi && data.ngayCapChungChi != 0 ? new Date(data.ngayCapChungChi) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungChi")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayCapChungChi) && data.donViCapSoGiayPhep}
                                                            helperText={
                                                                submit && StringUtil.isNullOrEmty(data.ngayCapChungChi) && data.donViCapSoGiayPhep
                                                                    ? "Vui lòng nhập ngày"
                                                                    : ""
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </>
                                        )}

                                        {data.donViCapSoGiayPhep && !isNhapDauKy && (
                                            <>
                                                <Grid item xs={isNhapDauKy ? 4 : 3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Số giấy chứng chỉ
                                                    </InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        name="soChungChi"
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        value={data.soChungChi}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                        onChange={handleChange}
                                                        error={submit && StringUtil.isNullOrEmty(data.soChungChi) && data.donViCapSoGiayPhep}
                                                        helperText={
                                                            submit && StringUtil.isNullOrEmty(data.soChungChi) && data.donViCapSoGiayPhep
                                                                ? "Vui lòng nhập số chứng nhận"
                                                                : ""
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={isNhapDauKy ? 4 : 3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required>
                                                        Ngày cấp
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayCapChungChi && data.ngayCapChungChi != 0 ? new Date(data.ngayCapChungChi) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapChungChi")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayCapChungChi) && data.donViCapSoGiayPhep}
                                                            helperText={
                                                                submit && StringUtil.isNullOrEmty(data.ngayCapChungChi) && data.donViCapSoGiayPhep
                                                                    ? "Vui lòng nhập ngày"
                                                                    : ""
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={isNhapDauKy ? 4 : 3} className="grid-item">
                                                    <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Số CMND/ Số định danh
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        style={{ width: "90%" }}
                                        name="soCMND"
                                        type="number"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soCMND}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.soCMND)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.soCMND) ? "Vui lòng nhập Số CMND/ Số định danh" : ""}
                                    />
                                    <SearchIcon
                                        onClick={handleSearchCMND}
                                        fontSize="small"
                                        style={{ cursor: "pointer", marginTop: "15PX", marginLeft: "3px" }}
                                    />
                                    <FormHelperText>Định danh ví dụ : 100014869515</FormHelperText>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Họ tên
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        required
                                        name="hoTen"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.hoTen}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.hoTen)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.hoTen) ? "Vui lòng nhập Họ tên" : ""}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày sinh</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngaySinh && data.ngaySinh != 0 ? new Date(data.ngaySinh) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngaySinh")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapCMND && data.ngayCapCMND !== 0 ? new Date(data.ngayCapCMND) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapCMND")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Nơi cấp</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="noiCapCMND"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.noiCapCMND}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Nơi sinh</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="noiSinh"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.noiSinh}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Quốc tịch</InputLabel>
                                    <Autocomplete
                                        id="quocTich"
                                        options={quocTichs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maQuocTich, name: data.tenQuocTich }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectDanToc(newValue, "maQuocTich", "tenQuocTich");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Tỉnh/Thành phố
                                    </InputLabel>
                                    <Autocomplete
                                        id="maTinh"
                                        options={TINH_TP}
                                        getOptionLabel={(option) => "Thành phố Cần Thơ"}
                                        value={{ ma: data.maTinh, ten: data.tenTinh }}
                                        disabled={true}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelect(newValue, "maTinh", "tenTinh");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Huyện/Thị/TP{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maHuyen"
                                        options={quans}
                                        getOptionLabel={(option) => option?.name}
                                        value={{ code: data.maHuyen, name: data.tenHuyen }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn huyện/Thị/TP" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Phường/Xã/TT{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maPhuong"
                                        options={phuongs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maPhuong, name: data.tenPhuong }}
                                        noOptionsText={"Không có Phường/Xã/TT"}
                                        onFocus={loadPhuongs}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maPhuong) ? "Vui lòng chọn phường/Xã/TT" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Đường </InputLabel>
                                        <TextField
                                            fullWidth
                                            name="tenDuong"
                                            InputLabelProps={{ classes: labelStyles }}
                                            value={data.tenDuong}
                                            onChange={handleChange}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    {/* <InputLabel classes={labelStyles}>Đường </InputLabel>
                                    <Autocomplete
                                        id="maDuong"
                                        options={DUONG}
                                        getOptionLabel={(option) => option.ten}
                                        value={{ ma: data.maDuong, ten: data.tenDuong }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelect(newValue, "maDuong", "tenDuong");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    /> */}
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles}>Số nhà, đường </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="soNha"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soNha}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Trình độ chuyên môn </InputLabel>
                                    <TextField
                                        fullWidth
                                        size="medium"
                                        name="trinhDoChuyenMon"
                                        value={data.trinhDoChuyenMon}
                                        onChange={handleChange}
                                        rows={3}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <InputLabel classes={labelStyles}>Kinh nghiệm chuyên môn </InputLabel>
                                    <TextField
                                        fullWidth
                                        size="medium"
                                        name="kinhNghiemChuyenMon"
                                        value={data.kinhNghiemChuyenMon}
                                        onChange={handleChange}
                                        rows={3}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles}>Được phép hành nghề</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="medium"
                                        name="duocPhepHanhNghe"
                                        value={data.duocPhepHanhNghe}
                                        onChange={handleChange}
                                        rows={2}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                {!isNhapDauKy && (
                                    <>
                                        <Grid item xs={6} className="grid-item">
                                            <InputLabel classes={labelStyles}>Căn cứ</InputLabel>
                                            <TextField
                                                fullWidth
                                                size="medium"
                                                name="canCu"
                                                value={data.canCu}
                                                onChange={handleChange}
                                                rows={3}
                                                multiline
                                                InputLabelProps={{ classes: labelStyles }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: inputStyles,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className="grid-item">
                                            <InputLabel classes={labelStyles}>Đề nghị</InputLabel>
                                            <TextField
                                                fullWidth
                                                size="medium"
                                                name="deNghi"
                                                value={data.deNghi}
                                                onChange={handleChange}
                                                rows={3}
                                                multiline
                                                InputLabelProps={{ classes: labelStyles }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: inputStyles,
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {/* {isNhapDauKy && (
                                    <>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel classes={labelStyles}>Lần cấp lại</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="lanCapLai"
                                                InputLabelProps={{ classes: labelStyles }}
                                                value={data.lanCapLai}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    classes: inputStyles,
                                                }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </>
                                )} */}
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    )}
                    <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        <ThuLyButtonComponent
                            showIn={data.id > 0 && !isNhapDauKy && showChuyen}
                            onSubmit={handleSubmit}
                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                            onDelete={handleResetData}
                            handleChuyen={showHidePopperThaoTac}
                            handleDuThao={showHidePopperDuThao}
                            dataButton= {data}
                        />
                        {/* {(isNhapDauKy || data.isUpdate) && (
                            <NhapDauKyGiayChungChiButton onSubmit={handleSubmit} onOpenDialog={handleOpenDialog}></NhapDauKyGiayChungChiButton>
                        )}
                        {!isNhapDauKy && !data.isUpdate && (
                            <DiSanVanHoaButtonComponent
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onSubmit={handleSubmit}
                                onResetData={handleResetData}
                                onOpenDialog={handleOpenDialog}
                            />
                        )} */}
                    </Grid>
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
            {showDanhSachDuThaoModal && (
               <DanhSachDuThaoModal hoso={data} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}
        </>
        </div>
    );
};
export default CapMoiTuBoDiTichComponent;
