import apis from "..";
import { GiayPhepKinhDoanhLuHanhDTO, GiayPhepLuHanhNoiDiaRequest, HoSoThuLyLuHanhNoiDia, RequestDTO } from "../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { BaseService } from "../baseService";

export class LuHanhNoiDiaService extends BaseService {
    getByAutocompleteSoGiayPhep(soGiayPhepCu: string,maBienDong: string, hoatDong: boolean, limit: number) {
        let body = {
            soGiayPhep: soGiayPhepCu,
            maBienDong : maBienDong,
            hoatDong: hoatDong,
            limit: limit,
        };
        return apis.post("thu-ly/lu-hanh/autocomplete-by-so-giay-phep", body);
    }
    getByAutocompleteSoGiayPhepCu(soGiayPhepCu: string, hoatDong: boolean, limit: number) {
        let body = {
            soGiayPhep: soGiayPhepCu,
            hoatDong: hoatDong,
            limit: limit,
        };
        return apis.post("thu-ly/lu-hanh/autocomplete-by-so-giay-phep-cu", body);
    }

    getBySoGiayPhep(requestBody: any) {
        return apis.post("thu-ly/lu-hanh/by-so-giay-phep", requestBody);
    }

    getBySoGiayPhepTamNgung(requestBody: any) {
        return apis.post("thu-ly/lu-hanh/by-so-giay-phep-tam-ngung", requestBody);
    }

    getBySoGiayPhepCu(requestBody: any) {
        return apis.post("thu-ly/lu-hanh/by-so-giay-phep-cu", requestBody);
    }
    getByMaHoSo = (maHS: string) => {
        return apis.post("thu-ly/lu-hanh/get-by-ma-ho-so", { maHoSo: maHS }, this.getTokenRequestHeaders());
    };
    insertOrUpdate = (hoSo: HoSoThuLyLuHanhNoiDia) => {
        return apis.post("thu-ly/lu-hanh/save-or-update", hoSo, this.getTokenRequestHeaders());
    };

    searchGiayChungChi = (requestBody: GiayPhepLuHanhNoiDiaRequest) => {
        return apis.post("giay-phep/lu-hanh/tim-kiem", requestBody);
    };
    getGiayPhepById = (id: number) => {
        return apis.get("giay-phep/lu-hanh/chi-tiet-giay-phep-lhnd/" + id);
    };

    getByHoSoId = (hoSoId: number) => {
        return apis.get(`giay-phep/lu-hanh/chi-tiet-by-ho-so-id?hoSoId=${hoSoId}`);
    };

    downloadFileTraCuu = (request: RequestDTO) => {
        return apis.get("giay-phep/lu-hanh/download-danh-sach-tra-cuu", {
            responseType: "blob",
            params: request,
        });
    };
    downloadFileThuLy(request: any) {
        return apis.post("thu-ly/lu-hanh/download-file-thu-ly", request, {
            responseType: "blob",
        });
    }

    chiTietGiayTamNgung(giayChungNhanId: number, classNameGCN: string) {
        let body = {
            giayChungNhanId: giayChungNhanId,
            classNameGCN: classNameGCN,
        };
        return apis.get("giay-phep/lu-hanh/chi-tiet-tam-ngung", {
            params: body,
        });
    }

    tamNgung = (thuHoi: GiayPhepKinhDoanhLuHanhDTO) => {
        return apis.post("giay-phep/lu-hanh/save-or-update-tam-ngung", thuHoi, this.getTokenRequestHeaders());
    };
}
