import { Box, Button, createStyles, Grid, InputLabel, makeStyles, Table, TableHead, Theme, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GiayChungNhanDiSanVanHoaService } from "apis/DiSanVanHoa/GiayChungNhanDiSanVanHoaService";
import { LOAIBIENDONG_CAPLAI, LOAIBIENDONG_CAPMOI, LOAIBIENDONG_THUHOI } from "../../../../constants/DiSanVanHoaConstants";
import { GiayChungNhanDiSanVanHoa, GiayChungNhanDiSanVanHoa_INIT } from "../../../../model/giayChungNhanDiSanVanHoa";
import { LichSuGiayChungNhan } from "../../../../model/lichSuGiayChungNhan";
import StringUtil from "../../../../utils/stringUtils";
import { TepDinhKemPopper } from "../../../commons/hoso/TepDinhKemPopper";
import { Cell, Row } from "../../../commons/table/tableCommons";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import TepTinDinhKemTuBoDiTichComponent from "../../GiaoDienThuLy/ChungChiTuBoDiTich/ThuHoi/TepTinDinhKemTuBoDiTichComponent";
interface TabPanelProps {
    children?: React.ReactNode;
    index?: any;
    value: any;
}

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            padding: 5,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

interface IDataListProps {
    dataList: LichSuGiayChungNhan[];
}

const ChiTietDiSanVanHoaGiayChungNhan = (props: IDataListProps) => {
    const leftStyle = useStylesLabelLeft();
    const rightStyle = useStylesLabelRight();
    const [indexTab, setIndexTab] = useState(0);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState<GiayChungNhanDiSanVanHoa>({ ...GiayChungNhanDiSanVanHoa_INIT });
    const history = useHistory<any>();
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const [rowLichSu, setRowLichSu] = useState<LichSuGiayChungNhan[]>([]);
    const service = new GiayChungNhanDiSanVanHoaService();
    const getDetailById = async (id: number) => {
        await service
            .getGCNChiTiet(id)
            .then((p) => {
                setData(p);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const getLichSuGiayChungNhan = async (id: number) => {
        await service
            .getLichSuGCN(id)
            .then((res) => {
                setRowLichSu(res.rows ? res.rows : []);
                setTotal(res.total);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    function getDiaChi(soNha: string, tenDuong: string, tenPhuong: string, tenHuyen: string) {
        var result = "";
        if (!StringUtil.isNullOrEmty(soNha)) result = result.concat(soNha);
        if (!StringUtil.isNullOrEmty(tenDuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenDuong);
            else result = result.concat(", ", tenDuong);
        }
        if (!StringUtil.isNullOrEmty(tenPhuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenPhuong);
            else result = result.concat(", ", tenPhuong);
        }
        if (!StringUtil.isNullOrEmty(tenHuyen)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenHuyen);
            else result = result.concat(", ", tenHuyen);
        }
        return result;
    }

    useEffect(() => {
        console.log(data.ngayCapChungNhan);

        let id = history?.location?.state?.params;
        let giayChungNhanId = history?.location.state?.id;
        if (id !== undefined && id !== "" && id !== null) {
            getDetailById(id);
            getLichSuGiayChungNhan(id);
        } else if (giayChungNhanId !== undefined && giayChungNhanId !== "" && giayChungNhanId !== null) {
            getDetailById(giayChungNhanId);
            getLichSuGiayChungNhan(giayChungNhanId);
        }
        // let id = history?.location?.state?.params;
        // // let giayChungNhanId = history?.location?.state?.params;
        // let giayChungNhanId = history?.location.state?.giayChungNhanId;
        // console.log(giayChungNhanId);
        // console.log(id);
        // getDetailById(giayChungNhanId);
        // getLichSuGiayChungNhan(giayChungNhanId);
    }, []);

    return (
        <div className="dash-content">
        <Grid container>
           
                <div className="dash-title">
                    <span>CHI TIẾT GIẤY CHỨNG NHẬN ĐỦ ĐIỀU KIỆN HÀNH NGHỀ TU BỔ DI TÍCH</span>
                </div>
            
            <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                <Box mt="5px" bgcolor="#f2f2f2">
                    <CustomTabs value={indexTab} onChange={handleChangeTab}>
                        <CustomTab label="Thông tin chung" />
                        {/* <CustomTab label="Tập đính kèm" /> */}
                        <CustomTab label="Lịch sử" />
                    </CustomTabs>
                </Box>
            </Grid>
            <TabPanel value={indexTab} index={0}>
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Số giấy chứng nhận</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.soChungNhan}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày Cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>
                                {data.ngayCapChungNhan != null ? moment(data?.ngayCapChungNhan).format("DD/MM/YYYY") : ""}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tình Trạng</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.tenTrangThai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Lần cấp lại</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.lanCapLai}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Tên tổ chức</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.tenToChuc}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{getDiaChi(data.soNha, data.tenDuong, data.tenPhuong, data.tenHuyen)}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Số QĐ/ GCNĐKDN/ GCNĐKKD</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.soQD}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.ngayCapQD != null ? moment(data?.ngayCapQD).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Nơi cấp</InputLabel>
                        </Grid>
                        <Grid item xs={9} className="grid-item">
                            <InputLabel classes={rightStyle}>{data?.noiCapQD}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Người đại diện theo pháp luật</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.nguoiDaiDienTheoPhapLuat}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày sinh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.ngaySinh != null ? moment(data?.ngaySinh).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Chức danh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.chucDanh}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Số CMND/ Số định danh</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.soCMND}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Ngày cấp CMND</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.ngayCapCMND != null ? moment(data?.ngayCapCMND).format("DD/MM/YYYY") : ""}</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item" container justify="flex-end">
                            <InputLabel classes={leftStyle}>Nơi cấp CMND</InputLabel>
                        </Grid>
                        <Grid item xs={3} className="grid-item">
                            <InputLabel classes={rightStyle}>{data.noiCapCMND}</InputLabel>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            {/* <TabPanel value={indexTab} index={1}>
                <TepTinDinhKemTuBoDiTichComponent data={data.files} />
            </TabPanel> */}
            <TabPanel value={indexTab} index={1}>
                <Grid container>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <Cell component="th" scope="row" align="center" width="5%">
                                    STT
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    Loại biến động
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="15%">
                                    Ngày biến động
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    Số lần
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    Nội dung
                                </Cell>
                                <Cell component="th" scope="row" align="center" width="20%">
                                    Tệp tin
                                </Cell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowLichSu.map((row, i) => (
                                <Row key={row.giayChungNhanId}>
                                    <Cell scope="row" align="center">
                                        {i + 1}
                                    </Cell>
                                    <Cell align="left">{row.tenBienDong}</Cell>
                                    <Cell align="left">
                                            {/* {LOAIBIENDONG_CAPMOI.ma === row.maBienDong ? moment(row.ngayBienDong).format("DD/MM/YYYY") : ""}
                                            {LOAIBIENDONG_CAPLAI.ma === row.maBienDong ? moment(row.ngayCapLai).format("DD/MM/YYYY") : ""}
                                            {LOAIBIENDONG_THUHOI.ma === row.maBienDong ? moment(row.ngayThuHoi).format("DD/MM/YYYY") : ""} */}
                                        {row.ngayBienDong !== null && row.ngayBienDong !== undefined ? moment(row.ngayBienDong).format("DD/MM/YYYY") : ""}
                                    </Cell>
                                    <Cell align="left">{row.soLanCapLai}</Cell>
                                    <Cell align="left">{row.lyDoCapLai}</Cell>
                                    <Cell>
                                        <TepDinhKemPopper data={row.files} />
                                    </Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </TabPanel>
            <Grid item xs={12} container className="gridItem">
                <Box marginTop="5px">
                    <Button variant="contained" color="primary" disableElevation className="button" onClick={() => history.go(-1)}>
                        Trở về
                    </Button>
                </Box>
            </Grid>
        </Grid>
        </div>
    );
};
export default ChiTietDiSanVanHoaGiayChungNhan;
