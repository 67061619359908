import { ThongKeGiayChungNhan, ThongKeGiayChungNhanReponse } from "../../model/ThongKeGiayChungNhan";

export const ThongKeGiayChungNhan_INIT: ThongKeGiayChungNhanReponse = {
    tongCapMoi: 0,

    tongCapLai: 0,

    hoSoThuLyId: 0,

    tongThuHoi: 0,

    ngayCapChungNhanTu: 0,

    ngayCapChungNhanDen: 0,

    thongKeGiayChungNhan: new Array<ThongKeGiayChungNhan>(),
};
