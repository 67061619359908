import { Box, Grid, Snackbar, Typography } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import { LuHanhNoiDiaService } from "apis/LuHanhNoiDia/LuHanhNoiDiaService";
import { GiayPhepLuHanhNoiDia_INIT } from "../../../../init/LuHanhNoiDia/GiayPhepLuHanhNoiDia_INIT";
import { GiayPhepKinhDoanhLuHanhDTO } from "../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import ChiTietGiayPhepLHNDButtonComponent from "./ChiTietGiayPhepLHNDButtonComponent";
import LichSu from "./LichSu/LichSu";
import ChiTietNoiDungGiayPhepLHNDComponent from "./NoiDung/ChiTietNoiDungGiayPhepLHNDComponent";
import TepTinDinhKem from "./TepTin/TepTinDinhKem";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};
interface ChiTietGiayPhepLHNDProps {}
const ChiTietGiayPhepLHNDComponent = (props: ChiTietGiayPhepLHNDProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [giayPhepId, setGiayPhepId] = useState(0);
    const [currentDate, setCurrentDate] = useState(0);
    const luHanhNoiDiaService = new LuHanhNoiDiaService();
    const [giayPhepLHND, setGiayPhepLHND] = useState<GiayPhepKinhDoanhLuHanhDTO>({ ...GiayPhepLuHanhNoiDia_INIT });

    useEffect(() => {
        initData();
    }, []);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const initData = async () => {
        let giayPhepId = location?.state?.giayPhepId;
        setGiayPhepId(giayPhepId);
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        await luHanhNoiDiaService
            .getGiayPhepById(giayPhepId)
            .then((res) => {
                if (res.data.data !== null && res.data.data !== undefined) {
                    const dataNew = {
                        ...res?.data?.data,
                    };
                    setGiayPhepLHND(dataNew);
                } else {
                    const dataNew = {
                        ...GiayPhepLuHanhNoiDia_INIT,
                    };
                    setGiayPhepLHND(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    return (
        <>
            <div className="dash-content">
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={showAlert}
                        onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setShowAlert(false)} severity={severity as Color}>
                            {message}
                        </Alert>
                    </Snackbar>

                    <Grid container style={{ float: "left", paddingTop: "20px" }} item xs={12} className="grid-item">
                    <div className="dash-title">
                         <span> XEM THÔNG TIN CHI TIẾT GIẤY PHÉP KINH DOANH LỮ HÀNH NỘI ĐỊA</span>
                    </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChange}>
                                <CustomTab label="Thông tin" />
                                {/* <CustomTab label="Tệp đính kèm" /> */}
                                <CustomTab label="Lịch sử" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <ChiTietNoiDungGiayPhepLHNDComponent data={giayPhepLHND} />
                        </TabPanel>
                    )}
                    {/* {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <TepTinDinhKem data={giayPhepLHND?.files} />
                        </TabPanel>
                    )} */}
                    {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <LichSu data={giayPhepLHND?.lichSus} file={giayPhepLHND?.files}/>
                        </TabPanel>
                    )}
                    <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        <ChiTietGiayPhepLHNDButtonComponent />
                    </Grid>
                </Grid>
            }
            </div>
        </>
    );
};
export default ChiTietGiayPhepLHNDComponent;
