import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useRef } from "react";
import { TongThongKeGiayChungChi } from "../../../../../model/GiayChungChiDSVH/TongThongKeGiayChungChi";
import { Cell, Row } from "../../../../commons/table/tableCommons";

interface TableProps {
    dataList: TongThongKeGiayChungChi;
    handleChiTiet: (maTinhTrang?: string, maHuyen?: string) => void;
}

const DanhSachGiayChungNhanKinhDoanh = (props: TableProps) => {
    const anchorRef = useRef<HTMLButtonElement>(null);
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="10%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="40%">
                                Quận/ Huyện
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="25%">
                                Đang hoạt động
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="25%">
                                Thu hồi
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataList?.thongKeGiayChungChi?.length > 0 ? (
                            props.dataList?.thongKeGiayChungChi.map((row, i) => (
                                <Row key={i}>
                                    <Cell align="center">{i + 1}</Cell>
                                    <Cell>{row.tenHuyen}</Cell>
                                    <Cell align="center">
                                        <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("HD", row.tenHuyen)}>
                                            {row.capMoi}
                                        </Box>
                                    </Cell>
                                    <Cell align="center">
                                        <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("TH", row.tenHuyen)}>
                                            {row.thuHoi}
                                        </Box>
                                    </Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={4} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu
                                </Cell>
                            </Row>
                        )}
                        {props?.dataList && props.dataList?.thongKeGiayChungChi?.length > 0 && (
                            <Row>
                                <Cell scope="row" align="center"></Cell>
                                <Cell align="left" className="font-weight-bold">
                                    Tổng số
                                </Cell>
                                <Cell align="center" className="font-weight-bold text-center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("HD", "ALL")}>
                                        {props?.dataList.tongCapMoi}
                                    </Box>
                                </Cell>
                                <Cell align="center" className="font-weight-bold">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet("TH", "ALL")}>
                                        {props?.dataList.tongThuHoi}
                                    </Box>
                                </Cell>
                            </Row>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default DanhSachGiayChungNhanKinhDoanh;
