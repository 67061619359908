import { useEffect, useState } from 'react';
import { Box, Grid, InputLabel, FormHelperText, InputBase, TextField, InputAdornment } from '@material-ui/core';
import StringUtil from '../../../../utils/stringUtils';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { HoatDongVanHoaNgheThuat } from '../../../../model/ThongBaoNgheThuat/HoatDongVanHoaNgheThuat';
import { DanhMucService } from '../../../../apis/danhMuc/danhMucService';
import { DanhMucDiaChiDTO } from '../../../../apis/danhMuc/model/DanhMucDTO';
import { FileEntry } from '../../../../apis/Common/fileEntry';
import { FileServerService } from '../../../../apis/Common/FileServerService';
import { createFileType, downLoadFile } from '../../../../apis/Common/exportUtil';
import { bootstrapTextFieldHook } from '../../../commons/input/bootstrap';
import { AlertMessage } from '../../../commons/alert';
import { ACCEPT_FILES } from '../../../../constants/VanHoaNgheThuatConstants';
import AXIOS_INSTANCE from '../../../../apis';
import DropZoneCustom from 'components/commons/hoso/CustomDropzone/DropZoneCustom';

interface NhaORiengLeCapMoiNoiDungComponentProps {
    data: HoatDongVanHoaNgheThuat;
    submit: boolean;
    onUpdateData: (name: string, value: any) => void;
    isNhapDauKy: boolean;
}
const ThongTinChungNgheThuatComponent = (props: NhaORiengLeCapMoiNoiDungComponentProps) => {


    const [data, setData] = useState({} as HoatDongVanHoaNgheThuat);
    const [submit, setSubmit] = useState(false);

    const [openDonViTuVanModal, setOpenDonViTuVanModal] = useState(false);
    const [openSoKyHieuBangVeModal, setOpenSoKyHieuBangVeModal] = useState(false);
    const { onUpdateData } = props;
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);

    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [quanToChuc, setQuanToChuc] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongToChuc, setPhuongToChuc] = useState<DanhMucDiaChiDTO[]>([]);
    const fileServerService = new FileServerService();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [severity, setSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };
    useEffect(() => {
        loadQuans("92");
        loadQuanToChuc("92");
    }, []);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadQuanToChuc = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res &&  res.length > 0) {
                    setQuanToChuc(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadPhuongs = () => {
        if(data?.maHuyen){
            danhMucService
                .getDanhMucDiaChi(data?.maHuyen)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };


  const loadPhuongToChuc = async () => {
    if(data?.maHuyenToChuc){
            danhMucService
                .getDanhMucDiaChi(data?.maHuyenToChuc)
                .then((res) => {
                    setPhuongToChuc(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
};
    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectQuanToChuc = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText,  ['maPhuongToChuc'] : '', ['tenPhuongToChuc'] : ''};
        onUpdateData("data", dataNew);
    };

    const handleChangeSelectPhuong = async (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        let dataNew = { ...data, [nameValue]: value?.code, [nameText]: valueText};
        onUpdateData("data", dataNew);
    };

    useEffect(() => {
        let dataNew = {
            ...props.data,
        }
        setData(dataNew);
        setSubmit(props.submit);
    }, [props.data, props.submit, props.onUpdateData]);
   
    const handleUpdateData = (name: string, value: string) => {
        onUpdateData(name, value);
    }
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        onUpdateData(e.target.name, value);
    }
    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...data, [nameValue]: value?.ma, [nameText]: valueText };
        onUpdateData("data", dataNew);
    }
    //đơn vị tư vấn
    const handleDonViTuVanModalToggle = () => {
        setOpenDonViTuVanModal(!openDonViTuVanModal);
    }
    //Số ký hiệu bảng vẽ
    const handleSoKyHieuBangVeModalToggle = () => {
        setOpenSoKyHieuBangVeModal(!openSoKyHieuBangVeModal);
    }
    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        onUpdateData(filedName, date?.toDate()?.getTime());
    }
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    }
    const setDataFiles = (memData: FileEntry[]) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems = mems.concat(memData);
        onUpdateData("files", mems);
    }
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    }
    const onDeleteFiles = (index: number) => {
        let files = data.files ? data.files : [];
        let mems = [...files];
        mems.splice(index, 1);
        onUpdateData("files", mems);
    }
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    }

    const onSearchCMND = (cmnd: string) => {
        if (StringUtil.isNullOrEmty(cmnd)) {
            showMessageAlert("Số giấy tờ tùy thân không được trống", false);
            return;
        } else {
            new DanhMucService().getBySoCMND({soCMND: cmnd}).then(res => {
            let dataNew = res?.data.data;
            if (dataNew !== null && dataNew !== undefined) {
                if(dataNew.error_code === 'SUCCESSFUL') {
                    console.log("dataNew ", dataNew);
                
                    setData({
                        ...data,
                        ngayCapCMND: dataNew.data.cmt_NgayCap,
                        ngaySinh: dataNew.data.ngayThangNamSinh,
                    });
                }else {
                    setAlertMessage({ open: true, content: "Số giấy tờ tùy thân không tồn tại trong dữ liệu", severity: 'error' });
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }
    };
    return (
        <>
            {
                <Box padding={0}>
                    <Box padding={0}>
                        <Grid container className="grid-item" >
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required classes={labelStyles}>Số văn bản</InputLabel>
                                <TextField
                                    fullWidth
                                    name="soGiayPhep"
                                    InputLabelProps={{ classes: labelStyles }}
                                    value={data.soGiayPhep}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                    onChange={handleChange}
                                    error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                    helperText={submit && StringUtil.isNullOrEmty(data.soGiayPhep) ? "Vui lòng nhập số văn bản" : ""}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={data.ngayCap && data.ngayCap != 0 ? new Date(data.ngayCap) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <TextField
                                    fullWidth
                                    required
                                    label={"Tên nhà hát/ tên tổ chức"}
                                    name="tenNhaHat"
                                    onChange={handleChange}
                                    value={data.tenNhaHat}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.tenNhaHat) &&
                                    <FormHelperText className="-error">Vui lòng nhập tên nhà hát/ tên tổ chức</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <TextField
                                    fullWidth
                                    label={"Số điện thoại"}
                                    name="soDienThoai"
                                    onChange={handleChange}
                                    value={data.soDienThoai}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <TextField
                                    fullWidth
                                    label={"Số thông báo"}
                                    name="soThongBao"
                                    onChange={handleChange}
                                    value={data.soThongBao}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel classes={labelStyles} required>
                                    Huyện/Thị/TP{" "}
                                </InputLabel>
                                <Autocomplete
                                    id="maHuyen"
                                    classes={inputStyles}
                                    options={quans}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: data.maHuyen, name: data.tenHuyen }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn huyện/Thị/TP" : ""}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel classes={labelStyles} required>
                                    Phường/Xã/TT
                                </InputLabel>
                                <Autocomplete
                                    id="maPhuong"
                                    classes={inputStyles}
                                    options={phuongs}
                                    onFocus={loadPhuongs}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: data.maPhuong, name: data.tenPhuong }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.maPhuong) ? "Vui lòng chọn Phường/Xã/TT" : ""}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel classes={labelStyles}>Đường </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="tenDuong"
                                        value={data.tenDuong}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel classes={labelStyles}>Số nhà </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="soNha"
                                        value={data.soNha}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                            <InputLabel required classes={labelStyles}>Số CMND/ Hộ chiếu/ Căn cước </InputLabel>
                                    <InputBase fullWidth
                                        endAdornment={<InputAdornment position="end"> <SearchIcon fontSize="small" 
                                        style={{ cursor: 'pointer',  marginLeft: '3px' }} 
                                        onClick={(e) => onSearchCMND(data.soCMND)} /></InputAdornment>}
                                        value={data.soCMND} name="soCMND"
                                        error={submit && StringUtil.isNullOrEmty(data.soCMND)}
                                        onChange={handleChange} />
                                    {
                                        submit && StringUtil.isNullOrEmty(data.soCMND) &&
                                        <FormHelperText className="-error"  >Vui lòng nhập số CMND/ Hộ chiếu/ Căn cước</FormHelperText>
                                    }
                            </Grid>
                            <Grid item xs={3} className="grid-item">
                                <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={data.ngayCapCMND && data.ngayCapCMND != 0 ? new Date(data.ngayCapCMND) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapCMND")}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} className="grid-item">
                                <InputLabel classes={labelStyles}>Nơi cấp</InputLabel>
                                <TextField
                                    fullWidth
                                    name="noiCapCMND"
                                    value={data.noiCapCMND}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <TextField
                                    required
                                    fullWidth
                                    label={"Người đại diện theo pháp luật"}
                                    name="nguoiDaiDienTheoPhapLuat"
                                    InputLabelProps={{ classes: labelStyles }}
                                    value={data.nguoiDaiDienTheoPhapLuat}
                                    onChange={handleChange}
                                    error={submit && StringUtil.isNullOrEmty(data.nguoiDaiDienTheoPhapLuat)}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.nguoiDaiDienTheoPhapLuat) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập người đại diện theo pháp luật</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <TextField
                                    fullWidth
                                    label={"Số điện thoại"}
                                    name="soDienThoaiNguoiDaiDien"
                                    onChange={handleChange}
                                    value={data.soDienThoaiNguoiDaiDien}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-item" >
                                <TextField
                                    required
                                    fullWidth
                                    label={"Nội dung biểu diễn"}
                                    name="noiDungBieuDien"
                                    InputLabelProps={{ classes: labelStyles }}
                                    value={data.noiDungBieuDien}
                                    onChange={handleChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                                {
                                    submit && StringUtil.isNullOrEmty(data.noiDungBieuDien) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập nội dung biểu diễn</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel required classes={labelStyles}>Thời gian tổ chức</InputLabel>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        showTodayButton={true}
                                        value={data.thoiGianToChuc && data.thoiGianToChuc != 0 ? new Date(data.thoiGianToChuc) : null}
                                        format="DD/MM/YYYY"
                                        fullWidth
                                        InputAdornmentProps={{ className: "button-date-picker" }}
                                        variant="inline"
                                        InputProps={{ disableUnderline: true, classes: inputStyles }}
                                        invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                        onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "thoiGianToChuc")}
                                    />
                                    {
                                    submit && StringUtil.isNullOrEmty(data.thoiGianToChuc) &&
                                    <FormHelperText className="-error"  >Vui lòng nhập thời gian tổ chức</FormHelperText>
                                }
                                </MuiPickersUtilsProvider>
                            </Grid>
                        <Grid item xs={12} className="grid-item">
                            <InputLabel>Địa điểm tổ chức</InputLabel>
                        </Grid>
                            <Grid item xs={3} className="grid-item" >
                            <InputLabel classes={labelStyles} required>
                                    Huyện/Thị/TP
                                </InputLabel>
                                <Autocomplete
                                    id="maHuyenToChuc"
                                    classes={inputStyles}
                                    options={quanToChuc}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: data.maHuyenToChuc, name: data.tenHuyenToChuc }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectQuanToChuc(newValue, "maHuyenToChuc", "tenHuyenToChuc");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maHuyenToChuc)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.maHuyenToChuc) ? "Vui lòng chọn huyện/Thị/TP" : ""}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >

                            <InputLabel classes={labelStyles} required>
                                    Phường/Xã/TT
                                </InputLabel>
                                <Autocomplete
                                    id="maPhuongToChuc"
                                    classes={inputStyles}
                                    options={phuongToChuc}
                                    onFocus={loadPhuongToChuc}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: data.maPhuongToChuc, name: data.tenPhuongToChuc }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectPhuong(newValue, "maPhuongToChuc", "tenPhuongToChuc");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={submit && StringUtil.isNullOrEmty(data.maPhuongToChuc)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.maPhuongToChuc) ? "Vui lòng chọn Phường/Xã/TT" : ""}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel classes={labelStyles}>Đường </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="tenDuongToChuc"
                                        value={data.tenDuongToChuc}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                            </Grid>
                            <Grid item xs={3} className="grid-item" >
                                <InputLabel classes={labelStyles}>Số nhà </InputLabel>
                                    <TextField
                                        fullWidth
                                        name="soNhaToChuc"
                                        value={data.soNhaToChuc}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={6} className="grid-item" >
                                    <TextField
                                        fullWidth
                                        label={"Người chịu trách nhiệm"}
                                        name="nguoiChiuTrachNhiem"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.nguoiChiuTrachNhiem}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                    <InputLabel classes={labelStyles}>Căn cứ pháp lý</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="medium"
                                        name="canCuocPhapLy"
                                        value={data.canCuocPhapLy}
                                        onChange={handleChange}
                                        rows={2}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                            <DropZoneCustom
                                acceptFiles={ACCEPT_FILES}
                                attachFileServer={data.files}
                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/vhdl"}
                                handleChangeStatus={handleChangeStatus}
                                handleDeleteFileServer={handleDeleteFileServer}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </Grid>
                    </Box>

                </Box>
            }
        </>
    )
}
export default ThongTinChungNgheThuatComponent;