import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import RefreshIcon from "@material-ui/icons/Refresh";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { HoSoThuLyDiSanVanHoaService } from "apis/DiSanVanHoa/HoSoThuLyDiSanVanHoaService";
import { TINHTRANG_THUHOI } from "../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { GiayPhepToChucNgheThuat } from "../../../../model/ThongBaoNgheThuat/GiayPhepToChucNgheThuat";
import { HoatDongVanHoaNgheThuat } from "../../../../model/ThongBaoNgheThuat/HoatDongVanHoaNgheThuat";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0.5),
        },
        menuItem: {
            color: "#0821af",
            textDecoration: "none",
            padding: "10px 15px",
            "&:hover": {
                color: "#0821af",
                cursor: "pointer",
                backgroundColor: "#bfc1cc",
                fontWeight: "bold",
            },
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
        },
    })
);

export const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: "none",
        },
        sizeSmall: {
            fontSize: "13px",
        },
        startIcon: {
            marginRight: theme.spacing(0.5),
        },
        "&:focus": {
            outlineWidth: "0px",
            outlineStyle: "none",
        },
    })
)(Button);

export interface ThaoTacHoSoCuaToiPropsI {
    row: GiayPhepToChucNgheThuat;
    reloadData: () => void;
}

export const ThaoTacGiayChungNhan = (props: ThaoTacHoSoCuaToiPropsI) => {
    const classes = useStyles();
    const { row, reloadData } = props;
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const hoSoThuLyDiSanVanHoaService = new HoSoThuLyDiSanVanHoaService();
    const history = useHistory();
    const showHidePopperThaoTac = () => {
        setOpen(!open);
    };

    const match = useRouteMatch();

    console.log(match.params);

    const handleCloseProper = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const clickChiTiet = () => {
        history.push("/hoat-dong-nghe-thuat/giay-phep-to-chuc-NT/get-chi-tiet", { params: row.id });
    };
    const clickChinhSua = () => {
        if (row.maTrangThai === TINHTRANG_THUHOI.ma) {
            history.push("/hoat-dong-nghe-thuat/giay-phep-to-chuc-NT/thu-hoi-giay-phep", { params: row.id });
        } else {
            history.push("/hoat-dong-nghe-thuat/giay-phep-nghe-thuat", { maHoSo: row.maHoSo, soChungNhanId: row.id, isUpdate: true });
        }
    };
    const clickThuHoi = () => {
        history.push("/hoat-dong-nghe-thuat/giay-phep-to-chuc-NT/thu-hoi-giay-phep", { params: row.id });
    };

    return (
        <>
            <CustomButton
                className={classes.margin}
                startIcon={<ListIcon />}
                size="small"
                color="primary"
                disableRipple
                ref={anchorRef}
                aria-haspopup="true"
                aria-controls={open ? "menu-list-grow" : undefined}
                onClick={() => {
                    showHidePopperThaoTac();
                }}
            ></CustomButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseProper}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {props?.row?.maTrangThai === TINHTRANG_THUHOI.ma ? "" : 
                                    <MenuItem key={"chinh-sua"} style={{ padding: "unset" }}>
                                        <Button onClick={clickChinhSua} startIcon={<EditIcon color="primary" />} size="small" disableRipple>
                                            Chỉnh sửa
                                        </Button>
                                    </MenuItem>
                                }
                                    <MenuItem key={"thu-hoi"} onClick={clickThuHoi} style={{ padding: "unset" }}>
                                        <Button startIcon={<RefreshIcon color="error" />} size="small" disableRipple>
                                            {props?.row?.maTrangThai === TINHTRANG_THUHOI.ma ? "Chỉnh sửa thu hồi" : "Thu hồi"}
                                        </Button>
                                    </MenuItem>
                                    <MenuItem key={"menu-item-huy"} style={{ padding: "unset" }}>
                                        <Button startIcon={<VisibilityIcon style={{ color: "green" }} />} size="small" disableRipple onClick={clickChiTiet}>
                                            Xem chi tiết
                                        </Button>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
