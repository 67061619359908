import { Box, Button, Dialog, Grid, InputAdornment, InputBase, InputLabel, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { BuongLuuTruDTO_INIT } from "../../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { BuongLuuTruDTO } from "../../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import StringUtil from "../../../../../utils/stringUtils";
import { DialogContent, DialogTitle } from "../../../../commons/dialog/dialogCommons";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";

interface BuongCoSoModalProps {
    data: BuongCoSoModalData;
    onToggle: () => void;
    onAddData: (value: BuongLuuTruDTO) => void;
    onUpdateData: (index: number, value: BuongLuuTruDTO) => void;
}
interface BuongCoSoModalData {
    data: BuongLuuTruDTO;
    show: boolean;
    indexEdit: number;
}
const BuongCoSoModal = (props: BuongCoSoModalProps) => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [data, setData] = React.useState({ ...BuongLuuTruDTO_INIT });
    const [show, setShow] = React.useState(false);
    const [submit, setSubmit] = React.useState(false);
    const [indexEdit, setIndexEdit] = React.useState(-1);
    const { onToggle, onAddData, onUpdateData } = props;
    useEffect(() => {
        if (props.data.indexEdit >= 0) {
            setData(props.data.data);
        } else {
            setData({ ...BuongLuuTruDTO_INIT });
        }
        setShow(props.data.show);
        setIndexEdit(props.data.indexEdit);
        setSubmit(false);
    }, [props.data, props.data.show, props.data.indexEdit]);
    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };
    const handleChangeNumber = (e: any) => {
        let name = e.target.name;
        let value = StringUtil.convertToNumber(e.target.value);
        const regExpNumber = new RegExp("^[0-9]*$");
        if (regExpNumber.test(value)) {
            let newData = {
                ...data,
                [name]: Number.parseInt(value),
            };
            setData(newData);
        }
    };
    const handleSubmit = () => {
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.loaiBuong)) {
            return;
        }
        if (indexEdit >= 0) {
            onUpdateData(indexEdit, data);
        } else {
            onAddData(data);
        }
        onToggle();
    };

    return (
        <>
            {
                <Dialog onClose={onToggle} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={show}>
                    <DialogTitle id="customized-dialog-title" onClose={onToggle}>
                        {indexEdit >= 0 ? "CẬP NHẬT BUỒNG" : "THÊM BUỒNG"}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box padding={0}>
                            <Grid container>
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel required classes={labelStyles}>Loại buồng</InputLabel>
                                    <TextField
                                        fullWidth
                                        name="loaiBuong"
                                        value={data.loaiBuong}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel classes={labelStyles}>Số lượng</InputLabel>
                                    <InputBase classes={inputStyles} fullWidth value={data.soLuong > 0 ? data.soLuong : ''} name="soLuong" onChange={handleChangeNumber} />
                                </Grid>
                                <Grid item xs={4} className="grid-item">
                                    <InputLabel classes={labelStyles}>Giá công bố </InputLabel>
                                    <InputBase
                                        classes={inputStyles}
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">VND</InputAdornment>}
                                        value={StringUtil.formatMoney(data.gia)}
                                        name="gia"
                                        onChange={handleChangeNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} className="grid-item">
                                    <Box marginTop="5px">
                                        <Button variant="contained" color="primary" disableElevation onClick={handleSubmit}>
                                            Lưu
                                        </Button>
                                        <Button variant="contained" color="default" disableElevation onClick={onToggle}>
                                            Hủy
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    );
};
export default BuongCoSoModal;
