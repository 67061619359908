import { Animation, Stack, EventTracker } from "@devexpress/dx-react-chart";
import {
    ArgumentAxis,
    BarSeries, Chart,
    Legend, ValueAxis, Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { Box, Button, Card, CardContent, createStyles, Grid, InputLabel, makeStyles, TextField, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Autocomplete } from "@material-ui/lab";
import React, { Dispatch, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { ThongKeGiayChungNhanKinhDoanhService } from "../../../../../apis/DichVuDuLich/ThongKeGiayChungNhanKinhDoanhService";
import { GiayChungChiDiSanVanHoaRequest_INIT } from "../../../../../init/GiayChungChi/GiayChungChiDiSanVanHoaRequest";
import { GiayChungChiDiSanVanHoaRequest } from "../../../../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoaRequest";
import { ThongKeGiayChungChi } from "../../../../../model/GiayChungChiDSVH/TongThongKeGiayChungChi";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiCardHeader-title": {
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        button: {
            margin: "0px 5px",
            "margin-top": " 25px !important;",
            "margin-bottom": "0px",
        },
    })
);

export const BieuDoThongKeGCNKinhDoanhMuaSam = () => {
    const classes = useStyles();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [data, setData] = useState<ThongKeGiayChungChi[]>([]);
    const [dataNamThongKe, setDataNamThongKe] = useState<any[]>([]);
    const thongKeGiayChungNhanKinhDoanhService = new ThongKeGiayChungNhanKinhDoanhService();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const [search, setSeach] = useState({
        ...GiayChungChiDiSanVanHoaRequest_INIT,
        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuMuaSam",
    });
    const [rows, setRows] = useState<ThongKeGiayChungChi[]>([]);

    const dataCapMoi = [...rows.map((item) => item.capMoi)];
    const dataThuHoi = [...rows.map((item) => item.thuHoi)];
    const dataCapLai = [...rows.map((item) => item.capLai)];
    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        namThongKeList();
        loadDataTable(search);
    };

    const handleSearch = async () => {
        loadDataTable(search);
    };

    const loadDataTable = async (requestBody: GiayChungChiDiSanVanHoaRequest) => {
        dispatchLoading(showLoading());
        await thongKeGiayChungNhanKinhDoanhService
            .bieuDoThongKe(requestBody)
            .then((response) => {
                if (response.status == 200) {
                    if (response?.data?.data !== null && response?.data?.data !== undefined) {
                        setData(response?.data?.data);
                        setRows(response?.data?.data);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let searchNew = { ...search, [nameValue]: value?.ma, [nameText]: valueText };
        setSeach(searchNew);
    };

    const namThongKeList = () => {
        let namHienTai = new Date().getUTCFullYear();
        let temps = [{ ma: 0, ten: "Chọn năm" }];
        for (let i = 10 - 1; i >= 0; --i) {
            var temp = { ma: namHienTai - i, ten: "Năm " + (namHienTai - i) };
            temps.push(temp);
        }
        for (let i = 1; i <= 10; i++) {
            var temp1 = { ma: namHienTai + i, ten: "Năm " + (namHienTai + i) };
            temps.push(temp1);
        }
        setDataNamThongKe(temps);
    };

    const options = {
        responsive: true,
        legend: {
            position: "top",
        },
        layout: {
            padding: 10,
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    },
                },
            ],
        },
        type: "bar",
    };

    const dataBD = {
        labels: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
        datasets: [
            {
                label: "Cấp lần đầu",
                backgroundColor: "#51A3A3",
                data: dataCapMoi,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Cấp lại",
                backgroundColor: "#EAD94C",
                data: dataCapLai,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
            {
                label: "Thu hồi",
                backgroundColor: "#5E5D5C",
                data: dataThuHoi,
                barThickness: 15, // number (pixels) or 'flex'
                maxBarThickness: 20, // number (pixels)
            },
        ],
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>BIỂU ĐỒ THỐNG KÊ CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM TRÊN ĐỊA BÀN</span>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Năm </InputLabel>
                    <Autocomplete
                        options={dataNamThongKe}
                        getOptionLabel={(option) => option?.ten}
                        onChange={(event: any, newValue: any) => handleChangeSelect(newValue, "namThongKe", "tenNamThongKe")}
                        renderInput={(params) => {
                            return (
                                <div className="padding-top-8px" ref={params.InputProps.ref}>
                                    <TextField {...params} variant="outlined" />
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-start">
                    <Button onClick={handleSearch} type="button" variant="contained" color="primary" disableElevation className={classes.button}>
                        Tổng hợp
                    </Button>
                </Grid>

                <Grid item xs={12} className="grid-item">
                    <Card classes={classes}>
                        <CardContent>
                            <Bar data={dataBD} options={options} />
                        </CardContent>
                    </Card>
                    {/* <Paper>
                        <Chart data={data}>
                            <ArgumentAxis />
                            <ValueAxis
                                labelComponent={(props) => {
                                    return Number.isInteger(Number(props.text)) ? <text {...props}>{Number(props.text)}</text> : <text {...props}></text>;
                                }}
                            />
                            <BarSeries name="Đang hoạt động" valueField="capMoi" argumentField="tenThang" color="#00c292" />
                            <BarSeries name="Thu hồi" valueField="thuHoi" argumentField="tenThang" color="silver" />
                            <Animation />
                            <Legend
                                position="bottom"
                                rootComponent={(root: Legend.RootProps) => {
                                    return (
                                        <Box paddingTop={2} display="flex" flexDirection="row" flexGrow={1} justifyContent="center">
                                            {root.children}
                                        </Box>
                                    );
                                }}
                                itemComponent={(label: Legend.ItemProps) => {
                                    return (
                                        <Box paddingX={1} display="flex" flexDirection="row" alignItems="center">
                                            {label.children}
                                        </Box>
                                    );
                                }}
                            />
                            <Stack />
                            <EventTracker></EventTracker>
                            <Tooltip />
                        </Chart>
                    </Paper> */}
                </Grid>
            </Grid>
            <OverlayLoading />
        </div>
    );
};
