import { BieuDoTronCoSoLuuTru } from "components/DichVuDuLich/LuuTru/BieuDoTron/BieuDoTronCoSoLuuTru";
import { BieuDoTronTheoHangCoSoLuuTru } from "components/DichVuDuLich/LuuTru/BieuDoTron/BieuDoTronTheoHangCoSoLuuTru";
import { RoutePropsI } from ".";
import { BaoCaoGCNAnUong } from "../components/DichVuDuLich/AnUong/DanhSach/BaoCao/BaoCaoGCNAnUong";
import ChiTietGCNKinhDoanhAnUong from "../components/DichVuDuLich/AnUong/DanhSach/ChiTiet/ChiTietGCNKinhDoanhAnUong";
import { BieuDoThongKeGCNKinhDoanhAnUong } from "../components/DichVuDuLich/AnUong/DanhSach/ThongKe/BieuDoThongKeGCNKinhDoanhAnUong";
import ThongKeChungNhanKinhDoanhAnUong from "../components/DichVuDuLich/AnUong/DanhSach/ThongKe/ThongKeChungNhanKinhDoanhAnUong";
import TraCuuGCNKinhDoanhAnUong from "../components/DichVuDuLich/AnUong/DanhSach/TraCuu/TraCuuGCNKinhDoanhAnUong";
import CapMoiGCNKinhDoanhAnUongComponent from "../components/DichVuDuLich/AnUong/GiaoDienThuLy/CapMoi/CapMoiGCNKinhDoanhAnUongComponent";
import TamNgungGCNKinhDoanhAnUongComponent from "../components/DichVuDuLich/AnUong/GiaoDienThuLy/TamNgung/TamNgungGCNKinhDoanhAnUongComponent";
import ThuHoiGCNKinhDoanhAnUongComponent from "../components/DichVuDuLich/AnUong/GiaoDienThuLy/ThuHoi/ThuHoiGCNKinhDoanhAnUongComponent";
import CapSoDichVuDuLichComponent from "../components/DichVuDuLich/CapSoGiayPhep/CapSoDichVuDuLichComponent";
import { HangCoSoLuuTruComponent } from "../components/DichVuDuLich/DanhMuc/HangCoSoLuuTru/main";
import { LoaiHinhCoSoLuuTruComponent } from "../components/DichVuDuLich/DanhMuc/LoaiHinhCoSoLuuTru/main";
import BaoCaoCoSoLuuTruComponent from "../components/DichVuDuLich/LuuTru/BaoCao/BaoCaoCoSoLuuTruComponent";
import { BieuDoCoSoLuuTruComponent } from "../components/DichVuDuLich/LuuTru/BieuDo/BieuDoCoSoLuuTruComponent";
import CapMoiCoSoLuuTruComponent from "../components/DichVuDuLich/LuuTru/GiaoDienThuLy/CapMoi/CapMoiCoSoLuuTruComponent";
import NhapDauKyCoSoLuuTruComponent from "../components/DichVuDuLich/LuuTru/GiaoDienThuLy/NhapDauKy/NhapDauKyCoSoLuuTruComponent";
import TamNgungCSLuuTruComponent from "../components/DichVuDuLich/LuuTru/TamNgung/tamNgungCSLuuTruComponent";
import ThongKeCoSoLuuTruComponent from "../components/DichVuDuLich/LuuTru/ThongKe/ThongKeCoSoLuuTruComponent";
import ChiTieCoSoLuuTruComponent from "../components/DichVuDuLich/LuuTru/TraCuu/ChiTiet/ChiTieCoSoLuuTruComponent";
import TraCuuCoSoLuuTruComponent from "../components/DichVuDuLich/LuuTru/TraCuu/TraCuuCoSoLuuTruComponent";
import { BaoCaoGCNMuaSam } from "../components/DichVuDuLich/MuaSam/DanhSach/BaoCao/BaoCaoGCNMuaSam";
import ChiTietGCNKinhDoanhMuaSam from "../components/DichVuDuLich/MuaSam/DanhSach/ChiTiet/ChiTietGCNKinhDoanhMuaSam";
import TamNgungGCNKinhDoanhMuaSamComponent from "../components/DichVuDuLich/MuaSam/DanhSach/TamNgung/TamNgungGCNKinhDoanhMuaSamComponent";
import { BieuDoThongKeGCNKinhDoanhMuaSam } from "../components/DichVuDuLich/MuaSam/DanhSach/ThongKe/BieuDoThongKeGCNKinhDoanhMuaSam";
import ThongKeChungNhanKinhDoanhMuaSam from "../components/DichVuDuLich/MuaSam/DanhSach/ThongKe/ThongKeChungNhanKinhDoanhMuaSam";
import TraCuuGCNKinhDoanhMuaSam from "../components/DichVuDuLich/MuaSam/DanhSach/TraCuu/TraCuuGCNKinhDoanhMuaSam";
import CapMoiGCNKinhDoanhMuaSamComponent from "../components/DichVuDuLich/MuaSam/GiaoDienThuLy/CapMoi/CapMoiGCNKinhDoanhMuaSamComponent";
import ThuHoiGCNKinhDoanhMuaSamComponent from "../components/DichVuDuLich/MuaSam/GiaoDienThuLy/ThuHoi/ThuHoiGCNKinhDoanhMuaSamComponent";
import { BaoCaoDSKDTheThao } from "../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/baoCaoDSKDTheThao";
import { BieuDoThongKeCSKDTheThao } from "../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/BieuDoThongKeCSKDTheThao";
import ChiTietDichVuTheThaoComponent from "../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/chiTietDichVuTheThaoComponent";
import CSKDDichVuTheThaoComponent from "../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/CSKDDichVuTheThaoComponent";
import TamNgungCSKDTheThaoComponent from "../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/TamNgung/tamNgungCSKDTheThaoComponent";
import { ThongKeTinhHinhCSKDTheThao } from "../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/ThongKeTinhHinhCSKDTheThao";
import TraCuuDSCSKDichVuTheThaoComponent from "../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/TraCuuDSCSKDichVuTheThaoComponent";
import { BaoCaoDSKDVuiChoiGiaiTri } from "../components/HoSoThuLyDichVuDuLich/GiaoDien/VuiChoiGiaiTri/baoCaoDSKDVuiChoiGiaiTri";
import { BieuDoThongKeCSKDGiaiTri } from "../components/HoSoThuLyDichVuDuLich/GiaoDien/VuiChoiGiaiTri/BieuDoThongKeCSKDGiaiTri";
import ChiTietDichVuVuiChoiGiaiTriComponent from "../components/HoSoThuLyDichVuDuLich/GiaoDien/VuiChoiGiaiTri/chiTietDichVuVuiChoiGiaiTriComponent";
import CSKDDichVuVuiChoiGiaiTriComponent from "../components/HoSoThuLyDichVuDuLich/GiaoDien/VuiChoiGiaiTri/CSKDDichVuVuiChoiGiaiTriComponent";
import TamNgungDichVuVuiChoiGiaiTriComponent from "../components/HoSoThuLyDichVuDuLich/GiaoDien/VuiChoiGiaiTri/TamNgung/tamNgungDichVuVuiChoiGiaiTriComponent";
import { ThongKeTinhHinhCSKDVuiChoiGiaiTri } from "../components/HoSoThuLyDichVuDuLich/GiaoDien/VuiChoiGiaiTri/ThongKeTinhHinhCSKDVuiChoiGiaiTri";
import TraCuuDSCSKDichVuVuiChoiGiaiTriComponent from "../components/HoSoThuLyDichVuDuLich/GiaoDien/VuiChoiGiaiTri/TraCuuDSCSKDichVuVuiChoiGiaiTriComponent";
import WrapperMapTraCuu from "../pages/traCuuBanDo/wrapperTraCuuBanDo";
export const NHAPDAUKY_COSOLUUTRU = {
    ten: "Nhập đầu kỳ cơ sở lưu trú",
    duongDan: "/dich-vu-va-du-lich/nhap-dau-ky-co-so-luu-tru",
    giaoDien: NhapDauKyCoSoLuuTruComponent,
};

export const CAP_MOI_GCN_AN_UONG = {
    ten: "Công nhận cơ sở kinh doanh dịch vụ ăn uống đạt tiêu chuẩn phục vụ khách du lịch",
    duongDan: "/dich-vu-va-du-lich/cap-moi-gcn-kinh-doanh-va-an-uong",
    giaoDien: CapMoiGCNKinhDoanhAnUongComponent,
};
export const CAP_MOI_GCN_MUA_SAM = {
    ten: "Công nhận cơ sở kinh doanh dịch vụ mua sắm đạt tiêu chuẩn phục vụ khách du lịch",
    duongDan: "/dich-vu-va-du-lich/cap-moi-gcn-kinh-doanh-va-mua-sam",
    giaoDien: CapMoiGCNKinhDoanhMuaSamComponent,
};
export const THU_HOI_GCN_AN_UONG = {
    ten: "Thu hồi biển hiệu cơ sở kinh doanh dịch vụ ăn uống",
    duongDan: "/dich-vu-va-du-lich/thu-hoi-gcn-kinh-doanh-va-an-uong",
    giaoDien: ThuHoiGCNKinhDoanhAnUongComponent,
};
export const TAM_NGUNG_GCN_AN_UONG = {
    ten: "Tạm ngưng biển hiệu cơ sở kinh doanh dịch vụ ăn uống",
    duongDan: "/dich-vu-va-du-lich/tam-ngung-gcn-kinh-doanh-va-an-uong",
    giaoDien: TamNgungGCNKinhDoanhAnUongComponent,
};
export const ROUTE_CN_DVDL: RoutePropsI[] = [
    NHAPDAUKY_COSOLUUTRU,
    CAP_MOI_GCN_AN_UONG,
    CAP_MOI_GCN_MUA_SAM,
    {
        ten: "Công nhận cơ sở kinh doanh dịch vụ thể thao",
        duongDan: "/cskd-dich-vu-the-thao",
        giaoDien: CSKDDichVuTheThaoComponent,
    },
    {
        ten: "Công nhận cơ sở kinh doanh dịch vụ vui chơi, giải trí",
        duongDan: "/cskd-dich-vu-vui-choi-giai-tri",
        giaoDien: CSKDDichVuVuiChoiGiaiTriComponent,
    },

    {
        ten: "Công nhận hạng cơ sở lưu trú du lịch",
        duongDan: "/dich-vu-va-du-lich/cap-moi-co-so-luu-tru",
        giaoDien: CapMoiCoSoLuuTruComponent,
    },
];

export const ROUTE_GIAY_CHUNG_NHAN_GIAI_TRI_CHI_TIET = {
    ten: "THÔNG TIN CHI TIẾT CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
    duongDan: "/dich-vu-va-du-lich/chi-tiet-dich-vu-vui-choi-giai-tri",
    giaoDien: ChiTietDichVuVuiChoiGiaiTriComponent,
};

export const ROUTE_GIAY_CHUNG_NHAN_GIAI_TRI_TAM_NGUNG = {
    ten: "TẠM NGƯNG CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
    duongDan: "/dich-vu-va-du-lich/tam-ngung-dich-vu-vui-choi-giai-tri",
    giaoDien: TamNgungDichVuVuiChoiGiaiTriComponent,
};

export const ROUTE_CSKD_THE_THAO_TAM_NGUNG = {
    ten: "TẠM NGƯNG CƠ SỞ KINH DOANH THỂ THAO",
    duongDan: "/dich-vu-va-du-lich/tam-ngung-dich-vu-the-thao",
    giaoDien: TamNgungCSKDTheThaoComponent,
};
export const ROUTE_CO_SO_LUUTRU_TAM_NGUNG = {
    ten: "TẠM NGƯNG CƠ SỞ LƯU TRÚ",
    duongDan: "/dich-vu-va-du-lich/tam-ngung-co-so-luu-tru",
    giaoDien: TamNgungCSLuuTruComponent,
};

export const ROUTE_GIAY_CHUNG_NHAN_THE_THAO_CHI_TIET = {
    ten: "THÔNG TIN CHI TIẾT CƠ SỞ KINH DOANH DICH VỤ THỂ THAO",
    duongDan: "/dich-vu-va-du-lich/chi-tiet-dich-vu-the-thao",
    giaoDien: ChiTietDichVuTheThaoComponent,
};
export const ROUTE_PAGE_PUBLIC_DVDL: RoutePropsI[] = [
    {
        ten: "BIỂU ĐỒ THỐNG KÊ CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-gcn-kinh-doanh-an-uong",
        giaoDien: <BieuDoThongKeGCNKinhDoanhAnUong />,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-tinh-hinh-cskd-giai-tri",
        giaoDien: <BieuDoThongKeCSKDGiaiTri />,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ LƯU TRÚ TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-co-so-luu-tru",
        giaoDien: <BieuDoCoSoLuuTruComponent />,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-gcn-kinh-doanh-mua-sam",
        giaoDien: <BieuDoThongKeGCNKinhDoanhMuaSam />,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-tinh-hinh-cskd-the-thao",
        giaoDien: <BieuDoThongKeCSKDTheThao />,
    }
]
export const ROUTE_PAGE_DVDL: RoutePropsI[] = [
    {
        ten: "CẬP NHẬT QUYẾT ĐỊNH CƠ SỞ DỊCH VỤ DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/cap-so",
        giaoDien: CapSoDichVuDuLichComponent,
    },
    {
        ten: "BẢNG ĐỒ",
        duongDan: "/dich-vu-va-du-lich/bang-do",
        giaoDien: WrapperMapTraCuu,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG ĐẠT TIÊU CHUẨN PHỤC VỤ KHÁCH DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-co-so-kinh-doanh-an-uong",
        giaoDien: TraCuuGCNKinhDoanhAnUong,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bao-cao-co-so-kinh-doanh-an-uong",
        giaoDien: BaoCaoGCNAnUong,
    },
    {
        ten: "THỐNG KÊ DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/thong-ke-gcn-kinh-doanh-an-uong",
        giaoDien: ThongKeChungNhanKinhDoanhAnUong,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-gcn-kinh-doanh-an-uong",
        giaoDien: BieuDoThongKeGCNKinhDoanhAnUong,
    },
    {
        ten: "THÔNG TIN CHI TIẾT CƠ SỞ KINH DOANH DICH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/chi-tiet-dich-vu-the-thao",
        giaoDien: ChiTietDichVuTheThaoComponent,
    },
    {
        ten: "TẠM NGƯNG CƠ SỞ KINH DOANH DICH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/tam-ngung-dich-vu-the-thao",
        giaoDien: TamNgungCSKDTheThaoComponent,
    },
    {
        ten: "TẠM NGƯNG CƠ SỞ LƯU TRÚ",
        duongDan: "/dich-vu-va-du-lich/tam-ngung-co-so-luu-tru",
        giaoDien: TamNgungCSLuuTruComponent,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-thong-tin-cskd-the-thao",
        giaoDien: TraCuuDSCSKDichVuTheThaoComponent,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/bao-cao-dskd-the-thao",
        giaoDien: BaoCaoDSKDTheThao,
    },
    {
        ten: "THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/thong-ke-tinh-hinh-cskd-the-thao",
        giaoDien: ThongKeTinhHinhCSKDTheThao,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-tinh-hinh-cskd-the-thao",
        giaoDien: BieuDoThongKeCSKDTheThao,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-thong-tin-cskd-vui-choi-giai-tri",
        giaoDien: TraCuuDSCSKDichVuVuiChoiGiaiTriComponent,
    },
    {
        ten: "THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/thong-ke-tinh-hinh-cskd-vui-choi-giai-tri",
        giaoDien: ThongKeTinhHinhCSKDVuiChoiGiaiTri,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/bao-cao-dskd-vui-choi-giai-tri",
        giaoDien: BaoCaoDSKDVuiChoiGiaiTri,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-tinh-hinh-cskd-giai-tri",
        giaoDien: BieuDoThongKeCSKDGiaiTri,
    },
    {
        ten: "THÔNG TIN CHI TIẾT CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/chi-tiet-dich-vu-vui-choi-giai-tri",
        giaoDien: ChiTietDichVuVuiChoiGiaiTriComponent,
    },
    {
        ten: "TẠM NGƯNG CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/tam-ngung-dich-vu-vui-choi-giai-tri",
        giaoDien: TamNgungDichVuVuiChoiGiaiTriComponent,
    },
    
    {
        ten: "DANH MỤC LOẠI HÌNH CƠ SỞ LƯU TRÚ",
        duongDan: "/dich-vu-va-du-lich/loai-hinh-co-so",
        giaoDien: LoaiHinhCoSoLuuTruComponent,
    },
    {
        ten: "DANH MỤC HẠNG CƠ SỞ LƯU TRÚ",
        duongDan: "/dich-vu-va-du-lich/hang-co-so",
        giaoDien: HangCoSoLuuTruComponent,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ LƯU TRÚ",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-co-so-kinh-doanh-luu-tru",
        giaoDien: TraCuuCoSoLuuTruComponent,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ LƯU TRÚ TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bao-cao-co-so-kinh-doanh-luu-tru",
        giaoDien: BaoCaoCoSoLuuTruComponent,
    },
    {
        ten: "THỐNG KÊ DANH SÁCH CƠ SỞ LƯU TRÚ TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/thong-ke-co-so-luu-tru",
        giaoDien: ThongKeCoSoLuuTruComponent,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ LƯU TRÚ TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-co-so-luu-tru",
        giaoDien: BieuDoCoSoLuuTruComponent,
    },
    {
        ten: "BIỂU ĐỒ TRÒN THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ LƯU TRÚ TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-tron-co-so-luu-tru",
        giaoDien: BieuDoTronCoSoLuuTru,
    },
    {
        ten: "BIỂU ĐỒ TRÒN THỐNG KÊ THEO HẠNG CÔNG NHẬN CƠ SỞ LƯU TRÚ TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-tron-theo-hang-co-so-luu-tru",
        giaoDien: BieuDoTronTheoHangCoSoLuuTru,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-co-so-kinh-doanh-mua-sam",
        giaoDien: TraCuuGCNKinhDoanhMuaSam,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bao-cao-co-so-kinh-doanh-mua-sam",
        giaoDien: BaoCaoGCNMuaSam,
    },
    {
        ten: "THỐNG KÊ DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/thong-ke-gcn-kinh-doanh-mua-sam",
        giaoDien: ThongKeChungNhanKinhDoanhMuaSam,
    },

    {
        ten: "BIỂU ĐỒ THỐNG KÊ CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-gcn-kinh-doanh-mua-sam",
        giaoDien: BieuDoThongKeGCNKinhDoanhMuaSam,
    },
];
export const CHITIET_COSO_LUUTRU = {
    ten: "THÔNG TIN CHI TIẾT CƠ SỞ LƯU TRÚ",
    duongDan: "/dich-vu-va-du-lich/chi-tiet-co-so-luu-tru",
    giaoDien: ChiTieCoSoLuuTruComponent,
};

export const THU_HOI_GCN_MUA_SAM = {
    ten: "THU HỒI BIỂN HIỆU CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM",
    duongDan: "/dich-vu-va-du-lich/thu-hoi-gcn-kinh-doanh-va-mua-sam",
    giaoDien: ThuHoiGCNKinhDoanhMuaSamComponent,
};

export const TAM_NGUNG_GCN_MUA_SAM = {
    ten: "Tạm ngưng biển hiệu cơ sở kinh doanh mua sắm",
    duongDan: "/dich-vu-va-du-lich/tam-ngung-gcn-mua-sam",
    giaoDien: TamNgungGCNKinhDoanhMuaSamComponent,
};

export const CHI_TIET_GNC_MUA_SAM = {
    ten: "CHI TIẾT CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM ĐẠT TIÊU CHUẨN PHỤC VỤ KHÁCH DU LỊCH",
    duongDan: "/dich-vu-va-du-lich/chi-tiet-co-so-kinh-doanh-mua-sam",
    giaoDien: ChiTietGCNKinhDoanhMuaSam,
};
export const CHI_TIET_GNC_AN_UONG = {
    ten: "CHI TIẾT CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG ĐẠT TIÊU CHUẨN PHỤC VỤ KHÁCH DU LỊCH",
    duongDan: "/dich-vu-va-du-lich/chi-tiet-co-so-kinh-doanh-an-uong",
    giaoDien: ChiTietGCNKinhDoanhAnUong,
};
export const ROUTE_GUEST_DVDL: RoutePropsI[] = [
    ROUTE_GIAY_CHUNG_NHAN_GIAI_TRI_CHI_TIET,
    ROUTE_GIAY_CHUNG_NHAN_THE_THAO_CHI_TIET,
    CHI_TIET_GNC_AN_UONG,
    CHI_TIET_GNC_MUA_SAM,
    CHITIET_COSO_LUUTRU,
    THU_HOI_GCN_AN_UONG,
    TAM_NGUNG_GCN_AN_UONG,
    THU_HOI_GCN_MUA_SAM,
    NHAPDAUKY_COSOLUUTRU,
    ROUTE_GIAY_CHUNG_NHAN_GIAI_TRI_TAM_NGUNG,
    ROUTE_CSKD_THE_THAO_TAM_NGUNG,
    ROUTE_CO_SO_LUUTRU_TAM_NGUNG,
    TAM_NGUNG_GCN_MUA_SAM
];

export const ROUTE_DS_DVDL: RoutePropsI[] = [
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG ĐẠT TIÊU CHUẨN PHỤC VỤ KHÁCH DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-co-so-kinh-doanh-an-uong",
        giaoDien: TraCuuGCNKinhDoanhAnUong,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bao-cao-co-so-kinh-doanh-an-uong",
        giaoDien: BaoCaoGCNAnUong,
    },
    {
        ten: "THỐNG KÊ DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/thong-ke-gcn-kinh-doanh-an-uong",
        giaoDien: ThongKeChungNhanKinhDoanhAnUong,
    },
    {
        ten: "BIỂU ĐỒ THỐNG KÊ CƠ SỞ KINH DOANH DỊCH VỤ ĂN UỐNG TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bieu-do-thong-ke-gcn-kinh-doanh-an-uong",
        giaoDien: BieuDoThongKeGCNKinhDoanhAnUong,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-thong-tin-cskd-the-thao",
        giaoDien: TraCuuDSCSKDichVuTheThaoComponent,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/bao-cao-dskd-the-thao",
        giaoDien: BaoCaoDSKDTheThao,
    },
    {
        ten: "THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ THỂ THAO",
        duongDan: "/dich-vu-va-du-lich/thong-ke-tinh-hinh-cskd-the-thao",
        giaoDien: ThongKeTinhHinhCSKDTheThao,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-thong-tin-cskd-vui-choi-giai-tri",
        giaoDien: TraCuuDSCSKDichVuVuiChoiGiaiTriComponent,
    },
    {
        ten: "THỐNG KÊ TÌNH HÌNH CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/thong-ke-tinh-hinh-cskd-vui-choi-giai-tri",
        giaoDien: ThongKeTinhHinhCSKDVuiChoiGiaiTri,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ",
        duongDan: "/dich-vu-va-du-lich/bao-cao-dskd-vui-choi-giai-tri",
        giaoDien: BaoCaoDSKDVuiChoiGiaiTri,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ LƯU TRÚ",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-co-so-kinh-doanh-luu-tru",
        giaoDien: TraCuuCoSoLuuTruComponent,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ LƯU TRÚ TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bao-cao-co-so-kinh-doanh-luu-tru",
        giaoDien: BaoCaoCoSoLuuTruComponent,
    },
    {
        ten: "THỐNG KÊ DANH SÁCH CƠ SỞ LƯU TRÚ TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/thong-ke-co-so-luu-tru",
        giaoDien: ThongKeCoSoLuuTruComponent,
    },
    {
        ten: "DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM",
        duongDan: "/dich-vu-va-du-lich/tra-cuu-co-so-kinh-doanh-mua-sam",
        giaoDien: TraCuuGCNKinhDoanhMuaSam,
    },
    {
        ten: "BÁO CÁO DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/bao-cao-co-so-kinh-doanh-mua-sam",
        giaoDien: BaoCaoGCNMuaSam,
    },
    {
        ten: "THỐNG KÊ DANH SÁCH CƠ SỞ KINH DOANH DỊCH VỤ MUA SẮM TRÊN ĐỊA BÀN",
        duongDan: "/dich-vu-va-du-lich/thong-ke-gcn-kinh-doanh-mua-sam",
        giaoDien: ThongKeChungNhanKinhDoanhMuaSam,
    },
];
