import { CapDuLichComponent } from "components/DichVuDuLich/DanhMuc/CapDuLich/main";
import BaoCaoKhuDuLichComponent from "components/DichVuDuLich/KhuDuLich/BaoCao/BaoCaoKhuDuLichComponent";
import ChamDutKhuDuLichComponent from "components/DichVuDuLich/KhuDuLich/BienDong/ChamDut/ChamDutKhuDuLichComponent";
import TamNgungKhuDuLichComponent from "components/DichVuDuLich/KhuDuLich/BienDong/TamNgung/TamNgungKhuDuLichComponent";
import { BieuDoKhuDuLichComponent } from "components/DichVuDuLich/KhuDuLich/BieuDo/BieuDoKhuDuLichComponent";
import CapMoiKhuDuLichComponent from "components/DichVuDuLich/KhuDuLich/GiaoDienThuLy/CapMoi/CapMoiKhuDuLichComponent";
import NhapDauKyKhuDuLichComponent from "components/DichVuDuLich/KhuDuLich/GiaoDienThuLy/NhapDauKy/NhapDauKyKhuDuLichComponent";
import ThongKeKhuDuLichComponent from "components/DichVuDuLich/KhuDuLich/ThongKe/ThongKeKhuDuLichComponent";
import ChiTietKhuDuLichComponent from "components/DichVuDuLich/KhuDuLich/TraCuu/ChiTiet/ChiTietKhuDuLichComponent";
import TraCuuKhuDiemDuLichComponent from "components/DichVuDuLich/KhuDuLich/TraCuu/TraCuuKhuDiemDuLichComponent";
import { RoutePropsI } from ".";

export const ROUTE_NHAP_DAU_KY_KDL = {
    ten: "Nhập đầu kỳ Khu/Điểm du lịch",
    duongDan: "/dich-vu-va-du-lich/dau-ky/khu-diem-du-lich",
    giaoDien: NhapDauKyKhuDuLichComponent,
};
export const ROUTE_CN_KDL: RoutePropsI[] = [
    {
        ten: "Công nhận Khu/Điểm du lịch",
        duongDan: "/dich-vu-va-du-lich/cap-moi-khu/khu-diem-du-lich",
        giaoDien: CapMoiKhuDuLichComponent,
    },
    ROUTE_NHAP_DAU_KY_KDL
];

export const ROUTE_CHI_TIET_KDL = {
    ten: "Chi tiết Khu/Điểm du lịch",
    duongDan: "/dich-vu-va-du-lich/chi-tiet/khu-diem-du-lich",
    giaoDien: ChiTietKhuDuLichComponent,
};

export const ROUTE_TAMNGUNG_KDL = {
    ten: "Tạm ngưng Khu/Điểm du lịch",
    duongDan: "/dich-vu-va-du-lich/tam-ngung/khu-diem-du-lich",
    giaoDien: TamNgungKhuDuLichComponent,
};
export const ROUTE_CHAMDUT_KDL = {
    ten: "Chậm dứt Khu/Điểm du lịch",
    duongDan: "/dich-vu-va-du-lich/cham-dut/khu-diem-du-lich",
    giaoDien: ChamDutKhuDuLichComponent,
};

export const ROUTE_PAGE_PUBLIC_DVDL: RoutePropsI[] = [

]
export const ROUTE_PAGE_KDL: RoutePropsI[] = [
    {
        ten: "DANH SÁCH KHU/ĐIỂM DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/tra-cuu/khu-diem-du-lich",
        giaoDien: TraCuuKhuDiemDuLichComponent,
    },
    {
        ten: "BÁO CÁO KHU/ĐIỂM DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/bao-cao/khu-diem-du-lich",
        giaoDien: BaoCaoKhuDuLichComponent,
    },
    {
        ten: "THỐNG KÊ KHU/ĐIỂM DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/thong-ke/khu-diem-du-lich",
        giaoDien: ThongKeKhuDuLichComponent,
    },
    {
        ten: "BIỂU ĐỒ KHU/ĐIỂM DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/bieu-do/khu-diem-du-lich",
        giaoDien: BieuDoKhuDuLichComponent,
    },
    {
        ten: "DANH MỤC CẤP DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/danh-muc-cap-du-lich",
        giaoDien: CapDuLichComponent,
    },
    
];

export const ROUTE_GUEST_KDL: RoutePropsI[] = [
    ROUTE_TAMNGUNG_KDL,
    ROUTE_CHI_TIET_KDL,
    ROUTE_CHAMDUT_KDL
];

export const ROUTE_DS_KDL: RoutePropsI[] = [
    {
        ten: "DANH SÁCH KHU/ĐIỂM DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/tra-cuu/khu-diem-du-lich",
        giaoDien: TraCuuKhuDiemDuLichComponent,
    },
    {
        ten: "BÁO CÁO KHU/ĐIỂM DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/bao-cao/khu-diem-du-lich",
        giaoDien: BaoCaoKhuDuLichComponent,
    },
    {
        ten: "THỐNG KÊ KHU/ĐIỂM DU LỊCH",
        duongDan: "/dich-vu-va-du-lich/thong-ke/khu-diem-du-lich",
        giaoDien: ThongKeKhuDuLichComponent,
    },    
];
