import { Box, Button, Collapse, Divider, Grid, TextField, Typography } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import React from "react";
import { MarkerDTO } from "../../../model/marker";
import { bootstrapTextFieldHook } from "../input/bootstrap";

export interface ResultItemProps {
    item: MarkerDTO;
    setItemEdit: (value: MarkerDTO | null) => void;
    itemEdit: MarkerDTO | null;
    handleSubmitEdit: (values: MarkerDTO) => void;
    handleRemove: () => void;
    renderDetails: (item: MarkerDTO, showDetail: boolean) => JSX.Element;
}

const ResultItem: React.FC<ResultItemProps> = ({ item, setItemEdit, itemEdit, handleSubmitEdit, handleRemove, renderDetails }) => {
    const [showDetail, setShowDetail] = React.useState<boolean>(false);

    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();

    function handleShowDetail() {
        // if (itemEdit) {
        //     setItemEdit(null);
        //     setShowDetail(!showDetail);
        //     return;
        // }
        setItemEdit(item);
        setShowDetail(!showDetail);
    }

    return (
        <Grid item xs={12}>
            <Divider />
            <Box style={{ display: "flex", marginTop: 10, justifyContent: "flex-start" }} className="grid-item">
                <RoomIcon fontSize="small" style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 5, color: "red" }} />
                <Typography variant="subtitle2" gutterBottom style={{ paddingTop: item.diaChiDayDu && item.diaChiDayDu.length < 30 ? 9 : 0 }}>
                    <span>{item.diaChiDayDu ? item.diaChiDayDu : item.diaChiDayDu}</span>{" "}
                    <span style={{ color: item.kinhDo && item.viDo ? "green" : "red" }}>{`(${
                        item.kinhDo && item.viDo ? "Đã chọn tọa độ" : "Chưa chọn tọa độ"
                    })`}</span>
                </Typography>
            </Box>
            <Box style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Collapse in={showDetail}>
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <TextField
                            fullWidth
                            label={"Kinh độ:"}
                            type="text"
                            value={itemEdit ? Math.round(Number(itemEdit.kinhDo) * 100000) / 100000 : Math.round(Number(item.kinhDo) * 100000) / 100000}
                            disabled={itemEdit?.kinhDo === item.kinhDo ? false : true}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{
                                disableUnderline: true,
                                classes: inputStyles,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Vĩ độ:"}
                            type="text"
                            value={itemEdit ? Math.round(Number(itemEdit.viDo) * 100000) / 100000 : Math.round(Number(item.viDo) * 100000) / 100000}
                            disabled={itemEdit?.viDo === item.viDo ? false : true}
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{
                                disableUnderline: true,
                                classes: inputStyles,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="button-group" marginTop="5px">
                            <Button
                                variant="contained"
                                className="button_mobile"
                                color="primary"
                                disabled
                                // disabled={itemEdit === null}
                                disableElevation
                                onClick={() => handleSubmitEdit(item)}
                            >
                                Lưu
                            </Button>
                            <Button
                                variant="contained"
                                className="button_mobile"
                                color="default"
                                disabled
                                // disabled={itemEdit !== null}
                                disableElevation
                                onClick={() => setItemEdit(item)}
                            >
                                Đặt lại tọa độ
                            </Button>
                            <Button
                                variant="contained"
                                className="button_mobile"
                                color="secondary"
                                disabled
                                // disabled={itemEdit === null}
                                disableElevation
                                onClick={handleRemove}
                            >
                                Xóa
                            </Button>
                        </Box>
                    </Grid>
                </Collapse>
                {renderDetails(item, showDetail)}
            </Box>
            <span style={{ margin: 20, color: "rgb(54, 127, 169)", cursor: "pointer" }} onClick={handleShowDetail}>
                {showDetail ? "Quay lại kết quả tìm kiếm" : "Xem chi tiết"}
            </span>
            <Divider style={{ marginTop: 10 }} />
        </Grid>
    );
};

export default ResultItem;
