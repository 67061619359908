import { HoSoThuLyDichVuDuLichSearchRequest } from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";

export const HoSoThuLyDichVuDuLichSearchRequest_INIT: HoSoThuLyDichVuDuLichSearchRequest = {
    soQuyetDinh: "",

    tenCoSo: "",

    tenTrangThai: "",

    maTrangThai: "",

    maHuyen: "",

    tenHuyen: "",

    maPhuong: "",

    tenPhuong: "",

    ngayCapChungNhanTu: 0,

    ngayCapChungNhanDen: 0,

    pageNumber: 1,

    pageSize: 5,

    keywords: "",

    hoatDong: true,
};
