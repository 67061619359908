import { Grid, InputLabel, NativeSelect, InputBase } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DashboardDichVuDuLichComponent } from '../../components/DichVuDuLich/Dashboard/DashboardDichVuDuLichComponent';
import { DashboardDiSanComponent } from '../../components/DiSanVanHoa/Dashboard/DashboardDiSanComponent';
import { DashboardLuHanhComponent } from '../../components/LuHanhNoiDia/Dashboard/DashboardLuHanhComponent';
import { OverlayLoading } from '../../components/overlayLoading/overlayLoading';
import { LoadingAction } from '../../redux/actions/applicationAction';
export const DashboardPublic = () => {
    const [loaiDashboard, setLoaiDashboard] = useState<string>('1');
    useEffect(() => {
    }, []);
    return (
        <>
            <Grid container spacing={2} direction="row" alignItems="center">
                <OverlayLoading />
                <Grid item xs={6} className="gridItem">
                    <InputLabel >Dashboard</InputLabel>
                    <NativeSelect input={<InputBase fullWidth />}
                        value={loaiDashboard} onChange={(e) => setLoaiDashboard(e.target.value as string)} >
                        <option value={'1'}>Cơ sở kinh doanh đạt chuẩn</option>
                        <option value={'2'}>Doanh nghiệp lữ hành nội địa</option>
                        <option value={'3'}>Quản lý di sản</option>
                    </NativeSelect>
                </Grid>
                <Grid item xs={12} className="gridItem">
                    {
                        loaiDashboard === '1' &&
                        <DashboardDichVuDuLichComponent />
                    }
                    {
                        loaiDashboard === '2' &&
                        <DashboardLuHanhComponent />
                    }
                    {
                        loaiDashboard === '3' &&
                        <DashboardDiSanComponent />
                    }

                </Grid>
            </Grid>
        </>
    )
}
