import { ThongKeGiayChungNhan, ThongKeGiayChungNhanReponse } from "../ThongKeGiayChungNhan";
import { ThongKeGiayPhepToChucNT, ThongKeGiayPhepToChucNTReponse } from "./ThongKeGiayPhepToChucNT";

export const ThongKeGiayPhepToChucNT_INIT: ThongKeGiayPhepToChucNTReponse = {
    tongCapMoi: 0,

    tongCapLai: 0,

    hoSoThuLyId: 0,

    tongThuHoi: 0,

    ngayCapChungNhanTu: 0,

    ngayCapChungNhanDen: 0,

    thongKeGiayPhepToChucNT: new Array<ThongKeGiayPhepToChucNT>(),
};
