import { Box, Checkbox, createStyles, Grid, InputBase, InputLabel, makeStyles, TextField, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { DanhMucLoaiDiSanService } from "apis/danhMucCN/DanhMucLoaiDiSanService";
import React, { useEffect, useState } from "react";
import { BaseResponse } from "apis/baseService";
import { DanhMucLoaiDiSan } from "../../../../model/DiSanVanHoa/DiSanVanHoaModel";
import StringUtil from "../../../../utils/stringUtils";
import { DialogContent, DialogTitle } from "../../../commons/dialog/dialogCommons";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

interface IModalProps {
    onSubmitData: (resp: BaseResponse) => void;
    onDialogClose: () => void;
    openDialog: boolean;
    itemEdit: DanhMucLoaiDiSan;
}

type ErrorValidation = {
    name: string;
    code: string;
    group: string;
};

export const DanhMucLoaiDiSanEditDialog = (props: IModalProps) => {
    const classes = useStyles();

    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();

    const [openDialog, setOpenDialog] = useState(false);
    const [itemEditDTO, setItemEditDTO] = useState<DanhMucLoaiDiSan>({} as DanhMucLoaiDiSan);
    const [errors, setErrors] = useState<ErrorValidation>({} as ErrorValidation);

    useEffect(() => {
        setItemEditDTO(props.itemEdit);
        setOpenDialog(props.openDialog);
    }, [props.itemEdit, props.openDialog]);

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setItemEditDTO((itemEditDTO) => ({
            ...itemEditDTO,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    function validateFrom(): boolean {
        let errorValidation = {} as ErrorValidation;
        if (StringUtil.isNullOrEmty(itemEditDTO.name)) {
            errorValidation.name = "Tên danh mục bắt buộc nhập";
        } else if (itemEditDTO.name.trim().length > 255) {
            errorValidation.name = "Tên danh mục không vượt quá 255 ký tự";
        }

        setErrors(errorValidation);
        return Object.keys(errorValidation).length === 0;
    }

    const submitData = async (callback: (resp: BaseResponse) => void) => {
        if (validateFrom()) {
            await new DanhMucLoaiDiSanService().insertOrUpdate(itemEditDTO).then((res) => {
                callback(res);
            });
        }
    };

    const onResetData = () => {
        setItemEditDTO((itemEditDTO) => ({
            ...itemEditDTO,
            name: "",
            code: "",
            description: "",
            active: true,
        }));
    };

    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    {itemEditDTO.id < 0 ? "Thêm mới danh mục" : "Cập nhật danh mục"}
                </DialogTitle>
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid spacing={2} container>
                            <Grid item xs={12} className="gridItem">
                                <TextField
                                    fullWidth
                                    label={"Tên danh mục"}
                                    required
                                    name="name"
                                    value={itemEditDTO.name}
                                    onChange={handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    error={Boolean(errors.name)}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                    FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                    helperText={Boolean(errors.name) && errors.name}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} className="gridItem">
                                <InputLabel classes={labelStyles}>Mô tả</InputLabel>
                                <InputBase
                                    fullWidth
                                    rows={3}
                                    multiline
                                    classes={inputStyles}
                                    value={itemEditDTO.description}
                                    name="description"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} className="gridItem" style={{ paddingLeft: 5 }}>
                                <Checkbox
                                    name="active"
                                    checked={itemEditDTO.active}
                                    color="default"
                                    size="small"
                                    value={itemEditDTO.active}
                                    onChange={handleChange}
                                />{" "}
                                Hoạt động
                            </Grid>

                            <Grid item xs={12} className="gridItem">
                                <Box marginTop="5px">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        className={classes.button}
                                        onClick={() => {
                                            submitData(props.onSubmitData);
                                        }}
                                    >
                                        Lưu
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={onResetData} disableElevation className={classes.button}>
                                        Xóa
                                    </Button>
                                    <Button variant="contained" color="default" disableElevation className={classes.button} onClick={props.onDialogClose}>
                                        Trở về
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
