import { Box, Checkbox, createStyles, Grid, InputBase, InputLabel, makeStyles, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect, useState } from "react";
import { Cell, Row } from "components/commons/table/tableCommons";
import { Pagination } from "@material-ui/lab";
import { HoSoThuLyDiSanVanHoa } from "model/hoSoThuLyDiSanVanHoa";
import moment from "moment";
import { CustomInput } from "components/commons/input/customInput";
import DateUtil from "utils/dateUtils";
import StringUtil from "utils/stringUtils";
import { DialogContent, DialogTitle } from "components/commons/dialog/dialogCommons";
import { OverlayLoading } from "components/commons/overlayLoading/overlayLoading";
import { HoSoThuLyDichVuDuLich } from "model/DichVuDuLich/HoSoThuLyDichVuDuLich";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

interface IModalProps {
    dataList: HoSoThuLyDichVuDuLich[];
    pageProps: TablePaginationActionsProps;
    onDialogClose: () => void;
    openDialog: boolean;
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const ChiTietThongKeTheThaoDialog = (props: IModalProps) => {
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [rows, setRows] = useState<any[]>([]);

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.rowsPerPage);
        setOpenDialog(props.openDialog);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    function getDiaChi(soNha: string, tenDuong: string, tenPhuong: string, tenHuyen: string) {
        var result = "";
        if (!StringUtil.isNullOrEmty(soNha)) result = result.concat(soNha);
        if (!StringUtil.isNullOrEmty(tenDuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenDuong);
            else result = result.concat(", ", tenDuong);
        }
        if (!StringUtil.isNullOrEmty(tenPhuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenPhuong);
            else result = result.concat(", ", tenPhuong);
        }
        if (!StringUtil.isNullOrEmty(tenHuyen)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenHuyen);
            else result = result.concat(", ", tenHuyen);
        }
        return result;
    }
    return (
        <>
            <Dialog onClose={props.onDialogClose} fullWidth maxWidth="md" aria-labelledby="customized-dialog-title" open={openDialog}>
                <DialogTitle id="customized-dialog-title" onClose={props.onDialogClose}>
                    Thông tin
                </DialogTitle>
                <OverlayLoading />
                <DialogContent dividers>
                    <Box padding={0}>
                        <Grid container>
                            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <Cell component="th" scope="row" align="center" width="5%">
                                                STT
                                            </Cell>
                                            <Cell component="th" scope="row" align="center" width="15%">
                                                Số giấy phép
                                            </Cell>
                                            <Cell component="th" scope="row" align="center" width="10%">
                                                Ngày cấp
                                            </Cell>
                                            <Cell component="th" scope="row" align="center" width="10%">
                                                Tên doanh nghiệp
                                            </Cell>
                                            <Cell component="th" scope="row" align="center">
                                                Địa chỉ
                                            </Cell>
                                            <Cell component="th" scope="row" align="center" width="10%">
                                                Điện thoại
                                            </Cell>
                                            <Cell component="th" scope="row" align="center" width="10%">
                                                Tình trạng
                                            </Cell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.pageProps.count > 0 ? (
                                            rows.map((row, i) => (
                                                <Row key={i}>
                                                    <Cell> {(props.pageProps.page - 1) * rowsPerPage + i + 1}</Cell>
                                                    <Cell>
                                                            {row.soGiayPhep}
                                                    </Cell>
                                                    <Cell>
                                                        {row.ngayCap && row.ngayCap !== undefined && row.ngayCap !== null
                                                            ? moment(new Date(row.ngayCap)).format("DD/MM/YYYY")
                                                            : ""}
                                                    </Cell>
                                                    <Cell>{row.tenDoanhNghiep}</Cell>
                                                    <Cell>{row.diaChiDayDu}</Cell>
                                                    <Cell>{row.dienThoai}</Cell>
                                                    <Cell>{row.tenTrangThai}</Cell>
                                                </Row>
                                            ))
                                        ) : (
                                            <Row key={"empty"}>
                                                <Cell colSpan={12} scope="row" align="center">
                                                    Không có dữ liệu
                                                </Cell>
                                            </Row>
                                        )}
                                    </TableBody>
                                </Table>
                                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                                    <Pagination
                                        count={totalPage}
                                        defaultPage={1}
                                        siblingCount={1}
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        shape="rounded"
                                        showFirstButton
                                        showLastButton
                                        onChange={props.pageProps.onChangePage}
                                    />
                                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                                        <Box component="span" paddingX="5px">
                                            Số mẫu tin trên trang
                                        </Box>
                                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage}>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </NativeSelect>
                                    </Box>
                                    <Box component="span" paddingX="5px">
                                        Mẫu tin {(props.pageProps.page - 1) * props.pageProps.rowsPerPage + 1} -{" "}
                                        {props.pageProps.count > props.pageProps.page * props.pageProps.rowsPerPage
                                            ? props.pageProps.page * props.pageProps.rowsPerPage
                                            : props.pageProps.count}{" "}
                                        / {props.pageProps.count}
                                    </Box>
                                </Box>
                            </TableContainer>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
