import AXIOS_INSTANCE from "..";
import { RequestBaoCaoThongKeDTO } from "../../components/LuHanhNoiDia/ThuLyHoSo/BaoCao/BaoCaoLHND";
import { RequestThongKeDTO } from "../../components/LuHanhNoiDia/ThuLyHoSo/ThongKe/ThongKeLHND";
import { GiayPhepKinhDoanhLuHanhDTO, ThongKeTinhHinhCapPhepResponse } from "../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { BaseService, Pagination } from "../baseService";
export interface DashboardLuHanhDTO {
    thang: number;
    thuHoi: number;
    hoatDong: number;
    tenThang: string;
}
export interface DashboardLuHanhRequestDTO {
    namThongKe: number;
    tenNamThongKe : string;
}
export class ThongKeService extends BaseService {
    thongKe = async (request: RequestBaoCaoThongKeDTO): Promise<ThongKeTinhHinhCapPhepResponse> => {
        let result = {} as ThongKeTinhHinhCapPhepResponse;
        await AXIOS_INSTANCE
            .post("luhanh/thong-ke/thong-ke-tinh-hinh-cap-phep", request, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    baoCaoThongKe = async (request: RequestBaoCaoThongKeDTO): Promise<Pagination<GiayPhepKinhDoanhLuHanhDTO[]>> => {
        let result: Pagination<GiayPhepKinhDoanhLuHanhDTO[]> = {
            page: request.pageNumber,
            size: request.pageSize,
            total: 0,
            rows: [],
        };
        await AXIOS_INSTANCE
            .post("luhanh/thong-ke/bao-cao-thong-ke-giay-phep", request, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.data.data) {
                    result = {
                        ...result,
                        total: response.data.data.total,
                        rows: response.data.data.data,
                    };
                }
            })
            .catch();
        return result;
    };

    downloadFileBaoCao = (request: RequestBaoCaoThongKeDTO) => {
        return AXIOS_INSTANCE.get("luhanh/thong-ke/export-bao-cao-thong-ke-giay-phep", {
            responseType: "blob",
            params: request,
        });
    };
    downloadFileThongKe = (request: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.get("luhanh/thong-ke/export-thong-ke-tinh-hinh-cap-phep", {
            responseType: "blob",
            params: request,
        });
    };
    loadDataDashboard = (requestBody: DashboardLuHanhRequestDTO) => {
        return AXIOS_INSTANCE.post("lu-hanh/dashboard/load-data", requestBody);
    };

    chiTietThongKe = (request: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.post("luhanh/thong-ke/chi-tiet-thong-ke", request);
    };
}

