import { DanhMucCapDiSan, SearchRequest } from "../../model/DiSanVanHoa/DiSanVanHoaModel";
import { BaseResponse, BaseService } from "../baseService";
import axios from "../index";

export class DanhMucCapDiSanService extends BaseService {
    getAll = async (): Promise<{ rows: DanhMucCapDiSan[] }> => {
        var list: DanhMucCapDiSan[] = [];
        await axios
            .post("/danh-muc-cap-di-san/get-all", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        list = response.data.data;
                    }
                }
            })
            .catch((error) => {});
        return { rows: list };
    };

    getGroupRoles = async (request: SearchRequest): Promise<{ rows: DanhMucCapDiSan[]; total: number }> => {
        var list: DanhMucCapDiSan[] = [];
        var total: number = 0;
        await axios
            .post("/danh-muc-cap-di-san/search", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        total = response.data.data.total;
                        list = response.data.data.data;
                    }
                }
            })
            .catch((error) => {});
        return { rows: list, total: total };
    };

    insertOrUpdateGroupRole = async (request: DanhMucCapDiSan): Promise<BaseResponse> => {
        let requestBody = {
            id: request.id,
            name: request.name,
            code: request.code,
            description: request.description,
            active: request.active,
        } as DanhMucCapDiSan;
        let result = {} as BaseResponse;
        await axios
            .post("/danh-muc-cap-di-san/add-or-update", JSON.stringify(requestBody), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status !== 200) {
                    result = { isSuccess: false, message: "Có lỗi xảy ra, thực hiện lại!" };
                }
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch((error) => {
                result = { isSuccess: false, message: "Lỗi khi thực hiện kết nối server" };
            });
        return result;
    };
}
