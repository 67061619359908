import { FileEntry } from "../../apis/Common/fileEntry";
import { GiayChungNhanKinhDoanh, LichSuGiayChungNhanKinhDoanh } from "../../model/DichVuDuLich/GiayChungNhanKinhDoanh";
export const GiayChungNhanKinhDoanh_INIT: GiayChungNhanKinhDoanh = {
    hoSoThuLyId: 0,

    giayChungNhanId: 0,

    nguoiTaoId: "",

    ngayCapNhat: 0,

    ngayTao: 0,

    ngayCap: 0,

    ngayHetHan: 0,

    ngayCapGCN: 0,

    ngayThuHoi: 0,

    ngayQuyetDinhThuHoi: 0,

    classNameGCN: "",

    maHoSo: "",

    maLinhVuc: "",

    maThuTuc: "",

    soQuyetDinh: "",

    tenCoSo: "",

    soGCN: "",

    noiCapGCN: "",

    coQuanCapGCN: "",

    fax: "",

    email: "",

    website: "",

    maHuyen: "",

    tenHuyen: "",

    maPhuong: "",

    tenPhuong: "",

    maDuong: "",

    tenDuong: "",

    soNha: "",

    diaChiDayDu: "",

    maTrangThai: "",

    tenTrangThai: "",

    maBienDong: "",

    tenBienDong: "",

    lyDo: "",

    soDienThoai: "",

    tongSoNguoi: "",

    soQuyetDinhThuHoi: "",

    ghiChu: "",

    lyDoThuHoi: "",

    donViCapPhep: false,

    duDieuKienTranTu: false,

    phongChayChuaChay: false,

    baoVeMoiTruong: false,

    anToanThucPham: false,

    hoatDong: true,

    thuHoi: false,

    files: new Array<FileEntry>(),

    fileThuHois: new Array<FileEntry>(),

    fileCapMois: new Array<FileEntry>(),

    lichSus: new Array<LichSuGiayChungNhanKinhDoanh>(),

    maMauTrangThai: 0,
};
