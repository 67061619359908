import MomentUtils from "@date-io/moment";
import { Button, Grid, InputBase, InputLabel, Link, Snackbar, SvgIcon, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createFileType, downLoadFile } from "../../../apis/Common/exportUtil";
import { DanhMucService } from "../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../apis/danhMuc/model/DanhMucDTO";
import { DanhMucLoaiDiSanService } from "../../../apis/danhMucCN/DanhMucLoaiDiSanService";
import { DiSanVanHoaPhiVatTheService } from "../../../apis/DiSanVanHoa/DiSanVanHoaPhiVatTheService";
import { AlertMessage, CustomAlert } from "../../../components/commons/alert";
import ButtonExt from "../../../components/commons/button/customButton";
import { bootstrapTextFieldHook } from "../../../components/commons/input/bootstrap";
import { DanhMucLoaiDiSan, DanhMucIView } from "../../../model/DiSanVanHoa/DiSanVanHoaModel";
import { DiSanVanHoaPhiVatThe } from "../../../model/diSanVanHoaPhiVatThe";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { ListDiSanVanHoaPhiVatThe } from "./listDiSanVanHoaPhiVatThe";

export interface SearchPVTRequest {
    soQuyetDinh: string;
    ngayQuyetDinhTu: number;
    ngayQuyetDinhDen: number;
    loaiDiSanId: number;
    tenLoaiDiSan: string;
    maHuyen: string;
    tenHuyen: string;
    maPhuong: string;
    tenPhuong: string;
    tenDiSan: string;
    pageSize: number;
    pageNumber: number;
}

export const SearchDiSanVanHoaPhiVatThe = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [total, setTotal] = useState(0);
    const [listDiSan, setListDiSan] = useState<DiSanVanHoaPhiVatThe[]>([]);
    const [listLoaiDiSan, setListLoaiDiSan] = useState<DanhMucIView[]>([]);
    const [searchDSVHRequest, setSearchDSVHRequest] = useState<SearchPVTRequest>({
        soQuyetDinh: "",
        ngayQuyetDinhTu: 0,
        ngayQuyetDinhDen: 0,
        loaiDiSanId: 0,
        tenLoaiDiSan: "",
        maHuyen: "",
        tenHuyen: "",
        maPhuong: "",
        tenPhuong: "",
        tenDiSan: "",
        pageSize: 5,
        pageNumber: 1,
    });

    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const history = useHistory<any>();
    const themMoi = () => {
        history.push("/di-san-van-hoa-phi-vat-the/them-moi", {});
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setSearchDSVHRequest({ ...searchDSVHRequest, [e.target.name]: value });
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...searchDSVHRequest, [nameValue]: value?.ma, [nameText]: valueText };
        setSearchDSVHRequest(dataNew);
    };

    const handleChangeSelectLoaiDiSan = (value: any, id: string, name: string) => {
        let dataNew = { ...searchDSVHRequest, [id]: value?.id, [name]: value?.name };
        setSearchDSVHRequest(dataNew);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...searchDSVHRequest, [filedName]: date?.toDate()?.getTime() };
        setSearchDSVHRequest(dataNew);
    };

    const loadListDiSan = (searchRequest: SearchPVTRequest) => {
        //dispatch(showLoading());
        new DiSanVanHoaPhiVatTheService()
            .getList(searchRequest)
            .then((p) => {
                setListDiSan(p.rows ? p.rows : []);
                setTotal(p.total);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                //dispatch(hideLoading());
            });
    };

    const loadListLoaiDiSan = async () => {
        await new DanhMucLoaiDiSanService()
            .getAll()
            .then((p) => {
                setListLoaiDiSan(p.rows ? p.rows : []);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        let state = history?.location?.state;
        let code = state?.errorCode;
        if (code === "SUCCESSFUL") {
            showMessageAlert("Yêu cầu thực hiện thành công", true);
            delete state?.errorCode;
            history.replace({ ...history.location, state });
        }
        loadListLoaiDiSan();
        loadListDiSan({ ...searchDSVHRequest });
        loadQuans("92");
    }, []);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadPhuongs = () => {
        if(searchDSVHRequest?.maHuyen)
        danhMucService
            .getDanhMucDiaChi(searchDSVHRequest?.maHuyen)
            .then((res) => {
                setPhuongs(res);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const doSearch = () => {
        let searchNew = { ...searchDSVHRequest, pageNumber: 1 };
        setSearchDSVHRequest(searchNew);
        loadListDiSan(searchNew);
    };

    const reload = () => {
        let searchNew = { soQuyetDinh: "",
            ngayQuyetDinhTu: 0,
            ngayQuyetDinhDen: 0,
            loaiDiSanId: 0,
            tenLoaiDiSan: "",
            maHuyen: "",
            tenHuyen: "",
            maPhuong: "",
            tenPhuong: "",
            tenDiSan: "",
            pageSize: 5,
            pageNumber: 1, };
        setSearchDSVHRequest(searchNew);
        loadListDiSan(searchNew);
    }

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchDSVHRequestNew = { ...searchDSVHRequest, pageNumber: value };
        setSearchDSVHRequest(searchDSVHRequestNew);
        loadListDiSan(searchDSVHRequestNew);
    };
    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchDSVHRequestNew = { ...searchDSVHRequest, pageSize: Number(event.target.value) };
        searchDSVHRequestNew.pageNumber = 1;
        setSearchDSVHRequest(searchDSVHRequestNew);
        loadListDiSan(searchDSVHRequestNew);
    };

    const downloadFile = async (extension: string) => {
        let request = {
            ...searchDSVHRequest,
            exportType: extension,
            exportFileName: "Danh_sách_DSVH_phi_vật_thể",
        };
        console.log("downloadFile: ");
        dispatchLoading(showLoading());
        await new DiSanVanHoaPhiVatTheService()
            .downloadFileTraCuu(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "Danh_sách_DSVH_phi_vật_thể");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSearchDSVHRequest({
            ...searchDSVHRequest,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSearchDSVHRequest({
            ...searchDSVHRequest,
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    return (
        <div className="dash-content">
        <Grid container>
            <div className="dash-content">
                <div className="dash-title">
                    <span>DANH SÁCH DI SẢN VĂN HÓA PHI VẬT THỂ</span>
                </div>
            </div>
            <Grid item xs={12} container>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Số quyết định </InputLabel>
                    <InputBase classes={inputStyles} fullWidth value={searchDSVHRequest.soQuyetDinh} name="soQuyetDinh" onChange={handleChange} />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <InputLabel classes={labelStyles}>Ngày quyết định từ</InputLabel>
                        <KeyboardDatePicker
                            autoOk={true}
                            value={
                                searchDSVHRequest.ngayQuyetDinhTu !== 0 && searchDSVHRequest.ngayQuyetDinhTu !== undefined
                                    ? new Date(searchDSVHRequest.ngayQuyetDinhTu)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhTu")}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <InputLabel classes={labelStyles}>Ngày quyết định đến</InputLabel>
                        <KeyboardDatePicker
                            autoOk={true}
                            value={
                                searchDSVHRequest.ngayQuyetDinhDen !== 0 && searchDSVHRequest.ngayQuyetDinhDen !== undefined
                                    ? new Date(searchDSVHRequest.ngayQuyetDinhDen)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            fullWidth
                            InputAdornmentProps={{ className: "button-date-picker" }}
                            variant="inline"
                            InputLabelProps={{ classes: labelStyles }}
                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                            invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhDen")}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Loại di sản văn hóa </InputLabel>
                    <Autocomplete
                        classes={inputStyles}
                        id="tenLoaiDiSan"
                        noOptionsText={"Không có dữ liệu!!!"}
                        value={{ id: searchDSVHRequest.loaiDiSanId, name: searchDSVHRequest.tenLoaiDiSan }}
                        options={listLoaiDiSan}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => handleChangeSelectLoaiDiSan(newValue, "loaiDiSanId", "tenLoaiDiSan")}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Huyện/Thị/TP</InputLabel>
                    <Autocomplete
                        classes={inputStyles}
                        id="maHuyenThiTp"
                        noOptionsText={"Không có Huyện/Thị/TP"}
                        options={quans}
                        value={{ code: searchDSVHRequest.maHuyen, name: searchDSVHRequest.tenHuyen }}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event: any, newValue: any) => {
                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Phường/Xã/TT</InputLabel>
                    <Autocomplete
                        classes={inputStyles}
                        id="maPhuongXaTT"
                        noOptionsText={"Không có Phường/Xã/TT"}
                        options={phuongs}
                        onFocus={loadPhuongs}
                        value={{ code: searchDSVHRequest.maPhuong, name: searchDSVHRequest.tenPhuong }}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event: any, newValue: any) => handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong")}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <InputLabel classes={labelStyles}>Tên di sản </InputLabel>
                    <InputBase classes={inputStyles} fullWidth value={searchDSVHRequest.tenDiSan} name="tenDiSan" onChange={handleChange} />
                </Grid>
            </Grid>
            <Grid item xs={12} className="button-group-center">
                <Button variant="contained" color="primary" disableElevation onClick={doSearch}>
                    Tìm kiếm
                </Button>
                <ButtonExt
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                        themMoi();
                    }}
                >
                    Thêm mới
                </ButtonExt>
                <Button variant="contained" color="primary" disableElevation onClick={reload}>
                    Làm mới
                </Button>
            </Grid>
            <Grid item xs={12} className="grid-item" container justify="flex-end">
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("docx");
                    }}
                >
                    <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                        <path
                            fill="#007bff"
                            d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                        />
                    </SvgIcon>
                </Link>
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("pdf");
                    }}
                >
                    <SvgIcon fontSize="large">
                        <path
                            fill="#dc3545"
                            d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                        />
                    </SvgIcon>
                </Link>
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("xlsx");
                    }}
                >
                    <SvgIcon fontSize="large">
                        <path
                            fill="#28a745"
                            d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                        />
                    </SvgIcon>
                </Link>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <ListDiSanVanHoaPhiVatThe
                    dataList={listDiSan}
                    pageProps={{
                        page: searchDSVHRequest.pageNumber,
                        count: total,
                        rowsPerPage: searchDSVHRequest.pageSize,
                        onChangePage: handleOnChangePage,
                        onChangeRowsPerPage: handOnChangeRowsPerPage,
                    }}
                />
            </Grid>

            <Snackbar
                open={alertMessage.open}
                onClose={handleCloseAlertMessage}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
            >
                <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                    {alertMessage.content}
                </CustomAlert>
            </Snackbar>
        </Grid>
        </div>
    );
};
