import AXIOS_INSTANCE from "..";
import { RequestBieuDoThongKeDTO } from "../../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/BieuDoThongKeCSKDTheThao";
import { RequestThongKeDTO } from "../../components/HoSoThuLyDichVuDuLich/GiaoDien/TheThao/ThongKeTinhHinhCSKDTheThao";
import { ThongKeTinhHinhCSKDResponse } from "../../components/HoSoThuLyDichVuDuLich/model/BaoCaoThongKeCSKDDTO";
import { LichSuThuLyDichVuDuLich } from "../../components/HoSoThuLyDichVuDuLich/model/lichSuThuLyDichVuDuLich";
import { HoSoThuLyDichVuDuLich_INIT } from "../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLich, HoSoThuLyDichVuDuLichSearchRequest } from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { BaseService } from "../baseService";

export class GiayChungNhanTheThaoService extends BaseService {
    getListDichVuDuLichTheThao = (requestBody: HoSoThuLyDichVuDuLichSearchRequest) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-the-thao/get-list-data-dich-vu-the-thao", requestBody);
    };
    downloadFileCSKDTheThao = (request: HoSoThuLyDichVuDuLichSearchRequest) => {
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-the-thao/export-file-tra-cuu-the-thao", {
            responseType: "blob",
            params: request,
        });
    };
    getDetailDichVuTheThao = async (id: number): Promise<HoSoThuLyDichVuDuLich> => {
        var result: HoSoThuLyDichVuDuLich = HoSoThuLyDichVuDuLich_INIT;
        await AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-the-thao/get-chi-tiet-dich-vu-the-thao", { id: id }, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    result = { ...response.data.data };
                }
            })
            .catch();
        return result;
    };

    getLichSuDichVuDuLich = async (id: number, classNameGCN: string): Promise<{ rows: LichSuThuLyDichVuDuLich[]; total: number }> => {
        var list: LichSuThuLyDichVuDuLich[] = [];
        var total: number = 0;
        await AXIOS_INSTANCE.post(
            "dich-vu-du-lich/gcn-the-thao/get-lich-su-dich-vu-du-lich-the-thao",
            { id: id, classNameGCN: classNameGCN },
            this.getTokenRequestHeaders()
        )
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL") {
                    list = response.data.data.data;
                    total = response.data.data.data;
                }
            })
            .catch();
        return { rows: list, total: total };
    };

    thongKeCSKDTheThao = async (request: RequestThongKeDTO): Promise<ThongKeTinhHinhCSKDResponse> => {
        let result = {} as ThongKeTinhHinhCSKDResponse;
        await AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-the-thao/thong-ke-tinh-hinh-cskd-the-thao", request, this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    downloadFileThongKeCSKDTheThao = (request: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-the-thao/export-thong-ke-cskd-the-thao", {
            responseType: "blob",
            params: request,
        });
    };

    bieuDoThongkeCSKDTheThao = (requestBody: RequestBieuDoThongKeDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-the-thao/bieu-do-thong-ke-cskd-the-thao", requestBody);
    };

    thuHoi = (thuHoi: LichSuThuLyDichVuDuLich) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-the-thao/save-or-update-thu-hoi", thuHoi, this.getTokenRequestHeaders());
    };

    chiTietGiayChungNhanById(giayChungNhanId: number) {
        let body = {
            giayChungNhanId: giayChungNhanId,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-the-thao/get-by-id", {
            params: body,
        });
    };

    chiTietGiayTamNgung(giayChungNhanId: number, classNameGCN: string) {
        let body = {
            giayChungNhanId: giayChungNhanId,
            classNameGCN: classNameGCN,
        };
        return AXIOS_INSTANCE.get("dich-vu-du-lich/gcn-the-thao/chi-tiet-tam-ngung", {
            params: body,
        });
    };

    chiTietThongKe = (request: RequestThongKeDTO) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/gcn-the-thao/chi-tiet-thong-ke", request);
    };
}
