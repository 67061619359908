
export interface SearchRequest {
    pageSize: number;
    pageNumber: number;
    keywords: string;
    hoatDong: number;
}
export interface DanhMucI {
    id: number;
    ten: string;
    ma: string;
    hoatDong: boolean;
}
export interface DanhMucIView {
    ten: string;
    ma: string;
}
export const DanhMucI_INIT: DanhMucI = {
    id: 0,
    ten: '',
    ma: '',
    hoatDong: true
}
export interface DanhMucIView_EN {
    name: string;
    code: string;
}