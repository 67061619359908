import { Box, Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { useRef, useState } from "react";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { FileServerService } from "../../../apis/Common/FileServerService";
import { createFileType, downLoadFile } from "../../../apis/Common/exportUtil";
import { FileEntry } from "../../../apis/Common/fileEntry";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(0.5),
        },
        menuItem: {
            color: "#0821af",
            textDecoration: "none",
            padding: "10px 15px",
            '&:hover': {
                color: "#0821af",
                cursor: "pointer",
                backgroundColor: "#bfc1cc",
                fontWeight: "bold",
            },
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px'
        }
    }),
);

export const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
        },
        sizeSmall: {
            fontSize: '13px'
        },
        startIcon: {
            marginRight: theme.spacing(0.5)
        },
        '&:focus': {
            outlineWidth: '0px',
            outlineStyle: 'none'
        }
    }),
)(Button);

export interface TepDinhKemPopperProps {
    data: FileEntry[];
}

export const TepDinhKemPopper = (props: TepDinhKemPopperProps) => {
    const classes = useStyles();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const fileServerService = new FileServerService();
    const showHidePopperThaoTac = () => {
        setOpen(!open);
    }
    const handleClosePopperThaoTac = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const handleToggle = () => {
        setShowModal(!showModal);
    }
    const handleDownloadFile = (file: FileEntry) => {
        dispatchLoading(showLoading());
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        }).finally(() => {
            dispatchLoading(hideLoading());
        });
    }
    return (
        <>
            <CustomButton className={classes.margin} startIcon={<AttachFileIcon />}
                size="small" color="primary" disableRipple
                ref={anchorRef} aria-haspopup="true"
                aria-controls={open ? 'menu-list-grow' : undefined}  onClick={(e) => {
                    showHidePopperThaoTac();
                }} >
                {"(" + props.data?.length + ")"}
            </CustomButton>
            <Popper disablePortal open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClosePopperThaoTac}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                    {
                                        props.data?.map((file, k) => {
                                            return (
                                                <MenuItem key={k}>
                                                    <CustomButton className={classes.margin}
                                                        size="small" color="primary" disableRipple
                                                        onClick={() => handleDownloadFile(file)}
                                                    >
                                                        {file.name}
                                                    </CustomButton>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}