import MomentUtils from "@date-io/moment";
import { Box, Checkbox, createStyles, Grid, InputLabel, makeStyles, Snackbar, TextField, Theme, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../apis";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { HoSoThuLyDichVuDuLichService } from "../../../../apis/DichVuDuLich/HoSoThuLyDichVuDuLichService";
import { HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import { ThongTinDungChungService } from "apis/hoSoCuaToi/thongTinDungChungService";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import { ACCEPT_FILES, LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from "../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDichVuDuLich_INIT } from "../../../../init/DichVuDuLich/HoSoThuLyDichVuDuLich_INIT";
import { HoSoThuLyDichVuDuLich } from "../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { ThongTinChuyenNhanhUtil } from "../../../../utils/thongTinChuyenNhanhUtil";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import DropZoneCustom from "../../../commons/hoso/CustomDropzone/DropZoneCustom";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { ChonToaDoComponent } from "../../../commons/map/ChonToaDoComponent";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import DanhSachDuThaoModal from "../../thuly/danhSachDuThaoModal";
import ThuLyButtonComponent from "../../thuly/ThuLyButtonComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

type ErrorValidation = {
    soGiayChungNhan: string;
    ngayCap: string;
    tenToChuc: string;
    maHuyen: string;
    maPhuong: string;
    soQD: string;
    soCMND: string;
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CSKDDichVuVuiChoiGiaiTriComponent = () => {
    const classNameGCN = "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri";
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [currentDate, setCurrentDate] = useState(0);
    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [title, setTitle] = useState<string>("NHẬP ĐẦU KỲ CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ");
    const [data, setData] = useState<HoSoThuLyDichVuDuLich>({ ...HoSoThuLyDichVuDuLich_INIT, classNameGCN: classNameGCN });
    const classes = useStyles();
    const hoSoThuLyDichVuDuLichService = new HoSoThuLyDichVuDuLichService();
    const fileServerService = new FileServerService();
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [showAlert, setShowAlert] = useState(false);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const history = useHistory<any>();
    const thongTinDungChungService = new ThongTinDungChungService();
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);
    const [open, setOpen] = useState(false);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);

    useEffect(() => {
        initData();
        loadQuans("92");
    }, []);

    const initData = async () => {
        let id = history?.location?.state?.params;
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        let nhapDauKy = location.state?.isNhapDauKy;
        setCurrentDate(StringUtil.dateSecond());
        let update = location?.state?.isUpdate;
        let soChungNhanId = location.state?.giayChungNhanId;
        dispatchLoading(showLoading());

        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        hoSoCuaToiMgtService.chiTiet(location.state?.maHoSo).then((p) => {
            if (p.data && p.data.data) {
                hoTenNguoiNop = p.data.data.nguoiNop;
                soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });

        if (update) {
            hoSoThuLyDichVuDuLichService
                .getById(soChungNhanId, classNameGCN)
                .then((res) => {
                    const dataNew = {
                        ...res?.data?.data,
                        classNameGCN: classNameGCN,
                        isUpdate: update,
                        resoucePK: res?.data?.data.id,
                        id: res?.data?.data.hoSoThuLyId,
                        nhapDauKy: true,
                    };
                    setData(dataNew);
                    setIsNhapDauKy(true);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (maHS) {
            
            await hoSoThuLyDichVuDuLichService
                .getByMaHoSo(maHS, classNameGCN)
                .then(async(res) => {
                    if (res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.data.hoSoThuLyId > 0) {
                        const dataNew = {
                            ...res?.data?.data,
                            ngayTao: res.data.data.ngayTao,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            classNameGCN: classNameGCN,
                        };
                        setData(dataNew);
                    } else {
                        let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHS);
                        let maHuyen ="", tenHuyen = "", maPhuong = "", tenPhuong = "";
                        if(!StringUtil.isNullOrEmty(dataEform?.quanhuyen)){
                            let donViHanhChinh  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                            maHuyen = donViHanhChinh?.code;
                            tenHuyen = donViHanhChinh?.name;
                        }
                        if(!StringUtil.isNullOrEmty(dataEform?.xaphuong)){
                            let donViHanhChinhCap3  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                            maPhuong = donViHanhChinhCap3?.code;
                            tenPhuong = donViHanhChinhCap3?.name;
                        }
                        const dataNew = {
                            ...HoSoThuLyDichVuDuLich_INIT,
                            maHoSo: maHS,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            maThuTuc: maThuTuc,
                            classNameGCN: classNameGCN,
                            nhapDauKy: true,
                            soDienThoai : soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt,
                            fax : dataEform?.fax,
                            soGCN : dataEform?.giaydkkd,
                            tenCoSo: dataEform?.tendoanhnghiep,
                            maHuyen: !StringUtil.isNullOrEmty(maHuyen) ? maHuyen : HoSoThuLyDichVuDuLich_INIT.maHuyen,
                            tenHuyen: !StringUtil.isNullOrEmty(tenHuyen) ? tenHuyen : HoSoThuLyDichVuDuLich_INIT.maHuyen,
                            maPhuong: !StringUtil.isNullOrEmty(maPhuong) ? maPhuong : HoSoThuLyDichVuDuLich_INIT.maPhuong,
                            tenPhuong: !StringUtil.isNullOrEmty(tenPhuong) ? tenPhuong : HoSoThuLyDichVuDuLich_INIT.tenPhuong,
                            noiCapGCN :  dataEform?.noicapdkkd ? dataEform?.noicapdkkd : HoSoThuLyDichVuDuLich_INIT.noiCapGCN,
                            ngayCapGCN : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : HoSoThuLyDichVuDuLich_INIT.ngayCapGCN,
                            email : dataEform?.email,
                            website : dataEform?.website,
                            coQuanCapGCN : dataEform?.coquancapdkkd,
                            tenDuong: dataEform?.diachi,
                        };
                        setData(dataNew);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else {
            const dataNew = {
                ...HoSoThuLyDichVuDuLich_INIT,
                maHoSo: maHS,
                maTrangThai: TINHTRANG_HOATDONG.ma,
                tenTrangThai: TINHTRANG_HOATDONG.ten,
                maBienDong: LOAIBIENDONG_CAPMOI.ma,
                tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                maThuTuc: maThuTuc,
                classNameGCN: classNameGCN,
                nhapDauKy: true,
            };
            setData(dataNew);
            setIsNhapDauKy(nhapDauKy);
            dispatchLoading(hideLoading());
        }
        // else if(id != ""){
        //     hoSoThuLyDiSanVanHoaService.getGCNChiTiet(id).then((p) => {
        //         setData(p);
        //     }).catch((e) => {
        //         console.log(e);
        //     });
        // }
    };

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const loadPhuongs = (maQuan: string) => {
        if (!StringUtil.isNullOrEmty(maQuan)) {
            danhMucService
                .getDanhMucDiaChi(maQuan)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);
        if (data.donViCapPhep) {
            if (StringUtil.isNullOrEmty(data.ngayCap)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.soQuyetDinh)) {
                return;
            }
        }
        if (isNhapDauKy) {
            if (StringUtil.isNullOrEmty(data.ngayCap)) {
                return;
            }
            if (StringUtil.isNullOrEmty(data.soQuyetDinh)) {
                return;
            }
        }
        if (StringUtil.isNullOrEmty(data.soGCN)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenCoSo)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHuyen)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuong)) {
            return;
        }
        dispatchLoading(showLoading());
        hoSoThuLyDichVuDuLichService
            .insertOrUpdate(data)
            .then((response) => {
                console.log(response);

                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setData({ ...response.data.data });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else if (response.data.error_code === "FAILED") {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: false,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let tenValue = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: tenValue,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => { };

    const handleResetData = () => {
        const dataNew = {
            ...HoSoThuLyDichVuDuLich_INIT,
            id: data.hoSoThuLyId,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            resoucePK: data.resoucePK,
            classNameGCN: classNameGCN,
        };
        setData(dataNew);
        setSubmit(false);
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(data.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    useEffect(() => {
        if (data.maHoSo) {
            setTitle(`CÔNG NHẬN CƠ SỞ KINH DOANH DỊCH VỤ VUI CHƠI, GIẢI TRÍ ĐẠT TIÊU CHUẨN PHỤC VỤ KHÁCH DU LỊCH - MÃ HỒ SƠ ${data.maHoSo}`);
        }
    }, [data?.maHoSo]);

    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        hoSoThuLyDichVuDuLichService.downloadFileThuLy({ templateId: bieuMau.fileId, hoSoThuLyId: data.hoSoThuLyId }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
        });
    };
    const handleChangeToaDo = (dataToaDo: any) => {
        let newData = {
            ...data,
            viDo: dataToaDo.currentViDo,
            kinhDo: dataToaDo.currentKinhDo,
            diaChiDayDu: dataToaDo.diaChiDayDu,
        };
        setData(newData);
    };
    const getDiaChiDayDu = (): string => {
        if (StringUtil.isNullOrEmty(data.diaChiDayDu)) {
            return data.soNha + ", " + data.tenDuong + ", " + data.tenPhuong + ", " + data.tenHuyen + ", " + data.tenTinh;
        } else {
            return data.diaChiDayDu;
        }
    };

    async function handleSearchDoanhNghiep() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinDoanhNghiep(data.soGCN)
        .then((res) => { 
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.maSoDoanhNghiep) {
                setData({ ...data,
                    ngayCapGCN : res?.data?.data?.data?.ngayThanhLap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.ngayThanhLap) : data?.ngayCapGCN,
                    tenCoSo : res?.data?.data?.data?.tenDoanhNghiep_VN,
                    soDienThoai : res?.data?.data?.data?.dienThoai ? StringUtil.replaceDots(res?.data?.data?.data?.dienThoai) : data.soDienThoai,
                    fax: res?.data?.data?.data?.fax,
                    email: res?.data?.data?.data?.email,
                    website: res?.data?.data?.data?.website,
                    soNha: res?.data?.data?.data?.soNha,
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số đăng ký doanh nghiệp không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <div className="dash-content">
                        <div className="dash-title">
                            <span>{title}</span>
                        </div>
                    </div>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={12} className="grid-item">
                                    <Grid container>
                                        {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                            <Grid item xs={3} className="grid-item">
                                                <Checkbox checked={data.donViCapPhep} name="donViCapPhep" value={data.donViCapPhep} onChange={handleChange} />{" "}
                                                Đơn vị cấp số giấy phép
                                            </Grid>
                                        ) : (
                                            ``
                                        )}
                                    </Grid>
                                    <Grid item xs={12} container>
                                        {data.donViCapPhep || isNhapDauKy ? (
                                            <>
                                                <Grid item xs={3} className="grid-item">
                                                    {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                                        <TextField
                                                            fullWidth
                                                            label={"Số giấy chứng nhận"}
                                                            required
                                                            name="soQuyetDinh"
                                                            InputLabelProps={{ classes: labelStyles }}
                                                            value={data.soQuyetDinh}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                classes: inputStyles,
                                                            }}
                                                            onChange={handleChange}
                                                            error={submit && StringUtil.isNullOrEmty(data.soQuyetDinh)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.soQuyetDinh) ? "Vui lòng nhập số chứng nhận" : ""}
                                                        />
                                                    ) : (
                                                        <TextField
                                                            fullWidth
                                                            label={"Số quyết định"}
                                                            required
                                                            name="soQuyetDinh"
                                                            InputLabelProps={{ classes: labelStyles }}
                                                            value={data.soQuyetDinh}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                classes: inputStyles,
                                                            }}
                                                            onChange={handleChange}
                                                            error={submit && StringUtil.isNullOrEmty(data.soQuyetDinh)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.soQuyetDinh) ? "Vui lòng nhập số quyết định" : ""}
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <InputLabel classes={labelStyles} required >
                                                        Ngày cấp
                                                    </InputLabel>
                                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            showTodayButton={true}
                                                            value={data.ngayCap && data.ngayCap != 0 ? new Date(data.ngayCap) : null}
                                                            format="DD/MM/YYYY"
                                                            fullWidth
                                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                                            variant="inline"
                                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                                            error={submit && StringUtil.isNullOrEmty(data.ngayCap)}
                                                            helperText={submit && StringUtil.isNullOrEmty(data.ngayCap) ? "Vui lòng nhập ngày" : ""}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={3} className="grid-item">
                                                    <TextField
                                                        fullWidth
                                                        label={"Cơ quan cấp"}
                                                        name="coQuanCapGCN"
                                                        onChange={handleChange}
                                                        value={data.coQuanCapGCN}
                                                        InputLabelProps={{ classes: labelStyles }}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            classes: inputStyles,
                                                        }}
                                                    />
                                                </Grid>
                                                {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                                    <Grid item xs={3} className="grid-item" >
                                                        <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                autoOk={true}
                                                                showTodayButton={true}
                                                                value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                                format="DD/MM/YYYY"
                                                                fullWidth
                                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                                variant="inline"
                                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={3} className="grid-item" >
                                                        <InputLabel required classes={labelStyles}>Ngày hết hạn</InputLabel>
                                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                autoOk={true}
                                                                showTodayButton={true}
                                                                value={data.ngayHetHan && data.ngayHetHan != 0 ? new Date(data.ngayHetHan) : null}
                                                                format="DD/MM/YYYY"
                                                                fullWidth
                                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                                variant="inline"
                                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayHetHan")}
                                                                error={submit && StringUtil.isNullOrEmty(data.ngayHetHan)}
                                                                helperText={
                                                                    submit && StringUtil.isNullOrEmty(data.ngayHetHan) ? "Vui lòng nhập ngày hết hạn" : ""
                                                                }
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                )}
                                            </>
                                        ) : (
                                            ``
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles} required>
                                            Số GCNĐKDN/HKD
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            style={{ width: "90%" }}
                                            required
                                            name="soGCN"
                                            InputLabelProps={{ classes: labelStyles }}
                                            value={data.soGCN}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={handleChange}
                                            error={submit && StringUtil.isNullOrEmty(data.soGCN)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.soGCN) ? "Vui lòng nhập số GCNĐKDN/HKD" : ""}
                                        />
                                        <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "15PX", marginLeft: "3px" }}  onClick={handleSearchDoanhNghiep} />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Tên cơ sở"}
                                            required
                                            name="tenCoSo"
                                            InputLabelProps={{ classes: labelStyles }}
                                            value={data.tenCoSo}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={handleChange}
                                            error={submit && StringUtil.isNullOrEmty(data.tenCoSo)}
                                            helperText={submit && StringUtil.isNullOrEmty(data.tenCoSo) ? "Vui lòng nhập tên cơ sở" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={data.ngayCapGCN && data.ngayCapGCN != 0 ? new Date(data.ngayCapGCN) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapGCN")}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <Grid item xs={6} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Nơi cấp"}
                                            name="noiCapGCN"
                                            onChange={handleChange}
                                            value={data.noiCapGCN}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Điện thoại"}
                                            name="soDienThoai"
                                            onChange={handleChange}
                                            value={data.soDienThoai}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Fax"}
                                            name="fax"
                                            onChange={handleChange}
                                            value={data.fax}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <Grid item xs={3} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Email"}
                                            name="email"
                                            onChange={handleChange}
                                            value={data.email}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Website"}
                                            name="website"
                                            onChange={handleChange}
                                            value={data.website}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles} required>
                                            Huyện/Thị/TP{" "}
                                        </InputLabel>
                                        <Autocomplete
                                            id="maHuyen"
                                            classes={inputStyles}
                                            options={quans}
                                            getOptionLabel={(option) => option?.name}
                                            value={{ code: data.maHuyen, name: data.tenHuyen }}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                                    helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn huyện/Thị/TP" : ""}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles} required>
                                            Phường/Xã/TT{" "}
                                        </InputLabel>
                                        <Autocomplete
                                            id="maPhuong"
                                            classes={inputStyles}
                                            noOptionsText={"Không có Phường/Xã/TT"}
                                            options={phuongs}
                                            getOptionLabel={(option) => option.name}
                                            value={{ code: data.maPhuong, name: data.tenPhuong }}
                                            onFocus={(e) => loadPhuongs(data.maHuyen)}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                                    helperText={submit && StringUtil.isNullOrEmty(data.maPhuong) ? "Vui lòng chọn phường/Xã/TT" : ""}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Đường </InputLabel>
                                        <TextField
                                            fullWidth
                                            name="tenDuong"
                                            value={data.tenDuong}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Số nhà</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="soNha"
                                            value={data.soNha}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <ChonToaDoComponent
                                            id={classNameGCN}
                                            handleChangeToaDo={handleChangeToaDo}
                                            diaChiDayDu={getDiaChiDayDu()}
                                            kinhDo={data.kinhDo}
                                            viDo={data.viDo}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item">
                                        <TextField
                                            fullWidth
                                            label={"Tổng số người quản lý và nhân viên phục vụ"}
                                            name="tongSoNguoi"
                                            InputLabelProps={{ classes: labelStyles }}
                                            value={data.tongSoNguoi}
                                            onChange={handleChange}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <Grid item xs={6}>
                                        <Checkbox
                                            checked={data.duDieuKienTranTu}
                                            name="duDieuKienTranTu"
                                            value={data.duDieuKienTranTu}
                                            onChange={handleChange}
                                        />{" "}
                                        Đủ điều kiện về an ninh, trật tự
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Checkbox checked={data.baoVeMoiTruong} name="baoVeMoiTruong" value={data.baoVeMoiTruong} onChange={handleChange} /> Bảo
                                        vệ môi trường
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <Grid item xs={6}>
                                        <Checkbox
                                            checked={data.phongChayChuaChay}
                                            name="phongChayChuaChay"
                                            value={data.phongChayChuaChay}
                                            onChange={handleChange}
                                        />{" "}
                                        Phòng cháy chữa cháy
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Checkbox checked={data.anToanThucPham} name="anToanThucPham" value={data.anToanThucPham} onChange={handleChange} /> An
                                        toàn thực phẩm
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dsvh"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item"></Grid>
                            </Grid>
                        </TabPanel>
                    )}
                    {/* {(isNhapDauKy || data.isUpdate || data.hoSoThuLyId <= 0) ? */}
                    {/* {(data.hoSoThuLyId <= 0 || isNhapDauKy) ? 
                        <HoSoThuLyButonComponent
                            onSubmit={handleSubmit}
                            onResetData={handleResetData}
                            onOpenDialog={handleOpenDialog}
                        />
                        :
                        <HoSoThuLyButonComponent
                            onDownloadFileThuLy={handleDownloadFileBieuMau}
                            onSubmit={handleSubmit}
                            onResetData={handleResetData}
                            onOpenDialog={handleOpenDialog}
                        />
                    } */}
                    <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        <ThuLyButtonComponent
                            showIn={data.hoSoThuLyId > 0 && !isNhapDauKy}
                            onSubmit={handleSubmit}
                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                            onDelete={handleResetData}
                            handleChuyen={showHidePopperThaoTac}
                            handleDuThao={showHidePopperDuThao}
                            dataButton= {data}
                        />
                    </Grid>
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
            {showDanhSachDuThaoModal && (
               <DanhSachDuThaoModal hoso={data} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}
        </>
        </div>
    );
};
export default CSKDDichVuVuiChoiGiaiTriComponent;
