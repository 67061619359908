import { FileEntry } from "apis/Common/fileEntry";
import { LichSuDiaDiemDuLichDTO } from "model/DiaDiemDuLich/LichSuDiaDiemDuLichDTO";

export const LichSuDiaDiemDuLichDTO_INIT: LichSuDiaDiemDuLichDTO = {
    id: 0,

    hoSoThuLyId : 0,

    giayPhepId: 0,

    ngayTao: 0,

    ngayCapNhat: 0,
    
    ngayBienDong: 0,

    ngayQuyetDinh : 0,
    
    maBienDong: '',
    
    tenBienDong: '',
    
    soQuyetDinh : '',

    noiDung: '',

    ghiChu: '',

    lanThayDoi: 0,

    lyDo : '',
   
    maHuyen: '',
     
    tenHuyen: '',
    
    maPhuong: '',

    tenPhuong: '',

    maTinhTrang: '',
    
    tenTinhTrang: '',
    
    maLoaiHinh: '', 
    
    tenLoaiHinh: '',

    files: new Array<FileEntry>(),

};
