import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Cell, Row } from "../../../components/commons/table/tableCommons";
import { DanhMucLoaiDiSan } from "../../../model/danhMucLoaiDiSan";
import { ThongKeDSVHPhiVatThe, ThongKeDSVHPhiVatTheResponse } from "../../../model/diSanVanHoaPhiVatThe";
import { DiSanVanHoaVatThe } from "../../../model/diSanVanHoaVatThe";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface IModalProps {
    openEditDialog: (itemSelected: DiSanVanHoaVatThe) => void;
    open: boolean;
}

interface IDataListProps {
    dataList: any;
    handleChiTiet: (index?: number, tenHuyenString?: string) => void;
}

export const ListThongKeDiSanVanHoaPhiVatThe = (props: IDataListProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [data, setData] = useState<ThongKeDSVHPhiVatTheResponse>();

    useEffect(() => {
        setData(props.dataList);
        setRows(props.dataList?.thongKeDSVHDTOs);
    }, [props.dataList]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">
                            STT
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="15%">
                            Quận/Huyện
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Tổng số
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Di tích Bộ Văn hóa du lịch
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Di vật, cổ vật, bảo vật quốc gia
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Di sản UNESCO công nhận
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Di tích lịch sử - văn hóa, danh lam thắng cảnh (sau đây gọi là di tích)
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Đưa ra khỏi danh sách
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.length > 0 ? (
                        rows.map((row, i) => (
                            <Row key={row?.id}>
                                <Cell scope="row" align="center">
                                    {i + 1}
                                </Cell>
                                <Cell align="center">{row?.tenHuyen ? row.tenHuyen : "Khác"}</Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(0, row.tenHuyen)}>
                                        {row.tongSo}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(1, row.tenHuyen)}>
                                        {row.diTichBVHDL}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(2, row.tenHuyen)}>
                                        {row.diVatCVBVQG}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(3, row.tenHuyen)}>
                                        {row.diSanUnescoCN}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(4, row.tenHuyen)}>
                                        {row.diTichLSVHDLTC}
                                    </Box>
                                </Cell>
                                <Cell align="center">
                                    <Box style={{ cursor: "pointer" }} marginTop="5px" onClick={() => props.handleChiTiet(5, row.tenHuyen)}>
                                        {row.duaRaKhoiDS}
                                    </Box>
                                </Cell>
                            </Row>
                        ))
                    ) : (
                        <Row>
                            <Cell colSpan={12} scope="row" align="center">
                                {" "}
                                Không có dữ liệu
                            </Cell>
                        </Row>
                    )}
                    {rows?.length > 0 ? (
                        <Row>
                            <Cell scope="row" align="center"></Cell>
                            <Cell align="center" className="font-weight-bold">
                                Tổng số
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.total}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalDiTichBoVanHoa}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalDiVatCoVat}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalDiSanUnesco}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalDiTichLichSu}
                            </Cell>
                            <Cell align="center" className="font-weight-bold text-center">
                                {data?.totalDuaRaKhoiDanhSach}
                            </Cell>
                        </Row>
                    ) : (
                        ""
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
