import { GiayChungChiDiSanVanHoaRequest } from "../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoaRequest";
import StringUtil from "../../utils/stringUtils";

export const GiayChungChiDiSanVanHoaRequest_INIT: GiayChungChiDiSanVanHoaRequest = {
    soChungChi: "",

    ngayCapChungChiTu: 0,

    ngayCapChungChiDen: 0,

    hoTen: "",

    maHuyen: "",

    tenHuyen: "",

    tenNamThongKe: "",

    classNameGCN: "",

    maBienDong: "",

    tenBienDong: "",

    ngayHetHanTu: 0,

    ngayHetHanDen: 0,

    pageNumber: 1,

    pageSize: 5,

    kyThongKe: 1,

    maTrangThai: '',

    tenTrangThai: '',

    namThongKe: StringUtil.getCurrentYear(),

    
    maTinhTrang: '',

    maHuyenString: '',
};
