import AXIOS_INSTANCE from "..";
import { GiayChungChiDiSanVanHoaRequest } from "../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoaRequest";
import { BaseService } from "../baseService";

export class ThongKeGiayChungNhanKinhDoanhService extends BaseService {
    thongKeGiayChungChiTheoNam = (requestBody: GiayChungChiDiSanVanHoaRequest) => {
        return AXIOS_INSTANCE.post("thong-ke-giay-chung-nhan-kinh-doanh/theo-nam", requestBody);
    };

    bieuDoThongKe = (requestBody: GiayChungChiDiSanVanHoaRequest) => {
        return AXIOS_INSTANCE.post("thong-ke-giay-chung-nhan-kinh-doanh/bieu-do", requestBody);
    };

    downloadFileThongKeGiayChungNhanKinhDoanh = (request: GiayChungChiDiSanVanHoaRequest) => {
        return AXIOS_INSTANCE.get("thong-ke-giay-chung-nhan-kinh-doanh/export-file", {
            responseType: "blob",
            params: request,
        });
    };

    chiTietThongKe = (requestBody: GiayChungChiDiSanVanHoaRequest) => {
        return AXIOS_INSTANCE.post("thong-ke-giay-chung-nhan-kinh-doanh/chi-tiet-thong-ke", requestBody);
    };
}
