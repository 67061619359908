import { RoutePropsI } from ".";
import BaoCaoLHND from "../components/LuHanhNoiDia/ThuLyHoSo/BaoCao/BaoCaoLHND";
import CapDoiLHNDComponent from "../components/LuHanhNoiDia/ThuLyHoSo/CapDoi/CapDoiLHNDComponent";
import CapGiayLHNDComponent from "../components/LuHanhNoiDia/ThuLyHoSo/CapGiay/CapGiayLHNDComponent";
import CapLaiLHNDComponent from "../components/LuHanhNoiDia/ThuLyHoSo/CapLai/CapLaiLHNDComponent";
// import DauKyLHNDComponent from "../components/LuHanhNoiDia/ThuLyHoSo/DauKy/DauKyLHNDComponent";
import { ThongKeLHND } from "../components/LuHanhNoiDia/ThuLyHoSo/ThongKe/ThongKeLHND";
import ThuHoiLHNDComponent from "../components/LuHanhNoiDia/ThuLyHoSo/ThuHoi/ThuHoiLHNDComponent";
import ChiTietGiayPhepLHNDComponent from "../components/LuHanhNoiDia/TraCuu/ChiTiet/ChiTietGiayPhepLHNDComponent";
import QuanLyGiayPhepLHNDComponent from "../components/LuHanhNoiDia/TraCuu/QuanLyGiayPhepLHNDComponent";
import TamNgungGiayPhepLHNDComponent from "../components/LuHanhNoiDia/TraCuu/TamNgung/TamNgungGiayPhepLHNDComponent";
import TamNgungLHNDComponent from "../components/LuHanhNoiDia/TraCuu/TamNgung/TamNgungLHNDComponent";

export const ROUTE_GIAY_PHEP_LHND_CHI_TIET = {
    ten: "Chi tiết giấy phép lữ hành nội địa",
    duongDan: "/giay-phep-lu-hanh-noi-dia/chi-tiet",
    giaoDien: ChiTietGiayPhepLHNDComponent,
};
export const ROUTE_GIAY_PHEP_LHND_TAM_NGUNG = {
    ten: "Tạm ngưng giấy phép lữ hành nội địa",
    duongDan: "/giay-phep-lu-hanh-noi-dia/tam-ngung",
    giaoDien: TamNgungLHNDComponent,
};
export const ROUTE_GIAY_PHEP_LHND_CAP_MOI = {
    ten: "Cấp mới giấy phép lữ hành nội địa",
    duongDan: "/giay-phep-lu-hanh-noi-dia/cap-moi",
    giaoDien: CapGiayLHNDComponent,
};
// export const ROUTE_GIAY_PHEP_LHND_DAU_KY = {
//     ten : "NHẬP ĐẦU KỲ DOANH NGHIỆP LỮ HÀNH NỘI ĐỊA",
//     duongDan: '/giay-phep-lu-hanh-noi-dia/dau-ky',
//     giaoDien: DauKyLHNDComponent,
// }
export const ROUTE_CN_LUHANH: RoutePropsI[] = [
    ROUTE_GIAY_PHEP_LHND_TAM_NGUNG,
    ROUTE_GIAY_PHEP_LHND_CHI_TIET,
    ROUTE_GIAY_PHEP_LHND_CAP_MOI,
    // ROUTE_GIAY_PHEP_LHND_DAU_KY,
    {
        ten: "Cấp giấy phép kinh doanh dịch vụ lữ hành nội địa",
        duongDan: "/lu-hanh-noi-dia/cap-moi",
        giaoDien: CapGiayLHNDComponent,
    },
    {
        ten: "Cấp lại giấy phép kinh doanh dịch vụ lữ hành nội địa",
        duongDan: "/lu-hanh-noi-dia/cap-lai",
        giaoDien: CapLaiLHNDComponent,
    },
    {
        ten: "Cấp đổi giấy phép kinh doanh dịch vụ lữ hành nội địa",
        duongDan: "/lu-hanh-noi-dia/cap-doi",
        giaoDien: CapDoiLHNDComponent,
    },
    {
        ten: "Thu hồi giấy phép kinh doanh dịch vụ lữ hành nội địa",
        duongDan: "/lu-hanh-noi-dia/thu-hoi",
        giaoDien: ThuHoiLHNDComponent,
    },
];

export const ROUTE_PAGE_LUHANH: RoutePropsI[] = [
    {
        ten: "DANH SÁCH GIẤY PHÉP KINH DOANH DỊCH VỤ LỮ HÀNH NỘI ĐỊA",
        duongDan: "/lu-hanh-noi-dia/tra-cuu",
        giaoDien: QuanLyGiayPhepLHNDComponent,
    },
    {
        ten: "BÁO CÁO DOANH NGHIỆP KINH DOANH LỮ HÀNH NỘI ĐỊA",
        duongDan: "/lu-hanh-noi-dia/bao-cao",
        giaoDien: BaoCaoLHND,
    },
    {
        ten: "THỐNG KÊ DOANH NGHIỆP KINH DOANH LỮ HÀNH NỘI ĐỊA",
        duongDan: "/lu-hanh-noi-dia/thong-ke",
        giaoDien: ThongKeLHND,
    },
];
export const ROUTE_PAGE_LUHANH_PUBLIC : RoutePropsI[] = [
    {
        ten: "THỐNG KÊ DOANH NGHIỆP KINH DOANH LỮ HÀNH NỘI ĐỊA",
        duongDan: "/lu-hanh-noi-dia/thong-ke",
        giaoDien: <ThongKeLHND />,
    },
];
