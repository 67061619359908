export type LuHanhNoiDiaConstants = {
    ten: string;
    ma: string;
}
export const LOAIBIENDONG_CAPMOI: LuHanhNoiDiaConstants = {
    ten: "Cấp mới",
    ma: "1"
}
export const LOAIBIENDONG_CAPLAI: LuHanhNoiDiaConstants = {
    ten: "Cấp lại",
    ma: "2"
}
export const LOAIBIENDONG_CAPDOI: LuHanhNoiDiaConstants = {
    ten: "Cấp đổi",
    ma: "3"
}
export const LOAIBIENDONG_THUHOI: LuHanhNoiDiaConstants = {
    ten: "Thu hồi",
    ma: "4"
}
export const LOAIBIENDONG_TAMNGUNG: LuHanhNoiDiaConstants = {
    ten: "Tạm ngưng",
    ma: "4"
}
export const TINHTRANG_HOATDONG: LuHanhNoiDiaConstants = {
    ten: "Hoạt động",
    ma: "1"
}
export const TINHTRANG_THUHOI: LuHanhNoiDiaConstants = {
    ten: "Thu hồi",
    ma: "2"
}
export const TINHTRANG_TAMNGUNG: LuHanhNoiDiaConstants = {
    ten: "Tạm ngưng",
    ma: "4"
}

export const LIST_BIENDONG: LuHanhNoiDiaConstants[] = [
    LOAIBIENDONG_CAPMOI,
    LOAIBIENDONG_CAPLAI,
    LOAIBIENDONG_THUHOI,
    LOAIBIENDONG_TAMNGUNG
]

export const LIST_TINHTRANG: LuHanhNoiDiaConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_THUHOI,
    TINHTRANG_TAMNGUNG
]

export const LIST_TINHTRANG_NHAPDAUKY: LuHanhNoiDiaConstants[] = [
    TINHTRANG_HOATDONG,
    TINHTRANG_THUHOI,
]

export const ACCEPT_FILES = "image/*,.doc,.docx,.xls,.xlsx,.pdf";