import MomentUtils from "@date-io/moment";
import { Button, Grid, InputLabel, Link, NativeSelect, SvgIcon, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createFileType, downLoadFile } from "../../../apis/Common/exportUtil";
import { DanhMucService } from "../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../apis/danhMuc/model/DanhMucDTO";
import { DanhMucCapDiSanService } from "../../../apis/DiSanVanHoa/DanhMucCapDiSanService";
import { DanhMucLoaiDiSanService } from "../../../apis/danhMucCN/DanhMucLoaiDiSanService";
import { DiSanVanHoaVatTheService } from "../../../apis/DiSanVanHoa/DiSanVanHoaVatTheService";
import { bootstrapTextFieldHook } from "../../../components/commons/input/bootstrap";
import { CustomInput } from "../../../components/commons/input/customInput";
import { DanhMucCapDiSan, DanhMucLoaiDiSan, DanhMucIView } from "../../../model/DiSanVanHoa/DiSanVanHoaModel";
import { DiSanVanHoaVatThe } from "../../../model/diSanVanHoaVatThe";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import { ListBaoCaoDiSanVanHoaVatThe } from "./listBaoCaoDiSanVanHoaVatThe";

export interface SearchRequest {
    soQuyetDinh: string;
    ngayQuyetDinhTu: number;
    ngayQuyetDinhDen: number;
    namQuyetDinh: number;
    loaiDiSanId: number;
    tenLoaiDiSan:string;
    capDiSanId: number;
    tenCapDiSan:string;
    diaChiKhiXepHang: string;
    maHuyen: string;
    tenHuyen: string;
    maPhuong: string;
    tenPhuong: string;
    tenDiSan: string;
    pageSize: number;
    pageNumber: number;
}

export const SearchRequest_INT: SearchRequest = {
    soQuyetDinh: "",
    ngayQuyetDinhTu: 0,
    ngayQuyetDinhDen: 0,
    namQuyetDinh: 0,
    loaiDiSanId: 0,
    tenLoaiDiSan: "",
    capDiSanId: 0,
    tenCapDiSan: "",
    diaChiKhiXepHang: "",
    maHuyen: "",
    tenHuyen: "",
    maPhuong: "",
    tenPhuong: "",
    tenDiSan: "",
    pageSize: 10,
    pageNumber: 1,
};

export interface ExportVTRequest {
    kyThongKe: number;
    namQuyetDinh: number;
    ngayQuyetDinhTu: number;
    ngayQuyetDinhDen: number;
    capDiSanId: number;
    maHuyen: string;
    exportType: string;
    exportFileName: string;
    pageSize: number;
    pageNumber: number;
}

export const ExportVTRequest_INT: ExportVTRequest = {
    kyThongKe: 1,
    namQuyetDinh: 0,
    ngayQuyetDinhTu: 0,
    ngayQuyetDinhDen: 0,
    capDiSanId: 0,
    maHuyen: "",
    exportType: "docx",
    exportFileName: "Báo_cáo_DSVH_vật_thể",
    pageSize: 50,
    pageNumber: 1,
};

export const BaoCaoDiSanVanHoaVatThe = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [total, setTotal] = useState(0);
    const [listDiSan, setListDiSan] = useState<DiSanVanHoaVatThe[]>([]);
    const [listLoaiDiSan, setListLoaiDiSan] = useState<DanhMucLoaiDiSan[]>([]);
    const [listCapDiSan, setListCapDiSan] = useState<DanhMucIView[]>([]);
    const [exportDSVHRequest, setExportDSVHRequest] = useState<ExportVTRequest>({
        ...ExportVTRequest_INT,
    });
    const [searchDSVHRequest, setSearchDSVHRequest] = useState<SearchRequest>({
        ...SearchRequest_INT,
    });

    const [kyThongKeOption, setKyThongKeOption] = useState(1);
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);

    useEffect(() => {
        loadListLoaiDiSan();
        loadListCapDiSan();
        loadListDiSan({ ...searchDSVHRequest });
        loadQuans("92");
    }, []);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        let value = isCheckbox ? e.target.checked : e.target.value;
        setExportDSVHRequest({ ...exportDSVHRequest, [e.target.name]: value });
        setSearchDSVHRequest({ ...searchDSVHRequest, [e.target.name]: value });
    };

    const handleChangeSelect = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.ma !== "" ? value?.ten : "";
        let dataNew = { ...exportDSVHRequest, [nameValue]: value?.ma, [nameText]: valueText };
        setExportDSVHRequest(dataNew);
        let dataNew1 = { ...searchDSVHRequest, [nameValue]: value?.ma, [nameText]: valueText };
        setSearchDSVHRequest(dataNew1);
    };

    const handleChangeSelectLoaiDiSan = (value: any, id: string, name: string) => {
        let dataNew = { ...exportDSVHRequest, [id]: value?.id, [name]: value?.name };
        setExportDSVHRequest(dataNew);
        let dataNew1 = { ...searchDSVHRequest, [id]: value?.id, [name]: value?.name };
        setSearchDSVHRequest(dataNew1);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let dataNew = { ...exportDSVHRequest, [filedName]: date?.toDate()?.getTime() };
        setExportDSVHRequest(dataNew);
        let dataNew1 = { ...searchDSVHRequest, [filedName]: date?.toDate()?.getTime() };
        setSearchDSVHRequest(dataNew1);
    };

    const handleChangeKyThongKe = (event: any) => {
        if (kyThongKeOption == 1) {
            exportDSVHRequest.namQuyetDinh = 0;
            searchDSVHRequest.namQuyetDinh = 0;
        } else {
            exportDSVHRequest.ngayQuyetDinhTu = 0;
            exportDSVHRequest.ngayQuyetDinhDen = 0;
            searchDSVHRequest.ngayQuyetDinhTu = 0;
            searchDSVHRequest.ngayQuyetDinhDen = 0;
        }
        setKyThongKeOption(event.target.value);
        exportDSVHRequest.kyThongKe = event.target.value;
    };

    const loadListDiSan = (searchDSVHRequest: SearchRequest) => {
        dispatchLoading(showLoading());
        new DiSanVanHoaVatTheService()
            .getList(searchDSVHRequest)
            .then((p) => {
                setListDiSan(p.rows ? p.rows : []);
                setTotal(p.total);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const loadListLoaiDiSan = async () => {
        await new DanhMucLoaiDiSanService()
            .getAll()
            .then((p) => {
                setListLoaiDiSan(p.rows ? p.rows : []);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const loadListCapDiSan = async () => {
        await new DanhMucCapDiSanService()
            .getAll()
            .then((p) => {
                setListCapDiSan(p.rows ? p.rows : []);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    

    const doSearch = () => {
        let searchNew = { ...searchDSVHRequest, pageNumber: 1 };
        setSearchDSVHRequest(searchNew);
        loadListDiSan(searchNew);
    };

    const reload = () => {
        let searchNew = { ...SearchRequest_INT, pageNumber: 1 };
        setSearchDSVHRequest(searchNew);
        let exportDSVHRequest = { ...ExportVTRequest_INT };
        setExportDSVHRequest(exportDSVHRequest);
        loadListDiSan(searchNew);
    };

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let searchDSVHRequestNew = { ...searchDSVHRequest, pageNumber: value };
        setSearchDSVHRequest(searchDSVHRequestNew);
        loadListDiSan(searchDSVHRequestNew);
    };
    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let searchDSVHRequestNew = { ...searchDSVHRequest, pageSize: Number(event.target.value) };
        searchDSVHRequestNew.pageNumber = 1;
        setSearchDSVHRequest(searchDSVHRequestNew);
        loadListDiSan(searchDSVHRequestNew);
    };

    const downloadFile = async (extension: string) => {
        let request = {
            ...exportDSVHRequest,
            exportType: extension,
            exportFileName: "Báo_cáo_DSVH_vật_thể",
        };
        dispatchLoading(showLoading());
        await new DiSanVanHoaVatTheService()
            .downloadFileBaoCao(request)
            .then((res) => {
                downLoadFile(res.data, createFileType(extension), "Báo_cáo_DSVH_vật_thể");
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const kyThongKeOptions = [
        { option: 1, title: "Năm" },
        { option: 2, title: "Khoảng thời gian" },
    ];

    const nam = [
        { value: 0, title: "Chọn năm" },
        { value: 2018, title: "Năm 2018" },
        { value: 2019, title: "Năm 2019" },
        { value: 2020, title: "Năm 2020" },
        { value: 2021, title: "Năm 2021" },
    ];
    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setSearchDSVHRequest({
            ...searchDSVHRequest,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    return (
        <div className="dash-content">
        <Grid container>
            
                <div className="dash-title">
                    <span>BÁO CÁO DANH SÁCH DI SẢN VĂN HÓA VẬT THỂ TRÊN ĐỊA BÀN</span>
                </div>
            
            <Grid item xs={12} container>
                <Grid item xs={2} className="grid-item">
                    <InputLabel classes={labelStyles}>Kỳ báo cáo</InputLabel>
                    <NativeSelect input={<CustomInput />} fullWidth onChange={handleChangeKyThongKe}>
                        {kyThongKeOptions.map((item, idx) => (
                            <option value={item.option}>{item.title}</option>
                        ))}
                    </NativeSelect>
                </Grid>
                {kyThongKeOption == 1 && (
                    <Grid item xs={2} className="grid-item">
                        <InputLabel classes={labelStyles}>Năm</InputLabel>
                        <NativeSelect input={<CustomInput />} fullWidth value={exportDSVHRequest.namQuyetDinh} name="namQuyetDinh" onChange={handleChange}>
                            {nam.map((item, idx) => (
                                <option value={item.value}>{item.title}</option>
                                // value={{ ma: search.namThongKe, ten: search.tenNamThongKe }}
                            ))}
                        </NativeSelect>
                    </Grid>
                )}
                {kyThongKeOption == 1 && <Grid item xs={2} className="grid-item" />}
                {kyThongKeOption != 1 && (
                    <Grid item xs={2} className="grid-item">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <InputLabel classes={labelStyles}>Từ ngày</InputLabel>
                            <KeyboardDatePicker
                                autoOk={true}
                                value={
                                    exportDSVHRequest.ngayQuyetDinhTu !== 0 && exportDSVHRequest.ngayQuyetDinhTu !== undefined
                                        ? new Date(exportDSVHRequest.ngayQuyetDinhTu)
                                        : null
                                }
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhTu")}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {kyThongKeOption != 1 && (
                    <Grid item xs={2} className="grid-item">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <InputLabel classes={labelStyles}>Đến ngày</InputLabel>
                            <KeyboardDatePicker
                                autoOk={true}
                                value={
                                    exportDSVHRequest.ngayQuyetDinhDen !== 0 && exportDSVHRequest.ngayQuyetDinhDen !== undefined
                                        ? new Date(exportDSVHRequest.ngayQuyetDinhDen)
                                        : null
                                }
                                format="DD/MM/YYYY"
                                fullWidth
                                InputAdornmentProps={{ className: "button-date-picker" }}
                                variant="inline"
                                InputLabelProps={{ classes: labelStyles }}
                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhDen")}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Huyện/Thị/TP</InputLabel>
                    <Autocomplete
                        classes={inputStyles}
                        id="maHuyenThiTp"
                        noOptionsText={"Không có Huyện/Thị/TP"}
                        options={quans}
                        value={{ code: searchDSVHRequest.maHuyen, name: searchDSVHRequest.tenHuyen }}
                        getOptionLabel={(option) => option?.name}
                        onChange={(event: any, newValue: any) => {
                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={labelStyles}>Cấp di sản </InputLabel>
                    <Autocomplete
                        classes={inputStyles}
                        id="tenCapDiSan"
                        noOptionsText={"Không có dữ liệu!!!"}
                        value={{ id: searchDSVHRequest.capDiSanId, name: searchDSVHRequest.tenCapDiSan }}
                        options={listCapDiSan}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => handleChangeSelectLoaiDiSan(newValue, "capDiSanId", "tenCapDiSan")}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className="button-group-center">
                <Button variant="contained" color="primary" disableElevation onClick={doSearch}>
                    Tổng hợp
                </Button>
                <Button variant="contained" color="primary" disableElevation onClick={reload}>
                    Làm mới
                </Button>
            </Grid>
            <Grid item xs={12} className="grid-item" container justify="flex-end">
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("docx");
                    }}
                >
                    <SvgIcon fontSize="large" width="24" height="24" viewBox="0 0 24 24">
                        <path
                            fill="#007bff"
                            d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M17.35 10L15.25 19H13.85L12.05 12.21L10.25 19H8.85L6.65 10H8.15L9.55 16.81L11.35 10H12.65L14.45 16.81L15.85 10H17.35Z"
                        />
                    </SvgIcon>
                </Link>
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("pdf");
                    }}
                >
                    <SvgIcon fontSize="large">
                        <path
                            fill="#dc3545"
                            d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M10.92,12.31C10.68,11.54 10.15,9.08 11.55,9.04C12.95,9 12.03,12.16 12.03,12.16C12.42,13.65 14.05,14.72 14.05,14.72C14.55,14.57 17.4,14.24 17,15.72C16.57,17.2 13.5,15.81 13.5,15.81C11.55,15.95 10.09,16.47 10.09,16.47C8.96,18.58 7.64,19.5 7.1,18.61C6.43,17.5 9.23,16.07 9.23,16.07C10.68,13.72 10.9,12.35 10.92,12.31M11.57,13.15C11.17,14.45 10.37,15.84 10.37,15.84C11.22,15.5 13.08,15.11 13.08,15.11C11.94,14.11 11.59,13.16 11.57,13.15M14.71,15.32C14.71,15.32 16.46,15.97 16.5,15.71C16.57,15.44 15.17,15.2 14.71,15.32M9.05,16.81C8.28,17.11 7.54,18.39 7.72,18.39C7.9,18.4 8.63,17.79 9.05,16.81M11.57,11.26C11.57,11.21 12,9.58 11.57,9.53C11.27,9.5 11.56,11.22 11.57,11.26Z"
                        />
                    </SvgIcon>
                </Link>
                <Link
                    title="Tải xuống"
                    href="#"
                    onClick={() => {
                        downloadFile("xlsx");
                    }}
                >
                    <SvgIcon fontSize="large">
                        <path
                            fill="#28a745"
                            d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
                        />
                    </SvgIcon>
                </Link>
            </Grid>
            <Grid item xs={12} className="grid-item">
                <ListBaoCaoDiSanVanHoaVatThe
                    dataList={listDiSan}
                    pageProps={{
                        page: searchDSVHRequest.pageNumber,
                        count: total,
                        rowsPerPage: searchDSVHRequest.pageSize,
                        onChangePage: handleOnChangePage,
                        onChangeRowsPerPage: handOnChangeRowsPerPage,
                    }}
                />
            </Grid>
        </Grid>
        </div>
    );
};
