import { BaseService } from "../baseService";
import AXIOS_INSTANCE from "../index";
import { DanhMucDungChungDTO } from "./model/DanhMucDTO";


export class DanhMucDanTocService extends BaseService {
    
    findAll = async (): Promise<DanhMucDungChungDTO[]> => {
        let result: DanhMucDungChungDTO[] = [];
        await AXIOS_INSTANCE.get("danh-muc-dan-toc/find-all",this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };

    findByCode = async (): Promise<DanhMucDungChungDTO[]> => {
        let result: DanhMucDungChungDTO[] = [];
        await AXIOS_INSTANCE.get("danh-muc-dan-toc/find-by-code",this.getTokenRequestHeaders())
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    result = response.data.data;
                }
            })
            .catch();
        return result;
    };
}
