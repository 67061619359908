import { DanhMucLoaiDiSan, SearchRequest } from "../../model/DiSanVanHoa/DiSanVanHoaModel";
import { BaseResponse, BaseService } from "../baseService";
import api from "../index";

export class DanhMucLoaiDiSanService extends BaseService {
    getFullList = async (): Promise<{ rows: DanhMucLoaiDiSan[]; total: number }> => {
        var list: DanhMucLoaiDiSan[] = [];
        var total: number = 0;
        await api
            .post("/danh-muc-loai-di-san/search", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        total = response.data.data.total;
                        list = response.data.data.data;
                    }
                }
            })
            .catch((error) => { });
        return { rows: list, total: total };
    };

    getAll = async (): Promise<{ rows: DanhMucLoaiDiSan[] }> => {
        var list: DanhMucLoaiDiSan[] = [];
        await api
            .post("/danh-muc-loai-di-san/get-all", this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        list = response.data.data;
                    }
                }
            })
            .catch((error) => { });
        return { rows: list };
    };

    search = async (request: SearchRequest): Promise<{ rows: DanhMucLoaiDiSan[]; total: number }> => {
        var list: DanhMucLoaiDiSan[] = [];
        var total: number = 0;
        await api
            .post("/danh-muc-loai-di-san/search", JSON.stringify(request), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.error_code === "SUCCESSFUL") {
                        total = response.data.data.total;
                        list = response.data.data.data;
                    }
                }
            })
            .catch((error) => { });
        return { rows: list, total: total };
    };

    insertOrUpdate = async (data: DanhMucLoaiDiSan): Promise<BaseResponse> => {
        let requestBody = {
            id: data.id,
            name: data.name,
            code: data.code,
            description: data.description,
            active: data.active,
        } as DanhMucLoaiDiSan;
        let result = {} as BaseResponse;
        await api
            .post("/danh-muc-loai-di-san/add-or-update", JSON.stringify(requestBody), this.getTokenRequestHeaders())
            .then((response) => {
                if (response.status !== 200) {
                    result = { isSuccess: false, message: "Có lỗi xảy ra, thực hiện lại!" };
                }
                result = {
                    isSuccess: response.data.error_code === "SUCCESSFUL",
                    message: response.data.error_message,
                    data: response.data.data,
                };
            })
            .catch((error) => {
                result = { isSuccess: false, message: "Lỗi khi thực hiện kết nối server" };
            });
        return result;
    };
}
