import { FileEntry } from "../../apis/Common/fileEntry";
import {
    GiayPhepKinhDoanhLuHanhDTO,
    GiayPhepLuHanhNoiDiaRequest,
    LichSuGiayPhepKinhDoanhLuHanh,
    RequestDTO,
} from "../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { HoSoThuLyLuHanhNoiDia_INIT } from "./HoSoThuLyLuHanhNoiDia_INIT";

export const RequestDTO_INIT: RequestDTO = {
    tenDoanhNghiep: "",
    maPhuong: "",
    tenPhuong: "",
    maHuyen: "",
    tenHuyen: "",
    ngayCapPhepTu: 0,
    ngayCapPhepDen: 0,
    maTrangThai: "",
    tenTrangThai: "",
    exportType: "",
    exportFileName: "",
    pageSize: 10,
    pageNumber: 1,
    keywords: "",
};

export const GiayPhepLuHanhNoiDiaRequest_INIT: GiayPhepLuHanhNoiDiaRequest = {
    soGiayPhep: "",

    ngayCapPhepTu: 0,

    ngayCapPhepDen: 0,

    tenDoanhNghiep: "",

    maDoanhNghiep: "",

    maTrangThai:"",

    tenTrangThai:"",

    maHuyen: "",

    tenHuyen: "",

    tenPhuong: "",

    maPhuong: "",

    pageNumber: 1,

    pageSize: 10,
};
export const GiayPhepLuHanhNoiDia_INIT: GiayPhepKinhDoanhLuHanhDTO = {
    giayPhepId: 0,
    hoSoThuLyId: 0,
    ngayTaoId: 0,
    ngayCapNhat: 0,
    ngayTao: 0,
    hoatDong: true,
    maHoSo: "",
    maLinhVuc: "",
    maThuTuc: "",
    soGiayPhep: "",
    soGiayPhepCu: "",
    ngayCap: 0,
    tenDoanhNghiep: "",
    tenLoaiHinhDoanhNghiep: "",
    maLoaiHinhDoanhNghiep: "",
    dienThoai: "",
    tenVietTat: "",
    tenDichVu: "",
    fax: "",
    email: "",
    website: "",
    maHuyen: "",
    tenHuyen: "",
    maPhuong: "",
    tenPhuong: "",
    maDuong: "",
    tenDuong: "",
    soNha: "",
    diaChiDayDu: "",
    nguoiDaiDienTheoPhapLuat: "",
    gioiTinh: "",
    tenGioiTinh: "",
    ngaySinh: 0,
    maDanToc: "01",
    tenDanToc: "Kinh (Việt)",
    maQuocTich: "VN",
    tenQuocTich: "Cộng hòa xã hội chủ nghĩa Việt Nam",
    soDienThoaiNDD: "",
    mailNDD: "",
    soGiayTo: "",
    ngayCapSoGiayTo: 0,
    noiCapSoGiayTo: "",
    chucDanh: "",
    diaChiThuongTru: "",
    choOHienNay: "",
    soGCN: "",
    ngayCapGCN: 0,
    noiCapGCN: "",
    taiKhoanKyQuySo: "",
    nganHang: "",
    maTrangThai: "",
    tenTrangThai: "",
    maBienDong: "",
    tenBienDong: "",
    lyDo: "",
    soLanCapLai: 0,
    soLanCapDoi: 0,
    truongHopThuHoi: "",
    tenTruongHopThuHoi: "",
    ngayThuHoi: 0,
    soQuyetDinhThuHoi: "",
    ngayQuyetDinhThuHoi: 0,
    ghiChu: "",
    tenDiaChiChiNhanh: "",
    tenVanPhongDaiDien: "",
    ngayThayDoi: 0,
    nhapDauKy: false,
    files: new Array<FileEntry>(),
    hoSoThuLyLuHanhDTO: HoSoThuLyLuHanhNoiDia_INIT,
    lichSus: new Array<LichSuGiayPhepKinhDoanhLuHanh>(),
};
