import { FileEntry } from "../../apis/Common/fileEntry";

export interface HoatDongVanHoaNgheThuat {
    id: number;

    soGiayPhep: string;

    ngayCap: number;

    tenNhaHat: string;

    soDienThoai: string;

    soThongBao: string;

    soCMND: string;

    soDienThoaiNguoiDaiDien: string;

    noiDungBieuDien: string;

    ngayTao: number;

    ngayCapNhat: number;

    ngayCapChungNhan: number;

    ngayCapChungNhanMoi: number;

    resoucePK: number;

    pageSize: number;

    pageNumber: number;

    ngayHetHan: number;

    ngayCapCMND: number;

    thoiGianToChuc: number;

    ngayCapLai: number;

    noiCapCMND: string;

    nguoiTaoId: string;

    className: string;

    maHoSo: string;

    maThuTuc: string;

    soChungNhan: string;

    tenToChuc: string;

    dienThoai: string;

    fax: string;

    maTinh: string;

    tenTinh: string;

    tenHuyen: string;

    maHuyen: string;

    tenPhuong: string;

    maPhuong: string;

    tenDuong: string;

    maDuong: string;

    soNha: string;

    tenHuyenToChuc: string;

    maHuyenToChuc: string;

    tenPhuongToChuc: string;

    maPhuongToChuc: string;

    tenDuongToChuc: string;

    maDuongToChuc: string;

    soNhaToChuc: string;

    nguoiDaiDienTheoPhapLuat: string;

    nguoiChiuTrachNhiem: string;

    canCuocPhapLy: string;

    tenTietMuc: string,

    tacGia: string;

    nguoiBieuDien: string;

    ngaySinh: number;

    maTrangThai: string;

    tenTrangThai: string;

    maBienDong: string;

    tenBienDong: string;

    lyDoCapLai: string;

    soChungNhanMoi: string;

    diaChiDayDu: string;

    keywords: string;

    donViCapPhep: number;

    lanCapLai: string;

    files: FileEntry[];

    fileCapLais: FileEntry[];

    hoatDong: boolean;

    donViCapSoGiayPhep: boolean;

    isCapSoMoi: boolean;

    nhapDauKy: boolean;

    isUpdate: boolean;
    ghiChu: string;
    nguoiKy: string;
}

export interface HoatDongVanHoaNgheThuatDTO {
    id: number;

    ngayTao: number;

    ngayCapNhat: number;

    ngayCapChungNhan: number;

    ngayCapChungNhanMoi: number;

    resoucePK: number;

    pageSize: number;

    pageNumber: number;

    ngayHetHan: number;

    ngayHetHanMoi: number;

    ngayHetHanCu: number;

    ngayCapCMND: number;

    ngayCapQD: number;

    ngayCapLai: number;

    noiCapCMND: string;

    nguoiTaoId: string;

    className: string;

    maHoSo: string;

    maThuTuc: string;

    soChungNhan: string;

    soChungChi: string;

    soChungChiCu: string;

    soChungChiMoi: string;

    tenToChuc: string;

    dienThoai: string;

    fax: string;

    maTinh: string;

    tenTinh: string;

    tenHuyen: string;

    maHuyen: string;

    tenPhuong: string;

    maPhuong: string;

    tenDuong: string;

    maDuong: string;

    soNha: string;

    soQD: string;

    noiCapQD: string;

    soCMND: string;

    nguoiDaiDienTheoPhapLuat: string;

    ngaySinh: number;

    chucDanh: string;

    trichYeu: string;

    canCu: string;

    deNghi: string;

    hoTen: string;

    noiSinh: string;

    maQuocTich: string;

    tenQuocTich: string;

    trinhDoChuyenMon: string;

    kinhNghiemChuyenMon: string;

    duocPhepHanhNghe: string;

    maTrangThai: string;

    tenTrangThai: string;

    maBienDong: string;

    tenBienDong: string;

    lyDoCapLai: string;

    soChungNhanMoi: string;

    diaChiDayDu: string;

    keywords: string;

    donViCapPhep: number;

    lanCapLai: string;

    files: FileEntry[];

    fileCapLais: FileEntry[];

    hoatDong: boolean;

    donViCapSoGiayPhep: boolean;

    isCapSoMoi: boolean;

    nhapDauKy: boolean;

    isUpdate: boolean;
    ghiChu: string;
    nguoiKy: string;
}

export interface SearchRequest {
    id: number;

    ngayTao: number;

    ngayCapNhat: number;

    nguoiTaoId: string;

    resoucePK: number;

    className: string;

    maHoSo: string;

    maThuTuc: string;

    soChungNhan: string;

    ngayCapChungNhan: number;

    ngayHetHan: number;

    tenToChuc: string;

    dienThoai: string;

    fax: string;

    maTinh: string;

    tenTinh: string;

    tenHuyen: string;

    maHuyen: string;

    tenPhuong: string;

    maPhuong: string;

    tenDuong: string;

    maDuong: string;

    soNha: string;


    ngayCapCMND: number;

    noiCapCMND: string;

    nguoiDaiDienTheoPhapLuat: string;

    ngaySinh: number;

    chucDanh: string;

    trichYeu: string;

    canCu: string;

    deNghi: string;

    hoTen: string;

    noiSinh: string;

    maQuocTich: string;

    tenQuocTich: string;

    trinhDoChuyenMon: string;

    kinhNghiemChuyenMon: string;

    duocPhepHanhNghe: string;

    maTrangThai: string;

    tenTrangThai: string;

    maBienDong: string;

    tenBienDong: string;

    lyDoCapLai: string;

    soChungNhanMoi: string;

    diaChiDayDu: string;

    lanCapLai: string;

    donViCapPhep: number;

    ngayCapLai: number;

    ngayCapChungNhanMoi: number;

    hoatDong: boolean;

    donViCapSoGiayPhep: boolean;

    isCapSoMoi: boolean;
}
export const HoatDongVanHoaNgheThuat_INIT: HoatDongVanHoaNgheThuat = {
    id: 0,

    soGiayPhep: '',

    ngayCap: 0,
    
    tenNhaHat: '',

    soDienThoai: '',

    soThongBao: '',

    soCMND: '',

    soDienThoaiNguoiDaiDien: '',

    ngayTao: 0,

    ngayCapNhat: 0,

    ngayCapChungNhan: 0,

    ngayCapChungNhanMoi: 0,

    resoucePK: 0,

    pageSize: 0,

    pageNumber: 0,

    ngayHetHan: 0,

    ngayCapCMND: 0,

    thoiGianToChuc: 0,

    ngayCapLai: 0,

    noiCapCMND: "",

    nguoiTaoId: "",

    className: "",

    maHoSo: "",

    maThuTuc: "",

    soChungNhan: "",

    tenToChuc: "",

    dienThoai: "",

    fax: "",

    maTinh: "",

    tenTinh: "",

    tenHuyen: "",

    maHuyen: "",

    tenPhuong: "",

    maPhuong: "",

    tenDuong: "",

    maDuong: "",

    soNha: "",

    tenHuyenToChuc: "",

    maHuyenToChuc: "",

    tenPhuongToChuc: "",

    maPhuongToChuc: "",

    tenDuongToChuc: "",

    maDuongToChuc: "",

    soNhaToChuc: "",

    nguoiDaiDienTheoPhapLuat: "",

    nguoiChiuTrachNhiem: "",

    noiDungBieuDien: "",

    ngaySinh: 0,

    canCuocPhapLy: "",

    tenTietMuc: "",

    tacGia: "",

    nguoiBieuDien: "",

    maTrangThai: "",

    tenTrangThai: "",

    maBienDong: "",

    tenBienDong: "",

    lyDoCapLai: "",

    soChungNhanMoi: "",

    diaChiDayDu: "",

    keywords: "",

    donViCapPhep: 0,

    lanCapLai: "",

    files: [],

    fileCapLais: [],

    hoatDong: true,

    donViCapSoGiayPhep: true,

    isCapSoMoi: false,

    nhapDauKy: false,

    isUpdate: false,

    ghiChu: "",

    nguoiKy: "",
};
