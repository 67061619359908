import { Box, createStyles, Grid, makeStyles, Snackbar, Theme, Typography, Button } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import { FileEntry } from "../../../../../apis/Common/fileEntry";
import { GiayChungChiDiSanVanHoaService } from "../../../../../apis/DiSanVanHoa/GiayChungChiDiSanVanHoaService";
import { LOAIBIENDONG_THUHOI, TINHTRANG_THUHOI } from "../../../../../constants/DiSanVanHoaConstants";
import { GiayChungChiDiSanVanHoa_INIT } from "../../../../../init/GiayChungChi/GiayChungChiDiSanVanHoa";
import { GiayChungChiDiSanVanHoa } from "../../../../../model/GiayChungChiDSVH/GiayChungChiDiSanVanHoa";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import StringUtil from "../../../../../utils/stringUtils";
import { ConfirmationDialogRaw } from "../../../../commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
import LichSuTuBoDiTichComponent from "./LichSuTuBoDiTichComponent";
import TepTinDinhKemTuBoDiTichComponent from "./TepTinDinhKemTuBoDiTichComponent";
import ThongTinChungTuBoDiTichComponent from "./ThongTinChungTuBoDiTichComponent";
import ThuHoiTuBoDiTichButtonComponent from "./ThuHoiTuBoDiTichButtonComponent";
import { useHistory } from "react-router-dom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const ThuHoiTuBoDiTichComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [currentDate, setCurrentDate] = useState(0);
    const [giayChungChiId, setGiayChungChiId] = useState(0);
    const history = useHistory<any>();
    const [data, setData] = useState<GiayChungChiDiSanVanHoa>({
        ...GiayChungChiDiSanVanHoa_INIT,
        className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
    });
    const classes = useStyles();
    const giayChungChiDiSanVanHoaService = new GiayChungChiDiSanVanHoaService();
    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        let giayChungChiId = location.state?.giayChungChiId;
        setGiayChungChiId(giayChungChiId);
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        await giayChungChiDiSanVanHoaService
            .getBySoGiayChungChiDiSanVanHoas(giayChungChiId)
            .then((res) => {
                if (StringUtil.hasData(res)) {
                    const dataNew = {
                        ...res?.data?.data,
                        maTrangThai: TINHTRANG_THUHOI.ma,
                        tenTrangThai: TINHTRANG_THUHOI.ten,
                        maBienDong: LOAIBIENDONG_THUHOI.ma,
                        tenBienDong: LOAIBIENDONG_THUHOI.ten,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
                    };
                    setData(dataNew);
                } else {
                    const dataNew = {
                        ...GiayChungChiDiSanVanHoa_INIT,
                        maTrangThai: TINHTRANG_THUHOI.ma,
                        tenTrangThai: TINHTRANG_THUHOI.ten,
                        maBienDong: LOAIBIENDONG_THUHOI.ma,
                        tenBienDong: LOAIBIENDONG_THUHOI.ten,
                        className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleSubmit = async () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.lyDoThuHoi)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.ngayThuHoi)) {
            setTabRequired(0);
            return;
        }
        if (data.ngayThuHoi < data.ngayCapChungChi) {
            setAlert({
                showAlert: true,
                message: "Ngày thu hồi không được nhỏ hơn ngày cấp",
                severity: "warning",
            });
            setTabRequired(0);
            return;
        }
        if (data.ngayThuHoi > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày thu hồi không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            setTabRequired(0);
            return;
        }
        dispatchLoading(showLoading());
        await giayChungChiDiSanVanHoaService
            .thuHoi(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });

                    setData({
                        ...data,
                        lichSuTuBoDiTichs: response.data.data.lichSuTuBoDiTichs,
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value,
            };
            setData(dataNew);
        }
    };

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };
    const handleResetData = () => {
        const dataNew = {
            ...data,
            fileThuHois: new Array<FileEntry>(),
            lyDoThuHoi: "",
            ngayThuHoi: 0,
            ghiChu: "",
            maTrangThai: TINHTRANG_THUHOI.ma,
            tenTrangThai: TINHTRANG_THUHOI.ten,
            maBienDong: LOAIBIENDONG_THUHOI.ma,
            tenBienDong: LOAIBIENDONG_THUHOI.ten,
            className: "com.fpt.egov.api.model.dsvh.GiayChungChiDiSanVanHoa",
        };
        setData(dataNew);
        setSubmit(false);
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container style={{ float: "left", paddingTop: "20px" }} item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>THU HỒI CHỨNG CHỈ HÀNH NGHỀ TU BỔ DI TÍCH</h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab style={tabRequired == 0 ? { color: "red" } : {}} label="Thông tin chung" />
                                {/* <CustomTab label="Tệp tin đính kèm" /> */}
                                <CustomTab label="Lịch sử" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <ThongTinChungTuBoDiTichComponent data={data} onUpdateData={setDataParam} submit={submit} />
                        </TabPanel>
                    )}
                    {/* {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <TepTinDinhKemTuBoDiTichComponent data={data?.fileCapMois} />
                        </TabPanel>
                    )} */}
                    {indexTab === 1 && (
                        <TabPanel value={indexTab} index={1}>
                            <LichSuTuBoDiTichComponent data={data?.lichSuTuBoDiTichs} />
                        </TabPanel>
                    )}
                    {indexTab === 0 && (
                         <Grid container item xs={12} className="grid-item">
                         <ThuHoiTuBoDiTichButtonComponent
                             onSubmit={handleSubmit}
                             onResetData={handleResetData}
                             onOpenDialog={handleOpenDialog}
                             isChiTiet={false}
                         />
                     </Grid>
                    )}
                    {indexTab === 1 && (
                        <Button variant="contained" color="primary" disableElevation className="button" onClick={() => history.go(-1)}>
                            Trở về
                        </Button>
                    )}
                    {indexTab === 2 && (
                        <Button variant="contained" color="primary" disableElevation className="button" onClick={() => history.go(-1)}>
                            Trở về
                        </Button>
                    )}
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </>
        </div>
    );
};
export default ThuHoiTuBoDiTichComponent;
