import { FileEntry } from "../apis/Common/fileEntry";

export interface DiSanVanHoaPhiVatThe {
    id: number;
    soQuyetDinh: string;
    ngayQuyetDinh: number;
    loaiDiSanId: number;
    tenLoaiDiSan: string;
    tenDiSan: string;
    tenHuyen: string;
    maHuyen: string;
    tenPhuong: string;
    maPhuong: string;
    tenDuong: string;
    maDuong: string;
    soNha: string;
    noiDungGioiThieu: string;
    ghiChu: string;
    duaRaKhoiDanhSach: boolean;
    files: FileEntry[];
    className: string;
}

export const DiSanVanHoaPhiVatThe_INT: DiSanVanHoaPhiVatThe = {
    id: 0,
    soQuyetDinh: "",
    ngayQuyetDinh: 0,
    loaiDiSanId: 0,
    tenLoaiDiSan: "",
    tenDiSan: "",
    tenHuyen: "",
    maHuyen: "",
    tenPhuong: "",
    maPhuong: "",
    tenDuong: "",
    maDuong: "",
    soNha: "",
    noiDungGioiThieu: "",
    ghiChu: "",
    duaRaKhoiDanhSach: false,
    files: new Array<FileEntry>(),
    className: "com.fpt.egov.api.model.dsvh.DiSanVanHoaPhiVatThe",
};

export interface TongTheoLoaiDiSan {
    maHuyen: string;
    tenHuyen: string;
    tongSo: number;
    diTichLSVHDLTC: number;
    diVatCVBVQG: number;
    diSanUnescoCN: number;
    diTichBVHDL: number;
    duaRaKhoiDS: number;
}

export interface ThongKeDSVHPhiVatThe {
    maHuyen: string;
    tenHuyen: string;
    tongSo: number;
    tongSoTheoLoaiDiSan: TongTheoLoaiDiSan[];
    duaRaKhoiDS: number;
}

export interface ThongKeDSVHPhiVatTheResponse {
    total: number;
    totalDiTichBoVanHoa: number;
    totalDiVatCoVat: number;
    totalDiSanUnesco: number;
    totalDiTichLichSu: number;
    thongKeDSVHDTOs: TongTheoLoaiDiSan[];
    totalDuaRaKhoiDanhSach: number;
}
