import { Box, Button, Grid, IconButton, InputBase, InputLabel, NativeSelect, Paper, TextField, Typography } from "@material-ui/core";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React from "react";
import { Pagination } from "apis/baseService";
import { DanhMucService } from "apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "apis/danhMuc/model/DanhMucDTO";
import { MarkerDTO, MarkerDTO_INIT } from "../../../model/marker";
import StringUtil from "../../../utils/stringUtils";
import { bootstrapTextFieldHook } from "../input/bootstrap";
import ResultItem from "./resultItem";
export interface DialogTraCuuProps {
    view: any;
    setOpenDialog: (value: boolean) => void;
    setArrMarker: (value: MarkerDTO[]) => void;
    arrMarker: MarkerDTO[];
    setItemEdit: (value: MarkerDTO | null) => void;
    itemEdit: MarkerDTO | null;
    valueEdit: MarkerDTO | null;
    handleSubmitSearch: (values: MarkerDTO) => Promise<Pagination<MarkerDTO[]>>;
    handleSaveLatLong: (values: MarkerDTO) => Promise<MarkerDTO>;
    renderDetails: (item: MarkerDTO, showDetail: boolean) => JSX.Element;
}

const DialogTraCuu = ({
    setOpenDialog,
    setArrMarker,
    arrMarker,
    setItemEdit,
    itemEdit,
    valueEdit,
    handleSubmitSearch,
    handleSaveLatLong,
    renderDetails,
}: DialogTraCuuProps) => {
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const [total, setTotal] = React.useState<number>(0);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [quans, setQuans] = React.useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = React.useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const initialValues: MarkerDTO = {
        ...MarkerDTO_INIT,
        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
    };
    const formik = useFormik({
        initialValues,
        onSubmit: handleSearch,
    });
    React.useEffect(() => {
        loadQuans("92");
    }, []);
    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadPhuongs = (maQuan: string) => {
        if (!StringUtil.isNullOrEmty(maQuan)) {
            danhMucService
                .getDanhMucDiaChi(maQuan)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        formik.setFieldValue("maPhuong", "");
        formik.setFieldValue("tenPhuong", "");
        formik.setFieldValue(nameValue, value?.code);
        formik.setFieldValue(nameText, valueText);
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        formik.setFieldValue(nameValue, value?.code);
        formik.setFieldValue(nameText, valueText);
    };
    React.useEffect(() => {
        if (arrMarker.length === 0 && !isSubmit) {
            formik.resetForm();
        }
    }, [arrMarker]);

    React.useEffect(() => {
        if (isSubmit) {
            handleSearch(formik.values);
        }
    }, [initialValues.pageNumber]);

    async function handleSearch(values: any) {
        try {
            setIsSubmit(true);
            const { rows, total, page } = await handleSubmitSearch({ ...values });
            setArrMarker([...rows] as MarkerDTO[]);
            setTotal(total);
        } catch (error) {
            console.log(error);
        }
    }

    async function handleSubmitEdit(values: MarkerDTO) {
        const index = arrMarker.findIndex((item) => values.classNameGCN === item.classNameGCN);
        const data = valueEdit && (await handleSaveLatLong({ ...values, ...valueEdit }));
        const newValues = { ...arrMarker[index], ...data };
        setArrMarker([...arrMarker.slice(0, index), { ...newValues }, ...arrMarker.slice(index + 1)]);
        setItemEdit(null);
    }

    function handleRemove() {
        setArrMarker([...arrMarker]);
        setItemEdit(null);
    }

    function handleOnChangePage(value: number) {
        //setPageParams((pageParams) => ({ ...pageParams, page: value }));
    }

    function handleReset() {
        setIsSubmit(false);
        setArrMarker([]);
        setItemEdit(null);
        formik.resetForm();
    }

    return (
        <Paper className="dialog_map">
            <Box
                style={{
                    padding: 10,
                    backgroundColor: "#ccc",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    fontWeight: 700,
                    color: "#fff",
                    marginBottom: 5,
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box>Tìm kiếm</Box>
                <CancelPresentationIcon style={{ cursor: "pointer" }} color="secondary" onClick={() => setOpenDialog(false)} />
            </Box>
            <Box padding={0}>
                <Grid container style={{ overflow: "scroll", maxHeight: "53vh" }}>
                    {arrMarker.length === 0 && (
                        <>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles}>Lớp bản đồ</InputLabel>
                                <NativeSelect
                                    name="classNameGCN"
                                    input={<InputBase fullWidth classes={inputStyles} />}
                                    value={formik.values.classNameGCN}
                                    onChange={formik.handleChange}
                                >
                                    <option value={"com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong"}>
                                        Cơ sở kinh doanh dịch vụ ăn uống
                                    </option>
                                    <option value={"com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuMuaSam"}>
                                        Cơ sở kinh doanh dịch vụ mua sắm
                                    </option>
                                    <option value={"com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuTheThao"}>
                                        Cơ sở kinh doanh dịch vụ thể thao
                                    </option>
                                    <option value={"com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuGiaiTri"}>
                                        Cơ sở kinh doanh dịch vụ vui chơi, giải trí
                                    </option>
                                    <option value={"com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuLuuTru"}>Cơ sở lưu trú</option>
                                    <option value={"com.fpt.egov.api.model.luhanh.GiayPhepKinhDoanhLuHanh"}>Doanh nghiệp lữ hành</option>
                                    <option value={"com.fpt.egov.api.model.hdnt.GiayPhepToChucNT"}>Hoạt động nghệ thuật</option>
                                </NativeSelect>
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Số quyết định"}
                                    name="soQuyetDinh"
                                    value={formik.values.soQuyetDinh}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <TextField
                                    fullWidth
                                    label={"Tên cơ sở"}
                                    name="tenCoSo"
                                    value={formik.values.tenCoSo}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ classes: labelStyles }}
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: inputStyles,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles}>Huyện/Thị/TP </InputLabel>
                                <Autocomplete
                                    id="maHuyen"
                                    options={quans}
                                    getOptionLabel={(option) => option?.name}
                                    value={{ code: formik.values.maHuyen, name: formik.values.tenHuyen }}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={12} className="grid-item">
                                <InputLabel classes={labelStyles}>Phường/Xã/TT </InputLabel>
                                <Autocomplete
                                    id="maPhuong"
                                    options={phuongs}
                                    getOptionLabel={(option) => option.name}
                                    value={{ code: formik.values.maPhuong, name: formik.values.tenPhuong }}
                                    onFocus={(e) => loadPhuongs(formik.values.maHuyen)}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            </Grid>
                        </>
                    )}
                    {arrMarker.length > 0 &&
                        arrMarker.map((item: any, index: number) => (
                            <ResultItem
                                renderDetails={renderDetails}
                                key={index}
                                setItemEdit={setItemEdit}
                                itemEdit={itemEdit}
                                item={item}
                                handleSubmitEdit={handleSubmitEdit}
                                handleRemove={handleRemove}
                            />
                        ))}
                </Grid>
            </Box>
            {arrMarker.length > 0 && (
                <Box style={{ display: "flex", marginTop: 10, justifyContent: "space-between" }} className="grid-item">
                    <Typography variant="subtitle2" gutterBottom style={{ paddingTop: 9 }}>
                        Đang hiển thị kết quả {(formik.values.pageNumber - 1) * formik.values.pageSize + 1} -{" "}
                        {total < formik.values.pageNumber * formik.values.pageSize ? total : formik.values.pageNumber * formik.values.pageSize}
                    </Typography>
                    <Box>
                        <IconButton size="small" disabled={formik.values.pageNumber === 1} onClick={() => handleOnChangePage(formik.values.pageNumber)}>
                            <KeyboardArrowLeftIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                            size="small"
                            disabled={formik.values.pageNumber === Math.ceil(total / formik.values.pageSize)}
                            onClick={() => handleOnChangePage(formik.values.pageNumber + 1)}
                        >
                            <KeyboardArrowRightIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            )}
            <Box className="footer_map">
                {arrMarker.length === 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => formik.handleSubmit()}
                        style={{ marginLeft: 10, marginRight: 10 }}
                    >
                        Tìm kiếm
                    </Button>
                )}
                <Button
                    style={{ marginLeft: arrMarker.length === 0 ? 0 : 10 }}
                    id="btnHuy"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleReset}
                >
                    Làm mới
                </Button>
            </Box>
        </Paper>
    );
};

export default DialogTraCuu;
