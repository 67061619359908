const CONFIG_KH = {
    "AUTHORIZE_ENDPOINT": "https://login.cantho.gov.vn/oauth2/authorize",
    "RESPONSE_TYPE": "code",
    "USER_ROOT": "ZAD",
    "SCOPE": "openid cccc",
    "REDIRECT_URI": "https%3A%2F%2Fvanhoadulich.congchuc.cantho.gov.vn",
    "CLIENT_ID": "H4j9P_exdwYJwlQBiDvvZukLY5Ya",
    "CLIENT_SECRET": "gJV2aSMYDquyjqrzvrutgFfKUmIa",
    "GRANT_TYPE": "authorization_code",
    "CLIENT_URL": "*",
    "LOGOUT_URL": "https://login.cantho.gov.vn/oidc/logout",
    "COOKIE_PATH": "/"
};
const AXIOS_INSTANCE_SITETEST = {
    "AUTHORIZE_ENDPOINT": "https://login.cantho.gov.vn/oauth2/authorize",
    "RESPONSE_TYPE": "code",
    "USER_ROOT": "ZAD",
    "SCOPE": "openid cccc",
    "REDIRECT_URI": "https%3A%2F%2F118.69.123.51%3A8081%2F",
    "CLIENT_ID": "K3OvNndCOOZOq_Brj8SDhfJxlRca",
    "CLIENT_SECRET": "NNWaZx4OXh3rU2qJw7YBtPzxwswa",
    "GRANT_TYPE": "authorization_code",
    "CLIENT_URL": "*",
    "LOGOUT_URL": "https://login.cantho.gov.vn/oidc/logout",
    "COOKIE_PATH": "/"
};
export const CONFIG = CONFIG_KH;
export const AUTHORIZE_REQUEST = `${CONFIG.AUTHORIZE_ENDPOINT}?response_type=${CONFIG.RESPONSE_TYPE}&user_root=${CONFIG.USER_ROOT}&scope=${CONFIG.SCOPE}&redirect_uri=${CONFIG.REDIRECT_URI}&client_id=${CONFIG.CLIENT_ID}`;
export const ENDPOINT = {
    "USER_INFO_ENDPOINT": "/user-mgt/sso/userInfo",
    "TOKEN_ENDPOINT": "/user-mgt/sso/accessToken",
}