import AXIOS_INSTANCE from "..";
import { DanhMucI, SearchRequest } from "../Common/model/CommonModel";
export class HangCoSoLuuTruService {
    constructor() {}
    search = (request: SearchRequest) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/hang-co-so/search", request);
    };
    getByHoatDong = (hoatDong: boolean) => {
        return AXIOS_INSTANCE.get("dich-vu-du-lich/hang-co-so/get-by-hoat-dong/" + hoatDong);
    };
    addOrUpdate = (request: DanhMucI) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/hang-co-so/addOrUpdate", request);
    };
}
