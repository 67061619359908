
export  const thongTinChuyenNganhConstants = {
    NGAYTHANGHIENTAI : "ngaythanghientai",
    CMND : "cmnd",
    NGAYCAPCMND : "ngaycapcmnd",
    NOICAPCMND : "noicapcmnd",
    HOKHAUTHUONGTRU : "hokhauthuongtru",
    NOIOHIENNAY : "noiohiennay",
    HOVATEN : "hovaten",
    HOVATENVIETHOA : "hovatenviethoa",
    NGAYTHANGSINH : "ngaythangsinh",
    NGAYHIENTAI : "ngayhientai",
    THANGHIENTAI : "thanghientai",
    NAMHIENTAI : "namhientai",
    QUOCTICH : "quoctich",
    TINHTHANH : "tinhthanh",
    QUANHUYEN : "quanhuyen",
    XAPHUONG : "xaphuong",
    SDT : "sdt",
    FAX : "fax",
    EMAIL : "email",
    GIOITINH : "gioitinh",
    DIACHI : "diachi",
    KINHGUI : "kinhgui",
    TENDOANHNGHIEP : "tendoanhnghiep",
    DIACHIDOANHNGHIEP : "diachidoanhnghiep",
    WEBSITE : "website",
    GIAYDKKD : "giaydkkd",
    COQUANCAPDKKD : "coquancapdkkd",
    NGAYCAPDKKD : "ngaycapdkkd",
    NOICAPDKKD : "noicapdkkd",
    HOTENNGUOIDAIDIEN : "hotennguoidaidien",
    CHUCVUNGUOIDAIDIEN : "chucvunguoidaidien",
    DIADIEM : "diadiem",
    PHONE : "phone",
    TENCOSODV :"tencosodichvu",
    TONGSONHANVIEN : "tongsonhanvien",
    SODOANHNGHIEP :"so_dkdn",
    NGAYCAPDOANHNGHIEP: "ngaycap_dkdn",
    NOICAPDOANHNGHIEP : "noicap_dkdn" 

}
