import AXIOS_INSTANCE from "..";
import { HoSoThuLyDiSanVanHoa } from "../../model/hoSoThuLyDiSanVanHoa";
import { BaseService } from "../baseService";

export class HoSoThuLyDiSanVanHoaService extends BaseService {
    insertOrUpdate = (hoSo: HoSoThuLyDiSanVanHoa) => {
        return AXIOS_INSTANCE.post("ho-so-thu-ly/di-san-van-hoa/save-or-update", hoSo, this.getTokenRequestHeaders());
    };
    getByMaHoSo = (maHS: string) => {
        return AXIOS_INSTANCE.post("ho-so-thu-ly/di-san-van-hoa/get-by-ma-ho-so", { maHoSo: maHS }, this.getTokenRequestHeaders());
    };
    getByMaHoSos = (maHS: string) => {
        return AXIOS_INSTANCE.post("ho-so-thu-ly/di-san-van-hoa/get-by-ma-ho-sos", { maHoSo: maHS }, this.getTokenRequestHeaders());
    };
    capLai = (hoSo: HoSoThuLyDiSanVanHoa) => {
        return AXIOS_INSTANCE.post("ho-so-thu-ly/di-san-van-hoa/cap-lai", hoSo, this.getTokenRequestHeaders());
    };
    downloadFileThuLy(request: any) {
        return AXIOS_INSTANCE.post("ho-so-thu-ly/di-san-van-hoa/download-file-thu-ly", request, {
            responseType: "blob",
        });
    }
}
