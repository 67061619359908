import MomentUtils from "@date-io/moment";
import {
    Box, Button, Checkbox,
    createStyles,
    FormControlLabel, FormHelperText, Grid, InputLabel,
    makeStyles,
    NativeSelect,
    Snackbar,
    TextField,
    Theme,
    Typography
} from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AXIOS_INSTANCE from "apis";
import { createFileType, downLoadFile } from "apis/Common/exportUtil";
import { DanhMucIView } from "apis/Common/model/CommonModel";
import { BieuMauAttachFileDTO } from "apis/danhMucCN";
import { DanhMucCapDuLichService } from "apis/DiaDiemDuLich/DanhMucCapDuLichService";
import { DiaDiemDuLichService } from "apis/DiaDiemDuLich/DiaDiemDuLichService";
import DropZoneCustom2 from "components/commons/CustomDropzone2/dropzoneCustome2";
import { ConfirmationDialogRaw } from "components/commons/dialog/confirmDialog";
import HoSoKhuDuLichButonComponent from "components/commons/hoso/HoSoKhuDuLichButonComponent";
import { LOAIBIENDONG_CAPMOI_DIADIEMDULICH, TINHTRANG_HOATDONG_DIADIEMDULICH } from "constants/DiaDiemDuLichConstants";
import { ACCEPT_FILES } from "constants/DichVuDuLichConstants";
import { getIn, useFormik } from "formik";
import { useContextDropZone } from "hooks/useContextDropZone";
import { HoSoDiaDiemDuLichDTO_INIT } from "init/DiaDiemDuLich/HoSoDiaDiemDuLichDTO_INIT";
import { HoSoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Dispatch } from "redux";
import { closeConfirmation, openConfirmation } from "redux/actions/confirmDialogAction";
import { openNotification } from "redux/actions/notificationAction";
import * as Yup from "yup";
import { DanhMucService } from "../../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../../apis/danhMuc/model/DanhMucDTO";
import { hideLoading, LoadingAction, showLoading } from "../../../../../redux/actions/applicationAction";
import StringUtil from "../../../../../utils/stringUtils";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";
import { ChonToaDoComponent } from "../../../../commons/map/ChonToaDoComponent";
import { CustomTab, CustomTabs } from "../../../../commons/tabs";
import { OverlayLoading } from "../../../../overlayLoading/overlayLoading";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const NhapDauKyKhuDuLichComponent = () => {
    const classNameGCN = "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuLuuTru";
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const dispatch = useDispatch();
    const history = useHistory();
    const { attachFileServer, handleSetAttachFile, handleChangeStatus, handleDownloadFile, handleDeleteFileServer } = useContextDropZone();
    const [capDuLichs, setCapDuLichs] = useState<DanhMucIView[]>([]);
    const serViceCapDuLich = new DanhMucCapDuLichService();
    const hoSoDiaDiemId = location.state?.hoSoDiaDiemId;
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const service = new DiaDiemDuLichService();
    const validationSchema = Yup.object().shape(
        {
            giayPhep: Yup.object().shape(
                {
                    soQuyetDinh: Yup.string().required("Số quyết định không được bỏ trống"),
                    ngayCapVanBan: Yup.number()
                    .transform(value => (value <=0 || (isNaN(value)  )  ? undefined : value))
                    .required("Ngày cấp không được bỏ trống"),
                    //  ngayHetHan: Yup.date().required("Ngày cấp không được bỏ trống")
                    //     .nullable()
                    //     .required("Ngày hết hạn không được bỏ trống")
                    //     .when("ngayCapVanBan", (ngayCapVanBan: any, schema: any) => {
                    //         if (ngayCapVanBan) {
                    //             return schema.min(ngayCapVanBan, "Không hợp lệ");
                    //         }
                    //         return schema;
                    //     }),
                },
                // [["ngayCapVanBan", "ngayHetHan"]]
            ),
            tenToChucDuLich: Yup.string().required("Tên cơ quan, tổ chức quản lý khu du lịch không được bỏ trống").nullable(),
            tenDiemDuLich: Yup.string().required("Tên Khu/Điểm du lịch không được bỏ trống").nullable(),
            nguoiQuanLy: Yup.string().required("Người quản lý không được bỏ trống").nullable(),
            maHuyen: Yup.string().required("Huyện/Thị/TP không được bỏ trống").nullable(),
            maPhuong: Yup.string().required("Phường/Xã/TT không được bỏ trống").nullable(),
            maCapDuLich: Yup.string().required("Cấp du lịch không được bỏ trống").nullable(),
        }
    );

    const formik = useFormik({
        initialValues :{...HoSoDiaDiemDuLichDTO_INIT},
        onSubmit: (values) => handleSubmit(values),
        validationSchema,
        validateOnChange: false,
    });

    useEffect(() => {
        initialData();
    }, [hoSoDiaDiemId])  

    async function initialData() {
        loadQuans("92");
        loadDanhMucCapDuLich();
        try {
            if(hoSoDiaDiemId && hoSoDiaDiemId > 0){
                const data = await new DiaDiemDuLichService().getByHoSoID(hoSoDiaDiemId);
                formik.setValues({...data})
                data.files && data.files.length > 0 && handleSetAttachFile(data.files);
            }else{
                formik.setValues({
                    ...HoSoDiaDiemDuLichDTO_INIT,
                    giayPhep :{
                        ...HoSoDiaDiemDuLichDTO_INIT.giayPhep,
                        maTinhTrang: TINHTRANG_HOATDONG_DIADIEMDULICH?.ma,
                        tenTinhTrang: TINHTRANG_HOATDONG_DIADIEMDULICH?.ten
                    },
                    maTinhTrang: TINHTRANG_HOATDONG_DIADIEMDULICH?.ma,
                    tenTinhTrang: TINHTRANG_HOATDONG_DIADIEMDULICH?.ten,
                    maBienDong : LOAIBIENDONG_CAPMOI_DIADIEMDULICH?.ma,
                    tenBienDong: LOAIBIENDONG_CAPMOI_DIADIEMDULICH?.ten,
                    maLoaiHinh : '1',
                    tenLoaiHinh: 'Điểm du lịch'

                });
            }
        } catch (error) {}
    }  

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    
    const loadDanhMucCapDuLich = async () => {
        serViceCapDuLich.getByHoatDong(true).then((p) => {
            if (p && p?.data && p?.data?.data && p?.data?.error_code == 'SUCCESSFUL') {
                setCapDuLichs( p?.data?.data);
            }
        });
    }

    const loadPhuongs = (maQuan: string) => {
        if(!StringUtil.isNullOrEmty(maQuan)){
            danhMucService
                .getDanhMucDiaChi(maQuan)
                .then((res) => {
                    setPhuongs(res);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    
    const handleChangeSelectQuan = (value: any) => {
        let valueText = value?.code !== "" ? value?.name : "";
        formik.setFieldValue("maHuyen", value?.code);
        formik.setFieldValue("tenHuyen", valueText);
        formik.setFieldValue("maPhuong", "");
        formik.setFieldValue("tenPhuong", "");  
    };

    const handleChangeSelectPhuong = (value: any) => {
        let valueText = value?.code !== "" ? value?.name : "";
        formik.setFieldValue("maPhuong", value?.code);
        formik.setFieldValue("tenPhuong", valueText);  
    };
   
    const handleSubmit = async (values: HoSoDiaDiemDuLichDTO) => {
        let newValues = { ...values };
        newValues.files = [...attachFileServer];
        try {
            const { data, isSuccess, message } = await new DiaDiemDuLichService().insertOrUpdateDauKy(newValues);
            if (isSuccess) {
                formik.setValues({ ...data });
                data.files && data.files.length > 0 && handleSetAttachFile(data.files);
                dispatch(openNotification({ severity: "success", open: true, content: message }));
            } else {
                dispatch(openNotification({ severity: "error", open: true, content: message }));
            }
        } catch (error) {
            dispatch(openNotification({ severity: "error", open: true, content: "Server không thể thực hiện" }));
        }
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => { };
   
    const getDiaChiDayDu = (): string => {
        if (StringUtil.isNullOrEmty(formik?.values?.diaChiDayDu)) {
            return formik?.values?.tenPhuong + ", " + formik?.values?.tenHuyen + ", " + formik?.values?.tenTinh;
        } else {
            return formik?.values?.diaChiDayDu;
        }
    };

    const handleChangeToaDo = (dataToaDo: any) => {
        let newData = {
            ...formik?.values,
            viDo: dataToaDo.currentViDo,
            kinhDo: dataToaDo.currentKinhDo,
            diaChiDayDu: dataToaDo.diaChiDayDu,
        };
        formik.setValues({
            ...newData
        })
    };

    const handleChangeLoaiHinh = (event : any) => {
        const tenLoaiHinh = event.target.value == 1 ? 'Điểm du lịch' : 'Khu du lịch';
        formik.setFieldValue("maLoaiHinh", event.target.value);
        formik.setFieldValue("tenLoaiHinh", tenLoaiHinh);

    }

    const handleChangeCapDuLich = (value: any, keyMa: string, keyTen: string) => {
        formik.setFieldValue("maCapDuLich", value?.ma);
        formik.setFieldValue("tenCapDuLich", value?.ten);
    };

    const handleReset = () => {
        // if (isOk) {
            formik.setValues({
                ...HoSoDiaDiemDuLichDTO_INIT,
                id: formik.values.id,
            });
            handleSetAttachFile([
                ...attachFileServer.map((item) => {
                    return { ...item, isDeleted: true };
                }),
            ]);
        // }
        dispatch(closeConfirmation());
        formik.setErrors({});
    }

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const onDownloadFileThuLy = (bieuMau: BieuMauAttachFileDTO) => {
        dispatchLoading(showLoading());
        service
            .downloadFileThuLy({ templateId: bieuMau.fileId, hoSoId: formik?.values.id })
            .then((res) => {
                downLoadFile(res.data, createFileType(bieuMau.contentType), `${bieuMau.fileName}`);
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleReset();
            setAlert({
                showAlert: true,
                message: "Yêu cầu thực hiện thành công",
                severity: "success",
            });
        }
        setOpenDeleteUserDlg(false);
    };

    return (
        <div className="dash-content">
            <>
                {
                    <Grid container>
                        <OverlayLoading />
                        <Snackbar
                            open={alert.showAlert}
                            onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            autoHideDuration={3000}
                        >
                            <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                                {alert.message}
                            </Alert>
                        </Snackbar>
                        <div className="dash-content">
                            <div className="dash-title">
                                <span>NHẬP ĐẦU KỲ KHU/ĐIỂM DU LỊCH</span>
                            </div>
                        </div>
                        <br />
                        <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                    <CustomTab label="Thông tin chung" />
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {indexTab === 0 && (
                            <TabPanel value={indexTab} index={0}>
                                <Grid container>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles} required>
                                            Số quyết định
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            name="giayPhep.soQuyetDinh"
                                            InputLabelProps={{ classes: labelStyles }}
                                            value={formik.values?.giayPhep?.soQuyetDinh}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                            error={Boolean(getIn(formik.touched, "giayPhep.soQuyetDinh") && getIn(formik.errors, "giayPhep.soQuyetDinh"))}
                                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                            helperText={Boolean(getIn(formik.touched, "giayPhep.soQuyetDinh") && getIn(formik.errors, "giayPhep.soQuyetDinh")) && formik.errors?.giayPhep?.soQuyetDinh}
                                        />
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles} required>
                                            Ngày cấp
                                        </InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={formik.values?.giayPhep?.ngayCapVanBan && formik.values?.giayPhep?.ngayCapVanBan > 0 ? new Date(formik.values?.giayPhep?.ngayCapVanBan) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                onChange={(date: any, _val: any ) => {
                                                    formik.setFieldValue("giayPhep.ngayCapVanBan", date?.toDate()?.getTime());
                                                }}
                                                error={Boolean(getIn(formik.touched, "giayPhep.ngayCapVanBan") && getIn(formik.errors, "giayPhep.ngayCapVanBan"))}
                                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                                helperText={Boolean(getIn(formik.touched, "giayPhep.ngayCapVanBan") && getIn(formik.errors, "giayPhep.ngayCapVanBan")) && formik.errors?.giayPhep?.ngayCapVanBan}
                                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                                disableFuture={true}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles}>Ngày hết hạn</InputLabel>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={formik.values?.giayPhep?.ngayHetHan && formik.values?.giayPhep?.ngayHetHan > 0 ? new Date(formik.values?.giayPhep?.ngayHetHan) : null}
                                                format="DD/MM/YYYY"
                                                fullWidth
                                                InputAdornmentProps={{ className: "button-date-picker" }}
                                                variant="inline"
                                                InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                onChange={(date: any , _val : any) => {
                                                    formik.setFieldValue("giayPhep.ngayHetHan", date?.toDate()?.getTime());
                                                }}
                                                FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                                helperText={Boolean(getIn(formik.touched, "ngayHetHan") && getIn(formik.errors, "ngayHetHan")) && formik.errors?.giayPhep?.ngayHetHan}
                                                invalidDateMessage="Định dạng không đúng DD/MM/YYYY"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" />
                                    <Grid item xs={6} className="grid-item">
                                        <InputLabel classes={labelStyles} required>Tên cơ quan, tổ chức quản lý khu du lịch</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="tenToChucDuLich"
                                            value={formik.values?.tenToChucDuLich}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                            error={Boolean(getIn(formik.touched, "tenToChucDuLich") && getIn(formik.errors, "tenToChucDuLich"))}
                                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                            helperText={Boolean(getIn(formik.touched, "tenToChucDuLich") && getIn(formik.errors, "tenToChucDuLich")) && formik.errors?.tenToChucDuLich}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Điện thoại</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="dienThoai"
                                            value={formik.values?.dienThoai}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Email</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="email"
                                            value={formik.values?.email}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Website</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="website"
                                            value={formik.values?.website}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="grid-item">
                                        <FormControlLabel
                                            label={<span className="label-checkbox">Công nhận</span>}
                                            control={
                                                <Checkbox checked={formik.values?.congNhan} name="congNhan" color="default" size="small" onChange={formik.handleChange}/>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item">
                                        <InputLabel classes={labelStyles} required>Tên Khu/Điểm du lịch</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="tenDiemDuLich"
                                            value={formik.values?.tenDiemDuLich}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                            error={Boolean(getIn(formik.touched, "tenDiemDuLich") && getIn(formik.errors, "tenDiemDuLich"))}
                                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                            helperText={Boolean(getIn(formik.touched, "tenDiemDuLich") && getIn(formik.errors, "tenDiemDuLich")) && formik.errors?.tenDiemDuLich}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Điện thoại</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="dienThoaiDuLich"
                                            value={formik.values?.dienThoaiDuLich}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Email</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="emailDuLich"
                                            value={formik.values?.emailDuLich}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Website</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="websiteDuLich"
                                            value={formik.values?.websiteDuLich}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="grid-item">
                                        <InputLabel classes={labelStyles} required>Người quản lý</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="nguoiQuanLy"
                                            value={formik.values?.nguoiQuanLy}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                            error={Boolean(getIn(formik.touched, "nguoiQuanLy") && getIn(formik.errors, "nguoiQuanLy"))}
                                            FormHelperTextProps={{ className: "-error", classes: helperTextStyles }}
                                            helperText={Boolean(getIn(formik.touched, "nguoiQuanLy") && getIn(formik.errors, "nguoiQuanLy")) && formik.errors?.nguoiQuanLy}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles} required>Loại hình</InputLabel>
                                        <NativeSelect disableUnderline fullWidth name="maLoaiHinh" value={formik.values?.maLoaiHinh} onChange={handleChangeLoaiHinh}>
                                            <option value={1}>Điểm du lịch</option>
                                            <option value={2}>Khu du lịch</option>
                                        </NativeSelect>
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles} required>Cấp du lịch</InputLabel>
                                        <Autocomplete
                                            id="capDuLich"
                                            options={capDuLichs}
                                            value={{ ma: formik?.values?.maCapDuLich ? formik?.values?.maCapDuLich : "", ten: formik?.values?.tenCapDuLich ? formik?.values?.tenCapDuLich : ""}}
                                            getOptionLabel={(option) => option?.ten}
                                            onChange={(event: any, newValue: any) => handleChangeCapDuLich(newValue, "maCapDuLich", "tenCapDuLich")}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined"
                                                />
                                            )}
                                        />
                                        {Boolean(getIn(formik.errors, "maCapDuLich")) && (
                                            <FormHelperText className="-error" classes={helperTextStyles}>
                                                {formik.errors?.maCapDuLich}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                    {/* <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles} required>Cấp di tích</InputLabel>
                                        <NativeSelect disableUnderline fullWidth name="loai">
                                            <option value={1}>Di tích cấp thành phố</option>
                                            <option value={2}>Di tích cấp quốc gia</option>
                                        </NativeSelect>
                                    </Grid> */}
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles} required>
                                            Huyện/Thị/TP
                                        </InputLabel>
                                        <Autocomplete
                                            id="maHuyen"
                                            options={quans}
                                            getOptionLabel={(option) => option?.name}
                                            value={{ code: formik?.values.maHuyen, name: formik?.values.tenHuyen }}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelectQuan(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                        {Boolean(getIn(formik.errors, "maHuyen")) && (
                                            <FormHelperText className="-error" classes={helperTextStyles}>
                                                {formik.errors?.maHuyen}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel classes={labelStyles} required>
                                            Phường/Xã/TT
                                        </InputLabel>
                                        <Autocomplete
                                            id="maPhuong"
                                            options={phuongs}
                                            getOptionLabel={(option) => option.name}
                                            value={{ code: formik.values?.maPhuong, name: formik.values?.tenPhuong }}
                                            onFocus={(e) => loadPhuongs(formik.values?.maHuyen)}
                                            onChange={(event: any, newValue: any) => {
                                                handleChangeSelectPhuong(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    error={submit && StringUtil.isNullOrEmty(formik?.values.maPhuong)}
                                                    helperText={submit && StringUtil.isNullOrEmty(formik?.values.maPhuong) ? "Vui lòng chọn phường/Xã/TT" : ""}
                                                />
                                            )}
                                        />
                                        {Boolean(getIn(formik.errors, "maPhuong")) && (
                                            <FormHelperText className="-error" classes={helperTextStyles}>
                                                {formik.errors?.maPhuong}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Đường </InputLabel>
                                        <TextField
                                            fullWidth
                                            name="tenDuong"
                                            value={formik?.values?.tenDuong}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <InputLabel classes={labelStyles}>Số nhà</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="soNha"
                                            value={formik?.values?.soNha}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className="grid-item">
                                        <ChonToaDoComponent
                                            id={classNameGCN}
                                            handleChangeToaDo={handleChangeToaDo}
                                            diaChiDayDu={getDiaChiDayDu()}
                                            kinhDo={formik.values?.kinhDo}
                                            viDo={formik.values?.viDo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="grid-item">
                                        <InputLabel classes={labelStyles}>Loại hình dịch vụ</InputLabel>
                                        <TextField
                                            multiline
                                            rows={3}
                                            fullWidth
                                            name="loaiHinhDichVu"
                                            value={formik?.values?.loaiHinhDichVu}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="grid-item">
                                        <InputLabel classes={labelStyles}>Ghi chú</InputLabel>
                                        <TextField
                                            multiline
                                            rows={3}
                                            fullWidth
                                            name="ghiChu"
                                            value={formik?.values?.ghiChu}
                                            InputLabelProps={{ classes: labelStyles }}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: inputStyles,
                                            }}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} container className="grid-item">
                                        <DropZoneCustom2
                                            acceptFiles={`${ACCEPT_FILES}`}
                                            attachFileServer ={attachFileServer}
                                            url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/dddl"}
                                            handleChangeStatus ={handleChangeStatus}
                                            handleDeleteFileServer ={handleDeleteFileServer}
                                            handleDownloadFile ={handleDownloadFile}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="grid-item ">
                                         <HoSoKhuDuLichButonComponent
                                                formik={formik}
                                                onResetData={handleReset}
                                                onOpenDialog={handleOpenDialog}
                                                onDownloadFileBieuMau={onDownloadFileThuLy}
                                                showIn ={formik.values?.id > 0}
                                            />
                                        {/* <Box className="button-group" marginTop="5px">
                                            <Button type="submit" variant="contained" color="primary" disableElevation onClick={() => formik.handleSubmit()}>
                                                Lưu
                                            </Button>
                                            <Button variant="contained" color="secondary" disableElevation onClick={() => handleDeleteForm}>
                                                Xóa
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                onClick={() => {
                                                    history.go(-1);
                                                }}
                                            >
                                                Trở về
                                            </Button>
                                        </Box> */}
                                    </Grid>

                                </Grid>
                            </TabPanel>
                        )}
                        {openDeleteUserDlg && (
                            <ConfirmationDialogRaw
                                id="confirm-dialog-delete"
                                keepMounted
                                open={openDeleteUserDlg}
                                title="Xóa thông tin"
                                content="Bạn có đồng ý xóa thông tin đã nhập?"
                                onClose={handleCloseDeleteInfo}
                            />
                        )}
                    </Grid>
                }
            </>
        </div>
    );
};
export default NhapDauKyKhuDuLichComponent;
