import { Box, NativeSelect, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MenuDrop from "../../../components/commons/drop/menuDrop";
import { CustomInput } from "../../../components/commons/input/customInput";
import { Cell, Row } from "../../../components/commons/table/tableCommons";
import { DiSanVanHoaVatThe } from "../../../model/diSanVanHoaVatThe";
import DateUtil from "../../../utils/dateUtils";
import StringUtil from "../../../utils/stringUtils";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface IModalProps {
    openEditDialog: (itemSelected: DiSanVanHoaVatThe) => void;
    open: boolean;
}

interface IDataListProps {
    dataList: any[];
    pageProps: TablePaginationActionsProps;
    //deleteItem: (id: number) => void;
    //modalEdit: IModalProps;
}

export const ListDiSanVanHoaVatThe = (props: IDataListProps) => {
    const [rows, setRows] = useState<any[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);

    const history = useHistory();
    const openDetail = (id: number) => {
        history.push("/di-san-van-hoa-vat-the/chi-tiet", { params: id });
    };
    const openEdit = (id: number) => {
        history.push("/di-san-van-hoa-vat-the/them-moi", { params: id });
    };
    const openDelete = (id: number) => {
        // history.push('/di-san-van-hoa-vat-the/them-moi', { params: id })
        console.log("Tính năng đang phát triển...!!!");
    };

    function getDiaChi(soNha: string, tenDuong: string, tenPhuong: string, tenHuyen: string) {
        var result = "";
        if (!StringUtil.isNullOrEmty(soNha)) result = result.concat(soNha);
        if (!StringUtil.isNullOrEmty(tenDuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenDuong);
            else result = result.concat(", ", tenDuong);
        }
        if (!StringUtil.isNullOrEmty(tenPhuong)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenPhuong);
            else result = result.concat(", ", tenPhuong);
        }
        if (!StringUtil.isNullOrEmty(tenHuyen)) {
            if (StringUtil.isNullOrEmty(result)) result = result.concat(tenHuyen);
            else result = result.concat(", ", tenHuyen);
        }
        return result;
    }

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.rowsPerPage);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    return (
        <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <Cell component="th" scope="row" align="center" width="5%">
                            STT
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Số quyết định
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="12%">
                            Ngày quyết định
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="10%">
                            Tên di sản
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="15%">
                            Loại di sản văn hóa
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="15%">
                            Xã, huyện khi xếp hạng
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="15%">
                            Phường (Xã)/ Quận/ Huyện hiện nay
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="25%">
                            Cấp di sản
                        </Cell>
                        <Cell component="th" scope="row" align="center" width="8%">
                            Thao tác
                        </Cell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.pageProps.count > 0 ? (
                        rows.map((row, i) => (
                            <Row key={row.id}>
                                <Cell scope="row" align="center">
                                    {(props.pageProps.page - 1) * rowsPerPage + i + 1}
                                </Cell>
                                <Cell align="left">{row.soQuyetDinh}</Cell>
                                <Cell align="left">{DateUtil.toDDMMYYYY(row.ngayQuyetDinh)}</Cell>
                                <Cell
                                    align="left"
                                    onClick={() => {
                                        openDetail(row.id);
                                    }}
                                    style={{ fontWeight: "bold", cursor: "pointer" }}
                                >
                                    {row.tenDiSan}
                                </Cell>
                                <Cell align="left">{row.tenLoaiDiSan}</Cell>
                                <Cell align="left">{row.diaChiKhiXepHang}</Cell>
                                <Cell align="left">{getDiaChi(row.soNha, row.tenDuong, row.tenPhuong, row.tenHuyen)}</Cell>
                                <Cell align="left">{row.tenCapDiSan}</Cell>
                                <Cell align="center">
                                    <Box display="flex" justifyContent="center">
                                        <MenuDrop
                                            item={row.id}
                                            onEdit={() => {
                                                openEdit(row.id);
                                            }}
                                        />
                                    </Box>
                                </Cell>
                            </Row>
                        ))
                    ) : (
                        <Row key={"empty"}>
                            <Cell colSpan={12} scope="row" align="center">
                                Không có dữ liệu
                            </Cell>
                        </Row>
                    )}
                </TableBody>
            </Table>
            <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                <Pagination
                    count={totalPage}
                    page={props.pageProps.page}
                    defaultPage={1}
                    siblingCount={1}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    onChange={props.pageProps.onChangePage}
                />
                <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                    <Box component="span" paddingX="5px">
                        Số mẫu tin trên trang
                    </Box>
                    <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </NativeSelect>
                </Box>
                <Box component="span" paddingX="5px">
                    Mẫu tin {props.pageProps.page * props.pageProps.rowsPerPage - props.pageProps.rowsPerPage + 1} -{" "}
                    {props.pageProps.count - (props.pageProps.page - 1) * props.pageProps.rowsPerPage < props.pageProps.rowsPerPage
                        ? props.pageProps.count
                        : props.pageProps.page * props.pageProps.rowsPerPage}{" "}
                    / {props.pageProps.count}
                </Box>
            </Box>
        </TableContainer>
    );
};
