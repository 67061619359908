import { lazy } from "react";
import { DongBoDuLieuComponent } from "../pages/DongBoDuLieu/DongBoDuLieuComponent";
import { DanhMucBieuMau } from "../pages/quanTriHeThong/danhMucBieuMau";
import { DongBoCanBoCongChucComponent } from "../pages/quanTriHeThong/DongBoCanBo/DongBoCanBoCongChucComponent";
import { DongBoMotCuaComponent } from "../pages/quanTriHeThong/DongBoMotCua/DongBoMotCuaComponent";
import { ImportDauKyComponent } from "../pages/quanTriHeThong/importDauKy/ImportDauKyComponent";
import { UserManagement } from "../pages/quanTriHeThong/user/mgt/userManagement";
import { PasswordManagement } from "../pages/quanTriHeThong/user/passwordMgt/passwordManagement";
import { ProfileUpdate } from "../pages/quanTriHeThong/user/update/profileUpdate";
import { ROUTE_CN_DVDL, ROUTE_DS_DVDL, ROUTE_GUEST_DVDL, ROUTE_PAGE_DVDL } from "./route_dvdl";
import { ROUTE_CN_HDNT, ROUTE_DS_HDNT, ROUTE_PAGE_HDNT } from "./route_hdnt";
import { ROUTE_CN_KDL, ROUTE_DS_KDL, ROUTE_GUEST_KDL, ROUTE_PAGE_KDL } from "./route_khudulich";
import { ROUTE_CN_LUHANH, ROUTE_PAGE_LUHANH } from "./route_luhanh";
import { ROUTE_CN_VHDL, ROUTE_DS_VHDL, ROUTE_PAGE_VHDL } from "./route_vhdl";
const HoSoCuaToiMgt = lazy(() => import("../pages/hoSoCuaToi/hoSoCuaToiMgt").then(({ HoSoCuaToiMgt }) => ({ default: HoSoCuaToiMgt })));
const CauHinhNghiepVuMgt = lazy(() => import("pages/quanTriHeThong/cauHinhNghiepVu/cauHinhNghiepVuMgt").then(({ CauHinhNghiepVuMgt }) => ({ default: CauHinhNghiepVuMgt })));
const MenuRoleManagement = lazy(() => import("pages/quanTriHeThong/menu/menuRoleManagement").then(({ MenuRoleManagement }) => ({ default: MenuRoleManagement })));
const GroupRoleManagementComponent = lazy(() =>
    import("pages/quanTriHeThong/nhomVaiTro/groupRoleManagementComponent").then(({ GroupRoleManagementComponent }) => ({ default: GroupRoleManagementComponent }))
);
const PhanQuyenNguoiDungComponent = lazy(() =>
    import("pages/quanTriHeThong/phanQuyenNguoiDung/phanQuyenNguoiDungComponent").then(({ PhanQuyenNguoiDungComponent }) => ({ default: PhanQuyenNguoiDungComponent }))
);
const RoleManagementComponent = lazy(() => import("pages/quanTriHeThong/vaiTro/roleManagementComponent").then(({ RoleManagementComponent }) => ({ default: RoleManagementComponent })));

const HuongDanSuDungComponent = lazy(() => import('../pages/quanTriHeThong/huongDanSuDung/huongDanSuDungComponent').then(({ HuongDanSuDungComponent }) => ({ default: HuongDanSuDungComponent })));

export interface RoutePropsI {
    ten: string;
    giaoDien: any;
    duongDan: string;
    routes?: RoutePropsI[];
}
export const ROUTE_CN: RoutePropsI[] = [
    //VHDL
    ...ROUTE_CN_VHDL,
    //LUHANH
    ...ROUTE_CN_LUHANH,
    //DVDL
    ...ROUTE_CN_DVDL,
    //HDNT
    ...ROUTE_CN_HDNT,
    //Khu điểm du lịch
    ...ROUTE_CN_KDL

];

export const ROUTE_PAGE_DS: RoutePropsI[] = [
    //VHDL
    ...ROUTE_DS_VHDL,
    // DVDL
    ...ROUTE_DS_DVDL,
    // HDNT
    ...ROUTE_DS_HDNT,
    // KDL
    ...ROUTE_DS_KDL,
    // LUHANH
    ...ROUTE_PAGE_LUHANH,
];

export const ROUTE_PAGE_HOSOCUATOI: RoutePropsI = {
    ten: "HỒ SƠ CỦA TÔI",
    duongDan: "/ho-so-cua-toi",
    giaoDien: HoSoCuaToiMgt,
};
export const ROUTE_PAGE: RoutePropsI[] = [
    {
        ten: 'Hướng dẫn sử dụng',
        giaoDien: HuongDanSuDungComponent,
        duongDan: '/huong-dan-su-dung',
    },
    {
        ten: "HỒ SƠ CỦA TÔI",
        duongDan: "/ho-so-cua-toi",
        giaoDien: HoSoCuaToiMgt,
    },
    {
        ten: "Cấu hình nghiệp vụ",
        giaoDien: CauHinhNghiepVuMgt,
        duongDan: "/cau-hinh-nghiep-vu",
    },
    {
        ten: "Phân quyền người dùng",
        giaoDien: PhanQuyenNguoiDungComponent,
        duongDan: "/phan-quyen-nguoi-dung",
    },
    {
        ten: "Quản lý vai trò",
        giaoDien: RoleManagementComponent,
        duongDan: "/quan-ly-vai-tro",
    },
    {
        ten: "Quản lý nhóm vai trò",
        giaoDien: GroupRoleManagementComponent,
        duongDan: "/quan-ly-nhom-vai-tro",
    },
    {
        ten: "Quản lý menu",
        giaoDien: MenuRoleManagement,
        duongDan: "/quan-ly-menu",
    },
    {
        ten: "Đồng bộ một cửa",
        giaoDien: DongBoMotCuaComponent,
        duongDan: "/dong-bo-mot-cua",
    },
    {
        ten: "Import đầu ky",
        giaoDien: ImportDauKyComponent,
        duongDan: "/import-dau-ky",
    },
    {
        ten: 'Đồng bộ dữ liệu',
        giaoDien: DongBoDuLieuComponent,
        duongDan: '/dong-bo-du-lieu',
    },
    {
        ten: "DM biểu mẫu",
        giaoDien: DanhMucBieuMau,
        duongDan: "/danh-muc-bieu-mau",
    },
    {
        ten: 'Đồng bộ người dùng',
        duongDan: '/dong-bo-nguoi-dung',
        giaoDien: DongBoCanBoCongChucComponent,
    },
    {
        ten: 'Quản trị người dùng',
        duongDan: '/quan-tri-nguoi-dung',
        giaoDien: UserManagement,
    },
    //VHDL
    ...ROUTE_PAGE_VHDL,
    //LUHANH
    ...ROUTE_PAGE_LUHANH,
    //DVDL
    ...ROUTE_PAGE_DVDL,
    //VHNT
    ...ROUTE_PAGE_HDNT,
    //Khu điểm du lịch
    ...ROUTE_PAGE_KDL
];
export const ROUTE_GUEST_ADMIN: RoutePropsI[] = [
    {
        ten: 'Thông tin người dùng',
        giaoDien: ProfileUpdate,
        duongDan: '/user-info',
    },
    {
        ten: 'Quản lý mật khẩu',
        giaoDien: PasswordManagement,
        duongDan: '/password-mgt',
    },
]
export const ROUTE_ADMIN: RoutePropsI[] = [];
export const ROUTE_GUEST: RoutePropsI[] = [...ROUTE_GUEST_DVDL, ...ROUTE_GUEST_ADMIN, ...ROUTE_GUEST_KDL];
