import AXIOS_INSTANCE from "..";
import { HoSoThuLyDichVuDuLich } from "../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { BaseService } from "../baseService";

export class CapSoDichVuDuLichService extends BaseService {
    getThongTin = (request: HoSoThuLyDichVuDuLich) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/cap-so/thong-tin", request, this.getTokenRequestHeaders());
    };
    capSo = (request: HoSoThuLyDichVuDuLich) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/cap-so/add-or-update", request, this.getTokenRequestHeaders());
    };
    danhSachCapSo = (request: HoSoThuLyDichVuDuLich) => {
        return AXIOS_INSTANCE.post("dich-vu-du-lich/cap-so/danh-sach-cap-so", request, this.getTokenRequestHeaders());
    };
}
