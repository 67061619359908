import { Box, NativeSelect } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import StringUtil from "utils/stringUtils";
import { HoSoThuLyDichVuDuLich } from "../../../../model/DichVuDuLich/HoSoThuLyDichVuDuLich";
import { ROUTE_GIAY_CHUNG_NHAN_GIAI_TRI_CHI_TIET } from "../../../../routes/route_dvdl";
import { CustomInput } from "../../../commons/input/customInput";
import { Cell, Row } from "../../../commons/table/tableCommons";
import { ThaoTacCSKDDichVuVuiChoiGiaiTri } from "../../Action/thaoTacCSKDDichVuVuiChoiGiaiTri";

interface IModalProps {
    openEditDialog: (itemSelected: HoSoThuLyDichVuDuLich) => void;
    open: boolean;
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.ChangeEvent<unknown>, value: number) => void;
    onChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    reloadData: () => void;
}
interface IDataListProps {
    dataList: HoSoThuLyDichVuDuLich[];
    deleteItem: (roleId: number) => void;
    modalEdit: IModalProps;
    pageProps: TablePaginationActionsProps;
}

export const DataListThongTinCSKDDichVuVuiChoiGiaiTri = (props: IDataListProps) => {
    const [rows, setRows] = useState<HoSoThuLyDichVuDuLich[]>([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const { reloadData } = props.pageProps;

    useEffect(() => {
        setRows(props.dataList);
        setTotalPage(Math.ceil(props.pageProps.count / props.pageProps.rowsPerPage));
        setRowsPerPage(props.pageProps.rowsPerPage);
    }, [props.dataList, props.pageProps.count, props.pageProps.rowsPerPage]);

    const getDiaChiDayDu = (data: HoSoThuLyDichVuDuLich): string => {
        let diaChiDayDu = "";
        return (diaChiDayDu =
            (data.soNha !== "" && data.soNha !== undefined && data.soNha !== null ? data.soNha + " " : "") +
            (data.tenDuong !== "" && data.tenDuong !== undefined && data.tenDuong !== null ? data.tenDuong + ", " : "") +
            (data.tenPhuong !== "" && data.tenPhuong !== undefined ? data.tenPhuong + ", " : "") +
            (data.tenHuyen !== "" && data.tenHuyen !== undefined ? data.tenHuyen + ", " : "") +
            "Thành Phố Cần Thơ");
    };
    return (
        <>
            <TableContainer style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <Cell component="th" scope="row" align="center" width="5%">
                                STT
                            </Cell>
                            <Cell component="th" scope="row" align="left" width="10%">
                                Số quyết định
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ngày cấp
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Ngày hết hạn
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Tên cơ sở
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Địa chỉ
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="20%">
                                Điện thoại
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="10%">
                                Tình trạng
                            </Cell>
                            <Cell component="th" scope="row" align="center" width="5%">
                                Thao tác
                            </Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length > 0 ? (
                            rows.map((row, i) => (
                                <Row key={row.hoSoThuLyId}>
                                    <Cell scope="row" align="center" style={{backgroundColor: StringUtil.getMaMau(row?.maMauTrangThai)}}>
                                        {(props.pageProps.page - 1) * rowsPerPage + i + 1}
                                    </Cell>
                                    <Cell>
                                        <NavLink
                                            style={{ textDecoration: "none" }}
                                            exact
                                            to={{
                                                pathname: ROUTE_GIAY_CHUNG_NHAN_GIAI_TRI_CHI_TIET.duongDan,
                                                state: { ...row, params: row.giayChungNhanId },
                                            }}
                                        >
                                            {row.soQuyetDinh}
                                        </NavLink>
                                    </Cell>
                                    <Cell align="center">
                                        {row.ngayCap != 0 && row.ngayCap != undefined ? moment(row.ngayCap).format("DD/MM/YYYY") : ""}
                                    </Cell>
                                    <Cell align="center">
                                        {row.ngayHetHan != 0 && row.ngayHetHan != undefined ? moment(row.ngayHetHan).format("DD/MM/YYYY") : ""}
                                    </Cell>
                                    <Cell align="left">{row.tenCoSo}</Cell>
                                    <Cell align="left">{getDiaChiDayDu(row)}</Cell>
                                    <Cell align="center">{row.soDienThoai}</Cell>
                                    <Cell align="center">{row.tenTrangThai}</Cell>
                                    <Cell align="center">
                                        <ThaoTacCSKDDichVuVuiChoiGiaiTri reloadData={reloadData} row={row} />
                                    </Cell>
                                </Row>
                            ))
                        ) : (
                            <Row>
                                <Cell colSpan={9} scope="row" align="center">
                                    {" "}
                                    Không có dữ liệu
                                </Cell>
                            </Row>
                        )}
                    </TableBody>
                </Table>
                <Box paddingTop="10px" display="flex" alignItems="center" fontSize="14px" color="#555555">
                    <Pagination
                        count={totalPage}
                        defaultPage={1}
                        siblingCount={1}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        onChange={props.pageProps.onChangePage}
                    />
                    <Box flexGrow={1} display="flex" alignItems="center" paddingX="5px">
                        <Box component="span" paddingX="5px">
                            Số mẫu tin trên trang
                        </Box>
                        <NativeSelect input={<CustomInput />} onChange={props.pageProps.onChangeRowsPerPage}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>20</option>
                        </NativeSelect>
                    </Box>
                    <Box component="span" paddingX="5px">
                        Mẫu tin {(props.pageProps.page - 1) * props.pageProps.rowsPerPage + 1} - {props.pageProps.count > props.pageProps.page * props.pageProps.rowsPerPage ? props.pageProps.page * props.pageProps.rowsPerPage : props.pageProps.count} / {props.pageProps.count}
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};
