import MomentUtils from "@date-io/moment";
import { Box, createStyles, FormHelperText, Grid, InputBase, InputLabel, makeStyles, Snackbar, TextField, Theme, Typography } from "@material-ui/core";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../apis";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { DanhMucIView_EN } from "../../../../apis/Common/model/CommonModel";
import { DanhMucTruongHopThuHoiService } from "../../../../apis/danhMucCN/DanhMucTruongHopThuHoiService";
import { LuHanhNoiDiaService } from "../../../../apis/LuHanhNoiDia/LuHanhNoiDiaService";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import { LOAIBIENDONG_TAMNGUNG, TINHTRANG_TAMNGUNG } from "../../../../constants/DichVuDuLichConstants";
import { ACCEPT_FILES, LOAIBIENDONG_CAPDOI, LOAIBIENDONG_CAPLAI} from "../../../../constants/LuHanhNoiDiaConstants";
import { GiayPhepLuHanhNoiDia_INIT } from "../../../../init/LuHanhNoiDia/GiayPhepLuHanhNoiDia_INIT";
import { HoSoThuLyLuHanhNoiDia_INIT } from "../../../../init/LuHanhNoiDia/HoSoThuLyLuHanhNoiDia_INIT";
import { GiayPhepKinhDoanhLuHanhDTO, HoSoThuLyLuHanhNoiDia } from "../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import DropZoneCustom from "../../../commons/hoso/CustomDropzone/DropZoneCustom";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import LuHanhNoiDiaButtonComponent from "../../ThuLyHoSo/Action/LuHanhNoiDiaButtonComponent"

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
        textSearch: {
            width: "90%",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const TamNgungLHNDComponent = () => { 
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });

    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const [currentDate, setCurrentDate] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [title, setTitle] = useState<string>();
    const helperTextStyles = bootstrapTextFieldHook.useHelperText();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [listDMTHThuHoi, setListDMTHThuHoi] = useState<DanhMucIView_EN[]>([]);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [dataAutocomple, setDataAutocomple] = useState<GiayPhepKinhDoanhLuHanhDTO[]>(new Array<GiayPhepKinhDoanhLuHanhDTO>());
    const [dataSoGiayPhep, setDataSoGiayPhep] = useState<GiayPhepKinhDoanhLuHanhDTO>({ ...GiayPhepLuHanhNoiDia_INIT });
    const [data, setData] = useState<HoSoThuLyLuHanhNoiDia>({ ...HoSoThuLyLuHanhNoiDia_INIT });
    const classes = useStyles();
    const luHanhNoiDiaService = new LuHanhNoiDiaService();
    const danhMucTruongHopThuHoiService = new DanhMucTruongHopThuHoiService();
    const fileServerService = new FileServerService();

    useEffect(() => {
        loadListDMTHThuHoi();
        initData();
    }, []);

    const initData = () => {
        let giayPhepId = location?.state?.giayPhepId;
        loadGiayPhepBySoGiayPhep(giayPhepId);
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        setCurrentDate(StringUtil.dateSecond());
        dispatchLoading(showLoading());
        luHanhNoiDiaService
            .getByMaHoSo(maHS)
            .then((res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {

                    const dataNew = {
                        ...res?.data?.data,
                        maHoSo: maHS,
                        maThuTuc: maThuTuc,
                        maTrangThai: TINHTRANG_TAMNGUNG.ma,
                        tenTrangThai: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                    };
                    setData(dataNew);
                    setDataSoGiayPhep({ ...dataSoGiayPhep, soGiayPhep: dataNew.soGiayPhepCu, ngayCap: dataNew.ngayCap });
                } else {
                    const dataNew = {
                        ...HoSoThuLyLuHanhNoiDia_INIT,
                        maHoSo: maHS,
                        maThuTuc: maThuTuc,
                        maTrangThai: TINHTRANG_TAMNGUNG.ma,
                        tenTrangThai: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                        files: new Array<FileEntry>(),
                    };
                    setData(dataNew);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const loadGiayPhepBySoGiayPhep = (newValue: string) => {
        let maHS = location.state?.maHoSo;
        
        luHanhNoiDiaService
            .getBySoGiayPhepTamNgung({ soGiayPhep: newValue })
            .then((res) => {
                if (res?.data?.data !== null && res?.data?.data !== undefined) {
                    let resData = {
                        ...res?.data?.data,
                        soGiayPhep: res?.data?.data.soGiayPhep,
                    } as GiayPhepKinhDoanhLuHanhDTO;
                    setDataSoGiayPhep(resData);
                    setData({
                        ...res?.data?.data,
                        hoSoThuLyId: data.hoSoThuLyId,
                        //    giayPhepId: data.giayPhepId,
                        soGiayPhepCu: newValue,
                        maHoSo: maHS,
                        maThuTuc: data.maThuTuc,
                        ngayThuHoi: data.ngayThuHoi,
                        soLanCapLai: data.soLanCapLai,
                        maTrangThai: TINHTRANG_TAMNGUNG.ma,
                        tenTrangThai: TINHTRANG_TAMNGUNG.ten,
                        maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
                        tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
                    });
                } else {
                    setMessage("Số giấy chứng nhận không tồn tại");
                    setSeverity("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setSeverity("error");
            });
    };
    const loadListDMTHThuHoi = async () => {
        await danhMucTruongHopThuHoiService
            .getAllActive(true)
            .then((res) => {
                if (res && res.data && res.data.data) {
                    setListDMTHThuHoi(res.data.data);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const handleSubmit = async () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(data.ngayQuyetDinhThuHoi)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(data.lyDo)) {
            setTabRequired(0);
            return;
        }
        if (data.ngayQuyetDinhThuHoi < data.ngayCapGCN) {
            setAlert({
                showAlert: true,
                message: "Ngày quyết định thu hồi phải lớn hơn ngày cấp",
                severity: "warning",
            });
            setTabRequired(0);
            return;
        }
        setSubmit(false);
        dispatchLoading(showLoading());
        await luHanhNoiDiaService
            .insertOrUpdate(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setData({
                        ...response.data.data,
                        id: response.data.data.id,
                        resoucePK: response.data.data.resoucePK,
                        ngayTao: response.data.data.ngayTao,
                    });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
                //lỗi
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let maHoSo = location?.state?.maHoSo;
            let dataNew = {
                ...data,
                maHoSo: maHoSo,
                [name]: value,
            };
            setData(dataNew);
        }
    };
    const handleChange = (e: any) => {
        let target = e.target;
        let name = target.name;
        setDataParam(name, e.target.value);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSoGiayPhep = async (event: any) => {
        let soGiayPhepCu = event.target.value;
        setDataSoGiayPhep({
            ...dataSoGiayPhep,
            soGiayPhep: soGiayPhepCu,
            soGiayPhepCu: soGiayPhepCu
        });
        await luHanhNoiDiaService
            .getByAutocompleteSoGiayPhep(soGiayPhepCu, LOAIBIENDONG_CAPDOI.ma, true, 10)
            .then((res) => {
                let dataAutocompleNew = res?.data.data;
                if (StringUtil.listIsNotEmty(dataAutocompleNew)) {
                    setDataAutocomple([...dataAutocompleNew]);
                } else {
                    setDataAutocomple(new Array<GiayPhepKinhDoanhLuHanhDTO>());
                }
            })
            .catch((error) => { });
    };

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let tenValue = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            [keyMa]: value?.code,
            [keyTen]: tenValue,
        });
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const handleResetData = () => {
        const dataNew = {
            ...HoSoThuLyLuHanhNoiDia_INIT,
            hoSoThuLyId: data.hoSoThuLyId,
            giayPhepId: data.giayPhepId,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            // maTrangThai: TINHTRANG_THUHOI.ma,
            // tenTrangThai: TINHTRANG_THUHOI.ten,
            maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
            tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
        };
        setData(dataNew);
        const dataSoGiayPhepNew = {
            ...GiayPhepLuHanhNoiDia_INIT,
            hoSoThuLyId: dataSoGiayPhep.hoSoThuLyId,
            giayPhepId: dataSoGiayPhep.giayPhepId,
            maHoSo: dataSoGiayPhep.maHoSo,
            maThuTuc: dataSoGiayPhep.maThuTuc,
            // maTrangThai: TINHTRANG_HOATDONG.ma,
            // tenTrangThai: TINHTRANG_HOATDONG.ten,
            maBienDong: LOAIBIENDONG_TAMNGUNG.ma,
            tenBienDong: LOAIBIENDONG_TAMNGUNG.ten,
        };
        setDataSoGiayPhep(dataSoGiayPhepNew);
    };
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        luHanhNoiDiaService.downloadFileThuLy({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
        });
    };
    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>TẠM NGƯNG GIẤY PHÉP KINH DOANH DỊCH VỤ LỮ HÀNH NỘI ĐỊA </h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                                {/* <CustomTab label="Tệp đính kèm" /> */}
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid container spacing={2} style={{ marginTop: "2%" }}>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Số GPKD dịch vụ lữ hành</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.soGiayPhep}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Ngày cấp</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>
                                        {dataSoGiayPhep.ngayCap ? moment(dataSoGiayPhep.ngayCap).format("DD/MM/YYYY") : ""}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tên doanh nghiệp</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenDoanhNghiep}</InputLabel>
                                    </Grid>
                                    <Grid item xs={6} className="grid-item"></Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tình trạng</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenTrangThai}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Lần cấp lại</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep?.soLanCapLai}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tên giao dịch</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenDichVu}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tên viết tắt</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenVietTat}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Điện thoại</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.dienThoai}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Fax</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.fax}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Email</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.email}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Website</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.website}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel classes={labelStyles}>Địa chỉ</InputLabel>
                                    </Grid>
                                    <Grid item xs={9} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.diaChiDayDu}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Người đại diện theo pháp luật</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.nguoiDaiDienTheoPhapLuat}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Ngày sinh</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep?.ngaySinh ? moment(dataSoGiayPhep?.ngaySinh).format("DD/MM/YYYY") : ""}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Giới tính</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenGioiTinh}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Dân tộc</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenDanToc}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Chức danh</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.chucDanh}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Quốc tịch</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenQuocTich}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Số điện thoại</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.soDienThoaiNDD}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Email</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.mailNDD}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Số CMND/ Hộ chiếu/ Căn cước</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.soGiayTo}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Ngày cấp CMND</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>
                                            {dataSoGiayPhep?.ngayCapSoGiayTo ? moment(dataSoGiayPhep?.ngayCapSoGiayTo).format("DD/MM/YYYY") : ""}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Nơi cấp CMND</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.noiCapSoGiayTo}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Địa chỉ thường trú</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.diaChiThuongTru}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Chỗ ở hiện nay</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.choOHienNay}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tên, địa chỉ chi nhánh</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenDiaChiChiNhanh}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tên, địa chỉ văn phòng đại diện</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.tenVanPhongDaiDien}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Số GCNĐKDN/ GCNĐKKD</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.soGCN}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Ngày cấp</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep?.ngayCapGCN ? moment(dataSoGiayPhep?.ngayCapGCN).format("DD/MM/YYYY") : ""}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Nơi cấp</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.noiCapGCN}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tài khoản ký quỹ số</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.taiKhoanKyQuySo}</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item" container justify="flex-end">
                                        <InputLabel>Tại ngân hàng</InputLabel>
                                    </Grid>
                                    <Grid item xs={3} className="grid-item">
                                        <InputLabel>{dataSoGiayPhep.nganHang}</InputLabel>
                                    </Grid>
                                    <Grid container style={{ marginTop: "20px" }}>
                                        <Grid item xs={6} className="grid-item">
                                            <InputLabel classes={labelStyles} required>
                                                Lý do tạm ngưng
                                            </InputLabel>
                                            <InputBase
                                                classes={inputStyles}
                                                fullWidth
                                                name="lyDo"
                                                value={data.lyDo}
                                                onChange={handleChange}
                                                error={submit && StringUtil.isNullOrEmty(data.lyDo)}
                                            />
                                            {submit && StringUtil.isNullOrEmty(data.lyDo) && (
                                                <FormHelperText className="-error">Vui lòng lý do tạm ngưng</FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel classes={labelStyles} required>
                                                Ngày tạm ngưng
                                            </InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    required
                                                    name="ngayQuyetDinhThuHoi"
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    value={
                                                        data.ngayQuyetDinhThuHoi && data.ngayQuyetDinhThuHoi !== 0 ? new Date(data.ngayQuyetDinhThuHoi) : null
                                                    }
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayQuyetDinhThuHoi")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} className="grid-item">
                                            <InputLabel classes={labelStyles}>Ghi chú</InputLabel>
                                            <InputBase fullWidth classes={inputStyles} name="ghiChu" value={data?.ghiChu} onChange={handleChange} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "30px" }}>
                                        <Grid item xs={12} container className="grid-item">
                                            <DropZoneCustom
                                                acceptFiles={ACCEPT_FILES}
                                                attachFileServer={data.files}
                                                url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/luhanh"}
                                                handleChangeStatus={handleChangeStatus}
                                                handleDeleteFileServer={handleDeleteFileServer}
                                                handleDownloadFile={handleDownloadFile}
                                            />
                                        </Grid>
                                        <Grid item xs={3} className="grid-item"></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                                <LuHanhNoiDiaButtonComponent
                                    onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                    onSubmit={handleSubmit}
                                    onResetData={handleResetData}
                                    onOpenDialog={handleOpenDialog}
                                />
                            </Grid>
                        </TabPanel>
                    )}
                    {/* <Grid container style={{ float: 'left' }} item xs={12} className="grid-item" >
                        <LuHanhNoiDiaButtonComponent onDownloadFileBieuMau={handleDownloadFileBieuMau} onSubmit={handleSubmit} onResetData={handleResetData} onOpenDialog={handleOpenDialog} />
                    </Grid> */}
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </>
        </div>
    );
};
export default TamNgungLHNDComponent;
