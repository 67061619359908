import { Box, Grid, Snackbar, Typography } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import { FileEntry } from "apis/Common/fileEntry";
import { DiaDiemDuLichService } from "apis/DiaDiemDuLich/DiaDiemDuLichService";
import { ConfirmationDialogRaw } from "components/commons/dialog/confirmDialog";
import { OverlayLoading } from "components/commons/overlayLoading/overlayLoading";
import { CustomTab, CustomTabs } from "components/commons/tabs";
import { TINHTRANG_TAMNGUNG_DIADIEMDULICH } from "constants/DiaDiemDuLichConstants";
import { HoSoDiaDiemDuLichDTO_INIT } from "init/DiaDiemDuLich/HoSoDiaDiemDuLichDTO_INIT";
import { LichSuDiaDiemDuLichDTO_INIT } from "init/DiaDiemDuLich/LichSuDiaDiemDuLichDTO_INIT";
import { HoSoDiaDiemDuLichDTO } from "model/DiaDiemDuLich/HoSoDiaDiemDuLichDTO";
import { LichSuDiaDiemDuLichDTO } from "model/DiaDiemDuLich/LichSuDiaDiemDuLichDTO";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import { hideLoading, LoadingAction, showLoading } from "redux/actions/applicationAction";
import { openNotification } from "redux/actions/notificationAction";
import StringUtil from "utils/stringUtils";
import BienDongKhuDuLichButtonComponent from "../BienDongKhuDuLichButtonComponent";
import ThongTinBienDongComponent from "../ThongTinBienDongComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const TamNgungKhuDuLichComponent = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [currentDate, setCurrentDate] = useState(0);
    const [giayChungNhanId, setGiayChungNhanId] = useState(0);
    const [hoSoThuLyId, setHoSoThuLyId] = useState(0);
    const [lichSu, setLichSu] = useState<LichSuDiaDiemDuLichDTO>({ ...LichSuDiaDiemDuLichDTO_INIT });
    const [data, setData] = useState<HoSoDiaDiemDuLichDTO>({...HoSoDiaDiemDuLichDTO_INIT});
    const hoSoDiaDiemId = location.state?.hoSoDiaDiemId;
    const service = new DiaDiemDuLichService();
    const dispatch = useDispatch();

    useEffect(() => {
        initData();
    }, [hoSoDiaDiemId]);

    const initData = async () => {
        if(hoSoDiaDiemId && hoSoDiaDiemId > 0){
            dispatchLoading(showLoading());
            try {
                if(hoSoDiaDiemId && hoSoDiaDiemId > 0){
                    const data = await new DiaDiemDuLichService().getByHoSoID(hoSoDiaDiemId);
                    setData({...data});
                    const bienThong =  await new DiaDiemDuLichService().getBienDongLichSu(data?.giayPhepId, TINHTRANG_TAMNGUNG_DIADIEMDULICH.ma);
                    if(bienThong && bienThong.id > 0){
                        setLichSu({...bienThong})
                    }else{
                        setLichSu({
                            ...LichSuDiaDiemDuLichDTO_INIT,
                            maBienDong : TINHTRANG_TAMNGUNG_DIADIEMDULICH.ma,
                            tenBienDong : TINHTRANG_TAMNGUNG_DIADIEMDULICH.ten,
                            giayPhepId : data?.giayPhepId,
                            hoSoThuLyId : data?.id,
                            soQuyetDinh : data?.giayPhep?.soQuyetDinh,
                            ngayQuyetDinh : data?.giayPhep?.ngayCapVanBan,
                            maHuyen: data?.maHuyen,
                            tenHuyen: data?.tenHuyen,
                            maPhuong: data?.maPhuong,
                            tenPhuong : data?.tenPhuong,
                            maTinhTrang : TINHTRANG_TAMNGUNG_DIADIEMDULICH.ma,
                            tenTinhTrang : TINHTRANG_TAMNGUNG_DIADIEMDULICH.ten,
                            maLoaiHinh: data?.maLoaiHinh,
                            tenLoaiHinh : data?.tenLoaiHinh
                        })
                    }  
                }

            }catch (e) {} 
            finally {
                dispatchLoading(hideLoading());
            }
               
        }

    };

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setLichSu(dataNew);
        } else {
            let dataNew = {
                ...lichSu,
                [name]: value,
            };
            setLichSu(dataNew);
        }
    };

    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };
    const handleResetData = () => {
        const dataNew = {
            ...lichSu,
            lyDo: "",
            ghiChu: "",
            ngayBienDong: 0,
            maBienDong : TINHTRANG_TAMNGUNG_DIADIEMDULICH.ma,
            tenBienDong : TINHTRANG_TAMNGUNG_DIADIEMDULICH.ten,
            giayPhepId : data?.giayPhepId,
            hoSoThuLyId : data?.id,
            soQuyetDinh : data?.giayPhep?.soQuyetDinh,
            ngayQuyetDinh : data?.giayPhep?.ngayCapVanBan,
            maHuyen: data?.maHuyen,
            tenHuyen: data?.tenHuyen,
            maPhuong: data?.maPhuong,
            tenPhuong : data?.tenPhuong,
            maTinhTrang : TINHTRANG_TAMNGUNG_DIADIEMDULICH.ma,
            tenTinhTrang : TINHTRANG_TAMNGUNG_DIADIEMDULICH.ten,
            maLoaiHinh: data?.maLoaiHinh,
            tenLoaiHinh : data?.tenLoaiHinh,
            files: new Array<FileEntry>(),
        };
        setLichSu(dataNew);
        setSubmit(false);
    };
    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };

    const handleSubmit = async () => {
        setTabRequired(-1);
        setSubmit(true);
        if (StringUtil.isNullOrEmty(lichSu.lyDo)) {
            setTabRequired(0);
            return;
        }
        if (StringUtil.isNullOrEmty(lichSu.ngayBienDong)) {
            setTabRequired(0);
            return;
        }
        dispatchLoading(showLoading());
        await service
            .insertOrUpdateTrangThai(lichSu)
            .then((response) => {
                console.log('respone : ', response?.data?.data);
                if (response.data.error_code === "SUCCESSFUL" && response?.data?.data) {
                   setLichSu({
                       ...response?.data?.data
                   })
                } 
                dispatch(openNotification({ severity: "success", open: true, content: response?.data?.error_message }));
            })
            .catch((error) => {
                setAlert({
                    showAlert: true,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    return (
        <>
            {
                <div className="dash-content">
                    <div className="dash-title">
                        <span>BIẾN ĐỘNG KHU/ĐIỂM DU LỊCH</span>
                    </div>
                    <Grid container spacing={2}>
                        <OverlayLoading />
                        <Snackbar
                            open={alert.showAlert}
                            onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            autoHideDuration={3000}
                        >
                            <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                                {alert.message}
                            </Alert>
                        </Snackbar>
                        <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                            <Box mt="5px" bgcolor="#f2f2f2">
                                <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                    <CustomTab style={tabRequired == 0 ? { color: "red" } : {}} label="Thông tin chung" />
                                    {/* <CustomTab label="Tệp tin đính kèm" /> */}
                                    {/* <CustomTab label="Lịch sử" /> */}
                                </CustomTabs>
                            </Box>
                        </Grid>
                        {indexTab === 0 && (
                            <TabPanel value={indexTab} index={0}>
                                <ThongTinBienDongComponent data={data} onUpdateData={setDataParam} submit={submit} lichSu={lichSu} />
                            </TabPanel>
                        )}
                        <Grid container item xs={12} className="grid-item">
                            <BienDongKhuDuLichButtonComponent
                                onSubmit={handleSubmit}
                                onResetData={handleResetData}
                                onOpenDialog={handleOpenDialog}
                                isChiTiet={false}
                            />
                        </Grid>
                    </Grid>
                </div>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}

        </>

    );
};
export default TamNgungKhuDuLichComponent;
