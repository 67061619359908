import MomentUtils from "@date-io/moment";
import { Box, createStyles, Grid, InputLabel, makeStyles, Snackbar, TextField, Theme, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DOANH_NGHIEP } from "../../../../apis/quanTri/danhMucFakeService";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { DanhMucDanTocService } from "../../../../apis/danhMuc/danhMucDanTocService";
import { DanhMucGioiTinhService } from "../../../../apis/danhMuc/danhMucGioiTinhService";
import { DanhMucQuocGiaService } from "../../../../apis/danhMuc/danhMucQuocGiaService";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO, DanhMucDungChungDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { LuHanhNoiDiaService } from "../../../../apis/LuHanhNoiDia/LuHanhNoiDiaService";
import { ACCEPT_FILES, LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from "../../../../constants/LuHanhNoiDiaConstants";
import { HoSoThuLyLuHanhNoiDia_INIT } from "../../../../init/LuHanhNoiDia/HoSoThuLyLuHanhNoiDia_INIT";
import { HoSoThuLyLuHanhNoiDia } from "../../../../model/LuHanhNoiDia/GiayPhepLuHanhNoiDia";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { ThongTinChuyenNhanhUtil } from "../../../../utils/thongTinChuyenNhanhUtil";
import { AlertMessage } from "../../../commons/alert";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { ChonToaDoComponent } from "../../../commons/map/ChonToaDoComponent";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import DauKyLuHanhNoiDiaButton from "../Action/DauKyLuHanhNoiDiaButton";
import LuHanhNoiDiaButtonComponent from "../Action/LuHanhNoiDiaButtonComponent";
import DanhSachDuThaoModal from "../thuly/danhSachDuThaoModal";
import ThuLyButtonComponent from "../thuly/ThuLyButtonComponent";
import AXIOS_INSTANCE from "../../../../apis";
import { HoSoCuaToiMgtService, ThongTinBuocDTO } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";
import DropZoneCustom from "../../../commons/hoso/CustomDropzone/DropZoneCustom";
import { ThongTinDungChungService } from "apis/hoSoCuaToi/thongTinDungChungService";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CapGiayLHNDComponent = () => {
    const [indexTab, setIndexTab] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [data, setData] = useState<HoSoThuLyLuHanhNoiDia>({ ...HoSoThuLyLuHanhNoiDia_INIT });
    const luHanhNoiDiaService = new LuHanhNoiDiaService();
    const fileServerService = new FileServerService();
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const [buocChuyens, setBuocChuyens] = useState<ThongTinBuocDTO[]>([]);
    const [open, setOpen] = useState(false);
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [currentDate, setCurrentDate] = useState(0);
    const location = useLocation<any>();
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [title, setTitle] = useState<string>("NHẬP ĐẦU KỲ DOANH NGHIỆP LỮ HÀNH NỘI ĐỊA");

    const history = useHistory<any>();

    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const danhMucService = new DanhMucService();
    const [danTocs, setDanTocs] = useState<DanhMucDungChungDTO[]>([]);
    const [quocGias, setQuocGias] = useState<DanhMucDungChungDTO[]>([]);
    const [gioiTinhs, setGioiTinhs] = useState<DanhMucDungChungDTO[]>([]);
    const thongTinDungChungService = new ThongTinDungChungService();
    const [showDanhSachDuThaoModal, setShowDanhSachDuThaoModal] = useState(false);

    useEffect(() => {
        initData();
        loadQuans("92");
    }, []);

    const initData =  async () => {
        dispatchLoading(showLoading());
        let giayPhepId = location?.state?.giayPhepId;
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        setCurrentDate(StringUtil.dateSecond());
        let danToc =  await new DanhMucDanTocService().findAll();
        let quocGia =  await new DanhMucQuocGiaService().findAll();
        let gioiTinh =  await new DanhMucGioiTinhService().findAll();
        setDanTocs(danToc);
        setQuocGias(quocGia);
        setGioiTinhs(gioiTinh);

        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        hoSoCuaToiMgtService.chiTiet(location.state?.maHoSo).then((p) => {
            if (p.data && p.data.data) {
                hoTenNguoiNop = p.data.data.nguoiNop;
                soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });

        if (maHS) {
            
            await luHanhNoiDiaService
                .getByMaHoSo(maHS)
                .then(async(res) => {
                    if (res?.data?.data !== null && res?.data?.data !== undefined && res?.data?.data.hoSoThuLyId > 0) {
                        const dataNew = {
                            ...res?.data?.data,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                        };
                        setData(dataNew);
                    } else {
                        let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHS);
                        let maHuyen ="", tenHuyen = "", maPhuong = "", tenPhuong = "", gioiTinh="", tenGioiTinh = "", maQuocTich = "", tenQuocTich = "", noiCapCM="";
                        if(!StringUtil.isNullOrEmty(dataEform?.quanhuyen)){
                            let donViHanhChinh  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.quanhuyen);
                            maHuyen = donViHanhChinh?.code;
                            tenHuyen = donViHanhChinh?.name;
                        }
                        if(!StringUtil.isNullOrEmty(dataEform?.xaphuong)){
                            let donViHanhChinhCap3  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.xaphuong);
                            maPhuong = donViHanhChinhCap3?.code;
                            tenPhuong = donViHanhChinhCap3?.name;
                        }
                        if(!StringUtil.isNullOrEmty(dataEform?.gioitinh)){
                        if(dataEform?.gioitinh === '0'){
                                gioiTinh = "2";
                                tenGioiTinh = "Giới tính nam"
                        }else if(dataEform?.gioitinh === '1'){
                                gioiTinh = "1";
                                tenGioiTinh = "Giới tính nữ"
                        }        
                        }
                        if(!StringUtil.isNullOrEmty(dataEform?.quoctich)){
                            let quocTich = await new DanhMucQuocGiaService().findByCode(dataEform?.quoctich);
                            if(quocTich){
                                maQuocTich = !StringUtil.isNullOrEmty(quocTich?.code) ? quocTich?.code : HoSoThuLyLuHanhNoiDia_INIT?.maDanToc;
                                tenQuocTich = !StringUtil.isNullOrEmty(quocTich?.name) ? quocTich?.name : HoSoThuLyLuHanhNoiDia_INIT?.tenDanToc;
                            }
                        }
                        if (!StringUtil.isNullOrEmty(dataEform?.noicapcmnd)) {
                            let noiCap  =  await new DanhMucService().getDanhMucDiaChiByCode(dataEform?.noicapcmnd);
                            noiCapCM = noiCap?.name;
                        }
                        const dataNew = {
                            ...HoSoThuLyLuHanhNoiDia_INIT,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            // soGiayTo :  dataEform?.cmnd ? dataEform?.cmnd : HoSoThuLyLuHanhNoiDia_INIT.soGiayTo,
                            // ngayCapSoGiayTo : dataEform?.ngaycapcmnd ? StringUtil.stringDateToNumber(dataEform?.ngaycapcmnd) : HoSoThuLyLuHanhNoiDia_INIT.ngayCapSoGiayTo,
                            // noiCapSoGiayTo :  !StringUtil.isNullOrEmty(noiCapCM) ? noiCapCM :  HoSoThuLyLuHanhNoiDia_INIT?.noiCapSoGiayTo,
                            // ngaySinh : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaythangsinh) : HoSoThuLyLuHanhNoiDia_INIT.ngaySinh,
                            soGCN : dataEform?.giaydkkd,
                            ngayCapGCN : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : HoSoThuLyLuHanhNoiDia_INIT.ngayCapGCN,
                            noiCapGCN : dataEform?.noicapdkkd,
                            // diaChiThuongTru : dataEform?.hokhauthuongtru,
                            // choOHienNay : dataEform?.noiohiennay,                
                            tenDoanhNghiep : dataEform?.tendoanhnghiep,
                            dienThoai : soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt,
                            fax  : dataEform?.fax, 
                            email  : dataEform?.email, 
                            website  : dataEform?.website, 
                            maHuyen : !StringUtil.isNullOrEmty(maHuyen) ? maHuyen :  HoSoThuLyLuHanhNoiDia_INIT?.maHuyen,
                            tenHuyen : !StringUtil.isNullOrEmty(tenHuyen) ? tenHuyen : HoSoThuLyLuHanhNoiDia_INIT?.tenHuyen,
                            maPhuong : !StringUtil.isNullOrEmty(maPhuong) ? maPhuong :HoSoThuLyLuHanhNoiDia_INIT?.maPhuong,
                            tenPhuong : !StringUtil.isNullOrEmty(tenPhuong) ? tenPhuong : HoSoThuLyLuHanhNoiDia_INIT?.tenPhuong,
                            gioiTinh : gioiTinh,
                            tenGioiTinh : tenGioiTinh,
                            nguoiDaiDienTheoPhapLuat : dataEform?.hotennguoidaidien,
                            maQuocTich : maQuocTich,
                            tenQuocTich : tenQuocTich,
                            chucDanh : dataEform?.chucvunguoidaidien,
                            tenDuong: dataEform?.diachi,

                        };
                        setData(dataNew);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (giayPhepId != "") {
            luHanhNoiDiaService
                .getGiayPhepById(giayPhepId)
                .then((p) => {
                    if (p.data.data !== null && p.data.data !== undefined) {
                        setData({
                            ...p?.data?.data,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            giayPhepId: history?.location?.state.giayPhepId,
                            hoSoThuLyId: history?.location?.state?.hoSoThuLyId,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            const dataNew = {
                ...HoSoThuLyLuHanhNoiDia_INIT,
                maHoSo: maHS,
                maTrangThai: TINHTRANG_HOATDONG.ma,
                tenTrangThai: TINHTRANG_HOATDONG.ten,
                maThuTuc: maThuTuc,
            };
            setData(dataNew);
        }
        dispatchLoading(hideLoading());
    };

    useEffect(() => {
        if (data.maHoSo) {
            setTitle(`CẤP GIẤY PHÉP KINH DOANH DỊCH VỤ LỮ HÀNH NỘI ĐỊA - MÃ HỒ SƠ ${data.maHoSo}`);
        }
    }, [data?.maHoSo]);

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const loadPhuongs = (maQuan: string) => {
        danhMucService
            .getDanhMucDiaChi(maQuan)
            .then((res) => {
                setPhuongs(res);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleChangeSelectQuan = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({
            ...data,
            maPhuong: "",
            tenPhuong: "",
            [nameValue]: value?.code,
            [nameText]: valueText,
        });
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };
    const handleSubmit = () => {
        setSubmit(true);

        if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maLoaiHinhDoanhNghiep)) {
            return;
        }
        if (data.ngayCap > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenDoanhNghiep)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHuyen)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuong)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.soGCN)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.soGiayTo)) {
            return;
        }
        if ((!StringUtil.isNullOrEmty(data.soGiayTo) && data.soGiayTo.length < 9) || data.soGiayTo.length <= 0 || data.soGiayTo.length > 12) {
            setAlert({
                showAlert: true,
                message: "Số CMND phải từ 9 đến 12 số",
                severity: "warning",
            });
            return;
        }
        if (data.ngayCapGCN > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp GCNĐKDN không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (data.ngayCapSoGiayTo > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp CMND không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (data.ngaySinh > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày sinh không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }

        dispatchLoading(showLoading());
        let giayPhepId = data.giayPhepId;
        let dataInfo = { ...data, giayPhepId: data.giayPhepId, 
            maTrangThai: TINHTRANG_HOATDONG.ma,
            maBienDong: LOAIBIENDONG_CAPMOI.ma,
            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
            tenTrangThai: TINHTRANG_HOATDONG.ten };
        setSubmit(false);
        luHanhNoiDiaService
            .insertOrUpdate(dataInfo)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setData({ ...response.data.data });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else if (response.data.error_code === "409") {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "error",
                    });
                } else {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
        }
        setOpenDeleteUserDlg(false);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let tenValue = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: tenValue,
        });
    };

    const handleChangeAutocomplete = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => { };
    const handleResetData = () => {
        const dataNew = {
            ...HoSoThuLyLuHanhNoiDia_INIT,
            hoSoThuLyId: data.hoSoThuLyId,
            giayPhepId: data.giayPhepId,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            maTrangThai: TINHTRANG_HOATDONG.ma,
            tenTrangThai: TINHTRANG_HOATDONG.ten,
            maBienDong: LOAIBIENDONG_CAPMOI.ma,
            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
        };
        setData(dataNew);
    };

    const showHidePopperThaoTac = () => {
        hoSoCuaToiMgtService.getThongTinXuLy(data.maHoSo).then((res) => {
            const buocChuyensNew = [...res];
            setBuocChuyens(buocChuyensNew);
        });
        setOpen(!open);
    };

    const showHidePopperDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };

    const handleToggleDuThao = () => {
        setShowDanhSachDuThaoModal(!showDanhSachDuThaoModal);
    };
   
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };
    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        luHanhNoiDiaService.downloadFileThuLy({ templateId: bieuMau.fileId, maHoSo: data.maHoSo }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
        });
    };
    const handleChangeToaDo = (dataToaDo: any) => {
        let newData = {
            ...data,
            viDo: dataToaDo.currentViDo,
            kinhDo: dataToaDo.currentKinhDo,
            diaChiDayDu: dataToaDo.diaChiDayDu,
        };
        setData(newData);
    };
    const getDiaChiDayDu = (): string => {
        if (StringUtil.isNullOrEmty(data.diaChiDayDu)) {
            return data.tenPhuong + ", " + data.tenHuyen + ", " + " Cần Thơ";
        } else {
            return data.diaChiDayDu;
        }
    };

    async function handleSearchCMND() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinCongDan(data?.soGiayTo)
        .then((res) => {
            
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.soCMND) {
                // let isExist = false;
                // if(!StringUtil.isNullOrEmty(res?.data?.data?.data?.maQuocTich) && !StringUtil.isNullOrEmty(res?.data?.data?.data?.tenQuocTich)
                // && !StringUtil.isNullOrEmty(res?.data?.data?.data?.nguoiDaiDienTheoPhapLuat)&& !StringUtil.isNullOrEmty(res?.data?.data?.data?.maGioiTinh)){
                //      isExist = true;   
                // }
                
                setData({ ...data,
                    ngayCapSoGiayTo: res?.data?.data?.data?.cmt_NgayCap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.cmt_NgayCap) : data?.ngayCapSoGiayTo,
                    ngaySinh: res?.data?.data?.data?.ngayThangNamSinh ? StringUtil.formatNgaySinh(res?.data?.data?.data?.ngayThangNamSinh) : data?.ngaySinh,
                    noiCapSoGiayTo : res?.data?.data?.data?.cmt_NoiCap,
                    maQuocTich : res?.data?.data?.data?.maQuocTich ? res?.data?.data?.data?.maQuocTich : data.maQuocTich,
                    tenQuocTich : res?.data?.data?.data?.tenQuocTich ? res?.data?.data?.data?.tenQuocTich : data.tenQuocTich,
                    nguoiDaiDienTheoPhapLuat: res?.data?.data?.data?.tenCongDan ? res?.data?.data?.data?.tenCongDan : data.nguoiDaiDienTheoPhapLuat,
                    gioiTinh: res?.data?.data?.data?.maGioiTinh ? res?.data?.data?.data?.maGioiTinh : data.gioiTinh,
                    tenGioiTinh: res?.data?.data?.data?.tenGioiTinh ? res?.data?.data?.data?.tenGioiTinh : data.tenGioiTinh,
                    diaChiThuongTru: res?.data?.data?.data?.diaChi ? res?.data?.data?.data?.diaChi : data.diaChiThuongTru,
                    soDienThoaiNDD: res?.data?.data?.data?.soDienThoai ? res?.data?.data?.data?.soDienThoai : data.soDienThoaiNDD
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số căn cước/CMND không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }


    async function handleSearchDoanhNghiep() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinDoanhNghiep(data.soGCN)
        .then((res) => {
            
            let tenNguoiDaiDien = "", maGT="",tenGioiTinh="", maDanToc="", tenDanToc="",
             maQuocTich="", tenQuocTich="", hokhauthuongtru="", choOHienNay="", cmnd="", ngayCapCMND="",noiCapCMND="";
            for (let index = 0; index < res?.data?.data?.data?.listThanhVien.length; index++) {
                if (res?.data?.data?.data?.listThanhVien[index].isNguoiDaiDienPhapLuat=="1"){
                    tenNguoiDaiDien=res?.data?.data?.data?.listThanhVien[index].hoTen;
                    maGT=res?.data?.data?.data?.listThanhVien[index].maGioiTinh;
                    tenGioiTinh=res?.data?.data?.data?.listThanhVien[index].tenGioiTinh;
                    maDanToc=res?.data?.data?.data?.listThanhVien[index].maDanToc;
                    tenDanToc=res?.data?.data?.data?.listThanhVien[index].tenDanToc;
                    maQuocTich=res?.data?.data?.data?.listThanhVien[index].maQuocTich;
                    tenQuocTich=res?.data?.data?.data?.listThanhVien[index].tenQuocTich;
                    hokhauthuongtru=res?.data?.data?.data?.listThanhVien[index].hoKhauThuongTru_FullAddress;
                    choOHienNay=res?.data?.data?.data?.listThanhVien[index].choOHienNay_FullAddress;
                    cmnd=res?.data?.data?.data?.listThanhVien[index].soGiayToTuyThan;
                    ngayCapCMND=res?.data?.data?.data?.listThanhVien[index].ngayCap;
                    noiCapCMND=res?.data?.data?.data?.listThanhVien[index].noiCap;
                };
            }
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.maSoDoanhNghiep) {
                setData({ ...data,
                    ngayCapGCN : res?.data?.data?.data?.ngayThanhLap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.ngayThanhLap) : data?.ngayCapGCN,
                    noiCapGCN :  res?.data?.data?.data?.noiCap,
                    tenDoanhNghiep: res?.data?.data?.data?.tenDoanhNghiep_VN,
                    dienThoai: res?.data?.data?.data?.dienThoai,
                    fax: res?.data?.data?.data?.fax,
                    email: res?.data?.data?.data?.email,
                    website: res?.data?.data?.data?.website,
                    nguoiDaiDienTheoPhapLuat: tenNguoiDaiDien,
                    gioiTinh:maGT,
                    tenGioiTinh:tenGioiTinh,
                    maDanToc:maDanToc,
                    tenDanToc: tenDanToc,
                    maQuocTich: maQuocTich,
                    tenQuocTich: tenQuocTich,
                    diaChiThuongTru: hokhauthuongtru,
                    choOHienNay: choOHienNay,
                    soGiayTo: cmnd,
                    ngayCapSoGiayTo: StringUtil.stringDateToNumber(moment(ngayCapCMND.split('T')[0]).format("DD/MM/YYYY")),
                    noiCapSoGiayTo: noiCapCMND
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số đăng ký doanh nghiệp không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    return (
        <>
        <div className="dash-content">
            {
                <Grid container>
                    <OverlayLoading />
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>{title} </h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab label="Thông tin chung" />
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {indexTab === 0 && (
                        <TabPanel value={indexTab} index={0}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                                            <Grid item xs={3} className="grid-item">
                                                <TextField
                                                    fullWidth
                                                    label={"Số giấy phép"}
                                                    required
                                                    name="soGiayPhep"
                                                    InputLabelProps={{ classes: labelStyles }}
                                                    value={data.soGiayPhep}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: inputStyles,
                                                    }}
                                                    onChange={handleChange}
                                                    error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                                    helperText={submit && StringUtil.isNullOrEmty(data.soGiayPhep) ? "Vui lòng nhập số giấy phép" : ""}
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={3} className="grid-item">
                                                <TextField
                                                    fullWidth
                                                    label={"Số giấy phép"}
                                                    required
                                                    name="soGiayPhep"
                                                    InputLabelProps={{ classes: labelStyles }}
                                                    value={data.soGiayPhep}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        classes: inputStyles,
                                                    }}
                                                    onChange={handleChange}
                                                    error={submit && StringUtil.isNullOrEmty(data.soGiayPhep)}
                                                    helperText={submit && StringUtil.isNullOrEmty(data.soGiayPhep) ? "Vui lòng nhập số giấy phép" : ""}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={3} className="grid-item">
                                            <InputLabel classes={labelStyles} required>
                                                Ngày cấp
                                            </InputLabel>
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    showTodayButton={true}
                                                    value={
                                                        data.ngayCap && data.ngayCap !== 0 && data.ngayCap !== null && data.ngayCap !== undefined
                                                            ? new Date(data.ngayCap)
                                                            : null
                                                    }
                                                    format="DD/MM/YYYY"
                                                    fullWidth
                                                    InputAdornmentProps={{ className: "button-date-picker" }}
                                                    variant="inline"
                                                    InputProps={{ disableUnderline: true, classes: inputStyles }}
                                                    invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                                    onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCap")}
                                                    error={submit && StringUtil.isNullOrEmty(data.ngayCap)}
                                                    helperText={submit && StringUtil.isNullOrEmty(data.ngayCap) ? "Vui lòng nhập ngày" : ""}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={3} className="grid-item"></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        style={{ width: "90%" }}
                                        label={"Số GCNĐKDN/GCNĐKĐT"}
                                        name="soGCN"
                                        required
                                        InputLabelProps={{ classes: labelStyles }}
                                        onChange={handleChange}
                                        value={data.soGCN}
                                        error={submit && StringUtil.isNullOrEmty(data.soGCN)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        helperText={submit && StringUtil.isNullOrEmty(data?.soGCN) ? "Vui lòng nhập số GCNĐKDN/GCNĐKĐT" : ""}
                                    />
                                    <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "30px", marginLeft: "3px" }}  onClick={handleSearchDoanhNghiep}/>
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên doanh nghiệp"}
                                        required
                                        name="tenDoanhNghiep"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.tenDoanhNghiep}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.tenDoanhNghiep)}
                                        helperText={submit && StringUtil.isNullOrEmty(data.tenDoanhNghiep) ? "Vui lòng nhập tên doanh nghiệp" : ""}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapGCN && data.ngayCapGCN != 0 ? new Date(data.ngayCapGCN) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapGCN")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Nơi cấp"}
                                        name="noiCapGCN"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.noiCapGCN}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Loại hình Doanh nghiệp
                                    </InputLabel>
                                    <Autocomplete
                                        id="maLoaiHinhDoanhNghiep"
                                        classes={inputStyles}
                                        options={DOANH_NGHIEP}
                                        getOptionLabel={(option) => option.ten}
                                        value={{ ma: data.maLoaiHinhDoanhNghiep, ten: data.tenLoaiHinhDoanhNghiep }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelect(newValue, "maLoaiHinhDoanhNghiep", "tenLoaiHinhDoanhNghiep");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maLoaiHinhDoanhNghiep)}
                                                helperText={
                                                    submit && StringUtil.isNullOrEmty(data.maLoaiHinhDoanhNghiep) ? "Vui lòng chọn loại doanh nghiệp" : ""
                                                }
                                            />
                                        )}
                                    />

                                    {/* <InputLabel classes={labelStyles} required>
                                        Huyện/Thị/TP{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maHuyen"
                                        classes={inputStyles}
                                        options={quans}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maHuyen, name: data.tenHuyen }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn Huyện/Thị/TP" : ""}
                                            />
                                        )}
                                    /> */}

                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên giao dịch"}
                                        name="tenDichVu"
                                        onChange={handleChange}
                                        value={data.tenDichVu}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên viết tắt"}
                                        name="tenVietTat"
                                        onChange={handleChange}
                                        value={data.tenVietTat}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Điện thoại"}
                                        name="dienThoai"
                                        onChange={handleChange}
                                        value={data.dienThoai}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Fax"}
                                        name="fax"
                                        onChange={handleChange}
                                        value={data.fax}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Email"}
                                        name="email"
                                        onChange={handleChange}
                                        value={data.email}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Website"}
                                        name="website"
                                        onChange={handleChange}
                                        value={data.website}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Huyện/Thị/TP{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maHuyen"
                                        classes={inputStyles}
                                        options={quans}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maHuyen, name: data.tenHuyen }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectQuan(newValue, "maHuyen", "tenHuyen");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maHuyen)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maHuyen) ? "Vui lòng chọn Huyện/Thị/TP" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles} required>
                                        Phường/Xã/TT{" "}
                                    </InputLabel>
                                    <Autocomplete
                                        id="maPhuong"
                                        classes={inputStyles}
                                        options={phuongs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maPhuong, name: data.tenPhuong }}
                                        onFocus={(e) => loadPhuongs(data.maHuyen)}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelectPhuong(newValue, "maPhuong", "tenPhuong");
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={submit && StringUtil.isNullOrEmty(data.maPhuong)}
                                                helperText={submit && StringUtil.isNullOrEmty(data.maPhuong) ? "Vui lòng chọn Phường/Xã/TT" : ""}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Đường"}
                                        name="tenDuong"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.tenDuong}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />

                                    {/* <Autocomplete
                                        id="maDuong"
                                        classes={inputStyles}
                                        options={DUONG}
                                        getOptionLabel={(option) => option.ten}
                                        value={{ ma: data.maDuong, ten: data.tenDuong }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeSelect(newValue, "maDuong", "tenDuong");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    /> */}
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số nhà"}
                                        name="soNha"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soNha}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} className="grid-item">
                                    <ChonToaDoComponent
                                        id={"com.fpt.egov.api.model.luhanh.GiayPhepKinhDoanhLuHanh"}
                                        handleChangeToaDo={handleChangeToaDo}
                                        diaChiDayDu={getDiaChiDayDu()}
                                        kinhDo={data.kinhDo}
                                        viDo={data.viDo}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        style={{ width: "90%" }}
                                        label={"Số CMND/ Hộ chiếu/ Căn cước"}
                                        name="soGiayTo"
                                        required
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.soGiayTo}
                                        onChange={handleChange}
                                        error={submit && StringUtil.isNullOrEmty(data.soGiayTo)}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                        helperText={submit && StringUtil.isNullOrEmty(data?.soGiayTo) ? "Vui lòng nhập số CMND/ Hộ chiếu/ Căn cước" : ""}
                                    />
                                    <SearchIcon fontSize="small" style={{ cursor: "pointer", marginTop: "30px", marginLeft: "3px" }} onClick={handleSearchCMND}/>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Ngày cấp</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngayCapSoGiayTo && data.ngayCapSoGiayTo != 0 ? new Date(data.ngayCapSoGiayTo) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngayCapSoGiayTo")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Nơi cấp"}
                                        name="noiCapSoGiayTo"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.noiCapSoGiayTo}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Chức danh"}
                                        name="chucDanh"
                                        value={data.chucDanh}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Người đại diện theo pháp luật"}
                                        name="nguoiDaiDienTheoPhapLuat"
                                        InputLabelProps={{ classes: labelStyles }}
                                        value={data.nguoiDaiDienTheoPhapLuat}
                                        onChange={handleChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Giới tính </InputLabel>
                                    <Autocomplete
                                        id="gioiTinh"
                                        classes={inputStyles}
                                        options={gioiTinhs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.gioiTinh, name: data.tenGioiTinh }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeAutocomplete(newValue, "gioiTinh", "tenGioiTinh");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel classes={labelStyles}>Ngày sinh</InputLabel>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            showTodayButton={true}
                                            value={data.ngaySinh && data.ngaySinh !== 0 ? new Date(data.ngaySinh) : null}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            InputAdornmentProps={{ className: "button-date-picker" }}
                                            variant="inline"
                                            InputProps={{ disableUnderline: true, classes: inputStyles }}
                                            invalidDateMessage="Định dạng không đúng dd/MM/yyyy"
                                            onChange={(date: any, value: any) => handleChangeDatePicker(date, value, "ngaySinh")}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <InputLabel classes={labelStyles}>Dân tộc </InputLabel>
                                    <Autocomplete
                                        id="maDanToc"
                                        classes={inputStyles}
                                        options={danTocs}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maDanToc, name: data.tenDanToc }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeAutocomplete(newValue, "maDanToc", "tenDanToc");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item" >
                                    <InputLabel classes={labelStyles}>Quốc tịch </InputLabel>
                                    <Autocomplete
                                        id="maQuocTich"
                                        classes={inputStyles}
                                        options={quocGias}
                                        getOptionLabel={(option) => option.name}
                                        value={{ code: data.maQuocTich, name: data.tenQuocTich }}
                                        onChange={(event: any, newValue: any) => {
                                            handleChangeAutocomplete(newValue, "maQuocTich", "tenQuocTich");
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Số điện thoại"}
                                        name="soDienThoaiNDD"
                                        onChange={handleChange}
                                        value={data.soDienThoaiNDD}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Email"}
                                        name="mailNDD"
                                        onChange={handleChange}
                                        value={data.mailNDD}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Địa chỉ thường trú"}
                                        name="diaChiThuongTru"
                                        value={data.diaChiThuongTru}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Chỗ ở hiện nay"}
                                        name="choOHienNay"
                                        value={data.choOHienNay}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên, địa chỉ chi nhánh"}
                                        name="tenDiaChiChiNhanh"
                                        value={data?.tenDiaChiChiNhanh}
                                        onChange={handleChange}
                                        rows={3}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tên, địa chỉ văn phòng đại diện"}
                                        name="tenVanPhongDaiDien"
                                        value={data?.tenVanPhongDaiDien}
                                        onChange={handleChange}
                                        rows={3}
                                        multiline
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tài khoản ký quỹ số"}
                                        name="taiKhoanKyQuySo"
                                        value={data.taiKhoanKyQuySo}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="grid-item">
                                    <TextField
                                        fullWidth
                                        label={"Tại ngân hàng"}
                                        name="nganHang"
                                        value={data.nganHang}
                                        onChange={handleChange}
                                        InputLabelProps={{ classes: labelStyles }}
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: inputStyles,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} container className="grid-item">
                                    <DropZoneCustom
                                        acceptFiles={ACCEPT_FILES}
                                        attachFileServer={data.files}
                                        url={AXIOS_INSTANCE.defaults.baseURL + "/file-server/upload/luhanh"}
                                        handleChangeStatus={handleChangeStatus}
                                        handleDeleteFileServer={handleDeleteFileServer}
                                        handleDownloadFile={handleDownloadFile}
                                    />
                                </Grid>
                                <Grid item xs={3} className="grid-item"></Grid>
                            </Grid>
                        </TabPanel>
                    )}
                    {data.maHoSo !== null && data.maHoSo !== undefined && data.maHoSo !== "" ? (
                        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                            {/* <LuHanhNoiDiaButtonComponent
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onSubmit={handleSubmit}
                                onResetData={handleResetData}
                                onOpenDialog={handleOpenDialog}
                            /> */}
                            <ThuLyButtonComponent
                                showIn={data.hoSoThuLyId > 0}
                                onSubmit={handleSubmit}
                                onDownloadFileBieuMau={handleDownloadFileBieuMau}
                                onDelete={handleResetData}
                                handleChuyen={showHidePopperThaoTac}
                                handleDuThao={showHidePopperDuThao}
                                dataButton= {data}
                            />
                        </Grid>
                        ) : (
                        <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                            <DauKyLuHanhNoiDiaButton onSubmit={handleSubmit} onResetData={handleResetData} onOpenDialog={handleOpenDialog} />
                        </Grid>
                        )}
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
            {showDanhSachDuThaoModal && (
               <DanhSachDuThaoModal hoso={data} onToggle={handleToggleDuThao} show={showDanhSachDuThaoModal} />
            )}
          </div>
        </>
    );
};
export default CapGiayLHNDComponent;
