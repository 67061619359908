import { createStyles, Grid, InputLabel, makeStyles, Theme } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Dispatch } from "redux";
import { FileServerService } from "../../../../../apis/Common/FileServerService";
import { HoSoThuLyDiSanVanHoaService } from "../../../../../apis/DiSanVanHoa/HoSoThuLyDiSanVanHoaService";
import { GiayChungNhanKinhDoanh_INIT } from "../../../../../init/DichVuDuLich/GiayChungNhanKinhDoanh_INIT";
import { GiayChungNhanKinhDoanh } from "../../../../../model/DichVuDuLich/GiayChungNhanKinhDoanh";
import { LoadingAction } from "../../../../../redux/actions/applicationAction";
import { bootstrapTextFieldHook } from "../../../../commons/input/bootstrap";

interface giayChungChiProps {
    data: GiayChungNhanKinhDoanh;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

const fontFamily = [
    "Arial",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(",");

const useStylesLabelRight = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 500,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "start",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabelLeft = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            textAlign: "end",
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const useStylesLabel = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "initial",
            transform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: "#7F7F7F", // theme.palette.text.primary
            fontFamily,
            "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
                color: "red",
                "&::before": {
                    content: '"("',
                    marginRight: -3,
                },
                "&::after": {
                    content: '")"',
                },
                paddingLeft: 5,
            },
            "&& + *": {
                marginTop: theme.spacing(1),
            },
        },
        focused: {
            "&$root": {
                color: "#7F7F7F", //theme.palette.text.primary
            },
        },
    })
);

const ChiTietThongTinChungGCNAnUongComponent = (props: giayChungChiProps) => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const [currentDate, setCurrentDate] = useState(0);
    const rightStyle = useStylesLabelRight();
    const leftStyle = useStylesLabelLeft();
    const label = useStylesLabel();
    const [data, setData] = useState<GiayChungNhanKinhDoanh>({
        ...GiayChungNhanKinhDoanh_INIT,
        classNameGCN: "com.fpt.egov.api.model.dichvudulich.GiayChungNhanKinhDoanhDichVuAnUong",
    });
    const classes = useStyles();
    const hoSoThuLyDiSanVanHoaService = new HoSoThuLyDiSanVanHoaService();
    const fileServerService = new FileServerService();

    useEffect(() => {
        initData();
    }, [props.data]);

    const initData = () => {
        setData(props.data);
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số quyết định</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.soQuyetDinh}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayCap != null ? moment(data?.ngayCap).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày hết hạn</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayHetHan != null ? moment(data?.ngayHetHan).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tình trạng</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.tenTrangThai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tên Cơ sở</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tenCoSo}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Địa chỉ</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.diaChiDayDu}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Điện thoại</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.soDienThoai}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Fax</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.fax}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Email</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.email}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Website</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.website}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Số GCNĐKDN/HKD</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.soGCN}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Ngày cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data?.ngayCapGCN != null ? moment(data?.ngayCapGCN).format("DD/MM/YYYY") : ""}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Cơ quan cấp</InputLabel>
                </Grid>
                <Grid item xs={9} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.coQuanCapGCN}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Nơi cấp</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.noiCapGCN}</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item" container justify="flex-end">
                    <InputLabel classes={leftStyle}>Tổng số người quản lý và nhân viên</InputLabel>
                </Grid>
                <Grid item xs={3} className="grid-item">
                    <InputLabel classes={rightStyle}>{data.tongSoNguoi}</InputLabel>
                </Grid>
            </Grid>
        </>
    );
};
export default ChiTietThongTinChungGCNAnUongComponent;
