import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DanhMucBieuMauService, RequestDM } from "apis/quanTri/danhMucBieuMauService";
import { AlertMessage, CustomAlert } from "../../../components/commons/alert";
import { ConfirmationDialogRaw } from "../../../components/commons/dialog/confirmDialog";
import { hideLoading, LoadingAction, showLoading } from "../../../redux/actions/applicationAction";
import DataListBieuMau from "./dataListBieuMau";
import DialogBieuMau from "./dialogBieuMau";
import { DanhMucBieuMauDTO } from "apis/danhMucCN";
import { LoaiBieuMauEnum } from "apis/quanTri/model/danhMucBieuMau";
export interface DanhMucBieuMauProps { }

export const DanhMucBieuMau: React.SFC<DanhMucBieuMauProps> = () => {
    // Constant

    // Hook
    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    // State
    const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [itemSeleted, setItemSeleted] = useState<DanhMucBieuMauDTO | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataList, setDataList] = useState<DanhMucBieuMauDTO[]>([]);
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(5);
    const [total, setTotal] = useState<number>(0);
    const [keyword, setKeyword] = useState("");
    const [isActive, setIsActive] = useState("0");

    // Control
    const loadDataTable = async (pageNumber: number, pageSize: number, name: string) => {
        const request = {
            pageNumber: pageNumber,
            pageSize: pageSize,
            name: name,
            isActive: isActive === "0" ? null : isActive === "1" ? true : false,
        } as RequestDM;
        await new DanhMucBieuMauService().searchDanhMucBieuMau(request).then((res) => {
            setDataList(res.rows);
            setTotal(res.total);
        });
    };

    useEffect(() => {
        loadDataTable(page, size, keyword);
    }, [page, size]);

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const showEditDialog = (item: DanhMucBieuMauDTO) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };

    const handleCloseDeleteRole = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            // if (itemSeleted.id) {
            // showMessageAlert("Chức năng đang phát triển", false);
            //todo...
            if (itemSeleted && itemSeleted.id) {
                await new DanhMucBieuMauService().deleteBieuMau(itemSeleted).then((res) => {
                    if (Boolean(res.isSuccess)) {
                        setPage(0);
                        setSize(5);
                        setKeyword("");
                        loadDataTable(0, 5, "");
                        showMessageAlert("Xóa cấu hình biểu mẫu thành công");
                    } else {
                        showMessageAlert("Xóa cấu hình biểu mẫu không thành công", false);
                    }
                });
            }
        }
        setOpenDeleteConfirmDlg(false);
    };

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSize(Number(event.target.value));
    };

    const handleDeleteItem = (item: DanhMucBieuMauDTO) => {
        if (item) {
            setItemSeleted(item);
            setOpenDeleteConfirmDlg(true);
        }
    };

    const handleSubmitData = async (data: DanhMucBieuMauDTO) => {
        const newValues = { ...data };
        newValues.specializ = LoaiBieuMauEnum.NGHIEP_VU ? true : false;
        await new DanhMucBieuMauService().saveDanhMucBieuMau(data).then((resp) => {
            if (resp.isSuccess) {
                setOpenDialog(false);
                setPage(0);
                setSize(5);
                setKeyword("");
                loadDataTable(0, 5, "");
                showMessageAlert("Yêu cầu thực hiện thành công", true);
            } else {
                showMessageAlert("Yêu cầu thực hiện thất bại", false);
            }
        });
    };

    const handleSearch = async () => {
        loadDataTable(page, size, keyword);
    };

    return (
        <div className="dash-content">
            <div className="dash-title" style={{ marginBottom: 10 }}>
                <span>Danh Mục Biểu Mẫu</span>
            </div>
            <form noValidate autoComplete="off">
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={6} className="grid-item">
                        <InputLabel>Từ khóa</InputLabel>
                        <InputBase
                            fullWidth
                            placeholder="Nhập từ khóa tìm kiếm"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            inputProps={{ "aria-label": "description" }}
                        />
                    </Grid>
                    <Grid item xs={6} className="gridItem">
                        <InputLabel>Hoạt động</InputLabel>
                        <NativeSelect value={isActive} onChange={(e) => setIsActive(e.target.value)} input={<InputBase fullWidth />}>
                            <option value="0"></option>
                            <option value="1">Hoạt động</option>
                            <option value="2">Ngừng hoạt động</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={12} className="button-group-center">
                        <Button variant="contained" color="primary" disableElevation style={{ marginRight: 10 }} onClick={handleSearch}>
                            Tìm kiếm
                        </Button>
                        <Button variant="contained" color="primary" disableElevation onClick={() => setOpenDialog(true)}>
                            Thêm mới
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="grid-item">
                        <DataListBieuMau
                            dataList={dataList}
                            deleteItem={handleDeleteItem}
                            pageProps={{
                                page: page,
                                count: total,
                                rowsPerPage: size,
                                onChangePage: handleOnChangePage,
                                onChangeRowsPerPage: handOnChangeRowsPerPage,
                            }}
                            onEdit={showEditDialog}
                            modalEdit={{ open: true, openEditDialog: showEditDialog }}
                        />
                    </Grid>
                </Grid>

                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dm-bieumau"
                    keepMounted
                    open={openDeleteConfirmDlg}
                    title="Xóa danh mục biểu mẫu"
                    content="Bạn có đồng ý xóa?"
                    onClose={handleCloseDeleteRole}
                />
                <Snackbar
                    open={alertMessage.open}
                    onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={3000}
                >
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                        {alertMessage.content}
                    </CustomAlert>
                </Snackbar>
            </form>
            {openDialog && (
                <DialogBieuMau openDialog={openDialog} onDialogClose={() => setOpenDialog(false)} itemEdit={itemSeleted} onSubmitData={handleSubmitData} />
            )}
        </div>
    );
};
