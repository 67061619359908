import AXIOS_INSTANCE from "..";
import { DanhMucI, SearchRequest } from "../Common/model/CommonModel";
export class DanhMucCapDuLichService {
    constructor() {}
    url: string = "/danh-muc-dia-diem-du-lich";
    search = (request: SearchRequest) => {
        return AXIOS_INSTANCE.post(`${this.url}/search`, request);
    };

    addOrUpdate = (request: DanhMucI) => {
        return AXIOS_INSTANCE.post(`${this.url}/insert-or-update`, request);
    };
    
    delete = (id: number) => {
        return AXIOS_INSTANCE.post(`${this.url}/delete`, { id: id });
    }

    getByHoatDong = (hoatDong : boolean) => {
        return AXIOS_INSTANCE.get(`${this.url}/get-by-hoat-dong/`+ hoatDong);
     }
}
