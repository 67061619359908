import MomentUtils from "@date-io/moment";
import { Box, Checkbox, createStyles, Grid, InputLabel, makeStyles, Snackbar, TextField, Theme, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Alert, Autocomplete, Color } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import AXIOS_INSTANCE from "../../../../apis";
import { createFileType, downLoadFile } from "../../../../apis/Common/exportUtil";
import { FileServerService } from "../../../../apis/Common/FileServerService";
import { DanhMucService } from "../../../../apis/danhMuc/danhMucService";
import { DanhMucDiaChiDTO } from "../../../../apis/danhMuc/model/DanhMucDTO";
import { ThongBaoToChucService } from "../../../../apis/VanHoaNgheThuat/ThongBaoToChucService";
import { ACCEPT_FILES, LOAIBIENDONG_CAPMOI, TINHTRANG_HOATDONG } from "../../../../constants/DiSanVanHoaConstants";
import { HoSoThuLyDiSanVanHoa_INIT } from "../../../../init/DiSanVanHoa/HoSoThuLyDiSanVanHoa_INIT";
import { HoSoThuLyDiSanVanHoa } from "../../../../model/hoSoThuLyDiSanVanHoa";
import { HoatDongVanHoaNgheThuat, HoatDongVanHoaNgheThuat_INIT } from "../../../../model/ThongBaoNgheThuat/HoatDongVanHoaNgheThuat";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import StringUtil from "../../../../utils/stringUtils";
import { ThongTinChuyenNhanhUtil } from "../../../../utils/thongTinChuyenNhanhUtil";
import { ConfirmationDialogRaw } from "../../../commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../commons/input/bootstrap";
import { CustomTab, CustomTabs } from "../../../commons/tabs";
import { OverlayLoading } from "../../../overlayLoading/overlayLoading";
import VanHoaNgheThuatButton from "./Action/VanHoaNgheThuatButton";
import DanhSachTietMucComponent from "./DanhSachTietMucComponent";
import ThongTinChungNgheThuatComponent from "./ThongTinChungNgheThuatComponent";
import { FileEntry } from "../../../../apis/Common/fileEntry";
import { ThongTinDungChungService } from "apis/hoSoCuaToi/thongTinDungChungService";
import { BieuMauAttachFileDTO } from "apis/quanTri/model/danhMucBieuMau";
import { HoSoCuaToiMgtService } from "apis/hoSoCuaToi/HoSoCuaToiMgtService";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: "0px 5px",
        },
    })
);

type ErrorValidation = {
    soGiayChungNhan: string;
    ngayCap: string;
    tenToChuc: string;
    maHuyen: string;
    maPhuong: string;
    soQD: string;
    soCMND: string;
};

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{ width: "100%" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const ThongBaoToChucNTComponet = () => {
    const dispatchLoading = useDispatch<Dispatch<LoadingAction>>();
    const [alert, setAlert] = useState({
        showAlert: false,
        message: "",
        severity: "success",
    });
    const [currentDate, setCurrentDate] = useState(0);
    const [status, setStatus] = useState(1);
    const [indexTab, setIndexTab] = useState(0);
    const location = useLocation<any>();
    const [submit, setSubmit] = useState(false);
    const [tabRequired, setTabRequired] = useState(-1);
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();
    const [data, setData] = useState<HoatDongVanHoaNgheThuat>({ ...HoatDongVanHoaNgheThuat_INIT});
    const classes = useStyles();
    const service = new ThongBaoToChucService();
    const fileServerService = new FileServerService();
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [showAlert, setShowAlert] = useState(false);
    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [isNhapDauKy, setIsNhapDauKy] = useState(false);
    const danhMucService = new DanhMucService();
    const [quans, setQuans] = useState<DanhMucDiaChiDTO[]>([]);
    const [phuongs, setPhuongs] = useState<DanhMucDiaChiDTO[]>([]);
    const hoSoCuaToiMgtService = new HoSoCuaToiMgtService();
    const history = useHistory<any>();
    const thongTinDungChungService = new ThongTinDungChungService();

    useEffect(() => {
        loadQuans("92");
        loadData();
    }, []);

    const loadData =  async () => {
        let id = history?.location?.state?.params;
        let maHS = location.state?.maHoSo;
        let maThuTuc = location.state?.maThuTuc;
        let soChungNhan = location.state?.soChungNhan;
        let update = location?.state?.isUpdate;
        let soChungNhanId = location.state?.soChungNhanId;
        dispatchLoading(showLoading());
        setCurrentDate(StringUtil.dateSecond());

        let hoTenNguoiNop = "";
        let soDienThoaiNguoiNop = "";
        hoSoCuaToiMgtService.chiTiet(location.state?.maHoSo).then((p) => {
            if (p.data && p.data.data) {
                hoTenNguoiNop = p.data.data.nguoiNop;
                soDienThoaiNguoiNop = p.data.data.dienThoaiNguoiNop;
            }
        }).finally(() => {
            dispatchLoading(hideLoading());
        });

        if (update) {
            await service
                .getBySoGiayChungNhanDiSanVanHoas(soChungNhanId)
                .then((res) => {
                    const dataNew = {
                        ...res?.data?.data,
                        isUpdate: update,
                        resoucePK: res?.data?.data.id,
                        id: res?.data?.data.hoSoThuLyId,
                        nhapDauKy: true,
                        className: "com.fpt.egov.api.model.hdnt.ThongBaoToChucHDNT",
                    };
                    setData(dataNew);
                    setIsNhapDauKy(true);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else if (maHS) {
            let className = "com.fpt.egov.api.model.hdnt.ThongBaoToChucHDNT";
            await service
                .getByMaHoSo(maHS, className)
                .then(async(res) => {
                    if (StringUtil.hasData(res)) {
                        const dataNew = {
                            ...res?.data?.data,
                            maHoSo: maHS,
                            maThuTuc: maThuTuc,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            className: "com.fpt.egov.api.model.hdnt.ThongBaoToChucHDNT",
                        };
                        setData(dataNew);
                    } else {
                        let dataEform = await new ThongTinChuyenNhanhUtil().mapToThongTinChuyenNganh(maHS);
                        const dataNew = {
                            ...HoatDongVanHoaNgheThuat_INIT,
                            maHoSo: maHS,
                            maTrangThai: TINHTRANG_HOATDONG.ma,
                            tenTrangThai: TINHTRANG_HOATDONG.ten,
                            maBienDong: LOAIBIENDONG_CAPMOI.ma,
                            tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                            maThuTuc: maThuTuc,
                            className: "com.fpt.egov.api.model.hdnt.ThongBaoToChucHDNT",
                            // nhapDauKy: true,
                            soCMND :  dataEform?.cmnd ? dataEform?.cmnd : HoSoThuLyDiSanVanHoa_INIT.soCMND,
                            noiCapCMND :  dataEform?.noicapcmnd ? dataEform?.noicapcmnd : HoSoThuLyDiSanVanHoa_INIT.noiCapCMND,
                            noiCapQD :  dataEform?.noicapdkkd ? dataEform?.noicapdkkd : HoSoThuLyDiSanVanHoa_INIT.noiCapQD,
                            ngayCapCMND : dataEform?.ngaycapcmnd ? StringUtil.stringDateToNumber(dataEform?.ngaycapcmnd) : HoSoThuLyDiSanVanHoa_INIT.ngayCapCMND,
                            ngayCapQD : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaycapdkkd) : HoSoThuLyDiSanVanHoa_INIT.ngayCapQD,
                            ngaySinh : dataEform?.ngaycapdkkd ? StringUtil.stringDateToNumber(dataEform?.ngaythangsinh) : HoSoThuLyDiSanVanHoa_INIT.ngaySinh,
                            giaydkkd : dataEform?.giaydkkd,
                            soDienThoai : soDienThoaiNguoiNop != "" ? soDienThoaiNguoiNop : dataEform?.sdt,
                            fax : dataEform?.fax,
                            chucDanh : dataEform?.chucvunguoidaidien
                        };
                        setData(dataNew);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    dispatchLoading(hideLoading());
                });
        } else {
            const dataNew = {
                ...HoatDongVanHoaNgheThuat_INIT,
                maHoSo: maHS,
                maTrangThai: TINHTRANG_HOATDONG.ma,
                tenTrangThai: TINHTRANG_HOATDONG.ten,
                maBienDong: LOAIBIENDONG_CAPMOI.ma,
                tenBienDong: LOAIBIENDONG_CAPMOI.ten,
                maThuTuc: maThuTuc,
                className: "com.fpt.egov.api.model.hdnt.ThongBaoToChucHDNT",
                nhapDauKy: true,
            };
            setData(dataNew);
            setIsNhapDauKy(true);
        }
    };

    const loadQuans = (maThanhPho: string) => {
        danhMucService
            .getDanhMucDiaChi(maThanhPho)
            .then((res) => {
                if (res.length > 0) {
                    setQuans(res);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSubmit = () => {
        setTabRequired(-1);
        setSubmit(true);

        if (data.ngayCapChungNhan > currentDate) {
            setAlert({
                showAlert: true,
                message: "Ngày cấp không được lớn hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenNhaHat)){
            return;
        }
        if (StringUtil.isNullOrEmty(data.soGiayPhep)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.tenNhaHat)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maHuyen)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuong)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.soCMND)) {
            return;
        }
        if ((!StringUtil.isNullOrEmty(data.soCMND) && data.soCMND.length < 9) || data.soCMND.length <= 0 || data.soCMND.length > 12) {
            setAlert({
                showAlert: true,
                message: "Số CMND phải từ 9 đến 12 số",
                severity: "warning",
            });
            return;
        }
        if (StringUtil.isNullOrEmty(data.nguoiDaiDienTheoPhapLuat)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.noiDungBieuDien)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.thoiGianToChuc)) {
            return;
        }

        if (StringUtil.isNullOrEmty(data.maHuyenToChuc)) {
            return;
        }
        if (StringUtil.isNullOrEmty(data.maPhuongToChuc)) {
            return;
        }
        
        if (data.thoiGianToChuc < currentDate) {
            setAlert({
                showAlert: true,
                message: "Thời gian tổ chức không được nhỏ hơn ngày hiện tại",
                severity: "warning",
            });
            return;
        }
      
        setSubmit(false);
        dispatchLoading(showLoading());
        service.insertOrUpdate(data)
            .then((response) => {
                if (response.data.error_code === "SUCCESSFUL" && response.data.data) {
                    setData({ ...response.data.data });
                    setAlert({
                        showAlert: true,
                        message: "Yêu cầu thực hiện thành công",
                        severity: "success",
                    });
                } else if (response.data.error_code === "409") {
                    setAlert({
                        showAlert: true,
                        message: response.data.error_message,
                        severity: "warning",
                    });
                } else {
                    setAlert({
                        showAlert: false,
                        message: "Yêu cầu thực hiện thất bại",
                        severity: "error",
                    });
                }
            })
            .catch((error) => {
                setAlert({
                    showAlert: false,
                    message: "Yêu cầu thực hiện thất bại",
                    severity: "error",
                });
            })
            .finally(() => {
                dispatchLoading(hideLoading());
            });
    };
    const handleOpenDialog = () => {
        setOpenDeleteUserDlg(true);
    };

    const handleCloseDeleteInfo = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            handleResetData();
            setMessage("Yêu cầu thực hiện thành công");
            setSeverity("success");
            setShowAlert(true);
        }
        setOpenDeleteUserDlg(false);
    };

    const handleChangeDatePicker = (date: any, value: any, filedName: string) => {
        let valueNew = date?.toDate()?.getTime();
        let dataNew = { ...data, [filedName]: valueNew };
        setData(dataNew);
    };

    const handleChangeSelect = (value: any, keyMa: string, keyTen: string) => {
        let tenValue = value?.ma !== "" ? value?.ten : "";
        setData({
            ...data,
            [keyMa]: value?.ma,
            [keyTen]: tenValue,
        });
    };

    const handleChange = (e: any) => {
        const isCheckbox = e.target.type === "checkbox";
        setData((data) => ({
            ...data,
            [e.target.name]: isCheckbox ? e.target.checked : e.target.value,
        }));
    };

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setIndexTab(newValue);
    };
    const handleResetData = () => {
        const dataNew = {
            ...HoatDongVanHoaNgheThuat_INIT,
            id: data.id,
            maHoSo: data.maHoSo,
            maThuTuc: data.maThuTuc,
            resoucePK: data.resoucePK,
            className: "com.fpt.egov.api.model.hdnt.ThongBaoToChucHDNT",
        };
        setData(dataNew);
    };
   
    //file
    const handleChangeStatus = (file: IFileWithMeta) => {
        if (file.xhr?.readyState === 4 && file.xhr.status === 200 && file.meta.status === "done") {
            let res = JSON.parse(file.xhr.response);
            setDataFiles([...res.data]);
            file.remove();
        }
    };
    const setDataFiles = (memData: FileEntry[]) => {
        let mems = [...data.files];
        mems = mems.concat(memData);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDeleteFileServer = (item: FileEntry, index: number) => {
        onDeleteFiles(index);
    };

    const handleChangeSelectPhuong = (value: any, nameValue: string, nameText: string) => {
        let valueText = value?.code !== "" ? value?.name : "";
        setData({ ...data, [nameValue]: value?.code, [nameText]: valueText });
    };

    const onDeleteFiles = (index: number) => {
        let mems = [...data.files];
        mems.splice(index, 1);
        let dataNew = {
            ...data,
            ["files"]: mems,
        };
        setData(dataNew);
    };
    const handleDownloadFile = (file: FileEntry) => {
        fileServerService.downloadFile(file).then((res) => {
            downLoadFile(res.data, createFileType(file.extension), file.name);
        });
    };
    const handleDownloadFileBieuMau = (bieuMau: BieuMauAttachFileDTO) => {
        let className = "com.fpt.egov.api.model.hdnt.ThongBaoToChucHDNT";
        service.downloadFileThuLy({ templateId: bieuMau.fileId, maHoSo: data.maHoSo, className: className }).then((res) => {
            downLoadFile(res.data, createFileType(bieuMau.contentType), bieuMau.fileName as string);
        });
    };

    async function handleSearchCMND() {
        dispatchLoading(showLoading());
        await thongTinDungChungService.getThongTinCongDan(data?.soCMND)
        .then((res) => { 
            if (res && res?.data && res?.data?.data && res?.data?.data?.data && res?.data?.data?.data?.soCMND) {
                setData({ ...data,
                    ngayCapCMND: res?.data?.data?.data?.cmt_NgayCap ? StringUtil.stringDateToNumber(res?.data?.data?.data?.cmt_NgayCap) : data?.ngayCapCMND,
                })   
            }else {
                setAlert({
                    showAlert: true,
                    message: "Số căn cước/CMND không tồn tại trong cơ sở dữ liệu",
                    severity: "warning",
                });
            }
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            dispatchLoading(hideLoading());
        });
    }

    const setDataParam = (name: string, value: any) => {
        setTabRequired(-1);
        if (name === "data") {
            let dataNew = { ...value };
            setData(dataNew);
        } else {
            let dataNew = {
                ...data,
                [name]: value
            };
            setData(dataNew);
        }
    }

    return (
        <div className="dash-content">
        <>
            {
                <Grid container>
                    <Snackbar
                        open={alert.showAlert}
                        onClose={() => setAlert({ ...alert, showAlert: !alert.showAlert })}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={() => setAlert({ ...alert, showAlert: false })} severity={alert.severity as Color}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                    <Grid container item xs={12} className="grid-item">
                        <div className="dash-title">
                            <h6 style={{ fontSize: "15px", fontWeight: "bold" }}>THÔNG BÁO TỔ CHỨC BIỂU DIỄN NGHỆ THUẬT, TRÌNH DIỄN THỜI TRANG; THI NGƯỜI ĐẸP, NGƯỜI MẪU </h6>
                        </div>
                    </Grid>
                    <br />
                    <Grid style={{ float: "left" }} item xs={12} className="grid-item">
                        <Box mt="5px" bgcolor="#f2f2f2">
                            <CustomTabs value={indexTab} onChange={handleChangeTab}>
                                <CustomTab style={tabRequired == 0 ? { color: 'red' } : {}} label="Thông tin chung" />
                                {/* <CustomTab style={tabRequired == 1 ? { color: 'red' } : {}} className="test" label="Danh sách tiết mục" /> */}
                            </CustomTabs>
                        </Box>
                    </Grid>
                    {
                        indexTab === 0 &&
                        <TabPanel value={indexTab} index={0}>
                            <ThongTinChungNgheThuatComponent
                                onUpdateData={setDataParam}
                                submit={submit}
                                data={data}
                                isNhapDauKy={isNhapDauKy}
                            />
                            <Grid container style={{ float: "left" }} item xs={12} className="grid-item">
                        <VanHoaNgheThuatButton
                            onDownloadFileBieuMau={handleDownloadFileBieuMau}
                            onSubmit={handleSubmit}
                            onResetData={handleResetData}
                            onOpenDialog={handleOpenDialog}
                        />
                    </Grid>
                    <OverlayLoading />
                        </TabPanel>
                    }
                    {/* {
                        indexTab === 1 &&
                        <TabPanel value={indexTab} index={1}>
                            <DanhSachTietMucComponent
                                onUpdateData={setDataParam}
                                submit={submit}
                                data={data}
                                isNhapDauKy={isNhapDauKy}
                            />
                        </TabPanel>
                    } */}
                    
                </Grid>
            }
            {openDeleteUserDlg && (
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-dsvh"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa thông tin"
                    content="Bạn có đồng ý xóa thông tin đã nhập?"
                    onClose={handleCloseDeleteInfo}
                />
            )}
        </>
        </div>
    );
};
export default ThongBaoToChucNTComponet;
