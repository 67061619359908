import { Button, Grid, InputBase, InputLabel, NativeSelect, Snackbar } from "@material-ui/core";
import { DanhMucLoaiDiSanService } from "apis/danhMucCN/DanhMucLoaiDiSanService";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BaseResponse } from "apis/baseService";
import { AlertMessage, CustomAlert } from "../../../../components/commons/alert";
import ButtonExt from "../../../../components/commons/button/customButton";
import { ConfirmationDialogRaw } from "../../../../components/commons/dialog/confirmDialog";
import { bootstrapTextFieldHook } from "../../../../components/commons/input/bootstrap";
import { DanhMucLoaiDiSan, SearchRequest } from "../../../../model/DiSanVanHoa/DiSanVanHoaModel";
import { hideLoading, LoadingAction, showLoading } from "../../../../redux/actions/applicationAction";
import { DanhMucLoaiDiSanEditDialog } from "./DanhMucLoaiDiSanEditDialog";
import { DataListDanhMucLoaiDiSan } from "./DataListDanhMucLoaiDiSan";

export const DanhMucLoaiDiSanComponent = () => {
    const labelStyles = bootstrapTextFieldHook.useLabel();
    const inputStyles = bootstrapTextFieldHook.useInput();

    const [openDeleteUserDlg, setOpenDeleteUserDlg] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage>({ severity: "error", open: false } as AlertMessage);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dataList, setDataList] = useState<DanhMucLoaiDiSan[]>([]);
    const [itemSeleted, setItemSeleted] = useState<DanhMucLoaiDiSan>({} as DanhMucLoaiDiSan);

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(5);
    const [total, setTotal] = useState(0);

    const [keyword, setKeyword] = useState("");
    const [status, setStatus] = useState(1);

    const dispatch = useDispatch<Dispatch<LoadingAction>>();

    const showMessageAlert = (content: string, isSuccess: boolean = true) => {
        setAlertMessage({ open: true, content: content, severity: isSuccess ? "success" : "error" });
    };

    const loadDataTable = async () => {
        dispatch(showLoading());
        let request: SearchRequest = { keywords: keyword, hoatDong: status, pageNumber: page + 1, pageSize: size };
        await new DanhMucLoaiDiSanService()
            .search(request)
            .then((p) => {
                setDataList(p.rows ? p.rows : []);
                setTotal(p.total);
            })
            .catch((e : any) => {
                console.log(e);
            })
            .finally(() => {
                dispatch(hideLoading());
            });
    };
    useEffect(() => {
        loadDataTable();
    }, [page, size]);

    const doSearch = () => {
        loadDataTable();
    };
    const handleSubmitData = (resp: BaseResponse) => {
        if (resp.isSuccess) {
            setOpenDialog(false);
            setPage(0);
            loadDataTable();
            showMessageAlert(resp.message);
        } else {
            showMessageAlert(resp.message, false);
        }
    };
    const showEditDialog = (item: DanhMucLoaiDiSan) => {
        dispatch(showLoading());
        setItemSeleted(item);
        setOpenDialog(true);
        dispatch(hideLoading());
    };

    const handleOnChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const handOnChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSize(Number(event.target.value));
    };

    const handleDeleteItem = (id: number) => {
        if (id > 0) {
            setItemSeleted({ id: id } as DanhMucLoaiDiSan);
            setOpenDeleteUserDlg(true);
        }
    };

    const handleCloseDeleteRole = async (isOk?: boolean) => {
        if (Boolean(isOk)) {
            if (itemSeleted.id > 0) {
                showMessageAlert("Chức năng đang phát triển", false);
                //todo...
                // dispatch(showLoading());
                // await new RoleManagementService().deleteRole(itemSeleted.id).then((p) => {
                //     if (Boolean(p.data)) {
                //         loadDataTable();
                //         showMessageAlert('Xóa người dùng thành công');
                //     } else {
                //         showMessageAlert('Xóa người dùng không thành công', false);
                //     }
                // }).catch(e => {
                //     showMessageAlert(e, false);
                // }).finally(() => {
                //     dispatch(hideLoading());
                // });
            }
        }
        setOpenDeleteUserDlg(false);
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage((alertMessage) => ({
            ...alertMessage,
            open: false,
        }));
    };
    return (
        <div className="dash-content">
            <div className="dash-title">
                <span>Danh mục loại di sản</span>
            </div>
            <form noValidate autoComplete="off">
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={6}>
                        <InputLabel classes={labelStyles}>Từ khóa</InputLabel>
                        <InputBase
                            fullWidth
                            placeholder="Nhập nội dung tìm kiếm"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            classes={bootstrapTextFieldHook.useInput()}
                            inputProps={{ "aria-label": "description" }}
                        />
                    </Grid>
                    <Grid item xs={6} className="gridItem">
                        <InputLabel classes={labelStyles}>Hoạt động</InputLabel>
                        <NativeSelect input={<InputBase fullWidth classes={inputStyles} />} value={status} onChange={(e) => setStatus(Number(e.target.value))}>
                            <option value={0}></option>
                            <option value={1}>Hoạt động</option>
                            <option value={2}>Ngừng hoạt động</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={12} className="button-group-center">
                        <Button variant="contained" color="primary" disableElevation onClick={doSearch}>
                            Tìm kiếm
                        </Button>
                        <ButtonExt variant="contained" color="primary" disableElevation onClick={() => showEditDialog({ id: -1 } as DanhMucLoaiDiSan)}>
                            Thêm mới
                        </ButtonExt>
                    </Grid>
                </Grid>
                <div>
                    <DataListDanhMucLoaiDiSan
                        dataList={dataList}
                        deleteItem={handleDeleteItem}
                        pageProps={{
                            page: page,
                            count: total,
                            rowsPerPage: size,
                            onChangePage: handleOnChangePage,
                            onChangeRowsPerPage: handOnChangeRowsPerPage,
                        }}
                        modalEdit={{ open: true, openEditDialog: showEditDialog }}
                    />
                    {openDialog && (
                        <DanhMucLoaiDiSanEditDialog
                            openDialog={openDialog}
                            onDialogClose={() => setOpenDialog(false)}
                            itemEdit={itemSeleted}
                            onSubmitData={handleSubmitData}
                        />
                    )}
                </div>
                <ConfirmationDialogRaw
                    id="confirm-dialog-delete-user"
                    keepMounted
                    open={openDeleteUserDlg}
                    title="Xóa vai trò"
                    content="Bạn có đồng ý xóa nhóm vai trò này?"
                    onClose={handleCloseDeleteRole}
                />
                <Snackbar
                    open={alertMessage.open}
                    onClose={handleCloseAlertMessage}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={3000}
                >
                    <CustomAlert onClose={handleCloseAlertMessage} severity={alertMessage.severity}>
                        {alertMessage.content}
                    </CustomAlert>
                </Snackbar>
            </form>
        </div>
    );
};
